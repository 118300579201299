import { makeStyles } from "@material-ui/core";

export const stylesWebAppCard = makeStyles({
  paper: {
    marginBottom: "20px",
    padding: "10px",
  },
  container: {},
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  itemIcon: {
    textAlign: "right",
    margin: "auto",
  },
  divider: {
    margin: "0",
  },
});
