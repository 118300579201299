import _ from 'lodash';
import ButtonIcon from 'Components/Buttons/button-icon';
import ButtonOutlined from 'Components/Buttons/button-outlined';
import CasinoIcon from '@material-ui/icons/Casino';
import Colors from 'Common/colors';
import InputCheckbox from 'Components/Inputs/input-checkbox';
import MoveListEditor from 'Components/Permission/MoveListEditor/move-list-editor';
import React from 'react';
import SvgPencil from 'Components/Images/image-edit-pencil';
import SvgPlusCircle from 'Components/Images/image-plus-circle';
import SvgTrash from 'Components/Images/image-trash';
import TypographyTitle from 'Components/Typography/typography-title';
import UserApi from 'Services/Api/user-api';
import { ApiResultStatusEnum, IconType } from 'Common/enum';
import { FormikErrors, useFormikContext } from 'formik';
import { SaveUserLoginCustomersModelType } from 'Types/user-types';
import { stylesUserCompanySection } from '../styles';
import { UserLoginListItemFormikValuesType } from '../formikValues';
import {
  CustomerLocation,
  GetCustomerListResponseType,
} from 'Types/customer-types';
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

const userApi = new UserApi();

export interface UserLoginListItemCompanyProps {
  customerList: GetCustomerListResponseType[];
}

const UserLoginListItemCompany: React.FC<UserLoginListItemCompanyProps> = ({
  customerList,
}) => {
  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    handleChange,
    setFieldTouched,
  } = useFormikContext<UserLoginListItemFormikValuesType>();

  const customStyles = stylesUserCompanySection();

  const [isLocationEditorOpen, setIsLocationEditorOpen] =
    React.useState<boolean>(false);
  const [selectedCustomerId, setSelectedCustomerId] = React.useState<number>();

  const handleSelectCustomer = (value: any, indexNo: number) => {
    setFieldTouched(`customers[${indexNo}].customerID`);

    const customerID = value ? parseInt(value.toString()) : 0;
    const pinLengthForSelectedCustomer =
      customerList.find((cust) => cust.customerID === customerID)?.pinLength ??
      0;

    setFieldValue(
      'customers',
      values.customers.map(
        (item, index): SaveUserLoginCustomersModelType =>
          index !== indexNo
            ? item
            : ({
                ...item,
                customerID,
                roleID: 0,
                pinLength: pinLengthForSelectedCustomer,
              } as SaveUserLoginCustomersModelType)
      )
    );
  };

  const handleSelectRole = (value: any, indexNo: number) => {
    setFieldTouched(`customers[${indexNo}].roleID`);
    setFieldValue(
      'customers',
      values.customers.map(
        (item, index): SaveUserLoginCustomersModelType =>
          index !== indexNo
            ? item
            : ({
                ...item,
                roleID: value ? parseInt(value.toString()) : 0,
              } as SaveUserLoginCustomersModelType)
      )
    );
  };

  const handleChangePinCode = (value: any, indexNo: number) => {
    const pinCode = value ? value.replace(/[^0-9]/g, '') : undefined;

    setFieldTouched(`customers[${indexNo}].pinCode`);
    setPinCode(indexNo, pinCode);
  };

  const setPinCode = (indexNo: number, pinCode?: string) => {
    setFieldValue(
      'customers',
      values.customers.map(
        (item, index): SaveUserLoginCustomersModelType =>
          index !== indexNo
            ? item
            : ({
                ...item,
                pinCode,
              } as SaveUserLoginCustomersModelType)
      )
    );
  };

  const handleSaveLocations = (selected: number[]) => {
    setFieldValue(
      'customers',
      values.customers.map((item) =>
        item.customerID !== selectedCustomerId
          ? item
          : ({
              ...item,
              locationIDs: selected,
            } as SaveUserLoginCustomersModelType)
      )
    );
    setIsLocationEditorOpen(false);
  };

  const addCompanyRole = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const customersLength = values.customers.length;
    const lastCustomer = values.customers[values.customers.length - 1];
    if (
      customersLength === 0 ||
      lastCustomer.customerID !== 0 ||
      lastCustomer.roleID !== 0
    ) {
      setFieldValue('customers', [
        ...values.customers,
        {
          customerID: 0,
          roleID: 0,
          isExternalUserSync: true,
        } as SaveUserLoginCustomersModelType,
      ]);
    }
  };

  const removeCompanyRole = (indexNo: number) => {
    setFieldValue(
      'customers',
      values.customers.filter((item, index) => index !== indexNo)
    );
  };

  const handleClickRandomPinCode = (indexNo: number) => {
    const selectedCustomer = values.customers[indexNo];
    let pinCode: string | undefined = '';

    if (selectedCustomer?.customerID) {
      userApi
        .generatePinCode({
          userLoginID: values.userLoginID,
          customerID: selectedCustomer.customerID,
        })
        .then((response) => {
          if (
            response?.data?.pinCode &&
            response.status === ApiResultStatusEnum.Ok
          ) {
            pinCode = response.data.pinCode;
          }
        })
        .finally(() => {
          setPinCode(indexNo, pinCode);
        });
    } else {
      setPinCode(indexNo, pinCode);
    }
  };

  const getAllOptions = () => {
    return (
      customerList
        .find((c) => c.customerID === selectedCustomerId)
        ?.locations?.map((l) => ({ id: l.locationID, label: l.name })) ?? []
    );
  };

  const getSelectedOptions = () => {
    const customerSelectedLocationIds: number[] =
      values.customers.find((c) => c.customerID === selectedCustomerId)
        ?.locationIDs ?? [];
    const allCustomerLocations: CustomerLocation[] =
      customerList.find((c) => c.customerID === selectedCustomerId)
        ?.locations ?? [];

    let newCustomerSelectedLocationList = _.intersectionWith(
      allCustomerLocations,
      customerSelectedLocationIds,
      (al: CustomerLocation, sl: number) => {
        return al.locationID === sl;
      }
    );

    return newCustomerSelectedLocationList.map((l) => ({
      id: l.locationID,
      label: l.name,
    }));
  };

  return (
    <>
      <Grid container item xs={10} alignItems="center">
        <TypographyTitle text="Companies" fontSize="18px" />
      </Grid>
      <Grid container item xs={2} alignItems="center" justify="flex-end">
        <Grid item>
          <ButtonOutlined
            text="Add company"
            iconStartType={IconType.SvgImageIcon}
            iconStart={<SvgPlusCircle width={'14px'} />}
            size="md"
            margin="0 10px 0 0"
            handleClick={addCompanyRole}
          />
        </Grid>
      </Grid>

      <Grid container>
        {values.customers.map(
          (c: SaveUserLoginCustomersModelType, index: number) => {
            const errorsRole: string =
              touched.customers &&
              touched.customers[index] &&
              touched.customers[index].roleID &&
              errors?.customers &&
              errors.customers[index]
                ? (
                    errors.customers[
                      index
                    ] as FormikErrors<SaveUserLoginCustomersModelType>
                  ).roleID ?? ''
                : '';
            const errorsCustomer: string =
              touched.customers && touched.customers[index]?.customerID
                ? errors.customersDupclicate
                  ? errors.customersDupclicate
                  : errors.customers && errors.customers[index]
                  ? (
                      errors.customers[
                        index
                      ] as FormikErrors<SaveUserLoginCustomersModelType>
                    ).customerID ?? ''
                  : ''
                : '';

            const errorPinCode: string | undefined =
              touched.customers && touched.customers[index]?.pinCode
                ? errors.customers && errors.customers[index]
                  ? (
                      errors.customers[
                        index
                      ] as FormikErrors<SaveUserLoginCustomersModelType>
                    ).pinCode ?? undefined
                  : undefined
                : undefined;

            return (
              <Grid
                container
                id={index.toString()}
                key={index}
                wrap="nowrap"
                item
                spacing={1}
                justify="space-between"
              >
                <Grid item sm={3} container>
                  <FormControl required fullWidth style={{ maxWidth: '300px' }}>
                    <InputLabel id={`customer-select-label-${index}`}>
                      Customer
                    </InputLabel>

                    <Select
                      id="customerID"
                      labelId={`customer-select-label-${index}`}
                      value={c.customerID ?? 0}
                      name={'customerID' + index}
                      disableUnderline={false}
                      onChange={(event) => {
                        handleSelectCustomer(event.target.value, index);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem key={0} value={0}>
                        -- none --
                      </MenuItem>
                      {customerList.map((customer) => (
                        <MenuItem
                          key={customer.customerID}
                          value={customer.customerID}
                        >
                          {customer.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>{errorsCustomer}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={2} container>
                  <FormControl required fullWidth style={{ maxWidth: '300px' }}>
                    <InputLabel id={`role-select-error-label-${index}`}>
                      Role
                    </InputLabel>
                    <Select
                      labelId={`role-select-error-label-${index}`}
                      id="roleID"
                      value={c.roleID ?? 0}
                      name={'roleID' + index}
                      disableUnderline={false}
                      onChange={(event) => {
                        handleSelectRole(event.target.value, index);
                      }}
                    >
                      <MenuItem key={0} value={0}>
                        -- none --
                      </MenuItem>
                      {customerList
                        .find((cl) => cl.customerID === c.customerID)
                        ?.roles.map((role) => (
                          <MenuItem key={role.roleID} value={role.roleID}>
                            {role.name}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText error>{errorsRole}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={2} style={{ paddingTop: '19px' }}>
                  <InputCheckbox
                    checked={c.isExternalUserSync}
                    text="External user sync"
                    labelFontSize="15px"
                    onChange={handleChange}
                    name={`customers[${index}].isExternalUserSync`}
                  />
                </Grid>
                <Grid item sm={2}>
                  <TextField
                    fullWidth
                    id={`customers[${index}].pinCode`}
                    name={`customers[${index}].pinCode`}
                    label="PIN Code"
                    value={c.pinCode}
                    onChange={(event) => {
                      handleChangePinCode(event.target.value, index);
                    }}
                    onBlur={handleBlur}
                    error={!!errorPinCode}
                    helperText={
                      errorPinCode ??
                      `${c.pinCode?.toString().length ?? 0}/${c.pinLength}`
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: { ...{ input: customStyles.input } },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClickRandomPinCode(index)}
                          >
                            <CasinoIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={2}>
                  <ButtonOutlined
                    text={`${
                      c.locationIDs ? c.locationIDs.length : 0
                    } locations`}
                    iconStartType={IconType.SvgImageIcon}
                    iconStart={<SvgPencil width={'14px'} />}
                    fullWidth
                    margin="10px 0 0 5px"
                    handleClick={() => {
                      setSelectedCustomerId(c.customerID);
                      setIsLocationEditorOpen(true);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={1}
                  container
                  style={{ marginTop: '8px', marginLeft: '5px' }}
                >
                  <ButtonIcon
                    iconType={IconType.SvgImageIcon}
                    icon={
                      <SvgTrash
                        width="18px"
                        height="20px"
                        color={Colors.black}
                      />
                    }
                    handleClick={() => {
                      removeCompanyRole(index);
                    }}
                  />
                </Grid>
              </Grid>
            );
          }
        )}
      </Grid>
      <MoveListEditor
        isOpen={isLocationEditorOpen}
        close={() => setIsLocationEditorOpen(false)}
        dialogTitle={`Edit locations for customer: ${
          customerList.find((c) => c.customerID === selectedCustomerId)?.name
        }`}
        save={handleSaveLocations}
        allOptions={getAllOptions()}
        selectedOptions={getSelectedOptions()}
      />
    </>
  );
};

export default UserLoginListItemCompany;
