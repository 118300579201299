import { makeStyles } from "@material-ui/core";

export const stylesMobileAppCard = makeStyles({
  paper: {
    marginBottom: "20px",
    padding: "10px",
  },

  textRight: {
    marginTop: "5px",
    textAlign: "right",
  },
  itemIcon: {
    textAlign: "right",
    margin: "auto",
  },
  dividerBottom: {
    margin: "0",
  },
});
