import * as React from 'react';
import { TextField, Grid } from '@material-ui/core';
import { ChangeEvent } from 'react';
import ButtonSolid from 'Components/Buttons/button-solid';
import {
  GetReportStoredProcedureParameterType,
  SaveReportStoredProcedureParameterType,
} from 'Types/report-types';
import EditParam from './edit-param';

export interface SelectProcedureProps {
  procedureName: string;
  handleChangeProcedureName: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;

  procedureParams: GetReportStoredProcedureParameterType[];
  editParam: (param: GetReportStoredProcedureParameterType) => void;

  addedParams: SaveReportStoredProcedureParameterType[];
  addNewParam: () => void;
  editAddedParam: (
    param:
      | SaveReportStoredProcedureParameterType
      | GetReportStoredProcedureParameterType
  ) => void;
  deleteParam: (
    data:
      | GetReportStoredProcedureParameterType
      | SaveReportStoredProcedureParameterType
  ) => void;
}

const SelectProcedure: React.FC<SelectProcedureProps> = ({
  handleChangeProcedureName,
  editParam,
  procedureName,
  procedureParams,
  addNewParam,
  addedParams,
  editAddedParam,
  deleteParam,
}) => {
  return (
    <Grid container direction='column' wrap='nowrap'>
      <TextField
        name='procedureName'
        variant='standard'
        label='Procedure name'
        InputProps={{
          style: { fontSize: '14px' },
        }}
        value={procedureName}
        onChange={handleChangeProcedureName}
        fullWidth
      />
      <p>Parameters</p>
      <Grid container style={{ width: '66%' }} spacing={2}>
        {procedureParams.map((p) => (
          <EditParam data={p} edit={editParam} deleteParam={deleteParam} />
        ))}
        {addedParams.map((p) => (
          <EditParam data={p} edit={editAddedParam} deleteParam={deleteParam} />
        ))}

        <ButtonSolid
          text='Add param'
          onClickEvent={() => addNewParam()}
          margin='10px 0 20px 10px'
        />
      </Grid>
    </Grid>
  );
};

export default SelectProcedure;
