import { makeStyles } from "@material-ui/core";

export const stylesSelectColumnNameDialog = makeStyles({
  content: {
    height: "500px",
    padding: "0 32px",
  },
  autocomplete: {
    height: "325px",
  },
});
