import { LandingPageEnum } from 'Common/enum';
import * as React from 'react';
import { Route } from 'react-router-dom';
import { setLastVisitedPage } from './settings-helper';

export interface IAppRouteProps {
  Component: (props: any) => JSX.Element;
  landingPage: LandingPageEnum | null;
  path: string;
  [x: string]: any;
}

export const AppRoute = ({
  Component,
  landingPage,
  path,
  ...rest
}: IAppRouteProps) => {
  if (landingPage) {
    setLastVisitedPage(landingPage);
  }
  return (
    <Route path={path} render={(props) => <Component {...rest} {...props} />} />
  );
};
