import * as React from "react";

const SvgAndroid = ({
  style = {},
  fill = "none",
  width = "100%",
  className = "",
  color = "#DCDCDC",
  colorFill = "white",
  viewBox = "0 0 34 34",
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="34" height="34" rx="5" fill={color} />
    <path
      d="M6.86641 22.7216H27.9573C28.5075 22.7216 28.9178 22.2147 28.8048 21.6762C28.1072 18.3512 25.9902 15.5491 23.1177 13.9303L24.9207 10.6772C25.0427 10.4569 24.9631 10.1794 24.7429 10.0573C24.5221 9.93502 24.2449 10.0149 24.1229 10.2351L22.3063 13.5128C20.8184 12.8223 19.1602 12.4367 17.4118 12.4367C15.6634 12.4367 14.0052 12.8223 12.5173 13.5128L10.7006 10.235C10.5785 10.0147 10.301 9.93522 10.0808 10.0572C9.86056 10.1793 9.781 10.4568 9.90301 10.6771L11.706 13.9302C8.83346 15.5491 6.7165 18.3511 6.01884 21.6762C5.90574 22.2147 6.31609 22.7216 6.86641 22.7216ZM23.7051 17.8734C23.7051 18.415 23.266 18.8541 22.7243 18.8541C22.1827 18.8541 21.7436 18.4151 21.7436 17.8734C21.7436 17.3318 22.1826 16.8926 22.7243 16.8926C23.266 16.8926 23.7051 17.3318 23.7051 17.8734ZM12.0991 16.8926C12.6407 16.8926 13.0799 17.3317 13.0799 17.8734C13.0799 18.415 12.6408 18.8541 12.0991 18.8541C11.5575 18.8541 11.1184 18.4151 11.1184 17.8734C11.1184 17.3318 11.5574 16.8926 12.0991 16.8926Z"
      fill={colorFill}
    />
  </svg>
);

export default SvgAndroid;
