import * as React from "react";
import MaterialTable from "material-table";
import AppsPermissionsListItem from "Components/Permission/AppsPermissions/apps-permissions-list-item";
import { IAppsPermissionsListProps } from "Components/Permission/interfaces-permission";

export default function AppsPermissionsList(props: IAppsPermissionsListProps) {
    return (
        <MaterialTable
            columns={[]}
            data={props.appsList}
            options={{
                showTitle: false,
                header: false,
                paging: false,
                search: false,
                toolbar: false
            }}
            components={{
                Row: propsRow => (
                    <AppsPermissionsListItem
                        item={propsRow.data}
                        client={props.client}
                        getAppsWithPermissions={props.getAppsWithPermissions}
                        defaultTab={props.defaultTab}
                    />
                )
            }}
            style={{
                background: 'transparent',
                boxShadow: 'none'
            }}
        />
    )
}