import Colors from 'Common/colors';
import { makeStyles, Theme } from '@material-ui/core';

export const stylesCustomerListItem = makeStyles((theme: Theme) => ({
  headerTitle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "48px",
    color: Colors.grayDarker,
    borderBottom: "none",
  },
  guid: {
    paddingLeft: "40px",
    borderLeft: `1px solid ${Colors.graySeparate}`,
    height: "50px",
  },
  pinLength: {
    borderLeft: `1px solid ${Colors.graySeparate}`,
    height: "50px",
    paddingLeft: "40px",
  },
  gridItem: {
    borderRight: `1px solid ${Colors.graySeparate}`,
    marginRight: "40px",
    height: "50px",
  },
  paper: {
    padding: "11px 30px",
    marginBottom: "15px",
  },
  cell: {
    border: `1px solid ${theme.palette.graySeparate.main}`,
    padding: "8px",
    background: Colors.white,
  },
  cellActions: {
    textAlign: "center",
    verticalAlign: "top",
  },
}));

export const stylesCustomerListHeader = makeStyles({
  paper: {
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: "15px",
  },
  guid: {
    paddingLeft: "40px",
  },
  pinLength: {
    paddingLeft: "40px",
  },
});
