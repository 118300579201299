import { AppCheckRespondingTypeEnum } from "Common/enum-app";
import { ApiResultBaseType } from "Types/api-types";
import { axiosInstance } from "../base-api";
import { IReleaseApi } from "./interfaces";

export const useReleaseApi: () => IReleaseApi = (): IReleaseApi => {
  const checkAppIsResponding: (
    releaseId: number,
    checkRespondingType: AppCheckRespondingTypeEnum
  ) => Promise<ApiResultBaseType> = async (
    releaseId: number,
    checkRespondingType: AppCheckRespondingTypeEnum
  ): Promise<ApiResultBaseType> => {
    return await axiosInstance
      .get(
        `/Release/CheckAppIsResponding?releaseId=${releaseId}&checkRespondingType=${checkRespondingType}`
      )
      .then((response) => response.data);
  };
  return { checkAppIsResponding };
};
