import * as React from "react";
import * as Yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@material-ui/core";
import { IWebAppReleaseEditorProps } from "Components/WebApp/interfaces-web-app";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { AppPlatformEnum, FormEditorEnum, IconType } from "Common/enum";
import { Formik } from "formik";
import ButtonOutlined from "Components/Buttons/button-outlined";
import ButtonEdit from "Components/Buttons/ButtonEdit/button-edit";
import SvgPlusCircle from "Components/Images/image-plus-circle";
import moment from "moment";
import ClientAppApi from "Services/Api/client-app-api";
import { WebAppInstanceDeploymentType } from "Types/app-types";
import { AxiosError } from "axios";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import { useReleaseApi } from "Services/Api/ReleaseApi/release-api";
import useModalError from "Hooks/use-modal-error";
import { SweetAlertResult } from "sweetalert2";
import useModalSuccess from "Hooks/use-modal-success";
import { AppCheckRespondingTypeEnum } from "Common/enum-app";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import DateField from "Components/Inputs/DateField/date-field";

const RELEASE_NO_MAX_LENGTH: number = 20;
const RELEASE_NOTES_MAX_LENGTH: number = 4000;
const RELEASE_API_URL_SUFFIX_MAX_LENGTH: number = 50;

const clientAppApi = new ClientAppApi();

export default function WebAppReleaseEditor(props: IWebAppReleaseEditorProps) {
  const { checkAppIsResponding } = useReleaseApi();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [webAppInstanceDeployment, setWebAppInstanceDeplyment] =
    React.useState<WebAppInstanceDeploymentType>();
  const showModalError: (
    errorResult: string,
    isHtml?: boolean
  ) => Promise<SweetAlertResult> = useModalError();
  const showModalSuccess: (successResult: string) => Promise<SweetAlertResult> =
    useModalSuccess();

  const getWebAppInstanceDeployment = async (): Promise<void> => {
    if (props.webAppInstanceDeploymentId) {
      await clientAppApi
        .getWebAppInstanceDeployment(props.webAppInstanceDeploymentId)
        .then((result) => {
          if (result && result.data) {
            setWebAppInstanceDeplyment(result.data);
          }
        });
    }
  };

  const handleOpen = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    await getWebAppInstanceDeployment();
    setIsOpen(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setIsOpen(false);
  };

  const handleCheckWS = async (
    checkRespondingType: AppCheckRespondingTypeEnum
  ): Promise<void> => {
    if (props.webAppInstanceDeploymentId) {
      await checkAppIsResponding(
        props.webAppInstanceDeploymentId,
        checkRespondingType
      )
        .then((r) => {
          showModalSuccess("Service working properly");
        })
        .catch((error) => {
          showModalError(error.response.data.statusMessage);
        });
    }
  };

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const handleSave = async (
    instanceDeployment: WebAppInstanceDeploymentType
  ): Promise<void> => {
    await clientAppApi
      .saveWebAppInstanceDeployment(instanceDeployment)
      .then(async (result) => {
        if (result && result.data) {
          await props.getWebAppInstanceDeploymentList(
            props.deploymentItemCount ?? 1
          );
        }
        setIsOpen(false);
      })
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  let headerText = "New release";
  let button = (
    <ButtonOutlined
      text="New"
      iconStartType={IconType.SvgImageIcon}
      iconStart={<SvgPlusCircle width="14px" />}
      size={props.buttonSize ?? "sm"}
      margin="0 0 0 10px"
      handleClick={handleOpen}
    />
  );

  if (props.editorType === FormEditorEnum.Edit) {
    headerText = "Edit release";
    button = <ButtonEdit iconWidth="25px" handleClick={handleOpen} />;
  }

  return (
    <React.Fragment>
      {button}
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          <TypographySubtitle text={headerText} />
        </DialogTitle>
        <Formik
          initialValues={{
            id: props.webAppInstanceDeploymentId,
            iaWebAppInstanceID: props.webAppInstanceId,
            releaseNo: webAppInstanceDeployment
              ? webAppInstanceDeployment.releaseNo
              : "",
            releaseDate: webAppInstanceDeployment?.releaseDate
              ? moment(webAppInstanceDeployment.releaseDate).format()
              : moment().format(),
            apiVersionNo: webAppInstanceDeployment?.apiVersionNo
              ? webAppInstanceDeployment.apiVersionNo
              : undefined,
            releaseNotes: webAppInstanceDeployment?.releaseNotes
              ? webAppInstanceDeployment.releaseNotes
              : "",
            apiUrlSuffix: webAppInstanceDeployment?.apiUrlSuffix
              ? webAppInstanceDeployment.apiUrlSuffix
              : null,
            apiCheckMethodPath: webAppInstanceDeployment?.apiCheckMethodPath,
          }}
          onSubmit={async (values) => {
            values.releaseDate = moment(values.releaseDate).format();
            await handleSave(values);
          }}
          validationSchema={Yup.object().shape<WebAppInstanceDeploymentType>({
            releaseNo: Yup.string()
              .label("Release number")
              .required("Required"),
            releaseDate: Yup.string()
              .label("Release date")
              .required("Required")
              .nullable(),
            apiVersionNo: Yup.number(),
            releaseNotes: Yup.string(),
            apiUrlSuffix: Yup.string().nullable(),
            apiCheckMethodPath: Yup.string().max(100).nullable(),
          })}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = formProps;
            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <Grid container item sm={12} spacing={3}>
                    <Grid item sm={6}>
                      <TextFieldEntered
                        id="releaseNo"
                        name="releaseNo"
                        label="Release number"
                        value={values.releaseNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.releaseNo && touched.releaseNo)}
                        helperText={
                          errors.releaseNo &&
                          touched.releaseNo &&
                          errors.releaseNo
                        }
                        inputProps={{
                          maxLength: RELEASE_NO_MAX_LENGTH,
                        }}
                        required
                      />
                    </Grid>

                    <Grid item sm={6}>
                      <DateField
                        id="releaseDate"
                        name="releaseDate"
                        label="Release date"
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        value={values.releaseDate}
                        onChange={(date) => setFieldValue("releaseDate", date)}
                        onBlur={handleBlur}
                        error={!!(errors.releaseDate && touched.releaseDate)}
                        helperText={
                          errors.releaseDate &&
                          touched.releaseDate &&
                          errors.releaseDate
                        }
                        autoOk={true}
                        required
                      />
                    </Grid>

                    {props.platformType === AppPlatformEnum.WebService ? (
                      <>
                        <Grid item sm={6}>
                          <TextFieldEntered
                            id="apiVersionNo"
                            name="apiVersionNo"
                            label="Api version"
                            type="number"
                            value={values.apiVersionNo || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              !!(errors.apiVersionNo && touched.apiVersionNo)
                            }
                            helperText={
                              errors.apiVersionNo &&
                              touched.apiVersionNo &&
                              errors.apiVersionNo
                            }
                          />
                        </Grid>

                        <Grid item sm={6}>
                          <TextFieldEntered
                            id="apiUrlSuffix"
                            name="apiUrlSuffix"
                            label="Api url suffix"
                            value={values.apiUrlSuffix}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              !!(errors.apiUrlSuffix && touched.apiUrlSuffix)
                            }
                            helperText={
                              (errors.apiUrlSuffix &&
                                touched.apiUrlSuffix &&
                                errors.apiUrlSuffix) ||
                              `${
                                values.apiUrlSuffix
                                  ? values.apiUrlSuffix.length
                                  : 0
                              }/${RELEASE_API_URL_SUFFIX_MAX_LENGTH}`
                            }
                            inputProps={{
                              maxLength: RELEASE_API_URL_SUFFIX_MAX_LENGTH,
                            }}
                          />
                        </Grid>

                        <Grid item sm={6}>
                          <TextFieldEntered
                            id="apiCheckMethodPath"
                            name="apiCheckMethodPath"
                            label="Api check method path"
                            value={values.apiCheckMethodPath}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              !!(
                                errors.apiCheckMethodPath &&
                                touched.apiCheckMethodPath
                              )
                            }
                            helperText={
                              (errors.apiCheckMethodPath &&
                                touched.apiCheckMethodPath &&
                                errors.apiCheckMethodPath) ||
                              `${
                                values.apiCheckMethodPath
                                  ? values.apiCheckMethodPath.length
                                  : 0
                              }/100`
                            }
                            inputProps={{
                              maxLength: 100,
                            }}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <ButtonSubaction
                            text="Check WS"
                            handleClick={() =>
                              handleCheckWS(AppCheckRespondingTypeEnum.Base)
                            }
                          />
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                    <Grid item sm={6}>
                      <TextFieldEntered
                        id="releaseNotes"
                        name="releaseNotes"
                        label="Release notes"
                        value={values.releaseNotes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.releaseNotes && touched.releaseNotes)}
                        helperText={
                          (errors.releaseNotes &&
                            touched.releaseNotes &&
                            errors.releaseNotes) ||
                          `${values.releaseNotes.length}/${RELEASE_NOTES_MAX_LENGTH}`
                        }
                        inputProps={{
                          maxLength: RELEASE_NOTES_MAX_LENGTH,
                        }}
                        multiline
                      />
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions>
                  <ButtonSubaction text="Cancel" handleClick={handleClose} />
                  <ButtonAction text="Save" isSubmit />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </React.Fragment>
  );
}
