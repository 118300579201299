import * as React from "react";
import { Formik } from "formik";
import { ApiResultStatusEnum, IconType, FormEditorEnum } from "Common/enum";
import UserApi from "Services/Api/user-api";
import { IUserEditorProps } from "Components/User/interfaces-user";
import { SaveUserModelType } from "Types/user-types";
import { SaveUserApiResultType } from "Types/api-types";
import ButtonOutlined from "Components/Buttons/button-outlined";
import SvgPlusCircle from "Components/Images/image-plus-circle";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import ButtonEdit from "Components/Buttons/ButtonEdit/button-edit";
import { AxiosError } from "axios";
import { initialValues, validationSchema } from "./formikValues";
import {
  EMAIL_MAX_LENGTH,
  NAME_MAX_LENGTH,
  PASSWORD_MAX_LENGTH,
} from "Common/consts";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";

const UserEditor: (props: IUserEditorProps) => JSX.Element = (
  props: IUserEditorProps
): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<
    AxiosError | string | null
  >(null);
  const [isExecuting, setIsExecuting] = React.useState(false);

  const userApi = new UserApi();

  const handleErrorDialogOkClick = (): void => {
    setIsErrorDialogOpen(false);
  };

  const handleOpen = async (): Promise<void> => {
    setIsOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const handleSubmit = (user: SaveUserModelType) => {
    setIsExecuting(true);

    saveUser(user)
      .then(() => {})
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      })
      .finally(() => {
        setIsExecuting(false);
      });
  };

  const saveUser = (
    user: SaveUserModelType
  ): Promise<SaveUserApiResultType | void> => {
    return userApi.saveUser(user).then((result) => {
      if (result && result.status === ApiResultStatusEnum.Ok) {
        if (props.setUserData) {
          props.setUserData(user, [] /*TODO*/);
        }
        if (props.getUsers) {
          props.getUsers();
        }
        setIsOpen(false);
      } else {
        setErrorResponse("Error while save user");
        setIsErrorDialogOpen(true);
      }
    });
  };

  let headerText = "New user";
  let buttonText =
    props.editorType === FormEditorEnum.Create ? "Add user" : "Edit";
  let button = (
    <ButtonOutlined
      text={buttonText}
      iconStartType={IconType.SvgImageIcon}
      iconStart={<SvgPlusCircle width={"14px"} />}
      size={"md"}
      margin="0 10px 0 0"
      handleClick={handleOpen}
    />
  );

  if (props.editorType === FormEditorEnum.Edit) {
    headerText = "Edit user";
    button = <ButtonEdit iconWidth="25px" handleClick={handleOpen} />;
  }

  return (
    <>
      {button}
      <Dialog open={isOpen} onEscapeKeyDown={handleClose} maxWidth="xl">
        <DialogTitle>
          <TypographySubtitle text={headerText} />
        </DialogTitle>

        <Formik
          enableReinitialize={true}
          initialValues={initialValues(props)}
          onSubmit={async (values) => {
            await handleSubmit(values);
          }}
          validationSchema={validationSchema(props)}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = formProps;
            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <TextFieldEntered
                        id="firstName"
                        name="firstName"
                        label="First name"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.firstName && touched.firstName)}
                        helperText={
                          (errors.firstName &&
                            touched.firstName &&
                            errors.firstName) ||
                          `${values.firstName.length}/${NAME_MAX_LENGTH}`
                        }
                        inputProps={{
                          maxLength: NAME_MAX_LENGTH,
                        }}
                        required
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextFieldEntered
                        id="lastName"
                        name="lastName"
                        label="Last name"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.lastName && touched.lastName)}
                        helperText={
                          (errors.lastName &&
                            touched.lastName &&
                            errors.lastName) ||
                          `${values.lastName.length}/${NAME_MAX_LENGTH}`
                        }
                        inputProps={{
                          maxLength: NAME_MAX_LENGTH,
                        }}
                        required
                      />
                    </Grid>
                    {props.systemAppSelected && (
                      <>
                        <Grid item sm={6}>
                          <TextFieldEntered
                            id="email"
                            name="email"
                            label="Email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!(errors.email && touched.email)}
                            helperText={
                              (errors.email && touched.email && errors.email) ||
                              `${values.email.length}/${EMAIL_MAX_LENGTH}`
                            }
                            inputProps={{
                              maxLength: EMAIL_MAX_LENGTH,
                            }}
                            required
                          />
                        </Grid>
                        {props.editorType === FormEditorEnum.Create && (
                          <Grid item sm={6}>
                            <TextFieldEntered
                              id="password"
                              name="password"
                              label="Password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={!!(errors.password && touched.password)}
                              helperText={
                                (errors.password &&
                                  touched.password &&
                                  errors.password) ||
                                `${values.password.length}/${PASSWORD_MAX_LENGTH}`
                              }
                              inputProps={{
                                maxLength: PASSWORD_MAX_LENGTH,
                              }}
                              required
                              type="password"
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ButtonSubaction text="Cancel" handleClick={handleClose} />
                  <ButtonAction
                    text="Save"
                    isSubmit
                    isExecuting={isExecuting}
                  />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </>
  );
};

export default UserEditor;
