import { makeStyles, Theme } from "@material-ui/core";
import {
  KeyboardDatePickerProps,
  KeyboardDateTimePickerProps,
} from "@material-ui/pickers";

export const stylesDateField: (
  props: KeyboardDatePickerProps | KeyboardDateTimePickerProps
) => Record<string, string> = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& .MuiInputLabel-root": {
      color: theme.palette.secondary.main,
      fontSize: "14px",
    },
    "& .MuiInputBase-root": {
      color: theme.palette.primary.main,
      fontSize: "14px",
    },
    "& .MuiInputBase-input": {
      padding: "6px 0px",
    },
  },
}));

export const stylesSearchField: () => Record<string, string> = makeStyles(
  (theme: Theme) => ({
    root: {
      margin: "16px 0 0 0",
    },
    inputAdornment: {
      color: theme.palette.primary.main,
      padding: "4.5px 0px 4.5px 3px",
    },
  })
);
