import * as React from "react";
import { ReportColumnIsVisible } from "../../../../Common/enum";
import InputCheckbox from "../../../Inputs/input-checkbox";
import { GetReportColumnListType } from "../../../../Types/report-types";

export interface CheckboxMasterProps {
  isEditable: boolean;

  data: GetReportColumnListType;
  list: GetReportColumnListType[];
  handleChangeColumns: (columns: GetReportColumnListType[]) => void;
}

const CheckboxMaster: React.FC<CheckboxMasterProps> = ({
  data,
  isEditable,
  list,
  handleChangeColumns,
}) => {
  const handleChangeMaster = (
    event: React.ChangeEvent<HTMLInputElement>,
    columnID: number
  ): void => {
    const isMaster: boolean = event.target.checked as boolean;

    handleChangeColumns(
      list.map((p) =>
        p.columnID === columnID
          ? {
              ...p,
              isMaster: isMaster,
            }
          : {
              ...p,
              isMaster: false,
            }
      )
    );
  };

  return (
    <InputCheckbox
      disabled={!isEditable}
      checked={data.isMaster === true}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleChangeMaster(event, data.columnID)
      }
    />
  );
};

export default CheckboxMaster;