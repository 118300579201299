import * as React from 'react';
import ButtonEdit from 'Components/Buttons/ButtonEdit/button-edit';
import Colors from 'Common/colors';
import TypographyInfo from 'Components/Typography/typography-info';
import UserLoginDelete from 'Components/User/UserLoginDelete/user-login-delete';
import { GetUserLoginListResponseType } from 'Types/user-types';
import { Grid } from '@material-ui/core';
import { stylesUserLoginListItem } from './styles';

export interface UserLoginListItemHeaderProps {
  userLogin: GetUserLoginListResponseType;
  handleOpenUserLogin: (userLogin: GetUserLoginListResponseType) => void;
  handleDeleteUserLogin: (userLoginID: number | null) => void;
}

export const UserLoginListItemHeader: React.FC<UserLoginListItemHeaderProps> =
  ({ userLogin, handleDeleteUserLogin, handleOpenUserLogin }) => {
    const customStyles = stylesUserLoginListItem();

    const handleEditToggle = async (
      event: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
      handleOpenUserLogin(userLogin);
    };

    return (
      <Grid container alignItems="center">
        <Grid container item xs={4}>
          <Grid
            container
            item
            xs={12}
            direction="column"
            className={customStyles.gridItem}
            justify="center"
          >
            <TypographyInfo
              text={userLogin.phoneNumber}
              color={Colors.black}
              margin="0px"
            />
            <TypographyInfo
              margin="0px"
              text={userLogin.email}
              color={Colors.black}
            />
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          {userLogin.apps.map((app) => {
            return (
              <TypographyInfo
                key={app.appGuid}
                text={app.appName}
                color={Colors.grayDark}
                style={{ marginRight: "15px", fontSize: "12px" }}
              />
            );
          })}
        </Grid>
        <Grid container item xs={2}>
          <Grid container item xs={12} alignItems="center" justify="flex-end">
            <ButtonEdit iconWidth="25px" handleClick={handleEditToggle} />
            <UserLoginDelete
              userLoginID={userLogin.userLoginID}
              handleDeleteUserLogin={handleDeleteUserLogin}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

export default UserLoginListItemHeader;
