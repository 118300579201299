import { axiosInstance } from 'Services/Api/base-api';
import {
  UserLoginApiResultType,
  UserRefreshTokenApiResultType,
  UserLogoutApiResultType,
  GetUserListApiResultType,
  ForgottenUserPasswordApiResultType,
  ResetUserPasswordApiResultType,
  VerifyUserResetPasswordCodeApiResultType,
  SaveUserApiResultType,
  DeleteUserApiResultType,
  DeleteUserApplicationConnApiResultType,
  SaveUserLoginApiResultType,
  GetUserLoginListApiResultType,
  GetUserDetailsApiResultType,
  SendUserLoginResetCodeApiResultType,
  GetAllClientUsersApiResultType,
  SetUserActiveApiResultType,
  GeneratePinCodeApiResultType,
} from 'Types/api-types';
import {
  UserLoginModelType,
  UserLogoutModelType,
  ForgottenUserPasswordModelType,
  VerifyUserResetPasswordCodeModelType,
  ResetUserPasswordModelType,
  SaveUserModelType,
  DeleteUserModelType,
  DeleteUserApplicationConnModelType,
  SaveUserLoginModelType,
  DeleteUserLoginModelType,
  SendUserLoginResetCodeModelType,
  SetUserActiveModelType,
  GeneratePinCodeModelType,
} from 'Types/user-types';
import AuthorizationHelper from 'Configuration/authorization-helper';

export default class UserApi {
  login = (userLogin: UserLoginModelType): Promise<UserLoginApiResultType> => {
    return axiosInstance
      .post('/User/Login', JSON.stringify(userLogin))
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  refreshToken = (token: string): Promise<UserRefreshTokenApiResultType> => {
    return axiosInstance
      .post('/User/RefreshToken?token=' + token)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  logout = (request: UserLogoutModelType): Promise<UserLogoutApiResultType> => {
    return axiosInstance
      .post('/User/Logout', JSON.stringify(request), {
        headers: AuthorizationHelper.authHeader(),
      })
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  forgottenUserPassword = (
    request: ForgottenUserPasswordModelType
  ): Promise<ForgottenUserPasswordApiResultType> => {
    return axiosInstance
      .post('/User/ForgottenUserPassword', JSON.stringify(request))
      .then((response) => response.data);
  };

  verifyUserResetPasswordCode = (
    request: VerifyUserResetPasswordCodeModelType
  ): Promise<VerifyUserResetPasswordCodeApiResultType> => {
    return axiosInstance
      .post('/User/VerifyUserResetPasswordCode', JSON.stringify(request))
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  resetUserPassword = (
    request: ResetUserPasswordModelType
  ): Promise<ResetUserPasswordApiResultType> => {
    return axiosInstance
      .post('/User/ResetUserPassword', JSON.stringify(request))
      .then((response) => response.data);
  };

  getUserList = async (
    clientID: number,
    appGuid: string,
    searchText?: string,
    companyID?: number
  ): Promise<GetUserListApiResultType> => {
    return await axiosInstance
      .get(
        '/User/GetList?clientID=' +
          clientID +
          '&appGuid=' +
          appGuid +
          '&searchText=' +
          (searchText ? searchText : '') +
          '&companyID=' +
          (companyID ? companyID : '')
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  saveUser = async (
    user: SaveUserModelType
  ): Promise<SaveUserApiResultType> => {
    return await axiosInstance
      .post('/User/SaveUser', JSON.stringify(user))
      .then((response) => response.data);
  };

  deleteUser = async (
    user: DeleteUserModelType
  ): Promise<DeleteUserApiResultType> => {
    return await axiosInstance
      .post('/User/DeleteUser', JSON.stringify(user))
      .then((response) => response.data);
  };

  deleteUserApplicationConn = async (
    user: DeleteUserApplicationConnModelType
  ): Promise<DeleteUserApplicationConnApiResultType> => {
    return await axiosInstance
      .post('/User/DeleteUserApplicationConn', JSON.stringify(user))
      .then((response) => response.data);
  };

  saveUserLogin = async (
    userLogin: SaveUserLoginModelType
  ): Promise<SaveUserLoginApiResultType> => {
    return await axiosInstance
      .post('/User/SaveUserLogin', JSON.stringify(userLogin))
      .then((response) => response.data);
  };

  getUserLoginList = async (
    userID: number,
    appGuid: string
  ): Promise<GetUserLoginListApiResultType> => {
    return await axiosInstance
      .get('/User/GetUserLogins?userID=' + userID + '&appGuid=' + appGuid)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getUserDetails = async (
    userID: number,
    appGuid: string
  ): Promise<GetUserDetailsApiResultType> => {
    return await axiosInstance
      .get('/User/GetUserDetails?userID=' + userID + '&appGuid=' + appGuid)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  deleteUserLogin = async (
    userLogin: DeleteUserLoginModelType
  ): Promise<GetUserDetailsApiResultType> => {
    return await axiosInstance
      .post('/User/DeleteUserLogin', JSON.stringify(userLogin))
      .then((response) => response.data);
  };

  sendUserLoginResetCode = async (
    sendUserLoginResetCode: SendUserLoginResetCodeModelType
  ): Promise<SendUserLoginResetCodeApiResultType> => {
    return await axiosInstance
      .post(
        '/User/SendUserLoginResetCode',
        JSON.stringify(sendUserLoginResetCode)
      )
      .then((response) => response.data);
  };

  getAllClientUsers = async (
    clientID: number | null
  ): Promise<GetAllClientUsersApiResultType> => {
    return await axiosInstance
      .get(
        '/User/GetAllClientUsers?clientID=' +
          (clientID === null ? '' : clientID)
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  setUserActive = async (
    request: SetUserActiveModelType
  ): Promise<SetUserActiveApiResultType> => {
    return await axiosInstance
      .post('/User/SetUserActive', JSON.stringify(request))
      .then((response) => response.data);
  };

  generatePinCode = async (
    request: GeneratePinCodeModelType
  ): Promise<GeneratePinCodeApiResultType> => {
    return await axiosInstance
      .post('/User/GeneratePinCode', JSON.stringify(request))
      .then((response) => response.data);
  };
}
