import * as React from 'react';
import CustomerLocationList from './customer-location-list';
import CustomerLocationsFilters from './customer-locations-filter';
import { CustomerLocationsListType } from 'Types/customer-types';
import { filter } from './utils';

export interface ICustomerLocationsContainerProps {
  locationList: CustomerLocationsListType[];
}

const CustomerLocationsContainer: (
  props: ICustomerLocationsContainerProps
) => JSX.Element = (props: ICustomerLocationsContainerProps): JSX.Element => {
  const [searchValue, setSearchValue] = React.useState("");

  const [filteredCustomerLocationList, setFilteredCustomerLocationList] =
    React.useState<CustomerLocationsListType[]>([]);

  React.useEffect(() => {
    const customerLocationList: CustomerLocationsListType[] = filter(
      props.locationList,
      searchValue
    );

    setFilteredCustomerLocationList(customerLocationList);
  }, [props.locationList, searchValue]);

  const handleSearchValueChange = (
    value: React.SetStateAction<string>
  ): void => {
    const searchedText: string = value.toString().toLowerCase();
    setSearchValue(searchedText ?? "");
  };

  return (
    <>
      <div style={{ marginTop: 15 }}>
        <CustomerLocationsFilters onSearchChange={handleSearchValueChange} />
      </div>
      <CustomerLocationList locationList={filteredCustomerLocationList ?? []} />
    </>
  );
};

export default CustomerLocationsContainer;
