import * as React from "react";
import AddNewReportNotPowerBi from "./add-new-report-not-power-bi";
import AddReportBasicInfo from "./add-report-basic-info";
import AddReportCopyReport from "./add-report-copy-report";
import AddReportImportReport from "./add-report-import-report";
import AddReportPowerBi from "./add-report-power-bi";
import AddReportTypeNone from "./add-report-type-none";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonTransparent from "Components/Buttons/button-transparent";
import ProgressSpinner from "Components/ProgressSpinner/progress-spinner";
import ReportApi from "Services/Api/report-api";
import SvgPlusCircle from "Components/Images/image-plus-circle";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { AddReportType, initValues, validationSchema } from "./types";
import { Formik } from "formik";
import { IAddReportProps } from "Components/Reports/interfaces-reports";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import {
  ApiResultStatusEnum,
  ExistingReportStrategyType,
  IconType,
  ReportSubtypeEnum,
  ReportTypeEnum,
} from "Common/enum";
import ConfirmDialog, {
  useConfirmDialog,
} from "Components/Dialogs/ConfirmDialog/confirm-dialog";
import ErrorDialog, {
  useErrorDialog,
} from "Components/Dialogs/ErrorDialog/error-dialog";
import {
  AddReportApiResultType,
  CopyReportApiResultType,
  ImportReportsFileApiResultType,
} from "Types/api-types";
import {
  AddReportModelType,
  CopyReportModelType,
  GetPowerBIReportListApiResponseType,
  GetReportListApiResponseType,
  ImportReportsFileModelType,
} from "Types/report-types";

const reportApi: ReportApi = new ReportApi();

export default function AddReport(props: IAddReportProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedInstanceID, setSelectedInstanceID] = React.useState<number>();
  const {
    isOpenErrorDialog,
    textErrorDialog,
    openErrorDialog,
    closeErrorDialog,
  } = useErrorDialog();
  const [isBlockedUi, setIsBlockedUi] = React.useState(false);
  const [isBtnSaveVisible, setIsBtnSaveVisible] = React.useState(false);
  const [addReportType, setAddReportType] = React.useState<AddReportType>(
    AddReportType.None
  );
  const [selectedOldReportID, setSelectedOldReportID] =
    React.useState<number>();
  const { confirmDialogData, openConfirmDialog, closeConfirmDialog } =
    useConfirmDialog();
  const [selectedReportType, setSelectedReportType] =
    React.useState<ReportTypeEnum>(ReportTypeEnum.Report);
  const [selectedReportSubtype, setSelectedReportSubtype] =
    React.useState<ReportSubtypeEnum>(ReportSubtypeEnum.Regular);
  const [powerBiReports, setPowerBiReports] = React.useState<
    GetPowerBIReportListApiResponseType[]
  >([]);
  const [selectedPowerBIReportId, setSelectedPowerBIReportId] =
    React.useState<string>("");
  const [reports, setReports] = React.useState<GetReportListApiResponseType[]>(
    []
  );

  React.useEffect(() => {
    if (isOpen && props.applications?.length) {
      setSelectedInstanceID(
        props.applications.find((a) => a.appID === props.selectedAppID)
          ?.instances?.[0].instanceID
      );
    }
  }, [props.applications, isOpen, props.selectedAppID]);

  React.useEffect(() => {
    if (isOpen) {
      setSelectedOldReportID(0);
      if (reports.length === 0 && props.client?.id && props.client.id > 0) {
        reportApi.getReportList(props.client.id).then((result) => {
          if (result) {
            setReports(result.data);
          }
        });
      }
    }
  }, [isOpen]);

  React.useEffect(() => {
    setPowerBiReports([]);
  }, [props.selectedAppID]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAddReportType(AddReportType.None);
    setIsBtnSaveVisible(false);
    setIsOpen(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setIsOpen(false);
  };

  const handleNewReport = () => {
    setAddReportType(AddReportType.NewReport);
    setIsBtnSaveVisible(true);

    if (
      selectedReportType === ReportTypeEnum.PowerBI &&
      !powerBiReports.length
    ) {
      getPowerBiReportList(props.selectedAppID);
    }
  };

  const getPowerBiReportList = (selectedAppId: number) => {
    setIsBlockedUi(true);
    reportApi
      .getPowerBiReports(selectedAppId)
      .then((resp) => {
        const list = resp?.data;
        if (list) {
          setPowerBiReports(list);
          if (list.length > 0) {
            setSelectedPowerBIReportId(list[0]?.reportId ?? 0);
          } else {
            openErrorDialog("All PowerBI reports are already registered");
            setSelectedReportType(ReportTypeEnum.Report);
            setAddReportType(AddReportType.None);
          }
        } else {
          openErrorDialog("Error get power bi reports");
        }
      })
      .catch((error) => {
        console.log(error);
        openErrorDialog(error);
      })
      .finally(() => {
        setIsBlockedUi(false);
      });
  };

  const handleSave = async (
    reportName: string,
    reportDescription: string,
    storedProcedureName: string,
    detailsStoredProcedureName: string | undefined,
    summaryStoredProcedureName: string | undefined,
    powerBIReportId: string,
    file?: Blob
  ): Promise<void> => {
    if (props.client && props.client.id) {
      switch (addReportType) {
        case AddReportType.NewReport:
          if (selectedInstanceID && props.client) {
            setIsBlockedUi(true);
            let addReportModel: AddReportModelType = {
              clientID: props.client.id,
              appID: props.selectedAppID,
              sourceInstanceID: selectedInstanceID,
              procedureName: storedProcedureName,
              detailsProcedureName: detailsStoredProcedureName,
              summaryProcedureName: summaryStoredProcedureName,
              reportName: reportName,
              reportDescription: reportDescription,
              powerBIReportId: powerBIReportId,
              reportType: selectedReportType,
              reportSubtype: selectedReportSubtype,
            };
            await newReport(addReportModel)
              .then(() => {
                setIsOpen(false);
                if (selectedReportType === ReportTypeEnum.PowerBI) {
                  setPowerBiReports((pb) =>
                    pb.filter((r) => r.reportId !== powerBIReportId)
                  );
                }
              })
              .catch((error) => {
                console.error(error);
                openErrorDialog(error);
              })
              .finally(() => {
                setIsBlockedUi(false);
              });
          }
          break;
        case AddReportType.CopyReport:
          if (selectedInstanceID && selectedOldReportID && props.client) {
            setIsBlockedUi(true);
            let copyReportModel: CopyReportModelType = {
              clientID: props.client.id,
              appID: props.selectedAppID,
              sourceInstanceID: selectedInstanceID,
              oldReportID: selectedOldReportID,
              reportName: reportName,
              reportDescription: reportDescription,
            };
            await copyReport(copyReportModel)
              .then(() => {
                setIsOpen(false);
              })
              .catch((error) => {
                console.error(error);
                openErrorDialog(error);
              })
              .finally(() => {
                setIsBlockedUi(false);
              });
          }
          break;
        case AddReportType.ImportReport:
          if (selectedInstanceID && props.client && file) {
            setIsBlockedUi(true);
            let importReportsFileModel: ImportReportsFileModelType = {
              clientID: props.client.id,
              appID: props.selectedAppID,
              sourceInstanceID: selectedInstanceID,
              existingReportStrategyType:
                ExistingReportStrategyType.CheckForExisting,
              reportName: reportName,
              reportDescription: reportDescription,
              file: file,
            };
            await importReportsFile(importReportsFileModel)
              .then(() => {
                setIsOpen(false);
              })
              .catch((error) => {
                console.error(error);
                openErrorDialog(error);
              })
              .finally(() => {
                setIsBlockedUi(false);
              });
          }
          break;
      }
    }
  };

  const importReportsFile = async (
    importReportsFileModel: ImportReportsFileModelType
  ): Promise<ImportReportsFileApiResultType | void> => {
    return await reportApi
      .importReportsFile(importReportsFileModel)
      .then(async (uploadResult) => {
        if (uploadResult.status === ApiResultStatusEnum.Ok) {
          if (uploadResult.data.isSaved) {
            props.handleRefreshReports();
          } else if (uploadResult.data.existingReportFound) {
            openConfirmDialog({
              text: `Existing report found. Update existing one or add new report?`,
              textOption1: "Update",
              textOption2: "Add new",
              handleOption1Click: () => {
                importReportsFileModel.existingReportStrategyType =
                  ExistingReportStrategyType.Update;

                setIsBlockedUi(true);
                importReportsFile(importReportsFileModel)
                  .then(() => {})
                  .catch((error) => {
                    console.error(error);
                    openErrorDialog(error);
                  })
                  .finally(() => {
                    setIsBlockedUi(false);
                    closeConfirmDialog();
                  });
              },
              handleOption2Click: () => {
                importReportsFileModel.existingReportStrategyType =
                  ExistingReportStrategyType.AddNew;

                setIsBlockedUi(true);
                importReportsFile(importReportsFileModel)
                  .then(() => {})
                  .catch((error) => {
                    console.error(error);
                    openErrorDialog(error);
                  })
                  .finally(() => {
                    setIsBlockedUi(false);
                    closeConfirmDialog();
                  });
              },
              handleCancelClick: () => {
                closeConfirmDialog();
              },
            });
          }
        } else {
          const error: any = {
            message: "Error while importing report",
          };
          openErrorDialog(error);
        }
      });
  };

  const newReport = async (
    addReport: AddReportModelType
  ): Promise<AddReportApiResultType | void> => {
    return await reportApi.addReport(addReport).then(async () => {
      props.handleRefreshReports();
    });
  };

  const copyReport = async (
    copyReport: CopyReportModelType
  ): Promise<CopyReportApiResultType | void> => {
    return await reportApi.copyReport(copyReport).then(async () => {
      props.handleRefreshReports();
    });
  };
  const handlePowerBiReportChange = (
    _event: React.ChangeEvent<{}>,
    value: GetPowerBIReportListApiResponseType | null,
    setFieldValue: any
  ) => {
    setSelectedPowerBIReportId(value?.reportId ?? "");
    setFieldValue("powerBIReportId", value?.reportId ?? "");
  };

  return (
    <React.Fragment>
      <span style={{ height: "30px" }}>
        <ButtonTransparent
          text="Add report"
          handleClick={handleOpen}
          padding="5px 2px 5px 10px"
          iconStart={<SvgPlusCircle width="20px" />}
          iconStartType={IconType.SvgImageIcon}
        />
      </span>

      <Dialog open={isOpen} maxWidth={"sm"} fullWidth onClose={handleClose}>
        <ProgressSpinner isDisplayed={isBlockedUi}>
          <DialogTitle>
            <TypographySubtitle text="Add report" />
          </DialogTitle>
          <Formik
            initialValues={initValues}
            onSubmit={(values) => {
              handleSave(
                values.reportName,
                values.reportDescription,
                values.storedProcedureName,
                values.detailsStoredProcedureName,
                values.summaryStoredProcedureName,
                values.powerBIReportId,
                values.file
              );
            }}
            validationSchema={validationSchema(
              addReportType,
              selectedReportType,
              selectedReportSubtype
            )}
          >
            {(formProps) => {
              const { handleSubmit } = formProps;
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    {addReportType === AddReportType.None ? (
                      <AddReportTypeNone
                        selectedReportType={selectedReportType}
                        handleNewReport={handleNewReport}
                        setSelectedReportType={setSelectedReportType}
                        setAddReportType={setAddReportType}
                        setIsBtnSaveVisible={setIsBtnSaveVisible}
                      />
                    ) : (
                      <Grid container spacing={2}>
                        {addReportType === AddReportType.NewReport &&
                          selectedReportType === ReportTypeEnum.PowerBI && (
                            <AddReportPowerBi
                              powerBiReports={powerBiReports}
                              selectedPowerBIReportId={selectedPowerBIReportId}
                              handlePowerBiReportChange={
                                handlePowerBiReportChange
                              }
                            />
                          )}
                        {addReportType === AddReportType.NewReport &&
                          selectedReportType !== ReportTypeEnum.PowerBI && (
                            <AddNewReportNotPowerBi
                              selectedReportSubtype={selectedReportSubtype}
                              setSelectedReportSubtype={
                                setSelectedReportSubtype
                              }
                            />
                          )}
                        {addReportType === AddReportType.CopyReport && (
                          <AddReportCopyReport
                            applications={props.applications}
                            selectedOldReportID={selectedOldReportID}
                            setSelectedOldReportID={setSelectedOldReportID}
                            reports={reports.filter(
                              (r) => r.reportType === selectedReportType
                            )}
                          />
                        )}
                        {addReportType === AddReportType.ImportReport && (
                          <AddReportImportReport />
                        )}
                        <AddReportBasicInfo />
                      </Grid>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <ButtonSubaction text="Cancel" handleClick={handleClose} />
                    <ButtonAction
                      isSubmit
                      text="Save"
                      isHidden={!isBtnSaveVisible}
                    />
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </ProgressSpinner>
      </Dialog>
      <ErrorDialog
        isOpen={isOpenErrorDialog}
        error={textErrorDialog}
        handleOkClick={closeErrorDialog}
      />
      <ConfirmDialog {...confirmDialogData} />
    </React.Fragment>
  );
}
