import * as Yup from "yup";
import { validate as validateGuid } from "uuid";

export const validationShape = {
  name: Yup.string().max(100).required("Required"),
  guid: Yup.string().when("id", {
    is: (val) => !!val,
    then: Yup.string()
      .required("Required")
      .transform((value, originalValue) => {
        return value?.toUpperCase();
      })
      .trim()
      .test("isGuid", "Invalid GUID format", (value) => validateGuid(value)),
  }),
};
