import * as React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import TypographyInfo from 'Components/Typography/typography-info';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { IInputCheckboxProps } from 'Components/Inputs/interfaces-input';

export default function InputCheckbox(props: IInputCheckboxProps) {
  const styles = makeStyles({
    checkbox: {
      width: props.size ?? 'auto',
      height: props.size ?? 'auto',
      marginBottom: '0px',
    },
    checkboxIcon: {
      fontSize: props.size ?? '20px',
    },
    formControlLabel: {
      margin: props.margin ?? '0',
      width: props.labelWidth,
    },
  });
  const customStyles = styles({});

  return (
    <FormControlLabel
      className={customStyles.formControlLabel}
      disabled={props.disabled}
      control={
        <Checkbox
          color="primary"
          className={customStyles.checkbox}
          checked={props.checked}
          onChange={props.onChange}
          name={props.name}
          icon={
            <CheckBoxOutlineBlankIcon className={customStyles.checkboxIcon} />
          }
          checkedIcon={<CheckBoxIcon className={customStyles.checkboxIcon} />}
        />
      }
      label={
        props.text && (
          <TypographyInfo
            text={props.text ?? ''}
            fontSize="14px"
            style={props.labelFontSize ? { fontSize: props.labelFontSize } : {}}
          />
        )
      }
    />
  );
}
