import { makeStyles } from "@material-ui/core";
import Colors from "Common/colors";

export const stylesPermissionListItem = makeStyles({
  gridItem: {
    borderRight: `1px solid ${Colors.graySeparate}`,
    marginRight: "40px",
    height: "50px",
  },
  paper: {
    padding: "11px 30px",
    marginBottom: "15px",
  },
  cell: {
    border: "1px solid #DCDCDC",
    padding: "8px",
    background: "#fff",
  },
  cellActions: {
    textAlign: "center",
    verticalAlign: "top",
  },
  textCellEnabled: {
    color: "#444444",
  },
  textCellDisabled: {
    color: "#AEAEAE",
  },
});
