import * as React from "react";
import { stylesProgressSpinner } from "Components/ProgressSpinner/styles";
import { CircularProgress } from "@material-ui/core";
import { IProgressSpinnerProps } from "Components/ProgressSpinner/interfaces-progress-spinner";
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css';

export default function ProgressSpinner(props: IProgressSpinnerProps) {
    const customStyles = stylesProgressSpinner();

    return (
        <BlockUi blocking={props.isDisplayed} loader={<CircularProgress className={customStyles.color} />}>
            {props.children}
        </BlockUi>
    )
}