import * as React from "react";
import { Grid, Paper } from "@material-ui/core";
import {
  faDesktop,
  faFile,
  faCheckCircle,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import TypographyInfo from "Components/Typography/typography-info";
import TypographySubinfo from "Components/Typography/typography-subinfo";
import moment from "moment";
import AppVersionEditor from "Components/AppVersionEditor/app-version-editor";
import { FormEditorEnum } from "Common/enum";
import { IAppListItemProps } from "./interfaces-app-list";
import { stylesAppListItem } from "./styles";

export default function AppListItem(props: IAppListItemProps) {
  const customStyles = stylesAppListItem();
  const { item } = props;

  return (
    <tr>
      <td>
        <Paper className={customStyles.paper}>
          <Grid container alignItems="center">
            <Grid container item xs={2}>
              <Grid item xs={12} className={customStyles.gridItem}>
                <TypographyInfo
                  text={`Release ${item.versionNo}`}
                  iconEnd={item.availableToInstall ? faCheckCircle : undefined}
                />
              </Grid>
            </Grid>
            <Grid container item xs={10}>
              <Grid item xs={2}>
                <TypographySubinfo
                  text={moment(item.releaseDate).format("DD/MM/YYYY")}
                  iconStart={faCalendar}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container={true} spacing={0}>
                  <Grid item={true} xs={1}>
                    <TypographySubinfo iconStart={faDesktop} text="" />
                  </Grid>
                  <Grid item={true} xs={11}>
                    <TypographySubinfo
                      text={
                        item.mobileWebApps
                          ? item.mobileWebApps
                              .map(
                                (x) =>
                                  `${x.appName} v ${x.webAppVersionRequired}`
                              )
                              .join(", ")
                          : "-"
                      }
                      showMore={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container={true} spacing={0}>
                  <Grid item={true} xs={1}>
                    <TypographySubinfo iconStart={faFile} text="" />
                  </Grid>
                  <Grid item={true} xs={11}>
                    <TypographySubinfo
                      text={item.releaseNotes ? item.releaseNotes : "-"}
                      showMore={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <div className={customStyles.buttonEdit}>
                  <AppVersionEditor
                    appId={item.iaAppID}
                    appName={props.appName}
                    editorType={FormEditorEnum.Edit}
                    mobileAppVersion={item}
                    isAnyVersionWithForceUpdate={
                      props.isAnyVersionWithForceUpdate
                    }
                    getMobileAppVersionList={props.handleRefreshApp}
                    platformType={props.platformType}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </td>
    </tr>
  );
}
