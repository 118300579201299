import * as React from "react";
import { stylesMoveListEditor } from "./styles";
import {
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@material-ui/core";

interface CustomListItemProps {
  id: number;
  checked: boolean;
  handleClick: () => void;
  text: string;
  className: "listItem" | "listItemSubHeader";
}
export const CustomListItem: React.FunctionComponent<CustomListItemProps> = ({
  id,
  checked,
  handleClick,
  text,
  className,
}) => {
  const customStyles = stylesMoveListEditor();

  return (
    <ListItem
      key={id}
      role={undefined}
      dense
      button
      className={customStyles[className]}
      onClick={handleClick}
    >
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          disableRipple
          inputProps={{
            "aria-labelledby": text,
          }}
          color="primary"
        />
      </ListItemIcon>
      <ListItemText id={id.toString()} primary={text} />
    </ListItem>
  );
};
export default CustomListItem;
