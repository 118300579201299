import * as Yup from "yup";
import { ReportSubtypeEnum, ReportTypeEnum } from "Common/enum";

export const NAME_MAX_LENGTH: number = 200;
export const DESCRIPTION_MAX_LENGTH: number = 500;
export const FILE_MAX_SIZE: number = 4194304; // 4 MB

export enum AddReportType {
  None = 0,
  NewReport = 1,
  CopyReport = 2,
  ImportReport = 3,
}

export type AddReportFormikValuesType = {
  reportName: string;
  reportDescription: string;
  storedProcedureName: string;
  detailsStoredProcedureName?: string;
  summaryStoredProcedureName?: string;
  oldReportID: number;
  file?: Blob;
  powerBIReportId: string;
};

export const ReportSubtypeEnumLabels: { [key in number]: string } = {
  [ReportSubtypeEnum.Regular]: "Regular report",
  [ReportSubtypeEnum.Summary]: "Summary report",
  [ReportSubtypeEnum.MasterDetails]: "Master details report",
};

export const initValues = {
  reportName: "",
  reportDescription: "",
  storedProcedureName: "",
  summaryStoredProcedureName: "",
  detailsStoredProcedureName: "",
  oldReportID: 0,
  file: undefined,
  powerBIReportId: "",
} as AddReportFormikValuesType;

export const validationSchema = (
  addReportType: AddReportType,
  selectedReportType: ReportTypeEnum,
  selectedReportSubtype: ReportSubtypeEnum
) => {
  const reportName = Yup.string()
    .label("Report name")
    .required("Required")
    .max(NAME_MAX_LENGTH);
  const reportDesc = Yup.string()
    .label("Description")
    .max(DESCRIPTION_MAX_LENGTH);

  const newReportNotPowerBi = (subType?: ReportSubtypeEnum) => {
    let summaryObjectShape = Yup.object().shape({
      summaryStoredProcedureName: Yup.string()
        .label("Summary procedure name")
        .max(NAME_MAX_LENGTH)
        .required("Required"),
    });

    let detailsObjectShape = Yup.object().shape({
      detailsStoredProcedureName: Yup.string()
        .label("Details procedure name")
        .max(NAME_MAX_LENGTH)
        .required("Required"),
    });

    let mainObjectShape = Yup.object().shape({
      reportName: reportName,
      reportDescription: reportDesc,
      storedProcedureName: Yup.string()
        .label("Procedure name")
        .max(NAME_MAX_LENGTH)
        .required("Required"),
    });

    if (subType === ReportSubtypeEnum.Summary) {
      mainObjectShape = mainObjectShape.concat(summaryObjectShape);
    } else if (subType === ReportSubtypeEnum.MasterDetails) {
      mainObjectShape = mainObjectShape.concat(detailsObjectShape);
    }
    return mainObjectShape;
  };
  const newReportPowerBi = Yup.object().shape({
    powerBIReportId: Yup.string()
      .label("PowerBi report Id")
      .required("Required"),
    reportName: reportName,
    reportDescription: reportDesc,
  });
  const copyReport = Yup.object().shape({
    oldReportID: Yup.number()
      .label("Old Report")
      .required("Required")
      .moreThan(0, "Required"),
    reportName: reportName,
    reportDescription: reportDesc,
  });
  const basicInfo = Yup.object().shape({
    reportName: reportName,
    reportDescription: reportDesc,
    file: Yup.mixed().required(),
  });

  return addReportType === AddReportType.NewReport &&
    selectedReportType !== ReportTypeEnum.PowerBI
    ? newReportNotPowerBi(selectedReportSubtype)
    : addReportType === AddReportType.NewReport &&
      selectedReportType === ReportTypeEnum.PowerBI
    ? newReportPowerBi
    : addReportType === AddReportType.CopyReport
    ? copyReport
    : basicInfo;
};
