import * as React from "react";
import { IAccessRightsViewProps } from "Components/AccessRight/interfaces-access-right";
import { Container } from "@material-ui/core";
import { AccessRightsDefaultTab } from "Common/enum";
import AccessRights from "Components/AccessRight/access-rights";

const AccessRightsView: (props: IAccessRightsViewProps) => JSX.Element = (
  props: IAccessRightsViewProps
): JSX.Element => {
  
  return (
    <Container>
      <AccessRights
        client={props.client}
        defaultTab={AccessRightsDefaultTab.Permissions}
      />
    </Container>
  );
};

export default AccessRightsView;