import { Grid } from "@material-ui/core";
import Colors from "Common/colors";
import TypographyInfo from "Components/Typography/typography-info";
import * as React from "react";
import ActionButtonDelete from "./ActionButton/delete";
import { CustomList } from "./custom-list";
import { MoveListHeader } from "./move-list-header";
import { MoveListEditorItemType } from "./types";

interface MoveListEditorRightSideProps {
  countSelected: number;
  onSearchChangeSelected: (value: React.SetStateAction<string>) => void;
  checkedSelected: number[];
  deleteFromSelected: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
  filteredSelected: MoveListEditorItemType[] | undefined;
  handleToggleSelected: (value: number) => void;
  selectAll: () => void;
  isAllSelected: boolean;
}
const MoveListEditorRightSide: React.FC<MoveListEditorRightSideProps> = ({
  countSelected,
  onSearchChangeSelected,
  checkedSelected,
  deleteFromSelected,
  filteredSelected,
  handleToggleSelected,
  selectAll,
  isAllSelected,
}) => {
  return (
    <Grid container item sm={6} direction="column" style={{ paddingLeft: 20 }}>
      <Grid container item sm={12} alignItems="baseline">
        <TypographyInfo
          text={`Selected (${countSelected})`}
          color={Colors.black}
          margin="5px 5px"
        />
      </Grid>
      <MoveListHeader
        onSearchChange={onSearchChangeSelected}
        actionButton={
          <ActionButtonDelete
            checkedSelectedLength={checkedSelected.length}
            deleteFromSelected={deleteFromSelected}
          />
        }
      />
      <CustomList
        isAllSelected={isAllSelected}
        selectAll={selectAll}
        items={filteredSelected}
        checked={checkedSelected}
        handleToggleItem={handleToggleSelected}
      />
    </Grid>
  );
};
export default MoveListEditorRightSide;
