import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { IErrorDialogProps } from "../interfaces";
import { isString } from "lodash";
import TypographyInfo from "Components/Typography/typography-info";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import { AxiosError } from "axios";

export const useErrorDialog = () => {
  const initValues = { isOpen: false, message: null };
  const [errorDialogData, setErrorDialogData] = React.useState<{
    isOpen: boolean;
    message: AxiosError | null | string;
  }>(initValues);

  const open = (text: AxiosError | null | string) => {
    setErrorDialogData({ isOpen: true, message: text });
  };

  const close = () => {
    setErrorDialogData(initValues);
  };

  return {
    isOpenErrorDialog: errorDialogData.isOpen,
    textErrorDialog: errorDialogData.message,
    openErrorDialog: open,
    closeErrorDialog: close,
  };
};

export default function ErrorDialog(props: IErrorDialogProps) {
  let errorMessage: string = "";
  if (isString(props.error)) {
    errorMessage = props.error;
  } else if (
    props.error &&
    props.error.response &&
    props.error.response.data.statusMessage
  ) {
    errorMessage = props.error.response.data.statusMessage;
  } else if (props.error && props.error.message) {
    errorMessage = props.error.message;
  }

  return (
    <Dialog open={props.isOpen}>
      <DialogTitle>
        <TypographySubtitle text={"Error"} />
      </DialogTitle>
      <DialogContent dividers>
        <TypographyInfo text={errorMessage} />
      </DialogContent>
      <DialogActions>
        <ButtonAction isSubmit text="Ok" handleClick={props.handleOkClick} />
      </DialogActions>
    </Dialog>
  );
}
