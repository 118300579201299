import * as React from 'react';
import { Grid } from '@material-ui/core';
import { IconType } from 'Common/enum';
import ButtonIcon from 'Components/Buttons/button-icon';
import SvgResendArrow from 'Components/Images/image-resend-arrow';
import InputCheckbox from 'Components/Inputs/input-checkbox';
import TypographyTitle from 'Components/Typography/typography-title';
import { useFormikContext } from 'formik';
import { AppType } from 'Types/app-types';
import { SendUserLoginResetCodeModelType } from 'Types/user-types';
import { UserLoginListItemFormikValuesType } from '../formikValues';

export interface UserLoginListItemAppProps {
  propsAppList: AppType[];
  setAppList: (appList: AppType[]) => void;
  userLoginID?: number;
  propsHandleSendUserLoginResetCode: (
    data: SendUserLoginResetCodeModelType
  ) => void;
}

const UserLoginListItemApp: React.FC<UserLoginListItemAppProps> = ({
  propsAppList,
  setAppList,
  userLoginID,
  propsHandleSendUserLoginResetCode,
}) => {
  const { values } = useFormikContext<UserLoginListItemFormikValuesType>();

  const [applicationList, setApplicationList] = React.useState<AppType[]>([]);

  const handleSendUserLoginResetCode = async (
    appGuid: string
  ): Promise<void> => {
    if (userLoginID) {
      const sendUserLoginResetCode: SendUserLoginResetCodeModelType = {
        userLoginID,
        appGuid,
      };

      propsHandleSendUserLoginResetCode(sendUserLoginResetCode);
    }
  };

  React.useEffect(() => {
    const selectedAppList: AppType[] = propsAppList.map((a) => {
      a.isSelected = values.apps.some((v) => v.appGuid === a.guid);
      return a;
    });
    setApplicationList(selectedAppList);
  }, []);

  const handleApplicationToggle = (appGuid: string): void => {
    let app: AppType | undefined = propsAppList.find((x) => x.guid === appGuid);
    if (app) {
      let appIndex = propsAppList.indexOf(app);
      app.isSelected = !app.isSelected;
      let newAppList = [...propsAppList];
      newAppList.splice(appIndex, 1, app);
      setAppList(newAppList);
    }
  };

  return (
    <>
      <Grid container item>
        <Grid item xs={12}>
          <TypographyTitle text="Applications" fontSize="18px" />
        </Grid>
        <Grid container item>
          {applicationList.map((app, index) => {
            return (
              <Grid
                container
                item
                xs={3}
                style={{ marginBottom: '15px' }}
                alignItems="baseline"
              >
                <InputCheckbox
                  checked={app.isSelected}
                  text={app.name}
                  labelFontSize="15px"
                  onChange={(e) => handleApplicationToggle(app.guid)}
                  name={`apps[${index}].isSelected`}
                  labelWidth="auto"
                />
                <ButtonIcon
                  iconType={IconType.SvgImageIcon}
                  icon={<SvgResendArrow width="20px" />}
                  handleClick={() => {
                    handleSendUserLoginResetCode(app.guid);
                  }}
                  isHidden={!userLoginID || !app.guid || !app.isSelected}
                  title="Send reset password link"
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default UserLoginListItemApp;
