import React from "react";
import HeaderCard from "Components/HeaderCard/header-card";
import { MenuItem, Select } from "@material-ui/core";
import { GetCustomerListResponseType } from "Types/customer-types";
import { stylesHeader } from "./styles";

export interface HeaderProps {
  customerList: GetCustomerListResponseType[];
  selectedCustomer?: GetCustomerListResponseType;

  setSelectedCustomer: (customer: GetCustomerListResponseType) => void;
}

const Header: React.FC<HeaderProps> = ({
  selectedCustomer,
  customerList,
  setSelectedCustomer,
}) => {
  const customStyles = stylesHeader();

  const handleChangeCustomer = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const selectedValue = event.target.value as number;
    if (selectedValue && !isNaN(selectedValue)) {
      const newSelectedCustomer = customerList.find(
        (c) => c.customerID === selectedValue
      );
      if (newSelectedCustomer) {
        setSelectedCustomer(newSelectedCustomer);
      }
    }
  };

  return (
    <HeaderCard>
      <Select
        id="select-customer"
        value={selectedCustomer?.customerID ?? null}
        onChange={handleChangeCustomer}
        disableUnderline={true}
        classes={{ selectMenu: customStyles.selectMenu }}
      >
        {customerList.map((customer) => (
          <MenuItem key={customer.customerID} value={customer.customerID}>
            {customer.name}
          </MenuItem>
        ))}
      </Select>
    </HeaderCard>
  );
};

export default Header;
