import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { AxiosError } from "axios";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { Formik } from "formik";
import React from "react";
import { unselectAllListElements } from "./utils";
import { PermissionModel } from "./models";
import EnabledPermissions from "./enabled-permissions";
import DisabledPermissions from "./disabled-permissions";
import PermissionApi from "Services/Api/permission-api";
import { GetCustomerPermissionsDisabledResponseType } from "Types/customer-types";
import AuthorizationHelper from "Configuration/authorization-helper";
import CustomerApi from "Services/Api/customer-api";
import { ApiResultStatusEnum } from "Common/enum";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";

export interface DisabledPermissionsEditorProps {
  isOpen: boolean;
  close: () => void;

  disabledPermissions: GetCustomerPermissionsDisabledResponseType[];

  customerID: number;
  refreshPage: () => void;
}

const permissionApi = new PermissionApi();
const customerApi = new CustomerApi();

const DisabledPermissionsEditor: React.FC<DisabledPermissionsEditorProps> = ({
  isOpen,
  close,
  disabledPermissions,
  customerID,
  refreshPage,
}) => {
  const [isErrorDialogOpen, setIsErrorDialogOpen] =
    React.useState<boolean>(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );

  const [enabledPermissionsList, setEnabledPermissions] = React.useState<
    PermissionModel[]
  >([]);
  const [enabledPermissionsFiltered, setEnabledPermissionsFiltered] =
    React.useState<PermissionModel[]>([]);

  const [disabledPermissionsList, setDisabledPermissionsList] = React.useState<
    PermissionModel[]
  >([]);
  const [disabledPermissionsListFiltered, setDisabledPermissionsListFiltered] =
    React.useState<PermissionModel[]>([]);

  React.useEffect(() => {
    setDisabledPermissionsList(
      disabledPermissions.map((c) => ({
        data: c,
        isSelected: false,
      }))
    );
    setDisabledPermissionsListFiltered(
      disabledPermissions.map((c) => ({
        data: c,
        isSelected: false,
      }))
    );
  }, [disabledPermissions, isOpen]);

  const getPermissions = React.useCallback(async (): Promise<void> => {
    await permissionApi
      .getPermissionsForCustomer(customerID)
      .then((result) => {
        if (result && result.data) {
          const permissions = result.data
            .filter(
              (r) =>
                !disabledPermissionsList.find(
                  (c) => c.data.permissionID === r.permissionID
                )
            )
            .map((r) => ({
              data: r,
              isSelected: false,
            }));
          setEnabledPermissions(permissions);
          setEnabledPermissionsFiltered(permissions);
        }
      })
      .catch((error) => {
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  }, [disabledPermissionsList, customerID]);

  React.useEffect(() => {
    getPermissions();
  }, [getPermissions, isOpen]);

  const removeFromEnabledPermissions = (permissionsIDs: number[]) => {
    setEnabledPermissions(
      unselectAllListElements(
        enabledPermissionsList.filter(
          (r) => !permissionsIDs.find((id) => id === r.data.permissionID)
        )
      )
    );
    setEnabledPermissionsFiltered(
      unselectAllListElements(
        enabledPermissionsFiltered.filter(
          (r) => !permissionsIDs.find((id) => id === r.data.permissionID)
        )
      )
    );
  };

  const removeFromDisabledPermissions = (permissionsIDs: number[]) => {
    setDisabledPermissionsList(
      unselectAllListElements(
        disabledPermissionsList.filter(
          (r) => !permissionsIDs.find((id) => id === r.data.permissionID)
        )
      )
    );
    setDisabledPermissionsListFiltered(
      unselectAllListElements(
        disabledPermissionsListFiltered.filter(
          (r) => !permissionsIDs.find((id) => id === r.data.permissionID)
        )
      )
    );
  };

  const addToDisabledPermissions = (permissions: PermissionModel[]) => {
    setDisabledPermissionsList(
      unselectAllListElements(disabledPermissionsList.concat(permissions))
    );
    setDisabledPermissionsListFiltered(
      unselectAllListElements(
        disabledPermissionsListFiltered.concat(permissions)
      )
    );
  };

  const toggleSelectEnabledPermissions = (permissionID: number) => {
    setEnabledPermissions(
      enabledPermissionsList.map((r) =>
        r.data.permissionID === permissionID
          ? { ...r, isSelected: !r.isSelected }
          : r
      )
    );
    setEnabledPermissionsFiltered(
      enabledPermissionsFiltered.map((r) =>
        r.data.permissionID === permissionID
          ? { ...r, isSelected: !r.isSelected }
          : r
      )
    );
  };

  const toggleSelectDisabledPermissions = (permissionID: number) => {
    setDisabledPermissionsList(
      disabledPermissionsList.map((r) =>
        r.data.permissionID === permissionID
          ? { ...r, isSelected: !r.isSelected }
          : r
      )
    );
    setDisabledPermissionsListFiltered(
      disabledPermissionsListFiltered.map((r) =>
        r.data.permissionID === permissionID
          ? { ...r, isSelected: !r.isSelected }
          : r
      )
    );
  };

  const addToEnabledPermissions = (permissions: PermissionModel[]) => {
    setEnabledPermissions(
      unselectAllListElements(enabledPermissionsList.concat(permissions))
    );
    setEnabledPermissionsFiltered(
      unselectAllListElements(enabledPermissionsFiltered.concat(permissions))
    );
  };
  const handleSubmit = () => {
    const userID = AuthorizationHelper.getUser().userID;
    if (userID) {
      customerApi
        .saveCustomerPermissionsDisabled({
          customerID,
          currentUserID: userID,
          disabledPermissionsIDs: disabledPermissionsList.map(
            (p) => p.data.permissionID
          ),
        })
        .then((response) => {
          if (response.status === ApiResultStatusEnum.Ok) {
            close();
            refreshPage();
          } else {
            setIsErrorDialogOpen(true);
          }
        })
        .catch((error) => {
          setIsErrorDialogOpen(true);
          setErrorResponse(error);
        });
    } else {
      setIsErrorDialogOpen(true);
    }
  };
  return (
    <>
      <Dialog open={isOpen} onEscapeKeyDown={close} fullWidth maxWidth={"md"}>
        <DialogTitle>
          <TypographySubtitle text={"Disable permissions"} />
        </DialogTitle>

        <Formik
          initialValues={{}}
          onSubmit={async () => {
            await handleSubmit();
          }}
        >
          {(formProps) => {
            const { handleSubmit } = formProps;

            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <Grid container item sm={12} alignItems="center">
                    <EnabledPermissions
                      list={enabledPermissionsList}
                      filteredList={enabledPermissionsFiltered}
                      setFilterd={(permissions: PermissionModel[]) =>
                        setEnabledPermissionsFiltered(permissions)
                      }
                      remove={removeFromEnabledPermissions}
                      addToDisabledPermissions={addToDisabledPermissions}
                      toggleSelectPermission={toggleSelectEnabledPermissions}
                    />

                    <DisabledPermissions
                      list={disabledPermissionsList}
                      filteredList={disabledPermissionsListFiltered}
                      setFilterd={(permissions: PermissionModel[]) =>
                        setDisabledPermissionsListFiltered(permissions)
                      }
                      toggleSelectPermission={toggleSelectDisabledPermissions}
                      addToEnabledPermissions={addToEnabledPermissions}
                      remove={removeFromDisabledPermissions}
                    />
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ButtonSubaction text="Cancel" handleClick={close} />
                  <ButtonAction isSubmit text="Save" />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={() => setIsErrorDialogOpen(false)}
      />
    </>
  );
};

export default DisabledPermissionsEditor;
