import * as React from "react";
import { ListBaseProps } from "./interfaces";
import ListItemBase from "./list-item-base";
import MaterialTable from "material-table";

const ListBase: <TListItem extends object>(
  props: ListBaseProps<TListItem>
) => JSX.Element = (props): JSX.Element => {
  return (
    //TODO - add infinite scroll with prop option to enable
    // <InfiniteScroll
    //   dataLength={props.dataLength}
    //   next={loadMore}
    //   hasMore={renderedActualItemCount < filteredUserList.length}
    //   loader={
    //     <Grid container justify="center">
    //       <Box marginTop={6}>
    //         <CircularProgress color="primary" />
    //       </Box>
    //     </Grid>
    //   }
    //   style={{ overflow: "hidden" }}
    // >
    <MaterialTable
      columns={[]}
      data={props.itemList}
      options={{
        showTitle: false,
        header: false,
        paging: false,
        search: false,
        toolbar: false,
      }}
      components={{
        Row: (propsRow) => (
          <ListItemBase
            renderTitle={props.renderTitle}
            renderItem={props.renderItem}
            item={propsRow.data}
          />
        ),
      }}
      style={{
        background: "transparent",
        boxShadow: "none",
        minHeight: "30vh",
        marginTop: "20px",
      }}
    />
    // </InfiniteScroll>
  );
};

export default ListBase;
