import ClientAppApi from 'Services/Api/client-app-api';
import ErrorDialog from 'Components/Dialogs/ErrorDialog/error-dialog';
import PermissionApi from 'Services/Api/permission-api';
import PermissionEditor from './PermissionEditor/permission-editor';
import PermissionList from './permission-list';
import PermissionListFilters from './permission-list-filters';
import ProgressSpinner from 'Components/ProgressSpinner/progress-spinner';
import React from 'react';
import ReportApi from 'Services/Api/report-api';
import RoleApi from 'Services/Api/role-api';
import TypographyTitle from 'Components/Typography/typography-title';
import { AppType } from 'Types/app-types';
import { AxiosError } from 'axios';
import { Container, Grid, useTheme } from '@material-ui/core';
import { GetPermissionGroupsApiResponseType } from 'Types/permission-types';
import { GetPermissionListItemType } from 'Types/role-types';
import { IPermissionListContainerProps } from './interfaces-permission-list';
import { MoveListEditorItemType } from 'Components/Permission/MoveListEditor/types';
import InfoDialog, {
  useInfoDialog,
} from "Components/Dialogs/InfoDialog/info-dialog";

const roleApi = new RoleApi();
const reportApi = new ReportApi();
const permissionApi = new PermissionApi();
const clientAppApi = new ClientAppApi();

const PermissionListContainer: React.FC<IPermissionListContainerProps> = ({
  client,
}) => {
  const [errorResponse, setErrorResponse] = React.useState<
    AxiosError | string | null
  >(null);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const { openInfoDialog, isOpenInfoDialog, closeInfoDialog, infoDialogText } =
    useInfoDialog();

  const [isBlockedUi, setIsBlockedUi] = React.useState<boolean>(false);
  const [list, setList] = React.useState<GetPermissionListItemType[]>([]);
  const [appList, setAppList] = React.useState<AppType[]>([]);
  const [permissionGroupsList, setPermissionGroupsList] = React.useState<
    GetPermissionGroupsApiResponseType[]
  >([]);
  const [reportsContainerList, setReportContainerList] = React.useState<
    MoveListEditorItemType[]
  >([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [selectedApps, setSelectedApps] = React.useState<number[]>([]);
  const [isSelectedAll, setIsSelectedAll] = React.useState<boolean>(false);
  const theme = useTheme();

  const getPermissionList = () => {
    setIsBlockedUi(true);
    roleApi
      .getPermissionList(client?.id!)
      .then((response) => {
        if (response?.data) {
          setList(response.data);
        }
      })
      .finally(() => {
        setIsBlockedUi(false);
      });
  };

  const getApplications = () => {
    clientAppApi.getAllAppList(client.id!, true).then((result) => {
      if (result?.data) {
        setAppList(result.data);
        setSelectedApps(result.data.map((s) => s.id!));
        setIsSelectedAll(true);
      }
    });
  };

  const getPermissionGroupsList = () => {
    permissionApi.getPermissionGroups(client?.id!).then((response) => {
      if (response?.data) {
        setPermissionGroupsList(response.data);
      }
    });
  };

  const getReportsContainerList = () => {
    reportApi.getReportContainerList(client?.id!).then((response) => {
      if (response?.data) {
        setReportContainerList(
          response.data.map((c) => ({
            id: c.reportContainerID,
            label: c.reportContainerName,
          }))
        );
      }
    });
  };

  React.useEffect(() => {
    if (client?.id && client.id > 0) {
      getPermissionList();
      getApplications();
      getPermissionGroupsList();
      getReportsContainerList();
    }
  }, [client]);

  const handleSearchValueChange = (
    value: React.SetStateAction<string>
  ): void => {
    setSearchValue(value?.toString() ?? "");
  };

  const handleSelectedAppsChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    let selectedValues = event.target.value as number[];
    if (selectedValues.some((id) => id === 0)) {
      if (isSelectedAll) {
        setSelectedApps([]);
      } else {
        setSelectedApps(appList.map((a) => a.id!));
      }
      setIsSelectedAll(!isSelectedAll);
    } else {
      setIsSelectedAll(false);
      setSelectedApps(selectedValues.filter((s) => s > 0));
    }
  };

  const filter = (p: GetPermissionListItemType): boolean => {
    const search = searchValue.toLocaleUpperCase();
    return (
      (isSelectedAll ||
        p.appList.some((a) => selectedApps.some((id) => id === a.appID))) &&
      (p.permissionName.toLocaleUpperCase().includes(search) ||
        p.permissionsGroups.some((g) =>
          g.permissionGroupName.toLocaleUpperCase().includes(search)
        ) ||
        p.reportContainers.some((c) =>
          c.reportContainerName.toLocaleUpperCase().includes(search)
        ) ||
        p.appList.some((a) => a.appName.toLocaleUpperCase().includes(search)) ||
        p.permissionNumber.toString().includes(search) ||
        p.reports.some((r) =>
          r.reportName.toLocaleUpperCase().includes(search)
        ))
    );
  };

  return (
    <ProgressSpinner isDisplayed={isBlockedUi}>
      <Container>
        <Grid container>
          <Grid container justify="space-between" alignItems="baseline">
            <TypographyTitle
              text="Permissions"
              color={theme.palette.grayDarker.main}
              fontSize="20px"
              margin="25px 0 0 0"
            />
            <PermissionEditor
              permissionGroupsList={permissionGroupsList}
              setIsBlockedUi={setIsBlockedUi}
              getPermissionList={getPermissionList}
            />
          </Grid>

          <Grid container>
            <PermissionListFilters
              appList={appList}
              selectedApps={selectedApps}
              onSearchChange={handleSearchValueChange}
              onSelectedAppsChange={handleSelectedAppsChange}
              isSelectedAll={isSelectedAll}
            />
          </Grid>
        </Grid>

        <PermissionList
          appList={appList}
          permissionList={list.filter(filter)}
          getPermissionList={getPermissionList}
          client={client}
          setIsBlockedUi={setIsBlockedUi}
          permissionGroupsList={permissionGroupsList.map((g) => ({
            id: g.permissionGroupID,
            label: g.name,
          }))}
          reportsContainerList={reportsContainerList}
          setErrorResponse={setErrorResponse}
          setIsErrorDialogOpen={setIsErrorDialogOpen}
          openInfoDialog={openInfoDialog}
        />
      </Container>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={() => {
          setIsErrorDialogOpen(false);
        }}
      />
      <InfoDialog
        isOpen={isOpenInfoDialog}
        text={infoDialogText}
        handleOkClick={closeInfoDialog}
      />
    </ProgressSpinner>
  );
};

export default PermissionListContainer;
