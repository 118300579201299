import * as React from "react";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import ButtonSolid from "Components/Buttons/button-solid";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import parse from "html-react-parser";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { IConfirmDialogProps } from "../interfaces";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

export const useConfirmDialog = () => {
  const initValues: IConfirmDialogProps = {
    isOpen: false,
    text: "",
    handleOkClick: () => {},
    handleCancelClick: () => {},
    textOption1: "",
    textOption2: "",
    handleOption1Click: () => {},
    handleOption2Click: () => {},
  };
  const [settings, setSettings] =
    React.useState<IConfirmDialogProps>(initValues);

  const close = () => {
    setSettings(initValues);
  };

  const open = (props: Omit<IConfirmDialogProps, "isOpen">) => {
    setSettings({
      ...props,
      isOpen: true,
    });
  };

  return {
    confirmDialogData: settings,
    openConfirmDialog: open,
    closeConfirmDialog: close,
  };
};

export default function ConfirmDialog(props: IConfirmDialogProps) {
  const {
    handleCancelClick,
    handleOkClick,
    text,
    isOpen,
    textOption1,
    handleOption1Click,
    textOption2,
    handleOption2Click,
  } = props;
  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <TypographySubtitle text="Confirm" />
      </DialogTitle>
      <DialogContent dividers>{parse(text)}</DialogContent>
      <DialogActions>
        <ButtonSubaction text="Cancel" handleClick={handleCancelClick} />
        {handleOkClick ? (
          <ButtonAction isSubmit text="Ok" handleClick={handleOkClick} />
        ) : (
          ""
        )}
        {textOption1 && handleOption1Click ? (
          <ButtonSolid
            text={textOption1}
            onClickEvent={handleOption1Click}
            margin="0 0 0 10px"
          />
        ) : (
          ""
        )}
        {textOption2 && handleOption2Click ? (
          <ButtonSolid
            text={textOption2}
            onClickEvent={handleOption2Click}
            margin="0 0 0 10px"
          />
        ) : (
          ""
        )}
      </DialogActions>
    </Dialog>
  );
}
