import * as React from 'react';

const SvgUploadLogo = ({
  style = {}
}) => (
  <svg
    style={style}
    width="52"
    height="53"
    viewBox="0 0 52 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="51" height="35" rx="4.5" stroke="black" />
    <path d="M6 31L22 13L33.5 26" stroke="black" />
    <path d="M30 22.5L36 16L49 31" stroke="black" />
    <circle cx="11" cy="10" r="3.5" stroke="black" />
    <path d="M22 44V40H30V44H35L26 53L17 44H22Z" fill="black" />
  </svg>
);

export default SvgUploadLogo;
