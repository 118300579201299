import React from "react";
import ReportsContainersListItem from "Components/Reports/ReportsContainers/reports-containers-list-item";
import { Grid } from "@material-ui/core";
import { IReportsContainersListProps } from "Components/Reports/interfaces-reports";

const ReportsContainersList: React.FC<IReportsContainersListProps> = ({
  client,
  data,
  getReportsContainers,
}) => {
  return (
    <Grid container direction="column">
      {data.map((item) => (
        <ReportsContainersListItem
          client={client}
          data={item}
          getReportsContainers={getReportsContainers}
          key={item.reportContainerID}
        />
      ))}
    </Grid>
  );
};

export default ReportsContainersList;