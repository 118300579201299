export enum AppUserTypeEnum {
  InternalUser = 1,
  ExternalUser = 2,
  NoUser = 3,
}

export enum AppLoginTypeEnum {
  Email = 1,
  Phone = 2,
  EmailAndPhone = 3,
}

export enum AppLoginDataTypeEnum {
  Id = 1,
  Guid = 2,
}

export enum AppCheckRespondingTypeEnum {
  Base = 1,
  User = 2,
  Report = 3,
  PowerBI = 4,
}
