import * as React from "react";
import { ICustomersProps } from "Components/Customers/interfaces-customers";
import { Container, Grid } from "@material-ui/core";
import CustomerApi from "Services/Api/customer-api";
import { GetCustomerListResponseType } from "Types/customer-types";
import CustomerListContainer from "Components/Customers/customer-list-container";
import { stylesCustomerListItem } from "Components/Customers/styles";

const customerApi = new CustomerApi();

const Customers: (props: ICustomersProps) => JSX.Element = (
  props: ICustomersProps
): JSX.Element => {
  const [customerList, setCustomerList] = React.useState<
    GetCustomerListResponseType[]
  >([]);

  const customStyles = stylesCustomerListItem();

  React.useEffect(() => {
    const getCustomerList = async (): Promise<void> => {
      const clientID: number | null =
        props.client && props.client.id !== null ? props.client.id : null;
      await customerApi.getList(clientID).then((result) => {
        if (result && result.data) {
          setCustomerList(result.data);
        }
      });
    };
    getCustomerList();
  }, [props.client, props.client.id]);

  return (
    <div style={{ marginTop: 30 }}>
      <Container>
        <Grid item className={customStyles.headerTitle}>
          Customer list
        </Grid>
        <CustomerListContainer
          client={props.client}
          customerList={customerList}
        />
      </Container>
    </div>
  );
};

export default Customers;
