import { makeStyles, Theme } from "@material-ui/core";
import Colors from "Common/colors";

export const stylesUsersHeader = makeStyles((theme: Theme) => ({
  editButton: {
    marginLeft: "25px",
  },
  gridItem: {
    marginRight: "15px",
    marginBottom: "0px",
  },
  gridItemApps: {
    borderLeft: `1px solid ${Colors.graySeparate}`,
    marginLeft: "20px",
    marginRight: "15px",
    marginBottom: "0px",
    paddingLeft: "20px",
    color: theme.palette.grayDarker.main,
  },
  link: {
    textDecoration: "none",
  },
  osIcon: {
    width: "35px",
    height: "35px",
    marginLeft: "10px",
    borderRadius: "8px",
  },
  selectedUserType: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "48px",
    color: theme.palette.grayDarker.main,
    borderBottom: "none",
    maxWidth: "400px",
    minWidth: "200px",
  },
  userImage: {
    verticalAlign: "middle",
  },
}));
