import * as React from "react";
import { IAccessRightListItemProps } from "Components/AccessRight/interfaces-access-right";
import { stylesAccessRightListItem } from "Components/AccessRight/AccessRightList/styles";
import { Paper, Grid } from "@material-ui/core";
import TypographyInfo from "Components/Typography/typography-info";

// @ts-ignore
import SegmentedControl from "mui-segmented-control";

const AccessRightListItem: (props: IAccessRightListItemProps) => JSX.Element
    = (props: IAccessRightListItemProps): JSX.Element => {
        const [selectedPermissionValue, setSelectedPermissionValue] = React.useState<string>("");

        const customStyles = stylesAccessRightListItem();

        React.useEffect(() => {
            let selectedValue: number | undefined = props.permission.permissionValues.find(x => x.isSelected)?.permissionValue;
            selectedValue = selectedValue ? selectedValue : 0;
            setSelectedPermissionValue(selectedValue.toString());
        }, [props.permission.permissionValues]);

        const handleValueChange = (value: string): void => {
            props.handlePermissionValueChange(props.permission.permissionID, value);
            setSelectedPermissionValue(value);
        }

        return (
            <tr>
                <td>
                    <Paper className={customStyles.paper}>
                        <Grid container alignItems="center">
                            <Grid container item xs={3}>
                                <Grid item>
                                    <TypographyInfo text={props.permission.permissionName} />
                                </Grid>
                            </Grid>
                            <Grid container item xs={9} justify="flex-end">
                                <Grid item>
                                <SegmentedControl
                                    buttonProps={{
                                        disableRipple: true
                                    }}
                                    classes={{
                                        buttonRoot: [customStyles.segmentedButton],
                                        buttonSelected: [customStyles.segmentedButtonSelected],
                                        buttonLabel: [customStyles.segmentedButtonLabel],
                                        root: [customStyles.segmented]
                                    }}
                                    color="secondary"
                                    options={props.permission.permissionValues.map(value => (
                                        {
                                            label: value.permissionValueName,
                                            value: value.permissionValue.toString()
                                        }
                                    ))}
                                    value={selectedPermissionValue}
                                    onChange={handleValueChange}
                                />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </td>
            </tr>
        )
    };

export default AccessRightListItem;