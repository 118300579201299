import * as React from "react";
import './login.scss';
import { ILoginProps } from "Components/Login/interfaces-login";
import SvgLoginBottomLeft from "Components/Images/Login/image-login-bottom-left";
import SvgLoginTopRight from "Components/Images/Login/image-login-top-right";
import LoginForm from "Components/Login/LoginForm/login-form";
import SvgLoginMiddle from "Components/Images/Login/image-login-middle";
import { renderToStaticMarkup } from "react-dom/server";

export default function Login(props: ILoginProps) {
    const svgString = encodeURIComponent(renderToStaticMarkup(<SvgLoginMiddle />));
    const dataUri = `url("data:image/svg+xml,${svgString}")`;
    const contentStyle = {
        backgroundImage: dataUri,
        backgroundPosition: "70% 50%",
        backgroundRepeat: "no-repeat"
    };

    return (
        <div className="container">
            <div className="content" style={contentStyle}>
                <div className="left">
                    <SvgLoginBottomLeft />
                </div>
                <div className="center">
                    <div className="loginForm">
                        <LoginForm />
                    </div>
                </div>
                <div className="right">
                    <SvgLoginTopRight />
                </div>
            </div>
        </div>
    )
}