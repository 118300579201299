import * as React from "react";
import { Grid } from "@material-ui/core";
import { IInputDateRangeProps } from "Components/Inputs/interfaces-input";
import DateField from "./DateField/date-field";

export default function InputDateRange(props: IInputDateRangeProps) {
  return (
    <>
      <Grid item sm={6}>
        <DateField
          disableToolbar
          format="dd/MM/yyyy"
          variant="inline"
          label={props.dateFromLabel}
          value={props.dateFrom}
          onChange={props.onDateFromChange}
          autoOk={true}
          disableFuture
          maxDate={props.dateTo ? props.dateTo : new Date("2100-01-01")}
        />
      </Grid>
      <Grid item sm={6}>
        <DateField
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          label={props.dateToLabel}
          value={props.dateTo}
          onChange={props.onDateToChange}
          autoOk={true}
          disableFuture
          minDate={props.dateFrom ? props.dateFrom : new Date("1900-01-01")}
        />
      </Grid>
    </>
  );
}
