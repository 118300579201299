import { axiosInstance } from 'Services/Api/base-api';
import {
  GetCustomerDetailsApiReultType,
  GetCustomerListApiResultType,
  GetCustomerPermissionsDisabledApiResultType,
  GetCustomerRolesApiResultType,
  SaveCustomerDisabledPermissionsApiResultType,
  SaveCustomerRolesApiResultType,
} from "Types/api-types";
import {
  SaveCustomerDisabledPermissionsModelType,
  SaveCustomerRolesModelType,
} from "Types/customer-types";

export default class CustomerApi {
  getList = async (
    clientID: number | null
  ): Promise<GetCustomerListApiResultType> => {
    return await axiosInstance
      .get(
        "/Customer/GetList" + (clientID !== null ? "?clientID=" + clientID : "")
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getCustomerRoles = async (
    customerID: number
  ): Promise<GetCustomerRolesApiResultType> => {
    return await axiosInstance
      .get("/Customer/GetCustomerRoles?customerID=" + customerID)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getCustomerPermissionsDisabled = async (
    customerID: number
  ): Promise<GetCustomerPermissionsDisabledApiResultType> => {
    return await axiosInstance
      .get("/Customer/GetCustomerPermissionsDisabled?customerID=" + customerID)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  saveCustomerRoles = async (
    request: SaveCustomerRolesModelType
  ): Promise<SaveCustomerRolesApiResultType> => {
    return await axiosInstance
      .post("/Customer/SaveCustomerRoles", JSON.stringify(request))
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  saveCustomerPermissionsDisabled = async (
    request: SaveCustomerDisabledPermissionsModelType
  ): Promise<SaveCustomerDisabledPermissionsApiResultType> => {
    return await axiosInstance
      .post(
        "/Customer/SaveCustomerPermissionsDisabled",
        JSON.stringify(request)
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getDetails = async (
    customerID: number | null
  ): Promise<GetCustomerDetailsApiReultType> => {
    return await axiosInstance
      .get(
        "/Customer/GetDetails" +
          (customerID !== null ? "?customerID=" + customerID : "")
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };
}
