import * as React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { IAppEditorComponentProps } from "./interfaces";
import { AppUserTypeEnum } from "Common/enum-app";

const AppEditorComponentUser: (props: IAppEditorComponentProps) => JSX.Element =
  (props: IAppEditorComponentProps): JSX.Element => {
    return (
      <FormControl fullWidth size="small" onChange={props.handleChange}>
        <InputLabel id="app-user-type-select-id">Users</InputLabel>
        <Select
          name="appUserType"
          fullWidth
          labelId="app-user-type-select-id"
          value={props.value}
          onChange={props.handleChange}
        >
          <MenuItem value={AppUserTypeEnum.InternalUser}>iAdmin users</MenuItem>
          <MenuItem value={AppUserTypeEnum.ExternalUser}>
            Native user system
          </MenuItem>
          <MenuItem value={AppUserTypeEnum.NoUser}>No users</MenuItem>
        </Select>
      </FormControl>
    );
  };

export default AppEditorComponentUser;
