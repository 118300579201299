import React from "react";
import { SvgIcon } from "@material-ui/core";

const SvgExpandMenu = (
  <SvgIcon viewBox="0 0 22 11" style={{ padding: " 0 0 0 5px", width: "15px" }}>
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L10 10L19 1" stroke="currentColor" strokeWidth="2" />
    </svg>
  </SvgIcon>
);

export default SvgExpandMenu;