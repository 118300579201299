import * as React from "react";
import {
  GetReportDataTypeFormatListApiResponseType,
  GetReportColumnListType,
  GetReportDictionariesApiResponseType,
  SaveReportStoredProcedureParameterType,
  GetReportDetailsApiResponseType,
} from "../../../../Types/report-types";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton } from "@material-ui/core";
import Colors from "../../../../Common/colors";
import {
  ReportColumnDataType,
  ReportParameterDisplayNameType,
} from "../../../../Common/enum";
import { stylesEditReportColumnsListItem } from "./styles";
import SelectDisplayName from "./select-display-name";
import SelectDataTypeFormat from "./select-data-type-format";
import SelectAlignment from "./select-alignment";
import CheckboxVisible from "./checkbox-visible";
import CheckboxWrap from "./checkbox-wrap";
import SelectAggregate from "./select-aggreagate";
import SelectSize from "./select-size";
import SelectSort from "./select-sort";
import SelectSource from "./select-source";
import CheckboxEditable from "./checkbox-editable";
import CheckboxMaster from "./checkbox-master";
import CheckboxHidable from "./checkbox-hidable";

export interface EditReportColumnsListItemEditableProps {
  data: GetReportColumnListType;
  list: GetReportColumnListType[];
  dictionaries: GetReportDictionariesApiResponseType[];
  dataTypeFormatList: GetReportDataTypeFormatListApiResponseType[];
  reportData: GetReportDetailsApiResponseType;
  selectedRowOrderNo?: number;
  handleChangeColumns: (columns: GetReportColumnListType[]) => void;
  handleMouseDown: (orderNo: number) => void;
  handleMouseUp: () => void;
  handleMouseEnter: (orderNo: number) => void;
  handleMouseLeave: () => void;

  newAddedStoredProcedureParams: SaveReportStoredProcedureParameterType[];
  handleChangeNewStoredProcedureParam: (
    data: SaveReportStoredProcedureParameterType[]
  ) => void;
  isMasterDetailsParameters?: boolean;
}

const EditReportColumnsListItemEditable: React.FC<
  EditReportColumnsListItemEditableProps
> = ({
  data,
  list,
  dictionaries,
  dataTypeFormatList,
  handleChangeColumns,
  handleMouseDown,
  handleMouseUp,
  handleMouseEnter,
  handleMouseLeave,
  selectedRowOrderNo,
  newAddedStoredProcedureParams,
  handleChangeNewStoredProcedureParam,
  isMasterDetailsParameters,
  reportData,
}) => {
  const customStyles = stylesEditReportColumnsListItem();
  const formatListForDataType =
    dataTypeFormatList?.filter(
      (format) => Number(format.dataType) === Number(data.dataType)
    ) ?? [];
  const styleRow: string = `${customStyles.row} ${
    selectedRowOrderNo === data.orderNo ? customStyles.selectedRow : ""
  }`;

  return (
    <tr
      className={styleRow}
      onMouseEnter={() => handleMouseEnter(data.orderNo)}
      onMouseUp={() => handleMouseUp()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <td style={{ padding: 0 }} align="center">
        <IconButton
          onMouseDown={() => handleMouseDown(data.orderNo)}
          style={{ padding: "12px 0" }}
        >
          <MenuIcon htmlColor={Colors.graySeparate} />
        </IconButton>
      </td>
      <td>
        <SelectSort
          data={data}
          list={list}
          handleChangeColumns={handleChangeColumns}
        />
      </td>
      <td className={customStyles.disable}>{data.databaseName}</td>
      <td
        className={`${
          data.displayNameType === ReportParameterDisplayNameType.Procedure
            ? customStyles.disable
            : ""
        }`}
      >
        <SelectDisplayName
          data={data}
          list={list}
          reportData={reportData}
          handleChangeColumns={handleChangeColumns}
          newAddedStoredProcedureParams={newAddedStoredProcedureParams} // TODO change
          handleChangeNewStoredProcedureParam={
            handleChangeNewStoredProcedureParam
          }
        />
      </td>
      <td className={`${customStyles.disable}`}>
        <div>{ReportColumnDataType[data.dataType]}</div>
      </td>
      <td>
        <div>
          <SelectSource
            data={data}
            list={list}
            dictionaries={dictionaries}
            handleChangeColumns={handleChangeColumns}
            newAddedStoredProcedureParams={newAddedStoredProcedureParams}
            handleChangeNewStoredProcedureParam={
              handleChangeNewStoredProcedureParam
            }
          />
        </div>
      </td>
      <td
        className={
          formatListForDataType.length === 0 ? customStyles.disable : ""
        }
      >
        {formatListForDataType.length > 0 ? (
          <SelectDataTypeFormat
            data={data}
            list={list}
            dataTypeFormatList={formatListForDataType}
            handleChangeColumns={handleChangeColumns}
          />
        ) : (
          ""
        )}
      </td>
      <td>
        <SelectAlignment
          data={data}
          list={list}
          handleChangeColumns={handleChangeColumns}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <CheckboxVisible
          isEditable={true}
          data={data}
          list={list}
          handleChangeColumns={handleChangeColumns}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <CheckboxEditable
          isEditableField
          data={data}
          list={list}
          handleChangeColumns={handleChangeColumns}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <CheckboxHidable
          isEditable={true}
          data={data}
          list={list}
          handleChangeColumns={handleChangeColumns}
        />
      </td>
      <td width="50px">
        <SelectSize
          data={data}
          list={list}
          handleChangeColumns={handleChangeColumns}
        />
      </td>
      <td>
        <SelectAggregate
          data={data}
          list={list}
          handleChangeColumns={handleChangeColumns}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <CheckboxWrap
          isEditable={true}
          data={data}
          list={list}
          handleChangeColumns={handleChangeColumns}
        />
      </td>
      {isMasterDetailsParameters && (
        <td style={{ textAlign: "center" }}>
          <CheckboxMaster
            isEditable={true}
            data={data}
            list={list}
            handleChangeColumns={handleChangeColumns}
          />
        </td>
      )}
    </tr>
  );
};

export default EditReportColumnsListItemEditable;
