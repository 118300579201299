import * as React from "react";
import ButtonOutlined from "Components/Buttons/button-outlined";
import ButtonSolid from "Components/Buttons/button-solid";
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import { IBoundUnsavedChangesProps } from "../interfaces-bound";
import { UnsavedChangesButtonEnum } from "Common/enum";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import Colors from "Common/colors";

export default function BoundUnsavedChanges(props: IBoundUnsavedChangesProps) {
    return (
        <Dialog open={props.isOpen}>
            <DialogTitle>
                <TypographySubtitle text={'There are unsaved changes for:'}/>
                <TypographySubtitle color={Colors.gray} text={props.appName}/>
            </DialogTitle>
            <DialogActions>
                <ButtonOutlined margin="5px" text="Cancel" handleClick={() => props.handleBtnClick(UnsavedChangesButtonEnum.Cancel)} />
                <ButtonOutlined margin="5px" text="Discard" handleClick={() => props.handleBtnClick(UnsavedChangesButtonEnum.Discard)} />
                <ButtonSolid margin="5px" text="Save" onClickEvent={() => props.handleBtnClick(UnsavedChangesButtonEnum.Save)} />
            </DialogActions>
        </Dialog>
    )
}