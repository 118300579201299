import * as React from "react";
import { IReportsAccessRightsProps } from "Components/Reports/interfaces-reports";
import { Container } from "@material-ui/core";
import { AccessRightsDefaultTab } from "Common/enum";
import AccessRights from "Components/AccessRight/access-rights";

export default function ReportsAccessRights(props: IReportsAccessRightsProps) {

    return (
        <Container>
            <AccessRights
                client={props.client}
                defaultTab={AccessRightsDefaultTab.Reports}
            />
        </Container>
    )
}