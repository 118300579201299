import * as React from "react";
import { Select, MenuItem } from "@material-ui/core";
import { IUserHeaderProps } from "Components/User/interfaces-user";
import { Grid } from "@material-ui/core";
import { stylesUsersHeader } from "Components/User/UserHeader/styles";
import SvgUser from "Components/Images/image-user";
import { FormEditorEnum } from "Common/enum";
import UserEditor from "Components/User/UserEditor/user-editor";
import HeaderCard from "Components/HeaderCard/header-card";

export default function UsersHeader(props: IUserHeaderProps) {
  let customStyles = stylesUsersHeader();

  return (
    <HeaderCard>
      <Grid container alignItems="center">
        <Grid container item xs={9} alignItems="center">
          {!props.systemAppSelected ? (
            <Grid item className={customStyles.gridItem}>
              {props.customerList !== undefined &&
              props.customerList !== null ? (
                <Select
                  id="customer-select"
                  value={
                    props.selectedCustomerGuid ? props.selectedCustomerGuid : ""
                  }
                  onChange={props.onCustomerChange}
                  disableUnderline={true}
                  className={customStyles.selectedUserType}
                >
                  {props.customerList.map((c) => (
                    <MenuItem key={c.customerID} value={c.guid}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                ""
              )}
            </Grid>
          ) : (
            ""
          )}
          {!props.systemAppSelected ? (
            <Grid className={customStyles.gridItemApps}>
              {props.appTypeList !== undefined &&
              props.appTypeList !== null &&
              props.appTypeList.length > 0 ? (
                props.selectedAppFileUrl && props.selectedAppFileUrl !== "" ? (
                  <img src={props.selectedAppFileUrl} alt="" width="50px" />
                ) : (
                  <SvgUser className={customStyles.userImage} />
                )
              ) : (
                ""
              )}
            </Grid>
          ) : (
            ""
          )}
          <Grid item className={customStyles.gridItem}>
            {props.appTypeList !== undefined && props.appTypeList !== null ? (
              props.systemAppSelected ? (
                <div className={customStyles.selectedUserType}>
                  System users
                </div>
              ) : props.appTypeList.length < 1 ? (
                "-- No applications --"
              ) : (
                <Select
                  id="type-select"
                  value={props.selectedAppGuid ? props.selectedAppGuid : ""}
                  onChange={props.onAppChange}
                  className={customStyles.selectedUserType}
                  disableUnderline={true}
                  disabled={props.systemAppSelected}
                >
                  {props.appTypeList.map((app) =>
                    app.appGuid !== props.defaultAppGuid ? (
                      <MenuItem key={app.appID} value={app.appGuid}>
                        {" "}
                        {app.appName}
                      </MenuItem>
                    ) : (
                      ""
                    )
                  )}
                </Select>
              )
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid container item xs={3} alignItems="center" justify="flex-end">
          <Grid item className={customStyles.gridItem}>
            <UserEditor
              appGuid={props.selectedAppGuid}
              editorType={FormEditorEnum.Create}
              user={null}
              getUsers={props.getUsers}
              setUserData={null}
              systemAppSelected={props.systemAppSelected}
            />
          </Grid>
        </Grid>
      </Grid>
    </HeaderCard>
  );
}
