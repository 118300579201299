import * as React from "react";
import { ButtonActionProps } from "../interfaces";
import { stylesButtonAction } from "../styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const ButtonAction: (props: ButtonActionProps) => JSX.Element = (
  props: ButtonActionProps
): JSX.Element => {
  const customStyles: Record<string, string> = stylesButtonAction(props);
  const {
    isSubmit,
    color,
    className,
    isExecuting,
    disabled,
    handleClick,
    text,
    size,
  } = props;

  return (
    <React.Fragment>
      <Button
        variant="contained"
        type={isSubmit ? "submit" : "button"}
        color={color || "primary"}
        className={`${customStyles.button} ${className}`}
        size={size}
        disabled={isExecuting || disabled}
        onClick={handleClick}
      >
        {text}
      </Button>
      {isExecuting && (
        <CircularProgress
          size={25}
          thickness={6}
          className={customStyles.circularProgress}
        />
      )}
    </React.Fragment>
  );
};

export default ButtonAction;
