import * as React from "react";

const SvgLoginMiddle = ({
    style = {},
    fill = "none",
    width = "100%",
    height = "100%",
    className = "",
    viewBox = "0 0 1510 1080"
  }) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M0 540C0 241.766 241.766 0 540 0H1510V1080H540C241.766 1080 0 838.234 0 540Z" fill="white"/>
    </svg>
  );

  export default SvgLoginMiddle;