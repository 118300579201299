import {
  Checkbox,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { IconType } from "Common/enum";
import { PermissionModel } from "./models";
import { stylesDisabledPermissionsEditor } from "./styles";
import { onSearchChange } from "./utils";
import Colors from "Common/colors";
import ButtonOutlined from "Components/Buttons/button-outlined";
import SvgArrowRight from "Components/Images/image-arrow-right";
import SearchField from "Components/Inputs/SearchField/search-field";
import TypographyInfo from "Components/Typography/typography-info";
import React from "react";

export interface EnabledPermissionsProps {
  list: PermissionModel[];
  filteredList: PermissionModel[];
  setFilterd: (permissions: PermissionModel[]) => void;
  remove: (permissionsIDs: number[]) => void;
  addToDisabledPermissions: (permissions: PermissionModel[]) => void;
  toggleSelectPermission: (permissionID: number) => void;
}

const EnabledPermissions: React.FC<EnabledPermissionsProps> = ({
  list,
  filteredList,
  setFilterd,
  remove,
  addToDisabledPermissions,
  toggleSelectPermission,
}) => {
  const customStyles = stylesDisabledPermissionsEditor();

  const moveToSelected = async (): Promise<void> => {
    const selected = filteredList.filter((r) => r.isSelected);
    if (selected.length > 0) {
      remove(selected.map((r) => r.data.permissionID));
      addToDisabledPermissions(selected);
    }
  };

  const permissionsToMoveCount = filteredList.filter(
    (r) => r.isSelected
  ).length;

  return (
    <Grid container item sm={6} direction="column" alignItems="flex-start">
      <Grid item sm={12} alignItems="center">
        <TypographyInfo
          text={`Enabled permissions (${list.length})`}
          color={Colors.black}
        />
      </Grid>
      <Grid container item sm={12} alignItems="center" spacing={1}>
        <Grid item sm={8} alignItems="center">
          <SearchField
            onChangeEvent={(value: React.SetStateAction<string>) =>
              onSearchChange(value, list, setFilterd)
            }
            preventEnterKey
            marginTop="0px"
          />
        </Grid>
        <Grid item sm={4} alignItems="flex-end">
          <ButtonOutlined
            text={
              "Move" +
              (permissionsToMoveCount > 0 ? ` (${permissionsToMoveCount})` : "")
            }
            iconStartType={IconType.SvgImageIcon}
            iconStart={
              <SvgArrowRight
                width={"14px"}
                color={
                  permissionsToMoveCount > 0
                    ? Colors.grayDark
                    : Colors.grayInactive
                }
              />
            }
            size="md"
            handleClick={moveToSelected}
            disabled={permissionsToMoveCount < 1}
            borderColor={
              permissionsToMoveCount > 0 ? Colors.grayDark : Colors.grayInactive
            }
          />
        </Grid>
      </Grid>
      <Grid className={customStyles.list}>
        {filteredList.length > 0
          ? filteredList.map((permission: PermissionModel) => {
              return (
                <ListItem
                  key={permission.data.permissionID}
                  role={undefined}
                  dense
                  button
                  className={customStyles.listItem}
                  onClick={() =>
                    toggleSelectPermission(permission.data.permissionID)
                  }
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={permission.isSelected}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": permission.data.name,
                      }}
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={permission.data.permissionID.toString()}
                    primary={permission.data.name}
                  />
                </ListItem>
              );
            })
          : "No items"}
      </Grid>
    </Grid>
  );
};

export default EnabledPermissions;
