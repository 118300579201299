import * as React from 'react';
import { Grid, MenuItem, Select } from '@material-ui/core';
import Colors from 'Common/colors';
import TypographyInfo from 'Components/Typography/typography-info';
import { MobileWebApp } from 'Types/app-types';
import { IAppVersionWebAppItemProps } from './interfaces-app-version-editor';

const AppVersionWebAppItem: (
  props: IAppVersionWebAppItemProps
) => JSX.Element = (props: IAppVersionWebAppItemProps): JSX.Element => {
  const handleVersionChange: (value: number) => void = (
    value: number
  ): void => {
    if (props.handleVersionChange) {
      let newVersion: MobileWebApp = {
        webAppVersionRequired: value,
        mobileWebAppID: props.webAppWithVersions.mobileWebAppID,
      };
      props.handleVersionChange(newVersion);
    }
  };

  return (
    <React.Fragment>
      <Grid container item sm={4}>
        <Grid
          container
          item
          sm={11}
          style={{
            border: `1px solid ${Colors.graySeparate}`,
            padding: '5px',
            marginBottom: '5px',
          }}
          direction={'row'}
          justify={'space-between'}
          alignItems={'center'}
        >
          <TypographyInfo
            text={props.webAppWithVersions.name}
            margin={'0 10px'}
          />
          <Select
            value={
              props.mobileWebApp
                ? props.mobileWebApp.webAppVersionRequired
                : props.webAppWithVersions.versionList[0].apiVersionNo
            }
            onChange={(event) => {
              handleVersionChange(event.target.value as number);
            }}
          >
            {props.webAppWithVersions.versionList.map((version) => (
              <MenuItem
                key={`${props.webAppWithVersions.mobileWebAppID}_${version.apiVersionNo}`}
                value={version.apiVersionNo}
              >
                {version.apiVersionNo}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AppVersionWebAppItem;
