import * as React from "react";
import SvgMenuButton from "Components/Images/image-menu-button";
import { IButtonMenuProps, IButtonMenuItemProps } from "Components/Buttons/interfaces";
import { IconButton, Menu, MenuItem, Grid } from "@material-ui/core";

export default function ButtonMenu (props: IButtonMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    return (
        <div title={props.title}>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <SvgMenuButton />
            </IconButton>
            <Menu
                id="context-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                style: {
                    width: "140px",
                    background: "#FFFFFF",
                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
                    borderRadius: "3px",
                    color: "#444444"
                },
                }}
            >
                {props.options.map((option: IButtonMenuItemProps) => (
                <MenuItem key={option.text} disabled={!option.isEnabled} onClick={option.onClick}
                    style={{fontSize: "14px"}}
                    >
                    <Grid container direction="row">
                        <Grid item xs={3}>{option.icon ? option.icon : ""}</Grid>
                        <Grid item xs={9}>{option.text}</Grid>
                    </Grid>
                </MenuItem>
                ))}
            </Menu>
        </div>
    )
}