import * as React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { List } from '@material-ui/core';
import { IWebAppListProps } from '../interfaces-web-app';
import { WebServiceListItem } from './web-service-list-item';

export const WebServiceList = SortableContainer(
  ({ instanceList }: { instanceList: IWebAppListProps }) => (
    <List>
      {instanceList.instanceList.map((instance) => (
        <WebServiceListItem
          key={instance.id}
          index={instance.orderNo}
          instance={{
            instance: instance,
            getWebApp: instanceList.getWebApp,
            platformType: instanceList.platformType,
          }}
        />
      ))}
    </List>
  )
);
