import * as React from 'react';
import { ChangeEvent } from 'react';
import { GetReportParameterListType } from 'Types/report-types';
import { TextField } from '@material-ui/core';

export interface SelectDisplayNameProps {
  data: GetReportParameterListType;
  list: GetReportParameterListType[];
  handleChangeParameters: (parameters: GetReportParameterListType[]) => void;
}

function SelectDisplayName(props: SelectDisplayNameProps) {
  const { data, list, handleChangeParameters } = props;
  const [value, setValue] = React.useState<string>(data.displayName);

  const handleChangeDisplayName = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    parameterID: number
  ) => {
    handleChangeParameters(
      list.map((p) =>
        p.parameterID === parameterID ? { ...p, displayName: value } : p
      )
    );
  };

  React.useEffect(() => {
    setValue(data.displayName);
  }, [data.displayName]);

  return (
    <TextField
      id={`${data.parameterID}_displayName`}
      key={`${data.parameterID}_displayName`}
      name='displayName'
      InputProps={{
        disableUnderline: true,
        style: { fontSize: '14px' },
      }}
      value={value}
      onBlur={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        handleChangeDisplayName(event, data.parameterID)
      }
      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        setValue(event.target.value)
      }
      fullWidth
    />
  );
}

export default SelectDisplayName;
