import * as React from 'react';
import { ChangeEvent } from 'react';
import { GetReportColumnListType } from '../../../../Types/report-types';
import { TextField/*, Tooltip*/ } from '@material-ui/core';

export interface SelectSizeProps {
  data: GetReportColumnListType;
  list: GetReportColumnListType[];
  handleChangeColumns: (columns: GetReportColumnListType[]) => void;
}

const SelectSize: React.FC<SelectSizeProps> = ({
  data,
  list,
  handleChangeColumns,
}) => {
  const [value, setValue] = React.useState<string>(data.size.toString());

  const handleChange = (event: ChangeEvent<{}>, valueStr: string | null, changeColumns: boolean) => {
    if (valueStr != null) {
      const re = /^[0-9\b]+$/;
      if (valueStr !== '' || re.test(valueStr)) {
        let valueInt = Number(valueStr);
        if (valueInt < 1) {
          valueInt = 1;
        }
        setValue(valueInt.toString());
        if (changeColumns) {
          handleChangeColumns(
            list.map((p) =>
              p.columnID === data.columnID ? { ...p, size: valueInt } : p
            )
          );
        }
      }
      else if (valueStr === '') {
        setValue(valueStr);
        if (changeColumns) {
          handleChangeColumns(
            list.map((p) =>
              p.columnID === data.columnID ? { ...p, size: 0 } : p
            )
          );
        }
      }
    }
  };

  React.useEffect(() => {
    setValue(data.size === 0 ? '' : data.size.toString());
  }, [data.size]);

  return (
    <TextField
      id={`${data.columnID}_size`}
      key={`${data.columnID}_size`}
      name='size'
      type="number"
      InputProps={{
        disableUnderline: true,
        style: { fontSize: '14px' },
      }}
      inputProps={{ }}
      value={value}
      onBlur={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        handleChange(event, event.target.value, true)
      }
      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        handleChange(event, event.target.value, false)
      }
      fullWidth
    />
  );
};

export default SelectSize;
