import * as React from "react";

const SvgEnabledReport = ({
    style = {},
    fill = "none",
    color = "#7FBA00",
    width = "100%",
    height = "100%",
    className = "",
    viewBox = "0 0 19 18",
    title = ""
  }) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C10.6686 1 12.2179 1.51085 13.5 2.38468" stroke={color} strokeWidth="2" />
      <path d="M5 8L9 12L18 3" stroke={color} strokeWidth="2" />
      <title>{title}</title>
    </svg>
);

export default SvgEnabledReport;