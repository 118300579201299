import * as React from "react";
import * as Yup from "yup";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonTransparent from "Components/Buttons/button-transparent";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import PermissionApi from "Services/Api/permission-api";
import MultiSelectItems from "../../MultiSelectItems/multi-select-items";
import SvgPlusCircle from "Components/Images/image-plus-circle";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { AxiosError } from "axios";
import { Formik } from "formik";
import { IconType } from "Common/enum";
import { IPermissionEditorProps } from "../interfaces-permission-list";
import { isArray } from "lodash";
import { stylesPermissionEditor } from "./styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";

const permissionApi = new PermissionApi();

type FormikDataType = {
  name: string;
  number?: number;
  groupsIDs: number[];
};

export default function PermissionEditor(props: IPermissionEditorProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<
    AxiosError | null | string
  >(null);

  const NAME_MAX_LENGTH: number = 200;

  const customStyles = stylesPermissionEditor();

  const [isSelectedAll, setIsSelectedAll] = React.useState(false);

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setIsOpen(false);
  };

  const handleSave = (values: FormikDataType) => {
    props.setIsBlockedUi(true);
    permissionApi
      .savePermission({
        permissionName: values.name,
        permissionNumber: values.number,
        permissionGroupIDs: values.groupsIDs,
        permissionOrderNumber: 1,
        isEnabled: true,
        permissionDescription: "",
      })
      .then((response) => {
        if (response?.data?.isSaved) {
          setIsOpen(false);
          props.getPermissionList();
        } else {
          setIsErrorDialogOpen(true);
          setErrorResponse("Error");
        }
      })
      .catch((error) => {
        setIsErrorDialogOpen(true);
        setErrorResponse(error);
      })
      .finally(() => {
        props.setIsBlockedUi(false);
      });
  };

  const onKeyPressed = React.useCallback(
    (
      e: React.KeyboardEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
      >
    ) => {
      const keyCode = e.key.charCodeAt(0);
      if (
        e.key === "-" ||
        e.key === "+" ||
        e.key === "." ||
        e.key === "," ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 97 && keyCode <= 122)
      ) {
        e.preventDefault();
      }
    },
    []
  );

  return (
    <>
      <span style={{ height: "30px" }}>
        <ButtonTransparent
          text="Add permission"
          handleClick={() => setIsOpen(true)}
          padding="5px 2px 5px 10px"
          iconStart={<SvgPlusCircle width="20px" />}
          iconStartType={IconType.SvgImageIcon}
        />
      </span>
      <Dialog
        open={isOpen}
        maxWidth="sm"
        fullWidth
        onEscapeKeyDown={handleClose}
      >
        <Formik
          enableReinitialize={true}
          initialValues={
            {
              name: "",
              groupsIDs: [],
              number: undefined,
            } as FormikDataType
          }
          onSubmit={async (values) => {
            await handleSave(values);
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .label("Name")
              .required("Required")
              .max(NAME_MAX_LENGTH)
              .trim(),
            number: Yup.number()
              .required("Required")
              .moreThan(0, "Required")
              .label("Number"),
            groupsIDs: Yup.array<number>()
              .label("Permission groups")
              .required("Required"),
          })}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
            } = formProps;

            const handleSelectedGroupsChange = (
              event: React.ChangeEvent<{ value: unknown }>
            ): void => {
              let selectedValues = event.target.value as number[];
              if (selectedValues.some((id) => id === 0)) {
                if (isSelectedAll) {
                  setFieldValue("groupsIDs", []);
                } else {
                  setFieldValue(
                    "groupsIDs",
                    props.permissionGroupsList.map((a) => a.permissionGroupID)
                  );
                }
                setIsSelectedAll(!isSelectedAll);
              } else {
                setIsSelectedAll(false);
                setFieldValue(
                  "groupsIDs",
                  selectedValues.filter((s) => s > 0)
                );
              }
            };

            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogTitle>
                  <Grid container justify="space-between">
                    <TypographySubtitle
                      text="Add new permission"
                      margin="0px"
                    />
                  </Grid>
                </DialogTitle>

                <DialogContent
                  dividers
                  classes={{ root: customStyles.dialogContent }}
                >
                  <Grid
                    container
                    alignItems="stretch"
                    justify="space-between"
                    spacing={2}
                  >
                    <Grid container item justify="space-between">
                      <TextFieldEntered
                        id="name"
                        name="name"
                        label="Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.name && touched.name)}
                        helperText={errors.name && touched.name && errors.name}
                        inputProps={{
                          maxLength: NAME_MAX_LENGTH,
                        }}
                        className={customStyles.textField}
                        required
                      />
                      <TextFieldEntered
                        id="number"
                        name="number"
                        label="Number"
                        value={values.number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.number && touched.number)}
                        helperText={
                          errors.number && touched.number && errors.number
                        }
                        type="number"
                        className={customStyles.textField}
                        required
                        onKeyPress={onKeyPressed}
                      />
                    </Grid>
                    <Grid item container>
                      <MultiSelectItems
                        labelName="Permission groups"
                        className={customStyles.selectFormControl}
                        error={!!errors.groupsIDs && touched.groupsIDs}
                        fullWidth
                        helperText={
                          errors.groupsIDs &&
                          touched.groupsIDs &&
                          (isArray(errors.groupsIDs)
                            ? errors.groupsIDs.join(", ")
                            : errors.groupsIDs.toString())
                        }
                        renderValue={values.groupsIDs
                          .map(
                            (a) =>
                              props.permissionGroupsList.find(
                                (g) => g.permissionGroupID === a
                              )?.name ?? ""
                          )
                          .join(", ")}
                        itemList={props.permissionGroupsList.map((g) => ({
                          id: g.permissionGroupID,
                          name: `${g.name} ${
                            g.applications.length > 0
                              ? `- (${g.applications.join(", ")})`
                              : ""
                          }`,
                        }))}
                        isSelectedAll={isSelectedAll}
                        selectedItems={values.groupsIDs}
                        onSelectedChange={handleSelectedGroupsChange}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={customStyles.dialogActions}>
                  <ButtonSubaction text="Cancel" handleClick={handleClose} />
                  <ButtonAction isSubmit text="Save" />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={() => {
          setIsErrorDialogOpen(false);
          setErrorResponse(null);
        }}
      />
    </>
  );
}
