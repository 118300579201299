import { makeStyles, Theme } from "@material-ui/core";

export const stylesUsersHeader = makeStyles((theme: Theme) => ({
  editButton: {
    marginLeft: "25px",
  },
  gridItem: {
    marginRight: "15px",
    marginBottom: "0px",
  },
  link: {
    textDecoration: "none",
  },
  osIcon: {
    width: "35px",
    height: "35px",
    marginLeft: "10px",
    borderRadius: "8px",
  },
  paper: {
    padding: "10px 20px",
    borderRadius: "42px",
    marginTop: "30px",
    marginBottom: "20px",
  },
  selectedUserType: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "48px",
    color: theme.palette.grayDarker.main,
    borderBottom: "none",
  },
  userImage: {
    verticalAlign: "middle",
  },
}));
