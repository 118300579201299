import * as React from "react";
import * as Yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Switch,
  Grid,
} from "@material-ui/core";
import { IWebServiceInstanceEditorProps } from "Components/WebApp/WebServiceList/interfaces-web-service";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { AppPlatformEnum, FormEditorEnum, IconType } from "Common/enum";
import { Formik } from "formik";
import ButtonOutlined from "Components/Buttons/button-outlined";
import ButtonEdit from "Components/Buttons/ButtonEdit/button-edit";
import SvgPlusCircle from "Components/Images/image-plus-circle";
import ClientAppApi from "Services/Api/client-app-api";
import { WebAppInstanceType } from "Types/app-types";
import { AxiosError } from "axios";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";

const INSTANCE_NAME_MAX_LENGTH: number = 100;
const INSTANCE_URL_MAX_LENGTH: number = 200;
const INSTANCE_KEY_NAME_MAX_LENGTH: number = 50;
const INSTANCE_KEY_VALUE_MAX_LENGTH: number = 100;
const TOO_LONG_VALUE_INFO = "The value entered is too long";

const clientAppApi = new ClientAppApi();

export default function WebServiceInstanceEditor(
  props: IWebServiceInstanceEditorProps
) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [webAppInstance, setWebAppInstance] =
    React.useState<WebAppInstanceType>();
  const [isExecuting, setIsExecuting] = React.useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .label("Instance name")
      .max(INSTANCE_NAME_MAX_LENGTH, TOO_LONG_VALUE_INFO)
      .required("Required"),
    url: Yup.string()
      .label("URL")
      .max(INSTANCE_URL_MAX_LENGTH, TOO_LONG_VALUE_INFO)
      .required("Required"),
    fileUrl: Yup.string()
      .label("File URL")
      .max(INSTANCE_URL_MAX_LENGTH, TOO_LONG_VALUE_INFO)
      .nullable(),
    securityKeyName: Yup.string()
      .label("Security key name")
      .max(INSTANCE_KEY_NAME_MAX_LENGTH, TOO_LONG_VALUE_INFO),
    securityKeyValue: Yup.string()
      .label("Security key value")
      .max(INSTANCE_KEY_VALUE_MAX_LENGTH, TOO_LONG_VALUE_INFO),
  });

  const handleOpen = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setWebAppInstance(props.webAppInstanceObject);
    // await getWebAppInstance();
    setIsOpen(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setIsOpen(false);
  };

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const handleSave = async (instance: WebAppInstanceType): Promise<void> => {
    instance.securityKeyName = instance.isSecurityKeyRequired
      ? instance.securityKeyName
      : undefined;
    instance.securityKeyValue = instance.isSecurityKeyRequired
      ? instance.securityKeyValue
      : undefined;

    setIsExecuting(true);

    await clientAppApi
      .saveWebAppInstance(instance)
      .then(async (result) => {
        if (result && result.data) {
          props.getWebApp();
        }
        setIsOpen(false);
        setIsExecuting(false);
      })
      .catch((error) => {
        setIsExecuting(false);
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  let headerText = "New instance";
  let button = (
    <ButtonOutlined
      text="New"
      iconStartType={IconType.SvgImageIcon}
      iconStart={<SvgPlusCircle width="15px" />}
      size="md"
      margin="0 0 0 10px"
      handleClick={handleOpen}
    />
  );

  if (props.editorType === FormEditorEnum.Edit) {
    headerText = "Edit instance";
    button = <ButtonEdit iconWidth="25px" handleClick={handleOpen} />;
  }

  return (
    <>
      {button}
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          <TypographySubtitle text={headerText} />
        </DialogTitle>
        <Formik
          initialValues={{
            id: props.webAppInstanceId,
            appID: props.webAppId,
            name: webAppInstance ? webAppInstance.name : "",
            orderNo: webAppInstance ? webAppInstance.orderNo : -1,
            isSecurityKeyRequired: webAppInstance
              ? webAppInstance.isSecurityKeyRequired
              : true,
            isTokenRequired: webAppInstance
              ? webAppInstance.isTokenRequired
              : false,
            securityKeyName:
              webAppInstance && webAppInstance.securityKeyName
                ? webAppInstance.securityKeyName
                : "",
            securityKeyValue:
              webAppInstance && webAppInstance.securityKeyValue
                ? webAppInstance.securityKeyValue
                : "",
            url: webAppInstance ? webAppInstance.url : "",
            fileURL:
              webAppInstance && webAppInstance.fileURL
                ? webAppInstance.fileURL
                : "",
          }}
          onSubmit={async (values: WebAppInstanceType) => {
            await handleSave(values);
          }}
          validationSchema={validationSchema}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = formProps;
            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <Grid container item spacing={3}>
                    <Grid item sm={12}>
                      <TextFieldEntered
                        id="name"
                        name="name"
                        label="Instance name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.name && touched.name)}
                        helperText={
                          (errors.name && touched.name && errors.name) ||
                          `${values.name.length}/${INSTANCE_NAME_MAX_LENGTH}`
                        }
                        inputProps={{
                          maxLength: INSTANCE_NAME_MAX_LENGTH,
                        }}
                        required
                      />
                    </Grid>
                    {props.platformType === AppPlatformEnum.WebService ? (
                      <>
                        <Grid item sm={12}>
                          <TextFieldEntered
                            id="url"
                            name="url"
                            label="URL"
                            value={values.url}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!(errors.url && touched.url)}
                            helperText={
                              (errors.url && touched.url && errors.url) ||
                              `${values.url.length}/${INSTANCE_URL_MAX_LENGTH}`
                            }
                            inputProps={{
                              maxLength: INSTANCE_URL_MAX_LENGTH,
                            }}
                            required
                          />
                        </Grid>
                        <Grid item sm={12}>
                          <TextFieldEntered
                            id="fileURL"
                            name="fileURL"
                            label="File URL"
                            value={values.fileURL}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!(errors.fileURL && touched.fileURL)}
                            helperText={
                              (errors.fileURL &&
                                touched.fileURL &&
                                errors.fileURL) ||
                              `${values.fileURL?.length}/${INSTANCE_URL_MAX_LENGTH}`
                            }
                            inputProps={{
                              maxLength: INSTANCE_URL_MAX_LENGTH,
                            }}
                          />
                        </Grid>

                        <Grid container item sm={12}>
                          <FormGroup row style={{ width: "100%" }}>
                            <Grid item sm={6}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={values.isSecurityKeyRequired}
                                    onChange={handleChange}
                                    value={values.isSecurityKeyRequired}
                                    name="isSecurityKeyRequired"
                                  />
                                }
                                label="Security key"
                                labelPlacement="start"
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={values.isTokenRequired}
                                    onChange={handleChange}
                                    value={values.isTokenRequired}
                                    name="isTokenRequired"
                                  />
                                }
                                label="Token"
                                labelPlacement="start"
                              />
                            </Grid>
                          </FormGroup>
                        </Grid>

                        {values.isSecurityKeyRequired && (
                          <>
                            <Grid item sm={12}>
                              <TextFieldEntered
                                id="securityKeyName"
                                name="securityKeyName"
                                label="Security key name"
                                value={values.securityKeyName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  !!(
                                    errors.securityKeyName &&
                                    touched.securityKeyName
                                  )
                                }
                                helperText={
                                  (errors.securityKeyName &&
                                    touched.securityKeyName &&
                                    errors.securityKeyName) ||
                                  `${
                                    values.securityKeyName
                                      ? values.securityKeyName.length
                                      : 0
                                  }/${INSTANCE_KEY_NAME_MAX_LENGTH}`
                                }
                                inputProps={{
                                  maxLength: INSTANCE_KEY_NAME_MAX_LENGTH,
                                }}
                              />
                            </Grid>
                            <Grid item sm={12}>
                              <TextFieldEntered
                                id="securityKeyValue"
                                name="securityKeyValue"
                                label="Security key value"
                                value={values.securityKeyValue}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  !!(
                                    errors.securityKeyValue &&
                                    touched.securityKeyValue
                                  )
                                }
                                helperText={
                                  (errors.securityKeyValue &&
                                    touched.securityKeyValue &&
                                    errors.securityKeyValue) ||
                                  `${
                                    values.securityKeyValue
                                      ? values.securityKeyValue.length
                                      : 0
                                  }/${INSTANCE_KEY_VALUE_MAX_LENGTH}`
                                }
                                inputProps={{
                                  maxLength: INSTANCE_KEY_VALUE_MAX_LENGTH,
                                }}
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ButtonSubaction text="Cancel" handleClick={handleClose} />
                  <ButtonAction text="Save" isSubmit isExecuting={isExecuting} />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </>
  );
}
