import { makeStyles, Theme } from "@material-ui/core";

export const stylesPermissionEditor = makeStyles((theme: Theme) => ({
  selectFormControl: {
    minWidth: "120px",
  },
  dialogContent: {
    "&:first-child": {
      paddingTop: "5px",
    },
  },
  textField: {
    width: "245px",
  },
  dialogActions: {
    padding: "10px",
  },
}));
