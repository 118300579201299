import * as React from "react";
import DeviceList from "Components/Device/DeviceList/device-list";
import { IDeviceListProps } from "Components/Device/interfaces-device";
import DeviceListFilters from "Components/Device/DeviceList/device-list-filters";
import { cloneDeep } from 'lodash';
import { DeviceListSortByEnum } from "Common/enum";
import { DeviceType } from "Types/device-types";
import moment from "moment";

export default function DeviceListContainer(props: IDeviceListProps) {
    const [searchValue, setSearchValue] = React.useState('');
    const [releaseNumber, setReleaseNumber] = React.useState('');
    const [dateFrom, setDateFrom] = React.useState<Date | null>(null);
    const [dateTo, setDateTo] = React.useState<Date | null>(null);
    const [selectAll, setSelectAll] = React.useState(false);
    const [sortBy, setSortBy] = React.useState<DeviceListSortByEnum>(DeviceListSortByEnum.Newest);
    const [selectedSoftwareVersions, setSelectedSoftwareVersions] = React.useState<string[]>([]);
    const [deviceList, setDeviceList] = React.useState<DeviceType[]>();

    const sortDeviceList = React.useCallback((list: DeviceType[], selectedSorting?: DeviceListSortByEnum): DeviceType[] => {
        selectedSorting = selectedSorting ? selectedSorting : sortBy;
        if (selectedSorting) {
            switch(+selectedSorting) {
                case DeviceListSortByEnum.Newest:
                        list = list.sort((a, b) => moment(b.lastContactDateUTC).diff(a.lastContactDateUTC))
                    break;
                case DeviceListSortByEnum.Oldest:
                        list = list.sort((a, b) => moment(a.lastContactDateUTC).diff(b.lastContactDateUTC))
                    break;
                default:
                    break;
            }
        }
        return list;
    }, [sortBy])

    React.useEffect(() => {
        if (!deviceList && props.deviceList.length > 0) {
            setDeviceList(sortDeviceList(cloneDeep(props.deviceList), DeviceListSortByEnum.Newest));
        }
    }, [deviceList, props.deviceList, sortDeviceList]);

    const getUniqueOS = (): string[] => {
        return [...new Set(props.deviceList.map(device => device.osInfo))].sort();
    }

    const handleSearchValueChange = (value: React.SetStateAction<string>): void => {
        let searchedText: string = value.toString().toUpperCase();
        searchedText = searchedText ? searchedText : ' ';
        let devices: DeviceType[] = searchByText(cloneDeep(props.deviceList), searchedText);
        devices = searchByReleaseNumber(devices);
        devices = searchBySoftwareVersion(devices);
        devices = searchByDates(devices);
        devices = sortDeviceList(devices);
        setSearchValue(searchedText);
        setDeviceList(devices);
    }

    const handleReleaseNumberChange = (value: React.SetStateAction<string>): void => {
        let devices: DeviceType[];
        if (value) {
            const releaseNo: string = value.toString().toUpperCase();
            devices = searchByReleaseNumber(props.deviceList, releaseNo);
        }
        else {
            devices = props.deviceList;
        }
        devices = searchBySoftwareVersion(devices);
        devices = searchByDates(devices);
        devices = searchByText(devices);
        devices = sortDeviceList(devices);
        setReleaseNumber(value);
        setDeviceList(devices);
    }

    const handleDateFromChange = (value: React.SetStateAction<Date | null>): void => {
        let devices: DeviceType[];
        if (value) {
            const date = value as Date;
            devices = searchByDateFrom(props.deviceList, date);
        }
        else {
            devices = props.deviceList;
        }
        devices = searchByDateTo(devices);
        devices = searchByReleaseNumber(devices);
        devices = searchBySoftwareVersion(devices);
        devices = searchByText(devices);
        devices = sortDeviceList(devices);
        setDateFrom(value);
        setDeviceList(devices);
    }

    const handleDateToChange = (value: React.SetStateAction<Date | null>): void => {
        let devices: DeviceType[];
        if (value) {
            const date = value as Date;
            devices = searchByDateTo(props.deviceList, date);
        }
        else {
            devices = props.deviceList;
        }
        devices = searchByDateFrom(devices);
        devices = searchByReleaseNumber(devices);
        devices = searchBySoftwareVersion(devices);
        devices = searchByText(devices);
        devices = sortDeviceList(devices);
        setDateTo(value);
        setDeviceList(devices);
    }

    const handleSoftwareVersionsChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        let selectedValues = event.target.value as string[];
        let devices: DeviceType[] = props.deviceList;
        if (selectedValues.indexOf('All') > -1) {
            if (selectAll) {
                setSelectedSoftwareVersions([]);
            }
            else {
                setSelectedSoftwareVersions(getUniqueOS());
            }
            setSelectAll(!selectAll);
        }
        else {
            setSelectAll(false);
            setSelectedSoftwareVersions(selectedValues);
            if (selectedValues.length > 0) {
                devices = searchBySoftwareVersion(props.deviceList, selectedValues);
            }
        }
        devices = searchByReleaseNumber(devices);
        devices = searchByDates(devices);
        devices = searchByText(devices);
        devices = sortDeviceList(devices);
        setDeviceList(devices);
    } 

    const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        if (deviceList) {
            let selectedSorting = event.target.value as DeviceListSortByEnum;
            let devices: DeviceType[] = sortDeviceList(deviceList, selectedSorting);
            setSortBy(selectedSorting);
            setDeviceList(devices);
        }
    }

    const searchByText = (list: DeviceType[], text?: string): DeviceType[] => {
        text = text ? text : searchValue;
        if (text && text !== ' ') {
            list = list.filter(x => x.name.toUpperCase().includes(text!.toUpperCase()));
        }
        return list;
    }

    const searchByReleaseNumber = (list: DeviceType[], releaseNo?: string): DeviceType[] => {
        releaseNo = releaseNo ? releaseNo : releaseNumber
        if (releaseNo) {
            list = list.filter(x => x.mobileAppVersionNo === releaseNo);
        }
        return list;
    }

    const searchByDateFrom = (list: DeviceType[], date?: Date | null): DeviceType[] => {
        date = date ? date : dateFrom;
        if (date) {
            list = list.filter(x => moment(x.lastContactDateUTC).startOf('day') >= moment(date!).startOf('day'));
        }
        return list;
    }

    const searchByDateTo = (list: DeviceType[], date?: Date | null): DeviceType[] => {
        date = date ? date : dateTo;
        if (date) {
            list = list.filter(x => moment(x.lastContactDateUTC).startOf('day') <= moment(date!).startOf('day'));
        }
        return list;
    }

    const searchByDates = (list: DeviceType[]): DeviceType[] => {
        list = searchByDateFrom(list);
        list = searchByDateTo(list);
        return list;
    }

    const searchBySoftwareVersion = (list: DeviceType[], selectedValues?: string[]): DeviceType[] => {
        selectedValues = selectedValues ? selectedValues : selectedSoftwareVersions;
        if (selectedValues.length > 0) {
            list = list.filter(device => {
                return selectedValues!.includes(device.osInfo);
            });
        }
        return list;
    }

    return (
        <React.Fragment>
            <DeviceListFilters
                releaseNumber={releaseNumber}
                dateFrom={dateFrom}
                dateTo={dateTo}
                selectAll={selectAll}
                softwareVersions={getUniqueOS()}
                selectedSoftwareVersions={selectedSoftwareVersions}
                sortBy={sortBy}
                onReleaseNumberChange={handleReleaseNumberChange}
                onDateFromChange={handleDateFromChange}
                onDateToChange={handleDateToChange}
                onSearchChange={handleSearchValueChange}
                onSelectedSoftwareVersionsChange={handleSoftwareVersionsChange}
                onSortChange={handleSortChange}
            />
            <DeviceList deviceList={deviceList || []}/>
        </React.Fragment>
    )
}