import * as React from "react";
import {
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { stylesDateField } from "../styles";
import DateFnsUtils from "@date-io/date-fns";
import SvgCalendar from "Components/Images/image-calendar";

const DateTimeField: (props: KeyboardDateTimePickerProps) => JSX.Element = (
  props: KeyboardDateTimePickerProps
): JSX.Element => {
  const customStyles: Record<string, string> = stylesDateField(props);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        {...props}
        className={customStyles.root}
        keyboardIcon={<SvgCalendar />}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateTimeField;
