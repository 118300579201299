import * as _ from "lodash";
import * as React from "react";
import ButtonIcon from "Components/Buttons/button-icon";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MoveListEditor from "Components/Permission/MoveListEditor/move-list-editor";
import PermissionApi from "Services/Api/permission-api";
import PermissionNameEditor from "../PermissionNameEditor/permission-name-editor";
import TypographyInfo from "Components/Typography/typography-info";
import { deletePermissionWarning } from "./delete-permission-warning";
import { Grid, Link, Menu, MenuItem, useTheme } from "@material-ui/core";
import { IconType } from "Common/enum";
import { IPermissionListItemProps } from "../interfaces-permission-list";
import { RouteReportEdit } from "Configuration/Routes";
import { stylesPermissionListItem } from "../styles";
import ConfirmDialog, {
  useConfirmDialog,
} from "Components/Dialogs/ConfirmDialog/confirm-dialog";
import {
  SavePermissionGroupPermissionsModelType,
  SaveReportConatinerPermissionsModelType,
} from "Types/permission-types";

const permissionApi = new PermissionApi();

type CellElementType = {
  id: number;
  value: string;
  isEnabled: number;
};

export default function PermissionListItem(props: IPermissionListItemProps) {
  const {
    permission,
    getPermissionList,
    setIsBlockedUi,
    permissionGroupsList,
    reportsContainerList,
    setErrorResponse,
    setIsErrorDialogOpen,
    openInfoDialog,
  } = props;
  const theme = useTheme();
  const customStyles = stylesPermissionListItem();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { confirmDialogData, openConfirmDialog, closeConfirmDialog } =
    useConfirmDialog();
  const [isOpenNameEditor, setIsOpenNameEditor] = React.useState(false);
  const [isOpenPermissionGroupsEditor, setIsOpenPermissionGroupsEditor] =
    React.useState(false);
  const [isOpenContainersEditor, setIsOpenContainersEditor] =
    React.useState(false);

  const enabledRoleColor = theme.palette.primary.main;
  const disabledRoleColor = theme.palette.secondary.main;

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditName = () => {
    setIsOpenNameEditor(true);
  };
  const handleEditPermissionGroup = () => {
    setIsOpenPermissionGroupsEditor(true);
  };
  const handleEditContainers = () => {
    setIsOpenContainersEditor(true);
  };

  const handleDelete = () => {
    openConfirmDialog({
      text: deletePermissionWarning(permission),
      handleCancelClick: handleCancelDelete,
      handleOkClick: handleConfirmDelete,
    });
  };

  const handleCancelDelete = () => {
    closeConfirmDialog();
  };

  const handleConfirmDelete = () => {
    setIsBlockedUi(true);
    permissionApi
      .deletePermission({ permissionID: permission.permissionId })
      .then((response) => {
        if (response?.data?.isDeleted) {
          closeConfirmDialog();
          openInfoDialog("Permission deleted");
          getPermissionList();
        } else {
          setIsBlockedUi(false);
          setIsErrorDialogOpen(true);
          setErrorResponse("Error");
        }
      })
      .catch((error) => {
        setIsBlockedUi(false);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const handleSavePermissionGroup = (selected: number[]) => {
    setIsOpenPermissionGroupsEditor(false);

    if (selected.length === 0) {
      openConfirmDialog({
        text: "When all permission groups are unassigned, the permission will be removed from permission list",
        handleCancelClick: closeConfirmDialog,
        handleOkClick: () => {
          confirmEditPermissionGroups(selected);
        },
      });
      return;
    }
    confirmEditPermissionGroups(selected);
  };

  const confirmEditPermissionGroups = (selected: number[]) => {
    setIsBlockedUi(true);
    permissionApi
      .savePermissionGroupPermissions([
        ...selected.map(
          (id) =>
            ({
              permissionID: permission.permissionId,
              permissionGroupID: id,
              isDeleted: false,
            } as SavePermissionGroupPermissionsModelType)
        ),
        ...permission.permissionsGroups
          .filter((p) => !selected.some((id) => id === p.permissionGroupID))
          .map(
            (g) =>
              ({
                permissionID: permission.permissionId,
                permissionGroupID: g.permissionGroupID,
                isDeleted: true,
              } as SavePermissionGroupPermissionsModelType)
          ),
      ])
      .then((response) => {
        if (response?.data?.isSaved) {
          getPermissionList();
        } else {
          setIsBlockedUi(false);
          setIsErrorDialogOpen(true);
          setErrorResponse("Error");
        }
      })
      .catch((error) => {
        setIsBlockedUi(false);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };
  const handleSaveReportContainers = (selected: number[]) => {
    setIsBlockedUi(true);
    permissionApi
      .saveReportContainerPermissions([
        ...selected.map(
          (id) =>
            ({
              permissionID: permission.permissionId,
              reportContainerID: id,
              isDeleted: false,
            } as SaveReportConatinerPermissionsModelType)
        ),
        ...permission.reportContainers
          .filter((p) => !selected.some((id) => id === p.reportContainerID))
          .map(
            (c) =>
              ({
                permissionID: permission.permissionId,
                reportContainerID: c.reportContainerID,
                isDeleted: true,
              } as SaveReportConatinerPermissionsModelType)
          ),
      ])
      .then((response) => {
        if (response?.data?.isSaved) {
          setIsOpenPermissionGroupsEditor(false);

          getPermissionList();
        } else {
          setIsBlockedUi(false);
          setIsErrorDialogOpen(true);
          setErrorResponse("Error");
        }
      })
      .catch((error) => {
        setIsBlockedUi(false);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const cellWithList = (items: CellElementType[]) => (
    <td className={customStyles.cell}>
      <Grid container item xs={12} alignItems="center">
        {_.orderBy(items, (item) => item.value.toLocaleLowerCase(), [
          "asc",
        ]).map((elem, index, array) => (
          <TypographyInfo
            fontSize="14px"
            margin="0 5px 0 0"
            key={elem.id}
            text={`${elem.value}${index < array.length - 1 ? ", " : ""}`}
            color={elem.isEnabled ? enabledRoleColor : disabledRoleColor}
          />
        ))}
      </Grid>
    </td>
  );

  return (
    <>
      <tr>
        <td className={customStyles.cell}>
          <TypographyInfo
            text={permission.permissionNumber}
            fontSize="14px"
            margin="0"
            color={permission.isEnabled ? enabledRoleColor : disabledRoleColor}
          />
        </td>
        <td className={customStyles.cell}>
          <TypographyInfo
            text={permission.permissionName}
            fontSize="14px"
            margin="0"
            color={permission.isEnabled ? enabledRoleColor : disabledRoleColor}
          />
        </td>

        {cellWithList(
          permission.permissionsGroups.map((group) => ({
            id: group.permissionGroupID,
            value: group.permissionGroupName,
            isEnabled: group.isEnabled,
          }))
        )}

        {cellWithList(
          permission.appList.map((app) => ({
            id: app.appID,
            value: app.appName,
            isEnabled: 1,
          }))
        )}
        {cellWithList(
          permission.reportContainers.map((container) => ({
            id: container.reportContainerID,
            value: container.reportContainerName,
            isEnabled: container.isEnabled,
          }))
        )}
        <td className={customStyles.cell}>
          <Grid container item xs={12} alignItems="center">
            {_.orderBy(
              permission.reports,
              (r) => r.reportName.toLocaleLowerCase(),
              ["asc"]
            ).map((r, index, array) => (
              <Link
                href={`${RouteReportEdit}/${r.reportID}`}
                underline="hover"
                key={r.reportID}
                style={{
                  fontSize: "14px",
                  marginRight: "5px",
                  color: r.isEnabled ? enabledRoleColor : disabledRoleColor,
                }}
              >
                {`${r.reportName}${index < array.length - 1 ? ", " : ""} `}
              </Link>
            ))}
          </Grid>
        </td>
        <td className={`${customStyles.cell} ${customStyles.cellActions}`}>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            keepMounted
          >
            <MenuItem onClick={handleEditName}>Edit name</MenuItem>
            <MenuItem onClick={handleEditPermissionGroup}>
              Edit permission group
            </MenuItem>
            {(permission.reports ?? []).length === 0 && (
              <MenuItem onClick={handleEditContainers}>
                Edit containers
              </MenuItem>
            )}
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
          <ButtonIcon
            iconType={IconType.SvgImageIcon}
            icon={<MoreVertIcon />}
            handleClick={handleMenuOpen}
          />
          <ConfirmDialog
            text={confirmDialogData.text}
            isOpen={confirmDialogData.isOpen}
            handleOkClick={confirmDialogData.handleOkClick}
            handleCancelClick={confirmDialogData.handleCancelClick}
          />
        </td>
      </tr>
      <PermissionNameEditor
        setIsBlockedUi={setIsBlockedUi}
        getPermissionList={getPermissionList}
        id={permission.permissionId}
        name={permission.permissionName}
        isOpen={isOpenNameEditor}
        setIsOpen={setIsOpenNameEditor}
      />
      <MoveListEditor
        isOpen={isOpenPermissionGroupsEditor}
        close={() => setIsOpenPermissionGroupsEditor(false)}
        dialogTitle={`Edit groups for permission: ${permission.permissionName}`}
        save={handleSavePermissionGroup}
        allOptions={permissionGroupsList}
        selectedOptions={permission.permissionsGroups.map((p) => ({
          id: p.permissionGroupID,
          label: p.permissionGroupName,
        }))}
      />

      <MoveListEditor
        isOpen={isOpenContainersEditor}
        close={() => setIsOpenContainersEditor(false)}
        dialogTitle={`Edit containers for permission: ${permission.permissionName}`}
        save={handleSaveReportContainers}
        allOptions={reportsContainerList}
        selectedOptions={permission.reportContainers.map((c) => ({
          id: c.reportContainerID,
          label: c.reportContainerName,
        }))}
      />
    </>
  );
}
