import * as React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { GetPowerBIReportListApiResponseType } from 'Types/report-types';
import { Grid, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { AddReportFormikValuesType } from './types';

interface AddReportPowerBiProps {
  powerBiReports: GetPowerBIReportListApiResponseType[];
  selectedPowerBIReportId: string;
  handlePowerBiReportChange: (
    _event: React.ChangeEvent<{}>,
    value: GetPowerBIReportListApiResponseType | null,
    setFieldValue: any
  ) => void;
}

const AddReportPowerBi: React.FC<AddReportPowerBiProps> = ({
  powerBiReports,
  selectedPowerBIReportId,
  handlePowerBiReportChange,
}) => {
  const { errors, touched, setFieldValue, handleChange } =
    useFormikContext<AddReportFormikValuesType>();

  return (
    <Grid item sm={12}>
      <Autocomplete
        options={powerBiReports}
        disableClearable
        value={powerBiReports.find(
          (report) => report.reportId === selectedPowerBIReportId
        )}
        getOptionLabel={(option) => option.reportName}
        renderInput={(params) => (
          <TextField
            {...params}
            label="PowerBI Report*"
            variant="standard"
            error={!!(errors.powerBIReportId && touched.powerBIReportId)}
            helperText={
              errors.powerBIReportId &&
              touched.powerBIReportId &&
              errors.powerBIReportId
            }
          />
        )}
        onChange={(e, value) => {
          handlePowerBiReportChange(e, value, setFieldValue);
          handleChange(e);
        }}
      ></Autocomplete>
    </Grid>
  );
};
export default AddReportPowerBi;
