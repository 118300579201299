import * as React from "react";

const SvgMenuButton = ({
    style = {},
    fill = "black",
    width = "3",
    height = "19",
    className = "",
    viewBox = "0 0 3 19",
    title = ""
  }) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle cx="1.5" cy="1.5" r="1.5" fill={fill} />
      <circle cx="1.5" cy="9.5" r="1.5" fill={fill} />
      <circle cx="1.5" cy="17.5" r="1.5" fill={fill} />
      <title>{title}</title>
    </svg>
);

export default SvgMenuButton;