import { axiosInstance } from "../base-api";
import { IInstanceApi } from "./interfaces";
import { ICheckAppIsRespondingForEveryReleaseResult } from "./interfaces-result";

export const useInstanceApi: () => IInstanceApi = (): IInstanceApi => {
  const checkAppIsRespondingForEveryRelease: (
    instanceId: number
  ) => Promise<ICheckAppIsRespondingForEveryReleaseResult> = async (
    instanceId: number
  ): Promise<ICheckAppIsRespondingForEveryReleaseResult> => {
    return await axiosInstance
      .get(
        "/Instance/CheckAppIsRespondingForEveryRelease?instanceId=" + instanceId
      )
      .then((response) => response.data);
  };
  return { checkAppIsRespondingForEveryRelease };
};
