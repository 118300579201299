import * as _ from 'lodash';
import * as React from 'react';
import ButtonMenu from 'Components/Buttons/ButtonMenu/button-menu';
import moment from 'moment';
import SvgEnabledReport from 'Components/Images/Reports/image-enabled-report';
import SvgExportXml from 'Components/Images/image-export-xml';
import SvgTrash from 'Components/Images/image-trash';
import { IButtonMenuItemProps } from 'Components/Buttons/interfaces';
import { IReportsListItemProps } from 'Components/Reports/interfaces-reports';
import { ReportSubtypeEnum, ReportTypeEnum } from 'Common/enum';
import { RouteReportEdit } from 'Configuration/Routes';
import { stylesReportsListItem } from 'Components/Reports/Reports/ReportsList/styles';
import { Link, useTheme } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { ReportSubtypeEnumLabels } from './types';

export default function ReportsListItem(props: IReportsListItemProps) {
  const customStyles = stylesReportsListItem();
  const theme = useTheme();
  const menuOptions: IButtonMenuItemProps[] = [
    {
      icon: <SvgExportXml height="14" />,
      text: 'Export XML',
      isEnabled: true,
      onClick: () => {
        props.handleExportReport(
          props.report.reportID,
          props.report.reportName
        );
      },
    },
    {
      icon: <SvgTrash height="14" />,
      text: 'Delete',
      isEnabled: true,
      onClick: () => {
        props.handleDeleteReport(
          props.report.reportID,
          props.report.reportName
        );
      },
    },
    {
      icon: <Refresh className={customStyles.refreshIcon} />,
      text: 'Refresh',
      isEnabled: true,
      onClick: () => {
        props.handleRefreshReport(props.report.reportID);
      },
    },
  ];

  return (
    <tr
      className={`${customStyles.row} ${
        props.report.isEnabled ? '' : customStyles.disabledRow
      } ${props.isSelected ? customStyles.selectedRow : ''}`}
    >
      <td className={`${customStyles.cell} ${customStyles.cellIsEnabled}`}>
        {props.report.isEnabled ? (
          <SvgEnabledReport
            width="19"
            height="18"
            title="Active"
          ></SvgEnabledReport>
        ) : (
          ''
        )}
      </td>
      <td className={customStyles.cell} style={{ wordBreak: 'keep-all' }}>
        {ReportTypeEnum[props.report.reportType]}
        <br/>
        <span style={{color: theme.palette.secondary.main}}>
          {ReportSubtypeEnumLabels[props.report.reportSubtype ?? ReportSubtypeEnum.Regular]}
        </span> 
      </td>
      <td className={customStyles.cell}>
        <Link
          href={`${RouteReportEdit}/${props.report.reportID}`}
          underline="hover"
          className={
            props.report.isEnabled
              ? customStyles.cellLink
              : customStyles.disabledCellLink
          }
        >
          {props.report.reportName}
        </Link>
      </td>
      <td className={customStyles.cell}>
        {props.report.definitionProcedureName}
      </td>
      <td className={customStyles.cell}>
        {_.orderBy(
          props.report.containers?.map((c) => c.reportContainerName) ?? [],
          (c) => c.toLocaleLowerCase(),
          ['asc']
        ).join(', ')}
      </td>
      <td className={customStyles.cell}>{props.report.permissionName ?? ''}</td>
      <td className={customStyles.cell} style={{ wordBreak: 'break-word' }}>
        {props.report.modifiedByName?.trim().length
          ? props.report.modifiedByName
          : props.report.insertedByName}
        <br />
        {props.report.modificationDate
          ? moment(props.report.modificationDate).format('DD/MM/YYYY')
          : props.report.insertDate
          ? moment(props.report.insertDate).format('DD/MM/YYYY')
          : ''}
      </td>
      <td className={customStyles.cell}>
        <ButtonMenu options={menuOptions} title="Actions for report" />
      </td>
    </tr>
  );
}
