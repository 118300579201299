import * as React from 'react';
import { ReportParameterIsRequired } from '../../../../Common/enum';
import InputCheckbox from '../../../../Components/Inputs/input-checkbox';
import { GetReportParameterListType } from '../../../../Types/report-types';

export interface CheckboxRequiredProps {
  data: GetReportParameterListType;
  list: GetReportParameterListType[];
  isEditable: boolean;
  handleChangeParameters: (parameters: GetReportParameterListType[]) => void;
}

const CheckboxRequired: React.FC<CheckboxRequiredProps> = ({
  data,
  list,
  isEditable,
  handleChangeParameters,
}) => {
  const handleChangeRequired = (
    event: React.ChangeEvent<HTMLInputElement>,
    parameterID: number
  ): void => {
    const required: boolean = event.target.checked as boolean;

    handleChangeParameters(
      list.map((p) =>
        p.parameterID === parameterID
          ? {
              ...p,
              isRequired: required
                ? ReportParameterIsRequired.Required
                : ReportParameterIsRequired.NotRequired,
            }
          : p
      )
    );
  };

  return (
    <InputCheckbox
      disabled={!isEditable}
      checked={data.isRequired === ReportParameterIsRequired.Required}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleChangeRequired(event, data.parameterID)
      }
    />
  );
};

export default CheckboxRequired;
