import * as React from 'react';
import { filter } from './utils';
import { GetCustomerListResponseType } from 'Types/customer-types';
import { ICustomerListContainerProps } from './interfaces-customers';
import CustomerFilters from './customer-filters';
import CustomerList from './customer-list';
import { CustomerType } from 'Common/enum';

export default function UserListContainer(props: ICustomerListContainerProps) {
  const [searchValue, setSearchValue] = React.useState('');
  const [customerType, setCustomerType] = React.useState<CustomerType>(
    CustomerType.All
  );

  const [filteredCustomerList, setFilteredCustomerList] = React.useState<
    GetCustomerListResponseType[]
  >([]);

  React.useEffect(() => {
    const customerList: GetCustomerListResponseType[] = filter(
      props.customerList,
      searchValue,
      customerType
    );

    setFilteredCustomerList(customerList);
  }, [
    props.customerList,
    searchValue,
    customerType
  ]);

  const handleSearchValueChange = (
    value: React.SetStateAction<string>
  ): void => {
    const searchedText: string = value.toString().toLowerCase();
    setSearchValue(searchedText ?? '');
  };

  const handleCustomerTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const selectedCustomerType: number = event.target.value as number;
    setCustomerType(selectedCustomerType);
  };

  return (
    <React.Fragment>
      <CustomerFilters
        onSearchChange={handleSearchValueChange}
        onCustomerTypeChange={handleCustomerTypeChange}
        selectedCustomerType={customerType}
      />
      <CustomerList
        customers={filteredCustomerList}
        client={props.client}
      />
    </React.Fragment>
  );
}
