import { LandingPageEnum, ParamType } from 'Common/enum';

const LAST_VISITED_PAGE = 'lastVisitedPage';
const SELECTED_PAGE_PARAMS = 'selectedPageParams';

export type SavedPageParam = {
  paramType: ParamType;
  page: LandingPageEnum;
  value: string;
};

export const getLastVisitedPage = (): LandingPageEnum | undefined => {
  const key: string | null = localStorage.getItem(LAST_VISITED_PAGE);

  return key && !Number.isNaN(Number(key)) ? Number(key) : undefined;
};

export const setLastVisitedPage = (page: LandingPageEnum) => {
  localStorage.setItem(LAST_VISITED_PAGE, page.toString());
};

export const getSavedPageParams = (): SavedPageParam[] => {
  const value: string | null = localStorage.getItem(SELECTED_PAGE_PARAMS);

  return value ? JSON.parse(value) : [];
};

export const getSavedPageParamValue = (
  page: LandingPageEnum,
  paramType: ParamType
): string | undefined => {
  const savedParams = getSavedPageParams();

  return savedParams.find((p) => p.page === page && p.paramType === paramType)
    ?.value;
};

export const savePageParam = (param: SavedPageParam) => {
  const savedParams = getSavedPageParams();
  let isSaved = false;
  let params = savedParams.map((p) => {
    if (p.page === param.page && p.paramType === param.paramType) {
      isSaved = true;
      return {
        ...p,
        value: param.value,
      };
    } else {
      return p;
    }
  });
  if (!isSaved) {
    params.push(param);
  }
  localStorage.setItem(SELECTED_PAGE_PARAMS, JSON.stringify(params));
};
