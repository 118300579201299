import * as React from "react";

const SvgLogo = ({
  style = {},
  fill = "none",
  width = "891px",
  height = "274px",
  className = "",
  colorFill = "#7FBA00",
  viewBox = "0 0 891 274",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M0 49.8876V215H18V46L0 49.8876Z" fill={colorFill} />
    <path d="M0 31.8876L18 28V0L0 3.8876V31.8876Z" fill={colorFill} />
    <path
      d="M74.3505 55.6626C90.0033 49.1597 106.777 45.7816 123.727 45.7188C150.015 45.7188 176.646 59.5487 176.646 88.4657V218.764L157.101 222.879V201.391H156.416C152.107 208.365 145.977 214.03 138.685 217.777C131.394 221.523 123.219 223.208 115.04 222.65C80.7511 222.65 50.6911 200.362 50.6911 163.673C50.1287 155.332 51.3189 146.965 54.1851 139.111C57.0513 131.258 61.53 124.091 67.333 118.073C73.136 112.054 80.1347 107.318 87.8788 104.167C95.6229 101.017 103.941 99.5229 112.297 99.7811C128.942 100.824 144.686 107.705 156.759 119.212V91.4375C156.759 77.2647 139.614 65.4921 117.098 65.4921C100.119 67.4323 83.5409 71.9815 67.9499 78.9791L74.3505 55.6626ZM116.183 203.105C121.646 203.221 127.074 202.213 132.131 200.143C137.188 198.074 141.766 194.988 145.582 191.077C149.397 187.165 152.368 182.511 154.31 177.404C156.252 172.297 157.125 166.845 156.873 161.387C157.178 155.836 156.323 150.283 154.363 145.081C152.402 139.879 149.379 135.143 145.486 131.175C141.593 127.207 136.916 124.093 131.752 122.033C126.589 119.974 121.053 119.013 115.497 119.212C109.504 118.842 103.5 119.729 97.8696 121.815C92.2393 123.902 87.1072 127.142 82.8026 131.329C78.4979 135.515 75.1157 140.555 72.8729 146.125C70.6301 151.695 69.5761 157.672 69.7787 163.673C70.0073 188.59 93.5524 203.105 116.183 203.105Z"
      fill="#AAAAAA"
    />
    <path
      d="M360.892 4.00039L380.437 0V218.535L360.892 222.65V190.304H360.206C352.16 200.177 342.064 208.183 330.617 213.768C319.171 219.353 306.647 222.384 293.914 222.65C282.16 222.938 270.472 220.819 259.568 216.421C248.664 212.024 238.775 205.442 230.51 197.08C222.245 188.719 215.778 178.754 211.507 167.8C207.236 156.846 205.252 145.134 205.677 133.384C205.57 121.852 207.761 110.414 212.122 99.737C216.483 89.0602 222.926 79.3587 231.075 71.1984C239.225 63.0381 248.918 56.5823 259.589 52.2077C270.26 47.833 281.696 45.6272 293.228 45.7187C306.117 46.113 318.77 49.2774 330.327 54.9969C341.884 60.7163 352.075 68.8566 360.206 78.8648H360.892V4.00039ZM292.314 65.0349C274.404 65.4529 257.37 72.8621 244.853 85.678C232.336 98.4939 225.331 115.699 225.336 133.613C224.827 142.728 226.214 151.849 229.407 160.401C232.6 168.953 237.531 176.751 243.889 183.302C250.246 189.853 257.893 195.015 266.345 198.463C274.798 201.911 283.874 203.569 293 203.334C311.088 203.183 328.385 195.892 341.123 183.047C353.86 170.202 361.007 152.845 361.006 134.756C361.49 125.55 360.049 116.345 356.774 107.728C353.5 99.1111 348.464 91.2717 341.989 84.7106C335.514 78.1494 327.742 73.0108 319.169 69.623C310.596 66.2351 301.411 64.6725 292.199 65.0349H292.314Z"
      fill="#AAAAAA"
    />
    <path
      d="M421.467 221.279V49.8334L440.898 45.7188V74.5215H441.583C446.046 66.2841 452.532 59.3181 460.431 54.2792C468.329 49.2403 477.379 46.2944 486.73 45.7188C513.705 45.7187 523.763 54.8625 536.221 73.7215C541.353 65.4867 548.42 58.6309 556.806 53.7504C565.193 48.8699 574.645 46.1128 584.34 45.7188C615.314 45.7187 641.488 60.5773 641.488 95.5521V218.535L621.944 221.279V99.4382C622.236 94.6475 621.519 89.8488 619.839 85.3526C618.16 80.8564 615.555 76.7629 612.194 73.3368C608.833 69.9108 604.789 67.2287 600.326 65.4639C595.863 63.6991 591.078 62.891 586.283 63.0919C555.994 63.0919 541.479 83.2081 541.479 113.84V218.535L521.934 221.279V99.4382C522.226 94.6475 521.509 89.8488 519.83 85.3526C518.15 80.8564 515.546 76.7629 512.184 73.3368C508.823 69.9108 504.78 67.2287 500.316 65.4639C495.853 63.6991 491.069 62.891 486.273 63.0919C455.185 63.0919 440.898 83.2081 440.898 114.297V218.993L421.467 221.279Z"
      fill="#AAAAAA"
    />
    <path
      d="M754.987 221.279V49.8334L774.532 45.7188V73.4929H775.218C780.418 65.1855 787.6 58.2992 796.118 53.4516C804.636 48.6041 814.224 45.947 824.022 45.7188C856.597 45.7187 887 58.52 887 105.382V218.764L867.455 221.507V100.696C867.455 76.1217 851.568 63.0919 824.708 63.0919C806.192 63.0919 774.532 76.4646 774.532 108.811V218.193L754.987 221.279Z"
      fill="#AAAAAA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59 274C26.4152 274 0 247.585 0 215H18C18 237.644 36.3563 256 59 256H887L891 274H59Z"
      fill={colorFill}
    />
    <path d="M690 49.8877L708 46V219L690 222.888L690 49.8877Z" fill="#AAAAAA" />
    <path d="M708 28L690 31.8877V3.8877L708 0V28Z" fill="#AAAAAA" />
  </svg>
);

export default SvgLogo;
