import { makeStyles, Theme } from '@material-ui/core';

export const stylesTextFieldEntered = makeStyles((theme: Theme) => ({
  root: {
    padding: "0px",
    width: "100%",
    "& .MuiInputBase-root": {
      color: theme.palette.primary.main,
      padding: "6px 0px",
      "& input": {
        lineHeight: "21px",
        fontSize: "14px",
        padding: "0px",
      },
      "& textarea": {
        lineHeight: "21px",
        fontSize: "14px",
        padding: "0px",
        marginBottom: "-3px",
      },
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.secondary.main,
      padding: "0px",
      fontSize: "14px",
    },
    "& .Mui-error": {
      color: "red",
    },
    "& :disabled": {
      color: "rgba(0, 0, 0, 0.38)",
    },
  },
}));
