import { Grid, MenuItem, Select } from '@material-ui/core';
import { IReportsHeaderProps } from 'Components/Reports/interfaces-reports';
import { stylesReportsHeader } from 'Components/Reports/Reports/ReportsHeader/styles';
import * as React from 'react';

export default function ReportsHeader(props: IReportsHeaderProps) {
  const customStyles = stylesReportsHeader();

  return (
    <Grid container item alignItems="center" className={customStyles.title}>
      {props.applications !== undefined && props.applications !== null ? (
        <Select
          id="app-select"
          value={props.selectedAppGuid}
          onChange={props.onAppChange}
          className={customStyles.selectedApp}
          disableUnderline
        >
          {props.applications.map((app) => (
            <MenuItem key={app.appID} value={app.appGuid}>
              {`${app.appName} reports`}
            </MenuItem>
          ))}
        </Select>
      ) : (
        ''
      )}
    </Grid>
  );
}
