import * as React from "react";
import { Box, Grid } from "@material-ui/core";
import SelectParamDBName from "./select-param-db-name";
import SelectParamDataType from "./select-param-data-type";
import {
  ReportParameterDataType,
  IconType,
} from "../../../../../../Common/enum";
import SelectParamDefault from "./select-param-default";
import { ReportColumnDisplayNameProcedureParameterType } from "../../../../../../Types/report-types";
import ButtonIcon from "Components/Buttons/button-icon";
import DeleteIcon from "@material-ui/icons/Delete";

export interface EditParamProps {
  data: ReportColumnDisplayNameProcedureParameterType;
  edit: (data: ReportColumnDisplayNameProcedureParameterType) => void;
  deleteParam: () => void;
}

const EditParam: React.FC<EditParamProps> = ({ data, edit, deleteParam }) => {
  const { defaultValueText, dataType, databaseName } = data;

  const handleChangeDatabaseName = (name: string) => {
    edit({ ...data, databaseName: name });
  };

  const handleChangeDefault = (text: string) => {
    edit({ ...data, defaultValueText: text });
  };

  const handleChangeDataType = (dataType: ReportParameterDataType) => {
    edit({ ...data, dataType });
  };

  return (
    <Grid container direction="column" wrap="nowrap" item spacing={1}>
      <Grid container item>
        <Box
          display="flex"
          alignItems="flex-end"
          gridGap="10px"
          justifyContent="space-between"
          width="100%"
        >
          <SelectParamDBName
            name={databaseName}
            handleChange={(name: string) => handleChangeDatabaseName(name)}
          />
          <ButtonIcon
            iconType={IconType.SvgImageIcon}
            icon={<DeleteIcon color="error" />}
            handleClick={() => deleteParam()}
            width="20px"
            height="20px"
          />
        </Box>
      </Grid>
      <Grid container item>
        <SelectParamDataType
          dataType={dataType}
          handleChange={(type: ReportParameterDataType) =>
            handleChangeDataType(type)
          }
        />
      </Grid>
      <Grid container item>
        <SelectParamDefault
          text={defaultValueText}
          handleChange={(text: string) => handleChangeDefault(text)}
        />
      </Grid>
      <Grid
        container
        item
        justify="space-between"
        alignContent="center"
        alignItems="center"
      ></Grid>
    </Grid>
  );
};

export default EditParam;
