import { makeStyles } from '@material-ui/core';
import Colors from '../../../../Common/colors';

export const stylesEditReportColumns = makeStyles({
  gridItem: {
    padding: '0 15px',
  },
  paper: {
    padding: '5px 30px',
    borderRadius: '70px',
    marginTop: '30px',
  },
  subinfo: {
    fontSize: '14px',
    lineHeight: '21px',
    color: '#959595',
  },
});

export const stylesFont = makeStyles({
  input: {
    fontSize: '14px',
    lineHeight: '21px',
    color: Colors.grayDark,
  },
  inputRoot: {
    flexWrap: 'nowrap',
    '& .MuiAutocomplete-input': {
      minWidth: '100px',
    },
  },
});

export const stylesEditReportColumnsListItem = makeStyles({
  secondParamDataRange: {
    borderTop: 'none',
  },
  row: {
    '& td': {
      fontSize: '14px',
      lineHeight: '21px',
      paddingLeft: '8px',
      wordBreak: 'break-all',
      border: `1px solid ${Colors.graySeparate}`,
      borderBottom: 'none',
      '&:first-child': {
        borderLeft: 'none',
      },
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
  selectedRow: {
    borderBottom: `4px solid ${Colors.gray}`,
    borderTop: `4px solid ${Colors.gray}`,
  },
  selectedRowFirstParam: {
    borderBottom: 'none',
  },
  selectedRowSecondParam: {
    borderTop: 'none',
  },
  disable: {
    backgroundColor: Colors.grayLight,
    color: '#959595',
  },
});
