import * as React from "react";
import Colors from "Common/colors";
import { makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ITypographySubinfoProps } from "./interfaces-typography";
import parse from "html-react-parser";
import ShowMore from "react-show-more";

const styles = makeStyles({
  text: (props: ITypographySubinfoProps) => ({
    color: props.color ?? Colors.gray,
    fontSize: props.fontSize ?? "12px",
    margin: props.margin ?? "5px 0",
    textTransform: props.isUppercase ? "uppercase" : "none",
  }),
  iconStart: (props: ITypographySubinfoProps) => ({
    paddingRight: "10px",
    color: props.iconStartColor ? props.iconStartColor : Colors.gray,
  }),
  iconEnd: (props: ITypographySubinfoProps) => ({
    color: props.iconEndColor ? props.iconEndColor : Colors.gray,
  }),
});

export default function TypographySubinfo(props: ITypographySubinfoProps) {
  const customStyles = styles(props);

  let prependIcon, appendIcon;
  if (props.iconStart) {
    prependIcon = (
      <FontAwesomeIcon
        icon={props.iconStart}
        className={customStyles.iconStart}
      />
    );
  }
  if (props.iconEnd) {
    appendIcon = (
      <FontAwesomeIcon icon={props.iconEnd} className={customStyles.iconEnd} />
    );
  }

  if (props.showMore) {
    return (
      <span className={customStyles.text} style={props.style ?? {}}>
        <ShowMore lines={2} more="Show more" less="Show less">
          {props.text.toString()}
        </ShowMore>
      </span>
    );
  } else {
    return (
      <span className={customStyles.text} style={props.style ?? {}}>
        {prependIcon}
        {parse(props.text.toString())}
        {appendIcon}
      </span>
    );
  }
}
