import * as React from "react";
import {
  ReportParameterIsRequired,
  ReportParameterIsVisible,
} from "../../../../Common/enum";
import InputCheckbox from "../../../Inputs/input-checkbox";
import { GetReportParameterListType } from "../../../../Types/report-types";

export interface CheckboxVisibleProps {
  data: GetReportParameterListType;
  list: GetReportParameterListType[];
  isEditable: boolean;
  handleChangeParameters: (parameters: GetReportParameterListType[]) => void;
}

const CheckboxVisible: React.FC<CheckboxVisibleProps> = ({
  data,
  list,
  isEditable,
  handleChangeParameters,
}) => {
  const handleChangeVisible = (
    event: React.ChangeEvent<HTMLInputElement>,
    parameterID: number
  ): void => {
    const visible: boolean = event.target.checked as boolean;

    handleChangeParameters(
      list.map((p) =>
        p.parameterID === parameterID
          ? {
              ...p,
              isVisible: visible
                ? ReportParameterIsVisible.Visible
                : ReportParameterIsVisible.NotVisible,
            }
          : p
      )
    );
  };

  return (
    <InputCheckbox
      disabled={!isEditable}
      checked={
        data.isRequired === ReportParameterIsRequired.Required ||
        data.isVisible === ReportParameterIsVisible.Visible
      }
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleChangeVisible(event, data.parameterID)
      }
    />
  );
};

export default CheckboxVisible;
