import * as React from "react";

const SvgTrash = ({
    style = {},
    fill = "none",
    width = "14",
    height = "14",
    className = "",
    color = "#444444",
    viewBox = "0 0 14 14"
  }) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M2.5 2.5H11.5V12C11.5 12.8284 10.8284 13.5 10 13.5H4C3.17157 13.5 2.5 12.8284 2.5 12V2.5Z" stroke={color} />
        <path d="M4.5 2.5H9.5V1.5C9.5 0.947715 9.05228 0.5 8.5 0.5H5.5C4.94772 0.5 4.5 0.947715 4.5 1.5V2.5Z" stroke={color} />
        <path d="M0 2.5H14" stroke={color} />
    </svg>
);

export default SvgTrash;