import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";
import ButtonSolid from "../../../../Buttons/button-solid";
import { ChangeEvent, Fragment } from "react";
import {
  GetReportColumnListType,
  GetReportDictionariesApiResponseType,
  GetReportParameterListType,
  GetReportStoredProcedureParameterType,
  SaveReportStoredProcedureParameterType,
} from "Types/report-types";
import { stylesSelectSourceDialog } from "./styles";
import {
  ReportParameterSourceType,
  ReportParameterDefaultValueType,
  ReportParameterIsRequired,
  ReportParameterDataType,
} from "Common/enum";
import SelectSourceType from "./select-source-type";
import SelectDictionary from "./select-dictionary";
import SelectProcedure from "./SelectProcedure/select-procedure";
import SelectIsMultiple from "./select-is-multiple";
import InfoDialog from "Components/Dialogs/InfoDialog/info-dialog";

export interface SelectSourceDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  dictionaries: GetReportDictionariesApiResponseType[];

  data: GetReportParameterListType | GetReportColumnListType;
  list: GetReportParameterListType[] | GetReportColumnListType[];
  handleChangeParameters?: (parameters: GetReportParameterListType[]) => void;
  handleChangeColumns?: (columns: GetReportColumnListType[]) => void;
  newAddedStoredProcedureParams: SaveReportStoredProcedureParameterType[];
  handleChangeNewStoredProcedureParam: (
    data: SaveReportStoredProcedureParameterType[]
  ) => void;
}

const SelectSourceDialog: React.FC<SelectSourceDialogProps> = ({
  isOpen,
  handleClose,
  dictionaries,
  data,
  list,
  handleChangeParameters,
  handleChangeColumns,
  newAddedStoredProcedureParams,
  handleChangeNewStoredProcedureParam,
}) => {
  const customStyles = stylesSelectSourceDialog();
  const [sourceType, setSourceType] = React.useState<ReportParameterSourceType>(
    data.sourceType
  );
  const [multiselect, setMultiselect] = React.useState<boolean>(
    data.isSourceMultiselect
  );

  const [procedureName, setProcedureName] = React.useState<string>(
    data.sourceProcedure
  );
  const [procedureParams, setProcedureParams] = React.useState<
    GetReportStoredProcedureParameterType[]
  >(data.storedProcedureParameters);

  const [addedProcedureParams, setAddedProcedureParams] = React.useState<
    SaveReportStoredProcedureParameterType[]
  >(newAddedStoredProcedureParams);

  const [selectedDictionary, setSelectedDictionary] = React.useState<
    GetReportDictionariesApiResponseType | undefined
  >(
    data.iaReportDictionaryIDSource
      ? dictionaries.find(
          (d) => d.dictionaryID === data.iaReportDictionaryIDSource
        )
      : undefined
  );

  const [infoDialogText, setInfoDialog] = React.useState<string | undefined>(
    undefined
  );

  React.useEffect(() => {
    setSourceType(data.sourceType);
  }, [data.sourceType]);

  React.useEffect(() => {
    setMultiselect(data.isSourceMultiselect);
  }, [data.isSourceMultiselect]);

  React.useEffect(() => {
    setProcedureName(data.sourceProcedure);
  }, [data.sourceProcedure]);

  React.useEffect(() => {
    setProcedureParams(data.storedProcedureParameters);
  }, [data.storedProcedureParameters]);

  React.useEffect(() => {
    setAddedProcedureParams(newAddedStoredProcedureParams);
  }, [newAddedStoredProcedureParams]);

  React.useEffect(() => {
    setSelectedDictionary(
      data.iaReportDictionaryIDSource
        ? dictionaries.find(
            (d) => d.dictionaryID === data.iaReportDictionaryIDSource
          )
        : undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.iaReportDictionaryIDSource]);

  const handleClickSelect = () => {
    if (sourceType === ReportParameterSourceType.Procedure) {
      if (
        procedureName == null ||
        !procedureName ||
        procedureName.trim().length === 0
      ) {
        setInfoDialog("Procedure name is required");
        return;
      }
      const invalidParams = [...procedureParams, ...addedProcedureParams].find(
        (p) =>
          p.databaseName == null ||
          !p.databaseName ||
          p.databaseName.trim().length === 0
      );

      if (invalidParams) {
        setInfoDialog("Parameters names are required");
        return;
      }
      if (handleChangeParameters) {
        handleChangeParameters(
          (list as GetReportParameterListType[]).map(
            (p: GetReportParameterListType) =>
              p.parameterID === (data as GetReportParameterListType).parameterID
                ? {
                    ...p,
                    sourceType,
                    sourceProcedure: procedureName,
                    iaReportDictionaryIDSource: undefined,
                    reportDictionarySourceName: "",
                    isSourceMultiselect: multiselect,
                    defaultValueText: "",
                    defaultValueType: ReportParameterDefaultValueType.Custom, //TODO:???
                    iaReportDictionaryItemIDDefaultValue: undefined,
                    reportDictionaryItemDefaultValue: "",
                    storedProcedureParameters: procedureParams,
                  }
                : p
          )
        );
      } else if (handleChangeColumns) {
        handleChangeColumns(
          (list as GetReportColumnListType[]).map(
            (p: GetReportColumnListType) =>
              p.columnID === (data as GetReportColumnListType).columnID
                ? {
                    ...p,
                    sourceType,
                    sourceProcedure: procedureName,
                    iaReportDictionaryIDSource: undefined,
                    reportDictionarySourceName: "",
                    isSourceMultiselect: multiselect,
                    defaultValueText: "",
                    defaultValueType: ReportParameterDefaultValueType.Custom, //TODO:???
                    iaReportDictionaryItemIDDefaultValue: undefined,
                    reportDictionaryItemDefaultValue: "",
                    storedProcedureParameters: procedureParams,
                  }
                : p
          )
        );
      }

      handleChangeNewStoredProcedureParam(addedProcedureParams);
    } else {
      if (handleChangeParameters) {
        handleChangeParameters(
          (list as GetReportParameterListType[]).map(
            (p: GetReportParameterListType) =>
              p.parameterID === (data as GetReportParameterListType).parameterID
                ? {
                    ...p,
                    sourceType,
                    sourceProcedure: "",
                    iaReportDictionaryIDSource:
                      selectedDictionary?.dictionaryID ?? undefined,
                    reportDictionarySourceName:
                      selectedDictionary?.dictionaryName ?? "",
                    isSourceMultiselect: multiselect,
                    defaultValueText: "",
                    defaultValueType:
                      ReportParameterDefaultValueType.DictionaryItem,
                    iaReportDictionaryItemIDDefaultValue: undefined,
                    reportDictionaryItemDefaultValue: "",
                    storedProcedureParameters: [],
                  }
                : p
          )
        );
      } else if (handleChangeColumns) {
        handleChangeColumns(
          (list as GetReportColumnListType[]).map(
            (p: GetReportColumnListType) =>
              p.columnID === (data as GetReportColumnListType).columnID
                ? {
                    ...p,
                    sourceType,
                    sourceProcedure: "",
                    iaReportDictionaryIDSource:
                      selectedDictionary?.dictionaryID ?? undefined,
                    reportDictionarySourceName:
                      selectedDictionary?.dictionaryName ?? "",
                    isSourceMultiselect: multiselect,
                    defaultValueText: "",
                    defaultValueType:
                      ReportParameterDefaultValueType.DictionaryItem,
                    iaReportDictionaryItemIDDefaultValue: undefined,
                    reportDictionaryItemDefaultValue: "",
                    storedProcedureParameters: [],
                  }
                : p
          )
        );
      }

      handleChangeNewStoredProcedureParam(
        addedProcedureParams.filter(
          (p) =>
            p.parameterID !==
            ((data as GetReportParameterListType).parameterID ??
              (data as GetReportColumnListType).columnID)
        )
      );
    }
    handleClose();
  };

  const handleChangeSelectedType = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSourceType(Number(value));
  };

  const handleChangeSelectedDictionary = (
    event: React.ChangeEvent<{}>,
    value: GetReportDictionariesApiResponseType | null
  ) => {
    setSelectedDictionary(value != null ? value : undefined);
  };

  const handleChangeSingleMultiply = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setMultiselect(value === "true");
  };

  const editParam = (param: GetReportStoredProcedureParameterType) => {
    setProcedureParams(
      procedureParams.map((p) =>
        p.parameterID === param.parameterID &&
        p.parameterStoredProcedureParameterID ===
          param.parameterStoredProcedureParameterID
          ? { ...param }
          : p
      )
    );
  };

  const addNewParam = () => {
    setAddedProcedureParams([
      ...addedProcedureParams,
      {
        parameterIndexID: addedProcedureParams.length,
        parameterID:
          (data as GetReportParameterListType).parameterID ??
          (data as GetReportColumnListType).columnID,
        databaseName: "",
        defaultValueText: "",
        dataType: ReportParameterDataType.String,
        isRequired: ReportParameterIsRequired.NotRequired,
      } as SaveReportStoredProcedureParameterType,
    ]);
  };

  const editAddedParam = (
    param:
      | SaveReportStoredProcedureParameterType
      | GetReportStoredProcedureParameterType
  ) => {
    const saveParam = param as SaveReportStoredProcedureParameterType;
    if (saveParam.parameterIndexID != null) {
      setAddedProcedureParams(
        addedProcedureParams.map((p) =>
          p.parameterID === param.parameterID &&
          p.parameterIndexID === saveParam.parameterIndexID
            ? ({ ...saveParam } as SaveReportStoredProcedureParameterType)
            : p
        )
      );
    }
  };

  const deleteParam = (
    param:
      | GetReportStoredProcedureParameterType
      | SaveReportStoredProcedureParameterType
  ) => {
    const saveParam = param as SaveReportStoredProcedureParameterType;
    if (saveParam.parameterIndexID != null) {
      setAddedProcedureParams(
        addedProcedureParams.filter(
          (p) =>
            p.parameterID !== saveParam.parameterID ||
            p.parameterIndexID !== saveParam.parameterIndexID
        )
      );
    } else {
      setProcedureParams(
        procedureParams.filter(
          (p) =>
            p.parameterID !== param.parameterID ||
            p.parameterStoredProcedureParameterID !==
              param.parameterStoredProcedureParameterID
        )
      );
    }
  };

  const resetData = () => {
    setSourceType(data.sourceType);
    setMultiselect(data.isSourceMultiselect);
    setProcedureName(data.sourceProcedure);
    setProcedureParams(data.storedProcedureParameters);
    setAddedProcedureParams(newAddedStoredProcedureParams);
    setSelectedDictionary(
      data.iaReportDictionaryIDSource
        ? dictionaries.find(
            (d) => d.dictionaryID === data.iaReportDictionaryIDSource
          )
        : undefined
    );
  };
  const close = () => {
    resetData();
    handleClose();
  };

  return (
    <Fragment>
      <Dialog
        open={isOpen}
        maxWidth="sm"
        fullWidth
        onClose={() => close()}
        onEscapeKeyDown={() => close()}
        // classes={{ paper: customStyles.dialogPaper }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent
          classes={{ root: customStyles.content }}
          style={{ overflow: "hidden" }}
        >
          <Grid
            container
            direction="column"
            style={{ height: "100%" }}
            wrap="nowrap"
          >
            <SelectSourceType
              sourceType={sourceType}
              handleChangeSelectedType={handleChangeSelectedType}
            />

            <Grid
              item
              container
              style={{ flex: 1, paddingTop: "8px", overflow: "auto" }}
            >
              {sourceType === ReportParameterSourceType.Dictionary ? (
                <SelectDictionary
                  dictionaries={dictionaries}
                  selectedDictionary={selectedDictionary}
                  handleChangeSelectedDictionary={
                    handleChangeSelectedDictionary
                  }
                />
              ) : sourceType === ReportParameterSourceType.Procedure ? (
                <SelectProcedure
                  procedureName={procedureName}
                  handleChangeProcedureName={(
                    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setProcedureName(event.target.value)}
                  procedureParams={procedureParams.filter(
                    (p) =>
                      p.parameterID ===
                      ((data as GetReportParameterListType).parameterID ??
                        (data as GetReportColumnListType).columnID)
                  )}
                  editParam={editParam}
                  addedParams={addedProcedureParams.filter(
                    (p) =>
                      p.parameterID ===
                      ((data as GetReportParameterListType).parameterID ??
                        (data as GetReportColumnListType).columnID)
                  )}
                  addNewParam={addNewParam}
                  editAddedParam={editAddedParam}
                  deleteParam={deleteParam}
                />
              ) : (
                ""
              )}
            </Grid>
            <SelectIsMultiple
              multiselect={multiselect}
              handleChangeSingleMultiply={handleChangeSingleMultiply}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonSolid
            text="Select"
            onClickEvent={handleClickSelect}
            margin="0 0 0 10px"
          />
        </DialogActions>
      </Dialog>
      <InfoDialog
        isOpen={!!infoDialogText}
        text={infoDialogText ?? ""}
        handleOkClick={() => setInfoDialog(undefined)}
      />
    </Fragment>
  );
};

export default SelectSourceDialog;
