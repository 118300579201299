import {
  GetPermissionListItemType,
  PermissionListAppItemType,
  PermissionListGroupItemType,
  PermissionListReportContainerItemType,
  PermissionListReportItemType,
} from "Types/role-types";

type PropertyListItemNameType<
  T, // T - type
  W extends keyof T, // W - property name which is in type, (T[W] is an array)
  Z extends keyof (T[W] extends Array<infer U> ? U : never) //Z - property name which is in W
> = {
  propertyList: keyof Pick<T, W>;
  propertyItemName: Z;
};

type PermissionPropertyListItemNameType<
  W extends keyof GetPermissionListItemType,
  Z extends keyof (GetPermissionListItemType[W] extends Array<infer U>
    ? U
    : never)
> = PropertyListItemNameType<GetPermissionListItemType, W, Z> & {
  label: string;
};

export const deletePermissionWarning = (
  permission: GetPermissionListItemType
) => {
  type GroupsType = PermissionPropertyListItemNameType<
    "permissionsGroups",
    "permissionGroupName"
  >;

  type AppsType = PermissionPropertyListItemNameType<"appList", "appName">;

  type ReportsType = PermissionPropertyListItemNameType<
    "reports",
    "reportName"
  >;

  type ContainersType = PermissionPropertyListItemNameType<
    "reportContainers",
    "reportContainerName"
  >;

  const listType = [
    {
      propertyList: "permissionsGroups",
      propertyItemName: "permissionGroupName",
      label: "Permission groups",
    } as GroupsType,
    {
      propertyList: "appList",
      propertyItemName: "appName",
      label: "Applications",
    } as AppsType,
    {
      propertyList: "reportContainers",
      propertyItemName: "reportContainerName",
      label: "Report containers",
    } as ContainersType,
    {
      propertyList: "reports",
      propertyItemName: "reportName",
      label: "Reports",
    } as ReportsType,
  ];

  type ListsTypes = GroupsType | ReportsType | ContainersType | AppsType;
  type ListsTypesKeys = ListsTypes["propertyList"];
  const toArray = <T extends ListsTypesKeys[]>(...args: T) => args;
  const listTypesKeysArray = toArray(
    "appList",
    "reports",
    "reportContainers",
    "permissionsGroups"
  );

  const permissionData = permission as Pick<
    GetPermissionListItemType,
    ListsTypesKeys
  >;

  const getList = (arg: ListsTypes) => {
    const propertyList: Array<
      | PermissionListGroupItemType
      | PermissionListReportItemType
      | PermissionListReportContainerItemType
      | PermissionListAppItemType
    > = permission[arg.propertyList];

    return `<br/><br/><b>${arg.label}:</b><br/>${propertyList
      .map((value) =>
        value[arg.propertyItemName as keyof typeof value].toString()
      )
      .join(", ")}`;
  };

  return `Are you sure to delete permission <b>${
    permission.permissionName
  }</b>${
    listTypesKeysArray.some((property) => permissionData[property].length)
      ? `<br/>with all assignments to objects? ${listType
          .map((a) => (permissionData[a.propertyList].length ? getList(a) : ""))
          .filter((a) => a.length)
          .join("")}`
      : ""
  }`;
};
