import { UserActiveType } from "Common/enum";
import { GetUserListResponseType } from "Types/user-types";

export const filter = (
  list: GetUserListResponseType[],
  textSearch: string,
  companyID: number,
  roleID: number,
  activity: UserActiveType
): GetUserListResponseType[] => {
  let userList = searchByText(list, textSearch);
  userList = searchByCompany(userList, companyID);
  userList = searchByRole(userList, roleID);
  userList = searchByActivity(userList, activity);
  return userList;
};

const searchByRole = (list: GetUserListResponseType[], roleID: number) => {
  if (roleID) {
    list = list.filter((user) =>
      user.customers.find((c) => c.roleID && c.roleID === roleID)
    );
  }
  return list;
};

const searchByCompany = (
  list: GetUserListResponseType[],
  companyID: number
) => {
  if (companyID) {
    list = list.filter((user) =>
      user.customers.find((c) => c.customerID && c.customerID === companyID)
    );
  }
  return list;
};

const searchByText = (
  list: GetUserListResponseType[],
  text?: string
): GetUserListResponseType[] => {
  if (text) {
    list = list.filter(
      (x) =>
        x.firstName.toLowerCase().includes(text!) ||
        x.lastName.toLowerCase().includes(text!) ||
        x.email?.toLowerCase().includes(text!) ||
        x.phoneNumber?.toLowerCase().includes(text)
    );
  }
  return list;
};

const searchByActivity = (
  list: GetUserListResponseType[],
  activity: UserActiveType
) => list.filter((user) => user.activeType === activity);
