import * as React from 'react';
import ButtonOutlined from 'Components/Buttons/button-outlined';
import SvgPlusCircle from 'Components/Images/image-plus-circle';
import TextFieldEntered from 'Components/Inputs/TextFieldEntered/text-field-entered';
import TypographyTitle from 'Components/Typography/typography-title';
import { Autocomplete } from '@material-ui/lab';
import { EMAIL_MAX_LENGTH, PHONE_NUMBER_MAX_LENGTH } from 'Common/consts';
import { GetCountryListResponseType } from 'Types/country-types';
import { GetUserLoginAppResult } from 'Types/app-types';
import { Grid, TextField } from '@material-ui/core';
import { IconType } from 'Common/enum';
import { stylesUserLoginListItem } from '../styles';
import { useFormikContext } from 'formik';
import { UserLoginListItemFormikValuesType } from '../formikValues';

export interface UserLoginListItemBasicDataProps {
  countryList: GetCountryListResponseType[];
}

const UserLoginListItemBasicData: (
  props: UserLoginListItemBasicDataProps
) => JSX.Element = (props: UserLoginListItemBasicDataProps): JSX.Element => {
  const { countryList } = props;

  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext<UserLoginListItemFormikValuesType>();

  const customStyles = stylesUserLoginListItem();

  // const handleAddApplication = () => {
  //   if (
  //     values.apps.length === 0 ||
  //     values.apps[values.apps.length - 1].appGuid !== ""
  //   ) {
  //     setFieldValue("apps", [
  //       ...values.apps,
  //       {
  //         appGuid: "",
  //         appName: "",
  //       } as GetUserLoginAppResult,
  //     ]);
  //   }
  // };

  return (
    <>
      <Grid container item xs={12}>
        <TypographyTitle text="Login" fontSize="18px" />
      </Grid>
      <Grid container item direction="row" alignItems="center">
        <Grid container item sm={8} alignItems="flex-start" spacing={4}>
          <Grid item sm={5}>
            <TextFieldEntered
              style={{ marginTop: "1px" }}
              id="email"
              name="email"
              label="Email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.email && touched.email)}
              helperText={
                (errors.email && touched.email && errors.email) ||
                `${values.email?.length ?? 0}/${EMAIL_MAX_LENGTH}`
              }
              inputProps={{
                maxLength: EMAIL_MAX_LENGTH,
              }}
            />
          </Grid>

          <Grid item sm={3} alignItems="center">
            <Autocomplete
              id="country"
              options={countryList}
              getOptionLabel={(country) =>
                `${country.name} ${country.phonePrefix}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!(errors.countryID && touched.countryID)}
                  helperText={
                    errors.countryID && touched.countryID && errors.countryID
                  }
                  label="Country"
                />
              )}
              size="small"
              onChange={(
                event: React.ChangeEvent<{}>,
                value: GetCountryListResponseType | null
              ) => {
                setFieldTouched("countryID");
                setFieldValue("countryID", value?.countryID);
              }}
              value={countryList.find((c) => c.countryID === values.countryID)}
            />
          </Grid>
          <Grid item sm={4}>
            <TextFieldEntered
              style={{ marginTop: "1px" }}
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              value={values.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.phoneNumber && touched.phoneNumber)}
              helperText={
                (errors.phoneNumber &&
                  touched.phoneNumber &&
                  errors.phoneNumber) ||
                `${
                  values.phoneNumber?.toString().length ?? 0
                }/${PHONE_NUMBER_MAX_LENGTH}`
              }
              inputProps={{
                maxLength: PHONE_NUMBER_MAX_LENGTH,
                inputMode: "decimal",
                pattern: "[0-9]*",
              }}
            />
          </Grid>
        </Grid>
        {/* <Grid container item sm={6} justify="flex-end">
          <ButtonOutlined
            text="Add application"
            iconStartType={IconType.SvgImageIcon}
            iconStart={<SvgPlusCircle width="14px" />}
            size="md"
            margin="0px 10px 0px 0px"
            handleClick={handleAddApplication}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

export default UserLoginListItemBasicData;
