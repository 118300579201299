import ButtonOutlined from 'Components/Buttons/button-outlined';
import Colors from 'Common/colors';
import InputSearch from 'Components/Inputs/input-search';
import React from 'react';
import SearchField from 'Components/Inputs/SearchField/search-field';
import SvgTrash from 'Components/Images/image-trash';
import TypographyInfo from 'Components/Typography/typography-info';
import { convertShareModeToSymbol } from 'Common/utils';
import { IconType } from 'Common/enum';
import { onSearchChange } from './utils';
import { RoleModel } from './models';
import { stylesRoleEditor } from './styles';
import {
  Checkbox,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

export interface CustomerRolesProps {
  list: RoleModel[];
  filteredList: RoleModel[];
  setFilterd: (roles: RoleModel[]) => void;
  remove: (roleIDS: number[]) => void;
  addToAllRoles: (roles: RoleModel[]) => void;
  toggleSelectRole: (roleID: number) => void;
}

const CustomerRoles: React.FC<CustomerRolesProps> = ({
  list,
  filteredList,
  setFilterd,
  remove,
  addToAllRoles,
  toggleSelectRole,
}) => {
  const customStyles = stylesRoleEditor();

  const deleteFromSelected = async (): Promise<void> => {
    const selected = filteredList.filter((r) => r.isSelected);
    if (selected.length > 0) {
      remove(selected.map((r) => r.data.roleID));
      addToAllRoles(selected);
    }
  };

  const rolesToDeleteCount = filteredList.filter((r) => r.isSelected).length;

  return (
    <Grid
      container
      item
      sm={6}
      direction="column"
      style={{ paddingLeft: 20 }}
      alignItems="flex-start"
    >
      <Grid item sm={12} alignItems="center">
        <TypographyInfo
          text={`Customer roles (${list.length})`}
          color={Colors.black}
        />
      </Grid>
      <Grid container item sm={12} alignItems="center" spacing={1}>
        <Grid item sm={8} alignItems="center">
          <SearchField
            onChangeEvent={(value: React.SetStateAction<string>) =>
              onSearchChange(value, list, setFilterd)
            }
            preventEnterKey
            marginTop="0px"
          />
        </Grid>
        <Grid item sm={4} alignItems="center">
          <ButtonOutlined
            text={`Delete${
              rolesToDeleteCount > 0 ? ` (${rolesToDeleteCount})` : ""
            }`}
            iconStartType={IconType.SvgImageIcon}
            iconStart={
              <SvgTrash
                width={"14px"}
                color={
                  rolesToDeleteCount > 0 ? Colors.grayDark : Colors.grayInactive
                }
              />
            }
            size="md"
            handleClick={deleteFromSelected}
            disabled={rolesToDeleteCount < 1}
            borderColor={
              rolesToDeleteCount > 0 ? Colors.grayDark : Colors.grayInactive
            }
          />
        </Grid>
      </Grid>
      <Grid className={customStyles.list}>
        {filteredList.length > 0
          ? filteredList.map((role: RoleModel) => {
              return (
                <ListItem
                  key={role.data.roleID}
                  role={undefined}
                  dense
                  button
                  className={customStyles.listItem}
                  onClick={() => toggleSelectRole(role.data.roleID)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={role.isSelected}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": role.data.name,
                      }}
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={role.data.roleID.toString()}
                    primary={`${role.data.name} (${convertShareModeToSymbol(
                      role.data.shareMode
                    )})`}
                  />
                </ListItem>
              );
            })
          : "No items"}
      </Grid>
    </Grid>
  );
};

export default CustomerRoles;
