import * as React from "react";
import { Container } from "@material-ui/core";
import { EditReportDefaultTab } from "Common/enum";
import { IEditReportViewProps } from "Components/Reports/EditReport/interfaces-edit-report";
import EditReport from "Components/Reports/EditReport/edit-report";
import { useParams } from "react-router-dom";
import { isNumber } from "lodash";

export default function EditReportView(props: IEditReportViewProps) {
  const { id } = useParams<{ id: string }>();

  return (
    <Container>
      {id && isNumber(Number(id)) ? (
        <EditReport
          client={props.client}
          reportID={Number(id)}
          defaultTab={EditReportDefaultTab.General}
        />
      ) : (
        ""
      )}
    </Container>
  );
}
