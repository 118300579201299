import * as React from 'react';
import moment from 'moment';
import TypographyInfo from 'Components/Typography/typography-info';
import TypographySubinfo from 'Components/Typography/typography-subinfo';
import { Container, Grid } from '@material-ui/core';
import { IMobileAppMainListItemProps } from '../interfaces-mobile-app';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { stylesMobileAppMainList } from 'Components/MobileApp/MobileAppMainList/styles';
import SvgCalendar from 'Components/Images/image-calendar';
import SvgFile from 'Components/Images/image-file';

export default function MobileAppMainListItem(
  props: IMobileAppMainListItemProps
) {
  let customStyles = stylesMobileAppMainList();

  return (
    <Container className={customStyles.root}>
      <Grid container justify="flex-start">
        <Grid item xs={12}>
          <TypographyInfo
            text={'Release ' + props.version.versionNo}
            margin="0 0 10px 0"
            iconEnd={
              props.version.availableToInstall ? faCheckCircle : undefined
            }
            style={{ fontSize: '18px' }}
          />
        </Grid>
        <Grid container item xs={4} alignItems="center" alignContent="center">
          <SvgCalendar />
          <TypographySubinfo
            text={moment(props.version.releaseDate).format('DD/MM/YYYY')}
            style={{ fontSize: '14px' }}
            margin="0 0 0 13px"
          />
        </Grid>
        <Grid container item xs={8} alignItems="center" alignContent="center">
          <Grid item xs={1}>
            <SvgFile />
          </Grid>
          <Grid item xs={11}>
            <TypographySubinfo
              text={props.version.releaseNotes ?? '-'}
              showMore={true}
              style={{ fontSize: '14px' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
