export enum FormEditorEnum {
  Create = 1,
  Edit = 2,
}

export enum IconType {
  FontAwesomeIcon = 1,
  SvgImageIcon = 2,
}

export enum AppPlatformEnum {
  Android = 1,
  IOS = 2,
  Web = 3,
  WebService = 4,
}

export enum AppTypeEnum {
  All = 1,
  WebApp = 2,
  WebService = 3,
  MobileApp = 4,
}

export enum RequiredLoginEnum {
  Email = 1,
  PhoneNumber = 2,
  All = 3,
}

export enum ApiResultStatusEnum {
  Ok = 1,
  InternalServerError = 2,
  SecurityKeyNotProvided = 3,
  IncorrectSecurityKey = 4,
  ClientNotFound = 5,
  UnexpectedServerError = 6,
  IncorrectAuthorizationToken = 7,
  InsufficientPermissions = 8,
  GuidIsAlreadyTaken = 9,
  NameIsAlreadyTaken = 10,
  FileNotFound = 11,
  NoFileProvided = 12,
  AppNotFound = 13,
  IncorrectClientID = 14,
  MobileAppVersionNotFound = 15,
  WebAppInstanceNotFound = 16,
  WebAppNotFound = 17,
  WebAppInstanceDeploymentNotFound = 18,
  IncorrectWebAppInstanceID = 19,
  IncorrectAppID = 20,
  PermissionsAddedToGroup = 33,
  RoleInUse = 37,
  ReportDictionaryNotFound = 44,
  ReportsAddedToContainer = 47,
  ReportDictionaryIsUsedInReport = 56,
  MaintenanceNotFound = 66,
  CannotDelete = 70,
}

export enum UnsavedChangesButtonEnum {
  Save = 1,
  Discard = 2,
  Cancel = 3,
}

export enum DeviceListSortByEnum {
  Newest = 1,
  Oldest = 2,
}

export enum FileTypeEnum {
  AppInstall = 1,
  AppLogo = 2,
}

export enum UserType {
  Admin = 1,
  Client = 2,
}

export type SizeEnum = "sm" | "md" | "lg";

export enum UserLoginType {
  Email = 1,
  PhoneNo = 2,
  All = 3,
  None = 4,
}

export enum UserLoginIsDefaultForUser {
  NotDefaultForUser = 0,
  DefaultForUser = 1,
}

export enum SetAllValuesEnum {
  MinValues = 1,
  MaxValues = 2,
}

export enum AppsPermissionsDefaultTab {
  PermissionGroups = 1,
  ReportContainers = 2,
}

export enum AccessRightsDefaultTab {
  Permissions = 1,
  Reports = 2,
}

export enum IsEnabled {
  NotEnabled = 0,
  Enabled = 1,
}

export enum EditReportDefaultTab {
  General = 0,
  Parameters = 1,
  Columns = 2,
  Scheduler = 3,
  SummaryParameters = 3,
  SummaryColumns = 4,
  SummaryScheduler = 5,
  DetailsParameters = 3,
  DetailsHTML = 4,
}

export enum EditSummaryReportDefaultTab {
  General = 0,
  Parameters = 1,
  Columns = 2,
  SummaryColumns = 3,
  SummaryParameters = 4,
  Scheduler = 5,
}

export enum ReportIsSetBeforeExecute {
  NoSetBeforeExecute = 0,
  SetBeforeExecute = 1,
}

export enum ReportIsColorizeRows {
  NoColorizeRows = 0,
  ColorizeRows = 1,
}

export enum ReportParameterDataType {
  String = 1,
  Int = 2,
  Float = 3,
  DateTime = 4,
  Date = 5,
  Time = 6,
  Bool = 7,
}

export enum ReportParameterEnterType {
  Text = 1,
  Combo = 2,
  Range = 3,
}

export enum ReportParameterSourceType {
  None = 0,
  Procedure = 1,
  Dictionary = 2, //,
  //Users = 3,
  //Roles = 4
}

export enum ReportParameterDisplayNameType {
  SimpleText = 1,
  Procedure = 2,
}

export enum ReportParameterIsSourceMultiselect {
  NoMultiselect = 0,
  Multiselect = 1,
}

export enum ReportParameterFormatType {
  None = 0,
  DataTypeFormat = 1,
  Custom = 2,
}

export enum ReportParameterDefaultValueType {
  Text = 1,
  DictionaryItem = 2,
  Custom = 3,
}

export enum ReportParameterAlignment {
  Left = 1,
  Center = 2,
  Right = 3,
}

export enum ReportParameterIsRequired {
  NotRequired = 0,
  Required = 1,
}

export enum ReportParameterIsVisible {
  NotVisible = 0,
  Visible = 1,
}

export enum ReportParameterDatabaseDirection {
  Input = 1,
  InputOutput = 2,
}

export enum ReportColumnSortType {
  Never = 0,
  Sort = 1,
  SortDefaultByAsc = 2,
  SortDefaultByDesc = 3,
}

export enum ReportColumnDataType {
  String = 1,
  Int = 2,
  Float = 3,
  DateTime = 4,
  Date = 5,
  Time = 6,
  Bool = 7,
}

export enum ReportColumnFormatType {
  None = 0,
  DataTypeFormat = 1,
  Custom = 2,
}

export enum ReportColumnAlignment {
  Left = 1,
  Center = 2,
  Right = 3,
}

export enum ReportColumnAggregateType {
  None = 0,
  Sum = 1,
  Avg = 2,
  Min = 3,
  Max = 4,
  Count = 5,
  CountUnique = 6,
}

export enum ReportColumnIsVisible {
  NotVisible = 0,
  Visible = 1,
}

export enum ReportColumnIsWrap {
  NotWrap = 0,
  Wrap = 1,
}

export enum ExistingReportStrategyType {
  CheckForExisting = 0,
  Update = 1,
  AddNew = 2,
}

export enum UserActiveType {
  NotActive = 0,
  Active = 1,
  All = 2,
}

export enum CustomerType {
  NonDelaer = 0,
  Dealer = 1,
  All = 2,
}

export enum MaintenanceActivityTypeEnum {
  All = 1,
  Finished = 2,
  Unfinished = 3,
}

export enum LandingPageEnum {
  Applications = 1,
  Users = 2,
  Roles = 3,
  SystemUsers = 4,
  SystemPermissions = 5,
  SystemAppsPermissions = 6,
  SystemCustomers = 8,
  ReportsDictionaries = 9,
  ReportsReports = 10,
  ReportsReportsContainers = 11,
  ReportsAppsReports = 12,
  AccessRights = 13,
  CustomerPermissions = 14,
  Maintenance = 15,
  PermissionList = 16,
  CustomerLocationList = 17,
}

export enum ParamType {
  SelectedCustomerID = 1,
  SelectedAppGuid = 2,
  SelectedRole = 3,
}

export enum RoleShareMode {
  Multiple,
  Single,
}

export enum ReportTypeEnum {
  Report = 1,
  Grid = 2,
  PowerBI = 3,
}

export enum ReportSubtypeEnum {
  Regular = 1,
  Summary = 2,
  MasterDetails = 3,
}
