import * as React from "react";
import * as _ from "lodash";
import { Grid } from "@material-ui/core";
import { useInstanceApi } from "Services/Api/InstanceApi/instance-api";
import { stylesWebAppInstanceHeader } from "./styles";
import { IWebAppInstanceHeaderProps } from "./interfaces";
import { SweetAlertResult } from "sweetalert2";
import { AppPlatformEnum, FormEditorEnum } from "Common/enum";
import TypographyTitle from "Components/Typography/typography-title";
import useModalError from "Hooks/use-modal-error";
import useModalSuccess from "Hooks/use-modal-success";
import WebAppReleaseEditor from "../WebAppReleaseEditor/web-app-release-editor";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import HeaderCard from "Components/HeaderCard/header-card";

const WebAppInstanceHeader: (props: IWebAppInstanceHeaderProps) => JSX.Element =
  (props: IWebAppInstanceHeaderProps): JSX.Element => {
    const { checkAppIsRespondingForEveryRelease } = useInstanceApi();
    const customStyles = stylesWebAppInstanceHeader();
    const showModalError: (
      errorResult: string,
      isHtml?: boolean
    ) => Promise<SweetAlertResult> = useModalError();
    const showModalSuccess: (
      successResult: string
    ) => Promise<SweetAlertResult> = useModalSuccess();

    const handleCheckWS = async (): Promise<void> => {
      if (props.instanceId) {
        await checkAppIsRespondingForEveryRelease(props.instanceId)
          .then((r) => {
            showModalSuccess("Service working properly");
          })
          .catch((error) => {
            if (error.response.data?.data) {
              showModalError(_.join(error.response.data.data, "</br>"), true);
            } else {
              showModalError("An error occured. Please, try again later");
            }
          });
      }
    };

    return (
      <HeaderCard>
        <Grid container>
          <Grid container item xs={6} alignItems="center" justify="flex-start">
            <Grid item className={customStyles.gridItem}>
              <TypographyTitle text={props.instanceName} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={6}
            alignItems="center"
            justify="flex-end"
            spacing={2}
          >
            <Grid item>
              <ButtonSubaction
                text="Check releases"
                handleClick={handleCheckWS}
              />
            </Grid>
            <Grid item>
              <WebAppReleaseEditor
                buttonSize={"md"}
                editorType={FormEditorEnum.Create}
                webAppInstanceId={props.instanceId}
                getWebAppInstanceDeploymentList={
                  props.getInstanceDeploymentList
                }
                platformType={AppPlatformEnum.WebService}
                deploymentItemCount={50}
              />
            </Grid>
          </Grid>
        </Grid>
      </HeaderCard>
    );
  };

export default WebAppInstanceHeader;
