import * as React from "react";
import { Container } from "@material-ui/core";
import { IReportsDictionariesProps } from "Components/Reports/interfaces-reports";
import {
  GetReportDictionariesApiResponseType,
  SaveReportDictionaryModelType,
  DictionaryEditor,
} from "Types/report-types";
import { AxiosError } from "axios";
import ReportApi from "Services/Api/report-api";
import { ApiResultStatusEnum } from "Common/enum";
import { SaveReportDictionaryApiResultType } from "Types/api-types";
import DictionaryHeader from "Components/Reports/Dictionary/DictionaryHeader/dictionary-header";
import DictionaryList from "Components/Reports/Dictionary/DictionaryList/dictionary-list";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import InfoDialog from "Components/Dialogs/InfoDialog/info-dialog";

const reportApi = new ReportApi();

export default function ReportsDictionaries(props: IReportsDictionariesProps) {
  const [dictionaryEditors, setDictionaryEditors] = React.useState<
    DictionaryEditor[]
  >([]);
  const [
    filteredDictionaryEditors,
    setFilteredDictionaryEditors,
  ] = React.useState<DictionaryEditor[]>([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [isInfoDialogOpen, setIsInfoDialogOpen] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");

  const getDictionaryList = async (): Promise<void> => {
    if (props.client && props.client.id) {
      await reportApi.getDictionaryList(props.client.id!).then((result) => {
        if (result && result.data) {
          let dictionaryEditorList: DictionaryEditor[] = result.data.map(
            (dictionary) => {
              return {
                dictionary: dictionary,
                isOpen: false,
              };
            }
          );
          setDictionaryEditors(dictionaryEditorList);
          setFilteredDictionaryEditors(
            sortByName(searchByName(searchValue, dictionaryEditorList))
          );
        }
      });
    }
  };

  React.useEffect(() => {
    getDictionaryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const handleInfoDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsInfoDialogOpen(false);
  };

  const handleSaveDictionary = (
    saveDictionaryModel: SaveReportDictionaryModelType
  ) => {
    saveDictionary(saveDictionaryModel)
      .then(() => {})
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const saveDictionary = (
    saveDictionaryModel: SaveReportDictionaryModelType
  ): Promise<SaveReportDictionaryApiResultType | void> => {
    return reportApi
      .saveDictionary(
        saveDictionaryModel.dictionaryID
          ? saveDictionaryModel
          : { ...saveDictionaryModel, dictionaryID: 0 }
      )
      .then((result) => {
        if (result && result.status === ApiResultStatusEnum.Ok) {
          if (dictionaryEditors) {
            let dictionaryEditorList: DictionaryEditor[] = [
              ...dictionaryEditors,
            ];
            let dictionaryEditor:
              | DictionaryEditor
              | undefined = dictionaryEditorList.find(
              (dictionaryEditor) =>
                dictionaryEditor.dictionary.dictionaryID ===
                  result.data.dictionaryID ||
                dictionaryEditor.dictionary.dictionaryID == null
            );

            if (dictionaryEditor === undefined) {
              getDictionaryList();
              return;
            }

            let dictionary = dictionaryEditor!.dictionary;

            if (dictionary && saveDictionaryModel.dictionaryID) {
              dictionary.dictionaryName = saveDictionaryModel.dictionaryName;
              dictionary.items = saveDictionaryModel.items.map((dict) => {
                return {
                  itemID: dict.itemID,
                  itemName: dict.itemName,
                  itemValue: dict.itemValue,
                  orderNo: dict.orderNo,
                };
              });
            } else {
              dictionary = {
                dictionaryID: result.data.dictionaryID,
                dictionaryName: saveDictionaryModel.dictionaryName,
                items: saveDictionaryModel.items.map((dict) => {
                  return {
                    itemID: dict.itemID,
                    itemName: dict.itemName,
                    itemValue: dict.itemValue,
                    orderNo: dict.orderNo,
                  };
                }),
              };
              dictionaryEditor!.dictionary = dictionary;
            }
            dictionaryEditor!.isOpen = false;
            setDictionaryEditors(dictionaryEditorList);
            setFilteredDictionaryEditors(
              sortByName(searchByName(searchValue, dictionaryEditorList))
            );
          }
        }
      });
  };

  const handleOpenDictionary = (
    dictionary: GetReportDictionariesApiResponseType
  ) => {
    let newDictionaryEditorList = [...dictionaryEditors];
    let dictionaryEditor = newDictionaryEditorList.find(
      (x) => x.dictionary.dictionaryID === dictionary.dictionaryID
    );

    if (dictionaryEditor) {
      dictionaryEditor.isOpen = !dictionaryEditor.isOpen;
      setDictionaryEditors(newDictionaryEditorList);
      setFilteredDictionaryEditors(
        sortByName(searchByName(searchValue, newDictionaryEditorList))
      );
    }
  };

  const handleDeleteDictionary = (dictionaryID: number | null) => {
    if (dictionaryID) {
      reportApi
        .deleteDictionary({ dictionaryID })
        .then((result) => {
          if (
            result.status === ApiResultStatusEnum.Ok &&
            result?.data?.isDeleted
          ) {
            const newDictionaryEditors = [...dictionaryEditors].filter(
              (x) => x.dictionary.dictionaryID !== dictionaryID
            );
            setDictionaryEditors(newDictionaryEditors);
            setFilteredDictionaryEditors(
              sortByName(searchByName(searchValue, newDictionaryEditors))
            );
          } else {
            setInfoText("Error delete user list.");
            setIsInfoDialogOpen(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setErrorResponse(error);
          setIsErrorDialogOpen(true);
        });
    } else {
      setDictionaryEditors(
        [...dictionaryEditors].filter((x) => x.dictionary.dictionaryID !== null)
      );
      setFilteredDictionaryEditors(
        sortByName(
          searchByName(
            searchValue,
            [...dictionaryEditors].filter(
              (x) => x.dictionary.dictionaryID !== null
            )
          )
        )
      );
    }
  };

  const handleCreateNewDictionary = () => {
    if (
      !dictionaryEditors.some(
        (dictionaryEditor) => dictionaryEditor.dictionary.dictionaryID == null
      )
    ) {
      let dictionaryEditor: DictionaryEditor = {
        dictionary: {
          dictionaryID: null,
          dictionaryName: "",
          items: [],
        },
        isOpen: true,
      };
      const newDictionaryEditors = [dictionaryEditor, ...dictionaryEditors];
      setDictionaryEditors(newDictionaryEditors);
      setFilteredDictionaryEditors(
        sortByName(searchByName(searchValue, newDictionaryEditors))
      );
    }
  };

  const showInfoMessage = (infoText: string) => {
    setInfoText(infoText);
    setIsInfoDialogOpen(true);
  };

  const handleSearchValueChange = (
    value: React.SetStateAction<string>
  ): void => {
    let searchedText: string = value.toString().toLowerCase();
    searchedText = searchedText ? searchedText : "";

    setSearchValue(searchedText);

    setFilteredDictionaryEditors(
      sortByName(searchByName(searchedText, dictionaryEditors))
    );
  };

  const searchByName = (
    text: string,
    list: DictionaryEditor[]
  ): DictionaryEditor[] => {
    if (text.length < 1) {
      return list;
    }
    return list.filter(
      (d) =>
        d.dictionary.dictionaryName
          .toLowerCase()
          .includes(text.toLowerCase()) || d.isOpen
    );
  };

  const sortByName = (list: DictionaryEditor[]): DictionaryEditor[] => {
    return list.sort((a, b) =>
      a.dictionary.dictionaryName.localeCompare(b.dictionary.dictionaryName)
    );
  };

  return (
    <Container>
      <DictionaryHeader
        client={props.client}
        handleCreateNewDictionary={handleCreateNewDictionary}
      />
      <DictionaryList
        client={props.client}
        handleSaveDictionary={handleSaveDictionary}
        handleOpenDictionary={handleOpenDictionary}
        handleDeleteDictionary={handleDeleteDictionary}
        dictionaryEditorList={filteredDictionaryEditors}
        showInfoMessage={showInfoMessage}
        handleSearchValueChange={handleSearchValueChange}
      />
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
      <InfoDialog
        isOpen={isInfoDialogOpen}
        text={infoText}
        handleOkClick={handleInfoDialogOkClick}
      />
    </Container>
  );
}
