import { makeStyles } from "@material-ui/core";

export const stylesAccessRightsListReports = makeStyles({
  gridItem: {
    padding: "0 5px",
  },
  gridItemGroupName: {
    padding: "0 0 0 30px",
  },
});

export const stylesAccessRightsListReportsContainer = makeStyles({
  divNoReportContainers: {
    textAlign: "center",
  },
});

export const stylesAccessRightsListItemReports = makeStyles({
  paper: {
    padding: "20px 30px",
    marginBottom: "15px",
  },
});
