import { ApiResultStatusEnum } from 'Common/enum';
import { AppCheckRespondingTypeEnum, AppUserTypeEnum } from 'Common/enum-app';
import { RouteApplications } from 'Configuration/Routes';
import useModalError from 'Hooks/use-modal-error';
import useModalSuccess from 'Hooks/use-modal-success';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import ClientAppApi from 'Services/Api/client-app-api';
import { SweetAlertResult } from 'sweetalert2';
import { WebAppGetApiResultType } from 'Types/api-types';
import { AppType } from 'Types/app-types';
import { IAppEditor, IAppEditorProps } from '../interfaces';
import { appEditorInitValues } from './mappers';

export const useClientAppApi = (
  ids: Pick<IAppEditorProps, 'id' | 'clientId'>
) => {
  const clientAppApi: ClientAppApi = new ClientAppApi();
  const history = useHistory();
  const { id: appID, clientId } = ids;

  const [initValues, setInitValues] = React.useState<IAppEditor>(
    appEditorInitValues(ids.clientId, ids.id)
  );

  const showModalSuccess: (successResult: string) => Promise<SweetAlertResult> =
    useModalSuccess();

  const showModalError: (
    errorResult: string,
    isHtml?: boolean
  ) => Promise<SweetAlertResult> = useModalError();

  const handleLoad = () => {
    if (appID) {
      clientAppApi.getWebApp(appID).then((result: WebAppGetApiResultType) => {
        if (result?.data) {
          const { iaClientID, platformType, loginDataType } = result.data;
          if (iaClientID === clientId) {
            setInitValues({
              ...result.data,
              clientId: iaClientID,
              appPlatformType: platformType,
              appUserType: AppUserTypeEnum.NoUser,
              appLoginDataType: loginDataType,
            });
          }
        } else {
          history.push(RouteApplications);
        }
      });
    }
  };

  const handleCheckWS = async (
    checkRespondingType: AppCheckRespondingTypeEnum
  ): Promise<void> => {
    if (appID) {
      await clientAppApi
        .checkAppIsResponding(appID, checkRespondingType)
        .then((r) => {
          showModalSuccess('Service working properly');
        })
        .catch((error) => {
          showModalError(error.response.data.statusMessage);
        });
    }
  };

  const handleSave = (data: AppType, handleAfterSave?: () => void): void => {
    clientAppApi
      .checkAppGuidAlreadyExists(data.guid)
      .then((response) => {
        if (response?.status === ApiResultStatusEnum.Ok && response.data) {
          const { isAlreadyExists, appID } = response.data;
          if (isAlreadyExists && appID !== ids.id) {
            showModalError('App with this guid already exists');
          } else {
            clientAppApi
              .saveApp(data)
              .then(() => {
                handleAfterSave?.();
              })
              .catch((error) => {
                showModalError(error.response.datastatusMessage);
              });
          }
        } else {
          showModalError('Something went wrong, try again later');
        }
      })
      .catch((error) => {
        showModalError(error.response.data?.statusMessage);
      });
  };

  return { handleLoad, handleSave, handleCheckWS, initValues };
};
