import * as React from "react";
import Colors from "Common/colors";
import { Button, makeStyles } from "@material-ui/core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IButtonSolidProps {
  text: string;
  onClickEvent?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  iconStart?: IconDefinition;
  iconEnd?: IconDefinition;
  margin?: string;
  disabled?: boolean;
  isHidden?: boolean;
}

export default function ButtonSolid(props: IButtonSolidProps) {
  let styles = makeStyles({
    button: {
      background: Colors.grayDark,
      border: "1.5px solid " + Colors.grayDark,
      borderRadius: "7px",
      color: Colors.grayLight,
      padding: "3px 20px",
      textTransform: "none",
      margin: props.margin ? props.margin : "0",
    },
  });

  let customStyles = styles({});
  let startIcon, endIcon;
  if (props.iconStart) {
    startIcon = <FontAwesomeIcon icon={props.iconStart} />;
  }
  if (props.iconEnd) {
    endIcon = <FontAwesomeIcon icon={props.iconEnd} />;
  }

  return !props.isHidden ? (
    <Button
      type="submit"
      className={customStyles.button}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={props.onClickEvent}
      disabled={props.disabled}
    >
      {props.text}
    </Button>
  ) : (
    <div></div>
  );
}
