import * as React from "react";

const SvgHeap = ({
  style = {},
  fill = "none",
  width = "14",
  height = "14",
  className = "",
  color = "black",
  viewBox = "0 0 18 16"
}) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill={fill}
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.5 9.5L2 11L9 15L16 11L13.5 9.5" stroke={color} />
    <path d="M4.5 6.5L2 8L9 12L16 8L13.5 6.5" stroke={color} />
    <path d="M9 9L2 5L9 1L16 5L9 9Z" stroke={color} />
  </svg>
);

export default SvgHeap;
