import * as React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { IInputHtmlEditorProps } from "./interfaces-input";
export default function InputHtmlEditor(props: IInputHtmlEditorProps) {
  const handleEditorChange = (a: string, editor: any) => {
    if (props.onChangeEvent != null) {
      props.onChangeEvent(editor.getContent());
    }
  };

  return (
    <Editor
      id={props.id}
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      value={props.value}
      onEditorChange={handleEditorChange}
      disabled={props.disabled}
      init={{
        encoding: "xml",
        height: 500,
        menubar: "edit insert view format table tools",
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "charmap",
          "preview",
          "searchreplace",
          "visualblocks",
          "code",
          "insertdatetime",
          "nonbreaking",
          "save",
          "table",
          "directionality",
          "image",
        ],
        text_patterns: false,
        toolbar:
          "undo redo | styles | bold italic | fontfamily fontsize | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | forecolor backcolor | image | replacementddl",
        valid_elements: "*[*]",
        setup: (editor) => {
          if (props.replacementData != null) {
            let valuesData: any = [];
            valuesData.push({ text: "#", value: "" });
            props.replacementData.forEach((value: string) => {
              valuesData.push({
                text: value,
                value: `#${value.toUpperCase()}#`,
              });
            });
            editor.on('PostProcess', function(ed) {
              // we are cleaning empty paragraphs
              ed.content = ed.content.replace(/(<p><\/p>)/gi,'<br />');
            });
            editor.ui.registry.addMenuButton("replacementddl", {
              text: "#",
              fetch: (callback) => {
                const items: any = [];
                valuesData.forEach((item: any) => {
                  items.push({
                    type: "menuitem",
                    text: item.text,
                    onAction: () => editor.insertContent(item.value),
                  });
                });
                callback(items);
              },
            });
          }
        },
        menu: {
          edit: {
            title: "Edit",
            items: "undo redo  | selectall | searchreplace",
          },
          insert: {
            title: "Insert",
            items: "link | charmap hr insertdatetime nonbreaking",
          },
          view: { title: "View", items: "visualblocks visualaids | preview" },
          format: {
            title: "Format",
            items:
              "bold italic underline strikethrough superscript subscript | removeformat",
          },
          table: {
            title: "Table",
            items: "inserttable tableprops deletetable | cell row column",
          },
          tools: { title: "Tools", items: "code" },
        },
        nonbreaking_force_tab: true,
        visual: true,
      }}
    />
  );
}