import * as React from "react";
import { IconButton, Grid, makeStyles } from "@material-ui/core";
import { IButtonImageProps } from "Components/Buttons/interfaces";

export default function ButtonImage(props: IButtonImageProps) {
    let styles = makeStyles({
        main: {
            background: 'transparent',
            border: `1px solid ${(props.borderColor ? (props.borderColor) : '#DCDCDC')}`,
            borderRadius: '6px',
            width: props.buttonWidth ? props.buttonWidth : '129px',
            height: props.buttonHeight ? props.buttonHeight : '150px',
            boxShadow: 'none',
            overflow: 'hidden',
            visibility: props.isHidden ? 'hidden' : 'visible',
            fontSize: '12px',
            color: '#444444',
        },
    })

    const customStyles = styles({});
    return (
        <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={props.handleClick}
            className={customStyles.main}
            disabled={!props.isEnabled}
        >
            <Grid container direction="column" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    {props.icon}
                </Grid>
                <Grid item xs={12}>{props.text}</Grid>
            </Grid>
        </IconButton>
    )
}