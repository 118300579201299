import * as React from "react";
import { Fab, makeStyles } from "@material-ui/core";
import SvgPencil from "Components/Images/image-edit-pencil";
import { IButtonEditProps} from "Components/Buttons/interfaces"

const styles = makeStyles({ 
    buttonImg: (props: IButtonEditProps) => ({
        background: 'transparent',
        height: props.buttonHeight ? props.buttonHeight : '30px',
        minHeight: props.buttonHeight ? props.buttonHeight : '30px',
        width: props.buttonWidth ? props.buttonWidth : '30px',
        boxShadow: 'none'
    })
});


export default function ButtonEdit (props: IButtonEditProps) {
    
    let customStyles = styles(props);
    
    return (
        <Fab className={customStyles.buttonImg} onClick={props.handleClick}>
            <SvgPencil width={props.iconWidth}/>
        </Fab>
    )
}