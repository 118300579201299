import * as React from 'react';
import TypographyInfo from 'Components/Typography/typography-info';
import { CustomerLocationsListType } from 'Types/customer-types';
import { stylesCustomerListItem } from 'Components/Customers/styles';

export interface ICustomerLocationListItemProps {
  location: CustomerLocationsListType;
}

export default function CustomerLocationListItem(
  props: ICustomerLocationListItemProps
) {
  const customStyles = stylesCustomerListItem();
  const { location } = props;
  const { address } = location;

  const locationAddress = address
    ? `ul. ${address.streetName ?? ""} ${address.streetNumber ?? ""} ${
        address.apartmentNumber ? "/" : ""
      } ${address.apartmentNumber ?? ""}, ${address.zipCode ?? ""} ${
        address.city ?? ""
      }`
    : "";

  return (
    <tr>
      <td className={customStyles.cell}>
        <TypographyInfo text={location.name} fontSize="14px" margin="0" />
      </td>
      <td className={customStyles.cell}>
        <TypographyInfo text={locationAddress} fontSize="14px" margin="0" />
      </td>
      <td className={customStyles.cell}>
        <TypographyInfo text={location.guid} fontSize="14px" margin="0" />
      </td>
    </tr>
  );
}
