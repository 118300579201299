import * as React from "react";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import TypographyInfo from "Components/Typography/typography-info";
import { ChangeEvent } from "react";
import { GetPermissionsApiResponseType } from "Types/permission-types";
import { Grid, useTheme } from "@material-ui/core";
import { ReportTypeEnum } from "Common/enum";

import {
  GetReportContainerListApiResponseType,
  GetReportDetailsApiResponseType,
} from "Types/report-types";
import { stylesEditReportDetailsHTML } from "./styles";
import InputHtmlEditor from "Components/Inputs/input-html-editor";

export interface EditReportDetailsHTMLProps {
  isEditable: boolean;
  data: GetReportDetailsApiResponseType;
  changeReportData: (data: GetReportDetailsApiResponseType) => void;
}

const EditReportDetailsHTML: React.FC<EditReportDetailsHTMLProps> = ({
  data,
  isEditable,
  changeReportData,
}) => {
  const theme = useTheme();

  const { reportName, reportGuid, reportDescription, reportType } = data;
  const customStyles = stylesEditReportDetailsHTML();

  const handleChangeDetailsHTML = (
    value: React.SetStateAction<string>
  ) => {
    changeReportData({ ...data, detailsHTML: value.toString() });
  };

  return (
    <form noValidate autoComplete="off">
      <Grid
        container
        direction="column"
        className={customStyles.container}
        spacing={3}
      >
        <Grid item sm={12}>
          <InputHtmlEditor
            replacementData={data.detailsColumnsToReplace}
            disabled={!isEditable}
            value={data.detailsHTML}
            id="details-html"
            onChangeEvent={handleChangeDetailsHTML}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default EditReportDetailsHTML;