import { makeStyles, Theme } from "@material-ui/core";

export const stylesReportsHeader = makeStyles((theme: Theme) => ({
  title: {
    fontSize: "20px",
    lineHeight: "30px",
    color: theme.palette.grayDarker.main,
    marginTop: "25px",
  },
  selectedApp: {
    fontSize: "20px",
    lineHeight: "30px",
    color: theme.palette.grayDarker.main,
    borderBottom: "none",
  },
}));
