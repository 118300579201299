import { makeStyles, Theme } from "@material-ui/core";

export const stylesEditReportDetailsHTML = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "white",
    width: "100%",
    padding: "8px",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 4px",
    borderRadius: "5px",
    margin: 0,
  }
}));