import { axiosInstance } from 'Services/Api/base-api';
import { ClientGetListApiResultType, ClientSaveApiResultType } from 'Types/api-types';
import { ClientType } from 'Types/client-types';

export default class ClientApi {
    getList = async (): Promise<ClientGetListApiResultType> => {
        return await axiosInstance
            .get('/Client/GetList')
            .then(response => response.data)
            .catch(error => console.log(error));
    };

    save = async (client: ClientType): Promise<ClientSaveApiResultType> => {
        return await axiosInstance
            .post('/Client/Save', JSON.stringify(client))
            .then(response => response.data)
    }
}