import * as React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import InputSearch from 'Components/Inputs/input-search';
import StyledSelect from 'Components/Inputs/StyledSelect/styled-select';
import TypographySubinfo from 'Components/Typography/typography-subinfo';
import { ICustomerFiltersProps } from 'Components/Customers/interfaces-customers';
import { CustomerType } from 'Common/enum';

export default function CustomerFilters(props: ICustomerFiltersProps) {
    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-end"
            spacing={4}
        >
            <Grid item >
                <InputSearch onChangeEvent={props.onSearchChange} />
            </Grid>
            <Grid item >
                <FormControl >
                    <InputLabel>Customer Type</InputLabel>
                    <StyledSelect
                        id="type-select"
                        value={props.selectedCustomerType}
                        onChange={props.onCustomerTypeChange}
                    >
                        <MenuItem value={CustomerType.All}>All</MenuItem>
                        <MenuItem value={CustomerType.NonDelaer}>Non-dealer</MenuItem>
                        <MenuItem value={CustomerType.Dealer}>Dealer</MenuItem>
                    </StyledSelect>
                </FormControl>
            </Grid>
        </Grid>
    );
};
