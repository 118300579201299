import * as React from "react";
import TypographyInfo from "Components/Typography/typography-info";
import TypographySubinfo from "Components/Typography/typography-subinfo";
import { Popover, Grid, Divider, makeStyles } from "@material-ui/core";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import ButtonBlock from "Components/Buttons/ButtonBlock/button-block";
import ClientSwitcher from "Components/Client/ClientSwitcher/client-switcher";
import ClientEditor from "Components/Client/ClientEditor/client-editor";
import { FormEditorEnum } from "Common/enum";
import { IClientPopoverProps } from "../interfaces-client";

export default function ClientPopover(props: IClientPopoverProps) {
  const [displayIcon, setDisplayIcon] = React.useState(faAngleDown);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const isOpen: boolean = Boolean(anchorEl);

  const styles = makeStyles({
    divPopover: {
      padding: "10px",
      width: 400,
    },

    divMain: {
      textAlign: "right",
    },

    divider: {
      margin: "5px 0px 20px 0",
    },

    constainerFooter: {
      textAlign: "center",
      paddingBottom: "10px",
    },

    textLeft: {
      textAlign: "left",
    },

    textRight: {
      textAlign: "right",
    },
  });

  const customStyles = styles({});

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDisplayIcon(faAngleUp);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setDisplayIcon(faAngleDown);
    setAnchorEl(null);
  };

  const checkGuidUniqueness = (guid: string): boolean => {
    if (guid) {
      return !props.clientList.some(
        (client) => client.guid.toUpperCase() === guid.toUpperCase()
      );
    } else {
      return false;
    }
  };

  return (
    <div className={customStyles.divMain}>
      <ButtonBlock
        text={props.client.name}
        onClickEvent={handleClick}
        iconEnd={displayIcon}
      />
      <Popover
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <div className={customStyles.divPopover}>
          <Grid container={true}>
            <Grid item={true} className={customStyles.textLeft} xs={9}>
              <TypographyInfo text={props.client.name} />
            </Grid>
            <Grid item={true} className={customStyles.textRight} xs={3}>
              <ClientEditor
                editorType={FormEditorEnum.Edit}
                handleSaveClient={props.handleSaveClient}
                checkGuidUniqueness={checkGuidUniqueness}
                client={props.client}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TypographySubinfo text={props.client.guid} isUppercase={true} />
            </Grid>
            <Grid item={true} className={customStyles.constainerFooter} xs={12}>
              <Divider className={customStyles.divider} />
              <ClientSwitcher
                handlerClientChange={props.handleClientChange}
                handleClose={handleClose}
                clientList={props.clientList}
                handleSaveClient={props.handleSaveClient}
                checkGuidUniqueness={checkGuidUniqueness}
              />
            </Grid>
          </Grid>
        </div>
      </Popover>
    </div>
  );
}
