import { CustomerLocationsListType } from 'Types/customer-types';

export const filter = (
  list: CustomerLocationsListType[],
  textSearch: string
): CustomerLocationsListType[] => {
  let customerList = searchByText(list, textSearch);
  return customerList;
};

const searchByText = (
  list: CustomerLocationsListType[],
  text: string
): CustomerLocationsListType[] => {
  if (text) {
    list = list.filter(
      (x) =>
        x.name.toLowerCase().includes(text!) ||
        x.guid.toLowerCase().includes(text!) ||
        x.address.city.toLowerCase().includes(text!) ||
        x.address.streetName.toLowerCase().includes(text!) ||
        x.address.zipCode.toLowerCase().includes(text!)
    );
  }
  return list;
};
