import * as React from 'react';
import InputCheckbox from '../../../Inputs/input-checkbox';
import { GetReportColumnListType } from '../../../../Types/report-types';

export interface CheckboxEditableProps {
  isEditableField?: boolean;

  data: GetReportColumnListType;
  list: GetReportColumnListType[];
  handleChangeColumns?: (columns: GetReportColumnListType[]) => void;
}

const CheckboxEditable: React.FC<CheckboxEditableProps> = ({
  data,
  isEditableField,
  list,
  handleChangeColumns,
}) => {
  const handleChangeVisible = (
    event: React.ChangeEvent<HTMLInputElement>,
    columnID: number
  ): void => {
    const value: boolean = event.target.checked as boolean;

    handleChangeColumns?.(
      list.map((p) =>
        p.columnID === columnID
          ? {
              ...p,
              isEditable: value,
            }
          : p
      )
    );
  };

  return (
    <InputCheckbox
      disabled={!isEditableField}
      checked={data.isEditable}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleChangeVisible(event, data.columnID)
      }
    />
  );
};

export default CheckboxEditable;
