import * as React from "react";

const SvgWeb = ({
    style = {},
    fill = "none",
    width = "100%",
    className = "",
    color = "#DCDCDC",
    colorFill = "white",
    viewBox = "0 0 60 60"
  }) => (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <rect width="60" height="60" rx="5" fill={color} />
        <path d="M12 33.75H48V36.3111C48 37.7961 46.7961 39 45.3111 39H14.6889C13.2039 39 12 37.7961 12 36.3111V33.75Z" fill={colorFill}/>
        <path d="M12 17.6889C12 16.2039 13.2039 15 14.6889 15H45.3111C46.7961 15 48 16.2039 48 17.6889V32.25H12V17.6889Z" fill={colorFill}/>
        <rect x="25.5" y="39" width="9" height="4.5" fill={colorFill}/>
        <rect x="21.75" y="43.5" width="16.5" height="1.5" rx="0.75" fill={colorFill}/>
    </svg>
  );

  export default SvgWeb;