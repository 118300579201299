import { makeStyles } from "@material-ui/core";

export const stylesBoundEditor = makeStyles({
  webAppList: {
    height: "450px",
  },
  content: {
    height: "460px",
  },
  formControl: {
    margin: "8px",
    width: "300px",
  },
});
