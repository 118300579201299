import * as React from 'react';
import CustomerApi from 'Services/Api/customer-api';
import CustomerDealerAccountListContainer from 'Components/CustomerLocations/CustomerDealerAccounts/customer-dealer-account-list-container';
import CustomerLocationsContainer from 'Components/CustomerLocations/customer-location-list-container';
import CustomerLocationsHeader from 'Components/CustomerLocations/customer-location-header';
import { Container } from '@material-ui/core';
import { GetCustomerDetailsType } from 'Types/customer-types';
import { ICustomerLocationProps } from 'Components/CustomerLocations/interfaces-customer-locations';
import { useParams } from 'react-router';

const customerApi = new CustomerApi();

const CustomerLocations: (props: ICustomerLocationProps) => JSX.Element = (
  props: ICustomerLocationProps
): JSX.Element => {
  const params = useParams<{ customerId?: string }>();

  const [customerDetails, setCustomerDetails] =
    React.useState<GetCustomerDetailsType>();

  const getCustomerDetails = React.useCallback(async (): Promise<void> => {
    if (params.customerId) {
      await customerApi.getDetails(Number(params.customerId)).then((result) => {
        if (result?.data) {
          setCustomerDetails(result.data);
        }
      });
    }
  }, []);

  React.useEffect(() => {
    getCustomerDetails();
  }, []);

  const address = customerDetails?.address
    ? `ul. ${customerDetails?.address.streetName ?? ""} ${
        customerDetails?.address.streetNumber ?? ""
      } ${customerDetails?.address.apartmentNumber ? "/" : ""} ${
        customerDetails?.address.apartmentNumber ?? ""
      }, ${customerDetails?.address.zipCode ?? ""} ${
        customerDetails?.address.city ?? ""
      }`
    : "";

  return (
    <div style={{ marginLeft: 40 }}>
      <Container>
        <CustomerLocationsHeader
          isDealer={customerDetails?.isDealer ?? false}
          customerName={customerDetails?.name ?? ""}
          pinLength={customerDetails?.pinLength ?? 0}
          address={address ?? ""}
          guid={customerDetails?.guid ?? ""}
        />
        <CustomerLocationsContainer
          locationList={customerDetails?.locations ?? []}
        />
        {customerDetails?.dealerCustomers &&
          customerDetails?.dealerCustomers.length > 0 && (
            <CustomerDealerAccountListContainer
              dealerAccountList={customerDetails?.dealerCustomers ?? []}
            />
          )}
      </Container>
    </div>
  );
};

export default CustomerLocations;
