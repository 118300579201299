import * as React from 'react';
import ClientAppApi from 'Services/Api/client-app-api';
import ConfirmDialog from 'Components/Dialogs/ConfirmDialog/confirm-dialog';
import ErrorDialog from 'Components/Dialogs/ErrorDialog/error-dialog';
import InfoDialog from 'Components/Dialogs/InfoDialog/info-dialog';
import ReportApi from 'Services/Api/report-api';
import ReportsHeader from 'Components/Reports/Reports/ReportsHeader/reports-header';
import ReportsList from 'Components/Reports/Reports/ReportsList/reports-list';
import ReportsListFilters from 'Components/Reports/Reports/ReportsList/reports-list-filters';
import { ApiResultBaseType, DeleteReportApiResultType } from 'Types/api-types';
import { AxiosError } from 'axios';
import { ClientAppForReportsApiResponseType } from 'Types/app-types';
import { cloneDeep } from 'lodash';
import { Container, useTheme } from '@material-ui/core';
import { IConfirmDialogProps } from 'Components/Dialogs/interfaces';
import { IReportsReportsProps } from 'Components/Reports/interfaces-reports';
import {
  ApiResultStatusEnum,
  LandingPageEnum,
  ParamType,
  ReportTypeEnum,
} from 'Common/enum';
import {
  GetReportListApiResponseType,
  DeleteReportModelType,
  ExportReportsModelType,
} from 'Types/report-types';
import {
  getSavedPageParamValue,
  savePageParam,
} from 'Configuration/settings-helper';
import ProgressSpinner from 'Components/ProgressSpinner/progress-spinner';

const clientAppApi = new ClientAppApi();
const reportApi = new ReportApi();

export default function ReportsReports(props: IReportsReportsProps) {
  const theme = useTheme();
  const [isBlockedUi, setIsBlockedUi] = React.useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<
    AxiosError | string | null
  >(null);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = React.useState(false);
  const [infoText, setInfoText] = React.useState('');
  const [applications, setApplications] = React.useState<
    ClientAppForReportsApiResponseType[]
  >([]);
  const [selectedAppGuid, setSelectedAppGuid] = React.useState('');
  const [selectedAppID, setSelectedAppID] = React.useState(0);
  const [selectedInstanceID, setSelectedInstanceID] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');
  const [isEnabled, setIsEnabled] = React.useState<number>(1);
  const [selectedType, setSelectedType] = React.useState<ReportTypeEnum | -1>(
    -1
  );
  const [reports, setReports] = React.useState<GetReportListApiResponseType[]>(
    []
  );

  const [filteredReports, setFilteredReports] = React.useState<
    GetReportListApiResponseType[]
  >([]);
  const [confirmDialogSettings, setConfirmDialogSettings] =
    React.useState<IConfirmDialogProps>({
      isOpen: false,
      text: '',
      handleCancelClick: () => {},
      handleOkClick: () => {},
    });

  React.useEffect(() => {
    const getApplications = async (): Promise<void> => {
      if (
        props.client &&
        props.client !== null &&
        props.client.id !== null &&
        props.client.id > 0
      ) {
        await clientAppApi
          .getAppListForReports(props.client.id)
          .then((result) => {
            if (result) {
              setApplications(result.data);
              if (result.data && result.data.length > 0) {
                const savedAppGuid = getSavedPageParamValue(
                  LandingPageEnum.ReportsReports,
                  ParamType.SelectedAppGuid
                );
                let app: ClientAppForReportsApiResponseType | undefined;
                if (savedAppGuid) {
                  app = result.data.find(
                    (a) =>
                      a.appGuid.toLowerCase() === savedAppGuid.toLowerCase()
                  );
                }
                if (!app) {
                  app = result.data[0];
                  savePageParam({
                    page: LandingPageEnum.ReportsReports,
                    paramType: ParamType.SelectedAppGuid,
                    value: app.appGuid,
                  });
                }

                setSelectedAppID(app.appID);
                setSelectedInstanceID(app.instances[0].instanceID);
                setSelectedAppGuid(app.appGuid);
              }
            }
          });
      }
    };

    getApplications();
  }, [props.client]);

  const getReportsList = async (): Promise<void> => {
    if (
      props.client?.id &&
      props.client.id > 0 &&
      selectedAppID &&
      selectedAppID > 0 &&
      selectedInstanceID &&
      selectedInstanceID > 0
    ) {
      await reportApi
        .getReportList(props.client.id, selectedAppID, selectedInstanceID)
        .then((result) => {
          if (result) {
            setReports(result.data);
            setFilteredReports(
              searchByTextAndCreatedByAndIsEnabled(cloneDeep(result.data))
            );
          }
        });
    }
  };

  React.useEffect(() => {
    getReportsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.client.id, selectedAppID, selectedInstanceID]);

  const searchByTextAndCreatedByAndIsEnabled = (
    list: GetReportListApiResponseType[]
  ): GetReportListApiResponseType[] => {
    let results: GetReportListApiResponseType[] = list;
    // searchText
    const text = searchText?.toUpperCase();
    if (text && text !== '') {
      results = results.filter((x) => {
        return (
          x.definitionProcedureName?.toUpperCase().includes(text) ||
          (x.modifiedByName &&
            x.modifiedByName?.toUpperCase().includes(text)) ||
          x.reportName?.toUpperCase().includes(text) ||
          x.containers?.some((c) =>
            c.reportContainerName.toUpperCase().includes(text)
          ) ||
          x.permissionName?.toUpperCase().includes(text)
        );
      });
    }

    // isEnabled
    if (isEnabled > -1) {
      results = results.filter((x) => x.isEnabled === isEnabled);
    }

    if (selectedType > -1) {
      results = results.filter((x) => x.reportType === selectedType);
    }

    return results;
  };

  React.useEffect(() => {
    setFilteredReports(
      searchByTextAndCreatedByAndIsEnabled(cloneDeep(reports))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports, searchText, isEnabled, selectedType]);

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const handleInfoDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsInfoDialogOpen(false);
  };

  const handleExportReport = (reportID: number, reportName: string) => {
    exportReport(reportID).catch((error) => {
      if (error.toString() === 'Error: Request failed with status code 406') {
        error.message = 'Invalid report';
      }
      setErrorResponse(error);
      setIsErrorDialogOpen(true);
    });
  };

  const handleRefreshReport = (reportID: number) => {
    setIsBlockedUi(true);
    reportApi
      .refreshReport(reportID)
      .then((resposne) => {
        if (resposne?.data?.isRefreshed) {
          showInfoMessage('Report refreshed');
        } else {
          setErrorResponse('Error');
          setIsErrorDialogOpen(true);
        }
      })
      .catch((error) => {
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      })
      .finally(() => {
        setIsBlockedUi(false);
      });
  };

  const handleDeleteReport = (reportID: number, reportName: string) => {
    setConfirmDialogSettings({
      isOpen: true,
      text: `Delete report ${reportName}?`,
      handleOkClick: () => {
        confirmDelete(reportID, reportName, false);
      },
      handleCancelClick: () => {
        setConfirmDialogSettings({ ...confirmDialogSettings, isOpen: false });
      },
    });
  };

  const confirmDelete = (
    reportID: number,
    reportName: string,
    forceDelete: boolean
  ) => {
    deleteReport(reportID, forceDelete)
      .then(() => {})
      .catch((error) => {
        if (
          error.response.status === 406 &&
          error.response.data.status ===
            ApiResultStatusEnum.PermissionsAddedToGroup
        ) {
          setConfirmDialogSettings({
            isOpen: true,
            text: `${error.response.data.statusMessage}<br/> Delete report ${reportName}?`,
            handleCancelClick: () => {
              setConfirmDialogSettings({
                ...confirmDialogSettings,
                isOpen: false,
              });
            },
            handleOkClick: () => {
              confirmDelete(reportID, reportName, true);
            },
          });
        } else {
          setErrorResponse(error);
          setIsErrorDialogOpen(true);
        }
      });
  };

  const deleteReport = async (
    reportID: number,
    forceDelete: boolean
  ): Promise<DeleteReportApiResultType | void> => {
    const request: DeleteReportModelType = {
      reportID,
      forceDelete,
    };

    const result = await reportApi.deleteReport(request);
    if (result && result.status === ApiResultStatusEnum.Ok) {
      setConfirmDialogSettings({
        ...confirmDialogSettings,
        isOpen: false,
      });
      if (getReportsList) {
        getReportsList();
      }
    } else {
      setConfirmDialogSettings({
        ...confirmDialogSettings,
        isOpen: false,
      });
    }
  };

  const exportReport = async (
    reportID: number
  ): Promise<ApiResultBaseType | void> => {
    const model: ExportReportsModelType = {
      reportIDs: [reportID],
    };
    await reportApi.exportReports(model);
    //const result = await reportApi.exportReports(model);
    //if (result && result.status === ApiResultStatusEnum.Ok) { }
  };

  const showInfoMessage = (infoText: string) => {
    setInfoText(infoText);
    setIsInfoDialogOpen(true);
  };

  const handleAppChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let selectedAppGuid: string = event.target.value as string;
    setSelectedAppGuid(selectedAppGuid);
    savePageParam({
      page: LandingPageEnum.ReportsReports,
      paramType: ParamType.SelectedAppGuid,
      value: selectedAppGuid,
    });
    if (applications) {
      const app = applications.find((app) => app.appGuid === selectedAppGuid);
      setSelectedAppID(app!.appID ?? 0);
      setSelectedInstanceID(app!.instances[0].instanceID);
    }
  };

  const handleSearchValueChange = (
    value: React.SetStateAction<string>
  ): void => {
    let searchText: string = value.toString().toUpperCase();
    searchText = searchText ? searchText : '';
    setSearchText(searchText);
  };

  const handleSelectedStatusChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    let enabled: number = event.target.value as number;
    setIsEnabled(enabled);
  };

  const handleSelectedTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const type: number = event.target.value as number;
    setSelectedType(type);
  };

  const handleRefreshReports = (): void => {
    getReportsList();
  };

  return (
    <ProgressSpinner isDisplayed={isBlockedUi}>
      <Container>
        <ReportsHeader
          client={props.client}
          applications={applications ? applications : []}
          selectedAppGuid={selectedAppGuid}
          onAppChange={handleAppChange}
        />
        <ReportsListFilters
          searchText={searchText}
          selectedStatus={isEnabled}
          onSearchChange={handleSearchValueChange}
          onSelectedStatusChange={handleSelectedStatusChange}
          type={selectedType}
          onTypeChange={handleSelectedTypeChange}
          handleRefreshReports={handleRefreshReports}
          client={props.client}
          applications={applications ? applications : []}
          selectedAppID={selectedAppID}
        />
        <div
          style={{
            border: `1px solid ${theme.palette.graySeparate}`,
          }}
        >
          <ReportsList
            client={props.client}
            reportList={filteredReports}
            handleExportReport={handleExportReport}
            handleDeleteReport={handleDeleteReport}
            handleRefreshReport={handleRefreshReport}
            showInfoMessage={showInfoMessage}
          />
        </div>
        <ErrorDialog
          isOpen={isErrorDialogOpen}
          error={errorResponse}
          handleOkClick={handleErrorDialogOkClick}
        />
        <InfoDialog
          isOpen={isInfoDialogOpen}
          text={infoText}
          handleOkClick={handleInfoDialogOkClick}
        />
        <ConfirmDialog
          text={confirmDialogSettings.text}
          isOpen={confirmDialogSettings.isOpen}
          handleOkClick={confirmDialogSettings.handleOkClick}
          handleCancelClick={confirmDialogSettings.handleCancelClick}
        />
      </Container>
    </ProgressSpinner>
  );
}
