import * as React from "react";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import ButtonOutlined from "Components/Buttons/button-outlined";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import EditReportColumns from "../EditReportColumns/edit-report-columns";
import EditReportGeneral from "../EditReportGeneral/edit-report-general";
import EditReportParameters from "../EditReportParameters/edit-report-parameters";
import SvgPencil from "Components/Images/image-edit-pencil";
import TabPanel from "../../../TabPanel/tab-panel";
import { Grid, Tab, Tabs } from "@material-ui/core";
import { IEditReportTabsContainerProps } from "../interfaces-edit-report";
import { stylesEditReportTabsContainer } from "./styles";
import {
  EditReportDefaultTab,
  IconType,
  ReportSubtypeEnum,
  ReportTypeEnum,
} from "../../../../Common/enum";
import {
  GetReportColumnListType,
  GetReportParameterListType,
} from "Types/report-types";
import EditReportDetailsHTML from "../EditReportDetailsHTML/edit-report-details-html";

const EditReportTabsContainer: (
  props: IEditReportTabsContainerProps
) => JSX.Element = (props): JSX.Element => {
  const customStyles = stylesEditReportTabsContainer();
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };
  const handleChangeParameters = (parameters: GetReportParameterListType[]) => {
    props.changeReportData({ ...props.reportData, parameters });
  };
  const handleChangeColumns = (columns: GetReportColumnListType[]) => {
    props.changeReportData({ ...props.reportData, columns });
  };
  const handleChangeSummaryParameters = (
    summaryParameters: GetReportParameterListType[]
  ) => {
    props.changeReportData({ ...props.reportData, summaryParameters });
  };
  const handleChangeSummaryColumns = (
    summaryColumns: GetReportColumnListType[]
  ) => {
    props.changeReportData({ ...props.reportData, summaryColumns });
  };
  const handleChangeDetailsParameters = (
    detailsParameters: GetReportParameterListType[]
  ) => {
    props.changeReportData({ ...props.reportData, detailsParameters });
  };

  React.useEffect(() => {
    setSelectedTab(props.defaultTab);
  }, [props.defaultTab]);

  const notPowerBiTabs = (subTypeEnum?: ReportSubtypeEnum) => {
    switch (subTypeEnum) {
      case ReportSubtypeEnum.Summary:
        return [
          <Tab label="Main parameters" classes={{ root: customStyles.tab }} />,
          <Tab label="Main columns" classes={{ root: customStyles.tab }} />,
          <Tab
            label="Summary parameters"
            classes={{ root: customStyles.tab }}
          />,
          <Tab label="Summary columns" classes={{ root: customStyles.tab }} />,
          <Tab label="Scheduler" classes={{ root: customStyles.tab }} />,
        ];
      case ReportSubtypeEnum.MasterDetails:
        return [
          <Tab label="Main parameters" classes={{ root: customStyles.tab }} />,
          <Tab label="Main columns" classes={{ root: customStyles.tab }} />,
          <Tab
            label="Details parameters"
            classes={{ root: customStyles.tab }}
          />,
          <Tab label="Details HTML" classes={{ root: customStyles.tab }} />,
        ];
      case ReportSubtypeEnum.Regular:
      default:
        return [
          <Tab label="Parameters" classes={{ root: customStyles.tab }} />,
          <Tab label="Columns" classes={{ root: customStyles.tab }} />,
          <Tab label="Scheduler" classes={{ root: customStyles.tab }} />,
        ];
    }
  };

  const buttons = (
    <Grid
      container
      item
      sm={3}
      alignItems="center"
      justify="flex-end"
      spacing={1}
    >
      {props.isEditable ? (
        <>
          <Grid item>
            <ButtonSubaction text="Cancel" handleClick={props.handleCancel} />
          </Grid>
          <Grid item>
            <ButtonAction isSubmit text="Save" handleClick={props.handleSave} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item>
            <ButtonOutlined
              text="Edit"
              borderWidth="1px"
              iconStartType={IconType.SvgImageIcon}
              iconStart={<SvgPencil width={"18px"} />}
              handleClick={props.handleEdit}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
  return (
    <React.Fragment>
      <Grid container justify="space-between">
        <Grid item>
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            className={customStyles.tabs}
          >
            <Tab label="General" classes={{ root: customStyles.tab }} />
            {props.reportData.reportType !== ReportTypeEnum.PowerBI &&
              notPowerBiTabs(props.reportData.reportSubtype)}
          </Tabs>
        </Grid>
        {buttons}
      </Grid>

      <TabPanel
        padding="10px 0"
        value={selectedTab}
        index={EditReportDefaultTab.General}
      >
        <EditReportGeneral
          isEditable={props.isEditable}
          data={props.reportData}
          changeReportData={props.changeReportData}
          permissionList={props.permissionList}
          reportContainerList={props.reportContainerList}
        />
      </TabPanel>
      {props.reportData.reportType !== ReportTypeEnum.PowerBI && (
        <>
          <TabPanel
            padding="22px 0"
            value={selectedTab}
            index={EditReportDefaultTab.Parameters}
          >
            <EditReportParameters
              isEditable={props.isEditable}
              data={props.reportData.parameters}
              sources={props.reportData.sources}
              dictionaries={props.dictionaries}
              dataTypeFormatList={props.dataTypeFormatList}
              noOfParameterColumns={props.reportData.noOfParameterColumns}
              handleChangeParameters={handleChangeParameters}
              handleChangeNewStoredProcedureParam={
                props.handleChangeNewStoredProcedureParam
              }
              newAddedStoredProcedureParams={
                props.newAddedStoredProcedureParams
              }
            />
          </TabPanel>
          <TabPanel
            padding="22px 0"
            value={selectedTab}
            index={EditReportDefaultTab.Columns}
          >
            <EditReportColumns
              isEditable={props.isEditable}
              data={props.reportData.columns}
              dictionaries={props.dictionaries}
              reportData={props.reportData}
              handleChangeReportData={props.changeReportData}
              dataTypeFormatList={props.dataTypeFormatList}
              handleChangeColumns={handleChangeColumns}
              handleChangeNewStoredProcedureParam={
                props.handleChangeNewStoredProcedureParam
              }
              newAddedStoredProcedureParams={
                props.newAddedStoredProcedureParams
              }
              isMasterDetailsParameters={
                props.reportData.reportSubtype ===
                ReportSubtypeEnum.MasterDetails
              }
            />
          </TabPanel>
          {props.reportData.reportSubtype === ReportSubtypeEnum.Summary && [
            <TabPanel
              padding="22px 0"
              value={selectedTab}
              index={EditReportDefaultTab.SummaryParameters}
            >
              <EditReportParameters
                isEditable={props.isEditable}
                data={props.reportData.summaryParameters}
                sources={props.reportData.sources}
                dictionaries={props.dictionaries}
                dataTypeFormatList={props.dataTypeFormatList}
                noOfParameterColumns={props.reportData.noOfParameterColumns}
                handleChangeParameters={handleChangeSummaryParameters}
                handleChangeNewStoredProcedureParam={
                  props.handleChangeNewStoredProcedureParam
                }
                newAddedStoredProcedureParams={
                  props.newAddedStoredProcedureParams
                }
              />
            </TabPanel>,
            <TabPanel
              padding="22px 0"
              value={selectedTab}
              index={EditReportDefaultTab.SummaryColumns}
            >
              <EditReportColumns
                isEditable={props.isEditable}
                reportData={props.reportData}
                data={props.reportData.summaryColumns}
                dictionaries={props.dictionaries}
                dataTypeFormatList={props.dataTypeFormatList}
                handleChangeColumns={handleChangeSummaryColumns}
                handleChangeReportData={props.changeReportData}
                handleChangeNewStoredProcedureParam={
                  props.handleChangeNewStoredProcedureParam
                }
                newAddedStoredProcedureParams={
                  props.newAddedStoredProcedureParams
                }
              />
            </TabPanel>,
            <TabPanel
              padding="22px 0"
              value={selectedTab}
              index={EditReportDefaultTab.SummaryScheduler}
            >
              Scheduler info
            </TabPanel>,
          ]}
          {props.reportData.reportSubtype ===
            ReportSubtypeEnum.MasterDetails && [
            <TabPanel
              padding="22px 0"
              value={selectedTab}
              index={EditReportDefaultTab.DetailsParameters}
            >
              <EditReportParameters
                isEditable={props.isEditable}
                data={props.reportData.detailsParameters}
                sources={props.reportData.sources}
                dictionaries={props.dictionaries}
                dataTypeFormatList={props.dataTypeFormatList}
                noOfParameterColumns={props.reportData.noOfParameterColumns}
                handleChangeParameters={handleChangeDetailsParameters}
                handleChangeNewStoredProcedureParam={
                  props.handleChangeNewStoredProcedureParam
                }
                newAddedStoredProcedureParams={
                  props.newAddedStoredProcedureParams
                }
              />
            </TabPanel>,
            <TabPanel
              padding="22px 0"
              value={selectedTab}
              index={EditReportDefaultTab.DetailsHTML}
            >
              <EditReportDetailsHTML
                isEditable={props.isEditable}
                changeReportData={props.changeReportData}
                data={props.reportData}
              />
            </TabPanel>,
          ]}
          {props.reportData.reportSubtype == null ||
            (props.reportData.reportSubtype === ReportSubtypeEnum.Regular && [
              <TabPanel
                padding="22px 0"
                value={selectedTab}
                index={EditReportDefaultTab.Scheduler}
              >
                Scheduler info
              </TabPanel>,
            ])}
        </>
      )}
    </React.Fragment>
  );
};

export default EditReportTabsContainer;
