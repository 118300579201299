import * as React from "react";
import { FormEditorEnum, IconType, ApiResultStatusEnum, RoleShareMode } from "Common/enum";
import { ApiResultBaseType, CopyRoleApiResultType, DeleteRoleApiResultType, SaveRoleApiResultType } from "Types/api-types";
import { IConfirmDialogProps } from "Components/Dialogs/interfaces";
import { CopyRoleModelType, DeleteRoleModelType } from "Types/role-types";
import { stylesRoleListItem } from "Components/Role/RoleList/styles";
import { IRoleItemProps } from "Components/Role/interfaces-role";
import { Grid, Menu, MenuItem } from "@material-ui/core";
import { AxiosError } from "axios";
import TypographyInfo from "Components/Typography/typography-info";
import SvgThreeDots from "Components/Images/image-three-dots"
import ButtonIcon from "Components/Buttons/button-icon";
import RoleEditor from "Components/Role/RoleEditor/role-editor";
import SvgHeap from "Components/Images/image-heap";
import RoleApi from "Services/Api/role-api";
import ConfirmDialog from "Components/Dialogs/ConfirmDialog/confirm-dialog";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";

const roleApi = new RoleApi();

export default function RoleItem(props: IRoleItemProps) {
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [
    confirmDialogSettings,
    setConfirmDialogSettings,
  ] = React.useState<IConfirmDialogProps>({
    isOpen: false,
    text: "",
    handleCancelClick: () => {},
    handleOkClick: () => {},
  });

  const customStyles = stylesRoleListItem();

  const enabledRoleColor = "#444444"
  const disabledRoleColor = "#AEAEAE"

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const handleOkDeleteClick = (): void => {
    deleteRole({ roleID: props.role.roleID })
      .then(() => {})
      .catch((error) => {
        if (
          error.response.status === 406 &&
          error.response.data.status === ApiResultStatusEnum.RoleInUse
        ) {
          setConfirmDialogSettings({
            isOpen: true,
            text: error.response.data.statusMessage,

            handleCancelClick: () => {
              setConfirmDialogSettings({
                ...confirmDialogSettings,
                text: error.response.data.statusMessage,
                isOpen: false,
              });
            },
            handleOkClick: () => {
              setConfirmDialogSettings({
                ...confirmDialogSettings,
                text: error.response.data.statusMessage,
                isOpen: false,
              });
            },
          });
        } else {
          setErrorResponse(error);
          setIsErrorDialogOpen(true);
        }
      });
  };

  const handleOkCopyClick = (): void => {
    copyRole({ roleID: props.role.roleID })
      .then(() => {})
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const handleCancelClick = (): void => {
    setConfirmDialogSettings({
      ...confirmDialogSettings,
      isOpen: false,
    });
  };

  const handleErrorDialogOkClick = (): void => {
    setIsErrorDialogOpen(false);
  };

  const handleCopyRole = () => {
    setAnchorEl(null)
    setConfirmDialogSettings({
      ...confirmDialogSettings,
      text: `Do you want to copy role ${props.role.name}?`,
      isOpen: true,
      handleCancelClick: handleCancelClick,
      handleOkClick: handleOkCopyClick,
    });
  }

  const handleDeleteRole = () => {
    setAnchorEl(null)
    setConfirmDialogSettings({
      ...confirmDialogSettings,
      text: `Do you want to delete role ${props.role.name}?`,
      isOpen: true,
      handleCancelClick: handleCancelClick,
      handleOkClick: handleOkDeleteClick,
    });
  };

  const deleteRole = async (
    role: DeleteRoleModelType
  ): Promise<SaveRoleApiResultType | void> => {
    const result: DeleteRoleApiResultType = await roleApi.deleteRole(role);

    resolveApiResult(result);
  };

  const copyRole = async (
    role: CopyRoleModelType
  ): Promise<CopyRoleApiResultType | void> => {
    const result: CopyRoleApiResultType = await roleApi.copyRole(role);
    
    resolveApiResult(result);
  }

  const resolveApiResult = async (
    result: ApiResultBaseType
  ): Promise<ApiResultBaseType | void> => {
    if (result && result.status === ApiResultStatusEnum.Ok) {
      setConfirmDialogSettings({
        ...confirmDialogSettings,
        isOpen: false,
      });

      props.getRoles();
    } else {
      setConfirmDialogSettings({
        ...confirmDialogSettings,
        isOpen: false,
      });
    }
  }

  return (
    <tr>
      <td className={customStyles.cell}>
                <TypographyInfo text={props.role.name} color={props.role.isEnabled ? enabledRoleColor : disabledRoleColor} />
      </td>
      <td className={customStyles.cell}>
            <Grid container item xs={12} alignItems="center">
              <SvgHeap className={customStyles.heapIcon} />

              {props.role.applications.map((app) => {
                return (
                  <TypographyInfo
                    key={app.appID}
                    text={app.name}
                    color={props.role.isEnabled ? enabledRoleColor : disabledRoleColor}
                    style={{ marginRight: "15px", fontSize: "12px" }}
                  />
                );
              })}
            </Grid>
      </td>
      <td className={customStyles.cell}>
      <p className={props.role.isEnabled ? customStyles.textCellEnabled : customStyles.textCellDisabled} >
      {RoleShareMode[props.role.shareMode]}
      {props.role.isDefault &&
        ', Default'
      }
      </p>
        </td>
        <td className={customStyles.cellCentered}>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                keepMounted
              >
                <MenuItem
                  onClick={handleCopyRole}
                >
                Copy Role
                </MenuItem>
                <RoleEditor
                  onOpen={() => setAnchorEl(null)}
                  editorType={FormEditorEnum.Edit}
                  appList={props.appList}
                  getRoles={props.getRoles}
                  role={props.role}
                  client={props.client}
                />
                <MenuItem
                  onClick={handleDeleteRole}
                >
                Delete role
                </MenuItem>
              </Menu>
              <ButtonIcon
                iconType={IconType.SvgImageIcon}
                icon={<SvgThreeDots />}
                handleClick={handleMenuOpen}
              />
              <ConfirmDialog
                text={confirmDialogSettings.text}
                isOpen={confirmDialogSettings.isOpen}
                handleOkClick={confirmDialogSettings.handleOkClick}
                handleCancelClick={confirmDialogSettings.handleCancelClick}
              />

              <ErrorDialog
                isOpen={isErrorDialogOpen}
                error={errorResponse}
                handleOkClick={handleErrorDialogOkClick}
              />
            </td>
    </tr>
  );
}
