import * as React from "react";
import Colors from "Common/colors";
import { makeStyles } from "@material-ui/core";
import { ITypographySubtitleProps } from "./interfaces-typography";

const styles = makeStyles({ 
    text: (props: ITypographySubtitleProps) => ({
        color: props.color ? props.color : Colors.black,
        fontSize: '24px',
        margin: props.margin ? props.margin : '10px 0',
        textTransform: props.isUppercase ? 'uppercase' : 'none'
    })
});

export default function TypographySubtitle (props: ITypographySubtitleProps) {
    const customStyles = styles(props);

    
    return (
        <p className={customStyles.text}>
            {props.text}
        </p>
    )
}