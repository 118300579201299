import * as React from "react";
import { InputAdornment } from "@material-ui/core";
import SvgSearch from "Components/Images/image-search";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import { SearchFieldProps } from "../interfaces-input";
import { stylesSearchField } from "../styles";

const WAIT_INTERVAL = 300;

const SearchField: (props: SearchFieldProps) => JSX.Element = (
  props: SearchFieldProps
): JSX.Element => {
  const customStyles = stylesSearchField();
  let search: string = "";
  let time: NodeJS.Timeout;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) => {
    e.preventDefault();
    clearTimeout(time);
    search = value;
    time = setTimeout(triggerChange, WAIT_INTERVAL);
  };

  const triggerChange = () => {
    props.onChangeEvent(search);
  };

  return (
    <TextFieldEntered
      placeholder="Search"
      style={{ ...props }}
      className={customStyles.root}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgSearch className={customStyles.inputAdornment} />
          </InputAdornment>
        ),
      }}
      onChange={(e) => handleChange(e, e.target.value)}
      onKeyPress={(ev) => {
        if (props.preventEnterKey) {
          if (ev.key === "Enter") {
            // do nothing
            ev.preventDefault();
          }
        }
      }}
    />
  );
};

export default SearchField;
