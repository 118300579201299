import * as React from "react";
import { Grid } from "@material-ui/core";
import { stylesHeader } from "./styles";
import { HeaderProps } from "./interfaces";
import { IconType } from "Common/enum";
import Colors from "Common/colors";
import ButtonOutlined from "Components/Buttons/button-outlined";
import SvgPlusCircle from "Components/Images/image-plus-circle";
import HeaderCard from "Components/HeaderCard/header-card";

const Header: (props: HeaderProps) => JSX.Element = (
  props: HeaderProps
): JSX.Element => {
  const customStyles = stylesHeader();

  return (
    <React.Fragment>
      <HeaderCard>
        <Grid container alignItems="center" justify="space-between">
          <Grid item className={customStyles.title}>
            {props.headerText}
          </Grid>
          {props.withAddButton && (
            <ButtonOutlined
              text={props.addButtonText ?? "Add"}
              iconStartType={IconType.SvgImageIcon}
              iconStart={
                <SvgPlusCircle width={"14px"} color={Colors.grayDark} />
              }
              size="md"
              margin="0 10px 0 0"
              handleClick={props.handleAddButtonClick}
            />
          )}
        </Grid>
      </HeaderCard>
    </React.Fragment>
  );
};

export default Header;
