import * as React from "react";

const SvgImportReport = ({
    style = {},
    fill = "none",
    color = "#444444",
    width = "50",
    height = "62",
    className = "",
    viewBox = "0 0 50 62"
  }) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <rect x="0.5" y="0.5" width="33" height="33" rx="4.5" stroke={color} strokeDasharray="3 3" />
        <path d="M38 20.5C44.3513 20.5 49.5 25.6487 49.5 32L49.5 58C49.5 59.933 47.933 61.5 46 61.5L24 61.5C22.067 61.5 20.5 59.933 20.5 58L20.5 24C20.5 22.067 22.067 20.5 24 20.5L38 20.5Z" fill="white" stroke="#444444"/>
        <path d="M50 32L42 32C39.7909 32 38 30.2091 38 28L38 20" stroke={color} />
        <path d="M26 40H44" stroke="{color}" />
        <path d="M26 47H44" stroke="{color}" />
        <path d="M26 54H44" stroke="{color}" />
    </svg>
);

export default SvgImportReport;