import { createMuiTheme, MuiThemeProvider, useTheme } from '@material-ui/core';
import { ArrowDownward } from '@material-ui/icons';
import Colors from 'Common/colors';
import { customSortList } from 'Common/utils';
import { IReportsListProps } from 'Components/Reports/interfaces-reports';
import ReportsListItem from 'Components/Reports/Reports/ReportsList/reports-list-item';
import MaterialTable, { Icons } from 'material-table';
import moment from 'moment';
import * as React from 'react';
import { forwardRef } from 'react';
import { GetReportListApiResponseType } from 'Types/report-types';

export default function ReportsList(props: IReportsListProps) {
  const theme = useTheme();
  const [selectedRow, setSelectedRow] = React.useState<
    GetReportListApiResponseType | undefined
  >();

  const tableIcons: Icons = {
    //Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    // Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    // Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    // Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    // DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    // Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    // Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    // FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    // LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    // NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    // PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    // ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    // Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    //ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    //ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const headerStyle: React.CSSProperties = {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.graySeparate.main}`,
    borderBottomWidth: `2px`,
    fontSize: '14px',
    padding: '8px',
  };
  const themeTable = createMuiTheme(theme, {
    overrides: {
      MuiTableSortLabel: {
        root: {
          width: 'max-content',
        },
      },
      MuiTable: {
        root: {
          border: `1px solid ${theme.palette.graySeparate.main}`,
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={themeTable}>
      <MaterialTable
        icons={tableIcons}
        columns={[
          {
            width: 'auto',
            title: 'Active',
            field: 'isEnabled',
            headerStyle: {
              textAlign: 'center',
            },
            sorting: false,
          },
          {
            width: 'auto',
            title: 'Type',
            field: 'reportType',
          },
          {
            width: 'auto',
            title: 'Name',
            field: 'reportName',
            customSort: (
              data1: GetReportListApiResponseType,
              data2: GetReportListApiResponseType,
              type: 'row' | 'group'
            ): number => {
              return data1.reportName.localeCompare(data2.reportName);
            },
          },
          {
            width: 'auto',
            title: 'SP name',
            field: 'definitionProcedureName',
          },
          {
            width: 'auto',
            title: 'Containers',
            field: 'containers',
            sorting: true,
            customSort: (
              data1: GetReportListApiResponseType,
              data2: GetReportListApiResponseType
            ) =>
              customSortList(
                data1.containers?.map((p) => p.reportContainerName) ?? [],
                data2.containers?.map((p) => p.reportContainerName) ?? []
              ),
          },
          {
            width: 'auto',
            title: 'Permission',
            field: 'permission',

            customSort: (
              data1: GetReportListApiResponseType,
              data2: GetReportListApiResponseType,
              type: 'row' | 'group'
            ): number => {
              return (data1.permissionName ?? '').localeCompare(
                data2.permissionName ?? ''
              );
            },
          },
          {
            width: 'auto',
            title: 'Updated',
            field: 'modificationDate',
            defaultSort: 'desc',
            customSort: (
              data1: GetReportListApiResponseType,
              data2: GetReportListApiResponseType
            ): number => {
              return moment(
                data1.modificationDate ?? data1.insertDate ?? ''
              ).isAfter(data2.modificationDate ?? data2.insertDate ?? '')
                ? 1
                : -1;
            },
          },
          {
            width: 'auto',
            title: '',
            disableClick: true,
            sorting: false,
            cellStyle: {},
          },
        ]}
        data={props.reportList}
        options={{
          showTitle: false,
          header: true,
          paging: false,
          search: false,
          toolbar: false,
          draggable: false,
          tableLayout: 'auto',
          headerStyle: {
            color: '#AEAEAE',
            fontSize: '14px',
            borderBottom: `2px solid ${theme.palette.graySeparate.main}`,
            padding: '5px 5px 5px 5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          },
        }}
        onRowClick={(event, selectedRow) => setSelectedRow(selectedRow)}
        components={{
          Row: (propsRow) => (
            <ReportsListItem
              client={props.client}
              report={propsRow.data}
              handleExportReport={props.handleExportReport}
              handleDeleteReport={props.handleDeleteReport}
              handleRefreshReport={props.handleRefreshReport}
              showInfoMessage={props.showInfoMessage}
              isSelected={
                selectedRow !== undefined &&
                propsRow.data.reportID === selectedRow!.reportID
              }
            />
          ),
        }}
        style={{
          background: Colors.white,
          boxShadow: 'none',
          marginBottom: '28px',
        }}
      />
    </MuiThemeProvider>
  );
}
