import * as React from "react";
import { ImportStructureProps } from "../interfaces";
import { IconType } from "Common/enum";
import ButtonTransparent from "Components/Buttons/button-transparent";
import SvgImport from "Components/Images/image-import";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import FileUpload from "Components/File/FileUpload/file-upload";
import { axiosInstance } from "Services/Api/base-api";
import moment from "moment";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import InfoDialog from "Components/Dialogs/InfoDialog/info-dialog";
import ProgressSpinner from "Components/ProgressSpinner/progress-spinner";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";

let envBaseURL: string = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : "https://intelliadmin-webapi-core-test.azurewebsites.net/api/";
let envApiVersion: string = process.env.REACT_APP_API_VERSION
  ? process.env.REACT_APP_API_VERSION
  : "v1";

const ImportStructure: (props: ImportStructureProps) => JSX.Element = (
  props: ImportStructureProps
): JSX.Element => {
  const fileMaxSize: number = 104857600; //100 MB
  const fileUploadAcceptedFormats = [".xlsx"];
  const [isBlockedUi, setIsBlockedUi] = React.useState<boolean>(false);
  const [dialogIsOpen, setDialogIsOpen] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<Blob>();
  const [dialogErrorText, setDialogErrorText] = React.useState<string>("");
  const [dialogErrorIsOpen, setDialogErrorIsOpen] =
    React.useState<boolean>(false);
  const [dialogInfoIsOpen, setDialogInfoIsOpen] =
    React.useState<boolean>(false);

  const handleClick: () => void = (): void => {
    setIsBlockedUi(true);
    let url: string = "/Import/XlsxImportAppStructure";
    let formData: FormData = new FormData();
    formData.append("File", file!);
    axiosInstance({
      url: `${envBaseURL}${envApiVersion}${url}`,
      method: "POST",
      data: formData,
      responseType: "blob",
    })
      .then((response) => {
        if (
          response.data.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          setDialogErrorText(
            "The data in the file is incorrect. Download the document and check the status column"
          );
          setDialogErrorIsOpen(true);
          let fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `iAdmin_import_error_${moment().format("YYYYMMDDHHmm")}.xlsx`
          );
        } else {
          switch (response.status) {
            case 204:
              setDialogErrorText("Column is missing in provided file");
              setDialogErrorIsOpen(true);
              break;
            case 206:
              setDialogErrorText("No data rows in provided file");
              setDialogErrorIsOpen(true);
              break;
            default:
              setDialogInfoIsOpen(true);
              setDialogIsOpen(false);
              break;
          }
        }
      })
      .catch((error) => {
        setDialogErrorText("An error occured");
        setDialogErrorIsOpen(true);
      })
      .finally(() => {
        setIsBlockedUi(false);
      });
  };

  return (
    <React.Fragment>
      <ButtonTransparent
        text="Import"
        iconStartType={IconType.SvgImageIcon}
        iconStart={<SvgImport width="20px" height="20px" color="#000000" />}
        handleClick={() => {
          setDialogIsOpen(true);
        }}
      />
      <ProgressSpinner isDisplayed={isBlockedUi}>
        <Dialog
          open={dialogIsOpen}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={() => {
            setDialogIsOpen(false);
          }}
        >
          <DialogTitle>
            <TypographySubtitle
              text="Import applications structure"
              margin="0px"
            />
          </DialogTitle>
          <DialogContent dividers>
            <Grid container justify="center" alignItems="flex-start">
              <Grid container item sm={6}>
                <FileUpload
                  file={file}
                  setFile={(newFile) => setFile(newFile)}
                  acceptedFormats={fileUploadAcceptedFormats}
                  maxSize={fileMaxSize}
                  width="250px"
                  height="200px"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonSubaction
              text="Cancel"
              handleClick={() => {
                setDialogIsOpen(false);
              }}
            />
            <ButtonAction
              text="Import"
              handleClick={() => {
                handleClick();
              }}
            />
          </DialogActions>
        </Dialog>
      </ProgressSpinner>
      <InfoDialog
        isOpen={dialogInfoIsOpen}
        text={"Successful import"}
        handleOkClick={() => window.location.reload()}
      />
      <ErrorDialog
        isOpen={dialogErrorIsOpen}
        error={dialogErrorText}
        handleOkClick={() => setDialogErrorIsOpen(false)}
      />
    </React.Fragment>
  );
};

export default ImportStructure;
