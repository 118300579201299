import * as React from "react";
import { ReportParameterSourceType } from "../../../../Common/enum";
import SvgMenuButton from "../../../../Components/Images/image-menu-button";
import { IconButton, Grid } from "@material-ui/core";
import {
  GetReportParameterListType,
  GetReportDictionariesApiResponseType,
  SaveReportStoredProcedureParameterType,
} from "../../../../Types/report-types";
import SelectSourceDialog from "./SelectSourceDialog/select-source-dialog";

export interface SelectSourceProcedureProps {
  data: GetReportParameterListType;
  list: GetReportParameterListType[];
  handleChangeParameters: (parameters: GetReportParameterListType[]) => void;
  dictionaries: GetReportDictionariesApiResponseType[];

  newAddedStoredProcedureParams: SaveReportStoredProcedureParameterType[];
  handleChangeNewStoredProcedureParam: (
    data: SaveReportStoredProcedureParameterType[]
  ) => void;
}

const SelectSourceProcedure: React.FC<SelectSourceProcedureProps> = ({
  data,
  list,
  dictionaries,
  handleChangeParameters,
  handleChangeNewStoredProcedureParam,
  newAddedStoredProcedureParams,
}) => {
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  return (
    <React.Fragment>
      <Grid container justify="space-between" wrap="nowrap">
        <Grid container alignItems="center" alignContent="center">
          {sourceName(data)}
        </Grid>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={() => setIsOpenDialog(true)}
        >
          <SvgMenuButton />
        </IconButton>
      </Grid>
      <SelectSourceDialog
        isOpen={isOpenDialog}
        dictionaries={dictionaries}
        handleClose={() => setIsOpenDialog(false)}
        list={list}
        data={data}
        handleChangeParameters={handleChangeParameters}
        newAddedStoredProcedureParams={newAddedStoredProcedureParams}
        handleChangeNewStoredProcedureParam={
          handleChangeNewStoredProcedureParam
        }
      />
    </React.Fragment>
  );
};

export default SelectSourceProcedure;

export const sourceName = (data: GetReportParameterListType) => (
  <React.Fragment>
    {data.sourceType === ReportParameterSourceType.Procedure &&
    data.sourceProcedure != null &&
    data.sourceProcedure.length > 0
      ? `!${data.sourceProcedure}`
      : data.sourceType === ReportParameterSourceType.Dictionary &&
        data.reportDictionarySourceName != null &&
        data.reportDictionarySourceName.length > 0
      ? `@${data.reportDictionarySourceName}`
      : ""}

    {data.sourceType === ReportParameterSourceType.Dictionary &&
    data.reportDictionarySourceName
      ? data.isSourceMultiselect
        ? ":M"
        : ":S"
      : ""}
  </React.Fragment>
);
