import { IEditReportParametersProps } from "../interfaces-edit-report";
import * as React from "react";
import MaterialTable, { Icons } from "material-table";
import {
  ArrowDownward,
  Check,
  Clear,
  DeleteOutline,
  Edit,
} from "@material-ui/icons";
import { forwardRef } from "react";
import { GetReportParameterListType } from "Types/report-types";
import Colors from "Common/colors";
import EditReportParametersListItem from "./edit-report-parameters-list-item";
import EditReportParametersListItemEditable from "./edit-report-parameters-list-item-editable";
import { ReportParameterEnterType } from "Common/enum";

const EditReportParameters: (props: IEditReportParametersProps) => JSX.Element =
  (props): JSX.Element => {
    const tableIcons: Icons = {
      //Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} />
      )),
      // DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      // Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      // FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      // LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      // NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      // PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      // ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      // Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
      )),
      //ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      //ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const [selectedRowOrderNo, setSelectedRowOrderNo] = React.useState<
      number[]
    >([]);

    const handleMouseDown = (orderNo: number[]) => {
      setSelectedRowOrderNo(orderNo);
    };

    const handleMouseUp = () => {
      if (selectedRowOrderNo.length > 0) {
        setSelectedRowOrderNo([]);
      }
    };

    const handleMouseLeave = () => {
      if (selectedRowOrderNo.length > 0) {
        setSelectedRowOrderNo([]);
      }
    };
    const handleMouseEnter = (orderNo: number) => {
      if (selectedRowOrderNo.length === 1) {
        moveOneParam(selectedRowOrderNo[0], orderNo);
      } else if (selectedRowOrderNo.length === 2) {
        moveTwoParams(orderNo);
      }
    };
    const moveOneParam = (selected: number, orderNo: number) => {
      const directionDown = selected < orderNo;
      const directionUp = selected > orderNo;

      props.handleChangeParameters(
        props.data
          .map((p) =>
            p.orderNo === selected
              ? {
                  ...p,
                  orderNo,
                }
              : directionUp && p.orderNo >= orderNo && p.orderNo < selected
              ? { ...p, orderNo: p.orderNo + 1 }
              : directionDown && p.orderNo <= orderNo && p.orderNo > selected
              ? { ...p, orderNo: p.orderNo - 1 }
              : p
          )
          .sort((a, b) => a.orderNo - b.orderNo)
      );
      setSelectedRowOrderNo([orderNo]);
    };
    const moveTwoParams = (orderNo: number) => {
      const firstOrderNo =
        selectedRowOrderNo[0] < selectedRowOrderNo[1]
          ? selectedRowOrderNo[0]
          : selectedRowOrderNo[1];
      const secondOrderNo =
        selectedRowOrderNo[0] > selectedRowOrderNo[1]
          ? selectedRowOrderNo[0]
          : selectedRowOrderNo[1];
      const directionDown = secondOrderNo < orderNo;

      if (selectedRowOrderNo.includes(orderNo)) {
        setSelectedRowOrderNo([firstOrderNo, secondOrderNo]);
      } else {
        props.handleChangeParameters(
          props.data
            .map((p) =>
              directionDown
                ? p.orderNo < firstOrderNo || p.orderNo > orderNo
                  ? p
                  : p.orderNo === secondOrderNo + 1
                  ? { ...p, orderNo: firstOrderNo }
                  : { ...p, orderNo: p.orderNo + 1 }
                : p.orderNo < orderNo || p.orderNo > secondOrderNo
                ? p
                : p.orderNo === firstOrderNo - 1
                ? { ...p, orderNo: secondOrderNo }
                : { ...p, orderNo: p.orderNo - 1 }
            )
            .sort((a, b) => a.orderNo - b.orderNo)
        );
        setSelectedRowOrderNo(
          directionDown
            ? [firstOrderNo + 1, secondOrderNo + 1]
            : [firstOrderNo - 1, secondOrderNo - 1]
        );
      }
    };

    return (
      <MaterialTable
        icons={tableIcons}
        columns={[
          {
            title: "",
            field: "orderNo",
            sorting: true,
            defaultSort: "asc",
            headerStyle: {
              borderLeft: "none",
            },
            /* hack for setting column width */
            ...(true && ({ width: "3%" } as object)),
          },
          {
            title: "DB Name / Type",
            field: "databaseName",
            disableClick: true,
            /* hack for setting column width */
            ...(true && ({ width: "10%" } as object)),
          },
          {
            title: "Name",
            field: "displayName",
            /* hack for setting column width */
            ...(true && ({ width: "10%" } as object)),
          },
          {
            title: "Default",
            field: "defaultValueText",
            /* hack for setting column width */
            ...(true && ({ width: "10%" } as object)),
          },
          {
            title: "Visible",
            field: "visible",
            type: "boolean",
            headerStyle: {
              textAlign: "center",
            },
            /* hack for setting column width */
            ...(true && ({ width: "5%" } as object)),
          },
          {
            title: "Input Type",
            field: "enterType",
            /* hack for setting column width */
            ...(true && ({ width: "10%" } as object)),
          },
          {
            title: "Source",
            field: "sourceProcedure",
            /* hack for setting column width */
            ...(true && ({ width: "20%" } as object)),
          },
          {
            title: "Format",
            field: "dataTypeFormatID",
            /* hack for setting column width */
            ...(true && ({ width: "15%" } as object)),
          },
          // {
          //   title: 'Alignment',
          //   field: 'alignment',
          // },
          {
            title: "Column",
            field: "columnNo",
            type: "numeric",
            headerStyle: {
              textAlign: "left",
            },
            /* hack for setting column width */
            ...(true && ({ width: "5%" } as object)),
          },
          {
            title: "I/O type",
            field: "databaseDirection",
            /* hack for setting column width */
            ...(true && ({ width: "8%" } as object)),
          },
          {
            title: "Required",
            field: "required",
            type: "boolean",
            headerStyle: {
              textAlign: "center",
            },
            /* hack for setting column width */
            ...(true && ({ width: "5%" } as object)),
          },
        ]}
        data={props.data}
        options={{
          showTitle: false,
          header: true,
          paging: false,
          search: false,
          toolbar: false,
          sorting: false,
          draggable: false,
          headerStyle: {
            color: "#AEAEAE",
            fontSize: "14px",
            borderBottom: `2px solid ${Colors.graySeparate}`,
            borderLeft: `1px solid ${Colors.graySeparate}`,
            padding: "10px 5px 9px 12px",
          },
        }}
        // onRowClick={(event, selectedRow) => setSelectedRow(selectedRow)}

        components={{
          Row: (propsRow) => {
            return props.isEditable ? (
              <EditReportParametersListItemEditable
                key={propsRow.data.parameterID}
                data={propsRow.data}
                source={props.sources.find(
                  (s) => s.parameterID === propsRow.data.parameterID
                )}
                list={props.data}
                dictionaries={props.dictionaries}
                dataTypeFormatList={props.dataTypeFormatList}
                noOfParameterColumns={props.noOfParameterColumns}
                handleChangeParameters={props.handleChangeParameters}
                handleMouseDown={handleMouseDown}
                handleMouseUp={handleMouseUp}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                selectedRowOrderNo={selectedRowOrderNo}
                handleChangeNewStoredProcedureParam={
                  props.handleChangeNewStoredProcedureParam
                }
                newAddedStoredProcedureParams={
                  props.newAddedStoredProcedureParams
                }
              />
            ) : (
              <EditReportParametersListItem
                key={propsRow.data.parameterID}
                data={propsRow.data}
                list={props.data}
                dataTypeFormatList={props.dataTypeFormatList}
              />
            );
          },
        }}
        style={{
          background: "white",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
        }}
      />
    );
  };
export default EditReportParameters;

export const isFirstParamDataRange = (
  data: GetReportParameterListType,
  list: GetReportParameterListType[]
): boolean =>
  data.enterType === ReportParameterEnterType.Range &&
  data.orderNo !== list.length &&
  !!list.find(
    (p) =>
      p.orderNo === data.orderNo + 1 &&
      p.enterType === ReportParameterEnterType.Range &&
      p.dataType === data.dataType
  );

export const isSecondParamDataRange = (
  data: GetReportParameterListType,
  list: GetReportParameterListType[]
): boolean =>
  data.enterType === ReportParameterEnterType.Range &&
  data.orderNo !== 1 &&
  !!list.find(
    (p) =>
      p.orderNo === data.orderNo - 1 &&
      p.enterType === ReportParameterEnterType.Range &&
      p.dataType === data.dataType
  );
