import * as React from "react";

const SvgCustomerRole = ({
    style = {},
    fill = "none",
    color = "black",
    width = "12",
    height = "16",
    className = "",
    viewBox = "0 0 12 16",
    title = ""
  }) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M3.5 6H1V15H11V6H8.5" stroke={color} />
        <path d="M7 15V12H5V15" stroke={color} />
        <path d="M9 6V1H3V6" stroke={color} />
        <path d="M6 9.5V7.5" stroke={color} />
        <path d="M6 6V4" stroke={color} />
    </svg>
);

export default SvgCustomerRole;