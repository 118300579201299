import { makeStyles } from '@material-ui/core';
import Colors from 'Common/colors';

export const stylesDisabledPermissions = makeStyles({
  paper: {
    padding: '10px 37px 5px 37px',
  },
});

export const nameStyle = {
  borderBottom: `1px solid ${Colors.graySeparate}`,
  fontSize: '14px',
  padding: '2px 0 16px 0',
};
