import { makeStyles, Theme } from "@material-ui/core";

export const stylesPermissionNameEditor = makeStyles((theme: Theme) => ({
  selectFormControl: {
    minWidth: "120px",
  },
  dialogContent: {
    "&:first-child": {
      paddingTop: "5px",
    },
  },

  dialogActions: {
    padding: "10px",
  },
}));
