import Colors from "Common/colors";
import { makeStyles } from "@material-ui/core";

export const stylesWebServiceListItem = makeStyles({
  buttonEdit: {
    float: "right",
    padding: "20px",
  },
  paper: {
    marginRight: "10px",
    marginTop: "15px",
  },
  paperLink: {
    marginTop: "5px",
    paddingLeft: "10px",
    boxShadow: "none",
    backgroundColor: Colors.grayLight,
  },
  releaseInfo: {
    paddingLeft: "40px !important",
    borderLeft: `2px solid ${Colors.gray}`,
  },
});

export const stylesWebServiceListDragHandle = makeStyles({
  dragIcon: {
    fontSize: "16px",
    display: "block",
    margin: "auto",
  },
  dragWrapper: {
    height: "100px",
    backgroundColor: Colors.grayLight,
    border: `1px solid ${Colors.graySeparate}`,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    borderRadius: "5px 0px 0px 5px",
  },
});
