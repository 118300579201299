import {
  Checkbox,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Colors from "Common/colors";
import { IconType } from "Common/enum";
import ButtonOutlined from "Components/Buttons/button-outlined";
import SvgArrowRight from "Components/Images/image-arrow-right";
import SearchField from "Components/Inputs/SearchField/search-field";
import InputSearch from 'Components/Inputs/input-search';
import TypographyInfo from 'Components/Typography/typography-info';
import React from 'react';
import { stylesRoleEditor } from './styles';
import { onSearchChange } from './utils';
import { RoleModel } from './models';
import { convertShareModeToSymbol } from 'Common/utils';

export interface AllRolesProps {
  list: RoleModel[];
  filteredList: RoleModel[];
  setFilterd: (roles: RoleModel[]) => void;
  remove: (rolesIDs: number[]) => void;
  addToCustomerRoles: (roles: RoleModel[]) => void;
  toggleSelectRole: (roleID: number) => void;
}

const AllRoles: React.FC<AllRolesProps> = ({
  list,
  filteredList,
  setFilterd,
  remove,
  addToCustomerRoles,
  toggleSelectRole,
}) => {
  const customStyles = stylesRoleEditor();

  const moveToSelected = async (): Promise<void> => {
    const selected = filteredList.filter((r) => r.isSelected);
    if (selected.length > 0) {
      remove(selected.map((r) => r.data.roleID));
      addToCustomerRoles(selected);
    }
  };

  const rolesToMoveCount = filteredList.filter((r) => r.isSelected).length;

  return (
    <Grid container item sm={6} direction="column" alignItems="flex-start">
      <Grid item sm={12} alignItems="center">
        <TypographyInfo
          text={`All roles (${list.length})`}
          color={Colors.black}
        />
      </Grid>
      <Grid container item sm={12} alignItems="center" spacing={1}>
        <Grid item sm={8} alignItems="center">
          <SearchField
            onChangeEvent={(value: React.SetStateAction<string>) =>
              onSearchChange(value, list, setFilterd)
            }
            preventEnterKey
            marginTop="0px"
          />
        </Grid>
        <Grid item sm={4} alignItems="flex-end">
          <ButtonOutlined
            text={
              "Move" + (rolesToMoveCount > 0 ? ` (${rolesToMoveCount})` : "")
            }
            iconStartType={IconType.SvgImageIcon}
            iconStart={
              <SvgArrowRight
                width={"14px"}
                color={
                  rolesToMoveCount > 0 ? Colors.grayDark : Colors.grayInactive
                }
              />
            }
            size="md"
            handleClick={moveToSelected}
            disabled={rolesToMoveCount < 1}
            borderColor={
              rolesToMoveCount > 0 ? Colors.grayDark : Colors.grayInactive
            }
          />
        </Grid>
      </Grid>
      <Grid item className={customStyles.list}>
        {filteredList.length > 0
          ? filteredList.map((role: RoleModel) => {
              return (
                <ListItem
                  key={role.data.roleID}
                  role={undefined}
                  dense
                  button
                  className={customStyles.listItem}
                  onClick={() => toggleSelectRole(role.data.roleID)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={role.isSelected}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": role.data.name,
                      }}
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={role.data.roleID.toString()}
                    primary={`${role.data.name} (${convertShareModeToSymbol(role.data.shareMode)})`}
                  />
                </ListItem>
              );
            })
          : "No items"}
      </Grid>
    </Grid>
  );
};

export default AllRoles;
