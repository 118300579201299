import * as React from "react";
import { ChangeEvent } from "react";
import { TextField } from "@material-ui/core";
import {
  GetReportColumnListType,
  GetReportDetailsApiResponseType,
  SaveReportStoredProcedureParameterType,
} from "../../../../Types/report-types";
import { IconButton, Grid } from "@material-ui/core";
import SvgMenuButton from "../../../../Components/Images/image-menu-button";
import SelectColumnNameDialog from "../EditReportParameters/SelectColumnNameDialog/select-column-name-dialog";
import { ReportParameterDisplayNameType } from "Common/enum";

export interface SelectDisplayNameProps {
  data: GetReportColumnListType;
  list: GetReportColumnListType[];
  handleChangeColumns: (columns: GetReportColumnListType[]) => void;
  reportData: GetReportDetailsApiResponseType;
  newAddedStoredProcedureParams: SaveReportStoredProcedureParameterType[];
  handleChangeNewStoredProcedureParam: (
    data: SaveReportStoredProcedureParameterType[]
  ) => void;
}

function SelectDisplayName(props: SelectDisplayNameProps) {
  const { data, list, handleChangeColumns, reportData } = props;
  const [value, setValue] = React.useState<string>(data.displayName);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const handleChangeDisplayName = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    columnID: number
  ) => {
    handleChangeColumns(
      list.map((p) =>
        p.columnID === columnID ? { ...p, displayName: value } : p
      )
    );
  };

  React.useEffect(() => {
    setValue(data.displayName);
  }, [data.displayName]);

  return (
    <>
      <Grid container justify="space-between" alignItems="center" wrap="nowrap">
        {data.displayNameType === ReportParameterDisplayNameType.SimpleText ? (
          <TextField
            id={`${data.columnID}_displayName`}
            key={`${data.columnID}_displayName`}
            name="displayName"
            InputProps={{
              disableUnderline: true,
              style: { fontSize: "14px" },
            }}
            value={value}
            onBlur={(
              event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => handleChangeDisplayName(event, data.columnID)}
            onChange={(
              event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setValue(event.target.value)}
            fullWidth
          />
        ) : (
          <p
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "150px",
            }}
          >
            {`[${data.displayNameStoredProcedureRowIndex}] - ${reportData.columnsDisplayNameStoredProcedure}`}
          </p>
        )}
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={() => setIsOpenDialog(true)}
        >
          <SvgMenuButton />
        </IconButton>
      </Grid>
      {isOpenDialog && (
        <SelectColumnNameDialog
          isOpen={isOpenDialog}
          handleClose={() => setIsOpenDialog(false)}
          data={data}
          clickOk={(data) => {
            handleChangeColumns(
              list.map((p) => (p.columnID === data.columnID ? data : p))
            );
          }}
        />
      )}
    </>
  );
}

export default SelectDisplayName;
