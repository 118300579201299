import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import ButtonTransparent from "Components/Buttons/button-transparent";
import {
  MobileAppBoundType,
  WebAppBoundType,
  NewBoundType,
} from "Types/app-types";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import BoundAppList from "Components/Bound/BoundAppList/bound-app-list";
import BoundUnsavedChanges from "Components/Bound/BoundUnsavedChanges/bound-unsaved-changes";
import { IconType, UnsavedChangesButtonEnum } from "Common/enum";
import { stylesBoundEditor } from "Components/Bound/BoundEditor/styles";
import { IBoundEditorProps } from "Components/Bound/interfaces-bound";
import ClientAppApi from "Services/Api/client-app-api";
import { AxiosError } from "axios";
import SvgBounds from "Components/Images/image-bounds";
import ImageOSIcon from "Components/Image/ImageOSIcon/image-os-icon";
import Colors from "Common/colors";
import { Autocomplete } from "@material-ui/lab";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";

const clientAppApi = new ClientAppApi();

export default function BoundEditor(props: IBoundEditorProps) {
  const [isChanged, setIsChanged] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isUnsavedChangesDialogOpen, setIsUnsavedChangesDialogOpen] =
    React.useState(false);
  const [selectedMobileAppId, setSelectedMobileAppId] = React.useState("");
  const [newSelectedMobileAppId, setNewSelectedMobileAppId] =
    React.useState("");
  const [mobileAppList, setMobileAppList] = React.useState<
    MobileAppBoundType[]
  >([]);
  const [webAppList, setWebAppList] = React.useState<WebAppBoundType[]>([]);
  const [filteredWebAppList, setFilteredWebAppList] = React.useState<
    WebAppBoundType[]
  >([]);
  const [displayWebAppList, setDisplayWebAppList] =
    React.useState<boolean>(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );

  const customStyles = stylesBoundEditor({});

  const getBoundsMobileAppList = async () => {
    if (props.clientID) {
      await clientAppApi
        .getMobileAppBoundList(props.clientID)
        .then((result) => {
          if (result && result.data) {
            setMobileAppList(result.data);
          }
        });
    }
  };

  const getBoundsWebAppList = async () => {
    if (props.clientID) {
      await clientAppApi.getWebAppBoundList(props.clientID).then((result) => {
        if (result && result.data) {
          setWebAppList(result.data);
          setFilteredWebAppList(result.data);
        }
      });
    }
  };

  const changeSelectedMobileApp = (selectedMobileId: string): void => {
    setWebApps(Number(selectedMobileId));
    setSelectedMobileAppId(selectedMobileId);
  };

  const getMobileAppName = (): string => {
    if (selectedMobileAppId) {
      const mobileApp = mobileAppList.find(
        (x) => x.iaAppID === Number(selectedMobileAppId)
      );
      if (mobileApp) {
        return mobileApp.appName;
      }
    }
    return "";
  };

  const setWebApps = (mobileAppId: number): void => {
    const selectedMobileApp: MobileAppBoundType | undefined =
      mobileAppList.find((x) => x.iaAppID === mobileAppId);
    if (selectedMobileApp) {
      const webList: WebAppBoundType[] = webAppList.map((app) => {
        if (selectedMobileApp.boundWebAppIds.find((x) => x === app.iaAppID)) {
          app.isBound = true;
        } else {
          app.isBound = false;
        }
        return app;
      });
      setWebAppList(webList);
    }
  };

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setIsOpen(false);
  };

  const handleOpen = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (props.clientID) {
      setSelectedMobileAppId("");
      setDisplayWebAppList(false);
      setIsChanged(false);
      setIsOpen(true);
      await getBoundsWebAppList();
      await getBoundsMobileAppList();
    }
  };

  // const handleMobileAppChange = (
  //   event: React.ChangeEvent<{ value: unknown }>
  // ): void => {
  //   let mobileAppId: string = event.target.value as string;
  //   if (selectedMobileAppId !== mobileAppId) {
  //     setDisplayWebAppList(true);
  //     setNewSelectedMobileAppId(mobileAppId);
  //     if (isChanged) {
  //       setIsUnsavedChangesDialogOpen(true);
  //     } else {
  //       changeSelectedMobileApp(mobileAppId);
  //     }
  //   }
  // };

  const handleAppChange = (
    event: React.ChangeEvent<{}>,
    value: MobileAppBoundType | null
  ): void => {
    let mobileAppId: string = value?.iaAppID.toString() as string;
    if (selectedMobileAppId !== mobileAppId) {
      let boundApps = [...webAppList];
      const appItem = boundApps.find(
        (a) => a.iaAppID.toString() === mobileAppId
      );
      if (appItem) {
        const selectedIdx = boundApps.indexOf(appItem);
        if (selectedIdx >= 0) {
          boundApps.splice(selectedIdx, 1);
        }
      }
      setFilteredWebAppList(boundApps);

      setDisplayWebAppList(true);
      setNewSelectedMobileAppId(mobileAppId);
      if (isChanged) {
        setIsUnsavedChangesDialogOpen(true);
      } else {
        changeSelectedMobileApp(mobileAppId);
      }
    }
  };

  const handleWebAppToggle = (webAppId: number) => (): void => {
    let webApp: WebAppBoundType | undefined = webAppList.find(
      (x) => x.iaAppID === webAppId
    );
    if (webApp) {
      const appIndex = webAppList.indexOf(webApp);
      webApp.isBound = !webApp.isBound;
      let newWebAppList = [...webAppList];
      newWebAppList.splice(appIndex, 1, webApp);
      setWebAppList(newWebAppList);
    }
    setIsChanged(true);
  };

  const handleSave = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    let result = await save();
    if (result) {
      setIsOpen(false);
    }
  };

  const handleUnsavedChangesBtnClick = async (
    buttonType: UnsavedChangesButtonEnum
  ): Promise<void> => {
    switch (buttonType) {
      case UnsavedChangesButtonEnum.Cancel:
        setIsUnsavedChangesDialogOpen(false);
        break;
      case UnsavedChangesButtonEnum.Discard:
        setIsUnsavedChangesDialogOpen(false);
        setIsChanged(false);
        changeSelectedMobileApp(newSelectedMobileAppId);
        break;
      case UnsavedChangesButtonEnum.Save:
        setIsUnsavedChangesDialogOpen(false);
        await saveAfterChangeMobileApp();
        break;
    }
  };

  const saveAfterChangeMobileApp = async (): Promise<void> => {
    let result = await save();
    if (result) {
      setIsChanged(false);
      await getBoundsMobileAppList();
      changeSelectedMobileApp(newSelectedMobileAppId);
    }
  };

  const save = async (): Promise<boolean> => {
    if (selectedMobileAppId) {
      let bounds: NewBoundType = {
        mobileIAAppID: Number(selectedMobileAppId),
        boundWebAppIDs: webAppList
          .filter((x) => x.isBound)
          .map((x) => x.iaAppID),
      };
      return await clientAppApi
        .saveBound(bounds)
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.error(error);
          setErrorResponse(error);
          setIsErrorDialogOpen(true);
          return false;
        });
    }
    return false;
  };

  const selectedApp: MobileAppBoundType | undefined = mobileAppList.find(
    (a) => a.iaAppID.toString() === selectedMobileAppId
  );

  return (
    <React.Fragment>
      <ButtonTransparent
        text="Bounds"
        iconStartType={IconType.SvgImageIcon}
        iconStart={<SvgBounds width="20px" />}
        handleClick={handleOpen}
      />
      <Dialog
        open={isOpen}
        maxWidth={"md"}
        fullWidth
        onEscapeKeyDown={handleClose}
      >
        <DialogTitle>
          <TypographySubtitle text="Edit bounds" />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container item spacing={2}>
            <Grid item sm={6}>
              {/* <FormControl className={customStyles.formControl}>
                <InputLabel>Application</InputLabel>
                <Select
                  value={selectedMobileAppId}
                  onChange={handleMobileAppChange}
                >
                  {mobileAppList.map((app) => (
                    <MenuItem key={app.iaAppID} value={app.iaAppID}>
                      <div style={{ display: "inline-block", verticalAlign: "top", marginLeft: "5px" }}>
                        <ImageOSIcon
                          platformType={app.platformType}
                          width="20px"
                          color={Colors.graySeparate}
                          text=""
                          textColor={Colors.graySeparate}
                          labelFontSize="1px"
                        />
                      </div>
                      <div style={{ display: "inline-block", verticalAlign: "middle", marginLeft: "10px" }}>{app.appName}</div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}

              <Autocomplete
                id="applications"
                options={mobileAppList}
                getOptionLabel={(option) => option.appName}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "top",
                        marginLeft: "5px",
                      }}
                    >
                      <ImageOSIcon
                        platformType={option.platformType}
                        width="20px"
                        color={Colors.graySeparate}
                        text=""
                        textColor={Colors.graySeparate}
                        labelFontSize="1px"
                      />
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                      }}
                    >
                      {option.appName}
                    </div>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Application"
                    variant="standard"
                  />
                )}
                size="small"
                onChange={handleAppChange}
                value={selectedApp}
                disableClearable={true}
              />
            </Grid>
            <Grid item sm={6} className={customStyles.webAppList}>
              <BoundAppList
                appList={displayWebAppList ? filteredWebAppList : []}
                handleAppToggle={handleWebAppToggle}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonSubaction text="Cancel" handleClick={handleClose} />
          <ButtonAction isSubmit text="Save" handleClick={handleSave} />
        </DialogActions>
      </Dialog>
      <BoundUnsavedChanges
        isOpen={isUnsavedChangesDialogOpen}
        appName={getMobileAppName()}
        handleBtnClick={handleUnsavedChangesBtnClick}
      />
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </React.Fragment>
  );
}
