export const RouteApplications = "/applications";

export const RouteUsers = "/users";
export const RouteUser = "/user";
export const RouteUserEdit = RouteUser + "/:userID/:appGuid";

export const RouteRoles = "/roles";

export const RouteSystem = "/system";
export const RouteSystemUsers = RouteSystem + "/users";
export const RouteSystemPermissions = RouteSystem + "/permissions";
export const RouteSystemAppsPermissions = RouteSystem + "/apps-permissions";
export const RouteSystemAccessRights = RouteSystem + "/access-rights";
export const RouteSystemCustomers = RouteSystem + "/customers";
export const RouteSystemCustomerLocations = RouteSystem + "/customer-locations";
export const RouteSystemCustomerLocationsParam =
  RouteSystem + "/customer-locations/:customerId";
export const RouteSystemPermissionList = RouteSystem + "/permission-list";

export const RouteReports = "/reports";
export const RouteReportsDictionaries = RouteReports + "/dictionaries";
export const RouteReportsReports = RouteReports + "/reports";
export const RouteReportsReportsContainers =
  RouteReports + "/reports-containers";
export const RouteReportsAppsReports = RouteReports + "/apps-reports";
export const RouteReportsAccessRights = RouteReports + "/access-rights";
export const RouteReportEdit = RouteReports + "/edit-report";

export const RouteCustomerPermissions = "/customer-permissions";

export const RouteMaintenance = "/maintenance";
