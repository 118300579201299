import * as React from 'react';
import { TextField } from '@material-ui/core';
import { ChangeEvent } from 'react';

export interface SelectParamDBNameProps {
  name: string;
  handleChange: (name: string) => void;
}

const SelectParamDBName: React.FC<SelectParamDBNameProps> = ({
  handleChange,
  name,
}) => {
  const [value, setValue] = React.useState(name);
  const [wasChanged, setWasChanged] = React.useState(false);

  React.useEffect(() => {
    setValue(name);
    setWasChanged(false);
  }, [name]);

  return (
    <TextField
      variant='standard'
      label='Database name'
      margin='dense'
      fullWidth
      error={
        wasChanged && (value == null || !value || value.trim().length === 0)
      }
      InputProps={{
        style: { fontSize: '14px' },
      }}
      value={value}
      onChange={(
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => {
        setValue(event.target.value ?? '');
        setWasChanged(true);
      }}
      onBlur={(
        event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => {
        handleChange(value);
      }}
    />
  );
};

export default SelectParamDBName;
