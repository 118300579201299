import * as React from "react";
import { Grid, Paper } from "@material-ui/core";
import TypographyInfo from "Components/Typography/typography-info";
import { IAppsPermissionsListItemProps } from "Components/Permission/interfaces-permission";
import { stylesAppsPermissionsListItem } from "Components/Permission/AppsPermissions/styles";
import SvgConnected from "Components/Images/image-connected";
import SvgConnectedContainers from "Components/Images/Reports/image-connected-containers";
import AppsPermissionsEditor from "Components/Permission/AppsPermissions/apps-permissions-editor";
import {
  GetAppsWithPermissionGroupsItemResponseType,
  GetAppsWithReportContainersItemResponseType,
} from "Types/permission-types";

export default function AppsPermissionsListItem(
  props: IAppsPermissionsListItemProps
) {
  const customStyles = stylesAppsPermissionsListItem();
  const { item } = props;

  const maxPermItemsCount: number = 5;

  return (
    <tr>
      <td>
        <Paper className={customStyles.paper}>
          <Grid container alignItems="center">
            <Grid container item sm={3}>
              <Grid
                item
                xs={12}
                className={customStyles.gridItem}
                alignItems="center"
              >
                <TypographyInfo text={item.name} color="#000000" />
              </Grid>
            </Grid>
            <Grid container item sm={8} spacing={1}>
              <Grid container item sm={12} alignItems="center">
                <SvgConnected
                  className={customStyles.connectedIcon}
                  width={"16px"}
                  height={"16px"}
                  title="Assigned Permission groups"
                />
                {item.permissionGroups && item.permissionGroups.length > 0
                  ? item.permissionGroups.map(
                      (
                        perm: GetAppsWithPermissionGroupsItemResponseType,
                        index: number
                      ) => {
                        return index >= maxPermItemsCount ? (
                          ""
                        ) : (
                          <TypographyInfo
                            key={perm.permissionGroupID}
                            text={perm.name}
                            color="#000000"
                            style={{ marginRight: "15px", fontSize: "12px" }}
                          />
                        );
                      }
                    )
                  : ""}
                {item.permissionGroups &&
                item.permissionGroups.length > maxPermItemsCount ? (
                  <TypographyInfo
                    text={
                      "And " +
                      (item.permissionGroups.length - maxPermItemsCount) +
                      " more..."
                    }
                    color="#000000"
                    style={{ marginRight: "15px", fontSize: "12px" }}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid container item sm={12} alignItems="center">
                <SvgConnectedContainers
                  className={customStyles.connectedIcon}
                  width={"16px"}
                  height={"15px"}
                  title="Assigned Report containers"
                />
                {item.reportContainers && item.reportContainers.length > 0
                  ? item.reportContainers.map(
                      (
                        perm: GetAppsWithReportContainersItemResponseType,
                        index: number
                      ) => {
                        return index >= maxPermItemsCount ? (
                          ""
                        ) : (
                          <TypographyInfo
                            key={perm.reportContainerID}
                            text={perm.reportContainerName}
                            color="#000000"
                            style={{ marginRight: "15px", fontSize: "12px" }}
                          />
                        );
                      }
                    )
                  : ""}
                {item.reportContainers &&
                item.reportContainers.length > maxPermItemsCount ? (
                  <TypographyInfo
                    text={
                      "And " +
                      (item.reportContainers.length - maxPermItemsCount) +
                      " more..."
                    }
                    color="#000000"
                    style={{ marginRight: "15px", fontSize: "12px" }}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid container item sm={1}>
              <Grid item sm={12} alignItems="center" justify="flex-end">
                <AppsPermissionsEditor
                  app={item}
                  getAppsWithPermissions={props.getAppsWithPermissions}
                  defaultTab={props.defaultTab}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </td>
    </tr>
  );
}
