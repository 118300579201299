import * as React from "react";
import ClientList from "Components/Client/ClientList/client-list";
import ClientEditor from "Components/Client/ClientEditor/client-editor";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { FormEditorEnum } from "Common/enum";
import { IClientSwitcherProps } from "../interfaces-client";
import UserApi from "Services/Api/user-api";
import AuthorizationHelper, {
  Tokens,
} from "Configuration/authorization-helper";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import SearchField from "Components/Inputs/SearchField/search-field";

const userApi = new UserApi();

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    width: 250,
  },
  dividers: {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: theme.spacing(0),
    height: 460,
  },
  dialogPaper: {
    width: 450,
    padding: "0 20px",
  },
  dialogActions: {
    justifyContent: "space-between",
  },
}));

export default function ClientSwitcher(props: IClientSwitcherProps) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSelectClient = (clientGuid: string) => {
    props.handleClose();
    props.handlerClientChange(clientGuid);
    setIsOpen(false);
  };

  const handleLogout = () => {
    const tokens: Tokens | null = AuthorizationHelper.getToken();
    if (tokens !== null) {
      userApi
        .logout({ tokenAuth: tokens.auth, tokenRefresh: tokens.refresh })
        .then((response) => {
          if (response != null && response.data.isLogout) {
            AuthorizationHelper.logOut();
            // eslint-disable-next-line no-restricted-globals
            history.pushState(history.state, "logout", "/");
            // eslint-disable-next-line no-restricted-globals
            history.go();
          }
        });
    }
  };

  const loggedUser = AuthorizationHelper.getUser();

  return (
    <div>
      <div style={{ padding: 20 }}>
        <Grid
          container
          item
          direction="column"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <ButtonSubaction text="Change context" handleClick={handleOpen} />
          </Grid>
          <Grid container item direction="column" spacing={1}>
            {loggedUser !== null && loggedUser !== undefined ? (
              <Grid item>
                <span>Logged as:</span>
                {loggedUser.firstName !== undefined
                  ? " " + loggedUser.firstName
                  : null}
                {loggedUser.lastName !== undefined
                  ? " " + loggedUser.lastName
                  : null}
              </Grid>
            ) : null}
            <Grid item>
              <ButtonSubaction text="Logout" handleClick={handleLogout} />
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>
          <TypographySubtitle text="Change context" />
        </DialogTitle>
        <DialogContent dividers className={classes.dividers}>
          <SearchField onChangeEvent={setSearchValue} />
          <ClientList
            searchValue={searchValue}
            clientClick={handleSelectClient}
            clientList={props.clientList}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <ClientEditor
            editorType={FormEditorEnum.Create}
            handleSaveClient={props.handleSaveClient}
            checkGuidUniqueness={props.checkGuidUniqueness}
          />
          <ButtonSubaction text="Cancel" handleClick={handleClose} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
