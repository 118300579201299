import * as React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { IAppEditorComponentNameProps } from "./interfaces";
import ImageOSIcon from "Components/Image/ImageOSIcon/image-os-icon";
import Colors from "Common/colors";

const AppEditorComponentName: (
  props: IAppEditorComponentNameProps
) => JSX.Element = (props: IAppEditorComponentNameProps): JSX.Element => {
  const NAME_MAX_LENGTH: number = 100;

  return (
    <TextField
      name="name"
      label="Name"
      size="small"
      value={props.value}
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      error={props.error}
      helperText={props.errorText}
      InputProps={{
        startAdornment: props.showAdornment && (
          <InputAdornment position="start">
            <ImageOSIcon
              platformType={props.platformType}
              width="20px"
              color={Colors.grayDark}
            />
          </InputAdornment>
        ),
      }}
      inputProps={{
        maxLength: NAME_MAX_LENGTH,
      }}
      fullWidth
      required
    />
  );
};

export default AppEditorComponentName;
