import * as React from "react";

const SvgConnected = ({
    style = {},
    fill = "none",
    color = "black",
    width = "100%",
    height = "100%",
    className = "",
    viewBox = "0 0 16 16",
    title = ""
  }) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle cx="3" cy="3" r="2.5" stroke={color} />
      <circle cx="13" cy="8" r="2.5" stroke={color} />
      <circle cx="3" cy="13" r="2.5" stroke={color} />
      <path d="M5 5L8 8L5 11" stroke={color} />
      <path d="M8 8H10.5" stroke={color} />
      <title>{title}</title>
    </svg>
);

export default SvgConnected;