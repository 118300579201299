import * as React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { MaintenanceListFilterProps } from "../interfaces";
import { AppTypeEnum, MaintenanceActivityTypeEnum } from "Common/enum";
import SearchField from "Components/Inputs/SearchField/search-field";
import FilterCard from "Components/FilterCard/filter-card";

const MaintenanceListFilter: (
  props: MaintenanceListFilterProps
) => JSX.Element = (props: MaintenanceListFilterProps): JSX.Element => {
  return (
    <React.Fragment>
      <FilterCard>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-end"
          spacing={4}
        >
          <Grid item sm={2}>
            <SearchField
              text={props.searchText}
              onChangeEvent={props.handleSearchChange}
            />
          </Grid>
          <Grid item sm={2}>
            <FormControl>
              <InputLabel>Application type</InputLabel>
              <Select
                id="app-type-select"
                value={props.appType}
                onChange={(e, v) => {
                  props.handleAppTypeChange(e.target.value as AppTypeEnum);
                }}
              >
                <MenuItem key={AppTypeEnum.All} value={AppTypeEnum.All}>
                  All
                </MenuItem>
                <MenuItem key={AppTypeEnum.WebApp} value={AppTypeEnum.WebApp}>
                  Web apps
                </MenuItem>
                <MenuItem
                  key={AppTypeEnum.MobileApp}
                  value={AppTypeEnum.MobileApp}
                >
                  Mobile apps
                </MenuItem>
                <MenuItem
                  key={AppTypeEnum.WebService}
                  value={AppTypeEnum.WebService}
                >
                  Web services
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item sm={2}>
            <FormControl>
              <InputLabel>Maintenance status</InputLabel>
              <Select
                id="maintenance-activity"
                value={props.maintenanceActivityType}
                onChange={(e, v) => {
                  props.handleActivityTypeChange(
                    e.target.value as MaintenanceActivityTypeEnum
                  );
                }}
              >
                <MenuItem value={MaintenanceActivityTypeEnum.All}>All</MenuItem>
                <MenuItem value={MaintenanceActivityTypeEnum.Finished}>
                  Finished
                </MenuItem>
                <MenuItem value={MaintenanceActivityTypeEnum.Unfinished}>
                  Unfinished
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </FilterCard>
    </React.Fragment>
  );
};

export default MaintenanceListFilter;
