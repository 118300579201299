import * as React from 'react';
import ButtonIcon from 'Components/Buttons/button-icon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TypographyInfo from 'Components/Typography/typography-info';
import { IconType } from 'Common/enum';
import { ICustomerListItemProps } from 'Components/Customers/interfaces-customers';
import { Menu, MenuItem } from '@material-ui/core';
import { RouteSystemCustomerLocations } from 'Configuration/Routes';
import { stylesCustomerListItem } from 'Components/Customers/styles';
import { useHistory } from 'react-router';

export default function CustomerListItem(props: ICustomerListItemProps) {
  const customStyles = stylesCustomerListItem();
  const history = useHistory();
  const { item } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <tr>
      <td className={customStyles.cell}>
        <TypographyInfo text={item.name} fontSize="14px" margin="0" />
      </td>
      <td className={customStyles.cell}>
        <TypographyInfo text={item.guid} fontSize="14px" margin="0" />
      </td>
      <td className={customStyles.cell}>
        <TypographyInfo text={item.pinLength} fontSize="14px" margin="0" />
      </td>
      <td className={customStyles.cell}>
        <TypographyInfo
          text={item.locations.length}
          fontSize="14px"
          margin="0"
        />
      </td>
      <td className={`${customStyles.cell} ${customStyles.cellActions}`}>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          keepMounted
        >
          <MenuItem
            onClick={() => {
              history.push(
                `${RouteSystemCustomerLocations}/${item.customerID}`
              );
            }}
          >
            Show locations
          </MenuItem>
        </Menu>
        <ButtonIcon
          iconType={IconType.SvgImageIcon}
          icon={<MoreVertIcon />}
          handleClick={handleMenuOpen}
        />
      </td>
    </tr>
  );
}
