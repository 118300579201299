import * as React from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  ListItemText,
  Select,
  MenuItem,
  Divider,
} from "@material-ui/core";
import InputDateRange from "Components/Inputs/input-date-range";
import { IDeviceAppFiltersProps } from "Components/Device/interfaces-device";
import InputCheckbox from "Components/Inputs/input-checkbox";
import TypographyInfo from "Components/Typography/typography-info";
import { DeviceListSortByEnum as DeviceListSortTypeEnum } from "Common/enum";
import { stylesDeviceListFilters } from "Components/Device/DeviceList/styles";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import SearchField from "Components/Inputs/SearchField/search-field";
import FilterCard from "Components/FilterCard/filter-card";

export default function DeviceListFilters(props: IDeviceAppFiltersProps) {
  const customStyles = stylesDeviceListFilters({});

  return (
    <FilterCard>
      <Grid container>
        <Grid container item sm={10} spacing={2}>
          <Grid item sm={2}>
            <SearchField onChangeEvent={props.onSearchChange} />
          </Grid>
          <Grid item sm={2}>
            <TextFieldEntered
              label="Release number"
              type="number"
              value={props.releaseNumber}
              onChange={(e) => props.onReleaseNumberChange(e.target.value)}
              inputProps={{ min: "1" }}
            />
          </Grid>
          <Grid item sm={2}>
            <FormControl>
              <InputLabel>Software version</InputLabel>
              <Select
                multiple
                value={props.selectedSoftwareVersions}
                onChange={props.onSelectedSoftwareVersionsChange}
                renderValue={(selected) =>
                  props.selectAll ? "All" : (selected as string[]).join(", ")
                }
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              >
                <MenuItem value="All">
                  <InputCheckbox checked={props.selectAll} />
                  <ListItemText
                    primary={<TypographyInfo text="Select all" />}
                  />
                </MenuItem>
                <Divider variant="middle" />
                {props.softwareVersions.map((version) => (
                  <MenuItem key={version} value={version}>
                    <InputCheckbox
                      checked={
                        props.selectedSoftwareVersions.indexOf(version) > -1
                      }
                    />
                    <ListItemText primary={<TypographyInfo text={version} />} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item sm={5} spacing={2}>
            <InputDateRange
              dateFrom={props.dateFrom}
              dateTo={props.dateTo}
              dateFromLabel="Contact date from"
              dateToLabel="Contact date to"
              onDateFromChange={props.onDateFromChange}
              onDateToChange={props.onDateToChange}
            />
          </Grid>
        </Grid>
        <Grid item sm={2} spacing={2}>
          <FormControl className={customStyles.sortBy}>
            <InputLabel>Sort by</InputLabel>
            <Select value={props.sortBy} onChange={props.onSortChange}>
              {Object.keys(DeviceListSortTypeEnum)
                .filter((e) => !isNaN(+e))
                .map((sortType) => (
                  <MenuItem key={sortType} value={sortType}>
                    {DeviceListSortTypeEnum[+sortType]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </FilterCard>
  );
}
