import { makeStyles } from "@material-ui/core";

export const stylesWebAppMainListItem = makeStyles({
    root: {
      margin: 0,
      lineHeight: 1
    },
    textRight: {
      textAlign: 'right'
    }
});