import * as React from 'react';
import { TextField } from '@material-ui/core';
import { ChangeEvent } from 'react';

export interface SelectParamDefaultProps {
  text: string;
  handleChange: (text: string) => void;
}

const SelectParamDefault: React.FC<SelectParamDefaultProps> = ({
  handleChange,
  text,
}) => {
  const [value, setValue] = React.useState(text);

  React.useEffect(() => {
    setValue(text);
  }, [text]);

  return (
    <TextField
      fullWidth
      variant='standard'
      label='Default'
      margin='dense'
      InputProps={{
        style: { fontSize: '14px' },
      }}
      value={value}
      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        setValue(event.target.value ?? '')
      }
      onBlur={(
        event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => {
        handleChange(value);
      }}
    />
  );
};

export default SelectParamDefault;
