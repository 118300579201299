import * as React from "react";
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import { stylesPermissionsGroupListItem } from "./styles";
import SvgExpandMenu from "Components/Images/Menu/image-expand-menu";
import TypographyInfo from "Components/Typography/typography-info";
import Colors from "Common/colors";
import SvgHeap from "Components/Images/image-heap";
import { IPermissionsGroupListItemProps } from "../interfaces-permission";
import PermissionsGroupEditor from "./permissions-group-editor";
import { Fragment } from "react";
import ButtonEdit from "Components/Buttons/ButtonEdit/button-edit";
import ButtonDelete from "Components/Buttons/ButtonDelete/button-delete";
import { DeletePermissionGroupApiResultType } from "Types/api-types";
import { ApiResultStatusEnum } from "Common/enum";
import { DeletePermissionGroupModelType } from "Types/permission-types";
import PermissionApi from "Services/Api/permission-api";
import { AxiosError } from "axios";
import { IConfirmDialogProps } from "Components/Dialogs/interfaces";
import ConfirmDialog from "Components/Dialogs/ConfirmDialog/confirm-dialog";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";

const permissionApi = new PermissionApi();

const PermissionsGroupListItem: React.FC<IPermissionsGroupListItemProps> = ({
  data,
  getPermissionGroups,
}) => {
  const { name, permissions, applications, permissionGroupID } = data;
  const customStyles = stylesPermissionsGroupListItem();

  const [isOpenEditor, setIsOpenEditor] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);

  const [confirmDialogSettings, setConfirmDialogSettings] =
    React.useState<IConfirmDialogProps>({
      isOpen: false,
      text: "",
      handleCancelClick: () => {},
      handleOkClick: () => {},
    });

  const openEditor = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setIsOpenEditor(true);
  };

  const closeEditor = (): void => {
    setIsOpenEditor(false);
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setConfirmDialogSettings({
      isOpen: true,
      text: `Delete permission group ${name}?`,
      handleOkClick: () => {
        confirmDelete(false);
      },
      handleCancelClick: () => {
        setConfirmDialogSettings({ ...confirmDialogSettings, isOpen: false });
      },
    });
  };

  const confirmDelete = (forceDelete: boolean) => {
    deletePermissionGroup(forceDelete)
      .then(() => {})
      .catch((error) => {
        if (
          error.response.status === 406 &&
          error.response.data.status ===
            ApiResultStatusEnum.PermissionsAddedToGroup
        ) {
          setConfirmDialogSettings({
            isOpen: true,
            text: `${error.response.data.statusMessage}<br/> Delete permission group ${name}?`,
            handleCancelClick: () => {
              setConfirmDialogSettings({
                ...confirmDialogSettings,
                isOpen: false,
              });
            },
            handleOkClick: () => {
              confirmDelete(true);
            },
          });
        } else {
          setErrorResponse(error);
          setIsErrorDialogOpen(true);
        }
      });
  };

  const deletePermissionGroup = async (
    forceDelete: boolean
  ): Promise<DeletePermissionGroupApiResultType | void> => {
    const request: DeletePermissionGroupModelType = {
      permissionGroupID,
      forceDelete,
    };

    const result = await permissionApi.deletePermissionGroup(request);
    if (result && result.status === ApiResultStatusEnum.Ok) {
      setConfirmDialogSettings({
        ...confirmDialogSettings,
        isOpen: false,
      });
      if (getPermissionGroups) {
        getPermissionGroups();
      }
    } else {
      setConfirmDialogSettings({
        ...confirmDialogSettings,
        isOpen: false,
      });
    }
  };

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  return (
    <Fragment>
      <Grid container alignItems="center">
        <ExpansionPanel classes={{ root: customStyles.expansionPanel }}>
          <ExpansionPanelSummary
            aria-controls={`panel-content-${permissionGroupID}`}
            id={`panel-header-${permissionGroupID}`}
            expandIcon={SvgExpandMenu}
            classes={{ root: customStyles.expansionPanelSummary }}
          >
            <Grid container>
              <Grid
                container
                item
                xs={3}
                className={customStyles.permissionGroupNameContainer}
              >
                <TypographyInfo
                  text={name}
                  color={Colors.black}
                  style={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "27px",
                  }}
                />
              </Grid>
              <Grid
                container
                item
                xs={8}
                style={{ paddingLeft: "34px" }}
                alignItems="center"
              >
                <SvgHeap />
                {applications.map((app) => (
                  <TypographyInfo
                    text={app}
                    margin={"5px 10px"}
                    style={{ fontSize: "14px", lineHeight: "21px" }}
                    key={app}
                  />
                ))}
              </Grid>

              <Grid
                container
                item
                xs={1}
                justify="flex-end"
                alignItems="center"
                className={customStyles.editButtonContainer}
              >
                <ButtonEdit iconWidth="20px" handleClick={openEditor} />
                <ButtonDelete iconWidth="15px" handleClick={handleDelete} />
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ root: customStyles.expansionPanelDetails }}
          >
            <Grid container wrap="wrap">
              {permissions.map((p) => (
                <Grid
                  container
                  item
                  xs={4}
                  alignItems="center"
                  className={customStyles.permissionContainer}
                  key={p.permissionID}
                >
                  <TypographyInfo
                    text={p.name}
                    style={{ fontSize: "14px", lineHeight: "21px" }}
                  />
                </Grid>
              ))}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
      <PermissionsGroupEditor
        permissionGroup={data}
        getPermissionGroups={getPermissionGroups}
        isOpen={isOpenEditor}
        closeEditor={closeEditor}
      />
      <ConfirmDialog
        text={confirmDialogSettings.text}
        isOpen={confirmDialogSettings.isOpen}
        handleOkClick={confirmDialogSettings.handleOkClick}
        handleCancelClick={confirmDialogSettings.handleCancelClick}
      />
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </Fragment>
  );
};

export default PermissionsGroupListItem;
