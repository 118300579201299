import * as React from 'react';
import InputSearch from 'Components/Inputs/input-search';
import { Grid } from '@material-ui/core';

export interface ICustomerLocationsFiltersProps {
  onSearchChange: (value: React.SetStateAction<string>) => void;
}

export default function CustomerLocationsFilters(
  props: ICustomerLocationsFiltersProps
) {
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
      spacing={4}
    >
      <Grid item>
        <InputSearch onChangeEvent={props.onSearchChange} />
      </Grid>
    </Grid>
  );
}
