import * as React from "react";
import SvgArrowRight from "Components/Images/image-arrow-right";
import { FunctionComponent } from "react";
import ActionButton from "./action-button";

interface ActionButtonMoveProps {
  checkedAllLength: number;
  moveToSelected: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}
const ActionButtonMove: FunctionComponent<ActionButtonMoveProps> = ({
  checkedAllLength,
  moveToSelected,
}) => {
  return (
    <ActionButton
      count={checkedAllLength}
      handleClick={moveToSelected}
      alignItems="flex-end"
      text="Move"
      iconStart={SvgArrowRight}
    />
  );
};
export default ActionButtonMove;
