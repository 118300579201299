import * as React from "react";
import { IAccessRightListProps } from "Components/AccessRight/interfaces-access-right";
import MaterialTable from "material-table";
import { Grid } from "@material-ui/core";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { stylesAccessRightList } from "Components/AccessRight/AccessRightList/styles";
import AccessRightListItem from "./access-right-list-item";
import { SetAllValuesEnum } from "Common/enum";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";

const AccessRightList: (props: IAccessRightListProps) => JSX.Element = (
  props: IAccessRightListProps
): JSX.Element => {
  const customStyles = stylesAccessRightList();

  return (
    <React.Fragment>
      <Grid container alignItems="center">
        <Grid container item alignItems="center">
          <Grid container item xs={9}>
            <Grid item className={customStyles.gridItemGroupName}>
              <TypographySubtitle
                text={props.permissionGroups.permissionGroupName}
              />
            </Grid>
          </Grid>
          <Grid container item xs={3} alignItems="center" justify="flex-end">
            <Grid item className={customStyles.gridItem}>
              <ButtonSubaction
                margin="0px 5px"
                text="Off"
                backgroundColor={"#DCDCDC"}
                handleClick={() =>
                  props.handleAllValuesChange(
                    props.permissionGroups.permissionGroupID,
                    SetAllValuesEnum.MinValues
                  )
                }
              />
            </Grid>
            <Grid item className={customStyles.gridItem}>
              <ButtonSubaction
                margin="0px 5px"
                text="On"
                handleClick={() =>
                  props.handleAllValuesChange(
                    props.permissionGroups.permissionGroupID,
                    SetAllValuesEnum.MaxValues
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item alignItems="center">
          <Grid item xs={12}>
            <MaterialTable
              columns={[]}
              data={props.permissionGroups.permissions}
              options={{
                showTitle: false,
                header: false,
                paging: false,
                search: false,
                toolbar: false,
              }}
              components={{
                Row: (tableProps) => (
                  <AccessRightListItem
                    permission={tableProps.data}
                    handlePermissionValueChange={
                      props.handlePermissionValueChange
                    }
                    defaultTab={props.defaultTab}
                  />
                ),
              }}
              style={{
                background: "transparent",
                boxShadow: "none",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AccessRightList;
