import * as React from "react";
import { ExportResponse, ExportStructureProps } from "../interfaces";
import { axiosInstance } from "Services/Api/base-api";
import { IconType } from "Common/enum";
import ButtonTransparent from "Components/Buttons/button-transparent";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import InfoDialog from "Components/Dialogs/InfoDialog/info-dialog";
import SvgExport from "Components/Images/image-export";
import moment from "moment";

let envBaseURL: string = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : "https://intelliadmin-webapi-core-test.azurewebsites.net/api/";
let envApiVersion: string = process.env.REACT_APP_API_VERSION
  ? process.env.REACT_APP_API_VERSION
  : "v1";

const ExportStructure: (props: ExportStructureProps) => JSX.Element = (
  props: ExportStructureProps
): JSX.Element => {
  const [dialogInfoIsOpen, setDialogInfoIsOpen] = React.useState<boolean>(
    false
  );
  const [dialogErrorIsOpen, setDialogErrorIsOpen] = React.useState<boolean>(
    false
  );

  const handleClick: () => void = (): void => {
    if (props.clientID) {
      axiosInstance({
        url: `${envBaseURL}${envApiVersion}/Export/XlsxExportAppStructure?clientID=${props.clientID}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response: ExportResponse) => {
          switch (response.status) {
            case 200:
              let fileDownload = require("js-file-download");
              fileDownload(
                response.data,
                `iAdmin_export_${moment().format("YYYYMMDDHHmm")}.xlsx`
              );
              break;
            case 204:
              setDialogInfoIsOpen(true);
              break;
            default:
              setDialogErrorIsOpen(true);
              break;
          }
        })
        .catch(() => {
          setDialogErrorIsOpen(true);
        });
    }
  };

  return (
    <React.Fragment>
      <ButtonTransparent
        text="Export"
        iconStartType={IconType.SvgImageIcon}
        iconStart={<SvgExport width="22px" height="22px" color="#000000" />}
        handleClick={handleClick}
      />
      <InfoDialog
        isOpen={dialogInfoIsOpen}
        text={"Nothing to export"}
        handleOkClick={() => setDialogInfoIsOpen(false)}
      />
      <ErrorDialog
        isOpen={dialogErrorIsOpen}
        error={"Something went wrong. Please, try again later."}
        handleOkClick={() => setDialogErrorIsOpen(false)}
      />
    </React.Fragment>
  );
};

export default ExportStructure;
