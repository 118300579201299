import moment from "moment";
import { WebAppWithVersions } from "Types/app-types";
import { IAppVersionEditorProps } from "./interfaces-app-version-editor";
import * as Yup from "yup";

export const initValues = (
  props: IAppVersionEditorProps,
  versionList: WebAppWithVersions[] | undefined
) => {
  return {
    iaAppID: props.appId,
    file: undefined,
    versionList: versionList,
    ...(props.mobileAppVersion
      ? {
          versionNo: props.mobileAppVersion.versionNo,
          releaseDate: props.mobileAppVersion.releaseDate,
          releaseNotes: props.mobileAppVersion.releaseNotes ?? "",
          availableToInstall: props.mobileAppVersion.availableToInstall,
          isForcedUpdate: props.mobileAppVersion.isForcedUpdate,
        }
      : {
          versionNo: "",
          releaseDate: moment().format(),
          releaseNotes: "",
          availableToInstall: false,
          isForcedUpdate: false,
        }),
  };
};

export const validationShape = {
  versionNo: Yup.number()
    .label("Release number")
    .required("Required")
    .integer("Only digits are allowed")
    .positive("Please provide positive number"),
  releaseDate: Yup.string()
    .label("Release date")
    .required("Required")
    .nullable(),
  releaseNotes: Yup.string(),
  file: Yup.mixed(),
};
