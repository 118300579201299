import * as React from "react";
import Colors from "Common/colors";
import { makeStyles } from "@material-ui/core";
import { ITypographyTitleProps } from "./interfaces-typography";
import Truncate from "react-truncate";

export default function TypographyTitle(props: ITypographyTitleProps) {
  let styles = makeStyles({
    text: {
      color: props.color ? props.color : Colors.black,
      fontSize: props.fontSize ?? "32px",
      margin: props.margin ? props.margin : "10px 0",
      textTransform: props.isUppercase ? "uppercase" : "none",
    },
  });

  let customStyles = styles({});

  return (
    <p className={customStyles.text} style={props.style ?? {}}>
      {props.useTruncate ? (
        <Truncate lines={1}>
          <span style={{ wordBreak: "break-all" }}>{props.text}</span>
        </Truncate>
      ) : (
        props.text
      )}
    </p>
  );
}
