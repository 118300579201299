import { RoleModel } from './models';

export const onSearchChange = (
  value: React.SetStateAction<string>,
  list: RoleModel[],
  setFilterd: (list: RoleModel[]) => void
): void => {
  const searchedText: string = value.toString().toLowerCase() ?? '';
  search(searchedText, list, setFilterd);
};

const search = (
  searchedText: string,
  list: RoleModel[],
  setFilterd: (list: RoleModel[]) => void
): void => {
  const filteredRoles: RoleModel[] = searchByText(list, searchedText);
  setFilterd(unselectAllListElements(filteredRoles));
};

export const unselectAllListElements = (list: RoleModel[]): RoleModel[] =>
  list.map((r) => ({ ...r, isSelected: false }));

export const searchByText = (list: RoleModel[], text?: string): RoleModel[] => {
  if (text) {
    list = list.filter((x) =>
      x.data.name.toLowerCase().includes(text.toLowerCase())
    );
  }
  return list;
};
