import * as React from "react";
import MobileAppMainListItem from "./mobile-app-main-list-item";
import { IMobileAppMainListProps } from "../interfaces-mobile-app";
import { List, ListItem, Divider } from "@material-ui/core";
import { stylesMobileAppMainList } from "./styles";

export default function MobileAppMainList(props: IMobileAppMainListProps) {
  let customStyles = stylesMobileAppMainList({});

  return (
    <List disablePadding>
      {props.versionList.map((version) => {
        return (
          <React.Fragment key={version.id}>
            <ListItem classes={{ gutters: customStyles.listItem }}>
              <MobileAppMainListItem version={version} />
            </ListItem>
            <Divider variant="fullWidth" />
          </React.Fragment>
        );
      })}
    </List>
  );
}
