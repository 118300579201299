import * as React from "react";

const SvgEmail = ({
    style = {},
    fill = "none",
    width = "14px",
    className = "",
    viewBox = "0 0 14 14"
}) => (
        <svg
            width={width}
            style={style}
            height={width}
            viewBox={viewBox}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M2 10C3.01915 11.7934 4.92401 13 7.10572 13C10.361 13 13 10.3137 13 7C13 3.68629 10.361 1 7.10573 1C4.92401 1 3.01915 2.2066 2 4" stroke="black" />
            <path d="M0 7L8 7" stroke="black" />
            <path d="M6 5L8 7L6 9" stroke="black" />
        </svg>

    );

export default SvgEmail;