import * as React from "react";
import { Grid, Paper } from "@material-ui/core";
import {
  faDesktop,
  faFile,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { IWebAppInstanceDeploymentListItemProps } from "../interfaces";
import { stylesWebAppInstanceDeploymentListItem } from "../styles";
import TypographyInfo from "Components/Typography/typography-info";
import TypographySubinfo from "Components/Typography/typography-subinfo";
import moment from "moment";
import WebAppReleaseEditor from "Components/WebApp/WebAppReleaseEditor/web-app-release-editor";
import { AppPlatformEnum, FormEditorEnum } from "Common/enum";

const WebAppInstanceDeploymentListItem: (
  props: IWebAppInstanceDeploymentListItemProps
) => JSX.Element = (
  props: IWebAppInstanceDeploymentListItemProps
): JSX.Element => {
  const customStyles = stylesWebAppInstanceDeploymentListItem();
  const { item } = props;

  return (
    <tr>
      <td>
        <Paper className={customStyles.paper}>
          <Grid container alignItems="center">
            <Grid container item xs={2}>
              <Grid item xs={12} className={customStyles.gridItem}>
                <TypographyInfo text={`Release ${item.releaseNo}`} />
              </Grid>
            </Grid>
            <Grid container item xs={10}>
              <Grid item xs={2}>
                <TypographySubinfo
                  text={
                    item.releaseDate
                      ? moment(item.releaseDate).format("DD/MM/YYYY")
                      : ""
                  }
                  iconStart={faCalendar}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container={true} spacing={0}>
                  <Grid item={true} xs={1}>
                    <TypographySubinfo iconStart={faDesktop} text="" />
                  </Grid>
                  <Grid item={true} xs={11}>
                    <TypographySubinfo
                      text={`Api version: ${
                        item.apiVersionNo ? item.apiVersionNo : "-"
                      }`}
                      showMore={true}
                    />
                    <TypographySubinfo
                      text={`Api url suffix: ${
                        item.apiUrlSuffix ? item.apiUrlSuffix : "-"
                      }`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid container={true} spacing={0}>
                  <Grid item={true} xs={1}>
                    <TypographySubinfo iconStart={faFile} text="" />
                  </Grid>
                  <Grid item={true} xs={11}>
                    <TypographySubinfo
                      text={item.releaseNotes ? item.releaseNotes : "-"}
                      showMore={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <WebAppReleaseEditor
                  editorType={FormEditorEnum.Edit}
                  webAppInstanceId={item.iaWebAppInstanceID}
                  webAppInstanceDeploymentId={item.id}
                  getWebAppInstanceDeploymentList={
                    props.getInstanceDeploymentList
                  }
                  platformType={AppPlatformEnum.WebService}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </td>
    </tr>
  );
};

export default WebAppInstanceDeploymentListItem;
