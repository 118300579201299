import * as React from "react";
import { Container } from "@material-ui/core";
import { ISystemUsersProps } from "Components/User/interfaces-user";
import UserListContainer from "Components/User/UserList/user-list-container";

export default function UsersList(props: ISystemUsersProps) {
  return (
    <Container>
      <UserListContainer
        userList={props.users ? props.users : []}
        systemAppSelected={props.systemAppSelected}
        appGuid={props.appGuid}
        getUsers={props.getUsers}
        appID={props.appID}
        roleList={props.roleList}
      />
    </Container>
  );
}
