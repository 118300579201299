import * as React from "react";

const SvgCopyReport = ({
    style = {},
    fill = "none",
    color = "#444444",
    width = "43",
    height = "59",
    className = "",
    viewBox = "0 0 43 59"
  }) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M22 0.499999C29.4558 0.5 35.5 6.54416 35.5 14L35.5 47C35.5 49.4853 33.4853 51.5 31 51.5L5 51.5C2.51471 51.5 0.499998 49.4853 0.499998 47L0.5 5C0.5 2.51472 2.51472 0.499999 5 0.499999L22 0.499999Z" stroke="#444444"/>
        <path d="M37 6V6C39.7614 6 42 8.23858 42 11L42 53C42 55.7614 39.7614 58 37 58L10 58C7.79086 58 6 56.2091 6 54V54" stroke={color} />
        <path d="M36 14L27 14C24.2386 14 22 11.7614 22 9L22 -6.11959e-07" stroke={color} />
        <path d="M7 25H29" stroke={color} />
        <path d="M7 33H29" stroke={color} />
        <path d="M7 41H29" stroke={color} />
    </svg>
);

export default SvgCopyReport;