import { makeStyles, Theme } from '@material-ui/core';

export const stylesTable = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiPaper-root": {
      background: "transparent",
      boxShadow: "none",
    },
    "& .MuiTable-root": {
      background: "#FFF",
      border: "1px solid #DCDCDC",
    },
    "& .MTableHeader-header-28": {
      background: "transparent",
      color: "#AEAEAE",
    },
    "& .MuiTableCell-root": {
      fontSize: "14px",
      border: "1px solid #DCDCDC",
      padding: "8px",
    },
  },
}));
