import * as React from "react";
import { Fab, makeStyles } from "@material-ui/core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconType } from "Common/enum";
import { IButtonIconProps } from "./interfaces";

export default function ButtonIcon(props: IButtonIconProps) {
  let styles = makeStyles({
    fabIcon: {
      background: "transparent",
      border: props.borderColor ? "1px solid " + props.borderColor : "none",
      fontSize: "1rem",
      width: props.width ? props.width : "36px",
      height: props.height ? props.height : "36px",
      minHeight: props.height ? props.height : "36px",
      boxShadow: "none",
      overflow: "hidden",
      padding: "2px",
      visibility: props.isHidden ? "hidden" : "visible",
    },
    buttonIcon: {
      background: "transparent",
      fontSize: "1rem",
      boxShadow: "none",
    },
  });

  const customStyles = styles({});
  let icon;
  switch (props.iconType) {
    case IconType.FontAwesomeIcon:
      icon = <FontAwesomeIcon icon={props.icon as IconDefinition} />;
      break;
    case IconType.SvgImageIcon:
      icon = props.icon;
      break;
  }

  return (
    <Fab
      className={customStyles.fabIcon}
      onClick={props.handleClick}
      title={props.title}
    >
      {icon ?? ""}
    </Fab>
  );
}
