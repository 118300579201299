import { makeStyles } from "@material-ui/core";

export const stylesWebAppHeader = makeStyles({
  devicesButton: {
    textTransform: "none",
  },
  editButton: {
    marginLeft: "10px",
  },
  gridItem: {
    marginRight: "15px",
  },
});
