import { makeStyles } from "@material-ui/core";
import Colors from "Common/colors";

export const stylesWebAppInstanceHeader: () => Record<string, string> =
  makeStyles({
    devicesButton: {
      textTransform: "none",
    },
    editButton: {
      marginLeft: "10px",
    },
    gridItem: {
      marginRight: "15px",
    },
  });

export const stylesWebAppInstanceDeploymentListItem = makeStyles({
  gridItem: {
    borderRight: `2px solid ${Colors.gray}`,
    marginRight: "40px",
  },
  paper: {
    padding: "20px 30px",
    marginTop: "15px",
  },
});
