import * as React from "react";
import MaterialTable from "material-table";
import DirectoryListItem from "Components/Reports/Dictionary/DictionaryList/dictionary-list-item";
import { IDictionaryListProps } from "Components/Reports/interfaces-reports";
import SearchField from "Components/Inputs/SearchField/search-field";
import { Grid } from "@material-ui/core";
import FilterCard from "Components/FilterCard/filter-card";

export default function DictionaryList(props: IDictionaryListProps) {
  return (
    <>
      <FilterCard>
        <Grid container>
          <Grid item sm={2}>
            <SearchField onChangeEvent={props.handleSearchValueChange} />
          </Grid>
        </Grid>
      </FilterCard>

      <MaterialTable
        columns={[]}
        data={props.dictionaryEditorList}
        options={{
          showTitle: false,
          header: false,
          paging: false,
          search: false,
          toolbar: false,
        }}
        components={{
          Row: (propsRow) => (
            <DirectoryListItem
              client={props.client}
              dictionaryEditor={propsRow.data}
              handleSaveDictionary={props.handleSaveDictionary}
              handleOpenDictionary={props.handleOpenDictionary}
              handleDeleteDictionary={props.handleDeleteDictionary}
              showInfoMessage={props.showInfoMessage}
              isOpen={propsRow.data.isOpen}
            />
          ),
        }}
        style={{
          background: "transparent",
          boxShadow: "none",
          marginTop: "25px",
        }}
      />
    </>
  );
}
