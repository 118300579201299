import * as React from "react";
import AddReport from "../AddReport/add-report";
import SearchField from "Components/Inputs/SearchField/search-field";
import { IReportsListFiltersProps } from "Components/Reports/interfaces-reports";
import { IsEnabled, ReportTypeEnum } from "Common/enum";
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

const ReportsListFilters: (props: IReportsListFiltersProps) => JSX.Element = (
  props: IReportsListFiltersProps
): JSX.Element => {
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      style={{ paddingBottom: "20px" }}
    >
      <Grid container item xs={8} spacing={4} alignItems="baseline">
        <Grid item sm={4}>
          <SearchField onChangeEvent={props.onSearchChange} />
        </Grid>
        <Grid item xs={2}>
          <FormControl>
            <InputLabel>Status</InputLabel>
            <Select
              value={props.selectedStatus}
              onChange={props.onSelectedStatusChange}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <MenuItem key={-1} value={-1}>
                All
              </MenuItem>
              <Divider variant="middle" />
              <MenuItem key={IsEnabled.Enabled} value={IsEnabled.Enabled}>
                Active
              </MenuItem>
              <MenuItem key={IsEnabled.NotEnabled} value={IsEnabled.NotEnabled}>
                Not active
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl>
            <InputLabel>Type</InputLabel>
            <Select
              value={props.type}
              onChange={props.onTypeChange}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <MenuItem key={-1} value={-1}>
                All
              </MenuItem>
              <Divider variant="middle" />
              <MenuItem
                key={ReportTypeEnum.Report}
                value={ReportTypeEnum.Report}
              >
                {ReportTypeEnum[ReportTypeEnum.Report]}
              </MenuItem>
              <MenuItem key={ReportTypeEnum.Grid} value={ReportTypeEnum.Grid}>
                {ReportTypeEnum[ReportTypeEnum.Grid]}
              </MenuItem>
              <MenuItem
                key={ReportTypeEnum.PowerBI}
                value={ReportTypeEnum.PowerBI}
              >
                {ReportTypeEnum[ReportTypeEnum.PowerBI]}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item xs={3} justify="flex-end">
        <AddReport
          client={props.client}
          applications={props.applications}
          handleRefreshReports={props.handleRefreshReports}
          selectedAppID={props.selectedAppID}
        />
      </Grid>
    </Grid>
  );
};

export default ReportsListFilters;
