import * as _ from "lodash";
import * as React from "react";
import InputCheckbox from "Components/Inputs/input-checkbox";
import TypographyInfo from "Components/Typography/typography-info";
import { Autocomplete } from "@material-ui/lab";
import { GetPermissionsApiResponseType } from "Types/permission-types";
import {
  GetReportContainerListApiResponseType,
  GetReportDetailsApiResponseType,
} from "Types/report-types";
import {
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@material-ui/core";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import { ReportSubtypeEnum } from "Common/enum";

interface EditReportGeneralOtherProps {
  isEditable: boolean;
  reportContainerList: GetReportContainerListApiResponseType[];
  permissionList: GetPermissionsApiResponseType[];
  changeReportData: (data: GetReportDetailsApiResponseType) => void;
  data: GetReportDetailsApiResponseType;
}
export const EditReportGeneralOther: React.FC<EditReportGeneralOtherProps> = ({
  isEditable,
  reportContainerList,
  permissionList,
  changeReportData,
  data,
}) => {
  const { permissionID, reportContainers } = data;
  const theme = useTheme();

  const handleChangePermission = (
    event: any,
    value: GetPermissionsApiResponseType | null
  ) => {
    if (!value) {
      changeReportData({
        ...data,
        permissionID: undefined,
        permissionName: undefined,
      });
    } else {
      changeReportData({
        ...data,
        permissionID: value.permissionID,
        permissionName: value.name,
      });
    }
  };

  const handleChangeSplitDetailsPercentage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = parseInt(event.target.value);
    changeReportData({ ...data, splitDetailsPercentage: value });
  };

  const handleChangeContainers = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    let selectedValues = event.target.value as number[];
    changeReportData({
      ...data,
      reportContainers: selectedValues.map((s) => ({
        reportContainerID: s,
        reportContainerName:
          reportContainerList.find((c) => c.reportContainerID === s)
            ?.reportContainerName ?? "",
      })),
    });
  };

  return (
    <Grid item container direction="column">
      <TypographyInfo
        text="Other"
        fontSize="16px"
        color={theme.palette.grayDarker.main}
        margin="0"
      />
      <Grid
        item
        container
        xs={6}
        justify="space-between"
        alignItems="flex-end"
        alignContent="flex-end"
      >
        <Grid item style={{ width: "49%" }}>
          <FormControl fullWidth margin="dense">
            <InputLabel>Containers</InputLabel>
            <Select
              disabled={!isEditable}
              multiple
              value={reportContainers?.map((c) => c.reportContainerID) ?? []}
              onChange={handleChangeContainers}
              renderValue={(selected) =>
                reportContainers
                  ?.map((c) => c.reportContainerName)
                  ?.join(", ") ?? ""
              }
              fullWidth
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              {reportContainerList.map((c) => (
                <MenuItem key={c.reportContainerID} value={c.reportContainerID}>
                  <span style={{ width: "40px", marginBottom: "19px" }}>
                    <InputCheckbox
                      checked={
                        !!reportContainers?.some(
                          (rc) => rc.reportContainerID === c.reportContainerID
                        )
                      }
                    />
                  </span>
                  <ListItemText
                    primary={<TypographyInfo text={c.reportContainerName} />}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item style={{ width: "49%" }}>
          <Autocomplete
            multiple={false}
            fullWidth
            disabled={!isEditable}
            value={
              permissionList.find(
                (p) => permissionID && p.permissionID === permissionID
              ) ?? null
            }
            onChange={handleChangePermission}
            options={_.orderBy(
              permissionList,
              [(p) => p.name.toLowerCase()],
              ["asc"]
            )}
            getOptionLabel={(option: GetPermissionsApiResponseType) =>
              option.name
            }
            style={{ width: "100%", minWidth: "170px" }}
            renderInput={(params) => (
              <TextField {...params} label="Permission" margin="dense" />
            )}
          />
        </Grid>
      </Grid>
      {data.reportSubtype == ReportSubtypeEnum.MasterDetails &&
        <Grid
          item
          container
          xs={6}
          justify="space-between"
          alignItems="flex-end"
          alignContent="flex-end"
        >
          <Grid item style={{ width: "49%" }}>
            <TextFieldEntered
              required
              disabled={!isEditable}
              id="split-details-precentage"
              label="% of the screen for details page"
              value={data.splitDetailsPercentage}
              type="number"
              inputProps={{ min: 1, max: 100 }}
              onChange={handleChangeSplitDetailsPercentage}
              style={{ textAlign: "left" }}
            />
          </Grid>
        </Grid>
      }
    </Grid>
  );
};

export default EditReportGeneralOther;
