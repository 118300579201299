import * as React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { GetReportDictionariesApiResponseType } from '../../../../../Types/report-types';

import { stylesSelectSourceDialog } from './styles';

export interface SelectDictionaryProps {
  dictionaries: GetReportDictionariesApiResponseType[];
  selectedDictionary?: GetReportDictionariesApiResponseType;
  handleChangeSelectedDictionary: (
    event: React.ChangeEvent<{}>,
    value: GetReportDictionariesApiResponseType | null
  ) => void;
}

const SelectDictionary: React.FC<SelectDictionaryProps> = ({
  selectedDictionary,
  handleChangeSelectedDictionary,
  dictionaries,
}) => {
  const customStyles = stylesSelectSourceDialog();

  return (
    <Autocomplete
      open={true}
      value={selectedDictionary}
      onChange={handleChangeSelectedDictionary}
      options={dictionaries}
      getOptionLabel={(option) => option.dictionaryName}
      style={{ width: '100%' }}
      classes={{ listbox: customStyles.autocomplete }}
      renderInput={(params) => <TextField {...params} variant='outlined' />}
    />
  );
};

export default SelectDictionary;
