import * as React from 'react';
import SvgDot from '../Images/image-dot';
import TypographyInfo from 'Components/Typography/typography-info';
import TypographyTitle from 'Components/Typography/typography-title';
import { Container, Grid, useTheme } from '@material-ui/core';

export interface ICustomerLocationsHeaderProps {
  customerName: string;
  pinLength: number;
  address: string;
  guid: string;
  isDealer: boolean;
}

const CustomerLocationsHeader: (
  props: ICustomerLocationsHeaderProps
) => JSX.Element = (props: ICustomerLocationsHeaderProps): JSX.Element => {
  const { customerName, pinLength, address, guid, isDealer } = props;

  const theme = useTheme();

  return (
    <div style={{ marginTop: 10 }}>
      <Container>
        <Grid container justify="space-between" alignItems="baseline">
          <TypographyTitle
            text={customerName}
            color={theme.palette.grayDarker.main}
            fontSize="20px"
            margin="25px 0 5px 0"
          />
        </Grid>
        <Grid container item direction="row" alignItems="center" spacing={2}>
          {isDealer && (
            <>
              <Grid item>
                <TypographyInfo text="Dealer" fontSize="14px" margin="0" />
              </Grid>
              <SvgDot />
            </>
          )}
          <Grid item>
            <TypographyInfo text={`GUID: ${guid}`} fontSize="14px" margin="0" />
          </Grid>
          <SvgDot />
          <Grid item>
            <TypographyInfo
              text={`PIN Length: ${pinLength}`}
              fontSize="14px"
              margin="0"
            />
          </Grid>
          <SvgDot />
          <Grid item>
            <TypographyInfo text={address} fontSize="14px" margin="0" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CustomerLocationsHeader;
