import * as React from 'react';
import EndpointAvailable from './endpoint-available';
import { AppCheckRespondingTypeEnum } from 'Common/enum-app';

interface PowerBiEndpointProps {
  handleCheckWS: (
    checkRespondingType: AppCheckRespondingTypeEnum
  ) => Promise<void>;
}

const PowerBiEndpoint: React.FC<PowerBiEndpointProps> = ({ handleCheckWS }) => (
  <EndpointAvailable
    handleCheckWS={() => handleCheckWS(AppCheckRespondingTypeEnum.PowerBI)}
    isAvailableKey="isPowerBIEndpointsAvailable"
    apiMethodPath="apiPowerBIMethodPath"
    checkboxLabel="PowerBI endpoints available"
    apiMethodPathLabel="Api powerBI method path"
  />
);

export default PowerBiEndpoint;
