import React, { Component } from "react";
import { saveJsErrorToLog } from "./Configuration/save-error-to-log";
import { RouterApp } from "./Configuration/router-app";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "Components/Theme/theme";

export interface AppProps {}
export interface AppSate {}

window.onerror = (message, source, lineno, colno, error) => {
  saveJsErrorToLog(message, source, lineno, colno, error);
  return false;
};

class App extends Component<AppProps, AppSate> {
  render() {
    return <ThemeProvider theme={theme}>{RouterApp()}</ThemeProvider>;
  }
}

export default App;
