import * as React from 'react';
import MaterialTable from 'material-table';
import UserLoginListItem from 'Components/User/UserLogin/UserLoginListItem/user-login-list-item';
import { IUserLoginListProps } from 'Components/User/interfaces-user';

export default function UserLoginList(props: IUserLoginListProps) {
  return (
    <MaterialTable
      columns={[]}
      data={props.userLoginEditorList}
      options={{
        showTitle: false,
        header: false,
        paging: false,
        search: false,
        toolbar: false,
      }}
      components={{
        Row: (propsRow) => (
          <UserLoginListItem
            appGuid={props.appGuid}
            userLogin={propsRow.data.userLogin}
            userID={props.userID}
            appList={props.appList}
            countryList={props.countryList}
            customerList={props.customerList}
            handleSaveUserLogin={props.handleSaveUserLogin}
            handleOpenUserLogin={props.handleOpenUserLogin}
            handleDeleteUserLogin={props.handleDeleteUserLogin}
            handleSendUserLoginResetCode={props.handleSendUserLoginResetCode}
            showInfoMessage={props.showInfoMessage}
            isOpen={propsRow.data.isOpen}
          />
        ),
      }}
      style={{
        background: 'transparent',
        boxShadow: 'none',
      }}
    />
  );
}
