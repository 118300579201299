import * as React from "react";
import ButtonOutlined from "Components/Buttons/button-outlined";
import { IconType } from "Common/enum";
import { Grid, GridItemsAlignment, useTheme } from "@material-ui/core";
import { FunctionComponent } from "react";

interface ActionButtonProps {
  count: number;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  alignItems: GridItemsAlignment;
  text: "Move" | "Delete";
  iconStart: (props: { width: string; color: string }) => React.ReactNode;
  padding?: string;
}
const ActionButton: FunctionComponent<ActionButtonProps> = ({
  count,
  handleClick,
  alignItems,
  text,
  iconStart,
  padding,
}) => {
  const theme = useTheme();

  const color =
    count > 0 ? theme.palette.primary.main : theme.palette.secondary.main;
  return (
    <Grid container item sm={4} alignItems={alignItems} justify="flex-end">
      <ButtonOutlined
        text={`${text} (${count})`}
        iconStartType={IconType.SvgImageIcon}
        iconStart={iconStart({ width: "14px", color: color })}
        padding={padding}
        size="md"
        disabled={count < 1}
        borderColor={color}
        handleClick={handleClick}
      />
    </Grid>
  );
};
export default ActionButton;
