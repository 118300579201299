import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { IInfoDialogProps } from "../interfaces";
import TypographySubtitle from "../../Typography/typography-subtitle";
import parse from "html-react-parser";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";

export default function InfoDialog(props: IInfoDialogProps) {
  const { handleOkClick, text, isOpen } = props;

  return (
    <Dialog open={isOpen} onEscapeKeyDown={handleOkClick}>
      <DialogTitle>
        <TypographySubtitle text="Info" />
      </DialogTitle>
      <DialogContent dividers>{parse(text)}</DialogContent>
      <DialogActions>
        <ButtonAction isSubmit text="Ok" handleClick={handleOkClick} />
      </DialogActions>
    </Dialog>
  );
}

export const useInfoDialog = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [text, setText] = React.useState("");

  const openInfoDialog = (text: string) => {
    setText(text);
    setIsOpen(true);
  };
  const closeInfoDialog = () => {
    setText("");
    setIsOpen(false);
  };

  return {
    openInfoDialog,
    isOpenInfoDialog: isOpen,
    closeInfoDialog,
    infoDialogText: text,
  };
};
