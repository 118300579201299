import * as React from "react";

const SvgLoginTopRight = ({
    style = {},
    fill = "none",
    width = "100%",
    height = "40%",
    className = "",
    colorFill = "#E6E6E6",
    viewBox = "0 0 568 516"
  }) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <rect x="109.851" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="117.174" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 117.174 16.5461)" fill={colorFill}/>
        <rect x="145.003" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="152.326" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 152.326 16.5461)" fill={colorFill}/>
        <rect x="180.155" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="187.478" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 187.478 16.5461)" fill={colorFill}/>
        <rect x="215.307" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="222.63" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 222.63 16.5461)" fill={colorFill}/>
        <rect x="250.459" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="257.782" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 257.782 16.5461)" fill={colorFill}/>
        <rect x="74.698" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="82.0212" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 82.0212 51.698)" fill={colorFill}/>
        <rect x="109.851" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="117.174" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 117.174 51.698)" fill={colorFill}/>
        <rect x="145.003" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="152.326" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 152.326 51.698)" fill={colorFill}/>
        <rect x="180.155" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="187.478" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 187.478 51.698)" fill={colorFill}/>
        <rect x="215.307" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="222.63" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 222.63 51.698)" fill={colorFill}/>
        <rect x="250.459" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="257.782" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 257.782 51.698)" fill={colorFill}/>
        <rect x="74.698" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="82.0212" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 82.0212 86.8511)" fill={colorFill}/>
        <rect x="109.851" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="117.174" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 117.174 86.8511)" fill={colorFill}/>
        <rect x="145.003" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="152.326" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 152.326 86.8511)" fill={colorFill}/>
        <rect x="180.155" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="187.478" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 187.478 86.8511)" fill={colorFill}/>
        <rect x="215.307" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="222.63" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 222.63 86.8511)" fill={colorFill}/>
        <rect x="250.459" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="257.782" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 257.782 86.8511)" fill={colorFill}/>
        <rect x="74.698" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="82.0212" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 82.0212 122.003)" fill={colorFill}/>
        <rect x="109.851" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="117.174" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 117.174 122.003)" fill={colorFill}/>
        <rect x="145.003" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="152.326" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 152.326 122.003)" fill={colorFill}/>
        <rect x="180.155" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="187.478" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 187.478 122.003)" fill={colorFill}/>
        <rect x="215.307" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="222.63" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 222.63 122.003)" fill={colorFill}/>
        <rect x="250.459" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="257.782" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 257.782 122.003)" fill={colorFill}/>
        <rect x="74.698" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="82.0212" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 82.0212 157.155)" fill={colorFill}/>
        <rect x="109.851" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="117.174" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 117.174 157.155)" fill={colorFill}/>
        <rect x="145.003" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="152.326" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 152.326 157.155)" fill={colorFill}/>
        <rect x="180.155" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="187.478" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 187.478 157.155)" fill={colorFill}/>
        <rect x="215.307" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="222.63" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 222.63 157.155)" fill={colorFill}/>
        <rect x="250.459" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="257.782" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 257.782 157.155)" fill={colorFill}/>
        <rect x="109.851" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="117.174" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 117.174 192.307)" fill={colorFill}/>
        <rect x="145.003" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="152.326" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 152.326 192.307)" fill={colorFill}/>
        <rect x="180.155" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="187.478" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 187.478 192.307)" fill={colorFill}/>
        <rect x="215.307" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="222.63" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 222.63 192.307)" fill={colorFill}/>
        <rect x="250.459" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="257.782" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 257.782 192.307)" fill={colorFill}/>
        <rect x="180.155" y="223.065" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="187.478" y="227.459" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 187.478 227.459)" fill={colorFill}/>
        <rect x="215.307" y="223.065" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="222.63" y="227.459" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 222.63 227.459)" fill={colorFill}/>
        <rect x="250.459" y="223.065" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="257.782" y="227.459" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 257.782 227.459)" fill={colorFill}/>
        <rect x="285.611" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="292.934" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 292.934 16.5461)" fill={colorFill}/>
        <rect x="320.764" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="328.087" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 328.087 16.5461)" fill={colorFill}/>
        <rect x="355.916" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="363.239" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 363.239 16.5461)" fill={colorFill}/>
        <rect x="391.068" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 16.5461)" fill={colorFill}/>
        <rect x="426.22" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 16.5461)" fill={colorFill}/>
        <rect x="285.611" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="292.934" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 292.934 51.698)" fill={colorFill}/>
        <rect x="320.764" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="328.087" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 328.087 51.698)" fill={colorFill}/>
        <rect x="355.916" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="363.239" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 363.239 51.698)" fill={colorFill}/>
        <rect x="391.068" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 51.698)" fill={colorFill}/>
        <rect x="426.22" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 51.698)" fill={colorFill}/>
        <rect x="285.611" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="292.934" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 292.934 86.8511)" fill={colorFill}/>
        <rect x="320.764" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="328.087" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 328.087 86.8511)" fill={colorFill}/>
        <rect x="355.916" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="363.239" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 363.239 86.8511)" fill={colorFill}/>
        <rect x="391.068" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 86.8511)" fill={colorFill}/>
        <rect x="426.22" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 86.8511)" fill={colorFill}/>
        <rect x="285.611" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="292.934" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 292.934 122.003)" fill={colorFill}/>
        <rect x="320.764" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="328.087" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 328.087 122.003)" fill={colorFill}/>
        <rect x="355.916" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="363.239" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 363.239 122.003)" fill={colorFill}/>
        <rect x="391.068" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 122.003)" fill={colorFill}/>
        <rect x="426.22" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 122.003)" fill={colorFill}/>
        <rect x="285.611" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="292.934" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 292.934 157.155)" fill={colorFill}/>
        <rect x="320.764" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="328.087" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 328.087 157.155)" fill={colorFill}/>
        <rect x="355.916" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="363.239" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 363.239 157.155)" fill={colorFill}/>
        <rect x="391.068" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 157.155)" fill={colorFill}/>
        <rect x="426.22" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 157.155)" fill={colorFill}/>
        <rect x="285.611" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="292.934" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 292.934 192.307)" fill={colorFill}/>
        <rect x="320.764" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="328.087" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 328.087 192.307)" fill={colorFill}/>
        <rect x="355.916" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="363.239" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 363.239 192.307)" fill={colorFill}/>
        <rect x="391.068" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 192.307)" fill={colorFill}/>
        <rect x="426.22" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 192.307)" fill={colorFill}/>
        <rect x="285.611" y="223.065" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="292.934" y="227.459" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 292.934 227.459)" fill={colorFill}/>
        <rect x="320.764" y="223.065" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="328.087" y="227.459" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 328.087 227.459)" fill={colorFill}/>
        <rect x="355.916" y="223.065" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="363.239" y="227.459" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 363.239 227.459)" fill={colorFill}/>
        <rect x="391.068" y="223.065" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="227.459" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 227.459)" fill={colorFill}/>
        <rect x="426.22" y="223.065" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="227.459" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 227.459)" fill={colorFill}/>
        <rect x="215.307" y="258.217" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="222.63" y="262.611" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 222.63 262.611)" fill={colorFill}/>
        <rect x="250.459" y="258.217" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="257.782" y="262.611" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 257.782 262.611)" fill={colorFill}/>
        <rect x="250.459" y="293.37" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="257.782" y="297.764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 257.782 297.764)" fill={colorFill}/>
        <rect x="285.611" y="258.217" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="292.934" y="262.611" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 292.934 262.611)" fill={colorFill}/>
        <rect x="320.764" y="258.217" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="328.087" y="262.611" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 328.087 262.611)" fill={colorFill}/>
        <rect x="355.916" y="258.217" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="363.239" y="262.611" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 363.239 262.611)" fill={colorFill}/>
        <rect x="391.068" y="258.217" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="262.611" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 262.611)" fill={colorFill}/>
        <rect x="426.22" y="258.217" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="262.611" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 262.611)" fill={colorFill}/>
        <rect x="285.611" y="293.37" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="292.934" y="297.764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 292.934 297.764)" fill={colorFill}/>
        <rect x="320.764" y="293.37" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="328.087" y="297.764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 328.087 297.764)" fill={colorFill}/>
        <rect x="355.916" y="293.37" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="363.239" y="297.764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 363.239 297.764)" fill={colorFill}/>
        <rect x="391.068" y="293.37" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="297.764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 297.764)" fill={colorFill}/>
        <rect x="426.22" y="293.37" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="297.764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 297.764)" fill={colorFill}/>
        <rect x="285.611" y="328.522" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="292.934" y="332.916" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 292.934 332.916)" fill={colorFill}/>
        <rect x="320.764" y="328.522" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="328.087" y="332.916" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 328.087 332.916)" fill={colorFill}/>
        <rect x="355.916" y="328.522" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="363.239" y="332.916" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 363.239 332.916)" fill={colorFill}/>
        <rect x="391.068" y="328.522" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="332.916" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 332.916)" fill={colorFill}/>
        <rect x="426.22" y="328.522" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="332.916" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 332.916)" fill={colorFill}/>
        <rect x="285.611" y="363.674" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="292.934" y="368.068" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 292.934 368.068)" fill={colorFill}/>
        <rect x="320.764" y="363.674" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="328.087" y="368.068" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 328.087 368.068)" fill={colorFill}/>
        <rect x="355.916" y="363.674" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="363.239" y="368.068" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 363.239 368.068)" fill={colorFill}/>
        <rect x="391.068" y="363.674" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="368.068" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 368.068)" fill={colorFill}/>
        <rect x="426.22" y="363.674" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="368.068" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 368.068)" fill={colorFill}/>
        <rect x="285.611" y="398.826" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="292.934" y="403.22" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 292.934 403.22)" fill={colorFill}/>
        <rect x="320.764" y="398.826" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="328.087" y="403.22" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 328.087 403.22)" fill={colorFill}/>
        <rect x="355.916" y="398.826" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="363.239" y="403.22" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 363.239 403.22)" fill={colorFill}/>
        <rect x="391.068" y="398.826" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="403.22" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 403.22)" fill={colorFill}/>
        <rect x="426.22" y="398.826" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="403.22" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 403.22)" fill={colorFill}/>
        <rect x="320.764" y="433.978" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="328.087" y="438.372" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 328.087 438.372)" fill={colorFill}/>
        <rect x="355.916" y="433.978" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="363.239" y="438.372" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 363.239 438.372)" fill={colorFill}/>
        <rect x="391.068" y="433.978" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="438.372" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 438.372)" fill={colorFill}/>
        <rect x="426.22" y="433.978" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="438.372" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 438.372)" fill={colorFill}/>
        <rect x="391.068" y="469.13" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="398.391" y="473.524" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 398.391 473.524)" fill={colorFill}/>
        <rect x="426.22" y="469.13" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="433.543" y="473.524" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 433.543 473.524)" fill={colorFill}/>
        <rect x="461.372" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 16.5461)" fill={colorFill}/>
        <rect x="496.524" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 16.5461)" fill={colorFill}/>
        <rect x="531.677" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 16.5461)" fill={colorFill}/>
        <rect x="566.829" y="12.1521" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="574.152" y="16.5461" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 574.152 16.5461)" fill={colorFill}/>
        <rect x="461.372" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 51.698)" fill={colorFill}/>
        <rect x="496.524" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 51.698)" fill={colorFill}/>
        <rect x="531.677" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 51.698)" fill={colorFill}/>
        <rect x="566.829" y="47.304" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="574.152" y="51.698" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 574.152 51.698)" fill={colorFill}/>
        <rect x="461.372" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 86.8511)" fill={colorFill}/>
        <rect x="496.524" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 86.8511)" fill={colorFill}/>
        <rect x="531.677" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 86.8511)" fill={colorFill}/>
        <rect x="566.829" y="82.457" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="574.152" y="86.8511" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 574.152 86.8511)" fill={colorFill}/>
        <rect x="461.372" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 122.003)" fill={colorFill}/>
        <rect x="496.524" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 122.003)" fill={colorFill}/>
        <rect x="531.677" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 122.003)" fill={colorFill}/>
        <rect x="566.829" y="117.609" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="574.152" y="122.003" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 574.152 122.003)" fill={colorFill}/>
        <rect x="461.372" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 157.155)" fill={colorFill}/>
        <rect x="496.524" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 157.155)" fill={colorFill}/>
        <rect x="531.677" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 157.155)" fill={colorFill}/>
        <rect x="566.829" y="152.761" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="574.152" y="157.155" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 574.152 157.155)" fill={colorFill}/>
        <rect x="461.372" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 192.307)" fill={colorFill}/>
        <rect x="496.524" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 192.307)" fill={colorFill}/>
        <rect x="531.677" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 192.307)" fill={colorFill}/>
        <rect x="566.829" y="187.913" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="574.152" y="192.307" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 574.152 192.307)" fill={colorFill}/>
        <rect x="461.372" y="223.065" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="227.459" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 227.459)" fill={colorFill}/>
        <rect x="496.524" y="223.065" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="227.459" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 227.459)" fill={colorFill}/>
        <rect x="531.677" y="223.065" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="227.459" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 227.459)" fill={colorFill}/>
        <rect x="566.829" y="223.065" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="574.152" y="227.459" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 574.152 227.459)" fill={colorFill}/>
        <rect x="461.372" y="258.217" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="262.611" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 262.611)" fill={colorFill}/>
        <rect x="496.524" y="258.217" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="262.611" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 262.611)" fill={colorFill}/>
        <rect x="531.677" y="258.217" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="262.611" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 262.611)" fill={colorFill}/>
        <rect x="566.829" y="258.217" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="574.152" y="262.611" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 574.152 262.611)" fill={colorFill}/>
        <rect x="461.372" y="293.37" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="297.764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 297.764)" fill={colorFill}/>
        <rect x="496.524" y="293.37" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="297.764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 297.764)" fill={colorFill}/>
        <rect x="531.677" y="293.37" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="297.764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 297.764)" fill={colorFill}/>
        <rect x="566.829" y="293.37" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="574.152" y="297.764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 574.152 297.764)" fill={colorFill}/>
        <rect x="461.372" y="328.522" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="332.916" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 332.916)" fill={colorFill}/>
        <rect x="496.524" y="328.522" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="332.916" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 332.916)" fill={colorFill}/>
        <rect x="531.677" y="328.522" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="332.916" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 332.916)" fill={colorFill}/>
        <rect x="566.829" y="328.522" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="574.152" y="332.916" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 574.152 332.916)" fill={colorFill}/>
        <rect x="461.372" y="363.674" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="368.068" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 368.068)" fill={colorFill}/>
        <rect x="496.524" y="363.674" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="368.068" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 368.068)" fill={colorFill}/>
        <rect x="531.677" y="363.674" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="368.068" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 368.068)" fill={colorFill}/>
        <rect x="566.829" y="363.674" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="574.152" y="368.068" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 574.152 368.068)" fill={colorFill}/>
        <rect x="461.372" y="398.826" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="403.22" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 403.22)" fill={colorFill}/>
        <rect x="496.524" y="398.826" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="403.22" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 403.22)" fill={colorFill}/>
        <rect x="531.677" y="398.826" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="403.22" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 403.22)" fill={colorFill}/>
        <rect x="566.829" y="398.826" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="574.152" y="403.22" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 574.152 403.22)" fill={colorFill}/>
        <rect x="461.372" y="433.978" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="438.372" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 438.372)" fill={colorFill}/>
        <rect x="496.524" y="433.978" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="438.372" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 438.372)" fill={colorFill}/>
        <rect x="531.677" y="433.978" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="438.372" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 438.372)" fill={colorFill}/>
        <rect x="566.829" y="433.978" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="574.152" y="438.372" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 574.152 438.372)" fill={colorFill}/>
        <rect x="461.372" y="469.13" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="468.695" y="473.524" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 468.695 473.524)" fill={colorFill}/>
        <rect x="496.524" y="469.13" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="503.848" y="473.524" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 503.848 473.524)" fill={colorFill}/>
        <rect x="531.677" y="469.13" width="2.92935" height="11.7174" rx="1.46467" fill={colorFill}/>
        <rect x="539" y="473.524" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(90 539 473.524)" fill={colorFill}/>
    </svg>
  );

  export default SvgLoginTopRight;