import { makeStyles } from "@material-ui/core";
import Colors from 'Common/colors';

export const stylesDictionaryList = makeStyles({
    gridItem: {
        borderRight: `1px solid ${Colors.graySeparate}`,
        marginRight: '40px',
        height: '50px',
    },
    paper: {
        padding: '11px 30px',
        marginBottom: '15px'
    },
    header: {
        marginBottom: '20px'
    },
    row: {
        marginTop: '15px',
    },
    button: {
        marginLeft: '15px',
    },
    itemRow: {
        borderBottom: `1px solid ${Colors.graySeparate}`,
        paddingBottom: "33px",
        marginTop: "15px",
        paddingRight: "45px"
    },
    editForm: {
        marginTop: "15px",
        borderTop: `1px solid ${Colors.graySeparate}`,
    },
    item: {
        width: "100%"
    },
    dialogContent: {
        '&:first-child': {
            paddingTop: '5px'
        }
    }
});