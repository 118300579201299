import { makeStyles } from "@material-ui/core";
import Colors from 'Common/colors';

export const stylesUserListItem = makeStyles({
    gridItem: {
        borderRight: `1px solid ${Colors.graySeparate}`,
        marginRight: '40px',
        minHeight: '50px',
    },
    paper: {
        padding: '11px 30px',
        marginBottom: '15px'
    },
    emailIcon: {
        marginRight: '6px'
    },
    userName: {
        fontSize: '18px'
    },
    userEmail: {
        fontSize: '14px'
    }

});