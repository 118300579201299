import * as React from "react";

const SvgResendArrow = ({
  style = {},
  fill = "none",
  width = "100%",
  className = "",
  viewBox = "0 0 28 25"
}) => (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M11.3164 8.27246C20.7255 8.27246 26.9982 11.3138 26.9982 20.4377V24.9997" stroke="black" />
      <path d="M11.3164 16.6367C20.7255 16.6367 26.9982 18.7276 26.9982 25.0004" stroke="black" />
      <path d="M11.3178 8.76953V2L0.863281 12.4545L11.3178 22.9091V16.1445" stroke="black" />
    </svg>
  );

export default SvgResendArrow;