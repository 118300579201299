import { Grid, Paper } from '@material-ui/core';
import Colors from 'Common/colors';
import SvgHeap from 'Components/Images/image-heap';
import TypographyInfo from 'Components/Typography/typography-info';
import React from 'react';
import { GetRolesApiResponseType } from 'Types/role-types';

export interface RoleListItemProps {
  role: GetRolesApiResponseType;
}

const RoleListItem: React.FC<RoleListItemProps> = ({
  role: { roleID, name, applications },
}) => {
  return (
    <Grid container item>
      <Paper style={{ width: '100%', height: '100%', padding: '11px 30px' }}>
        <Grid container item alignContent="center" alignItems="center">
          <Grid
            container
            item
            xs={3}
            alignContent="center"
            alignItems="center"
            // className={customStyles.permissionGroupNameContainer}
          >
            <TypographyInfo
              text={name}
              color={Colors.black}
              style={{
                fontSize: '18px',
                fontWeight: 500,
                lineHeight: '27px',
              }}
            />
          </Grid>
          <Grid
            container
            item
            xs={9}
            style={{
              paddingLeft: '34px',
              borderLeft: `1px solid ${Colors.graySeparate}`,
            }}
            alignItems="center"
            alignContent="center"
          >
            <SvgHeap />
            {applications.map((app) => (
              <TypographyInfo
                text={app.name}
                margin={'5px 10px'}
                style={{ fontSize: '14px', lineHeight: '21px' }}
                key={app.appID}
              />
            ))}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default RoleListItem;
