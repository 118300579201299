import * as React from 'react';

const SvgFile = ({ fill = 'none', color = '#959595' }) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H7C9.48528 0.5 11.5 2.51472 11.5 5V12C11.5 12.8284 10.8284 13.5 10 13.5H2C1.17157 13.5 0.5 12.8284 0.5 12V2Z"
      stroke={color}
    />
    <path d="M7 1V4C7 4.55228 7.44772 5 8 5H11" stroke={color} />
  </svg>
);

export default SvgFile;
