import * as React from 'react';
import Colors from 'Common/colors';
import { Button, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IButtonOutlinedProps } from './interfaces';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { IconType } from 'Common/enum';

export default function ButtonOutlined(props: IButtonOutlinedProps) {
  let styles = makeStyles({
    button: {
      background: props.backgroundColor ? props.backgroundColor : "transparent",
      color: props.foregroundColor ? props.foregroundColor : Colors.grayDark,
      border:
        (props.borderWidth ? props.borderWidth : "1.5px") +
        " solid " +
        (props.borderColor ? props.borderColor : Colors.grayDark),
      borderRadius: "7px",
      padding: props.padding ?? "3px 20px",
      textTransform: "none",
      margin: props.margin ? props.margin : "0",
    },
    buttonSmall: {
      background: props.backgroundColor ? props.backgroundColor : "transparent",
      color: props.foregroundColor ? props.foregroundColor : Colors.grayDark,
      border:
        (props.borderWidth ? props.borderWidth : "1.5px") +
        " solid " +
        (props.borderColor ? props.borderColor : Colors.grayDark),
      borderRadius: "4px",
      padding: props.padding ?? "2px 15px",
      fontSize: "10px",
      textTransform: "none",
      margin: props.margin ? props.margin : "0",
    },
    buttonLarge: {
      background: props.backgroundColor ? props.backgroundColor : "transparent",
      color: props.foregroundColor ? props.foregroundColor : Colors.grayDark,
      border:
        (props.borderWidth ? props.borderWidth : "2px") +
        " solid " +
        (props.borderColor ? props.borderColor : Colors.grayDark),
      borderRadius: "7px",
      padding: props.padding ?? "5px 25px",
      fontSize: "20px",
      textTransform: "none",
      margin: props.margin ? props.margin : "0",
    },
  });

  let customStyles = styles({});
  let startIcon, endIcon;
  let styleName: string;
  switch (props.iconStartType) {
    case IconType.FontAwesomeIcon:
      startIcon = <FontAwesomeIcon icon={props.iconStart as IconDefinition} />;
      break;
    case IconType.SvgImageIcon:
      startIcon = props.iconStart;
      break;
    default:
      startIcon = undefined;
      break;
  }

  switch (props.iconEndType) {
    case IconType.FontAwesomeIcon:
      endIcon = <FontAwesomeIcon icon={props.iconEnd as IconDefinition} />;
      break;
    case IconType.SvgImageIcon:
      endIcon = props.iconEnd;
      break;
    default:
      endIcon = undefined;
      break;
  }

  switch (props.size) {
    case "sm":
      styleName = customStyles.buttonSmall;
      break;
    case "lg":
      styleName = customStyles.buttonLarge;
      break;
    default:
      styleName = customStyles.button;
      break;
  }

  return (
    <Button
      type={props.isSubmit ? "submit" : "button"}
      className={styleName}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={props.handleClick}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
    >
      {props.text}
    </Button>
  );
}
