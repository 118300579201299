import * as React from "react";
import {
  GetReportParameterListType,
  GetReportDictionariesApiResponseType,
  GetReportDataTypeFormatListApiResponseType,
  SaveReportStoredProcedureParameterType,
  IReportComponentSource,
} from "../../../../Types/report-types";
import MenuIcon from "@material-ui/icons/Menu";
import SelectDataTypeFormat from "./select-data-type-format";
import SelectDefault from "./select-default";
import SelectEnterType from "./select-enter-type";
import SelectColumnNo from "./select-columnNo";
import SelectDisplayName from "./select-display-name";
import CheckboxRequired from "./checkbox-required";
import CheckboxVisible from "./checkbox-visible";
import SelectSourceProcedure from "./select-source-procedure";
import { IconButton } from "@material-ui/core";
import Colors from "../../../../Common/colors";
import {
  ReportParameterDatabaseDirection,
  ReportParameterDataType,
  ReportParameterEnterType,
} from "../../../../Common/enum";
import { stylesEditReportParametersListItemEditable } from "./styles";
import {
  isSecondParamDataRange,
  isFirstParamDataRange,
} from "./edit-report-parameters";

export interface EditReportParametersListItemEditableProps {
  data: GetReportParameterListType;
  source?: IReportComponentSource;
  list: GetReportParameterListType[];

  dictionaries: GetReportDictionariesApiResponseType[];
  dataTypeFormatList: GetReportDataTypeFormatListApiResponseType[];
  noOfParameterColumns: number;

  selectedRowOrderNo: number[];
  handleChangeParameters: (parameters: GetReportParameterListType[]) => void;
  handleMouseDown: (orderNo: number[]) => void;
  handleMouseUp: () => void;
  handleMouseEnter: (orderNo: number) => void;
  handleMouseLeave: () => void;

  newAddedStoredProcedureParams: SaveReportStoredProcedureParameterType[];
  handleChangeNewStoredProcedureParam: (
    data: SaveReportStoredProcedureParameterType[]
  ) => void;
}

const EditReportParametersListItemEditable: React.FC<EditReportParametersListItemEditableProps> =
  ({
    data,
    source,
    list,
    dictionaries,
    noOfParameterColumns,
    dataTypeFormatList,
    handleChangeParameters,
    handleMouseDown,
    handleMouseUp,
    handleMouseEnter,
    handleMouseLeave,
    selectedRowOrderNo,
    newAddedStoredProcedureParams,
    handleChangeNewStoredProcedureParam,
  }) => {
    const customStyles = stylesEditReportParametersListItemEditable();
    const formatListForDataType =
      dataTypeFormatList?.filter(
        (format) => format.dataType === data.dataType
      ) ?? [];

    const firstParamStyle: React.CSSProperties = {
      position: "relative",
      top: "30px",
    };
    const tdStyle: React.CSSProperties = isSecondParamDataRange(data, list)
      ? {
          borderTop: "none",
        }
      : {};
    const style: React.CSSProperties = isFirstParamDataRange(data, list)
      ? firstParamStyle
      : {};
    const connectedOrderNo = isFirstParamDataRange(data, list)
      ? data.orderNo + 1
      : isSecondParamDataRange(data, list)
      ? data.orderNo - 1
      : undefined;
    const orderNumbers = connectedOrderNo
      ? [data.orderNo, connectedOrderNo]
      : [data.orderNo];
    const styleRow: string = `${customStyles.row} ${
      selectedRowOrderNo.includes(data.orderNo)
        ? customStyles.selectedRow +
          " " +
          (selectedRowOrderNo.length === 2
            ? isFirstParamDataRange(data, list)
              ? customStyles.selectedRowFirstParam
              : isSecondParamDataRange(data, list)
              ? customStyles.selectedRowSecondParam
              : ""
            : "")
        : ""
    }`;
    const isRowsToShowParam: boolean = data.databaseName === "RowsToShow";

    return (
      <tr
        className={styleRow}
        onMouseEnter={() => handleMouseEnter(data.orderNo)}
        onMouseUp={() => handleMouseUp()}
        onMouseLeave={() => handleMouseLeave()}
      >
        <td style={{ padding: 0 }} align="center">
          <IconButton
            onMouseDown={() => handleMouseDown(orderNumbers)}
            style={{ padding: "12px 0" }}
          >
            <MenuIcon htmlColor={Colors.graySeparate} />
          </IconButton>
        </td>
        <td className={customStyles.disable}>
          {data.databaseName}
          <div style={{ ...style }}>
            {!isSecondParamDataRange(data, list)
              ? ReportParameterDataType[data.dataType]
              : ""}
          </div>
        </td>
        <td className={isRowsToShowParam ? customStyles.disable : undefined}>
          {isRowsToShowParam ? (
            data.databaseName
          ) : (
            <SelectDisplayName
              data={data}
              list={list}
              handleChangeParameters={handleChangeParameters}
            />
          )}
        </td>
        <td
          style={{
            ...tdStyle,
          }}
        >
          {!isRowsToShowParam && !isSecondParamDataRange(data, list) ? (
            <div style={{ ...style }}>
              <SelectDefault
                data={data}
                list={list}
                dictionary={dictionaries.find(
                  (dictionary) =>
                    dictionary.dictionaryID === data.iaReportDictionaryIDSource
                )}
                handleChangeParameters={handleChangeParameters}
              />
            </div>
          ) : (
            ""
          )}
        </td>
        <td style={{ textAlign: "center" }}>
          <CheckboxVisible
            data={data}
            list={list}
            isEditable={!isRowsToShowParam && !data.isRequired}
            handleChangeParameters={handleChangeParameters}
          />
        </td>
        <td
          style={{
            ...tdStyle,
          }}
          className={isRowsToShowParam ? customStyles.disable : undefined}
        >
          {isRowsToShowParam ? (
            <div style={{ ...style }}>
              {ReportParameterEnterType[data.enterType]}
            </div>
          ) : !isSecondParamDataRange(data, list) ? (
            <div style={{ ...style }}>
              <SelectEnterType
                data={data}
                list={list}
                handleChangeParameters={handleChangeParameters}
              />
            </div>
          ) : (
            ""
          )}
        </td>
        <td
          style={{
            ...tdStyle,
          }}
          className={
            data.enterType === ReportParameterEnterType.Text
              ? customStyles.disable
              : ""
          }
        >
          {data.enterType !== ReportParameterEnterType.Text &&
          !isRowsToShowParam &&
          !isSecondParamDataRange(data, list) ? (
            <div style={{ ...style }}>
              {/* {source && (
                <EditReportComponentSource
                  componentSource={source}
                ></EditReportComponentSource>
              )} */}
              <SelectSourceProcedure
                data={data}
                list={list}
                dictionaries={dictionaries}
                handleChangeParameters={handleChangeParameters}
                newAddedStoredProcedureParams={newAddedStoredProcedureParams}
                handleChangeNewStoredProcedureParam={
                  handleChangeNewStoredProcedureParam
                }
              />
            </div>
          ) : (
            ""
          )}
        </td>
        <td
          style={{
            ...tdStyle,
          }}
          className={
            formatListForDataType.length === 0 ? customStyles.disable : ""
          }
        >
          {formatListForDataType.length > 0 &&
          !isRowsToShowParam &&
          !isSecondParamDataRange(data, list) ? (
            <div style={{ ...style }}>
              <SelectDataTypeFormat
                data={data}
                list={list}
                dataTypeFormatList={formatListForDataType}
                handleChangeParameters={handleChangeParameters}
              />
            </div>
          ) : (
            ""
          )}
        </td>
        {/* <td>
        <SelectAlignment
          data={data}
          list={list}
          handleChangeParameters={handleChangeParameters}
        />
      </td> */}
        <td>
          <SelectColumnNo
            data={data}
            list={list}
            handleChangeParameters={handleChangeParameters}
            noOfParameterColumns={noOfParameterColumns}
          />
        </td>
        <td className={customStyles.disable}>
          {ReportParameterDatabaseDirection[data.databaseDirection]}
        </td>
        <td style={{ textAlign: "center" }}>
          <CheckboxRequired
            data={data}
            list={list}
            isEditable={!isRowsToShowParam}
            handleChangeParameters={handleChangeParameters}
          />
        </td>
      </tr>
    );
  };

export default EditReportParametersListItemEditable;
