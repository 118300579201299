import * as React from "react";
import RoleList from "Components/Role/RoleList/role-list";
import { IRoleListProps } from "Components/Role/interfaces-role";

export default function RoleContainer(props: IRoleListProps) {

    return (
        <React.Fragment>
            <RoleList
                roleList={props.roleList}
                appList={props.appList}
                getRoles={props.getRoles}
                client={props.client}
            />
        </React.Fragment>
    )
}