import * as React from "react";
import { IMenuProps } from "Components/Menu/interfaces-menu";
import { menuContent } from "Components/Menu/menu-content";
import SvgExpandMenu from "Components/Images/Menu/image-expand-menu";
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { RouteSystemCustomers } from "Configuration/Routes";
import { cloneDeep } from "lodash";

export default function MainMenu(props: IMenuProps) {
  const [selectedSubMenu, setSelectedSubMenu] = React.useState<any>(undefined);
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState<boolean>(false);
  const [isButtonMenuOver, setIsButtonMenuOver] = React.useState<boolean>(
    false
  );

  const handleClose = () => {
    if (!isButtonMenuOver) {
      setSelectedSubMenu(undefined);
      setIsSubMenuOpen(false);
      setIsButtonMenuOver(false);
    }
  };

  const handleClickMenuElement = (event: any) => {
    setIsButtonMenuOver(true);
    setSelectedSubMenu(event.currentTarget);
    setIsSubMenuOpen(true);
  };

  const handleButtonLeave = (event: any) => {
    setIsButtonMenuOver(false);
  };

  // remove 'Customers' menu item if client.wsCustomerUrl is empty
  let menuItems = cloneDeep(menuContent);
  if (props.client && !props.client.wsCustomerUrl) {
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].subMenu && menuItems[i].subMenu!.length > 0) {
        for (let j = 0; j < menuItems[i].subMenu!.length; j++) {
          if ((menuItems[i].subMenu![j]?.path ?? "") === RouteSystemCustomers) {
            menuItems[i].subMenu?.splice(j, 1);
          }
        }
      }
    }
  }

  return (
    <div style={{ width: "100%" }}>
      {menuItems.map((m) => {
        return (
          <React.Fragment key={m.name}>
            <Button
              className={
                "main-header-button NavLink" + (!!m.disabled ? " disabled" : "")
              }
              id={m.name + "-button"}
              aria-controls={m.name + "-menu"}
              aria-haspopup="true"
              onMouseOver={
                m.subMenu !== undefined && m.subMenu.length > 0
                  ? handleClickMenuElement
                  : () => {}
              }
              onMouseLeave={handleButtonLeave}
            >
              {!m.disabled ? (
                <NavLink
                  className={"NavLink" + (m.path ? "" : " disabled-link")}
                  to={m.path ? m.path : ""}
                >
                  <React.Fragment>
                    {m.name}
                    {m.subMenu !== undefined && m.subMenu.length > 0
                      ? SvgExpandMenu
                      : null}
                  </React.Fragment>
                </NavLink>
              ) : (
                <React.Fragment>
                  {m.name}
                  {m.subMenu !== undefined && m.subMenu.length > 0
                    ? SvgExpandMenu
                    : null}
                </React.Fragment>
              )}
            </Button>
            {m.subMenu !== undefined ? (
              <Popper
                id={m.name + "-menu"}
                anchorEl={selectedSubMenu}
                placement="bottom-start"
                open={
                  isSubMenuOpen && selectedSubMenu
                    ? selectedSubMenu.id === m.name + "-button"
                    : false
                }
                transition
                disablePortal
                onMouseLeave={handleClose}
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: "center center",
                    }}
                  >
                    <Paper
                      square
                      style={
                        m.paperProps !== undefined &&
                        m.paperProps.style !== undefined
                          ? m.paperProps.style
                          : undefined
                      }
                    >
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList>
                          {m.subMenu.map((s) => (
                            <MenuItem
                              key={s?.name ?? ""}
                              onClick={handleClickMenuElement}
                              disabled={s?.disabled}
                            >
                              <NavLink
                                className="NavLinkSubMenuElement"
                                to={s?.path ?? ""}
                              >
                                {s?.name ?? ""}
                              </NavLink>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            ) : null}
          </React.Fragment>
        );
      })}
    </div>
  );
}
