import * as React from "react";
import AppFilters from "Components/AppList/AppFilters/app-filters";
import { cloneDeep } from "lodash";
import { MobileAppVersionType } from "Types/app-types";
import { IAppListProps } from "Components/AppList/interfaces-app-list";
import moment from "moment";
import { AppPlatformEnum } from "Common/enum";
import AppList from "./app-list";

export default function AppListContainer(props: IAppListProps) {
  const [searchValue, setSearchValue] = React.useState("");
  const [dateFrom, setDateFrom] = React.useState<Date | null>(null);
  const [dateTo, setDateTo] = React.useState<Date | null>(null);
  const [isDistributeChecked, setDistributeChecked] = React.useState(false);
  const [filteredVersionList, setFilteredVersionList] =
    React.useState<MobileAppVersionType[]>();

  React.useEffect(() => {
    setFilteredVersionList(props.versionList);
  }, [props.versionList]);

  const handleSearchValueChange = (
    value: React.SetStateAction<string>
  ): void => {
    let searchedText: string = value.toString().toUpperCase();
    searchedText = searchedText ? searchedText : " ";
    let versions: MobileAppVersionType[] = searchByText(
      cloneDeep(props.versionList),
      searchedText
    );
    versions = searchByDistributable(versions);
    versions = searchByDates(versions);
    setSearchValue(searchedText);
    setFilteredVersionList(versions);
  };

  const handleDistributableChecked = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let versions: MobileAppVersionType[];
    if (event.target.checked) {
      versions = props.versionList.filter((x) => x.availableToInstall);
    } else {
      versions = props.versionList;
    }
    versions = searchByDates(versions);
    versions = searchByText(versions);
    setDistributeChecked(event.target.checked);
    setFilteredVersionList(versions);
  };

  const handleDateFromChange = (
    value: React.SetStateAction<Date | null>
  ): void => {
    let versions: MobileAppVersionType[];
    if (value) {
      const date = value as Date;
      versions = searchByDateFrom(props.versionList, date);
    } else {
      versions = props.versionList;
    }
    versions = searchByDistributable(versions);
    versions = searchByDateTo(versions);
    versions = searchByText(versions);
    setDateFrom(value);
    setFilteredVersionList(versions);
  };

  const handleDateToChange = (
    value: React.SetStateAction<Date | null>
  ): void => {
    let versions: MobileAppVersionType[];
    if (value) {
      const date = value as Date;
      versions = searchByDateTo(props.versionList, date);
    } else {
      versions = props.versionList;
    }
    versions = searchByDistributable(versions);
    versions = searchByDateFrom(versions);
    versions = searchByText(versions);
    setDateTo(value);
    setFilteredVersionList(versions);
  };

  const searchByText = (
    list: MobileAppVersionType[],
    text?: string
  ): MobileAppVersionType[] => {
    text = text ? text : searchValue;
    if (text) {
      list = list.filter(
        (x) =>
          `Release ${x.versionNo.toString()}`.toUpperCase().includes(text!) ||
          (x.releaseNotes || "").toUpperCase().includes(text!)
      );
    }
    return list;
  };

  const searchByDateFrom = (
    list: MobileAppVersionType[],
    date?: Date | null
  ): MobileAppVersionType[] => {
    date = date ? date : dateFrom;
    if (date) {
      list = list.filter(
        (x) =>
          moment(x.releaseDate).startOf("day") >= moment(date!).startOf("day")
      );
    }
    return list;
  };

  const searchByDateTo = (
    list: MobileAppVersionType[],
    date?: Date | null
  ): MobileAppVersionType[] => {
    date = date ? date : dateTo;
    if (date) {
      list = list.filter(
        (x) =>
          moment(x.releaseDate).startOf("day") <= moment(date!).startOf("day")
      );
    }
    return list;
  };

  const searchByDates = (
    list: MobileAppVersionType[]
  ): MobileAppVersionType[] => {
    list = searchByDateFrom(list);
    list = searchByDateTo(list);
    return list;
  };

  const searchByDistributable = (
    list: MobileAppVersionType[]
  ): MobileAppVersionType[] => {
    if (isDistributeChecked) {
      list = list.filter((x) => x.availableToInstall);
    }
    return list;
  };

  const showReadyToDistributionFlag =
    props.platformType === AppPlatformEnum.Android ||
    props.platformType === AppPlatformEnum.IOS;
  return (
    <>
      <AppFilters
        onSearchChange={handleSearchValueChange}
        dateFrom={dateFrom}
        dateTo={dateTo}
        isDistributableChecked={
          showReadyToDistributionFlag ? isDistributeChecked : false
        }
        onDateFromChange={handleDateFromChange}
        onDateToChange={handleDateToChange}
        onDistributableChecked={handleDistributableChecked}
        showReadyToDistributionFlag={showReadyToDistributionFlag}
      />
      <AppList
        appName={props.appName}
        platformType={props.platformType}
        versionList={filteredVersionList || []}
        isAnyVersionWithForceUpdate={props.isAnyVersionWithForceUpdate}
        handleRefreshApp={props.handleRefreshApp}
      />
    </>
  );
}
