import * as React from 'react';

const SvgOptions = ({
  style = {},
  fill = 'none',
  width = '100%',
  className = '',
  viewBox = '0 0 28 22',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M6 1H28" stroke="black" />
    <path d="M6 11H28" stroke="black" />
    <circle cx="1" cy="21" r="1" fill="black" />
    <circle cx="1" cy="10.7773" r="1" fill="black" />
    <circle cx="1" cy="1" r="1" fill="black" />
    <path d="M6 21H28" stroke="black" />
  </svg>
);

export default SvgOptions;
