import * as React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { ClientType } from "Types/client-types";
import { FormEditorEnum } from "Common/enum";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@material-ui/core";
import { IClientEditorProps } from "../interfaces-client";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { AxiosError } from "axios";
import { customAlphabet } from "nanoid";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import ButtonEdit from "Components/Buttons/ButtonEdit/button-edit";
import ButtonTransparent from "Components/Buttons/button-transparent";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
//import { validate as validateUuid } from 'uuid';

const NAME_MAX_LENGTH: number = 50;
const WS_CUSTOMER_URL_MAX_LENGTH: number = 500;
const WS_CUSTOMER_SECURITY_KEY_NAME_MAX_LENGTH: number = 50;
const WS_CUSTOMER_SECURITY_KEY_VALUE_MAX_LENGTH: number = 50;
const GUID_ALPHABET: string = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const GUID_SIZE: number = 8;

export default function ClientEditor(props: IClientEditorProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );

  // const randHex = (length: number): string => {
  //     const maxlen: number = 8;
  //     const min: number = Math.pow(16, Math.min(length, maxlen) - 1);
  //     const max: number = Math.pow(16, Math.min(length, maxlen)) - 1;
  //     const n: number = Math.floor(Math.random() * (max - min + 1)) + min;
  //     let r: string = n.toString(16);
  //     while (r.length < length) {
  //         r = r + randHex(length - maxlen)
  //     }
  //     return r;
  // }

  const validateGuid = (uuid: string) => {
    return /^[a-zA-Z0-9]+$/.test(uuid);
  };

  const generateGuid = (): string => {
    const nanoid = customAlphabet(GUID_ALPHABET, GUID_SIZE);
    let guid: string;
    do {
      guid = nanoid();
    } while (!props.checkGuidUniqueness(guid));
    return guid;
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(false);
  };

  const handleCreate = async (client: ClientType) => {
    await props
      .handleSaveClient(client)
      .then(() => {
        setIsOpen(false);
      })
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  let button = <ButtonTransparent text="Add new" handleClick={handleOpen} />;
  let headerText = "New context";
  if (props.editorType === FormEditorEnum.Edit) {
    button = <ButtonEdit iconWidth="20" handleClick={handleOpen} />;
    headerText = "Edit context";
  }

  return (
    <React.Fragment>
      {button}
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          <TypographySubtitle text={headerText} margin="0px" />
        </DialogTitle>
        <Formik
          initialValues={{
            id: props.client ? props.client.id : 0,
            name: props.client ? props.client.name : "",
            guid: props.client ? props.client.guid : generateGuid(),
            wsCustomerUrl: props.client ? props.client.wsCustomerUrl : "",
            wsCustomerSecurityKeyName: props.client
              ? props.client.wsCustomerSecurityKeyName
              : "",
            wsCustomerSecurityKeyValue: props.client
              ? props.client.wsCustomerSecurityKeyValue
              : "",
          }}
          onSubmit={async (values) => {
            await handleCreate(values);
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .label("Name")
              .required("Required")
              .max(NAME_MAX_LENGTH),
            guid: Yup.string()
              .label("Guid")
              .required("Required")
              .trim()
              .max(GUID_SIZE)
              .transform((value, originalValue) => {
                return value !== null ? value.toUpperCase() : value;
              })
              .test("isGuid", "Invalid GUID format", (value) =>
                //validateUuid(value)
                validateGuid(value)
              )
              .test("UniqueTest", "Guid is not unique", (value) => {
                if (props.editorType === FormEditorEnum.Create) {
                  return props.checkGuidUniqueness(value);
                }
                return true;
              }),
            wsCustomerUrl: Yup.string()
              .label("WS customer URL")
              .url()
              .nullable(),
            wsCustomerSecurityKeyName: Yup.string()
              .label("WS security key name")
              .when("wsCustomerUrl", {
                is: (wsCustomerUrl) => wsCustomerUrl,
                then: Yup.string()
                  .required("Required WS security key name")
                  .min(5),
              })
              .nullable(),
            wsCustomerSecurityKeyValue: Yup.string()
              .label("WS security key value")
              .when("wsCustomerUrl", {
                is: (wsCustomerUrl) => wsCustomerUrl,
                then: Yup.string()
                  .required("Required WS security key value")
                  .min(5),
              })
              .nullable(),
          })}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = formProps;
            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <Grid container sm={12} spacing={2}>
                    <Grid item sm={12}>
                      <TextFieldEntered
                        id="name"
                        name="name"
                        label="Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.name && touched.name)}
                        helperText={
                          (errors.name && touched.name && errors.name) ||
                          `${values.name.length}/${NAME_MAX_LENGTH}`
                        }
                        inputProps={{
                          maxLength: NAME_MAX_LENGTH,
                        }}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextFieldEntered
                        id="guid"
                        name="guid"
                        label="GUID"
                        value={values.guid.toUpperCase()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.guid && touched.guid)}
                        helperText={
                          (errors.guid && touched.guid && errors.guid) ||
                          `${values.guid.length}/${GUID_SIZE}`
                        }
                        inputProps={{
                          maxLength: GUID_SIZE,
                        }}
                        fullWidth
                        required
                        disabled={props.editorType === FormEditorEnum.Edit}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextFieldEntered
                        id="wsCustomerUrl"
                        name="wsCustomerUrl"
                        label="WS customer URL"
                        value={values.wsCustomerUrl ? values.wsCustomerUrl : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          !!(errors.wsCustomerUrl && touched.wsCustomerUrl)
                        }
                        helperText={
                          (errors.wsCustomerUrl &&
                            touched.wsCustomerUrl &&
                            errors.wsCustomerUrl) ||
                          `${
                            values.wsCustomerUrl
                              ? values.wsCustomerUrl.length
                              : 0
                          }/${WS_CUSTOMER_URL_MAX_LENGTH}`
                        }
                        inputProps={{
                          maxLength: WS_CUSTOMER_URL_MAX_LENGTH,
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextFieldEntered
                        id="wsCustomerSecurityKeyName"
                        name="wsCustomerSecurityKeyName"
                        label="WS security key name"
                        value={
                          values.wsCustomerSecurityKeyName
                            ? values.wsCustomerSecurityKeyName
                            : ""
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          !!(
                            errors.wsCustomerSecurityKeyName &&
                            touched.wsCustomerSecurityKeyName
                          )
                        }
                        helperText={
                          (errors.wsCustomerSecurityKeyName &&
                            touched.wsCustomerSecurityKeyName &&
                            errors.wsCustomerSecurityKeyName) ||
                          `${
                            values.wsCustomerSecurityKeyName
                              ? values.wsCustomerSecurityKeyName.length
                              : 0
                          }/${WS_CUSTOMER_SECURITY_KEY_NAME_MAX_LENGTH}`
                        }
                        inputProps={{
                          maxLength: WS_CUSTOMER_SECURITY_KEY_NAME_MAX_LENGTH,
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextFieldEntered
                        id="wsCustomerSecurityKeyValue"
                        name="wsCustomerSecurityKeyValue"
                        label="WS security key value"
                        value={
                          values.wsCustomerSecurityKeyValue
                            ? values.wsCustomerSecurityKeyValue
                            : ""
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          !!(
                            errors.wsCustomerSecurityKeyValue &&
                            touched.wsCustomerSecurityKeyValue
                          )
                        }
                        helperText={
                          (errors.wsCustomerSecurityKeyValue &&
                            touched.wsCustomerSecurityKeyValue &&
                            errors.wsCustomerSecurityKeyValue) ||
                          `${
                            values.wsCustomerSecurityKeyValue
                              ? values.wsCustomerSecurityKeyValue.length
                              : 0
                          }/${WS_CUSTOMER_SECURITY_KEY_VALUE_MAX_LENGTH}`
                        }
                        inputProps={{
                          maxLength: WS_CUSTOMER_SECURITY_KEY_VALUE_MAX_LENGTH,
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ButtonSubaction text="Cancel" handleClick={handleClose} />
                  <ButtonAction
                    isSubmit
                    text={
                      props.editorType === FormEditorEnum.Create
                        ? "Create"
                        : "Save"
                    }
                  />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </React.Fragment>
  );
}
