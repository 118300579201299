import * as React from 'react';
import * as _ from 'lodash';
import { Grid } from '@material-ui/core';
import { IWebAppHeaderProps } from 'Components/WebApp/interfaces-web-app';
import { AppPlatformEnum, FormEditorEnum } from 'Common/enum';
import { stylesWebAppHeader } from './styles';
import { SweetAlertResult } from 'sweetalert2';
import ImageAppLogo from 'Components/Image/ImageAppLogo/image-app-logo';
import TypographySubinfo from 'Components/Typography/typography-subinfo';
import TypographyTitle from 'Components/Typography/typography-title';
import WebServiceInstanceEditor from '../WebServiceList/web-service-instance-editor';
import AppVersionEditor from 'Components/AppVersionEditor/app-version-editor';
import AppEditorWeb from 'Components/App/AppEditor/app-editor-web';
import AppEditorWebService from 'Components/App/AppEditor/AppEditorWebService/app-editor-web-service';
import ClientAppApi from 'Services/Api/client-app-api';
import useModalError from 'Hooks/use-modal-error';
import useModalSuccess from 'Hooks/use-modal-success';
import ButtonSubaction from 'Components/Buttons/ButtonSubaction/button-subaction';
import HeaderCard from 'Components/HeaderCard/header-card';

export default function WebAppHeader(props: IWebAppHeaderProps) {
  const showModalError: (
    errorResult: string,
    isHtml?: boolean
  ) => Promise<SweetAlertResult> = useModalError();
  const showModalSuccess: (successResult: string) => Promise<SweetAlertResult> =
    useModalSuccess();
  const clientAppApi = new ClientAppApi();
  let customStyles = stylesWebAppHeader({});

  const handleCheckWS = async (): Promise<void> => {
    if (props.appId) {
      await clientAppApi
        .checkAppIsRespondingForEveryInstance(props.appId)
        .then((r) => {
          showModalSuccess('Service working properly');
        })
        .catch((error) => {
          if (error.response.data?.data) {
            showModalError(_.join(error.response.data.data, '</br>'), true);
          } else {
            showModalError('An error occured. Please, try again later');
          }
        });
    }
  };

  return (
    <HeaderCard>
      <Grid container>
        <Grid container item xs={6} alignItems="center" justify="flex-start">
          <Grid item className={customStyles.gridItem}>
            <ImageAppLogo
              guid={props.logoFile?.guid}
              width="60px"
              height="60px"
            />
          </Grid>
          <Grid item className={customStyles.gridItem}>
            <TypographyTitle text={props.appName} margin="10px 0 0 0" />
            <TypographySubinfo text={`GUID: ${props.appGuid}`} />
          </Grid>
          <Grid item className={customStyles.editButton}>
            {props.platformType === AppPlatformEnum.Web && (
              <AppEditorWeb
                clientId={props.clientId}
                id={props.appId}
                roleList={props.roleList}
                titleText={'Edit web app'}
                handleAfterSave={props.getWebApp}
              ></AppEditorWeb>
            )}
            {props.platformType === AppPlatformEnum.WebService && (
              <AppEditorWebService
                clientId={props.clientId}
                id={props.appId}
                roleList={props.roleList}
                titleText={'Edit web service'}
                handleAfterSave={props.getWebApp}
              ></AppEditorWebService>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={6} alignItems="center" justify="flex-end">
          <Grid item className={customStyles.gridItem}>
            {props.platformType === AppPlatformEnum.Web ? (
              <AppVersionEditor
                appId={props.appId}
                appName={props.appName}
                editorType={FormEditorEnum.Create}
                platformType={props.platformType}
                getMobileAppVersionList={props.getWebApp}
              />
            ) : (
              <>
                <ButtonSubaction
                  text="Check web service"
                  handleClick={handleCheckWS}
                />
                <WebServiceInstanceEditor
                  editorType={FormEditorEnum.Create}
                  webAppId={props.appId}
                  getWebApp={props.getWebApp}
                  platformType={props.platformType}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </HeaderCard>
  );
}
