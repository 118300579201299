import * as React from 'react';
import ApplicationUserHeader from 'Components/User/ApplicationUserHeader/application-user-header';
import ClientAppApi from 'Services/Api/client-app-api';
import ConfirmDialog from 'Components/Dialogs/ConfirmDialog/confirm-dialog';
import CountryApi from 'Services/Api/country-api';
import CustomerApi from 'Services/Api/customer-api';
import ErrorDialog from 'Components/Dialogs/ErrorDialog/error-dialog';
import InfoDialog from 'Components/Dialogs/InfoDialog/info-dialog';
import ProgressSpinner from 'Components/ProgressSpinner/progress-spinner';
import RoleApi from 'Services/Api/role-api';
import UserApi from 'Services/Api/user-api';
import UserLoginList from 'Components/User/UserLogin/user-login-list';
import { AppType } from 'Types/app-types';
import { AxiosError } from 'axios';
import { Container } from '@material-ui/core';
import { flatten } from 'lodash';
import { GetCountryListResponseType } from 'Types/country-types';
import { GetCustomerListResponseType } from 'Types/customer-types';
import { GetRolesForAppApiResponseType } from 'Types/role-types';
import { IApplicationUserDetails } from 'Components/User/interfaces-user';
import { useParams } from 'react-router-dom';
import {
  GetUserLoginListResponseType,
  SaveUserLoginModelType,
  GetUserListResponseType,
  UserLoginEditor,
  DeleteUserLoginModelType,
  SendUserLoginResetCodeModelType,
} from 'Types/user-types';
import {
  SaveUserLoginApiResultType,
  DeleteUserLoginApiResultType,
  SendUserLoginResetCodeApiResultType,
} from 'Types/api-types';
import {
  ApiResultStatusEnum,
  UserLoginType,
  UserLoginIsDefaultForUser,
  AppPlatformEnum,
} from 'Common/enum';

const clientAppApi = new ClientAppApi();
const userApi = new UserApi();
const countryApi = new CountryApi();
const roleApi = new RoleApi();
const customerApi = new CustomerApi();

export default function ApplicationUserDetails(props: IApplicationUserDetails) {
  const [user, setUser] = React.useState<GetUserListResponseType>();
  const [applications, setApplications] = React.useState<AppType[]>([]);
  const [countries, setCountries] = React.useState<
    GetCountryListResponseType[]
  >([]);
  const [roles, setRoles] = React.useState<GetRolesForAppApiResponseType[]>([]);
  const [customers, setCustomers] = React.useState<
    GetCustomerListResponseType[]
  >([]);

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] =
    React.useState<boolean>(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [userLoginEditors, setUserLoginEditors] = React.useState<
    UserLoginEditor[]
  >([]);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = React.useState(false);
  const [infoText, setInfoText] = React.useState('');
  const [isBlockedUi, setIsBlockedUi] = React.useState(false);

  const params = useParams<{ userID?: string; appGuid?: string }>();

  React.useEffect(() => {
    const getApplications = async (): Promise<void> => {
      if (props.client?.id && props.client.id > 0) {
        await clientAppApi
          .getAllAppList(props.client.id, true)
          .then((result) => {
            if (result?.data) {
              setApplications(
                result.data.filter(
                  (a) => a.platformType !== AppPlatformEnum.WebService
                )
              );
            }
          });
      }
    };
    getApplications();
  }, [props.client]);

  React.useEffect(() => {
    const getCountries = async (): Promise<void> => {
      await countryApi.getList().then((result) => {
        if (result && result.data) {
          setCountries(result.data);
        }
      });
    };
    getCountries();
  }, []);

  React.useEffect(() => {
    if (applications.length) {
      let appID: number =
        applications.find((app) => app.guid === params.appGuid)?.id ?? 0;
      if (appID > 0) {
        const getRoles = async (): Promise<void> => {
          await roleApi.getRolesForApp(appID).then((result) => {
            if (result?.data) {
              setRoles(result.data);
            }
          });
        };
        getRoles();
      }
    }
  }, [applications, params.appGuid]);

  const handleErrorDialogOkClick = (): void => {
    setIsErrorDialogOpen(false);
  };

  const handleInfoDialogOkClick = (): void => {
    setIsInfoDialogOpen(false);
  };

  const handleCheckForChangesDialogClose = (): void => {
    setIsOpenConfirmDialog(false);
  };

  const handleCheckForChangesDialogOkClick = (): void => {
    setIsOpenConfirmDialog(false);
  };

  const handleDeleteUserLogin = (userLoginID: number | null) => {
    if (userLoginID) {
      deleteUserLogin({ userLoginID: userLoginID })
        .then(() => {
          setUserLoginEditors(
            [...userLoginEditors].filter(
              (x) => x.userLogin.userLoginID !== userLoginID
            )
          );
        })
        .catch((error) => {
          console.error(error);
          setErrorResponse(error);
          setIsErrorDialogOpen(true);
        });
    } else {
      setUserLoginEditors(
        [...userLoginEditors].filter((x) => x.userLogin.userLoginID !== null)
      );
    }
  };

  const deleteUserLogin = (
    userLogin: DeleteUserLoginModelType
  ): Promise<DeleteUserLoginApiResultType | void> => {
    return userApi
      .deleteUserLogin(userLogin)
      .then(() => {})
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const handleSaveUserLogin = (saveUserLoginModel: SaveUserLoginModelType) =>
    saveUserLogin(saveUserLoginModel)
      .then(() => {})
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });

  const handleSendUserLoginResetCode = (
    userLoginResetCode: SendUserLoginResetCodeModelType
  ) => {
    setIsBlockedUi(true);
    sendUserLoginResetCode(userLoginResetCode)
      .then(() => {})
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      })
      .finally(() => {
        setIsBlockedUi(false);
      });
  };

  const getUserDetails = async (): Promise<void> => {
    setIsBlockedUi(true);
    await userApi
      .getUserDetails(Number(params.userID), params.appGuid!)
      .then((result) => {
        if (result && result.data) {
          setUser({
            userID: parseInt(params.userID!),
            canDelete: false,
            firstName: result.data.firstName,
            lastName: result.data.lastName,
            customers: flatten(
              result.data.userLogins.map(
                (login) =>
                  login.customers?.map((c) => ({
                    customerID: c.customerID,
                    roleID: c.roleID,
                  })) ?? []
              )
            ),
            hasLogins: false,
          });
          const userLoginEditorList: UserLoginEditor[] =
            result.data.userLogins.map((userLogin) => ({
              userLogin: userLogin,
              isOpen: false,
            }));
          setUserLoginEditors(userLoginEditorList);
        }
      })
      .finally(() => {
        setIsBlockedUi(false);
      });
  };

  React.useEffect(() => {
    if (applications.length) {
      let appID: number =
        applications.find((app) => app.guid === params.appGuid)?.id ?? 0;
      if (appID > 0) {
        const getRoles = async (): Promise<void> => {
          await roleApi.getRolesForApp(appID).then((result) => {
            if (result?.data) {
              setRoles(result.data);
            }
          });
        };
        getRoles();
      }
    }
  }, [applications, params.appGuid]);

  React.useEffect(() => {
    if (props.client.id) {
      customerApi.getList(props.client.id).then((result) => {
        if (result?.status === ApiResultStatusEnum.Ok && result?.data) {
          setCustomers(result.data);
        }
      });
    }
  }, [props.client.id]);

  React.useEffect(() => {
    if (params.appGuid && !Number.isNaN(Number(params.userID))) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.userID, params.appGuid]);

  const handleOpenUserLogin = (userLogin: GetUserLoginListResponseType) => {
    const newUserLoginEditorList = userLoginEditors.map((e) =>
      e.userLogin === null || e.userLogin.userLoginID === userLogin.userLoginID
        ? ({
            ...e,
            isOpen: !e.isOpen,
          } as UserLoginEditor)
        : e
    );
    setUserLoginEditors(newUserLoginEditorList);
  };

  const addNewUserLogin = (): void => {
    if (
      !Number.isNaN(Number(params.userID)) &&
      !userLoginEditors.some(
        (userLoginEditor) => userLoginEditor.userLogin.userLoginID == null
      )
    ) {
      let userLoginEditor: UserLoginEditor = {
        userLogin: {
          userLoginID: null,
          userID: parseInt(params.userID!),
          loginType: UserLoginType.None,
          email: '',
          phonePrefix: '',
          countryID: null,
          phoneNumber: '',
          isDefaultForUser: UserLoginIsDefaultForUser.NotDefaultForUser,
          apps: [],
        },
        isOpen: true,
      };
      setUserLoginEditors([...userLoginEditors, userLoginEditor]);
    }
  };

  const saveUserLogin = (
    saveUserLoginModel: SaveUserLoginModelType
  ): Promise<SaveUserLoginApiResultType | void> => {
    return userApi
      .saveUserLogin({
        ...saveUserLoginModel,
      })
      .then((result) => {
        if (result && result.status === ApiResultStatusEnum.Ok) {
          getUserDetails();
        }
      });
  };

  const sendUserLoginResetCode = (
    sendUserLoginResetCode: SendUserLoginResetCodeModelType
  ): Promise<SendUserLoginResetCodeApiResultType | void> => {
    return userApi
      .sendUserLoginResetCode(sendUserLoginResetCode)
      .then((result) => {
        if (result && result.status === ApiResultStatusEnum.Ok) {
          showInfoMessage('Reset code was sent successfully.');
        }
      });
  };

  const showInfoMessage = (infoText: string) => {
    setInfoText(infoText);
    setIsInfoDialogOpen(true);
  };

  return params.appGuid &&
    params.userID &&
    !Number.isNaN(Number(params.userID)) ? (
    <ProgressSpinner isDisplayed={isBlockedUi}>
      <Container style={{ marginTop: 15 }}>
        <ApplicationUserHeader
          user={user ?? null}
          appGuid={params.appGuid}
          handleCreateNewUserLogin={addNewUserLogin}
          roleList={roles}
          customerList={customers}
          client={props.client}
          showInfoMessage={showInfoMessage}
        />
        <UserLoginList
          appGuid={params.appGuid}
          userID={Number(params.userID)}
          appList={applications}
          countryList={countries}
          customerList={customers}
          userLoginEditorList={userLoginEditors}
          handleSaveUserLogin={handleSaveUserLogin}
          handleOpenUserLogin={handleOpenUserLogin}
          handleDeleteUserLogin={handleDeleteUserLogin}
          handleSendUserLoginResetCode={handleSendUserLoginResetCode}
          showInfoMessage={showInfoMessage}
        />

        <ConfirmDialog
          text="Your unsaved data will be lost. Do you want to continue?"
          isOpen={isOpenConfirmDialog}
          handleOkClick={handleCheckForChangesDialogOkClick}
          handleCancelClick={handleCheckForChangesDialogClose}
        />
        <ErrorDialog
          isOpen={isErrorDialogOpen}
          error={errorResponse}
          handleOkClick={handleErrorDialogOkClick}
        />
        <InfoDialog
          isOpen={isInfoDialogOpen}
          text={infoText}
          handleOkClick={handleInfoDialogOkClick}
        />
      </Container>
    </ProgressSpinner>
  ) : (
    <></>
  );
}
