import * as React from "react";
import { IDeviceAppHeaderProps } from "Components/Device/interfaces-device";
import { Grid } from "@material-ui/core";
import TypographyTitle from "Components/Typography/typography-title";
import { stylesDeviceHeader } from "Components/Device/DeviceHeader/styles";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import ImageOSIcon from "Components/Image/ImageOSIcon/image-os-icon";
import ImageAppLogo from "Components/Image/ImageAppLogo/image-app-logo";
import HeaderCard from "Components/HeaderCard/header-card";

export default function DeviceHeader(props: IDeviceAppHeaderProps) {
  const customStyles = stylesDeviceHeader({});

  return (
    <HeaderCard>
      <Grid container>
        <Grid container item sm={6} alignItems="center" justify="flex-start">
          <Grid item>
            <TypographyTitle text={props.text} />
          </Grid>
        </Grid>
        <Grid container item sm={6} alignItems="center" justify="flex-end">
          <Grid item className={customStyles.gridItem}>
            <ImageAppLogo
              guid={props.logoFile?.guid}
              width="45px"
              height="45px"
            />
          </Grid>
          <Grid item>
            <TypographySubtitle text={props.mobileAppName} />
          </Grid>
          <Grid item className={customStyles.osIcon}>
            <ImageOSIcon platformType={props.platformType} width="30px" />
          </Grid>
        </Grid>
      </Grid>
    </HeaderCard>
  );
}
