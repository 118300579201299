import * as React from "react";
import ButtonIcon from "Components/Buttons/button-icon";
import MobileAppMainList from "Components/MobileApp/MobileAppMainList/mobile-app-main-list";
import SvgOptions from "Components/Images/image-options";
import TypographyTitle from "Components/Typography/typography-title";
import { Paper, Grid, Divider } from "@material-ui/core";
import { IMobileAppCardProps } from "../interfaces-mobile-app";
import { IconType, FormEditorEnum } from "Common/enum";
import { Link } from "react-router-dom";
import { stylesMobileAppCard } from "Components/MobileApp/MobileAppCard/styles";
import ImageAppLogo from "Components/Image/ImageAppLogo/image-app-logo";
import ImageOSIcon from "Components/Image/ImageOSIcon/image-os-icon";
import AppVersionEditor from "Components/AppVersionEditor/app-version-editor";
import ClientAppApi from "Services/Api/client-app-api";
import TypographySubinfo from "Components/Typography/typography-subinfo";

const clientAppApi = new ClientAppApi();

export default function MobileAppCard(props: IMobileAppCardProps) {
  const [versionList, setVersionList] = React.useState(
    props.mobileApp.versionList
  );
  const [isAppLogoLoaded, setIsAppLogoLoaded] = React.useState<boolean>(true);

  let customStyles = stylesMobileAppCard();

  const getMobileAppVersionList = async (): Promise<void> => {
    await clientAppApi
      .getMobileAppVersionList(props.mobileApp.id, 3)
      .then((result) => {
        if (result && result.data) {
          setVersionList(result.data);
        }
      });
  };

  return (
    <Paper className={customStyles.paper}>
      <Grid container spacing={1}>
        <Grid container item>
          {props.mobileApp.logoFile?.guid && isAppLogoLoaded ? (
            <Grid
              item
              xs={2}
              container
              alignContent="center"
              alignItems="center"
            >
              <ImageAppLogo
                guid={props.mobileApp.logoFile?.guid}
                width="45px"
                height="45px"
                setAppLogoNotLoaded={() => setIsAppLogoLoaded(false)}
              />
            </Grid>
          ) : (
            ""
          )}

          <Grid
            item
            xs={props.mobileApp.logoFile?.guid && isAppLogoLoaded ? 8 : 10}
          >
            <TypographyTitle
              text={props.mobileApp.name}
              margin="0"
              style={{ fontSize: "28px" }}
            />
          </Grid>
          <Grid item xs={2} className={customStyles.textRight}>
            <ImageOSIcon
              platformType={props.mobileApp.platformType}
              width="35px"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
          <TypographySubinfo text={`GUID: ${props.mobileApp.guid}`} />
          <Divider variant="fullWidth" className={customStyles.dividerBottom} />
        </Grid>
        <Grid item>
          <AppVersionEditor
            appId={props.mobileApp.id}
            appName={props.mobileApp.name}
            buttonSize="sm"
            editorType={FormEditorEnum.Create}
            isAnyVersionWithForceUpdate={
              props.mobileApp.isAnyVersionWithForceUpdate ?? false
            }
            getMobileAppVersionList={getMobileAppVersionList}
            platformType={props.mobileApp.platformType}
          />
          <Link to={`/mobile-application/${props.mobileApp.id}`}>
            <ButtonIcon
              iconType={IconType.SvgImageIcon}
              icon={<SvgOptions width="25px" />}
            />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" className={customStyles.dividerBottom} />
          <MobileAppMainList versionList={versionList} />
        </Grid>
      </Grid>
    </Paper>
  );
}
