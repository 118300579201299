import * as React from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortableHandle } from 'react-sortable-hoc';
import { stylesWebServiceListDragHandle } from './styles';

export const WebServiceListDragHandle = SortableHandle(() => {
  const customStyles = stylesWebServiceListDragHandle({});

  return (
    <div className={customStyles.dragWrapper}>
      <FontAwesomeIcon icon={faBars} className={customStyles.dragIcon} />
    </div>
  );
});
