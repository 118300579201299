import { makeStyles } from "@material-ui/core";
import Colors from "Common/colors";

export const stylesAccessRightList = makeStyles({
  gridItem: {
    padding: "0 5px",
  },
  gridItemGroupName: {
    padding: "0 0 0 30px",
  },
});

export const stylesAccessRightListContainer = makeStyles({
  divNoPermissionGroups: {
    textAlign: "center",
  },
});

export const stylesAccessRightListItem = makeStyles({
  paper: {
    padding: "20px 30px",
    marginBottom: "15px",
  },
  segmented: {
    backgroundColor: Colors.graySeparate,
    borderColor: `${Colors.graySeparate} !important`,
  },
  segmentedButton: {
    padding: "5px 10px !important",
    width: "auto",
  },
  segmentedButtonSelected: {
    color: `${Colors.black} !important`,
  },
  segmentedButtonLabel: {
    fontSize: "12px",
    textTransform: "none",
  },
});
