import { AppCheckRespondingTypeEnum } from 'Common/enum-app';
import * as React from 'react';
import EndpointAvailable from './endpoint-available';

interface UsersEndpointProps {
  handleCheckWS: (
    checkRespondingType: AppCheckRespondingTypeEnum
  ) => Promise<void>;
}

const UsersEndpoint: React.FC<UsersEndpointProps> = ({ handleCheckWS }) => (
  <EndpointAvailable
    handleCheckWS={() => handleCheckWS(AppCheckRespondingTypeEnum.User)}
    isAvailableKey="isUsersEndpointsAvailable"
    apiMethodPath="apiUserMethodPath"
    checkboxLabel="Users endpoints available"
    apiMethodPathLabel="Api user method path"
    isLoginDataType
  />
);

export default UsersEndpoint;
