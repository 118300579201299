import * as React from "react";
import { IAccessRightsFiltersReportsProps } from "Components/AccessRight/interfaces-access-right";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import InputCheckbox from "Components/Inputs/input-checkbox";
import TypographyInfo from "Components/Typography/typography-info";
import SearchField from "Components/Inputs/SearchField/search-field";
import FilterCard from "Components/FilterCard/filter-card";

const AccessRightsFiltersReports: (
  props: IAccessRightsFiltersReportsProps
) => JSX.Element = (props: IAccessRightsFiltersReportsProps): JSX.Element => {
  const getSelectedGroupNames = (selectedGroupsIds: number[]): string => {
    let selectedGroupNames: string[] = [];
    props.reportContainers.forEach((group) => {
      if (selectedGroupsIds.includes(group.reportContainerID)) {
        selectedGroupNames.push(group.reportContainerName);
      }
    });

    return selectedGroupNames.join(", ");
  };

  return (
    <FilterCard>
      <Grid item container justify="flex-start" spacing={4}>
        <Grid item sm={3}>
          <SearchField onChangeEvent={props.onSearchChange} />
        </Grid>
        <Grid item sm={2}>
          <FormControl>
            <InputLabel>Report containers</InputLabel>
            <Select
              multiple
              value={props.selectedReportContainers}
              onChange={props.onSelectedReportContainersChange}
              renderValue={(selected) =>
                props.selectAllReportContainers
                  ? "All"
                  : getSelectedGroupNames(selected as number[])
              }
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <MenuItem value={0}>
                <InputCheckbox checked={props.selectAllReportContainers} />
                <ListItemText primary={<TypographyInfo text="Select all" />} />
              </MenuItem>
              <Divider variant="middle" />
              {props.reportContainers.map((reportContainer) => (
                <MenuItem
                  key={reportContainer.reportContainerID}
                  value={reportContainer.reportContainerID}
                >
                  <InputCheckbox
                    checked={
                      props.selectedReportContainers.indexOf(
                        reportContainer.reportContainerID
                      ) > -1
                    }
                  />
                  <ListItemText
                    primary={
                      <TypographyInfo
                        text={reportContainer.reportContainerName}
                      />
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </FilterCard>
  );
};

export default AccessRightsFiltersReports;
