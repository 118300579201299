import * as React from 'react';
import { Grid } from '@material-ui/core';
import { IAppFiltersProps } from '../interfaces-app-list';
import InputDateRange from 'Components/Inputs/input-date-range';
import InputCheckbox from 'Components/Inputs/input-checkbox';
import SearchField from 'Components/Inputs/SearchField/search-field';
import FilterCard from 'Components/FilterCard/filter-card';

export default function AppFilters(props: IAppFiltersProps) {
  return (
    <FilterCard>
      <Grid container justify="flex-start" alignItems="center" spacing={4}>
        <Grid item sm={2}>
          <SearchField onChangeEvent={props.onSearchChange} />
        </Grid>
        {props.showReadyToDistributionFlag ? (
          <Grid item sm={2}>
            <InputCheckbox
              checked={props.isDistributableChecked}
              onChange={props.onDistributableChecked}
              text="Ready to distribute"
              margin="0 0 -16px 0"
            />
          </Grid>
        ) : null}
        <Grid container item sm={4} spacing={4}>
          <InputDateRange
            dateFrom={props.dateFrom}
            dateTo={props.dateTo}
            dateFromLabel="Date from"
            dateToLabel="Date to"
            onDateFromChange={props.onDateFromChange}
            onDateToChange={props.onDateToChange}
          />
        </Grid>
      </Grid>
    </FilterCard>
  );
}
