import { createMuiTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    hoverColor: Palette["primary"];
    grayDarker: Palette["primary"];
    graySeparate: Palette["primary"];
    grayLight: Palette["primary"];
  }
  interface PaletteOptions {
    hoverColor: PaletteOptions["primary"];
    grayDarker: PaletteOptions["primary"];
    graySeparate: PaletteOptions["primary"];
    grayLight: PaletteOptions["primary"];
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#444444",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#AEAEAE",
      contrastText: "#FFFFFF",
    },
    hoverColor: {
      main: "#636363",
    },
    grayDarker: {
      main: "#6F6F6F",
    },
    graySeparate: {
      main: "#DCDCDC",
    },
    grayLight: {
      main: "#F8F8F8",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiDialogTitle: {
      root: {
        display: "flex",
        alignItems: "center",
        marginLeft: "40px",
        marginRight: "40px",
        height: "64px",
        padding: "0px",
      },
    },
    MuiDialogContent: {
      root: {
        composes: "$dividers",
        "&:first-child": {
          padding: "18px 40px 18px 40px",
          overflow: "hidden",
        },
        padding: "18px 40px 18px 40px",
        overflow: "hidden",
      },
      dividers: {
        padding: "18px 40px 18px 40px",
        overflow: "hidden",
      },
    },
    MuiDialogActions: {
      root: {
        "&>:first-child": {
          marginRight: "12px",
        },
        padding: "34px 40px 25px 40px",
      },
    },
    MuiSelect: {
      root: {
        paddingBottom: "4px",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "14px",
        color: "#AEAEAE",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiInput: {
      formControl: {
        color: "#444444",
      },
    },
  },
});

export default theme;
