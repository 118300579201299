import * as React from "react";
import { FormControl, Grid, InputLabel } from "@material-ui/core";
import { Select, MenuItem } from "@material-ui/core";
import { IUserFiltersProps } from "Components/User/interfaces-user";
import { UserActiveType } from "Common/enum";
import SearchField from "Components/Inputs/SearchField/search-field";
import FilterCard from "Components/FilterCard/filter-card";

export default function UserFilters(props: IUserFiltersProps) {
  const selectedRoleID =
    props.selectedRoleID === 0 ||
    props.roleList.find((r) => r.roleID === props.selectedRoleID)
      ? props.selectedRoleID
      : props.roleList.length > 0
      ? props.roleList[0].roleID
      : 0;

  return (
    <FilterCard>
      <Grid container justify="flex-start" alignItems="flex-end" spacing={4}>
        <Grid item sm={2}>
          <SearchField onChangeEvent={props.onSearchChange} />
        </Grid>
        {!props.systemAppSelected && (
          <Grid container item sm={2}>
            <FormControl>
              <InputLabel>Role</InputLabel>
              <Select
                id="type-select"
                value={selectedRoleID}
                onChange={props.onRoleChange}
                style={{ width: 180 }}
              >
                <MenuItem key="0" value={0}>
                  All
                </MenuItem>
                {props.roleList.map((role) => (
                  <MenuItem key={role.roleID} value={role.roleID}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid container item sm={2}>
          <FormControl>
            <InputLabel>Activity</InputLabel>
            <Select
              id="type-activity"
              value={props.selectedActivity}
              onChange={props.onActivityChange}
            >
              <MenuItem value={UserActiveType.Active}>Active</MenuItem>
              <MenuItem value={UserActiveType.NotActive}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </FilterCard>
  );
}
