import * as React from "react";
import { makeStyles, InputAdornment, TextField } from "@material-ui/core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IInputSearchProps {
  onChangeEvent: (value: React.SetStateAction<string>) => void;
  text?: string;
  marginLeft?: string;
  width?: string;
  preventEnterKey?: boolean;
}

const WAIT_INTERVAL = 300;

export default function InputSearch(props: IInputSearchProps) {
  const styles = makeStyles({
    inputAdornment: {
      color: "#444444",
    },
  });

  const customStyles = styles({});
  let search: string = "";
  let time: NodeJS.Timeout;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) => {
    e.preventDefault();
    clearTimeout(time);
    search = value;
    time = setTimeout(triggerChange, WAIT_INTERVAL);
  };

  const triggerChange = () => {
    props.onChangeEvent(search);
  };

  return (
    <TextField
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            className={customStyles.inputAdornment}
          >
            <FontAwesomeIcon icon={faSearch} />
          </InputAdornment>
        ),
      }}
      onChange={(e) => handleChange(e, e.target.value)}
      onKeyPress={(ev) => {
        if (props.preventEnterKey) {
          if (ev.key === "Enter") {
            // do nothing
            ev.preventDefault();
          }
        }
      }}
    />
  );
}
