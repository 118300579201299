import * as React from "react";
import { Formik } from "formik";
import { ApiResultStatusEnum, IconType } from "Common/enum";
import { SavePermissionGroupApiResultType } from "Types/api-types";
import ButtonOutlined from "Components/Buttons/button-outlined";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  TextField,
} from "@material-ui/core";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { AxiosError } from "axios";
import { IPermissionsGroupEditorProps } from "Components/Permission/interfaces-permission";
import TypographyInfo from "Components/Typography/typography-info";
import PermissionApi from "Services/Api/permission-api";
import {
  GetPermissionsApiResponseType,
  SavePermissionGroupModelType,
} from "Types/permission-types";
import SvgArrowRight from "Components/Images/image-arrow-right";
import SvgTrash from "Components/Images/image-trash";
import Colors from "Common/colors";
import { stylesPermissionsGroupListItem } from "./styles";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import SearchField from "Components/Inputs/SearchField/search-field";

const permissionApi = new PermissionApi();

export default function PermissionsGroupEditor(
  props: IPermissionsGroupEditorProps
) {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [permissionsAll, setPermissionsAll] =
    React.useState<GetPermissionsApiResponseType[]>();
  const [filteredPermissionsAll, setFilteredPermissionsAll] =
    React.useState<GetPermissionsApiResponseType[]>();
  const [permissionsSelected, setPermissionsSelected] =
    React.useState<GetPermissionsApiResponseType[]>();
  const [filteredPermissionsSelected, setFilteredPermissionsSelected] =
    React.useState<GetPermissionsApiResponseType[]>();
  const [permissionsCountAll, setPermissionsCountAll] = React.useState(0);
  const [permissionsCountSelected, setPermissionsCountSelected] =
    React.useState(0);
  const [checkedAll, setCheckedAll] = React.useState<number[]>([]);
  const [checkedSelected, setCheckedSelected] = React.useState<number[]>([]);
  const [groupName, setGroupName] = React.useState<string>(
    props.permissionGroup.name
  );

  const getPermissions = React.useCallback(async (): Promise<void> => {
    if (props.isOpen) {
      await permissionApi
        .getPermissions(props.permissionGroup.permissionGroupID)
        .then((result) => {
          if (result && result.data) {
            const allPerm: GetPermissionsApiResponseType[] = result.data.filter(
              (item) => !item.isSelected
            );
            const selectedPerm: GetPermissionsApiResponseType[] =
              result.data.filter((item) => item.isSelected);

            refresh(allPerm, selectedPerm, allPerm, selectedPerm);

            if (props.permissionGroup.name === "") {
              //for refresh editor when we added new group
              setGroupName("");
            }
          }
        });
    }
  }, [
    props.isOpen,
    props.permissionGroup.permissionGroupID,
    props.permissionGroup.name,
  ]);

  React.useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  React.useEffect(() => {
    setGroupName(props.permissionGroup.name);
  }, [props.permissionGroup.name]);

  const customStyles = stylesPermissionsGroupListItem();

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const searchByText = (
    list: GetPermissionsApiResponseType[],
    text?: string
  ): GetPermissionsApiResponseType[] => {
    if (text) {
      list = list.filter((x) => x.name.toLowerCase().includes(text));
    }
    return list;
  };

  const onSearchChangeAll = (value: React.SetStateAction<string>): void => {
    let searchedText: string = value.toString().toLowerCase();
    searchedText = searchedText ? searchedText : "";
    const permissions: GetPermissionsApiResponseType[] = searchByText(
      permissionsAll ? permissionsAll : [],
      searchedText
    );
    setFilteredPermissionsAll(permissions);
  };

  const handleToggleAll = (value: number) => {
    const currentIndex: number = checkedAll.indexOf(value);
    const newChecked: number[] = [...checkedAll];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedAll(newChecked);
  };

  const onSearchChangeSelected = (
    value: React.SetStateAction<string>
  ): void => {
    let searchedText: string = value.toString().toLowerCase();
    searchedText = searchedText ? searchedText : "";
    const permissions: GetPermissionsApiResponseType[] = searchByText(
      permissionsSelected ? permissionsSelected : [],
      searchedText
    );
    setFilteredPermissionsSelected(permissions);
  };

  const handleToggleSelected = (value: number) => {
    const currentIndex = checkedSelected.indexOf(value);
    const newChecked = [...checkedSelected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedSelected(newChecked);
  };

  const handleSubmit = () => {
    savePermissionGroup()
      .then(() => {})
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const savePermissionGroup =
    async (): Promise<SavePermissionGroupApiResultType | void> => {
      if (groupName.length === 0) {
        return;
      }
      const request: SavePermissionGroupModelType = {
        permissionGroupID: props.permissionGroup.permissionGroupID,
        name: groupName,
        permissions: permissionsSelected
          ? permissionsSelected.map((p) => p.permissionID)
          : [],
      };

      const result = await permissionApi.savePermissionGroup(request);
      if (result && result.status === ApiResultStatusEnum.Ok) {
        close();
        if (props.getPermissionGroups) {
          props.getPermissionGroups();
        }
      } else {
      }
    };

  const moveToSelected = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (
      filteredPermissionsAll &&
      filteredPermissionsSelected &&
      permissionsAll &&
      permissionsSelected
    ) {
      // add to selected list and remove from all list
      const allPerm: GetPermissionsApiResponseType[] = permissionsAll.filter(
        (item) => checkedAll.indexOf(item.permissionID) === -1
      );
      const movePerm: GetPermissionsApiResponseType[] =
        filteredPermissionsAll.filter(
          (item) => checkedAll.indexOf(item.permissionID) > -1
        );
      const selectedPerm: GetPermissionsApiResponseType[] =
        permissionsSelected.concat(movePerm);
      const allPermFiltered: GetPermissionsApiResponseType[] =
        filteredPermissionsAll.filter(
          (item) => checkedAll.indexOf(item.permissionID) === -1
        );
      const selectedPermFiltered: GetPermissionsApiResponseType[] =
        filteredPermissionsSelected.concat(movePerm);

      refresh(allPerm, selectedPerm, allPermFiltered, selectedPermFiltered);
    }
  };

  const deleteFromSelected = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (
      filteredPermissionsAll &&
      filteredPermissionsSelected &&
      permissionsAll &&
      permissionsSelected
    ) {
      // add to all list and remove from selected list
      const selectedPerm: GetPermissionsApiResponseType[] =
        permissionsSelected.filter(
          (item) => checkedSelected.indexOf(item.permissionID) === -1
        );
      const deletePerm: GetPermissionsApiResponseType[] =
        filteredPermissionsSelected.filter(
          (item) => checkedSelected.indexOf(item.permissionID) > -1
        );
      const allPerm: GetPermissionsApiResponseType[] =
        permissionsAll.concat(deletePerm);
      const selectedPermFiltered: GetPermissionsApiResponseType[] =
        filteredPermissionsSelected.filter(
          (item) => checkedSelected.indexOf(item.permissionID) === -1
        );
      const allPermFiltered: GetPermissionsApiResponseType[] =
        filteredPermissionsAll.concat(deletePerm);

      refresh(allPerm, selectedPerm, allPermFiltered, selectedPermFiltered);
    }
  };

  const refresh = (
    allPerm: GetPermissionsApiResponseType[],
    selectedPerm: GetPermissionsApiResponseType[],
    allPermFiltered: GetPermissionsApiResponseType[],
    selectedPermFiltered: GetPermissionsApiResponseType[]
  ): void => {
    setPermissionsAll(allPerm);
    setFilteredPermissionsAll(allPermFiltered);
    setPermissionsCountAll(allPermFiltered.length);
    setPermissionsSelected(selectedPerm);
    setFilteredPermissionsSelected(selectedPermFiltered);
    setPermissionsCountSelected(selectedPermFiltered.length);
    setCheckedAll([]);
    setCheckedSelected([]);
  };

  const handleChangeGroupName = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const text = event.currentTarget.value;
    setGroupName(text);
  };

  const close = () => {
    props.closeEditor();
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.isOpen}
        onEscapeKeyDown={close}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <TypographySubtitle
            text={`Edit ${props.permissionGroup.name} group`}
            margin="0px"
          />
        </DialogTitle>

        <Formik
          initialValues={{}}
          onSubmit={async (values) => {
            await handleSubmit();
          }}
        >
          {(formProps) => {
            const { handleSubmit } = formProps;
            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <Grid container item sm={12} alignItems="flex-start">
                    <Grid container item sm={6} direction="column">
                      <Grid item sm={12} alignItems="center">
                        <TypographyInfo
                          text={`All permissions (${permissionsCountAll})`}
                          color={Colors.black}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        sm={12}
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid container item sm={8} alignItems="center">
                          <SearchField
                            onChangeEvent={onSearchChangeAll}
                            preventEnterKey
                            marginTop="0px"
                          />
                        </Grid>
                        <Grid item sm={4} alignItems="flex-end">
                          <ButtonOutlined
                            text={
                              "Move" +
                              (checkedAll.length > 0
                                ? ` (${checkedAll.length})`
                                : "")
                            }
                            iconStartType={IconType.SvgImageIcon}
                            iconStart={
                              <SvgArrowRight
                                width={"14px"}
                                color={
                                  checkedAll.length > 0
                                    ? Colors.grayDark
                                    : "#AEAEAE"
                                }
                              />
                            }
                            size="md"
                            handleClick={moveToSelected}
                            disabled={checkedAll.length < 1}
                            borderColor={
                              checkedAll.length > 0
                                ? Colors.grayDark
                                : "#AEAEAE"
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid className={customStyles.list}>
                        {filteredPermissionsAll &&
                        filteredPermissionsAll.length > 0
                          ? filteredPermissionsAll.map(
                              (perm: GetPermissionsApiResponseType) => {
                                return (
                                  <ListItem
                                    key={perm.permissionID}
                                    role={undefined}
                                    dense
                                    button
                                    className={customStyles.listItem}
                                    onClick={() => {
                                      handleToggleAll(perm.permissionID);
                                    }}
                                  >
                                    <ListItemIcon>
                                      <Checkbox
                                        edge="start"
                                        checked={
                                          checkedAll.indexOf(
                                            perm.permissionID
                                          ) !== -1
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          "aria-labelledby": perm.name,
                                        }}
                                        color="primary"
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      id={perm.permissionID.toString()}
                                      primary={perm.name}
                                    />
                                  </ListItem>
                                );
                              }
                            )
                          : "No items"}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      sm={6}
                      direction="column"
                      style={{ paddingLeft: 20 }}
                    >
                      <Grid container item sm={12} alignItems="baseline">
                        <TextField
                          id="permission-group-name"
                          name="permission-group-name"
                          label="Name"
                          value={groupName}
                          onChange={handleChangeGroupName}
                          error={groupName.length === 0}
                          style={{ width: 200 }}
                          onKeyPress={(ev) => {
                            if (ev.key === "Enter") {
                              // do nothing
                              ev.preventDefault();
                            }
                          }}
                        />

                        <TypographyInfo
                          text={` (${permissionsCountSelected})`}
                          color={Colors.black}
                          margin="5px 5px"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        sm={12}
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item sm={8} alignItems="center">
                          <SearchField
                            onChangeEvent={onSearchChangeSelected}
                            preventEnterKey
                            marginTop="0px"
                          />
                        </Grid>
                        <Grid item sm={4} alignItems="center">
                          <ButtonOutlined
                            text={
                              "Delete" +
                              (checkedSelected.length > 0
                                ? ` (${checkedSelected.length})`
                                : "")
                            }
                            iconStartType={IconType.SvgImageIcon}
                            iconStart={
                              <SvgTrash
                                width={"14px"}
                                color={
                                  checkedSelected.length > 0
                                    ? Colors.grayDark
                                    : "#AEAEAE"
                                }
                              />
                            }
                            size={"md"}
                            handleClick={deleteFromSelected}
                            disabled={checkedSelected.length < 1}
                            borderColor={
                              checkedSelected.length > 0
                                ? Colors.grayDark
                                : "#AEAEAE"
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid className={customStyles.list}>
                        {filteredPermissionsSelected &&
                        filteredPermissionsSelected.length > 0
                          ? filteredPermissionsSelected.map(
                              (perm: GetPermissionsApiResponseType) => {
                                return (
                                  <ListItem
                                    key={perm.permissionID}
                                    role={undefined}
                                    dense
                                    button
                                    className={customStyles.listItem}
                                    onClick={() => {
                                      handleToggleSelected(perm.permissionID);
                                    }}
                                  >
                                    <ListItemIcon>
                                      <Checkbox
                                        edge="start"
                                        checked={
                                          checkedSelected.indexOf(
                                            perm.permissionID
                                          ) !== -1
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          "aria-labelledby": perm.name,
                                        }}
                                        color="primary"
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      id={perm.permissionID.toString()}
                                      primary={perm.name}
                                    />
                                  </ListItem>
                                );
                              }
                            )
                          : "No items"}
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ButtonSubaction text="Cancel" handleClick={close} />
                  <ButtonAction isSubmit text="Save" />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </React.Fragment>
  );
}
