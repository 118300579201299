import * as React from "react";
import { Container, Grid } from "@material-ui/core";
import { IAppsPermissionsProps } from "Components/Permission/interfaces-permission";
import { stylesPermissionHeader } from "Components/Permission/PermissionHeader/styles";
import PermissionApi from "Services/Api/permission-api";
import { GetAppsWithPermissionGroupsApiResponseType } from "Types/permission-types";
import AppsPermissionsList from "Components/Permission/AppsPermissions/apps-permissions-list";
import { AppsPermissionsDefaultTab } from "Common/enum";
import HeaderCard from "Components/HeaderCard/header-card";

const permissionApi = new PermissionApi();

export default function AppsPermissions(props: IAppsPermissionsProps) {
  const [appsWithPermissionGroups, setAppsWithPermissionGroups] =
    React.useState<GetAppsWithPermissionGroupsApiResponseType[]>();

  const getAppsWithPermissions = React.useCallback(async (): Promise<void> => {
    if (props.client.id) {
      await permissionApi
        .getAppsWithPermissionGroups(props.client.id)
        .then((result) => {
          if (result && result.data) {
            setAppsWithPermissionGroups(result.data);
          }
        });
    }
  }, [props.client.id]);

  React.useEffect(() => {
    getAppsWithPermissions();
  }, [getAppsWithPermissions]);

  let customStyles = stylesPermissionHeader();

  return (
    <Container>
      <HeaderCard>
        <Grid container alignItems="center">
          <Grid item className={customStyles.title}>
            Application list
          </Grid>
        </Grid>
      </HeaderCard>

      <div style={{ marginTop: 30 }}>
        <AppsPermissionsList
          client={props.client}
          appsList={appsWithPermissionGroups ? appsWithPermissionGroups : []}
          getAppsWithPermissions={getAppsWithPermissions}
          defaultTab={AppsPermissionsDefaultTab.PermissionGroups}
        />
      </div>
    </Container>
  );
}
