import * as React from "react";
import { Container, Grid } from "@material-ui/core";
import WebAppHeader from "Components/WebApp/WebAppHeader/web-app-header";
import { IWebApplicationDetailsProps } from "Components/WebApp/interfaces-web-app";
import { WebAppType } from "Types/app-types";
import ClientAppApi from "Services/Api/client-app-api";
import { useHistory } from "react-router-dom";
import { RouteApplications } from "Configuration/Routes";
import { GetRolesForAppApiResponseType } from "Types/role-types";
import RoleApi from "Services/Api/role-api";
import { AppPlatformEnum, RequiredLoginEnum } from "Common/enum";
import AppListContainer from "Components/AppList/app-list-container";
import WebServiceListContainer from "Components/WebApp/WebServiceList/web-service-list-container";

const clientAppApi = new ClientAppApi();
const roleApi = new RoleApi();

export default function WebApplicationDetails(
  props: IWebApplicationDetailsProps
) {
  const [webApp, setWebApp] = React.useState<WebAppType>();
  const [roleList, setRoleList] = React.useState<
    GetRolesForAppApiResponseType[]
  >([]);

  const history = useHistory();

  const getWebApp = React.useCallback(async () => {
    if (props.match.params.id && props.client.id && props.client.id > 0) {
      await clientAppApi.getWebApp(props.match.params.id).then((result) => {
        if (result && result.data) {
          if (result.data.iaClientID === props.client.id) {
            setWebApp(result.data);
          } else {
            history.push(RouteApplications);
          }
        } else {
          history.push(RouteApplications);
        }
      });
    }
  }, [props.match.params.id, props.client.id, history]);

  React.useEffect(() => {
    getWebApp();
  }, [getWebApp]);

  React.useEffect(() => {
    if (webApp) {
      const getRoleList = async (): Promise<void> => {
        await roleApi.getRolesForApp(webApp?.id!).then((result) => {
          if (result && result.data) {
            setRoleList(result.data);
          }
        });
      };
      getRoleList();
    }
  }, [webApp]);

  return (
    <Container>
      <Grid container>
        <Grid item sm={12}>
          <WebAppHeader
            appName={webApp ? webApp.name : "-"}
            appId={webApp ? webApp.id : 0}
            appGuid={webApp ? webApp.guid : ""}
            clientId={webApp ? webApp.iaClientID : 0}
            logoFile={webApp ? webApp.logoFile : undefined}
            isRequireUsers={webApp ? webApp.isRequireUsers : true}
            requiredLoginType={
              webApp ? webApp.requiredLoginType : RequiredLoginEnum.Email
            }
            getWebApp={getWebApp}
            roleList={roleList}
            roleID={webApp?.iaRoleID}
            isReportsAvailable={webApp ? webApp.isReportsAvailable : true}
            isActivationRequired={webApp ? webApp.isActivationRequired : true}
            platformType={webApp?.platformType ?? AppPlatformEnum.Web}
            isExternalApplication={webApp?.isExternalApplication ?? true}
          />
          {webApp?.platformType === AppPlatformEnum.Web ? (
            <AppListContainer
              appName={webApp?.name ?? ""}
              versionList={webApp?.versionList ?? []}
              handleRefreshApp={getWebApp}
              platformType={webApp?.platformType ?? AppPlatformEnum.Web}
            />
          ) : (
            <WebServiceListContainer
              webAppId={webApp?.id ?? 0}
              instanceList={webApp?.instanceList ?? []}
              platformType={webApp?.platformType ?? AppPlatformEnum.WebService}
              getWebApp={getWebApp}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
