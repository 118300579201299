import * as React from "react";
import { IAccessRightsListItemReportsProps } from "Components/AccessRight/interfaces-access-right";
import { stylesAccessRightsListItemReports } from "Components/AccessRight/AccessRightsListReports/styles";
import { Paper, Grid, Switch } from "@material-ui/core";
import TypographyInfo from "Components/Typography/typography-info";

const AccessRightsListItemReports: (
  props: IAccessRightsListItemReportsProps
) => JSX.Element = (props: IAccessRightsListItemReportsProps): JSX.Element => {
  const [selected, setSelected] = React.useState({
    checked: props.report.isSelected,
  });

  React.useEffect(() => {
    setSelected({ ...selected, checked: props.report.isSelected });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.report.isSelected]);

  const customStyles = stylesAccessRightsListItemReports();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelected({ ...selected, checked: event.target.checked });
    props.handleReportSelectedChange(
      props.report.reportID,
      event.target.checked
    );
  };

  return (
    <tr>
      <td>
        <Paper className={customStyles.paper}>
          <Grid container alignItems="center">
            <Grid container item xs={3}>
              <Grid item>
                <TypographyInfo text={props.report.reportName} />
              </Grid>
            </Grid>
            <Grid container item xs={9} justify="flex-end">
              <Grid item>
                <Switch
                  checked={selected.checked}
                  onChange={handleChange}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </td>
    </tr>
  );
};

export default AccessRightsListItemReports;
