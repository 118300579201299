import React from 'react';
import PermissionsGroupListItem from './permissions-group-list-item';
import { Grid } from '@material-ui/core';
import { IPermissionsGroupListProps } from '../interfaces-permission';

const PermissionsGroupList: React.FC<IPermissionsGroupListProps> = ({
  data,
  getPermissionGroups,
}) => {
  return (
    <Grid container direction="column">
      {data.map((item) => (
        <PermissionsGroupListItem
          data={item}
          getPermissionGroups={getPermissionGroups}
          key={item.permissionGroupID}
        />
      ))}
    </Grid>
  );
};

export default PermissionsGroupList;
