import Colors from 'Common/colors';
import * as React from 'react';

const SvgPreview = ({ style = {}, color = Colors.black }) => (
  <svg
    style={style}
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="13" rx="1.5" stroke={color} />
    <path
      d="M8.14286 5C5.72905 5 3 8 3 8C3 8 5.72905 11 8.14286 11C10.5567 11 13.2857 8 13.2857 8C13.2857 8 10.5567 5 8.14286 5Z"
      stroke={color}
    />
    <circle cx="8.14202" cy="7.99993" r="1.21429" stroke={color} />
    <path d="M0 3H16" stroke={color} />
  </svg>
);

export default SvgPreview;
