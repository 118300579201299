import * as React from "react";
import { IImageOSIconProps } from "Components/Image/interfaces-image";
import SvgAndroid from "Components/Images/image-android";
import SvgIos from "Components/Images/image-ios";
import { AppPlatformEnum } from "Common/enum";
import SvgWeb from "Components/Images/image-web";
import SvgWebService from "Components/Images/image-web-service";
import Colors from "Common/colors";
import TypographySubinfo from "Components/Typography/typography-subinfo";

export default function ImageOSIcon(props: IImageOSIconProps) {
  const width = props.width ? props.width : "35px";
  const color = props.color ? props.color : Colors.graySeparate;
  const textColor = props.textColor ? props.textColor : Colors.gray;

  let iconOS: React.ReactNode;
  switch (props.platformType) {
    case AppPlatformEnum.Android:
      iconOS = <SvgAndroid width={width} color={color} />;
      break;
    case AppPlatformEnum.IOS:
      iconOS = <SvgIos width={width} color={color} />;
      break;
    case AppPlatformEnum.Web:
      iconOS = <SvgWeb width={width} color={color} />;
      break;
    case AppPlatformEnum.WebService:
      iconOS = <SvgWebService width={width} color={color} />;
      break;
    default:
      break;
  }

  let text: React.ReactNode;
  if (props.text) {
    text = (
      <TypographySubinfo
        text={props.text}
        color={textColor}
        style={props.labelFontSize ? { fontSize: props.labelFontSize } : {}}
      />
    );
  }

  return (
    <React.Fragment>
      {iconOS}
      {text}
    </React.Fragment>
  );
}
