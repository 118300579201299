import * as React from "react";
import * as Yup from "yup";
import * as _ from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import {
  AppInstanceListData,
  AppListData,
  MaintenanceData,
  MaintenanceEditorProps,
} from "../interfaces";
import { Form, Formik } from "formik";
import { ApiResultStatusEnum, AppPlatformEnum } from "Common/enum";
import { validationShape } from "../formik-validation-values";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import Colors from "Common/colors";
import InputCheckbox from "Components/Inputs/input-checkbox";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import ImageOSIcon from "Components/Image/ImageOSIcon/image-os-icon";
import ClientAppApi from "Services/Api/client-app-api";
import MaintenanceApi from "Services/Api/maintenance-api";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import DateTimeField from "Components/Inputs/DateTimeField/date-time-field";

const clientAppApi: ClientAppApi = new ClientAppApi();
const maintenanceApi: MaintenanceApi = new MaintenanceApi();

const MaintenanceEditor: (props: MaintenanceEditorProps) => JSX.Element = (
  props: MaintenanceEditorProps
): JSX.Element => {
  const [appList, setAppList] = React.useState<AppListData[]>([]);
  const [appInstanceList, setAppInstanceList] = React.useState<
    AppInstanceListData[]
  >([]);
  const defaultValues: MaintenanceData = {
    notificationMailSendToAllUsers: false,
    notificationMailSendToDistributionList: false,
    notificationMailDistributionListText: "",
    notificationMailDistributionList: [],
    notificationSmsSendToAllUsers: false,
    notificationSmsSendToDistributionList: false,
    notificationSmsDistributionListText: "",
    notificationSmsDistributionList: [],
    notificationWebOnUserLogin: false,
    notificationText: "",
    startDateUTC: moment.utc().format(),
    endDateUTC: moment.utc().add(1, "d").format(),
  };
  const [values, setValues] = React.useState<MaintenanceData>(defaultValues);

  const handleClose: () => void = (): void => {
    props.handleToggle(false);
  };

  const handleSave: (valuesToSave: MaintenanceData) => void = (
    valuesToSave: MaintenanceData
  ): void => {
    maintenanceApi.save(valuesToSave).then((r) => {
      if (props.handleAfterSave) {
        props.handleAfterSave();
      } else {
        props.handleToggle(false);
      }
    });
  };

  const loadMaintenanceDetails: (maintenanceID: number) => void = (
    maintenanceID: number
  ): void => {
    maintenanceApi.getDetails(maintenanceID).then((r) => {
      if (r.status === ApiResultStatusEnum.Ok && r.data) {
        r.data.notificationMailDistributionListText = _.join(
          r.data.notificationMailDistributionList,
          ";"
        );
        r.data.notificationSmsDistributionListText = _.join(
          r.data.notificationSmsDistributionList,
          ";"
        );
        setValues(r.data);
      }
    });
  };

  const loadAppInstanceList: (appID: number) => void = (
    appID: number
  ): void => {
    clientAppApi.getWebApp(appID).then((result) => {
      if (result?.status === 1 && result?.data) {
        setAppInstanceList(
          _.map(result.data.instanceList, (d) => {
            return {
              appInstanceID: d.id,
              appInstanceName: d.name,
            } as AppInstanceListData;
          })
        );
      } else {
        setAppInstanceList([]);
      }
    });
  };

  React.useEffect(() => {
    if (props.editorIsOpen) {
      if (props.maintenanceId) {
        loadMaintenanceDetails(props.maintenanceId);
      } else {
        setValues(defaultValues);
      }
    }
  }, [props.editorIsOpen]);

  React.useEffect(() => {
    setAppList(
      _.map(props.appList, (a) => {
        return {
          appID: a.id,
          appName: a.name,
          platformType: a.platformType,
        } as AppListData;
      })
    );
  }, [props.appList]);

  return (
    <React.Fragment>
      <Dialog open={props.editorIsOpen}>
        <DialogTitle>
          <TypographySubtitle
            text={props.maintenanceId ? "Edit maintenance" : "New maintenance"}
          />
        </DialogTitle>
        <Divider />
        <Formik
          initialValues={values}
          enableReinitialize
          onSubmit={(valuesToSave: MaintenanceData) => {
            valuesToSave.startDateUTC = valuesToSave.startDateUTC
              ? moment(valuesToSave.startDateUTC).utc().format()
              : moment().utc().format();
            valuesToSave.endDateUTC = valuesToSave.endDateUTC
              ? moment(valuesToSave.endDateUTC).utc().format()
              : moment().utc().format();
            valuesToSave.webAppInstanceID =
              valuesToSave.webAppInstanceID ??
              appInstanceList[0]?.appInstanceID;
            valuesToSave.appID = valuesToSave.appID ?? appList[0]?.appID;
            valuesToSave.notificationMailDistributionList =
              valuesToSave.notificationMailDistributionListText
                ? valuesToSave.notificationMailDistributionListText?.split(";")
                : [];
            valuesToSave.notificationSmsDistributionList =
              valuesToSave.notificationSmsDistributionListText
                ? valuesToSave.notificationSmsDistributionListText?.split(";")
                : [];

            handleSave(valuesToSave);
          }}
          validationSchema={Yup.object().shape(validationShape)}
        >
          {(formik) => (
            <Form>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Autocomplete
                      id="appID"
                      options={appList}
                      getOptionLabel={(option) => option.appName}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <div
                            style={{
                              display: "inline-block",
                              verticalAlign: "top",
                              marginLeft: "5px",
                            }}
                          >
                            <ImageOSIcon
                              platformType={option.platformType}
                              width="20px"
                              color={Colors.graySeparate}
                              text=""
                              textColor={Colors.graySeparate}
                              labelFontSize="1px"
                            />
                          </div>
                          <div
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginLeft: "10px",
                            }}
                          >
                            {option.appName}
                          </div>
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="appID"
                          label="Application"
                          variant="standard"
                        />
                      )}
                      size="small"
                      onChange={(e, v) => {
                        formik.setFieldValue("appID", v.appID);
                        if (v.platformType === AppPlatformEnum.WebService) {
                          loadAppInstanceList(v.appID);
                        } else {
                          setAppInstanceList([]);
                        }
                      }}
                      value={
                        _.find(
                          appList,
                          (p) => p.appID === formik.values.appID
                        ) ?? appList[0]
                      }
                      disableClearable={true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {[...appInstanceList].length > 0 && (
                      <Autocomplete
                        id="webAppInstanceID"
                        defaultValue={appInstanceList[0]}
                        options={appInstanceList}
                        getOptionLabel={(option) => option.appInstanceName}
                        renderOption={(option, { selected }) =>
                          option.appInstanceName
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Instance"
                            variant="standard"
                          />
                        )}
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={(ed, v) =>
                          formik.setFieldValue(
                            "webAppInstanceID",
                            v.appInstanceID
                          )
                        }
                        value={
                          _.find(
                            appInstanceList,
                            (p) =>
                              p.appInstanceID === formik.values.webAppInstanceID
                          ) ?? appInstanceList[0]
                        }
                        disableClearable={true}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <DateTimeField
                      id="startDateUTC"
                      name="startDateUTC"
                      label="Start date"
                      variant="inline"
                      format="dd/MM/yyyy hh:mm a"
                      disablePast
                      value={moment(formik.values.startDateUTC).local(true)}
                      onChange={(ed) =>
                        formik.setFieldValue(
                          "startDateUTC",
                          ed ? moment(ed) : null
                        )
                      }
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.errors.startDateUTC &&
                          formik.touched.startDateUTC
                        )
                      }
                      helperText={
                        formik.errors.startDateUTC &&
                        formik.touched.startDateUTC &&
                        formik.errors.startDateUTC
                      }
                      autoOk={true}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateTimeField
                      id="endDateUTC"
                      name="endDateUTC"
                      label="End date"
                      variant="inline"
                      format="dd/MM/yyyy hh:mm a"
                      disablePast
                      minDate={moment(formik.values.startDateUTC).local(true)}
                      value={moment(formik.values.endDateUTC).local(true)}
                      onChange={(ed) =>
                        formik.setFieldValue(
                          "endDateUTC",
                          ed ? moment(ed) : null
                        )
                      }
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.errors.endDateUTC && formik.touched.endDateUTC
                        )
                      }
                      helperText={
                        formik.errors.endDateUTC &&
                        formik.touched.endDateUTC &&
                        formik.errors.endDateUTC
                      }
                      autoOk={true}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputCheckbox
                      name="notificationMailSendToAllUsers"
                      checked={formik.values.notificationMailSendToAllUsers}
                      onChange={formik.handleChange}
                      text="Mail to all users"
                      margin="0"
                    />
                    <InputCheckbox
                      name="notificationMailSendToDistributionList"
                      checked={
                        formik.values.notificationMailSendToDistributionList
                      }
                      onChange={formik.handleChange}
                      text="Mail to distribution list"
                      margin="0"
                    />
                    <InputCheckbox
                      name="notificationWebOnUserLogin"
                      checked={formik.values.notificationWebOnUserLogin}
                      onChange={formik.handleChange}
                      text="On user login"
                      margin="0"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputCheckbox
                      name="notificationSmsSendToAllUsers"
                      checked={formik.values.notificationSmsSendToAllUsers}
                      onChange={formik.handleChange}
                      text="SMS to all users"
                      margin="0"
                    />
                    <InputCheckbox
                      name="notificationSmsSendToDistributionList"
                      checked={
                        formik.values.notificationSmsSendToDistributionList
                      }
                      onChange={formik.handleChange}
                      text="SMS to distribution list"
                      margin="0"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldEntered
                      id="notificationMailDistributionListText"
                      name="notificationMailDistributionListText"
                      label="Mail distribution list - separated by ;"
                      value={formik.values.notificationMailDistributionListText}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.errors.notificationMailDistributionListText &&
                          formik.touched.notificationMailDistributionListText
                        )
                      }
                      helperText={
                        formik.errors.notificationMailDistributionListText &&
                        formik.touched.notificationMailDistributionListText &&
                        formik.errors.notificationMailDistributionListText
                      }
                      inputProps={{
                        maxLength: 500,
                      }}
                      fullWidth
                      disabled={
                        !formik.values.notificationMailSendToDistributionList
                      }
                      required={
                        formik.values.notificationMailSendToDistributionList
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldEntered
                      id="notificationSmsDistributionListText"
                      name="notificationSmsDistributionListText"
                      label="SMS distribution list - separated by ;"
                      value={formik.values.notificationSmsDistributionListText}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.errors.notificationSmsDistributionListText &&
                          formik.touched.notificationSmsDistributionListText
                        )
                      }
                      helperText={
                        formik.errors.notificationSmsDistributionListText &&
                        formik.touched.notificationSmsDistributionListText &&
                        formik.errors.notificationSmsDistributionListText
                      }
                      inputProps={{
                        maxLength: 500,
                      }}
                      fullWidth
                      disabled={
                        !formik.values.notificationSmsSendToDistributionList
                      }
                      required={
                        formik.values.notificationSmsSendToDistributionList
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldEntered
                      id="notificationText"
                      name="notificationText"
                      label="Notification text"
                      value={formik.values.notificationText}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.errors.notificationText &&
                          formik.touched.notificationText
                        )
                      }
                      helperText={
                        (formik.errors.notificationText &&
                          formik.touched.notificationText &&
                          formik.errors.notificationText) ||
                        `${formik?.values?.notificationText?.length ?? 0}/1000`
                      }
                      inputProps={{
                        maxLength: 1000,
                      }}
                      fullWidth
                      multiline
                      required
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <ButtonSubaction text="Cancel" handleClick={handleClose} />
                <ButtonAction isSubmit text="Save" />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
};

export default MaintenanceEditor;
