import * as React from "react";
import { IFileDownloadProps } from "Components/File/interfaces-file";
import { stylesFileDownload } from "Components/File/FileDownload/styles";
import TypographyInfo from "Components/Typography/typography-info";
import ButtonOutlined from "Components/Buttons/button-outlined";

const FileDownload: (props: IFileDownloadProps) => JSX.Element
    = (props: IFileDownloadProps): JSX.Element => {
        const customStyles = stylesFileDownload();

        const handleClick = (): void => {
            const url = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_VERSION}/File/Download?guid=${props.file?.guid}`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', props.file ? props.file.originalName : 'file');
            document.body.appendChild(link);
            link.click();
        }

        return (
            <div className={customStyles.container}>
                <div className={customStyles.fileInfo}>
                    <TypographyInfo text={props.file ? props.file.originalName : '-'} />
                </div>
                <div className={customStyles.button}>
                    <ButtonOutlined text="Download" handleClick={handleClick} />
                </div>
            </div>
        )
}

export default FileDownload;