import * as React from "react";
import { ITabPanelProps } from "Components/TabPanel/interfaces-tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default function TabPanel(props: ITabPanelProps) {
  const { children, value, index, padding, style, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
      {...other}
    >
      {value === index &&
        (padding ? (
          <Typography style={{ padding: padding ?? 0 }}>{children}</Typography>
        ) : (
          <Box>
            <Typography style={{ padding: 0 }}>{children}</Typography>
          </Box>
        ))}
    </div>
  );
}
