import { axiosInstance, apiDownloadRequest } from "../../Services/Api/base-api";
import {
  GetReportDictionariesApiResultType,
  SaveReportDictionaryApiResultType,
  DeleteReportDictionaryApiResultType,
  GetReportContainerListApiResultType,
  SaveReportContainerApiResultType,
  DeleteReportContainerApiResultType,
  GetReportsForReportContainerApiResultType,
  GetReportContainersForAppApiResultType,
  SaveAppReportContainersApiResultType,
  GetReportsForRoleApiResultType,
  SaveReportsForRoleApiResultType,
  GetReportListApiResultType,
  DeleteReportApiResultType,
  GetReportDetailsApiResultType,
  GetReportDataTypeFormatListApiResultType,
  AddReportApiResultType,
  CopyReportApiResultType,
  ApiResultBaseType,
  SaveReportDetailsResultType,
  ImportReportsFileApiResultType,
  GetPowerBiReportListApiResultType,
  RefreshReportApiResultType,
} from "../../Types/api-types";
import {
  SaveReportDictionaryModelType,
  DeleteReportDictionaryModelType,
  SaveReportContainerModelType,
  DeleteReportContainerModelType,
  SaveAppReportContainersModelType,
  SaveReportsForRoleModelType,
  DeleteReportModelType,
  AddReportModelType,
  CopyReportModelType,
  ExportReportsModelType,
  SaveReportDetailsModelType,
  ImportReportsFileModelType,
} from "../../Types/report-types";

export default class ReportApi {
  getDictionaryList = async (
    clientID: number
  ): Promise<GetReportDictionariesApiResultType> => {
    return await axiosInstance
      .get("/Reports/GetReportDictionaries?clientID=" + clientID)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  saveDictionary = async (
    dictionary: SaveReportDictionaryModelType
  ): Promise<SaveReportDictionaryApiResultType> => {
    return await axiosInstance
      .post("/Reports/SaveReportDictionary", JSON.stringify(dictionary))
      .then((response) => response.data);
  };

  deleteDictionary = async (
    dictionary: DeleteReportDictionaryModelType
  ): Promise<DeleteReportDictionaryApiResultType> => {
    return await axiosInstance
      .post("/Reports/DeleteReportDictionary", JSON.stringify(dictionary))
      .then((response) => response.data);
  };

  getReportContainerList = async (
    clientID: number
  ): Promise<GetReportContainerListApiResultType> => {
    return await axiosInstance
      .get("/Reports/GetReportContainerList?clientID=" + clientID)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  saveReportContainer = async (
    request: SaveReportContainerModelType
  ): Promise<SaveReportContainerApiResultType> => {
    return await axiosInstance
      .post("/Reports/SaveReportContainer", JSON.stringify(request))
      .then((response) => response.data);
  };

  deleteReportContainer = async (
    request: DeleteReportContainerModelType
  ): Promise<DeleteReportContainerApiResultType> => {
    return await axiosInstance
      .post("/Reports/DeleteReportContainer", JSON.stringify(request))
      .then((response) => response.data);
  };

  getReportsForReportContainer = async (
    reportContainerID: number
  ): Promise<GetReportsForReportContainerApiResultType> => {
    return await axiosInstance
      .get(
        "/Reports/GetReportsForReportContainer?reportContainerID=" +
          reportContainerID
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getReportContainersForApp = async (
    appID: number
  ): Promise<GetReportContainersForAppApiResultType> => {
    return await axiosInstance
      .get("/Reports/GetReportContainersForApp?appID=" + appID)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  saveAppReportContainers = async (
    appPerm: SaveAppReportContainersModelType
  ): Promise<SaveAppReportContainersApiResultType> => {
    return await axiosInstance
      .post("/Reports/SaveAppReportContainers", JSON.stringify(appPerm))
      .then((response) => response.data);
  };

  getReportsForRole = async (
    roleID: string
  ): Promise<GetReportsForRoleApiResultType> => {
    return await axiosInstance
      .get("/Reports/GetReportsForRole?roleID=" + roleID)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  saveReportsForRole = async (
    appPerm: SaveReportsForRoleModelType
  ): Promise<SaveReportsForRoleApiResultType> => {
    return await axiosInstance
      .post("/Reports/SaveReportsForRole", JSON.stringify(appPerm))
      .then((response) => response.data);
  };

  getReportList = async (
    clientID: number,
    appID?: number,
    sourceInstanceID?: number
  ): Promise<GetReportListApiResultType> => {
    return await axiosInstance
      .get(
        `/Reports/GetReportList?clientID=${clientID}${
          appID ? `&appID=${appID}` : ""
        }${sourceInstanceID ? `&sourceInstanceID=${sourceInstanceID}` : ""}`
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  deleteReport = async (
    request: DeleteReportModelType
  ): Promise<DeleteReportApiResultType> => {
    return await axiosInstance
      .post("/Reports/DeleteReport", JSON.stringify(request))
      .then((response) => response.data);
  };

  getReportDetails = async (
    reportID: number
  ): Promise<GetReportDetailsApiResultType> => {
    return await axiosInstance
      .get("/Reports/GetReportDetails?reportID=" + reportID)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getReportDataTypeFormatList =
    async (): Promise<GetReportDataTypeFormatListApiResultType> => {
      return await axiosInstance
        .get("/Reports/GetReportDataTypeFormatList")
        .then((response) => response.data)
        .catch((error) => console.log(error));
    };

  addReport = async (
    request: AddReportModelType
  ): Promise<AddReportApiResultType> => {
    return await axiosInstance
      .post("/Reports/AddReport", JSON.stringify(request))
      .then((response) => response.data);
  };

  copyReport = async (
    request: CopyReportModelType
  ): Promise<CopyReportApiResultType> => {
    return await axiosInstance
      .post("/Reports/CopyReport", JSON.stringify(request))
      .then((response) => response.data);
  };

  exportReports = async (
    request: ExportReportsModelType
  ): Promise<ApiResultBaseType | void> => {
    return apiDownloadRequest<ApiResultBaseType | void>(
      "/Reports/ExportReports",
      "POST",
      request
    );
  };

  saveReportDetails = async (
    request: SaveReportDetailsModelType
  ): Promise<SaveReportDetailsResultType> => {
    return await axiosInstance
      .post("/Reports/SaveReportDetails", JSON.stringify(request))
      .then((response) => response.data);
  };

  importReportsFile = async (
    reportsFile: ImportReportsFileModelType
  ): Promise<ImportReportsFileApiResultType> => {
    let formData = new FormData();
    formData.append("ReportName", reportsFile.reportName);
    formData.append(
      "ReportDescription",
      reportsFile.reportDescription ? reportsFile.reportDescription : ""
    );
    formData.append("ClientID", reportsFile.clientID.toString());
    formData.append("AppID", reportsFile.appID.toString());
    formData.append(
      "SourceInstanceID",
      reportsFile.sourceInstanceID.toString()
    );
    formData.append(
      "ExistingReportStrategyType",
      reportsFile.existingReportStrategyType.toString()
    );
    formData.append("File", reportsFile.file!);
    return await axiosInstance
      .post("/Reports/ImportReportsFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  };

  getPowerBiReports = async (
    selectedAppId: number
  ): Promise<GetPowerBiReportListApiResultType> => {
    return await axiosInstance
      .get("/Reports/GetPowerBiReportList?appID=" + selectedAppId)
      .then((response) => response.data)
      .catch((err) => console.log(err));
  };

  refreshReport = async (
    reportID: number
  ): Promise<RefreshReportApiResultType> => {
    return await axiosInstance
      .post("/Reports/RefreshReport", JSON.stringify({ reportID }))
      .then((response) => response.data);
  };
}
