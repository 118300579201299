import * as React from 'react';
import { AddReportFormikValuesType } from './types';
import { Autocomplete } from '@material-ui/lab';
import { ClientAppForReportsApiResponseType } from 'Types/app-types';
import { GetReportListApiResponseType } from 'Types/report-types';
import { Grid, TextField } from '@material-ui/core';
import { stylesAddReport } from 'Components/Reports/Reports/AddReport/styles';
import { useFormikContext } from 'formik';

interface AddReportCopyReportProps {
  applications: ClientAppForReportsApiResponseType[];
  selectedOldReportID: number | undefined;
  setSelectedOldReportID: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  reports: GetReportListApiResponseType[];
}

const AddReportCopyReport: React.FC<AddReportCopyReportProps> = ({
  applications,
  setSelectedOldReportID,
  reports,
  selectedOldReportID,
}) => {
  const customStyles = stylesAddReport();

  const { errors, touched, setFieldValue } =
    useFormikContext<AddReportFormikValuesType>();
  return (
    <Grid item sm={12} container>
      {applications !== undefined && applications !== null && (
        <Autocomplete
          options={reports}
          value={
            reports.find((r) => r.reportID === selectedOldReportID) ?? null
          }
          getOptionLabel={(option) => option.reportName}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Copy from"
              variant="standard"
              error={!!(errors.oldReportID && touched.oldReportID)}
              helperText={
                errors.oldReportID && touched.oldReportID && errors.oldReportID
              }
            />
          )}
          onChange={(
            e: React.ChangeEvent<{}>,
            value: GetReportListApiResponseType | null
          ) => {
            const reportID = value?.reportID ?? 0;
            setSelectedOldReportID(reportID);
            setFieldValue('oldReportID', reportID);

            if (value) {
              setFieldValue('reportName', `${value.reportName}_v01`);
            }
          }}
          className={customStyles.selectedList}
          fullWidth
        />
      )}
    </Grid>
  );
};

export default AddReportCopyReport;
