import * as React from 'react';
import { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { IconType } from 'Common/enum';
import { AppLoginDataTypeEnum } from 'Common/enum-app';
import ButtonIcon from 'Components/Buttons/button-icon';
import SvgPreview from 'Components/Images/image-preview';
import InputCheckbox from 'Components/Inputs/input-checkbox';
import TextFieldEntered from 'Components/Inputs/TextFieldEntered/text-field-entered';
import { useFormikContext } from 'formik';
import AppEditorComponentLoginData from '../AppEditorComponents/app-editor-component-login-data';
import { IAppEditor } from '../interfaces';

interface EndpointAvailableProps {
  handleCheckWS: () => Promise<void>;
  isAvailableKey: keyof Pick<
    IAppEditor,
    | 'isReportsEndpointsAvailable'
    | 'isUsersEndpointsAvailable'
    | 'isPowerBIEndpointsAvailable'
  >;
  checkboxLabel: string;
  apiMethodPath: keyof Pick<
    IAppEditor,
    'apiUserMethodPath' | 'apiReportMethodPath' | 'apiPowerBIMethodPath'
  >;
  apiMethodPathLabel: string;
  isLoginDataType?: boolean;
}

const EndpointAvailable: FunctionComponent<EndpointAvailableProps> = ({
  handleCheckWS,
  isAvailableKey,
  apiMethodPath,
  checkboxLabel,
  apiMethodPathLabel,
  isLoginDataType,
}) => {
  const { errors, touched, values, handleChange, handleBlur } =
    useFormikContext<IAppEditor>();

  return (
    <Grid container item wrap="nowrap" alignItems="flex-end">
      <Grid item xs={4}>
        <InputCheckbox
          name={isAvailableKey}
          checked={values[isAvailableKey]}
          text={checkboxLabel}
          onChange={handleChange}
          labelFontSize="14px"
          margin="0 0 0 -12px"
          labelWidth="auto"
        />
        {values[isAvailableKey] && (
          <ButtonIcon
            iconType={IconType.SvgImageIcon}
            icon={<SvgPreview />}
            handleClick={handleCheckWS}
          />
        )}
      </Grid>
      {values[isAvailableKey] && (
        <>
          <Grid item xs={isLoginDataType ? 6 : 8}>
            <TextFieldEntered
              name={apiMethodPath}
              label={apiMethodPathLabel}
              size="small"
              value={values[apiMethodPath]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors[apiMethodPath]}
              helperText={errors[apiMethodPath]}
              inputProps={{
                maxLength: 100,
              }}
              fullWidth
            />
          </Grid>
          {isLoginDataType && (
            <Grid item xs={2} style={{ paddingLeft: '12px' }}>
              <AppEditorComponentLoginData
                value={values.appLoginDataType ?? AppLoginDataTypeEnum.Id}
                handleBlur={handleBlur}
                handleChange={handleChange}
                error={!!(errors.appLoginDataType && touched.appLoginDataType)}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default EndpointAvailable;
