import * as React from "react";

const SvgArrowRight = ({
    style = {},
    fill = "none",
    width = "11",
    height = "8",
    className = "",
    color = "#444444",
    viewBox = "0 0 11 8"
  }) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M0 4H10" stroke={color} />
        <path d="M7 1L10 4L7 7" stroke={color} />
    </svg>
);

export default SvgArrowRight;