import {
  MaintenanceData,
  MaintenanceDeleteModel,
} from "Components/Maintenance/interfaces";
import { axiosInstance } from "Services/Api/base-api";
import { ApiResultBaseType } from "Types/api-types";
import {
  MaintenanceGetDetailsApiResultType,
  MaintenanceGetListApiResultType,
} from "Types/maintenance-types";

export default class MaintenanceApi {
  delete = async (model: MaintenanceDeleteModel): Promise<any> => {
    return await axiosInstance.post(
      "/Maintenance/Delete",
      JSON.stringify(model)
    );
  };

  save = async (request: MaintenanceData): Promise<ApiResultBaseType> => {
    return await axiosInstance
      .post("/Maintenance/Save", JSON.stringify(request))
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getList = async (
    clientID: number
  ): Promise<MaintenanceGetListApiResultType> => {
    return await axiosInstance
      .get("/Maintenance/GetList?clientID=" + clientID)
      .then((response) => response.data)
      .catch((error) => console.error(error));
  };

  getDetails = async (
    maintenanceID: number
  ): Promise<MaintenanceGetDetailsApiResultType> => {
    return await axiosInstance
      .get("/Maintenance/GetDetails?maintenanceID=" + maintenanceID)
      .then((response) => response.data)
      .catch((error) => console.error(error));
  };
}
