import { ButtonActionProps } from "./interfaces";
import { makeStyles, Theme } from "@material-ui/core";

export const stylesButtonAction = makeStyles((theme: Theme) => ({
  button: {
    boxSizing: "border-box",
    padding: "7px 18px",
    margin: (props: ButtonActionProps) => (props.margin ? props.margin : "0px"),
    textTransform: "none",
    minWidth: "74px",
    height: "32px",
    fontSize: "12px",
    lineHeight: "18px",
    "&:hover": {
      background: theme.palette.hoverColor.main,
      color: theme.palette.primary.contrastText,
    },
    "&:disabled": {
      background: theme.palette.graySeparate.main,
      color: theme.palette.secondary.main,
    },
  },
  circularProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: "#ec8e00",
  },
}));

export const stylesButtonSubaction = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.primary.contrastText,
      color: theme.palette.hoverColor.main,
      borderColor: theme.palette.hoverColor.main,
    },
    "&:disabled": {
      background: theme.palette.primary.contrastText,
      color: theme.palette.graySeparate.main,
      borderColor: theme.palette.graySeparate.main,
    },
  },
}));
