import * as React from 'react';
import TextFieldEntered from 'Components/Inputs/TextFieldEntered/text-field-entered';
import { Grid } from '@material-ui/core';
import {
  AddReportFormikValuesType,
  DESCRIPTION_MAX_LENGTH,
  NAME_MAX_LENGTH,
} from './types';
import { useFormikContext } from 'formik';

interface AddReportBasicInfoProps {}

const AddReportBasicInfo: React.FC<AddReportBasicInfoProps> = () => {
  const { values, errors, touched, handleBlur, handleChange } =
    useFormikContext<AddReportFormikValuesType>();

  return (
    <>
      <Grid item sm={12}>
        <TextFieldEntered
          id="report-name"
          name="reportName"
          label="Report name"
          value={values.reportName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!(errors.reportName && touched.reportName)}
          helperText={
            (errors.reportName && touched.reportName && errors.reportName) ||
            `${values.reportName.length}/${NAME_MAX_LENGTH}`
          }
          inputProps={{
            maxLength: NAME_MAX_LENGTH,
          }}
          required
        />
      </Grid>
      <Grid item sm={12}>
        <TextFieldEntered
          id="report-description"
          name="reportDescription"
          label="Description (optional)"
          value={values.reportDescription}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!(errors.reportDescription && touched.reportDescription)}
          helperText={
            (errors.reportDescription &&
              touched.reportDescription &&
              errors.reportDescription) ||
            `${values.reportDescription.length}/${DESCRIPTION_MAX_LENGTH}`
          }
          inputProps={{
            maxLength: DESCRIPTION_MAX_LENGTH,
          }}
          fullWidth
        />
      </Grid>
    </>
  );
};

export default AddReportBasicInfo;
