import { axiosInstance } from "Services/Api/base-api";
import {
  AppType,
  WebAppChangeInstanceOrderType,
  NewBoundType,
  WebAppInstanceDeploymentType,
  MobileAppVersionType,
  WebAppInstanceType,
} from "Types/app-types";
import {
  ClientAppSaveApiResultType,
  ClientAppGetWebAppListApiResultType,
  ClientAppGetMobileAppListApiResultType,
  MobileAppWithDevicesGetApiResultType,
  MobileAppGetApiResultType,
  WebAppGetApiResultType,
  WebAppBoundGetListApiResultType,
  MobileAppBoundGetListApiResultType,
  ApiResultBaseType,
  WebAppInstanceDeploymentGetApiResultType,
  WebAppInstanceDeploymentSaveApiResultType,
  WebAppInstanceDeploymentGetListApiResultType,
  BoundSaveApiResultType,
  MobileAppVersionSaveApiResultType,
  MobileAppVersionGetListResultType,
  WebAppInstanceGetApiResultType,
  WebAppInstanceSaveApiResultType,
  ClientAppGetAllAppListApiResultType,
  WebAppGetWithVersionsApiResultType,
  ClientAppListForReportsApiResultType,
  ClientAppListForCustomerApiResultType,
  CheckAppGuidAlreadyExistsApiResultType,
  ICheckAppIsRespondingForEveryInstanceResult,
  ICheckAppIsRespondingForEveryAppResult,
} from "Types/api-types";
import { AppCheckRespondingTypeEnum } from "Common/enum-app";

export default class ClientAppApi {
  checkAppIsResponding = async (
    appId: number,
    checkRespondingType: AppCheckRespondingTypeEnum
  ): Promise<ICheckAppIsRespondingForEveryAppResult> => {
    return await axiosInstance
      .get(
        `/ClientApp/CheckAppIsResponding?appId=${appId}&checkRespondingType=${checkRespondingType}`
      )
      .then((response) => response.data);
  };

  checkAppIsRespondingForEveryApp = async (
    clientId: number
  ): Promise<ICheckAppIsRespondingForEveryAppResult> => {
    return await axiosInstance
      .get("/ClientApp/CheckAppIsRespondingForEveryApp?clientId=" + clientId)
      .then((response) => response.data);
  };

  checkAppIsRespondingForEveryInstance = async (
    appId: number
  ): Promise<ICheckAppIsRespondingForEveryInstanceResult> => {
    return await axiosInstance
      .get("/ClientApp/CheckAppIsRespondingForEveryInstance?appId=" + appId)
      .then((response) => response.data);
  };

  changeWebAppInstanceOrder = async (
    newOrder: WebAppChangeInstanceOrderType
  ): Promise<ApiResultBaseType> => {
    return await axiosInstance
      .post("/ClientApp/ChangeWebAppInstanceOrder", JSON.stringify(newOrder))
      .then((response) => response.data);
  };

  getMobileAppBoundList = async (
    clientID: number
  ): Promise<MobileAppBoundGetListApiResultType> => {
    return await axiosInstance
      .get("/ClientApp/GetMobileAppBoundList?clientID=" + clientID)
      .then((response) => response.data)
      .catch((error) => console.error(error));
  };

  getWebAppBoundList = async (
    clientID: number
  ): Promise<WebAppBoundGetListApiResultType> => {
    return await axiosInstance
      .get("/ClientApp/GetWebAppBoundList?clientID=" + clientID)
      .then((response) => response.data)
      .catch((error) => console.error(error));
  };

  getMobileApp = async (appID: number): Promise<MobileAppGetApiResultType> => {
    return await axiosInstance
      .get("/ClientApp/GetMobileApp?appID=" + appID)
      .then((response) => response.data)
      .catch((error) => console.error(error));
  };

  getMobileAppDevices = async (
    appID: number
  ): Promise<MobileAppWithDevicesGetApiResultType> => {
    return await axiosInstance
      .get("/ClientApp/GetMobileAppDeviceList?appID=" + appID)
      .then((response) => response.data)
      .catch((error) => console.error(error));
  };

  getMobileAppVersionList = async (
    appID: number,
    versionNumberCount?: number
  ): Promise<MobileAppVersionGetListResultType> => {
    return await axiosInstance
      .get("/ClientApp/GetMobileAppVersionList", {
        params: {
          IAAppID: appID,
          MaxMobileAppVersionItemCount: versionNumberCount,
        },
      })
      .then((response) => response.data)
      .catch((error) => console.error(error));
  };

  getMobileAppList = async (
    clientID: number,
    deploymentItemCount: number
  ): Promise<ClientAppGetMobileAppListApiResultType> => {
    return await axiosInstance
      .get(
        "/ClientApp/GetClientMobileAppList?IAClientID=" +
          clientID +
          "&MaxMobileAppVersionItemCount=" +
          deploymentItemCount
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getWebApp = async (appID: number): Promise<WebAppGetApiResultType> => {
    return await axiosInstance
      .get("/ClientApp/GetWebApp?appID=" + appID)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getWebAppWithVersionsList: (
    appId: number
  ) => Promise<WebAppGetWithVersionsApiResultType> = async (
    appId: number
  ): Promise<WebAppGetWithVersionsApiResultType> => {
    return await axiosInstance
      .get("/ClientApp/GetWebAppWithVersionsList?appID=" + appId)
      .then((response) => response.data)
      .catch((error) => console.error(error));
  };

  getWebAppInstance = async (
    webAppInstanceID: number
  ): Promise<WebAppInstanceGetApiResultType> => {
    return await axiosInstance
      .get("/ClientApp/GetWebAppInstance?id=" + webAppInstanceID)
      .then((response) => response.data)
      .catch((error) => console.error(error));
  };

  getWebAppInstanceDeployment = async (
    webAppInstanceDeploymentID: number
  ): Promise<WebAppInstanceDeploymentGetApiResultType> => {
    return await axiosInstance
      .get(
        "/ClientApp/GetWebAppInstanceDeployment?id=" +
          webAppInstanceDeploymentID
      )
      .then((response) => response.data)
      .catch((error) => console.error(error));
  };

  getWebAppInstanceDeploymentList = async (
    webAppInstanceID?: number,
    deploymentItemCount?: number
  ): Promise<WebAppInstanceDeploymentGetListApiResultType> => {
    return await axiosInstance
      .get("/ClientApp/GetWebAppInstanceDeploymentList", {
        params: {
          IAWebAppInstanceID: webAppInstanceID,
          MaxWebAppInstanceDeploymentItemCount: deploymentItemCount,
        },
      })
      .then((response) => response.data)
      .catch((error) => console.error(error));
  };

  getWebAppList = async (
    clientID: number,
    deploymentItemCount: number
  ): Promise<ClientAppGetWebAppListApiResultType> => {
    return await axiosInstance
      .get(
        "/ClientApp/GetClientWebAppList?IAClientID=" +
          clientID +
          "&MaxWebInstanceDeploymentItemCount=" +
          deploymentItemCount
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  saveApp = async (app: AppType): Promise<ClientAppSaveApiResultType> => {
    return await axiosInstance
      .post("/ClientApp/SaveApp", JSON.stringify(app))
      .then((response) => response.data);
  };

  saveBound = async (bound: NewBoundType): Promise<BoundSaveApiResultType> => {
    return await axiosInstance
      .post("/ClientApp/SaveBound", JSON.stringify(bound))
      .then((response) => response.data);
  };

  saveMobileAppVersion = async (
    mobileAppVersion: MobileAppVersionType
  ): Promise<MobileAppVersionSaveApiResultType> => {
    return await axiosInstance
      .post("/ClientApp/SaveMobileAppVersion", JSON.stringify(mobileAppVersion))
      .then((response) => response.data);
  };

  saveWebAppInstanceDeployment = async (
    webAppInstanceDeployment: WebAppInstanceDeploymentType
  ): Promise<WebAppInstanceDeploymentSaveApiResultType> => {
    return await axiosInstance
      .post(
        "/ClientApp/SaveWebAppInstanceDeployment",
        JSON.stringify(webAppInstanceDeployment)
      )
      .then((response) => response.data);
  };

  saveWebAppInstance = async (
    webAppInstance: WebAppInstanceType
  ): Promise<WebAppInstanceSaveApiResultType> => {
    return await axiosInstance
      .post("/ClientApp/SaveWebAppInstance", JSON.stringify(webAppInstance))
      .then((response) => response.data);
  };

  getAllAppList = async (
    clientID: number,
    onlyWithRequiredUsers: boolean
  ): Promise<ClientAppGetAllAppListApiResultType> => {
    return await axiosInstance
      .get(
        "/ClientApp/GetClientAllAppList?clientID=" +
          clientID +
          "&onlyWithRequiredUsers=" +
          onlyWithRequiredUsers
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getAppListForReports = async (
    clientID: number
  ): Promise<ClientAppListForReportsApiResultType> => {
    return await axiosInstance
      .get("/ClientApp/GetClientAppListForReports?clientID=" + clientID)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getAppListForCustomer = async (
    clientID: number,
    customerID: number
  ): Promise<ClientAppListForCustomerApiResultType> => {
    return await axiosInstance
      .get(
        `/ClientApp/GetClientAppListForCustomer?clientID=${clientID}&customerID=${customerID}`
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  checkAppGuidAlreadyExists = async (
    appGuid: string
  ): Promise<CheckAppGuidAlreadyExistsApiResultType> => {
    return await axiosInstance
      .get(`/ClientApp/CheckAppGuidAlreadyExists?appGuid=${appGuid}`)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };
}
