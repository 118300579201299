import { AppPlatformEnum } from 'Common/enum';
import { AppLoginDataTypeEnum, AppUserTypeEnum } from 'Common/enum-app';
import { AppType } from 'Types/app-types';
import { IAppEditor } from '../interfaces';

export const appEditorInitValues = (clientId: number, id?: number) => ({
  guid: '',
  id: id,
  clientId: clientId,
  name: '',
  appPlatformType: AppPlatformEnum.WebService,
  isActivationRequired: false,
  isReportsAvailable: false,
  appUserType: AppUserTypeEnum.NoUser,
  appLoginDataType: AppLoginDataTypeEnum.Id,
  documentationURL: '',
  isReportsEndpointsAvailable: false,
  isUsersEndpointsAvailable: false,
  isPowerBIEndpointsAvailable: false,
  apiReportMethodPath: '',
  apiUserMethodPath: '',
  apiPowerBIMethodPath: '',
});

export const prepareDataToSave = (values: IAppEditor): AppType => {
  const { appLoginDataType, appPlatformType, clientId } = values;
  return {
    ...values,
    loginDataType: appLoginDataType,
    platformType: appPlatformType,
    iaClientID: clientId,
    isActivationRequired: false,
    isExternalApplication: false,
    isReportsAvailable: false,
    isRequireUsers: false,
  };
};
