import * as React from "react";
import { IAccessRightsListReportsContainerProps } from "Components/AccessRight/interfaces-access-right";
import AccessRightsListReports from "Components/AccessRight/AccessRightsListReports/access-rights-list-reports";
import AccessRightsFiltersReports from "Components/AccessRight/AccessRightsListReports/access-rights-filters-reports";
import { cloneDeep } from "lodash";
import { GetReportsForRoleApiResponseType } from "Types/report-types";
import { stylesAccessRightsListReportsContainer } from "Components/AccessRight/AccessRightsListReports/styles";
import TypographyInfo from "Components/Typography/typography-info";

const AccessRightListReportsContainer: (
  props: IAccessRightsListReportsContainerProps
) => JSX.Element = (
  props: IAccessRightsListReportsContainerProps
): JSX.Element => {
  const [searchValue, setSearchValue] = React.useState("");
  const [selectAllReportContainers, setSelectAllReportContainers] =
    React.useState(false);
  const [selectedReportContainers, setSelectedReportContainers] =
    React.useState<number[]>([]);
  const [reportContainers, setReportContainers] = React.useState<
    GetReportsForRoleApiResponseType[]
  >([]);

  const customStyles = stylesAccessRightsListReportsContainer();

  const handleSearchValueChange = (
    value: React.SetStateAction<string>
  ): void => {
    let searchText: string = value.toString().toUpperCase();
    searchText = searchText ? searchText : "";
    setSearchValue(searchText);
  };

  const handleReportContainersChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    let selectedValues = event.target.value as number[];
    if (selectedValues.indexOf(0) > -1) {
      if (selectAllReportContainers) {
        setSelectedReportContainers([]);
      } else {
        setSelectedReportContainers(
          props.reportContainers.map((x) => x.reportContainerID)
        );
      }
      setSelectAllReportContainers(!selectAllReportContainers);
    } else {
      setSelectAllReportContainers(false);
      setSelectedReportContainers(selectedValues);
    }
  };

  const searchByFilters = React.useCallback(
    (
      reports: GetReportsForRoleApiResponseType[]
    ): GetReportsForRoleApiResponseType[] => {
      return searchByTextAndGroup(reports);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [searchValue, selectedReportContainers]
  );

  const searchByTextAndGroup = (
    permissions: GetReportsForRoleApiResponseType[]
  ): GetReportsForRoleApiResponseType[] => {
    let results: GetReportsForRoleApiResponseType[] = permissions;
    // searchText
    const text = searchValue;
    if (text && text !== "") {
      results = results.map((permissionGroup) => {
        permissionGroup.reports = permissionGroup.reports.filter((x) =>
          x.reportName.toUpperCase().includes(text!.toUpperCase())
        );
        return permissionGroup;
      });
    }
    // reportContainers
    if (selectedReportContainers.length > 0) {
      results = results.filter((x) =>
        selectedReportContainers?.includes(x.reportContainerID)
      );
    }

    return results;
  };

  React.useEffect(() => {
    setReportContainers(searchByFilters(cloneDeep(props.reportContainers)));
  }, [
    props.reportContainers,
    searchByFilters,
    searchValue,
    selectedReportContainers,
  ]);

  return (
    <React.Fragment>
      <AccessRightsFiltersReports
        selectAllReportContainers={selectAllReportContainers}
        selectedReportContainers={selectedReportContainers}
        reportContainers={props.reportContainers}
        onSearchChange={handleSearchValueChange}
        onSelectedReportContainersChange={handleReportContainersChange}
      />
      {reportContainers.length > 0 ? (
        reportContainers.map((roleReport: GetReportsForRoleApiResponseType) => (
          <AccessRightsListReports
            key={roleReport.reportContainerID}
            reportContainer={roleReport}
            handleAllValuesChange={props.handleAllValuesChange}
            handleReportSelectedChange={props.handleReportSelectedChange}
            defaultTab={props.defaultTab}
          />
        ))
      ) : (
        <div className={customStyles.divNoReportContainers}>
          <TypographyInfo text={"No records to display"} />
        </div>
      )}
    </React.Fragment>
  );
};

export default AccessRightListReportsContainer;
