import React, { ChangeEvent } from "react";
import { Dialog, DialogActions, DialogContent, Grid } from "@material-ui/core";
import ButtonSolid from "Components/Buttons/button-solid";
import { ReportParameterDataType } from "Common/enum";
import {
  GetReportDetailsApiResponseType,
  ReportColumnDisplayNameProcedureParameterType,
} from "Types/report-types";
import SelectProcedure from "./SelectProcedure/select-procedure";
import { stylesDefineProcedureForColumnName } from "./styles";

interface DefineProcedureForColumnNameProps {
  isOpen: boolean;
  close: () => void;
  reportData: GetReportDetailsApiResponseType;
  clickOk: (reportData: GetReportDetailsApiResponseType) => void;
}

const DefineProcedureForColumnName: React.FC<
  DefineProcedureForColumnNameProps
> = ({ isOpen, close, reportData, clickOk }) => {
  const customStyles = stylesDefineProcedureForColumnName();

  const [
    reportColumnDisplayNameProcedureParameters,
    setReportColumnDisplayNameProcedureParameters,
  ] = React.useState<ReportColumnDisplayNameProcedureParameterType[]>(
    reportData?.columnsDisplayNameStoredProcedureParameters || []
  );

  const [columnsDisplayNameProcedure, setColumnsDisplayNameProcedure] =
    React.useState<string>(reportData?.columnsDisplayNameStoredProcedure || "");

  const addNewParam = () => {
    setReportColumnDisplayNameProcedureParameters([
      ...reportColumnDisplayNameProcedureParameters,
      {
        reportID: reportData.reportID,
        databaseName: "",
        defaultValueText: "",
        dataType: ReportParameterDataType.String,
        columnsDisplayNameStoredProcedureParameterID:
          reportColumnDisplayNameProcedureParameters.length * -1,
      },
    ]);
  };

  const editParams = (
    param: ReportColumnDisplayNameProcedureParameterType,
    index: number
  ) => {
    const newParams = [...reportColumnDisplayNameProcedureParameters];
    newParams[index] = param;
    setReportColumnDisplayNameProcedureParameters(newParams);
  };

  const handleClickOk = () => {
    clickOk({
      ...reportData,
      columnsDisplayNameStoredProcedure: columnsDisplayNameProcedure,
      columnsDisplayNameStoredProcedureParameters:
        reportColumnDisplayNameProcedureParameters,
    });
    close();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="sm"
        fullWidth
        onClose={() => close()}
        onEscapeKeyDown={() => close()}
      >
        <DialogContent
          classes={{ root: customStyles.content }}
          style={{ overflow: "hidden" }}
        >
          <Grid
            container
            direction="column"
            style={{ height: "100%" }}
            wrap="nowrap"
          >
            <Grid
              item
              container
              style={{ flex: 1, paddingTop: "8px", overflow: "auto" }}
            >
              <SelectProcedure
                procedureName={columnsDisplayNameProcedure}
                handleChangeProcedureName={(
                  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setColumnsDisplayNameProcedure(event.target.value)}
                procedureParams={reportColumnDisplayNameProcedureParameters}
                editParam={(param, index) => {
                  editParams(param, index);
                }}
                addNewParam={addNewParam}
                deleteParam={(index) => {
                  setReportColumnDisplayNameProcedureParameters((prev) =>
                    prev
                      .filter((_, i) => i !== index)
                      .map((p, i) => ({
                        ...p,
                        columnsDisplayNameStoredProcedureParameterID:
                          p.columnsDisplayNameStoredProcedureParameterID < 0
                            ? i * -1
                            : p.columnsDisplayNameStoredProcedureParameterID,
                      }))
                  );
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonSolid
            text="Ok"
            onClickEvent={handleClickOk}
            margin="0 0 0 10px"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DefineProcedureForColumnName;
