import * as React from "react";
import MaterialTable from "material-table";
import { IAppListProps } from "./interfaces-app-list";
import AppListItem from "./app-list-item";

export default function AppList(props: IAppListProps) {
  return (
    <MaterialTable
      columns={[]}
      data={props.versionList}
      options={{
        showTitle: false,
        header: false,
        paging: false,
        search: false,
        toolbar: false,
      }}
      components={{
        Row: (tableProps) => (
          <AppListItem
            appName={props.appName}
            platformType={props.platformType}
            item={tableProps.data}
            isAnyVersionWithForceUpdate={props.isAnyVersionWithForceUpdate}
            handleRefreshApp={props.handleRefreshApp}
          />
        ),
      }}
      style={{
        background: "transparent",
        boxShadow: "none",
      }}
    />
  );
}
