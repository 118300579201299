import React, { Component, Fragment } from "react";

class ErrorBoundary extends Component<any, any> {
    constructor(props: any) {
      super(props);
      this.state = {
        hasError: false,
        error: null,
        info: null
      };
    }
  
    componentDidCatch(error: any, info: any) {
      // display fallback UI
      this.setState({ hasError: true, error: error, info: info });
      //console.log("error", error.toString(), "info", info.componentStack);
      return false;
    }
  
    render() {
      if (this.state.hasError) {
        var divStyle = {
          margin: "20px"
        };
        // custom fallback UI
        return <Fragment>
          <div style={divStyle}>
            <h1>Something went wrong</h1>
            <h2>{ process.env.REACT_APP_SHOW_ERROR_INFO ? "The error: " + this.state.error.toString() : "" }</h2>
            <h2>{ process.env.REACT_APP_SHOW_ERROR_INFO ? "occured in: " + this.state.info.componentStack : "" }</h2>
            <h1>{ process.env.REACT_APP_SERVER === "dev" ? "Redirecting to error details ..." : "" }</h1>
          </div>
        </Fragment>
      }
      return this.props.children;
    }
  }
  
export default ErrorBoundary;