import * as React from 'react';
import ButtonAction from 'Components/Buttons/ButtonAction/button-action';
import ButtonSubaction from 'Components/Buttons/ButtonSubaction/button-subaction';
import UserLoginListItemApp from './user-login-list-item-app';
import UserLoginListItemBasicData from './user-login-list-item-basic-data';
import UserLoginListItemCompany from './user-login-list-item-company';
import { AppType } from 'Types/app-types';
import { Formik } from 'formik';
import { GetCountryListResponseType } from 'Types/country-types';
import { GetCustomerListResponseType } from 'Types/customer-types';
import { Grid } from '@material-ui/core';
import { UserLoginType } from 'Common/enum';
import {
  GetUserLoginListResponseType,
  SaveUserLoginModelType,
  SendUserLoginResetCodeModelType,
} from 'Types/user-types';
import {
  initialValues,
  UserLoginListItemFormikValuesType,
  validationSchema,
} from '../formikValues';
import {
  stylesButtonActionAndSubaction,
  stylesUserLoginListItem,
} from '../styles';

export interface UserLoginListItemEditFormProps {
  userID: number;
  userLogin: GetUserLoginListResponseType;
  propsAppList: AppType[];
  countryList: GetCountryListResponseType[];
  customerList: GetCustomerListResponseType[];
  handleOpenUserLogin: (userLogin: GetUserLoginListResponseType) => void;
  propsHandleSendUserLoginResetCode: (
    saveUserLoginModel: SendUserLoginResetCodeModelType
  ) => void;
  showInfoMessage: (infoText: string) => void;
  handleSaveUserLogin: (
    saveUserLoginModel: SaveUserLoginModelType
  ) => Promise<void>;
}

const UserLoginListItemEditForm: React.FC<UserLoginListItemEditFormProps> = ({
  userID,
  userLogin,
  propsAppList,
  countryList,
  customerList,
  handleOpenUserLogin,
  propsHandleSendUserLoginResetCode,
  showInfoMessage,
  handleSaveUserLogin,
}) => {
  const customStyles = stylesUserLoginListItem();
  const buttonsCustomStyles = stylesButtonActionAndSubaction();

  const [isExecuting, setIsExecuting] = React.useState(false);
  const [appListToSelect, setAppListToSelect] =
    React.useState<AppType[]>(propsAppList);

  const handleEditToggle = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    handleOpenUserLogin(userLogin);
  };

  const handleSubmit = async (
    values: UserLoginListItemFormikValuesType
  ): Promise<void> => {
    const { email, phoneNumber, countryID, userLoginID, customers } = values;

    if (!email && !phoneNumber) {
      showInfoMessage('Email or phone number is required.');
      return;
    }

    const loginType = !email
      ? UserLoginType.PhoneNo
      : !phoneNumber
      ? UserLoginType.Email
      : UserLoginType.All;

    const newUserLogin: SaveUserLoginModelType = {
      userLoginID,
      userID,
      email,
      phoneNumber,
      countryID,
      loginType,
      apps: appListToSelect
        .filter((a) => a.isSelected)
        .map((app) => ({ appGuid: app.guid })),
      customers,
    };
    setIsExecuting(true);
    handleSaveUserLogin(newUserLogin).finally(() => {
      setIsExecuting(false);
    });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues({
        userLogin,
        email: userLogin.email,
        phoneNumber: userLogin.phoneNumber,
        countryID: userLogin.countryID ?? undefined,
        appList: userLogin.apps,
      })}
      onSubmit={async (values: UserLoginListItemFormikValuesType) => {
        await handleSubmit(values);
      }}
      validationSchema={validationSchema}
    >
      {(formProps) => {
        const { handleSubmit, values } = formProps;
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Grid className={customStyles.editForm} container spacing={1}>
              <UserLoginListItemBasicData countryList={countryList} />
              <Grid container item alignItems="center" spacing={1}>
                <UserLoginListItemApp
                  userLoginID={userLogin.userLoginID ?? undefined}
                  propsAppList={appListToSelect}
                  setAppList={setAppListToSelect}
                  propsHandleSendUserLoginResetCode={
                    propsHandleSendUserLoginResetCode
                  }
                />
              </Grid>
              <Grid container item alignItems="center" spacing={1}>
                <UserLoginListItemCompany customerList={customerList} />
              </Grid>
              <Grid
                container
                item
                sm={12}
                alignItems="center"
                justify="flex-end"
                className={buttonsCustomStyles.root}
                spacing={0}
              >
                <Grid item>
                  <ButtonSubaction
                    text="Cancel"
                    handleClick={handleEditToggle}
                  />
                </Grid>
                <Grid item>
                  <ButtonAction
                    text="Save"
                    isSubmit
                    isExecuting={isExecuting}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default UserLoginListItemEditForm;
