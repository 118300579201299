import { makeStyles } from '@material-ui/core';
import Colors from 'Common/colors';

export const stylesAppListItem = makeStyles({
  buttonEdit: {
    float: 'right',
  },
  gridItem: {
    borderRight: `2px solid ${Colors.gray}`,
    marginRight: '40px',
  },
  paper: {
    padding: '20px 30px',
    marginBottom: '15px',
  },
});
