import { Grid } from '@material-ui/core';
import React from 'react';
import { GetRolesApiResponseType } from 'Types/role-types';
import RoleListItem from './role-list-item';

export interface RoleListProps {
  roleList: GetRolesApiResponseType[];
}

const RoleList: React.FC<RoleListProps> = ({ roleList }) => {
  return (
    <Grid container alignItems="center" direction="column" spacing={2}>
      {roleList.map((role) => (
        <RoleListItem role={role} key={role.roleID} />
      ))}
    </Grid>
  );
};

export default RoleList;
