import {
  GetReportDetailsApiResponseType,
  GetReportParameterListType,
  GetReportColumnListType,
} from "../../../Types/report-types";
import { ReportParameterEnterType, ReportSubtypeEnum } from "Common/enum";

export type ValidationResult = {
  isValid: boolean;
  errorMessage?: string;
};
export const checkIsValid = (
  editedReportData?: GetReportDetailsApiResponseType
): ValidationResult => {
  const generalValidation = checkGeneralDateAreValid(editedReportData);
  if (!generalValidation.isValid) {
    return generalValidation;
  }

  const parametersValidation = checkParametersAreValid(
    editedReportData!.parameters
  );
  if (!parametersValidation.isValid) {
    return parametersValidation;
  }

  const columnsValidation = checkColumnsAreValid(editedReportData!.columns);
  if (!columnsValidation.isValid) {
    return columnsValidation;
  }

  if (editedReportData?.reportSubtype == ReportSubtypeEnum.Summary) {
    const columnsValidation = checkColumnsAreValid(
      editedReportData!.summaryColumns
    );
    if (!columnsValidation.isValid) {
      return columnsValidation;
    }

    const parametersValidation = checkParametersAreValid(
      editedReportData!.summaryParameters
    );
    if (!parametersValidation.isValid) {
      return parametersValidation;
    }
  }

  if (editedReportData?.reportSubtype == ReportSubtypeEnum.MasterDetails) {
    const parametersValidation = checkParametersAreValid(
      editedReportData!.detailsParameters
    );
    if (!parametersValidation.isValid) {
      return parametersValidation;
    }
  }

  return { isValid: true };
};

const checkGeneralDateAreValid = (
  editedReportData?: GetReportDetailsApiResponseType
): ValidationResult => {
  if (!editedReportData) {
    return { isValid: false };
  }
  if (
    editedReportData.reportName == null ||
    editedReportData.reportName.length === 0
  ) {
    return { isValid: false, errorMessage: "Report name is required" };
  }

  return { isValid: true };
};

const checkParametersAreValid = (
  parameters: GetReportParameterListType[]
): ValidationResult => {
  const emptyDisplayNameParameters = parameters.filter(
    (p) => p.displayName == null || !p.displayName || p.displayName.length === 0
  );
  if (emptyDisplayNameParameters.length > 0) {
    return {
      isValid: false,
      errorMessage:
        "Name is required in Parameters: " +
        emptyDisplayNameParameters.map((p) => p.databaseName).join(", "),
    };
  }

  const paramWithoutPair = parameters.find(
    (p, index, array) =>
      p.enterType === ReportParameterEnterType.Range &&
      !(
        index < array.length - 1 &&
        array[index + 1].enterType === ReportParameterEnterType.Range &&
        array[index + 1].dataType === p.dataType
      ) &&
      !(
        index > 0 &&
        array[index - 1].enterType === ReportParameterEnterType.Range &&
        array[index - 1].dataType === p.dataType
      )
  );
  if (paramWithoutPair) {
    return {
      isValid: false,
      errorMessage: `Parameter ${paramWithoutPair.databaseName} has enter type Range and has not pair`,
    };
  }

  return { isValid: true };
};

const checkColumnsAreValid = (
  columns: GetReportColumnListType[]
): ValidationResult => {
  const emptyDisplayNameColumns = columns.filter(
    (p) => p.displayName == null || !p.displayName || p.displayName.length === 0
  );
  if (emptyDisplayNameColumns.length > 0) {
    return {
      isValid: false,
      errorMessage:
        "Name is required in Columns: " +
        emptyDisplayNameColumns.map((p) => p.databaseName).join(", "),
    };
  }

  return { isValid: true };
};