import * as React from "react";
import { Container, Grid } from "@material-ui/core";
import { IPermissionsProps } from "Components/Permission/interfaces-permission";
import { stylesPermissionHeader } from "Components/Permission/PermissionHeader/styles";
import PermissionApi from "Services/Api/permission-api";
import { GetPermissionGroupsApiResponseType } from "Types/permission-types";
import PermissionsGroupList from "Components/Permission/PermissionsGroup/permissions-group-list";
import ButtonOutlined from "Components/Buttons/button-outlined";
import SvgPlusCircle from "Components/Images/image-plus-circle";
import { IconType } from "Common/enum";
import Colors from "Common/colors";
import PermissionsGroupEditor from "Components/Permission/PermissionsGroup/permissions-group-editor";
import { Fragment } from "react";
import HeaderCard from "Components/HeaderCard/header-card";

const permissionApi = new PermissionApi();

export default function Permissions(props: IPermissionsProps) {
  let customStyles = stylesPermissionHeader();

  const [appsWithPermissionGroups, setPermissionGroups] = React.useState<
    GetPermissionGroupsApiResponseType[]
  >([]);
  const [isOpenEditorAddGroup, setIsOpenEditorAddGroup] = React.useState(false);

  const getPermissionGroups = React.useCallback(async (): Promise<void> => {
    if (props.client.id) {
      await permissionApi
        .getPermissionGroups(props.client.id)
        .then((result) => {
          if (result && result.data) {
            setPermissionGroups(result.data);
          }
        });
    }
  }, [props.client.id]);

  React.useEffect(() => {
    getPermissionGroups();
  }, [getPermissionGroups]);

  const handleAddGroup = () => {
    setIsOpenEditorAddGroup(true);
  };

  const closeEditorAddGroup = () => {
    setIsOpenEditorAddGroup(false);
  };

  return (
    <Fragment>
      <Container>
        <HeaderCard>
          <Grid container alignItems="center" justify="space-between">
            <Grid item className={customStyles.title}>
              Permission groups
            </Grid>
            <ButtonOutlined
              text={"Add group"}
              iconStartType={IconType.SvgImageIcon}
              iconStart={
                <SvgPlusCircle width={"14px"} color={Colors.grayDark} />
              }
              size="md"
              margin="0 10px 0 0"
              handleClick={handleAddGroup}
            />
          </Grid>
        </HeaderCard>

        <div style={{ marginTop: "30px" }}>
          <PermissionsGroupList
            data={appsWithPermissionGroups}
            getPermissionGroups={getPermissionGroups}
          />
        </div>
      </Container>
      <PermissionsGroupEditor
        isOpen={isOpenEditorAddGroup}
        permissionGroup={{
          permissionGroupID: 0,
          name: "",
          permissions: [],
          applications: [],
        }}
        closeEditor={closeEditorAddGroup}
        getPermissionGroups={getPermissionGroups}
      />
    </Fragment>
  );
}
