import { makeStyles } from "@material-ui/core";
import Colors from 'Common/colors';

export const stylesRoleListItem = makeStyles({
    gridItem: {
        borderRight: `1px solid ${Colors.graySeparate}`,
        marginRight: '40px',
        height: '50px',
    },
    paper: {
        padding: '11px 30px',
        marginBottom: '15px'
    },
    emailIcon: {
        marginRight: '6px'
    },
    userName: {
        fontSize: '18px'
    },
    userEmail: {
        fontSize: '14px'
    },
    heapIcon: {
        marginRight: '6px'
    },
    cell: {
        border: "1px solid #DCDCDC",
        padding: "8px",
        background: "#fff"
    },
    cellCentered: {
        border: "1px solid #DCDCDC",
        padding: "8px",
        background: "#fff",
        textAlign: "center"
    },
    shareModeIcon: {
        verticalAlign: "bottom",
        paddingLeft: "5px"
    },
    textCellEnabled:{
        color: "#444444"
    },
    textCellDisabled: {
        color: "#AEAEAE"
    }
});