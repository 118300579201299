export class UserData {
    userID?: number;
    firstName?: string;
    lastName?: string;
    login?: string;
    permissions?: UserPermission[];
    constructor(userID: number, firstName: string, lastName: string, login: string, permissions: UserPermission[]) {
      this.userID = userID;
      this.firstName = firstName;
      this.lastName = lastName;
      this.login = login;
      this.permissions = permissions;
    }
 }
 
 export class UserPermission {
    permissionID?: number;
    permissionName?: string;
    permissionNumber?: number;
    constructor(permissionID: number, permissionName: string, permissionNumber: number) {
      this.permissionID = permissionID;
      this.permissionName = permissionName;
      this.permissionNumber = permissionNumber;
    }
 }