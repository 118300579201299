import { makeStyles } from '@material-ui/core';

export const stylesMobileAppMainList = makeStyles({
  root: {
    margin: 0,
    lineHeight: 1,
  },
  textRight: {
    textAlign: 'right',
  },
  textLessMore: {
    fontSize: '12px',
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});
