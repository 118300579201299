import { axiosInstance } from "Services/Api/base-api";
import {
  DeletePermissionApiResultType,
  DeletePermissionGroupApiResultType,
  GetAppsWithPermissionGroupsApiResultType,
  GetPermissionGroupsApiResultType,
  GetPermissionGroupsForAppApiResultType,
  GetPermissionsApiResultType,
  GetPermissionsForCustomerApiResultType,
  SaveAppPermissionGroupsApiResultType,
  SavePermissionApiResultType,
  SavePermissionGroupApiResultType,
  SavePermissionGroupPermissionsApiResultType,
  SaveReportConatinerPermissionsApiResultType,
} from "Types/api-types";
import {
  DeletePermissionGroupModelType,
  DeletePermissionModelType,
  SaveAppPermissionGroupsModelType,
  SavePermissionGroupModelType,
  SavePermissionGroupPermissionsModelType,
  SavePermissionModelType,
  SaveReportConatinerPermissionsModelType,
} from "Types/permission-types";

export default class PermissionApi {
  getAppsWithPermissionGroups = async (
    clientID?: number
  ): Promise<GetAppsWithPermissionGroupsApiResultType> => {
    return await axiosInstance
      .get(
        `/Permission/GetAppsWithPermissionGroups${
          clientID ? `?clientID=${clientID}` : ""
        }`
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getPermissionGroupsForApp = async (
    appID: number
  ): Promise<GetPermissionGroupsForAppApiResultType> => {
    return await axiosInstance
      .get("/Permission/GetPermissionGroupsForApp?appID=" + appID)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  saveAppPermissionGroups = async (
    appPerm: SaveAppPermissionGroupsModelType
  ): Promise<SaveAppPermissionGroupsApiResultType> => {
    return await axiosInstance
      .post("/Permission/SaveAppPermissionGroups", JSON.stringify(appPerm))
      .then((response) => response.data);
  };

  savePermissionGroup = async (
    request: SavePermissionGroupModelType
  ): Promise<SavePermissionGroupApiResultType> => {
    return await axiosInstance
      .post("/Permission/SavePermissionGroup", JSON.stringify(request))
      .then((response) => response.data);
  };

  deletePermissionGroup = async (
    request: DeletePermissionGroupModelType
  ): Promise<DeletePermissionGroupApiResultType> => {
    return await axiosInstance
      .post("/Permission/DeletePermissionGroup", JSON.stringify(request))
      .then((response) => response.data);
  };

  getPermissionGroups = async (
    clientID?: number
  ): Promise<GetPermissionGroupsApiResultType> => {
    return await axiosInstance
      .get(
        `/Permission/GetPermissionGroups${
          clientID ? `?clientID=${clientID}` : ""
        }`
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getPermissions = async (
    permissionGroupID?: number
  ): Promise<GetPermissionsApiResultType> => {
    const param = permissionGroupID
      ? `?permissionGroupID=${permissionGroupID}`
      : "";
    return await axiosInstance
      .get(`/Permission/GetPermissions${param}`)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getPermissionsForCustomer = async (
    customerID: number
  ): Promise<GetPermissionsForCustomerApiResultType> => {
    const param = `?customerID=${customerID}`;
    return await axiosInstance
      .get(`/Permission/GetPermissionsForCustomer${param}`)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  savePermission = async (
    request: SavePermissionModelType
  ): Promise<SavePermissionApiResultType> => {
    return await axiosInstance
      .post("/Permission/SavePermission", JSON.stringify(request))
      .then((response) => response.data);
  };

  deletePermission = async (
    request: DeletePermissionModelType
  ): Promise<DeletePermissionApiResultType> => {
    return await axiosInstance
      .post("/Permission/DeletePermission", JSON.stringify(request))
      .then((response) => response.data);
  };

  savePermissionGroupPermissions = async (
    request: SavePermissionGroupPermissionsModelType[]
  ): Promise<SavePermissionGroupPermissionsApiResultType> => {
    return await axiosInstance
      .post(
        "/Permission/SavePermissionGroupPermissions",
        JSON.stringify(request)
      )
      .then((response) => response.data);
  };

  saveReportContainerPermissions = async (
    request: SaveReportConatinerPermissionsModelType[]
  ): Promise<SaveReportConatinerPermissionsApiResultType> => {
    return await axiosInstance
      .post(
        "/Permission/SaveReportContainerPermissions",
        JSON.stringify(request)
      )
      .then((response) => response.data);
  };
}
