import * as React from "react";

const SvgLoginBottomLeft = ({
    style = {},
    fill = "none",
    width = "100%",
    height = "40%",
    className = "",
    colorFill = "#DDDDDD",
    viewBox = "0 0 586 479"
  }) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <rect x="510.823" y="468.697" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 510.823 468.697)" fill={colorFill}/>
        <rect x="503.5" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 503.5 464.302)" fill={colorFill}/>
        <rect x="475.67" y="468.697" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 475.67 468.697)" fill={colorFill}/>
        <rect x="468.347" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 468.347 464.302)" fill={colorFill}/>
        <rect x="440.519" y="468.697" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 440.519 468.697)" fill={colorFill}/>
        <rect x="433.195" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 433.195 464.302)" fill={colorFill}/>
        <rect x="405.366" y="468.696" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 405.366 468.696)" fill={colorFill}/>
        <rect x="398.043" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 398.043 464.302)" fill={colorFill}/>
        <rect x="370.214" y="468.696" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 370.214 468.696)" fill={colorFill}/>
        <rect x="362.891" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 362.891 464.302)" fill={colorFill}/>
        <rect x="335.062" y="468.696" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 335.062 468.696)" fill={colorFill}/>
        <rect x="327.739" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 327.739 464.302)" fill={colorFill}/>
        <rect x="510.823" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 510.823 433.543)" fill={colorFill}/>
        <rect x="503.5" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 503.5 429.149)" fill={colorFill}/>
        <rect x="475.67" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 475.67 433.543)" fill={colorFill}/>
        <rect x="468.347" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 468.347 429.149)" fill={colorFill}/>
        <rect x="440.519" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 440.519 433.543)" fill={colorFill}/>
        <rect x="433.195" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 433.195 429.149)" fill={colorFill}/>
        <rect x="405.366" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 405.366 433.543)" fill={colorFill}/>
        <rect x="398.043" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 398.043 429.149)" fill={colorFill}/>
        <rect x="370.214" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 370.214 433.543)" fill={colorFill}/>
        <rect x="362.891" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 362.891 429.149)" fill={colorFill}/>
        <rect x="335.062" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 335.062 433.543)" fill={colorFill}/>
        <rect x="327.739" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 327.739 429.149)" fill={colorFill}/>
        <rect x="510.823" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 510.823 398.391)" fill={colorFill}/>
        <rect x="503.5" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 503.5 393.997)" fill={colorFill}/>
        <rect x="475.67" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 475.67 398.391)" fill={colorFill}/>
        <rect x="468.347" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 468.347 393.997)" fill={colorFill}/>
        <rect x="440.519" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 440.519 398.391)" fill={colorFill}/>
        <rect x="433.195" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 433.195 393.997)" fill={colorFill}/>
        <rect x="405.366" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 405.366 398.391)" fill={colorFill}/>
        <rect x="398.043" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 398.043 393.997)" fill={colorFill}/>
        <rect x="370.214" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 370.214 398.391)" fill={colorFill}/>
        <rect x="362.891" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 362.891 393.997)" fill={colorFill}/>
        <rect x="335.062" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 335.062 398.391)" fill={colorFill}/>
        <rect x="327.739" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 327.739 393.997)" fill={colorFill}/>
        <rect x="510.823" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 510.823 363.24)" fill={colorFill}/>
        <rect x="503.5" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 503.5 358.845)" fill={colorFill}/>
        <rect x="475.67" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 475.67 363.24)" fill={colorFill}/>
        <rect x="468.347" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 468.347 358.845)" fill={colorFill}/>
        <rect x="440.519" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 440.519 363.24)" fill={colorFill}/>
        <rect x="433.195" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 433.195 358.845)" fill={colorFill}/>
        <rect x="405.366" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 405.366 363.24)" fill={colorFill}/>
        <rect x="398.043" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 398.043 358.845)" fill={colorFill}/>
        <rect x="370.214" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 370.214 363.24)" fill={colorFill}/>
        <rect x="362.891" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 362.891 358.845)" fill={colorFill}/>
        <rect x="335.062" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 335.062 363.24)" fill={colorFill}/>
        <rect x="327.739" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 327.739 358.845)" fill={colorFill}/>
        <rect x="475.67" y="328.088" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 475.67 328.088)" fill={colorFill}/>
        <rect x="468.347" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 468.347 323.693)" fill={colorFill}/>
        <rect x="440.519" y="328.088" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 440.519 328.088)" fill={colorFill}/>
        <rect x="433.195" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 433.195 323.693)" fill={colorFill}/>
        <rect x="405.366" y="328.088" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 405.366 328.088)" fill={colorFill}/>
        <rect x="398.043" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 398.043 323.693)" fill={colorFill}/>
        <rect x="370.214" y="328.087" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 370.214 328.087)" fill={colorFill}/>
        <rect x="362.891" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 362.891 323.693)" fill={colorFill}/>
        <rect x="335.062" y="328.088" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 335.062 328.088)" fill={colorFill}/>
        <rect x="327.739" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 327.739 323.693)" fill={colorFill}/>
        <rect x="405.366" y="292.935" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 405.366 292.935)" fill={colorFill}/>
        <rect x="398.043" y="288.541" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 398.043 288.541)" fill={colorFill}/>
        <rect x="370.214" y="292.935" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 370.214 292.935)" fill={colorFill}/>
        <rect x="362.891" y="288.541" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 362.891 288.541)" fill={colorFill}/>
        <rect x="335.062" y="292.935" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 335.062 292.935)" fill={colorFill}/>
        <rect x="327.739" y="288.541" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 327.739 288.541)" fill={colorFill}/>
        <rect x="299.91" y="468.696" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 299.91 468.696)" fill={colorFill}/>
        <rect x="292.587" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 292.587 464.302)" fill={colorFill}/>
        <rect x="264.758" y="468.696" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 264.758 468.696)" fill={colorFill}/>
        <rect x="257.435" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 257.435 464.302)" fill={colorFill}/>
        <rect x="229.605" y="468.696" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 229.605 468.696)" fill={colorFill}/>
        <rect x="222.282" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 222.282 464.302)" fill={colorFill}/>
        <rect x="194.453" y="468.696" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 194.453 468.696)" fill={colorFill}/>
        <rect x="187.13" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 187.13 464.302)" fill={colorFill}/>
        <rect x="159.302" y="468.696" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 159.302 468.696)" fill={colorFill}/>
        <rect x="151.978" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 151.978 464.302)" fill={colorFill}/>
        <rect x="299.91" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 299.91 433.543)" fill={colorFill}/>
        <rect x="292.587" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 292.587 429.149)" fill={colorFill}/>
        <rect x="264.758" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 264.758 433.543)" fill={colorFill}/>
        <rect x="257.435" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 257.435 429.149)" fill={colorFill}/>
        <rect x="229.605" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 229.605 433.543)" fill={colorFill}/>
        <rect x="222.282" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 222.282 429.149)" fill={colorFill}/>
        <rect x="194.453" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 194.453 433.543)" fill={colorFill}/>
        <rect x="187.13" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 187.13 429.149)" fill={colorFill}/>
        <rect x="159.302" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 159.302 433.543)" fill={colorFill}/>
        <rect x="151.978" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 151.978 429.149)" fill={colorFill}/>
        <rect x="299.91" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 299.91 398.391)" fill={colorFill}/>
        <rect x="292.587" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 292.587 393.997)" fill={colorFill}/>
        <rect x="264.758" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 264.758 398.391)" fill={colorFill}/>
        <rect x="257.435" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 257.435 393.997)" fill={colorFill}/>
        <rect x="229.605" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 229.605 398.391)" fill={colorFill}/>
        <rect x="222.282" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 222.282 393.997)" fill={colorFill}/>
        <rect x="194.453" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 194.453 398.391)" fill={colorFill}/>
        <rect x="187.13" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 187.13 393.997)" fill={colorFill}/>
        <rect x="159.302" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 159.302 398.391)" fill={colorFill}/>
        <rect x="151.978" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 151.978 393.997)" fill={colorFill}/>
        <rect x="299.91" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 299.91 363.24)" fill={colorFill}/>
        <rect x="292.587" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 292.587 358.845)" fill={colorFill}/>
        <rect x="264.758" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 264.758 363.24)" fill={colorFill}/>
        <rect x="257.435" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 257.435 358.845)" fill={colorFill}/>
        <rect x="229.605" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 229.605 363.24)" fill={colorFill}/>
        <rect x="222.282" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 222.282 358.845)" fill={colorFill}/>
        <rect x="194.453" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 194.453 363.24)" fill={colorFill}/>
        <rect x="187.13" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 187.13 358.845)" fill={colorFill}/>
        <rect x="159.302" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 159.302 363.24)" fill={colorFill}/>
        <rect x="151.978" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 151.978 358.845)" fill={colorFill}/>
        <rect x="299.91" y="328.088" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 299.91 328.088)" fill={colorFill}/>
        <rect x="292.587" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 292.587 323.693)" fill={colorFill}/>
        <rect x="264.758" y="328.087" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 264.758 328.087)" fill={colorFill}/>
        <rect x="257.435" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 257.435 323.693)" fill={colorFill}/>
        <rect x="229.605" y="328.087" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 229.605 328.087)" fill={colorFill}/>
        <rect x="222.282" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 222.282 323.693)" fill={colorFill}/>
        <rect x="194.454" y="328.087" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 194.454 328.087)" fill={colorFill}/>
        <rect x="187.13" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 187.13 323.693)" fill={colorFill}/>
        <rect x="159.301" y="328.087" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 159.301 328.087)" fill={colorFill}/>
        <rect x="151.978" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 151.978 323.693)" fill={colorFill}/>
        <rect x="299.91" y="292.935" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 299.91 292.935)" fill={colorFill}/>
        <rect x="292.587" y="288.541" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 292.587 288.541)" fill={colorFill}/>
        <rect x="264.758" y="292.935" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 264.758 292.935)" fill={colorFill}/>
        <rect x="257.435" y="288.541" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 257.435 288.541)" fill={colorFill}/>
        <rect x="229.605" y="292.935" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 229.605 292.935)" fill={colorFill}/>
        <rect x="222.282" y="288.541" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 222.282 288.541)" fill={colorFill}/>
        <rect x="194.453" y="292.935" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 194.453 292.935)" fill={colorFill}/>
        <rect x="187.13" y="288.541" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 187.13 288.541)" fill={colorFill}/>
        <rect x="159.302" y="292.935" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 159.302 292.935)" fill={colorFill}/>
        <rect x="151.978" y="288.541" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 151.978 288.541)" fill={colorFill}/>
        <rect x="370.214" y="257.783" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 370.214 257.783)" fill={colorFill}/>
        <rect x="362.891" y="253.389" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 362.891 253.389)" fill={colorFill}/>
        <rect x="335.062" y="257.783" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 335.062 257.783)" fill={colorFill}/>
        <rect x="327.739" y="253.389" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 327.739 253.389)" fill={colorFill}/>
        <rect x="335.062" y="222.63" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 335.062 222.63)" fill={colorFill}/>
        <rect x="327.739" y="218.236" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 327.739 218.236)" fill={colorFill}/>
        <rect x="299.91" y="257.783" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 299.91 257.783)" fill={colorFill}/>
        <rect x="292.587" y="253.389" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 292.587 253.389)" fill={colorFill}/>
        <rect x="264.758" y="257.783" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 264.758 257.783)" fill={colorFill}/>
        <rect x="257.435" y="253.389" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 257.435 253.389)" fill={colorFill}/>
        <rect x="229.605" y="257.783" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 229.605 257.783)" fill={colorFill}/>
        <rect x="222.282" y="253.389" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 222.282 253.389)" fill={colorFill}/>
        <rect x="194.453" y="257.783" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 194.453 257.783)" fill={colorFill}/>
        <rect x="187.13" y="253.389" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 187.13 253.389)" fill={colorFill}/>
        <rect x="159.302" y="257.783" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 159.302 257.783)" fill={colorFill}/>
        <rect x="151.978" y="253.389" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 151.978 253.389)" fill={colorFill}/>
        <rect x="299.91" y="222.63" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 299.91 222.63)" fill={colorFill}/>
        <rect x="292.587" y="218.236" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 292.587 218.236)" fill={colorFill}/>
        <rect x="264.758" y="222.63" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 264.758 222.63)" fill={colorFill}/>
        <rect x="257.435" y="218.236" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 257.435 218.236)" fill={colorFill}/>
        <rect x="229.605" y="222.63" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 229.605 222.63)" fill={colorFill}/>
        <rect x="222.282" y="218.236" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 222.282 218.236)" fill={colorFill}/>
        <rect x="194.453" y="222.63" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 194.453 222.63)" fill={colorFill}/>
        <rect x="187.13" y="218.236" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 187.13 218.236)" fill={colorFill}/>
        <rect x="159.302" y="222.63" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 159.302 222.63)" fill={colorFill}/>
        <rect x="151.978" y="218.236" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 151.978 218.236)" fill={colorFill}/>
        <rect x="299.91" y="187.479" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 299.91 187.479)" fill={colorFill}/>
        <rect x="292.587" y="183.084" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 292.587 183.084)" fill={colorFill}/>
        <rect x="264.758" y="187.479" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 264.758 187.479)" fill={colorFill}/>
        <rect x="257.435" y="183.084" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 257.435 183.084)" fill={colorFill}/>
        <rect x="229.605" y="187.479" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 229.605 187.479)" fill={colorFill}/>
        <rect x="222.282" y="183.084" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 222.282 183.084)" fill={colorFill}/>
        <rect x="194.453" y="187.479" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 194.453 187.479)" fill={colorFill}/>
        <rect x="187.13" y="183.084" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 187.13 183.084)" fill={colorFill}/>
        <rect x="159.302" y="187.479" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 159.302 187.479)" fill={colorFill}/>
        <rect x="151.978" y="183.084" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 151.978 183.084)" fill={colorFill}/>
        <rect x="299.91" y="152.326" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 299.91 152.326)" fill={colorFill}/>
        <rect x="292.587" y="147.932" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 292.587 147.932)" fill={colorFill}/>
        <rect x="264.758" y="152.326" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 264.758 152.326)" fill={colorFill}/>
        <rect x="257.435" y="147.932" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 257.435 147.932)" fill={colorFill}/>
        <rect x="229.605" y="152.326" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 229.605 152.326)" fill={colorFill}/>
        <rect x="222.282" y="147.932" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 222.282 147.932)" fill={colorFill}/>
        <rect x="194.454" y="152.326" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 194.454 152.326)" fill={colorFill}/>
        <rect x="187.13" y="147.932" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 187.13 147.932)" fill={colorFill}/>
        <rect x="159.302" y="152.326" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 159.302 152.326)" fill={colorFill}/>
        <rect x="151.978" y="147.932" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 151.978 147.932)" fill={colorFill}/>
        <rect x="299.91" y="117.174" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 299.91 117.174)" fill={colorFill}/>
        <rect x="292.587" y="112.78" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 292.587 112.78)" fill={colorFill}/>
        <rect x="264.758" y="117.175" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 264.758 117.175)" fill={colorFill}/>
        <rect x="257.435" y="112.781" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 257.435 112.781)" fill={colorFill}/>
        <rect x="229.605" y="117.174" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 229.605 117.174)" fill={colorFill}/>
        <rect x="222.282" y="112.78" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 222.282 112.78)" fill={colorFill}/>
        <rect x="194.454" y="117.174" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 194.454 117.174)" fill={colorFill}/>
        <rect x="187.13" y="112.78" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 187.13 112.78)" fill={colorFill}/>
        <rect x="159.302" y="117.174" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 159.302 117.174)" fill={colorFill}/>
        <rect x="151.978" y="112.78" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 151.978 112.78)" fill={colorFill}/>
        <rect x="264.758" y="82.0225" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 264.758 82.0225)" fill={colorFill}/>
        <rect x="257.435" y="77.6284" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 257.435 77.6284)" fill={colorFill}/>
        <rect x="229.605" y="82.0225" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 229.605 82.0225)" fill={colorFill}/>
        <rect x="222.282" y="77.6284" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 222.282 77.6284)" fill={colorFill}/>
        <rect x="194.453" y="82.0225" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 194.453 82.0225)" fill={colorFill}/>
        <rect x="187.13" y="77.6284" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 187.13 77.6284)" fill={colorFill}/>
        <rect x="159.302" y="82.0225" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 159.302 82.0225)" fill={colorFill}/>
        <rect x="151.978" y="77.6284" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 151.978 77.6284)" fill={colorFill}/>
        <rect x="194.453" y="46.8705" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 194.453 46.8705)" fill={colorFill}/>
        <rect x="187.13" y="42.4764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 187.13 42.4764)" fill={colorFill}/>
        <rect x="159.302" y="46.8705" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 159.302 46.8705)" fill={colorFill}/>
        <rect x="151.978" y="42.4764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 151.978 42.4764)" fill={colorFill}/>
        <rect x="124.149" y="468.696" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 124.149 468.696)" fill={colorFill}/>
        <rect x="116.826" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 116.826 464.302)" fill={colorFill}/>
        <rect x="88.9971" y="468.696" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 88.9971 468.696)" fill={colorFill}/>
        <rect x="81.6738" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 81.6738 464.302)" fill={colorFill}/>
        <rect x="53.845" y="468.696" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 53.845 468.696)" fill={colorFill}/>
        <rect x="46.5217" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 46.5217 464.302)" fill={colorFill}/>
        <rect x="18.6926" y="468.696" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 18.6926 468.696)" fill={colorFill}/>
        <rect x="11.3694" y="464.302" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 11.3694 464.302)" fill={colorFill}/>
        <rect x="124.149" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 124.149 433.543)" fill={colorFill}/>
        <rect x="116.826" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 116.826 429.149)" fill={colorFill}/>
        <rect x="88.9971" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 88.9971 433.543)" fill={colorFill}/>
        <rect x="81.6738" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 81.6738 429.149)" fill={colorFill}/>
        <rect x="53.845" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 53.845 433.543)" fill={colorFill}/>
        <rect x="46.5217" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 46.5217 429.149)" fill={colorFill}/>
        <rect x="18.6926" y="433.543" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 18.6926 433.543)" fill={colorFill}/>
        <rect x="11.3694" y="429.149" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 11.3694 429.149)" fill={colorFill}/>
        <rect x="124.149" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 124.149 398.391)" fill={colorFill}/>
        <rect x="116.826" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 116.826 393.997)" fill={colorFill}/>
        <rect x="88.9971" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 88.9971 398.391)" fill={colorFill}/>
        <rect x="81.6738" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 81.6738 393.997)" fill={colorFill}/>
        <rect x="53.845" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 53.845 398.391)" fill={colorFill}/>
        <rect x="46.5217" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 46.5217 393.997)" fill={colorFill}/>
        <rect x="18.6926" y="398.391" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 18.6926 398.391)" fill={colorFill}/>
        <rect x="11.3694" y="393.997" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 11.3694 393.997)" fill={colorFill}/>
        <rect x="124.149" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 124.149 363.24)" fill={colorFill}/>
        <rect x="116.826" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 116.826 358.845)" fill={colorFill}/>
        <rect x="88.9971" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 88.9971 363.24)" fill={colorFill}/>
        <rect x="81.6738" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 81.6738 358.845)" fill={colorFill}/>
        <rect x="53.845" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 53.845 363.24)" fill={colorFill}/>
        <rect x="46.5217" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 46.5217 358.845)" fill={colorFill}/>
        <rect x="18.6926" y="363.24" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 18.6926 363.24)" fill={colorFill}/>
        <rect x="11.3694" y="358.845" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 11.3694 358.845)" fill={colorFill}/>
        <rect x="124.149" y="328.087" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 124.149 328.087)" fill={colorFill}/>
        <rect x="116.826" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 116.826 323.693)" fill={colorFill}/>
        <rect x="88.9971" y="328.087" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 88.9971 328.087)" fill={colorFill}/>
        <rect x="81.6736" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 81.6736 323.693)" fill={colorFill}/>
        <rect x="53.845" y="328.087" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 53.845 328.087)" fill={colorFill}/>
        <rect x="46.5217" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 46.5217 323.693)" fill={colorFill}/>
        <rect x="18.6926" y="328.087" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 18.6926 328.087)" fill={colorFill}/>
        <rect x="11.3694" y="323.693" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 11.3694 323.693)" fill={colorFill}/>
        <rect x="124.149" y="292.935" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 124.149 292.935)" fill={colorFill}/>
        <rect x="116.826" y="288.541" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 116.826 288.541)" fill={colorFill}/>
        <rect x="88.9971" y="292.935" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 88.9971 292.935)" fill={colorFill}/>
        <rect x="81.6738" y="288.541" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 81.6738 288.541)" fill={colorFill}/>
        <rect x="53.845" y="292.935" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 53.845 292.935)" fill={colorFill}/>
        <rect x="46.5217" y="288.541" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 46.5217 288.541)" fill={colorFill}/>
        <rect x="18.6926" y="292.935" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 18.6926 292.935)" fill={colorFill}/>
        <rect x="11.3694" y="288.541" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 11.3694 288.541)" fill={colorFill}/>
        <rect x="124.149" y="257.783" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 124.149 257.783)" fill={colorFill}/>
        <rect x="116.826" y="253.389" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 116.826 253.389)" fill={colorFill}/>
        <rect x="88.9971" y="257.783" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 88.9971 257.783)" fill={colorFill}/>
        <rect x="81.6738" y="253.389" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 81.6738 253.389)" fill={colorFill}/>
        <rect x="53.845" y="257.783" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 53.845 257.783)" fill={colorFill}/>
        <rect x="46.5217" y="253.389" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 46.5217 253.389)" fill={colorFill}/>
        <rect x="18.6926" y="257.783" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 18.6926 257.783)" fill={colorFill}/>
        <rect x="11.3694" y="253.389" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 11.3694 253.389)" fill={colorFill}/>
        <rect x="124.149" y="222.63" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 124.149 222.63)" fill={colorFill}/>
        <rect x="116.826" y="218.236" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 116.826 218.236)" fill={colorFill}/>
        <rect x="88.9971" y="222.63" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 88.9971 222.63)" fill={colorFill}/>
        <rect x="81.6738" y="218.236" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 81.6738 218.236)" fill={colorFill}/>
        <rect x="53.845" y="222.63" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 53.845 222.63)" fill={colorFill}/>
        <rect x="46.5217" y="218.236" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 46.5217 218.236)" fill={colorFill}/>
        <rect x="18.6926" y="222.63" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 18.6926 222.63)" fill={colorFill}/>
        <rect x="11.3694" y="218.236" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 11.3694 218.236)" fill={colorFill}/>
        <rect x="124.149" y="187.479" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 124.149 187.479)" fill={colorFill}/>
        <rect x="116.826" y="183.084" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 116.826 183.084)" fill={colorFill}/>
        <rect x="88.9971" y="187.479" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 88.9971 187.479)" fill={colorFill}/>
        <rect x="81.6738" y="183.084" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 81.6738 183.084)" fill={colorFill}/>
        <rect x="53.845" y="187.479" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 53.845 187.479)" fill={colorFill}/>
        <rect x="46.5217" y="183.084" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 46.5217 183.084)" fill={colorFill}/>
        <rect x="18.6926" y="187.479" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 18.6926 187.479)" fill={colorFill}/>
        <rect x="11.3694" y="183.084" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 11.3694 183.084)" fill={colorFill}/>
        <rect x="124.149" y="152.326" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 124.149 152.326)" fill={colorFill}/>
        <rect x="116.826" y="147.932" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 116.826 147.932)" fill={colorFill}/>
        <rect x="88.9971" y="152.326" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 88.9971 152.326)" fill={colorFill}/>
        <rect x="81.6736" y="147.932" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 81.6736 147.932)" fill={colorFill}/>
        <rect x="53.845" y="152.327" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 53.845 152.327)" fill={colorFill}/>
        <rect x="46.5217" y="147.932" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 46.5217 147.932)" fill={colorFill}/>
        <rect x="18.6926" y="152.327" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 18.6926 152.327)" fill={colorFill}/>
        <rect x="11.3694" y="147.932" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 11.3694 147.932)" fill={colorFill}/>
        <rect x="124.149" y="117.174" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 124.149 117.174)" fill={colorFill}/>
        <rect x="116.826" y="112.78" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 116.826 112.78)" fill={colorFill}/>
        <rect x="88.9971" y="117.174" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 88.9971 117.174)" fill={colorFill}/>
        <rect x="81.6736" y="112.78" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 81.6736 112.78)" fill={colorFill}/>
        <rect x="53.845" y="117.174" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 53.845 117.174)" fill={colorFill}/>
        <rect x="46.5217" y="112.78" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 46.5217 112.78)" fill={colorFill}/>
        <rect x="18.6926" y="117.174" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 18.6926 117.174)" fill={colorFill}/>
        <rect x="11.3694" y="112.78" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 11.3694 112.78)" fill={colorFill}/>
        <rect x="124.149" y="82.0225" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 124.149 82.0225)" fill={colorFill}/>
        <rect x="116.826" y="77.6284" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 116.826 77.6284)" fill={colorFill}/>
        <rect x="88.9971" y="82.0225" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 88.9971 82.0225)" fill={colorFill}/>
        <rect x="81.6738" y="77.6284" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 81.6738 77.6284)" fill={colorFill}/>
        <rect x="53.845" y="82.0225" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 53.845 82.0225)" fill={colorFill}/>
        <rect x="46.5217" y="77.6284" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 46.5217 77.6284)" fill={colorFill}/>
        <rect x="18.6926" y="82.0225" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 18.6926 82.0225)" fill={colorFill}/>
        <rect x="11.3694" y="77.6284" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 11.3694 77.6284)" fill={colorFill}/>
        <rect x="124.149" y="46.8705" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 124.149 46.8705)" fill={colorFill}/>
        <rect x="116.826" y="42.4764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 116.826 42.4764)" fill={colorFill}/>
        <rect x="88.9971" y="46.8705" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 88.9971 46.8705)" fill={colorFill}/>
        <rect x="81.6738" y="42.4764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 81.6738 42.4764)" fill={colorFill}/>
        <rect x="53.845" y="46.8705" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-180 53.845 46.8705)" fill={colorFill}/>
        <rect x="46.5217" y="42.4764" width="2.92935" height="11.7174" rx="1.46467" transform="rotate(-90 46.5217 42.4764)" fill={colorFill}/>
    </svg>
  );

  export default SvgLoginBottomLeft;