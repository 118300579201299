import * as React from "react";
import {
  Checkbox,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { IconType } from "Common/enum";
import { stylesDisabledPermissionsEditor } from "./styles";
import { onSearchChange } from "./utils";
import { PermissionModel } from "./models";
import ButtonOutlined from "Components/Buttons/button-outlined";
import SvgTrash from "Components/Images/image-trash";
import TypographyInfo from "Components/Typography/typography-info";
import Colors from "Common/colors";
import SearchField from "Components/Inputs/SearchField/search-field";

export interface DisabledPermissionsProps {
  list: PermissionModel[];
  filteredList: PermissionModel[];
  setFilterd: (roles: PermissionModel[]) => void;
  remove: (roleIDS: number[]) => void;
  addToEnabledPermissions: (roles: PermissionModel[]) => void;
  toggleSelectPermission: (roleID: number) => void;
}

const DisabledPermissions: React.FC<DisabledPermissionsProps> = ({
  list,
  filteredList,
  setFilterd,
  remove,
  addToEnabledPermissions,
  toggleSelectPermission,
}) => {
  const customStyles = stylesDisabledPermissionsEditor();

  const deleteFromSelected = async (): Promise<void> => {
    const selected = filteredList.filter((r) => r.isSelected);
    if (selected.length > 0) {
      remove(selected.map((r) => r.data.permissionID));
      addToEnabledPermissions(selected);
    }
  };

  const permissionsToDeleteCount = filteredList.filter(
    (r) => r.isSelected
  ).length;

  return (
    <Grid
      container
      item
      sm={6}
      direction="column"
      alignItems="flex-start"
      style={{ paddingLeft: 20 }}
    >
      <Grid item sm={12} alignItems="center">
        <TypographyInfo
          text={`Disabled permissions (${list.length})`}
          color={Colors.black}
        />
      </Grid>
      <Grid container item sm={12} alignItems="center" spacing={1}>
        <Grid item sm={8} alignItems="center">
          <SearchField
            onChangeEvent={(value: React.SetStateAction<string>) =>
              onSearchChange(value, list, setFilterd)
            }
            preventEnterKey
            marginTop="0px"
          />
        </Grid>
        <Grid item sm={4} alignItems="center">
          <ButtonOutlined
            text={`Delete${
              permissionsToDeleteCount > 0
                ? ` (${permissionsToDeleteCount})`
                : ""
            }`}
            iconStartType={IconType.SvgImageIcon}
            iconStart={
              <SvgTrash
                width={"14px"}
                color={
                  permissionsToDeleteCount > 0
                    ? Colors.grayDark
                    : Colors.grayInactive
                }
              />
            }
            size="md"
            handleClick={deleteFromSelected}
            disabled={permissionsToDeleteCount < 1}
            borderColor={
              permissionsToDeleteCount > 0
                ? Colors.grayDark
                : Colors.grayInactive
            }
          />
        </Grid>
      </Grid>
      <Grid className={customStyles.list}>
        {filteredList.length > 0
          ? filteredList.map((permission: PermissionModel) => {
              return (
                <ListItem
                  key={permission.data.permissionID}
                  role={undefined}
                  dense
                  button
                  className={customStyles.listItem}
                  onClick={() =>
                    toggleSelectPermission(permission.data.permissionID)
                  }
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={permission.isSelected}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": permission.data.name,
                      }}
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={permission.data.permissionID.toString()}
                    primary={permission.data.name}
                  />
                </ListItem>
              );
            })
          : "No items"}
      </Grid>
    </Grid>
  );
};

export default DisabledPermissions;
