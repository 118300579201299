import * as React from 'react';

const SvgWebService = ({
  style = {},
  fill = 'none',
  width = '100%',
  className = '',
  color = '#DCDCDC',
  colorFill = 'white',
  viewBox = '0 0 60 60',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    fill={fill}
    className={`svg-icon ${className || ''}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="60" height="60" rx="5" fill={color} />
    <path
      d="M48 20L12 20L12 17.6889C12 16.2039 13.2039 15 14.6889 15L45.3111 15C46.7961 15 48 16.2039 48 17.6889L48 20Z"
      fill={colorFill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 27.9963C46.4608 27.3545 44.7718 27 43 27C36.1568 27 30.5485 32.2876 30.0379 39L14.6889 39C13.2039 39 12 37.7961 12 36.3111V22L48 22V27.9963Z"
      fill={colorFill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.1654 31C41.1654 30.4477 41.6131 30 42.1654 30H44.8321C45.3844 30 45.8321 30.4477 45.8321 31V32.6715C47.0743 33.0412 48.1926 33.6985 49.1098 34.5662L50.5601 33.7288C51.0384 33.4527 51.65 33.6166 51.9261 34.0949L53.2595 36.4043C53.5356 36.8826 53.3717 37.4942 52.8935 37.7703L51.4448 38.6067C51.5891 39.2143 51.6654 39.8482 51.6654 40.5C51.6654 41.1515 51.5891 41.7852 51.445 42.3926L52.8935 43.2288C53.3717 43.505 53.5356 44.1166 53.2595 44.5949L51.9261 46.9043C51.65 47.3826 51.0384 47.5464 50.5601 47.2703L49.1104 46.4333C48.193 47.3012 47.0745 47.9587 45.8321 48.3285V50C45.8321 50.5523 45.3844 51 44.8321 51H42.1654C41.6131 51 41.1654 50.5523 41.1654 50V48.3285C39.9232 47.9588 38.8049 47.3015 37.8877 46.4339L36.439 47.2703C35.9607 47.5464 35.3491 47.3826 35.0729 46.9043L33.7396 44.5949C33.4635 44.1166 33.6273 43.505 34.1056 43.2288L35.5527 42.3934C35.4084 41.7857 35.3321 41.1518 35.3321 40.5C35.3321 39.8479 35.4085 39.2137 35.5529 38.6058L34.1056 37.7703C33.6273 37.4941 33.4635 36.8826 33.7396 36.4043L35.0729 34.0949C35.3491 33.6166 35.9607 33.4527 36.439 33.7288L37.8883 34.5656C38.8054 33.6982 39.9235 33.0411 41.1654 32.6715V31ZM43.4981 45.1667C46.0754 45.1667 48.1648 43.0773 48.1648 40.5C48.1648 37.9227 46.0754 35.8333 43.4981 35.8333C40.9208 35.8333 38.8314 37.9227 38.8314 40.5C38.8314 43.0773 40.9208 45.1667 43.4981 45.1667Z"
      fill={colorFill}
    />
  </svg>
);

export default SvgWebService;
