import * as React from "react";
import EditReportHeader from "./EditReportHeader/edit-report-header";
import EditReportTabsContainer from "./EditReportTabsContainer/edit-report-tabs-container";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import InfoDialog from "Components/Dialogs/InfoDialog/info-dialog";
import ProgressSpinner from "Components/ProgressSpinner/progress-spinner";
import ReportApi from "Services/Api/report-api";
import { ApiResultStatusEnum } from "Common/enum";
import { AxiosError } from "axios";
import { checkIsValid } from "../EditReport/validations";
import { flatten } from "lodash";
import { Fragment } from "react";
import { GetReportDictionariesApiResultType } from "Types/api-types";
import { IEditReportProps } from "./interfaces-edit-report";
/* eslint-disable array-callback-return */
import {
  GetReportContainerListApiResponseType,
  GetReportDataTypeFormatListApiResponseType,
  GetReportDetailsApiResponseType,
  GetReportDictionariesApiResponseType,
  SaveReportDetailsModelType,
  SaveReportStoredProcedureParameterType,
} from "Types/report-types";
import PermissionApi from "Services/Api/permission-api";
import { GetPermissionsApiResponseType } from "Types/permission-types";

const reportApi = new ReportApi();
const permissionApi = new PermissionApi();

const EditReport: (props: IEditReportProps) => JSX.Element = (
  props: IEditReportProps
): JSX.Element => {
  const [isBlockedUi, setIsBlockedUi] = React.useState(false);
  const [isEditable, setIsEditable] = React.useState(true);

  const [reportData, setReportData] =
    React.useState<GetReportDetailsApiResponseType>();
  const [editedReportData, setEditedReportData] =
    React.useState<GetReportDetailsApiResponseType>();

  const [newAddedStoredProcedureParams, setNewAddedStoredProcedureParams] =
    React.useState<SaveReportStoredProcedureParameterType[]>([]);

  const [dictionaries, setDictionaries] = React.useState<
    GetReportDictionariesApiResponseType[]
  >([]);
  const [dataTypeFormatList, setDataTypeFormatList] =
    React.useState<GetReportDataTypeFormatListApiResponseType[]>();
  const [permissionList, setPermissionList] = React.useState<
    GetPermissionsApiResponseType[]
  >([]);
  const [reportContainerList, setReportContainerList] = React.useState<
    GetReportContainerListApiResponseType[]
  >([]);

  const [infoDialogText, setInfoDialog] = React.useState<string | undefined>(
    undefined
  );
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );

  const changeEditedReportData = (data: GetReportDetailsApiResponseType) => {
    setEditedReportData(data);
  };

  const handleChangeNewStoredProcedureParam = (
    data: SaveReportStoredProcedureParameterType[]
  ) => {
    setNewAddedStoredProcedureParams(data);
  };

  const handleSave = () => {
    const validationResult = checkIsValid(editedReportData);
    if (!validationResult.isValid) {
      setInfoDialog(validationResult.errorMessage ?? "Error");
      return;
    }

    setIsBlockedUi(true);
    reportApi
      .saveReportDetails(
        mapToSaveModelType(editedReportData!, newAddedStoredProcedureParams)
      )
      .then((response) => {
        setIsBlockedUi(false);
        if (
          response.status === ApiResultStatusEnum.Ok &&
          response.data != null
        ) {
          setReportData(editedReportData);
          setIsEditable(false);
          setInfoDialog("Report saved");
          setNewAddedStoredProcedureParams([]);
          getReportDetails();
        } else {
          //TODO:error
        }
      })
      .catch((error) => {
        setIsBlockedUi(false);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const handleCancel = () => {
    setIsEditable(false);
    setEditedReportData(reportData);
  };

  React.useEffect(() => {
    getReportDetails();
    getPermissionList();
    getReportContainerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reportID]);

  const getReportDetails = () => {
    setIsBlockedUi(true);
    reportApi.getReportDetails(props.reportID).then((response) => {
      if (response.status === ApiResultStatusEnum.Ok && response.data != null) {
        setReportData(response.data);
        setEditedReportData(response.data);
      } else {
        //TODO: error
      }
      setIsBlockedUi(false);
    });
  };

  const getPermissionList = () => {
    permissionApi.getPermissions().then((response) => {
      if (response?.data) {
        setPermissionList(response.data);
      }
    });
  };

  const getReportContainerList = () => {
    reportApi.getReportContainerList(props.client.id!).then((response) => {
      if (response?.data) {
        setReportContainerList(response.data);
      }
    });
  };

  React.useEffect(() => {
    reportApi.getReportDataTypeFormatList().then((response) => {
      if (response.status === ApiResultStatusEnum.Ok) {
        setDataTypeFormatList(response.data);
      } else {
        //TODO: error
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reportID]);

  React.useEffect(() => {
    if (props.client.id) {
      reportApi
        .getDictionaryList(props.client.id)
        .then((response: GetReportDictionariesApiResultType) => {
          if (response.status === ApiResultStatusEnum.Ok) {
            setDictionaries(response.data);
          } else {
            //TODO: error
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.client.id]);

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  return (
    <>
      <ProgressSpinner isDisplayed={isBlockedUi}>
        {reportData && editedReportData ? (
          <Fragment>
            <EditReportHeader
              reportName={reportData.reportName}
              procedureName={reportData.definitionProcedureName}
              apiName={reportData.sourceName}
            />
            <EditReportTabsContainer
              isEditable={isEditable}
              defaultTab={props.defaultTab}
              reportData={editedReportData}
              dictionaries={dictionaries}
              dataTypeFormatList={dataTypeFormatList ?? []}
              permissionList={
                reportData?.permissionID
                  ? Array.from(
                      new Set([
                        ...permissionList,
                        {
                          isSelected: true,
                          permissionID: reportData.permissionID,
                          name: reportData.permissionName ?? "",
                        } as GetPermissionsApiResponseType,
                      ])
                    )
                  : permissionList
              }
              reportContainerList={reportContainerList}
              changeReportData={changeEditedReportData}
              newAddedStoredProcedureParams={newAddedStoredProcedureParams}
              handleChangeNewStoredProcedureParam={
                handleChangeNewStoredProcedureParam
              }
              handleSave={handleSave}
              handleEdit={() => setIsEditable(true)}
              handleCancel={handleCancel}
            />
          </Fragment>
        ) : (
          ""
        )}
      </ProgressSpinner>
      <InfoDialog
        isOpen={!!infoDialogText}
        text={infoDialogText ?? ""}
        handleOkClick={() => setInfoDialog(undefined)}
      />
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </>
  );
};

export default EditReport;

export const mapToSaveModelType = (
  data: GetReportDetailsApiResponseType,
  newAddedStoredProcedureParams: SaveReportStoredProcedureParameterType[]
): SaveReportDetailsModelType => {
  return {
    ...data,
    parameters: data.parameters.map((p, index) => ({
      ...p,
      iAReportDataTypeFormatID: p.dataTypeFormatID,
      orderNo: index + 1,
    })),
    columns: data.columns.map((c, index) => ({
      ...c,
      iAReportDataTypeFormatID: c.dataTypeFormatID,
      orderNo: index + 1,
      displayNameStoredProcedureRowIndex:
        c?.displayNameStoredProcedureRowIndex !== undefined
          ? Number(c?.displayNameStoredProcedureRowIndex)
          : undefined,
    })),
    summaryParameters: data.summaryParameters.map((p, index) => ({
      ...p,
      iAReportDataTypeFormatID: p.dataTypeFormatID,
      orderNo: index + 1,
    })),
    summaryColumns: data.summaryColumns.map((c, index) => ({
      ...c,
      iAReportDataTypeFormatID: c.dataTypeFormatID,
      orderNo: index + 1,
    })),
    detailsParameters: data.detailsParameters.map((p, index) => ({
      ...p,
      iAReportDataTypeFormatID: p.dataTypeFormatID,
      orderNo: index + 1,
    })),
    columnsDisplayNameStoredProcedureParameters:
      data.columnsDisplayNameStoredProcedureParameters?.map((c) => ({
        ...c,
        columnsDisplayNameStoredProcedureParameterID:
          c.columnsDisplayNameStoredProcedureParameterID < 0
            ? 0
            : c.columnsDisplayNameStoredProcedureParameterID,
      })),
    storedProcedureParameters: [
      ...flatten(
        data.parameters
          .map((p) =>
            p.storedProcedureParameters.map(
              (s) =>
                ({
                  ...s,
                  parameterIndexID: s.parameterStoredProcedureParameterID, //?
                } as SaveReportStoredProcedureParameterType)
            )
          )
          .concat(
            data.summaryParameters.map((p) =>
              p.storedProcedureParameters.map(
                (s) =>
                  ({
                    ...s,
                    parameterIndexID: s.parameterStoredProcedureParameterID, //?
                  } as SaveReportStoredProcedureParameterType)
              )
            )
          )
          .concat(
            data.detailsParameters.map((p) =>
              p.storedProcedureParameters.map(
                (s) =>
                  ({
                    ...s,
                    parameterIndexID: s.parameterStoredProcedureParameterID, //?
                  } as SaveReportStoredProcedureParameterType)
              )
            )
          )
      ),
      ...newAddedStoredProcedureParams,
    ],
    permissionID: data.permissionID,
    reportContainerIDs: data.reportContainers?.map((c) => c.reportContainerID),
  } as SaveReportDetailsModelType;
};
