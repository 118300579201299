import { Grid, ListSubheader } from "@material-ui/core";
import TypographyInfo from "Components/Typography/typography-info";
import { orderBy } from "lodash";
import * as React from "react";
import CustomListItem from "./custom-list-item";
import { stylesMoveListEditor } from "./styles";
import { MoveListEditorItemType } from "./types";

interface CustomListProps {
  isAllSelected: boolean;
  selectAll: () => void;
  items: MoveListEditorItemType[] | undefined;
  checked: number[];
  handleToggleItem: (value: number) => void;
}
export const CustomList: React.FunctionComponent<CustomListProps> = ({
  isAllSelected,
  selectAll,
  handleToggleItem,
  checked,
  items,
}) => {
  const customStyles = stylesMoveListEditor();
  return (
    <Grid className={customStyles.list}>
      <ListSubheader className={customStyles.subHeader}>
        <CustomListItem
          id={-1}
          checked={isAllSelected}
          handleClick={selectAll}
          text="Select all"
          className="listItemSubHeader"
        />
      </ListSubheader>
      {!!items?.length ? (
        orderBy(items, (i) => i.label.toLocaleLowerCase()).map(
          (item: MoveListEditorItemType) => (
            <CustomListItem
              id={item.id}
              checked={checked.indexOf(item.id) !== -1}
              handleClick={() => {
                handleToggleItem(item.id);
              }}
              text={item.label}
              className="listItem"
            />
          )
        )
      ) : (
        <TypographyInfo text="No items" margin="16px" />
      )}
    </Grid>
  );
};
export default CustomList;
