import { makeStyles } from "@material-ui/core";
import { IFileUploadProps } from "Components/File/interfaces-file";
import Colors from "Common/colors";

export const stylesFileUpload = makeStyles({
    centerItem: (props: IFileUploadProps) => ({
        width: props.width ? props.width : '200px',
        height: props.height ? props.height : '200px',
        display: 'table'
    }),
    fileInfo: {
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'table-cell',
    },
    container: (props: IFileUploadProps) => ({
        border: `2px dashed ${props.color ? props.color : Colors.gray}`,
        margin: props.margin ? props.margin : '15px',
        width: props.width ? props.width : '200px',
        height: props.height ? props.height : '200px',
        outline: 'none',
        textAlign: 'center'
    })
})