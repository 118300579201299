import { makeStyles, Theme } from '@material-ui/core';
import Colors from 'Common/colors';

export const stylesReportsListItem = makeStyles((theme: Theme) => ({
  row: {
    borderBottom: `1px solid ${theme.palette.graySeparate.main}`,
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    color: '#444444',
  },
  disabledRow: {
    color: Colors.gray,
  },
  selectedRow: {
    backgroundColor: Colors.grayLight,
  },
  cell: {
    padding: '8px 8px 8px 7px',
    fontSize: '14px',
    wordBreak: 'break-all',
    borderBottom: `1px solid ${theme.palette.graySeparate.main}`,
    borderRight: `1px solid ${theme.palette.graySeparate.main}`,
  },
  cellBreakWord: {
    padding: '8px 8px 8px 7px',
    fontSize: '14px',
    wordBreak: 'break-word',
  },
  cellIsEnabled: {
    textAlign: 'center',
    padding: '8px 0 8px 0',
  },
  cellLink: {
    textDecoration: 'none',
    color: '#444444',
  },
  disabledCellLink: {
    textDecoration: 'none',
    color: theme.palette.graySeparate.main,
  },
  refreshIcon: {
    width: '16px',
    height: 'auto',
    marginTop: '2px',
  },
}));
