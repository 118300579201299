import * as React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { GetReportColumnListType } from '../../../../Types/report-types';
import { stylesFont } from './styles';
import { ReportColumnSortType } from '../../../../Common/enum';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

export interface SelectSortProps {
  data: GetReportColumnListType;
  list: GetReportColumnListType[];
  handleChangeColumns: (columns: GetReportColumnListType[]) => void;
}

const SelectSort: React.FC<SelectSortProps> = ({
  data,
  list,
  handleChangeColumns,
}) => {
  const customStyles = stylesFont();
  const [value, setValue] = React.useState<ReportColumnSortType>(data.sortType);

  const handleChangeSort = (
    event: React.ChangeEvent<{ value: unknown }>,
    columnID: number
  ): void => {
    const sortType: ReportColumnSortType = event.target.value as number;

    if (
      sortType === ReportColumnSortType.SortDefaultByAsc ||
      sortType === ReportColumnSortType.SortDefaultByDesc
    ) {
      handleChangeColumns(
        list.map((p) =>
          p.columnID === columnID
            ? { ...p, sortType }
            : p.sortType === ReportColumnSortType.SortDefaultByAsc ||
              p.sortType === ReportColumnSortType.SortDefaultByDesc
            ? {
                ...p,
                sortType: ReportColumnSortType.Sort,
              }
            : p
        )
      );
    } else {
      handleChangeColumns(
        list.map((p) => (p.columnID === columnID ? { ...p, sortType } : p))
      );
    }
  };

  React.useEffect(() => {
    setValue(data.sortType);
  }, [data.sortType]);

  return (
    <Select
      fullWidth
      value={value}
      disableUnderline={true}
      classes={{
        root: customStyles.input,
      }}
      style={
        data.sortType === ReportColumnSortType.SortDefaultByDesc ||
        data.sortType === ReportColumnSortType.SortDefaultByAsc
          ? { fontWeight: 'bold' }
          : {}
      }
      onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
        setValue(event.target.value as number)
      }
      onBlur={(event: React.ChangeEvent<{ value: unknown }>) =>
        handleChangeSort(event, data.columnID)
      }
    >
      <MenuItem
        classes={{ root: customStyles.input }}
        value={ReportColumnSortType.Sort}
      >
        {ReportColumnSortType[ReportColumnSortType.Sort]}
      </MenuItem>
      <MenuItem
        classes={{ root: customStyles.input }}
        style={{ fontWeight: 'bold' }}
        value={ReportColumnSortType.SortDefaultByDesc}
      >
        <span>
          Default
          <ArrowUpward
            style={{ width: '15px', marginLeft: '5px', marginBottom: '-7px' }}
          />
        </span>
      </MenuItem>
      <MenuItem
        classes={{ root: customStyles.input }}
        style={{ fontWeight: 'bold' }}
        value={ReportColumnSortType.SortDefaultByAsc}
      >
        <span>
          Default
          <ArrowDownward
            style={{ width: '15px', marginLeft: '5px', marginBottom: '-7px' }}
          />
        </span>
      </MenuItem>
      <MenuItem
        classes={{ root: customStyles.input }}
        value={ReportColumnSortType.Never}
      >
        {ReportColumnSortType[ReportColumnSortType.Never]}
      </MenuItem>
    </Select>
  );
};

export default SelectSort;
