import * as React from "react";
import * as _ from "lodash";
import { Grid } from "@material-ui/core";
import { Duration, MaintenanceListItemProps } from "../interfaces";
import { EmailOutlined } from "@material-ui/icons";
import moment from "moment";
import ButtonEdit from "Components/Buttons/ButtonEdit/button-edit";
import TypographySubinfo from "Components/Typography/typography-subinfo";
import "moment-duration-format";
import ButtonDelete from "Components/Buttons/ButtonDelete/button-delete";
import SvgCalendar from "Components/Images/image-calendar";

const MaintenanceListItem: (props: MaintenanceListItemProps) => JSX.Element = (
  props: MaintenanceListItemProps
): JSX.Element => {
  const { item, ...other } = props;

  return (
    <React.Fragment>
      <Grid container item xs={5} alignItems="center">
        <SvgCalendar />
        <TypographySubinfo
          text={`${moment
            .utc(item.startDateUTC)
            .local()
            .format("DD/MM/YYYY HH:mm a")} - ${(
            moment.duration(
              moment.utc(item.endDateUTC).diff(moment.utc(item.startDateUTC))
            ) as Duration
          ).format("d[d] h[h] m[m]", { trim: "both" })}`}
          fontSize={"14px"}
          margin="0 0 0 10px"
        />
      </Grid>
      <Grid container item xs={6} alignItems="center">
        <EmailOutlined />
        <TypographySubinfo
          text={_.join(item.notificationMailDistributionList, ";")}
          fontSize={"14px"}
          margin="0 0 0 10px"
        />
      </Grid>
      <Grid container item xs={1} justify="flex-end" alignItems="center">
        {moment.utc(item.startDateUTC) > moment.utc() && (
          <>
            <ButtonDelete
              iconWidth="20px"
              handleClick={() => other.handleDelete(item.maintenanceID)}
            />
            <ButtonEdit
              iconWidth="25px"
              handleClick={() => other.handleOpenEditor(item.maintenanceID)}
            />
          </>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default MaintenanceListItem;
