import { makeStyles, Theme } from "@material-ui/core";

export const stylesEditReportHeader = makeStyles((theme: Theme) => ({
  container: {
    marginTop: "25px",
  },
  subinfo: {
    fontSize: "14px",
    lineHeight: "21px",
    color: theme.palette.primary.main,
  },
}));
