import * as React from 'react';

const SvgDetails = ({
  style = {},
  fill = 'none',
  width = '30px',
  className = '',
  color = 'black',
  viewBox = '0 0 30 30',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <circle cx="15" cy="15" r="14.5" stroke={color} />
    <circle cx="9" cy="15" r="1" fill={color} />
    <circle cx="15" cy="15" r="1" fill={color} />
    <circle cx="21" cy="15" r="1" fill={color} />
  </svg>
);

export default SvgDetails;
