import * as React from "react";
import * as Yup from "yup";
import { IForgotPasswordFormProps } from "../../../Components/Login/interfaces-login";
import SvgLogo from "../../Images/image-logo";
import { Fragment } from "react";
import { Formik } from "formik";
import ButtonSolid from "Components/Buttons/button-solid";
import { TextField } from "@material-ui/core";
import { AxiosError } from "axios";
import { ApiResultStatusEnum } from "Common/enum";
import UserApi from "Services/Api/user-api";
import { ForgottenUserPasswordApiResultType } from "Types/api-types";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";

const userApi = new UserApi();

const EMAIL_MAX_LENGTH: number = 100;

export default function ForgotPasswordForm(props: IForgotPasswordFormProps) {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [showError, setShowError] = React.useState<boolean>(false);
  const [showSuccess, setShowSuccess] = React.useState<boolean>(false);

  const handleSubmit = (email: string) => {
    forgottenUserPassword(email)
      .then(() => {})
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const forgottenUserPassword = (
    email: string
  ): Promise<ForgottenUserPasswordApiResultType | void> => {
    setShowError(false);
    return userApi
      .forgottenUserPassword({
        email: email,
        appGuid: process.env.REACT_APP_IA_GUID!,
      })
      .then((result) => {
        if (
          result &&
          result.status === ApiResultStatusEnum.Ok &&
          result.data.isSaved
        ) {
          setShowSuccess(true);
        } else {
          setErrorMessage("Error occurred while sending reset link");
          setShowError(true);
        }
      });
  };

  return (
    <Fragment>
      <SvgLogo width="auto" height="50px" />

      <br />
      <br />
      <div className="title">Reset Forgotten Password</div>
      {!showSuccess ? (
        <div>
          <div className="info">
            Please enter valid email address for your account.
            <br />
            You will be emailed a link to reset your password.
            <br />
            <br />
            <div className="italic">
              Please check your email account's Spam folder to ensure the
              message was not filtered.
            </div>
          </div>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={async (values) => {
              await handleSubmit(values.email);
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .label("Email")
                .email("Enter a valid email")
                .required("Required")
                .max(EMAIL_MAX_LENGTH)
                .trim(),
            })}
          >
            {(formProps) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
              } = formProps;
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(errors.email && touched.email)}
                    helperText={
                      (errors.email && touched.email && errors.email) ||
                      `${values.email.length}/${EMAIL_MAX_LENGTH}`
                    }
                    inputProps={{
                      maxLength: EMAIL_MAX_LENGTH,
                    }}
                    fullWidth
                    required
                    margin="normal"
                  />
                  <div className="link">
                    <a href="/">Back to login page</a>
                  </div>
                  <div className="errorInfo">
                    {showError ? errorMessage : ""}
                  </div>
                  <div className="buttons">
                    <ButtonSolid margin="10px" text="Send reset link" />
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <div>
          <div className="info">
            Link was sent to your email account.
            <br />
            <br />
            <div className="italic">
              Please check your email account's Spam folder to ensure the
              message was not filtered.
            </div>
          </div>
          <div className="link">
            <a href="/">Back to login page</a>
          </div>
        </div>
      )}
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </Fragment>
  );
}
