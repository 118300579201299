import { makeStyles, Theme } from "@material-ui/core";

export const stylesRoleEditor = makeStyles((theme: Theme) => ({
  roleCheckboxes: {
    maxHeight: "400px",
    overflowY: "auto",
  },
  checkbox: {
    margin: "0",
  },
  selectFormControl: {
    marginLeft: "10px",
    minWidth: "120px",
  },
  rolePropertiesGrid: {
    margin: "20px 0",
  },
  title: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "48px",
    color: theme.palette.grayDarker.main,
    borderBottom: "none",
  },
  dialogContent: {
    '&:first-child': {
        paddingTop: '5px'
    }
  },
  textField: {
      width: '245px',
  },
  notesTextFiled: {
      width: '520px',
      margin: '10px 15px'
  },
}));
