import * as React from 'react';
import moment from 'moment';
import Colors from 'Common/colors';
import TypographyInfo from 'Components/Typography/typography-info';
import TypographySubinfo from 'Components/Typography/typography-subinfo';
import { Container, Grid } from '@material-ui/core';
import { IWebAppMainListItemProps } from '../interfaces-web-app';
import { FormEditorEnum } from 'Common/enum';
import { head } from 'lodash';
import { WebAppInstanceDeploymentType } from 'Types/app-types';
import { stylesWebAppMainListItem } from './styles';
import WebAppReleaseEditor from 'Components/WebApp/WebAppReleaseEditor/web-app-release-editor';
import ClientAppApi from 'Services/Api/client-app-api';
import SvgCalendar from 'Components/Images/image-calendar';
import SvgGear from 'Components/Images/image-gear';

const clientAppApi = new ClientAppApi();

export default function WebAppMainListItem(props: IWebAppMainListItemProps) {
  const [lastDeployment, setLastDeployment] = React.useState<
    WebAppInstanceDeploymentType
  >({
    id: 0,
    iaWebAppInstanceID: props.instance.id,
    releaseDate: '',
    releaseNo: '-',
  });

  const customStyles = stylesWebAppMainListItem({});

  if (
    (props.instance.deploymentList || []).length > 0 &&
    lastDeployment.id === 0
  ) {
    setLastDeployment(
      head(props.instance.deploymentList) as WebAppInstanceDeploymentType
    );
  }

  const getWebAppInstanceDeploymentList = async (
    deploymentItemCount?: number
  ): Promise<void> => {
    await clientAppApi
      .getWebAppInstanceDeploymentList(props.instance.id, deploymentItemCount)
      .then((result) => {
        if (result && result.data) {
          setLastDeployment(head(result.data) as WebAppInstanceDeploymentType);
        }
      });
  };

  return (
    <Container className={customStyles.root}>
      <Grid container justify="flex-start">
        <Grid item xs={12}>
          <TypographyInfo
            text={props.instance.name}
            style={{ fontSize: '18px' }}
          />
        </Grid>
        <Grid item xs={8}>
          <TypographySubinfo
            text={`Release ${lastDeployment.releaseNo}`}
            color={Colors.grayDark}
            style={{ fontSize: '14px' }}
          />
        </Grid>
        <Grid item xs={4} className={customStyles.textRight}>
          <WebAppReleaseEditor
            editorType={FormEditorEnum.Edit}
            webAppInstanceId={props.instance.id}
            webAppInstanceDeploymentId={lastDeployment.id}
            getWebAppInstanceDeploymentList={getWebAppInstanceDeploymentList}
            platformType={props.platformType}
          />
          <WebAppReleaseEditor
            editorType={FormEditorEnum.Create}
            webAppInstanceId={props.instance.id}
            getWebAppInstanceDeploymentList={getWebAppInstanceDeploymentList}
            platformType={props.platformType}
          />
        </Grid>
        <Grid container item xs={4} alignItems="center" alignContent="center">
          <SvgCalendar />
          <TypographySubinfo
            text={
              lastDeployment.releaseDate
                ? moment(lastDeployment.releaseDate).format('DD/MM/YYYY')
                : '-'
            }
            margin="0 0 0 13px"
            style={{ fontSize: '14px' }}
          />
        </Grid>
        <Grid container item xs={4} alignItems="center" alignContent="center">
          <SvgGear />
          <TypographySubinfo
            text={lastDeployment.apiVersionNo || '-'}
            style={{ fontSize: '14px' }}
            margin="0 0 0 13px"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
