import * as React from "react";
import TypographyInfo from "Components/Typography/typography-info";
import TypographySubinfo from "Components/Typography/typography-subinfo";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";
import { Scrollbars } from 'react-custom-scrollbars';
import { IClientListProps } from "../interfaces-client";

export default function ClientList(props: IClientListProps) {
    const filterList = () => {
        return props.clientList.filter(client => client.name.toUpperCase().includes(props.searchValue.toUpperCase()))
    }
    
    return (
        <Scrollbars>
            <List>
            {filterList().map(client => {
                return (
                    <React.Fragment key={client.guid}>
                        <ListItem button onClick={() => props.clientClick(client.guid)}>
                            <ListItemText
                                primary={<TypographyInfo text={client.name} margin='0'/>}
                                secondary={<TypographySubinfo text={client.guid} isUppercase={true} margin='0'/>}
                            />
                        </ListItem>
                        <Divider variant="middle"/>
                    </React.Fragment>
                )
            })}
            </List>
        </Scrollbars>
    )
}