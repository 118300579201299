import * as React from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { IImageUpload } from "Components/Image/interfaces-image";
import TypographySubinfo from "Components/Typography/typography-subinfo";
import { useDropzone } from "react-dropzone";
import { stylesImageUpload } from "Components/Image/ImageUpload/styles";
import { Grid, Snackbar } from "@material-ui/core";
import SvgUploadLogo from "Components/Images/image-upload-logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ImageUpload(props: IImageUpload) {
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState<
    string | null | ArrayBuffer
  >("");
  const [showRemovePhotoButton, setShowRemovePhotoButton] =
    React.useState(false);
  const [isOpenErrorSnackbar, setIsOpenSnackbar] = React.useState(false);
  const customStyles = stylesImageUpload(props);
  let maxSize = props.maxSize ? props.maxSize : 4194304; //4MB

  React.useEffect(() => {
    const setImagePreview = (file: Blob): void => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
        setShowRemovePhotoButton(true);
      };
    };

    if (props.file) {
      setImagePreview(props.file);
    }
  }, [props.file]);

  const handleRemovePhoto = (): void => {
    props.setFile(undefined);
    setShowRemovePhotoButton(false);
  };

  const handleCloseErrorSnackbar = (): void => {
    setIsOpenSnackbar(false);
  };

  const onDrop = (files: Blob[]): void => {
    if (files.length > 0) {
      props.setFile(files[0]);
    }
  };

  const onDropRejected = (): void => {
    setIsOpenSnackbar(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: props.acceptedFormats,
    maxSize: maxSize,
    multiple: false,
    onDrop: onDrop,
    onDropRejected: onDropRejected,
  });

  return (
    <React.Fragment>
      <section>
        <div className={customStyles.container}>
          <input {...getInputProps()} />
          {props.file ? (
            <div
              className="img__wrap"
              style={{
                position: "relative",
                height: props.height ?? "200px",
                width: props.width ?? "200px",
              }}
            >
              <img
                alt="app_photo"
                className={customStyles.img}
                src={imagePreviewUrl!.toString()}
              />
              <p className="img__description" onClick={handleRemovePhoto}>
                <FontAwesomeIcon icon={faTrash} />
                <br />
                Remove photo
              </p>
            </div>
          ) : (
            <Grid
              container
              className={customStyles.centerItem}
              alignContent="center"
              alignItems="center"
              justify="center"
              {...getRootProps()}
            >
              <Grid item xs={12} style={{ paddingTop: "10px" }}>
                <SvgUploadLogo />
              </Grid>
              <TypographySubinfo
                text={`Max ${
                  maxSize > 0 ? Math.round(maxSize / 1048576) : 0
                } MB <br/> Min 200 px x 200 px <br/> ${props.acceptedFormats.join(
                  ", "
                )} formats`}
                style={{ fontSize: "10px" }}
              />
            </Grid>
          )}
        </div>
      </section>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={isOpenErrorSnackbar}
        onClose={handleCloseErrorSnackbar}
        autoHideDuration={6000}
        message="Unable to upload selected file!"
      />
    </React.Fragment>
  );
}
