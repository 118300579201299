import { makeStyles } from '@material-ui/core';

export const stylesSelectSourceDialog = makeStyles({
  content: {
    height: '500px',
    padding: '0 32px',
  },
  autocomplete: {
    height: '325px',
  },
});
