import "Styles/custom-styles-sweetalert2.scss";
import { useState } from "react";
import Swal, { SweetAlertResult } from "sweetalert2";

const useModalSuccess: (
  initialFunction?: () => void
) => (successResult: string) => Promise<SweetAlertResult> = (
  initialFunction?: () => void
): ((successResult: string) => Promise<SweetAlertResult>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [additionalFunction, setAdditionalFunction] = useState(
    () => initialFunction
  );

  const showModalSuccess: (successResult: string) => Promise<SweetAlertResult> =
    async (successResult: string): Promise<SweetAlertResult> => {
      return await Swal.fire({
        icon: "success",
        text: successResult,
        width: "25rem",
        customClass: {
          title: "swal-title",
          container: "swal-container",
          content: "swal-content",
          confirmButton: "swal-confirm-button",
        },

        onClose: () => {
          if (additionalFunction) {
            additionalFunction();
          }
        },
      });
    };

  return showModalSuccess;
};

export default useModalSuccess;
