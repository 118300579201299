import * as React from "react";
import * as _ from "lodash";
import { Container } from "@material-ui/core";
import { AppType } from "Types/app-types";
import Swal, { SweetAlertResult } from "sweetalert2";
import {
  MaintenanceData,
  MaintenanceProps,
} from "Components/Maintenance/interfaces";
import {
  ApiResultStatusEnum,
  AppPlatformEnum,
  AppTypeEnum,
  MaintenanceActivityTypeEnum,
} from "Common/enum";
import moment from "moment";
import Header from "Components/Header/header";
import ListBase from "Components/List/list-base";
import ClientAppApi from "Services/Api/client-app-api";
import TypographyInfo from "Components/Typography/typography-info";
import MaintenanceApi from "Services/Api/maintenance-api";
import MaintenanceEditor from "Components/Maintenance/MaintenanceEditors/maintenance-editor";
import MaintenanceListItem from "Components/Maintenance/MaintenanceLists/maintenance-list-item";
import MaintenanceListFilter from "Components/Maintenance/MaintenanceLists/maintenance-list-filter";
import useModalError from "Hooks/use-modal-error";
import useModalSuccess from "Hooks/use-modal-success";

const clientAppApi: ClientAppApi = new ClientAppApi();
const maintenanceApi: MaintenanceApi = new MaintenanceApi();

const Maintenance: (props: MaintenanceProps) => JSX.Element = (
  props: MaintenanceProps
): JSX.Element => {
  const showModalError: (
    errorResult: string,
    isHtml?: boolean
  ) => Promise<SweetAlertResult> = useModalError();
  const showModalSuccess: (successResult: string) => Promise<SweetAlertResult> =
    useModalSuccess();
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [maintenanceActivityType, setMaintenanceActivityType] =
    React.useState<MaintenanceActivityTypeEnum>(
      MaintenanceActivityTypeEnum.All
    );
  const [appType, setAppType] = React.useState<AppTypeEnum>(AppTypeEnum.All);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [appList, setAppList] = React.useState<AppType[]>([]);
  const [maintenanceId, setMaintenanceId] = React.useState<number>();
  const [maintenanceList, setMaintenanceList] = React.useState<
    MaintenanceData[]
  >([]);
  const [filteredMaintenanceList, setFilteredMaintenanceList] = React.useState<
    MaintenanceData[]
  >([]);

  const handleAfterSave: () => void = (): void => {
    setIsOpen(false);
    loadMaintenanceList();
  };

  const handleOpenEditor: (editedMaintenanceId?: number) => void = (
    editedMaintenanceId?: number
  ): void => {
    setMaintenanceId(editedMaintenanceId);
    setIsOpen(true);
  };

  const handleDelete: (maintenanceId?: number) => void = (
    maintenanceId?: number
  ): void => {
    if (maintenanceId) {
      Swal.fire({
        icon: "question",
        text: "Are you sure you want to remove maintenance?",
        width: "25rem",
        customClass: {
          title: "swal-title",
          container: "swal-container",
          content: "swal-content",
          confirmButton: "swal-confirm-button",
        },
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          maintenanceApi
            .delete({ maintenanceID: maintenanceId })
            .then((result) => {
              if (result.data.status === ApiResultStatusEnum.Ok) {
                showModalSuccess("Successful removal of maintenance").then(() =>
                  loadMaintenanceList()
                );
              } else {
                showModalError("An error occured. Please, try again later");
              }
            })
            .catch((error) => {
              switch (error.response.data.status) {
                case ApiResultStatusEnum.MaintenanceNotFound:
                  showModalError("Maintenance was not found");
                  break;
                case ApiResultStatusEnum.CannotDelete:
                  showModalError(
                    "Maintenance cannot be removed - it is active or has already ended"
                  );
                  break;
                default:
                  showModalError("An error occured. Please, try again later");
                  break;
              }
            });
        }
      });
    }
  };

  const loadMaintenanceList: () => void = (): void => {
    if (props.clientID) {
      maintenanceApi.getList(props.clientID).then((result) => {
        if (result?.data) {
          setMaintenanceList(result.data);
          setFilteredMaintenanceList(result.data);
        }
      });
    }
  };

  const loadClientAppList: () => void = (): void => {
    if (props.clientID) {
      clientAppApi.getAllAppList(props.clientID, false).then((result) => {
        if (result?.data) {
          setAppList(result.data);
        }
      });
    }
  };

  const renderTitle: (item: MaintenanceData) => React.ReactNode = (
    item: MaintenanceData
  ): React.ReactNode => {
    return <TypographyInfo text={item.appName ?? ""} />;
  };

  const renderItem: (item: MaintenanceData) => React.ReactNode = (
    item: MaintenanceData
  ): React.ReactNode => {
    return (
      <MaintenanceListItem
        item={item}
        handleAfterSave={handleAfterSave}
        handleOpenEditor={handleOpenEditor}
        handleDelete={handleDelete}
      ></MaintenanceListItem>
    );
  };

  React.useEffect(() => {
    loadMaintenanceList();
    loadClientAppList();
  }, [props.clientID]);

  React.useEffect(() => {
    let oldMaintenanceList = [...maintenanceList];
    let newMaintenanceList: MaintenanceData[] =
      _.filter(oldMaintenanceList, (m) => {
        return (
          ((m.appName?.toLowerCase().includes(searchValue.toLowerCase()) ??
            false) ||
            (m.notificationSmsDistributionListText
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ??
              false) ||
            (m.notificationMailDistributionListText
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ??
              false)) &&
          (maintenanceActivityType === MaintenanceActivityTypeEnum.All ||
            (maintenanceActivityType === MaintenanceActivityTypeEnum.Finished &&
              moment(m.endDateUTC) < moment.utc()) ||
            (maintenanceActivityType ===
              MaintenanceActivityTypeEnum.Unfinished &&
              moment(m.endDateUTC) >= moment.utc())) &&
          (appType === AppTypeEnum.All ||
            (appType === AppTypeEnum.WebApp &&
              m.appPlatformType === AppPlatformEnum.Web) ||
            (appType === AppTypeEnum.WebService &&
              m.appPlatformType === AppPlatformEnum.WebService) ||
            (appType === AppTypeEnum.MobileApp &&
              (m.appPlatformType === AppPlatformEnum.Android ||
                m.appPlatformType === AppPlatformEnum.IOS)))
        );
      }) ?? [];
    setFilteredMaintenanceList(newMaintenanceList);
  }, [searchValue, maintenanceActivityType, appType]);

  return (
    <React.Fragment>
      <Container>
        <Header
          headerText="Maintenance periods"
          withAddButton={true}
          addButtonText="Add period"
          handleAddButtonClick={() => handleOpenEditor()}
        />
        <MaintenanceEditor
          editorIsOpen={isOpen}
          clientID={props.clientID}
          handleToggle={setIsOpen}
          appList={appList}
          maintenanceId={maintenanceId}
          handleAfterSave={handleAfterSave}
        ></MaintenanceEditor>
        <MaintenanceListFilter
          searchText={searchValue}
          maintenanceActivityType={maintenanceActivityType}
          appType={appType}
          handleSearchChange={setSearchValue}
          handleActivityTypeChange={setMaintenanceActivityType}
          handleAppTypeChange={setAppType}
        ></MaintenanceListFilter>
        <ListBase<MaintenanceData>
          itemList={filteredMaintenanceList}
          dataLength={filteredMaintenanceList.length}
          renderTitle={renderTitle}
          renderItem={renderItem}
        ></ListBase>
      </Container>
    </React.Fragment>
  );
};

export default Maintenance;
