import { LandingPageEnum } from 'Common/enum';
import {
  RouteApplications,
  RouteCustomerPermissions,
  RouteReportsAccessRights,
  RouteReportsAppsReports,
  RouteReportsDictionaries,
  RouteReportsReports,
  RouteReportsReportsContainers,
  RouteRoles,
  RouteSystem,
  RouteSystemAccessRights,
  RouteSystemAppsPermissions,
  RouteSystemCustomers,
  RouteSystemPermissions,
  RouteUsers,
} from './Routes';

export type LandingPageRoute = {
  page: LandingPageEnum;
  route: string;
};

export const LandingPagesRoutes: LandingPageRoute[] = [
  {
    page: LandingPageEnum.Applications,
    route: RouteApplications,
  },
  { page: LandingPageEnum.Users, route: RouteUsers },
  { page: LandingPageEnum.Roles, route: RouteRoles },
  { page: LandingPageEnum.SystemUsers, route: RouteSystem },
  { page: LandingPageEnum.SystemPermissions, route: RouteSystemPermissions },
  {
    page: LandingPageEnum.SystemAppsPermissions,
    route: RouteSystemAppsPermissions,
  },
  { page: LandingPageEnum.AccessRights, route: RouteSystemAccessRights },
  { page: LandingPageEnum.SystemCustomers, route: RouteSystemCustomers },
  {
    page: LandingPageEnum.ReportsDictionaries,
    route: RouteReportsDictionaries,
  },
  { page: LandingPageEnum.ReportsReports, route: RouteReportsReports },
  {
    page: LandingPageEnum.ReportsReportsContainers,
    route: RouteReportsReportsContainers,
  },
  { page: LandingPageEnum.ReportsAppsReports, route: RouteReportsAppsReports },
  {
    page: LandingPageEnum.AccessRights,
    route: RouteReportsAccessRights,
  },
  {
    page: LandingPageEnum.CustomerPermissions,
    route: RouteCustomerPermissions,
  },
];
