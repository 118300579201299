import * as React from "react";
import { Formik } from "formik";
import {
  ApiResultStatusEnum,
  IconType,
  AppsPermissionsDefaultTab,
} from "Common/enum";
import { stylesAppsPermissionsListItem } from "Components/Permission/AppsPermissions/styles";
import { SaveUserApiResultType } from "Types/api-types";
import ButtonOutlined from "Components/Buttons/button-outlined";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "Components/TabPanel/tab-panel";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import ButtonEdit from "Components/Buttons/ButtonEdit/button-edit";
import { AxiosError } from "axios";
import { IAppsPermissionsEditorProps } from "Components/Permission/interfaces-permission";
import TypographyInfo from "Components/Typography/typography-info";
import PermissionApi from "Services/Api/permission-api";
import ReportApi from "Services/Api/report-api";
import {
  GetPermissionGroupsForAppApiResponseType,
  SaveAppPermissionGroupsModelType,
} from "Types/permission-types";
import {
  GetReportContainersForAppApiResponseType,
  SaveAppReportContainersModelType,
} from "Types/report-types";
import SvgArrowRight from "Components/Images/image-arrow-right";
import SvgTrash from "Components/Images/image-trash";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import SearchField from "Components/Inputs/SearchField/search-field";

const permissionApi = new PermissionApi();
const reportApi = new ReportApi();

export default function AppsPermissionsEditor(
  props: IAppsPermissionsEditorProps
) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [permissionGroupsForAppAll, setPermissionGroupsForAppAll] =
    React.useState<GetPermissionGroupsForAppApiResponseType[]>();
  const [
    filteredPermissionGroupsForAppAll,
    setFilteredPermissionGroupsForAppAll,
  ] = React.useState<GetPermissionGroupsForAppApiResponseType[]>();
  const [permissionGroupsForAppSelected, setPermissionGroupsForAppSelected] =
    React.useState<GetPermissionGroupsForAppApiResponseType[]>();
  const [
    filteredPermissionGroupsForAppSelected,
    setFilteredPermissionGroupsForAppSelected,
  ] = React.useState<GetPermissionGroupsForAppApiResponseType[]>();
  const [reportContainersForAppAll, setReportContainersForAppAll] =
    React.useState<GetReportContainersForAppApiResponseType[]>();
  const [
    filteredReportContainersForAppAll,
    setFilteredReportContainersForAppAll,
  ] = React.useState<GetReportContainersForAppApiResponseType[]>();
  const [reportContainersForAppSelected, setReportContainersForAppSelected] =
    React.useState<GetReportContainersForAppApiResponseType[]>();
  const [
    filteredReportContainersForAppSelected,
    setFilteredReportContainersForAppSelected,
  ] = React.useState<GetReportContainersForAppApiResponseType[]>();
  const [groupsCountAll, setGroupsCountAll] = React.useState(0);
  const [groupsCountSelected, setGroupsCountSelected] = React.useState(0);
  const [checkedAll, setCheckedAll] = React.useState<number[]>([]);
  const [checkedSelected, setCheckedSelected] = React.useState<number[]>([]);
  const [groupsCountAllContainers, setGroupsCountAllContainers] =
    React.useState(0);
  const [groupsCountSelectedContainers, setGroupsCountSelectedContainers] =
    React.useState(0);
  const [checkedAllContainers, setCheckedAllContainers] = React.useState<
    number[]
  >([]);
  const [checkedSelectedContainers, setCheckedSelectedContainers] =
    React.useState<number[]>([]);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const getAppsWithPermissions = React.useCallback(async (): Promise<void> => {
    if (isOpen) {
      setSelectedTab(
        props.defaultTab === AppsPermissionsDefaultTab.PermissionGroups ||
          !props.app.canAssignReports
          ? 0
          : 1
      );

      if (props.app.canAssignPermissionGroups) {
        await permissionApi
          .getPermissionGroupsForApp(props.app.appID)
          .then((result) => {
            if (result && result.data) {
              const allPerm: GetPermissionGroupsForAppApiResponseType[] =
                result.data.filter((item) => !item.isSelected);
              const selectedPerm: GetPermissionGroupsForAppApiResponseType[] =
                result.data.filter((item) => item.isSelected);

              setPermissionGroupsForAppAll(allPerm);
              setFilteredPermissionGroupsForAppAll(allPerm);
              setGroupsCountAll(allPerm.length);

              setPermissionGroupsForAppSelected(selectedPerm);
              setFilteredPermissionGroupsForAppSelected(selectedPerm);
              setGroupsCountSelected(selectedPerm.length);
            }
          });
      }

      if (props.app.canAssignReports) {
        await reportApi
          .getReportContainersForApp(props.app.appID)
          .then((result) => {
            if (result && result.data) {
              const allRep: GetReportContainersForAppApiResponseType[] =
                result.data.filter((item) => !item.isSelected);
              const selectedRep: GetReportContainersForAppApiResponseType[] =
                result.data.filter((item) => item.isSelected);

              setReportContainersForAppAll(allRep);
              setFilteredReportContainersForAppAll(allRep);
              setGroupsCountAllContainers(allRep.length);

              setReportContainersForAppSelected(selectedRep);
              setFilteredReportContainersForAppSelected(selectedRep);
              setGroupsCountSelectedContainers(selectedRep.length);
            }
          });
      }
    }
  }, [
    props.app.appID,
    props.defaultTab,
    props.app.canAssignPermissionGroups,
    props.app.canAssignReports,
    isOpen,
  ]);

  React.useEffect(() => {
    getAppsWithPermissions();
  }, [getAppsWithPermissions]);

  const customStyles = stylesAppsPermissionsListItem();

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const handleOpen = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setIsOpen(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setIsOpen(false);
  };

  const searchByText = (
    list: GetPermissionGroupsForAppApiResponseType[],
    text?: string
  ): GetPermissionGroupsForAppApiResponseType[] => {
    if (text) {
      list = list.filter((x) => x.name.toLowerCase().includes(text!));
    }
    return list;
  };

  const searchByTextContainers = (
    list: GetReportContainersForAppApiResponseType[],
    text?: string
  ): GetReportContainersForAppApiResponseType[] => {
    if (text) {
      list = list.filter((x) =>
        x.reportContainerName.toLowerCase().includes(text!)
      );
    }
    return list;
  };

  const searchAll = (searchedText: string): void => {
    const permissionGroups: GetPermissionGroupsForAppApiResponseType[] =
      searchByText(
        permissionGroupsForAppAll ? permissionGroupsForAppAll : [],
        searchedText
      );
    setFilteredPermissionGroupsForAppAll(permissionGroups);
    setGroupsCountAll(permissionGroups.length);
    setCheckedAll([]);
  };

  const searchAllContainers = (searchedText: string): void => {
    const reportContainers: GetReportContainersForAppApiResponseType[] =
      searchByTextContainers(
        reportContainersForAppAll ? reportContainersForAppAll : [],
        searchedText
      );
    setFilteredReportContainersForAppAll(reportContainers);
    setGroupsCountAllContainers(reportContainers.length);
    setCheckedAllContainers([]);
  };

  const onSearchChangeAll = (value: React.SetStateAction<string>): void => {
    let searchedText: string = value.toString().toLowerCase();
    searchedText = searchedText ? searchedText : "";
    searchAll(searchedText);
  };

  const onSearchChangeAllContainers = (
    value: React.SetStateAction<string>
  ): void => {
    let searchedText: string = value.toString().toLowerCase();
    searchedText = searchedText ? searchedText : "";
    searchAllContainers(searchedText);
  };

  const handleToggleAll = (value: number) => () => {
    const currentIndex = checkedAll.indexOf(value);
    const newChecked = [...checkedAll];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedAll(newChecked);
  };

  const handleToggleAllContainers = (value: number) => () => {
    const currentIndex = checkedAllContainers.indexOf(value);
    const newChecked = [...checkedAllContainers];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedAllContainers(newChecked);
  };

  const searchSelected = (searchedText: string): void => {
    const permissionGroups: GetPermissionGroupsForAppApiResponseType[] =
      searchByText(
        permissionGroupsForAppSelected ? permissionGroupsForAppSelected : [],
        searchedText
      );
    setFilteredPermissionGroupsForAppSelected(permissionGroups);
    setGroupsCountSelected(permissionGroups.length);
    setCheckedSelected([]);
  };

  const searchSelectedContainers = (searchedText: string): void => {
    const reportContainers: GetReportContainersForAppApiResponseType[] =
      searchByTextContainers(
        reportContainersForAppSelected ? reportContainersForAppSelected : [],
        searchedText
      );
    setFilteredReportContainersForAppSelected(reportContainers);
    setGroupsCountSelectedContainers(reportContainers.length);
    setCheckedSelectedContainers([]);
  };

  const onSearchChangeSelected = (
    value: React.SetStateAction<string>
  ): void => {
    let searchedText: string = value.toString().toLowerCase();
    searchedText = searchedText ? searchedText : "";
    searchSelected(searchedText);
  };

  const onSearchChangeSelectedContainers = (
    value: React.SetStateAction<string>
  ): void => {
    let searchedText: string = value.toString().toLowerCase();
    searchedText = searchedText ? searchedText : "";
    searchSelectedContainers(searchedText);
  };

  const handleToggleSelected = (value: number) => () => {
    const currentIndex = checkedSelected.indexOf(value);
    const newChecked = [...checkedSelected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedSelected(newChecked);
  };

  const handleToggleSelectedContainers = (value: number) => () => {
    const currentIndex = checkedSelectedContainers.indexOf(value);
    const newChecked = [...checkedSelectedContainers];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedSelectedContainers(newChecked);
  };

  const handleSubmit = () => {
    if (props.app.canAssignPermissionGroups) {
      saveAppPermissionGroups()
        .then(() => {})
        .catch((error) => {
          console.error(error);
          setErrorResponse(error);
          setIsErrorDialogOpen(true);
        });
    }
    if (props.app.canAssignReports) {
      saveAppReportContainers()
        .then(() => {})
        .catch((error) => {
          console.error(error);
          setErrorResponse(error);
          setIsErrorDialogOpen(true);
        });
    }
  };

  const saveAppPermissionGroups =
    async (): Promise<SaveUserApiResultType | void> => {
      const appPerm: SaveAppPermissionGroupsModelType = {
        appID: props.app.appID,
        permissionGroups: permissionGroupsForAppSelected
          ? permissionGroupsForAppSelected.map((p) => p.permissionGroupID)
          : [],
      };

      const result = await permissionApi.saveAppPermissionGroups(appPerm);
      if (result && result.status === ApiResultStatusEnum.Ok) {
        setIsOpen(false);
        if (props.getAppsWithPermissions) {
          props.getAppsWithPermissions();
        }
      } else {
        console.log("Error saving AppPermissionGroups");
      }
    };

  const saveAppReportContainers =
    async (): Promise<SaveUserApiResultType | void> => {
      const appRep: SaveAppReportContainersModelType = {
        appID: props.app.appID,
        reportContainers: reportContainersForAppSelected
          ? reportContainersForAppSelected.map((p) => p.reportContainerID)
          : [],
      };

      const result = await reportApi.saveAppReportContainers(appRep);
      if (result && result.status === ApiResultStatusEnum.Ok) {
        setIsOpen(false);
        if (props.getAppsWithPermissions) {
          props.getAppsWithPermissions();
        }
      } else {
        console.log("Error saving AppReportContainers");
      }
    };

  const moveToSelected = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (
      filteredPermissionGroupsForAppAll &&
      filteredPermissionGroupsForAppSelected &&
      permissionGroupsForAppAll &&
      permissionGroupsForAppSelected
    ) {
      // add to selected list and remove from all list
      const allPerm: GetPermissionGroupsForAppApiResponseType[] =
        permissionGroupsForAppAll.filter(
          (item) => checkedAll.indexOf(item.permissionGroupID) === -1
        );
      const movePerm: GetPermissionGroupsForAppApiResponseType[] =
        filteredPermissionGroupsForAppAll.filter(
          (item) => checkedAll.indexOf(item.permissionGroupID) > -1
        );
      const selectedPerm: GetPermissionGroupsForAppApiResponseType[] =
        permissionGroupsForAppSelected.concat(movePerm);
      const allPermFiltered: GetPermissionGroupsForAppApiResponseType[] =
        filteredPermissionGroupsForAppAll.filter(
          (item) => checkedAll.indexOf(item.permissionGroupID) === -1
        );
      const selectedPermFiltered: GetPermissionGroupsForAppApiResponseType[] =
        filteredPermissionGroupsForAppSelected.concat(movePerm);

      setPermissionGroupsForAppAll(allPerm);
      setFilteredPermissionGroupsForAppAll(allPermFiltered);
      setGroupsCountAll(allPermFiltered.length);

      setPermissionGroupsForAppSelected(selectedPerm);
      setFilteredPermissionGroupsForAppSelected(selectedPermFiltered);
      setGroupsCountSelected(selectedPermFiltered.length);

      setCheckedAll([]);
      setCheckedSelected([]);
    }
  };

  const moveToSelectedContainers = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (
      filteredReportContainersForAppAll &&
      filteredReportContainersForAppSelected &&
      reportContainersForAppAll &&
      reportContainersForAppSelected
    ) {
      // add to selected list and remove from all list
      const allRep: GetReportContainersForAppApiResponseType[] =
        reportContainersForAppAll.filter(
          (item) => checkedAllContainers.indexOf(item.reportContainerID) === -1
        );
      const moveRep: GetReportContainersForAppApiResponseType[] =
        filteredReportContainersForAppAll.filter(
          (item) => checkedAllContainers.indexOf(item.reportContainerID) > -1
        );
      const selectedRep: GetReportContainersForAppApiResponseType[] =
        reportContainersForAppSelected.concat(moveRep);
      const allRepFiltered: GetReportContainersForAppApiResponseType[] =
        filteredReportContainersForAppAll.filter(
          (item) => checkedAllContainers.indexOf(item.reportContainerID) === -1
        );
      const selectedRepFiltered: GetReportContainersForAppApiResponseType[] =
        filteredReportContainersForAppSelected.concat(moveRep);

      setReportContainersForAppAll(allRep);
      setFilteredReportContainersForAppAll(allRepFiltered);
      setGroupsCountAllContainers(allRepFiltered.length);

      setReportContainersForAppSelected(selectedRep);
      setFilteredReportContainersForAppSelected(selectedRepFiltered);
      setGroupsCountSelectedContainers(selectedRepFiltered.length);

      setCheckedAllContainers([]);
      setCheckedSelectedContainers([]);
    }
  };

  const deleteFromSelected = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (
      filteredPermissionGroupsForAppAll &&
      filteredPermissionGroupsForAppSelected &&
      permissionGroupsForAppAll &&
      permissionGroupsForAppSelected
    ) {
      // add to all list and remove from selected list
      const selectedPerm: GetPermissionGroupsForAppApiResponseType[] =
        permissionGroupsForAppSelected.filter(
          (item) => checkedSelected.indexOf(item.permissionGroupID) === -1
        );
      const deletePerm: GetPermissionGroupsForAppApiResponseType[] =
        filteredPermissionGroupsForAppSelected.filter(
          (item) => checkedSelected.indexOf(item.permissionGroupID) > -1
        );
      const allPerm: GetPermissionGroupsForAppApiResponseType[] =
        permissionGroupsForAppAll.concat(deletePerm);
      const selectedPermFiltered: GetPermissionGroupsForAppApiResponseType[] =
        filteredPermissionGroupsForAppSelected.filter(
          (item) => checkedSelected.indexOf(item.permissionGroupID) === -1
        );
      const allPermFiltered: GetPermissionGroupsForAppApiResponseType[] =
        filteredPermissionGroupsForAppAll.concat(deletePerm);

      setPermissionGroupsForAppAll(allPerm);
      setFilteredPermissionGroupsForAppAll(allPermFiltered);
      setGroupsCountAll(allPermFiltered.length);

      setPermissionGroupsForAppSelected(selectedPerm);
      setFilteredPermissionGroupsForAppSelected(selectedPermFiltered);
      setGroupsCountSelected(selectedPermFiltered.length);

      setCheckedAll([]);
      setCheckedSelected([]);
    }
  };

  const deleteFromSelectedContainers = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (
      filteredReportContainersForAppAll &&
      filteredReportContainersForAppSelected &&
      reportContainersForAppAll &&
      reportContainersForAppSelected
    ) {
      // add to all list and remove from selected list
      const selectedRep: GetReportContainersForAppApiResponseType[] =
        reportContainersForAppSelected.filter(
          (item) =>
            checkedSelectedContainers.indexOf(item.reportContainerID) === -1
        );
      const deleteRep: GetReportContainersForAppApiResponseType[] =
        filteredReportContainersForAppSelected.filter(
          (item) =>
            checkedSelectedContainers.indexOf(item.reportContainerID) > -1
        );
      const allRep: GetReportContainersForAppApiResponseType[] =
        reportContainersForAppAll.concat(deleteRep);
      const selectedRepFiltered: GetReportContainersForAppApiResponseType[] =
        filteredReportContainersForAppSelected.filter(
          (item) =>
            checkedSelectedContainers.indexOf(item.reportContainerID) === -1
        );
      const allRepFiltered: GetReportContainersForAppApiResponseType[] =
        filteredReportContainersForAppAll.concat(deleteRep);

      setReportContainersForAppAll(allRep);
      setFilteredReportContainersForAppAll(allRepFiltered);
      setGroupsCountAllContainers(allRepFiltered.length);

      setReportContainersForAppSelected(selectedRep);
      setFilteredReportContainersForAppSelected(selectedRepFiltered);
      setGroupsCountSelectedContainers(selectedRepFiltered.length);

      setCheckedAllContainers([]);
      setCheckedSelectedContainers([]);
    }
  };

  return (
    <React.Fragment>
      <ButtonEdit iconWidth="25px" handleClick={handleOpen} />
      <Dialog
        open={isOpen}
        onEscapeKeyDown={handleClose}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>
          <TypographySubtitle text={"Edit " + props.app.name + " permission"} />
        </DialogTitle>

        <Formik
          initialValues={{}}
          onSubmit={async (values) => {
            await handleSubmit();
          }}
        >
          {(formProps) => {
            const { handleSubmit } = formProps;
            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent dividers>
                  {props.app.canAssignPermissionGroups &&
                  props.app.canAssignReports ? (
                    <Tabs
                      value={selectedTab}
                      onChange={handleChangeTab}
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label="Permissions" />
                      <Tab label="Reports" />
                    </Tabs>
                  ) : (
                    ""
                  )}
                  {props.app.canAssignPermissionGroups ? (
                    <TabPanel value={selectedTab} index={0}>
                      <Grid container item sm={12} alignItems="center">
                        <Grid
                          container
                          item
                          sm={6}
                          direction="column"
                          alignItems="flex-start"
                        >
                          <Grid item sm={12} alignItems="center">
                            <TypographyInfo
                              text={"All groups (" + groupsCountAll + ")"}
                              color="#000000"
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            sm={12}
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item sm={8} alignItems="center">
                              <SearchField
                                onChangeEvent={onSearchChangeAll}
                                preventEnterKey
                                marginTop="0px"
                              />
                            </Grid>
                            <Grid item sm={4} alignItems="flex-end">
                              <ButtonOutlined
                                text={
                                  "Move" +
                                  (checkedAll.length > 0
                                    ? ` (${checkedAll.length})`
                                    : "")
                                }
                                iconStartType={IconType.SvgImageIcon}
                                iconStart={
                                  <SvgArrowRight
                                    width={"14px"}
                                    color={
                                      checkedAll.length > 0
                                        ? "#444444"
                                        : "#AEAEAE"
                                    }
                                  />
                                }
                                size={"md"}
                                handleClick={moveToSelected}
                                disabled={checkedAll.length < 1}
                                borderColor={
                                  checkedAll.length > 0 ? "#444444" : "#AEAEAE"
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid className={customStyles.list}>
                            {filteredPermissionGroupsForAppAll &&
                            filteredPermissionGroupsForAppAll.length > 0
                              ? filteredPermissionGroupsForAppAll.map(
                                  (
                                    perm: GetPermissionGroupsForAppApiResponseType
                                  ) => {
                                    return (
                                      <ListItem
                                        key={perm.permissionGroupID}
                                        role={undefined}
                                        dense
                                        button
                                        className={customStyles.listItem}
                                        onClick={handleToggleAll(
                                          perm.permissionGroupID
                                        )}
                                      >
                                        <ListItemIcon>
                                          <Checkbox
                                            edge="start"
                                            checked={
                                              checkedAll.indexOf(
                                                perm.permissionGroupID
                                              ) !== -1
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                              "aria-labelledby": perm.name,
                                            }}
                                            color="primary"
                                          />
                                        </ListItemIcon>
                                        <ListItemText
                                          id={perm.permissionGroupID.toString()}
                                          primary={perm.name}
                                        />
                                      </ListItem>
                                    );
                                  }
                                )
                              : "No items"}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          sm={6}
                          direction="column"
                          alignItems="flex-start"
                          style={{ paddingLeft: 20 }}
                        >
                          <Grid item sm={12} alignItems="center">
                            <TypographyInfo
                              text={
                                props.app.name +
                                " (" +
                                groupsCountSelected +
                                ")"
                              }
                              color="#000000"
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            sm={12}
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item sm={8} alignItems="center">
                              <SearchField
                                onChangeEvent={onSearchChangeSelected}
                                preventEnterKey
                                marginTop="0px"
                              />
                            </Grid>
                            <Grid item sm={4} alignItems="center">
                              <ButtonOutlined
                                text={
                                  "Delete" +
                                  (checkedSelected.length > 0
                                    ? ` (${checkedSelected.length})`
                                    : "")
                                }
                                iconStartType={IconType.SvgImageIcon}
                                iconStart={
                                  <SvgTrash
                                    width={"14px"}
                                    color={
                                      checkedSelected.length > 0
                                        ? "#444444"
                                        : "#AEAEAE"
                                    }
                                  />
                                }
                                size={"md"}
                                handleClick={deleteFromSelected}
                                disabled={checkedSelected.length < 1}
                                borderColor={
                                  checkedSelected.length > 0
                                    ? "#444444"
                                    : "#AEAEAE"
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid className={customStyles.list}>
                            {filteredPermissionGroupsForAppSelected &&
                            filteredPermissionGroupsForAppSelected.length > 0
                              ? filteredPermissionGroupsForAppSelected.map(
                                  (
                                    perm: GetPermissionGroupsForAppApiResponseType
                                  ) => {
                                    return (
                                      <ListItem
                                        key={perm.permissionGroupID}
                                        role={undefined}
                                        dense
                                        button
                                        className={customStyles.listItem}
                                        onClick={handleToggleSelected(
                                          perm.permissionGroupID
                                        )}
                                      >
                                        <ListItemIcon>
                                          <Checkbox
                                            edge="start"
                                            checked={
                                              checkedSelected.indexOf(
                                                perm.permissionGroupID
                                              ) !== -1
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                              "aria-labelledby": perm.name,
                                            }}
                                            color="primary"
                                          />
                                        </ListItemIcon>
                                        <ListItemText
                                          id={perm.permissionGroupID.toString()}
                                          primary={perm.name}
                                        />
                                      </ListItem>
                                    );
                                  }
                                )
                              : "No items"}
                          </Grid>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  ) : (
                    ""
                  )}
                  {props.app.canAssignReports ? (
                    <TabPanel value={selectedTab} index={1}>
                      <Grid container item sm={12} alignItems="center">
                        <Grid
                          container
                          item
                          sm={6}
                          direction="column"
                          alignItems="flex-start"
                        >
                          <Grid item sm={12} alignItems="center">
                            <TypographyInfo
                              text={
                                "All containers (" +
                                groupsCountAllContainers +
                                ")"
                              }
                              color="#000000"
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item sm={8} alignItems="center">
                              <SearchField
                                onChangeEvent={onSearchChangeAllContainers}
                                preventEnterKey
                                marginTop="0px"
                              />
                            </Grid>
                            <Grid item sm={4} alignItems="flex-end">
                              <ButtonOutlined
                                text={
                                  "Move" +
                                  (checkedAllContainers.length > 0
                                    ? ` (${checkedAllContainers.length})`
                                    : "")
                                }
                                iconStartType={IconType.SvgImageIcon}
                                iconStart={
                                  <SvgArrowRight
                                    width={"14px"}
                                    color={
                                      checkedAllContainers.length > 0
                                        ? "#444444"
                                        : "#AEAEAE"
                                    }
                                  />
                                }
                                size={"md"}
                                handleClick={moveToSelectedContainers}
                                disabled={checkedAllContainers.length < 1}
                                borderColor={
                                  checkedAllContainers.length > 0
                                    ? "#444444"
                                    : "#AEAEAE"
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid className={customStyles.list}>
                            {filteredReportContainersForAppAll &&
                            filteredReportContainersForAppAll.length > 0
                              ? filteredReportContainersForAppAll.map(
                                  (
                                    reportContainer: GetReportContainersForAppApiResponseType
                                  ) => {
                                    return (
                                      <ListItem
                                        key={reportContainer.reportContainerID}
                                        role={undefined}
                                        dense
                                        button
                                        className={customStyles.listItem}
                                        onClick={handleToggleAllContainers(
                                          reportContainer.reportContainerID
                                        )}
                                      >
                                        <ListItemIcon>
                                          <Checkbox
                                            edge="start"
                                            checked={
                                              checkedAllContainers.indexOf(
                                                reportContainer.reportContainerID
                                              ) !== -1
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                              "aria-labelledby":
                                                reportContainer.reportContainerName,
                                            }}
                                            color="primary"
                                          />
                                        </ListItemIcon>
                                        <ListItemText
                                          id={reportContainer.reportContainerID.toString()}
                                          primary={
                                            reportContainer.reportContainerName
                                          }
                                        />
                                      </ListItem>
                                    );
                                  }
                                )
                              : "No items"}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          sm={6}
                          direction="column"
                          alignItems="flex-start"
                          style={{ paddingLeft: 20 }}
                        >
                          <Grid item sm={12} alignItems="center">
                            <TypographyInfo
                              text={
                                props.app.name +
                                " (" +
                                groupsCountSelectedContainers +
                                ")"
                              }
                              color="#000000"
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            sm={12}
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item sm={8} alignItems="center">
                              <SearchField
                                onChangeEvent={onSearchChangeSelectedContainers}
                                preventEnterKey
                                marginTop="0px"
                              />
                            </Grid>
                            <Grid item sm={4} alignItems="center">
                              <ButtonOutlined
                                text={
                                  "Delete" +
                                  (checkedSelectedContainers.length > 0
                                    ? ` (${checkedSelectedContainers.length})`
                                    : "")
                                }
                                iconStartType={IconType.SvgImageIcon}
                                iconStart={
                                  <SvgTrash
                                    width={"14px"}
                                    color={
                                      checkedSelectedContainers.length > 0
                                        ? "#444444"
                                        : "#AEAEAE"
                                    }
                                  />
                                }
                                size={"md"}
                                handleClick={deleteFromSelectedContainers}
                                disabled={checkedSelectedContainers.length < 1}
                                borderColor={
                                  checkedSelectedContainers.length > 0
                                    ? "#444444"
                                    : "#AEAEAE"
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid className={customStyles.list}>
                            {filteredReportContainersForAppSelected &&
                            filteredReportContainersForAppSelected.length > 0
                              ? filteredReportContainersForAppSelected.map(
                                  (
                                    reportContainer: GetReportContainersForAppApiResponseType
                                  ) => {
                                    return (
                                      <ListItem
                                        key={reportContainer.reportContainerID}
                                        role={undefined}
                                        dense
                                        button
                                        className={customStyles.listItem}
                                        onClick={handleToggleSelectedContainers(
                                          reportContainer.reportContainerID
                                        )}
                                      >
                                        <ListItemIcon>
                                          <Checkbox
                                            edge="start"
                                            checked={
                                              checkedSelectedContainers.indexOf(
                                                reportContainer.reportContainerID
                                              ) !== -1
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                              "aria-labelledby":
                                                reportContainer.reportContainerName,
                                            }}
                                            color="primary"
                                          />
                                        </ListItemIcon>
                                        <ListItemText
                                          id={reportContainer.reportContainerID.toString()}
                                          primary={
                                            reportContainer.reportContainerName
                                          }
                                        />
                                      </ListItem>
                                    );
                                  }
                                )
                              : "No items"}
                          </Grid>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  ) : (
                    ""
                  )}
                </DialogContent>
                <DialogActions>
                  <ButtonSubaction text="Cancel" handleClick={handleClose} />
                  <ButtonAction isSubmit text="Save" />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </React.Fragment>
  );
}
