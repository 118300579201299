import * as React from 'react';
import { Container } from '@material-ui/core';
import RoleApi from 'Services/Api/role-api';
import { GetRolesApiResponseType } from 'Types/role-types';
import RoleHeader from 'Components/Role/RoleHeader/role-header';
import RoleListContainer from 'Components/Role/RoleList/role-list-container';
import ClientAppApi from 'Services/Api/client-app-api';
import { IRolesProps } from 'Components/Role/interfaces-role';
import { AppType } from 'Types/app-types';

const clientAppApi = new ClientAppApi();
const roleApi = new RoleApi();

export default function Roles(props: IRolesProps) {
  const [roles, setRoles] = React.useState<GetRolesApiResponseType[]>([]);
  const [applications, setApplications] = React.useState<AppType[]>([]);

  React.useEffect(() => {
    const getApplications = async (): Promise<void> => {
      if (
        props.client &&
        props.client !== null &&
        props.client.id !== null &&
        props.client.id > 0
      ) {
        await clientAppApi
          .getAllAppList(props.client.id!, true)
          .then((result) => {
            setApplications(result.data);
          });
      }
    };

    getApplications();
  }, [props.client]);

  const getRoles = React.useCallback(async (): Promise<void> => {
    if (props.client.id) {
      await roleApi.getRoles(props.client.id, true).then((result) => {
        if (result && result.data) {
          setRoles(result.data);
        }
      });
    }
  }, [props.client.id]);

  React.useEffect(() => {
    getRoles();
  }, [getRoles]);

  return (
    <Container>
      <RoleListContainer
        roleList={roles}
        appList={applications}
        getRoles={getRoles}
        client={props.client}
      />
    </Container>
  );
}
