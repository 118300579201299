import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { AppLoginDataTypeEnum } from "Common/enum-app";
import React from "react";
import { IAppEditorComponentProps } from "./interfaces";

const AppEditorComponentLoginData: (
  props: IAppEditorComponentProps
) => JSX.Element = (props: IAppEditorComponentProps): JSX.Element => {
  return (
    <FormControl fullWidth size="small" onChange={props.handleChange}>
      <InputLabel id="app-login-data-type-select-id">
        Login data type
      </InputLabel>
      <Select
        name="appLoginDataType"
        fullWidth
        labelId="app-login-data-type-select-id"
        value={props.value}
        onChange={props.handleChange}
      >
        <MenuItem value={AppLoginDataTypeEnum.Id}>ID</MenuItem>
        <MenuItem value={AppLoginDataTypeEnum.Guid}>GUID</MenuItem>
      </Select>
    </FormControl>
  );
};

export default AppEditorComponentLoginData;
