import * as React from "react";
import { Formik } from "formik";
import { ApiResultStatusEnum, IconType, FormEditorEnum } from "Common/enum";
import { SaveReportContainerApiResultType } from "Types/api-types";
import ButtonOutlined from "Components/Buttons/button-outlined";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { AxiosError } from "axios";
import { IReportContainerEditorProps } from "Components/Reports/interfaces-reports";
import TypographyInfo from "Components/Typography/typography-info";
import ReportApi from "Services/Api/report-api";
import {
  GetReportsForReportContainerApiResponseType,
  SaveReportContainerModelType,
} from "Types/report-types";
import SvgArrowRight from "Components/Images/image-arrow-right";
import SvgTrash from "Components/Images/image-trash";
import Colors from "Common/colors";
import { stylesReportContainers } from "Components/Reports/ReportsContainers/styles";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import SearchField from "Components/Inputs/SearchField/search-field";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";

const reportApi = new ReportApi();

export default function ReportContainerEditor(
  props: IReportContainerEditorProps
) {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [reportsAll, setReportsAll] =
    React.useState<GetReportsForReportContainerApiResponseType[]>();
  const [filteredReportsAll, setFilteredReportsAll] =
    React.useState<GetReportsForReportContainerApiResponseType[]>();
  const [reportsSelected, setReportsSelected] =
    React.useState<GetReportsForReportContainerApiResponseType[]>();
  const [filteredReportsSelected, setFilteredReportsSelected] =
    React.useState<GetReportsForReportContainerApiResponseType[]>();
  const [reportsCountAll, setReportsCountAll] = React.useState(0);
  const [reportsCountSelected, setReportsCountSelected] = React.useState(0);
  const [checkedAll, setCheckedAll] = React.useState<number[]>([]);
  const [checkedSelected, setCheckedSelected] = React.useState<number[]>([]);
  const [reportContainerName, setReportContainerName] = React.useState<string>(
    props.reportContainer.reportContainerName
  );

  const getReports = React.useCallback(async (): Promise<void> => {
    if (props.isOpen) {
      await reportApi
        .getReportsForReportContainer(props.reportContainer.reportContainerID)
        .then((result) => {
          if (result && result.data) {
            const allRep: GetReportsForReportContainerApiResponseType[] =
              result.data.filter((item) => !item.isSelected);
            const selectedRep: GetReportsForReportContainerApiResponseType[] =
              result.data.filter((item) => item.isSelected);

            refresh(allRep, selectedRep, allRep, selectedRep);

            if (props.reportContainer.reportContainerName === "") {
              // for refresh editor when we added new group
              setReportContainerName("");
            }
          }
        });
    }
  }, [
    props.isOpen,
    props.reportContainer.reportContainerID,
    props.reportContainer.reportContainerName,
  ]);

  React.useEffect(() => {
    getReports();
  }, [getReports]);

  React.useEffect(() => {
    setReportContainerName(props.reportContainer.reportContainerName);
  }, [props.reportContainer.reportContainerName]);

  const customStyles = stylesReportContainers();

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const searchByText = (
    list: GetReportsForReportContainerApiResponseType[],
    text?: string
  ): GetReportsForReportContainerApiResponseType[] => {
    if (text) {
      list = list.filter((x) => x.reportName.toLowerCase().includes(text));
    }
    return list;
  };

  const onSearchChangeAll = (value: React.SetStateAction<string>): void => {
    let searchedText: string = value.toString().toLowerCase();
    searchedText = searchedText ? searchedText : "";
    const reports: GetReportsForReportContainerApiResponseType[] = searchByText(
      reportsAll ? reportsAll : [],
      searchedText
    );
    setFilteredReportsAll(reports);
  };

  const handleToggleAll = (value: number) => {
    const currentIndex: number = checkedAll.indexOf(value);
    const newChecked: number[] = [...checkedAll];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedAll(newChecked);
  };

  const onSearchChangeSelected = (
    value: React.SetStateAction<string>
  ): void => {
    let searchedText: string = value.toString().toLowerCase();
    searchedText = searchedText ? searchedText : "";
    const reports: GetReportsForReportContainerApiResponseType[] = searchByText(
      reportsSelected ? reportsSelected : [],
      searchedText
    );
    setFilteredReportsSelected(reports);
  };

  const handleToggleSelected = (value: number) => {
    const currentIndex = checkedSelected.indexOf(value);
    const newChecked = [...checkedSelected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedSelected(newChecked);
  };

  const handleSubmit = () => {
    saveReportContainer()
      .then(() => {})
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const saveReportContainer =
    async (): Promise<SaveReportContainerApiResultType | void> => {
      if (reportContainerName.length === 0) {
        return;
      }
      const request: SaveReportContainerModelType = {
        reportContainerID: props.reportContainer.reportContainerID,
        clientID: props.client.id!,
        reportContainerName: reportContainerName,
        reports: reportsSelected ? reportsSelected.map((r) => r.reportID) : [],
      };

      const result = await reportApi.saveReportContainer(request);
      if (result && result.status === ApiResultStatusEnum.Ok) {
        close();
        if (props.getReportsContainers) {
          props.getReportsContainers();
        }
      }
    };

  const moveToSelected = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (
      filteredReportsAll &&
      filteredReportsSelected &&
      reportsAll &&
      reportsSelected
    ) {
      // add to selected list and remove from all list
      const allRep: GetReportsForReportContainerApiResponseType[] =
        reportsAll.filter((item) => checkedAll.indexOf(item.reportID) === -1);
      const moveRep: GetReportsForReportContainerApiResponseType[] =
        filteredReportsAll.filter(
          (item) => checkedAll.indexOf(item.reportID) > -1
        );
      const selectedRep: GetReportsForReportContainerApiResponseType[] =
        reportsSelected.concat(moveRep);
      const allRepFiltered: GetReportsForReportContainerApiResponseType[] =
        filteredReportsAll.filter(
          (item) => checkedAll.indexOf(item.reportID) === -1
        );
      const selectedRepFiltered: GetReportsForReportContainerApiResponseType[] =
        filteredReportsSelected.concat(moveRep);

      refresh(allRep, selectedRep, allRepFiltered, selectedRepFiltered);
    }
  };

  const deleteFromSelected = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (
      filteredReportsAll &&
      filteredReportsSelected &&
      reportsAll &&
      reportsSelected
    ) {
      // add to all list and remove from selected list
      const selectedRep: GetReportsForReportContainerApiResponseType[] =
        reportsSelected.filter(
          (item) => checkedSelected.indexOf(item.reportID) === -1
        );
      const deleteRep: GetReportsForReportContainerApiResponseType[] =
        filteredReportsSelected.filter(
          (item) => checkedSelected.indexOf(item.reportID) > -1
        );
      const allRep: GetReportsForReportContainerApiResponseType[] =
        reportsAll.concat(deleteRep);
      const selectedRepFiltered: GetReportsForReportContainerApiResponseType[] =
        filteredReportsSelected.filter(
          (item) => checkedSelected.indexOf(item.reportID) === -1
        );
      const allRepFiltered: GetReportsForReportContainerApiResponseType[] =
        filteredReportsAll.concat(deleteRep);

      refresh(allRep, selectedRep, allRepFiltered, selectedRepFiltered);
    }
  };

  const refresh = (
    allRep: GetReportsForReportContainerApiResponseType[],
    selectedRep: GetReportsForReportContainerApiResponseType[],
    allRepFiltered: GetReportsForReportContainerApiResponseType[],
    selectedRepFiltered: GetReportsForReportContainerApiResponseType[]
  ): void => {
    setReportsAll(allRep);
    setFilteredReportsAll(allRepFiltered);
    setReportsCountAll(allRepFiltered.length);
    setReportsSelected(selectedRep);
    setFilteredReportsSelected(selectedRepFiltered);
    setReportsCountSelected(selectedRepFiltered.length);
    setCheckedAll([]);
    setCheckedSelected([]);
  };

  const handleChangeReportContainerName = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const text = event.currentTarget.value;
    setReportContainerName(text);
  };

  const close = () => {
    props.closeEditor();
  };

  const title: string =
    props.forEditor === FormEditorEnum.Edit
      ? `Edit ${props.reportContainer.reportContainerName} container`
      : "Add container";

  return (
    <React.Fragment>
      <Dialog
        open={props.isOpen}
        onEscapeKeyDown={close}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <TypographySubtitle text={title} />
        </DialogTitle>

        <Formik
          initialValues={{}}
          onSubmit={async (values) => {
            await handleSubmit();
          }}
        >
          {(formProps) => {
            const { handleSubmit } = formProps;
            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <Grid
                    container
                    item
                    sm={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid container item sm={6} direction="column">
                      <Grid item sm={12} alignItems="center">
                        <TypographyInfo
                          text={`All reports (${reportsCountAll})`}
                          color={Colors.black}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        sm={12}
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item sm={8} alignItems="center">
                          <SearchField
                            onChangeEvent={onSearchChangeAll}
                            preventEnterKey={true}
                            marginTop="0px"
                          />
                        </Grid>
                        <Grid item sm={4} alignItems="flex-end">
                          <ButtonOutlined
                            text={
                              "Move" +
                              (checkedAll.length > 0
                                ? ` (${checkedAll.length})`
                                : "")
                            }
                            iconStartType={IconType.SvgImageIcon}
                            iconStart={
                              <SvgArrowRight
                                width={"14px"}
                                color={
                                  checkedAll.length > 0
                                    ? Colors.grayDark
                                    : "#AEAEAE"
                                }
                              />
                            }
                            size="md"
                            handleClick={moveToSelected}
                            disabled={checkedAll.length < 1}
                            borderColor={
                              checkedAll.length > 0
                                ? Colors.grayDark
                                : "#AEAEAE"
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid className={customStyles.list}>
                        {filteredReportsAll && filteredReportsAll.length > 0
                          ? filteredReportsAll.map(
                              (
                                report: GetReportsForReportContainerApiResponseType
                              ) => {
                                return (
                                  <ListItem
                                    key={report.reportID}
                                    role={undefined}
                                    dense
                                    button
                                    className={customStyles.listItem}
                                    onClick={() => {
                                      handleToggleAll(report.reportID);
                                    }}
                                  >
                                    <ListItemIcon>
                                      <Checkbox
                                        edge="start"
                                        checked={
                                          checkedAll.indexOf(
                                            report.reportID
                                          ) !== -1
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          "aria-labelledby": report.reportName,
                                        }}
                                        color="primary"
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      id={report.reportID.toString()}
                                      primary={report.reportName}
                                    />
                                  </ListItem>
                                );
                              }
                            )
                          : "No items"}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      sm={6}
                      direction="column"
                      style={{ paddingLeft: 20 }}
                    >
                      <Grid container item sm={12} alignItems="baseline">
                        <TextFieldEntered
                          id="report-container-name"
                          name="report-container-name"
                          label="Name"
                          value={reportContainerName}
                          onChange={handleChangeReportContainerName}
                          error={reportContainerName.length === 0}
                          style={{ width: 200 }}
                          onKeyPress={(ev) => {
                            if (ev.key === "Enter") {
                              // do nothing
                              ev.preventDefault();
                            }
                          }}
                        />

                        <TypographyInfo
                          text={` (${reportsCountSelected})`}
                          color={Colors.black}
                          margin="5px 5px"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        sm={12}
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item sm={8} alignItems="center">
                          <SearchField
                            onChangeEvent={onSearchChangeSelected}
                            preventEnterKey
                            marginTop="0px"
                          />
                        </Grid>
                        <Grid item sm={4} alignItems="flex-end">
                          <ButtonOutlined
                            text={
                              "Delete" +
                              (checkedSelected.length > 0
                                ? ` (${checkedSelected.length})`
                                : "")
                            }
                            iconStartType={IconType.SvgImageIcon}
                            iconStart={
                              <SvgTrash
                                width={"14px"}
                                color={
                                  checkedSelected.length > 0
                                    ? Colors.grayDark
                                    : "#AEAEAE"
                                }
                              />
                            }
                            size={"md"}
                            handleClick={deleteFromSelected}
                            disabled={checkedSelected.length < 1}
                            borderColor={
                              checkedSelected.length > 0
                                ? Colors.grayDark
                                : "#AEAEAE"
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid className={customStyles.list}>
                        {filteredReportsSelected &&
                        filteredReportsSelected.length > 0
                          ? filteredReportsSelected.map(
                              (
                                report: GetReportsForReportContainerApiResponseType
                              ) => {
                                return (
                                  <ListItem
                                    key={report.reportID}
                                    role={undefined}
                                    dense
                                    button
                                    className={customStyles.listItem}
                                    onClick={() => {
                                      handleToggleSelected(report.reportID);
                                    }}
                                  >
                                    <ListItemIcon>
                                      <Checkbox
                                        edge="start"
                                        checked={
                                          checkedSelected.indexOf(
                                            report.reportID
                                          ) !== -1
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          "aria-labelledby": report.reportName,
                                        }}
                                        color="primary"
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      id={report.reportID.toString()}
                                      primary={report.reportName}
                                    />
                                  </ListItem>
                                );
                              }
                            )
                          : "No items"}
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ButtonSubaction text="Cancel" handleClick={close} />
                  <ButtonAction isSubmit text="Save" />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </React.Fragment>
  );
}
