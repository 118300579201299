import * as React from 'react';
import { GetReportColumnListType } from '../../../../Types/report-types';
import { stylesFont } from './styles';
import { ReportColumnAggregateType } from '../../../../Common/enum';
import { Select, MenuItem } from '@material-ui/core';

export interface SelectAggregateProps {
  data: GetReportColumnListType;
  list: GetReportColumnListType[];
  handleChangeColumns: (columns: GetReportColumnListType[]) => void;
}

const SelectAggregate: React.FC<SelectAggregateProps> = ({
  data,
  list,
  handleChangeColumns,
}) => {
  const customStyles = stylesFont();
  const [value, setValue] = React.useState<ReportColumnAggregateType>(
    data.aggreagateType
  );

  const handleChangeAggregate = (
    event: React.ChangeEvent<{ value: unknown }>,
    columnID: number
  ): void => {
    const aggreagateType: ReportColumnAggregateType = event.target
      .value as number;

    handleChangeColumns(
      list.map((p) =>
        p.columnID === columnID
          ? { ...p, aggreagateType: Number(aggreagateType) }
          : p
      )
    );
  };

  const types = Object.keys(ReportColumnAggregateType).filter(
    (key: any) => !isNaN(Number(key))
  );

  return (
    <Select
      fullWidth
      value={value}
      disableUnderline={true}
      classes={{
        root: customStyles.input,
      }}
      onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
        setValue(event.target.value as number)
      }
      onBlur={(event: React.ChangeEvent<{ value: unknown }>) =>
        handleChangeAggregate(event, data.columnID)
      }
    >
      {types.map((key: string) => (
        <MenuItem
          classes={{ root: customStyles.input }}
          value={key}
          key={key}
          id={key}
        >
          {ReportColumnAggregateType[Number(key)]}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectAggregate;
