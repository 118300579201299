import * as React from "react";

const SvgNewReport = ({
    style = {},
    fill = "none",
    color = "#444444",
    width = "47",
    height = "63",
    className = "",
    viewBox = "0 0 47 63"
  }) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M25 53L6 53C3.23857 53 0.999998 50.7614 0.999998 48L1 6C1 3.23858 3.23858 0.999999 6 0.999999L23 0.999999C30.732 1 37 7.26801 37 15L37 41" stroke={color} />
        <path d="M37 15L28 15C25.2386 15 23 12.7614 23 10L23 0.999999" stroke={color} />
        <path d="M8 24H30" stroke={color} />
        <path d="M8 32H30" stroke={color} />
        <path d="M8 40H30" stroke={color} />
        <circle cx="37" cy="53" r="9.5" fill="white" stroke={color} />
        <path d="M37 49V57" stroke={color} />
        <path d="M41 53L33 53" stroke={color} />
    </svg>
);

export default SvgNewReport;