import * as React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  ApiResultStatusEnum,
  IconType,
  FormEditorEnum,
  RoleShareMode,
} from "Common/enum";
import { stylesRoleEditor } from "Components/Role/RoleEditor/styles";
import SvgPlusCircle from "Components/Images/image-plus-circle";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  ListItem,
  Checkbox,
  ListItemText,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Switch,
  Box,
  Tooltip,
} from "@material-ui/core";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import TypographyInfo from "Components/Typography/typography-info";
import { AxiosError } from "axios";
import { IRoleEditorProps } from "Components/Role/interfaces-role";
import { SaveRoleModelType, SaveRoleFormType } from "Types/role-types";
import RoleApi from "Services/Api/role-api";
import { AppType } from "Types/app-types";
import { SaveRoleApiResultType } from "Types/api-types";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import ButtonIcon from "Components/Buttons/button-icon";
import { GetRolesPermissionGroupsResult } from "Types/permission-types";

export default function RoleEditor(props: IRoleEditorProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<
    AxiosError | null | string
  >(null);
  const [checkedApps, setCheckedApps] = React.useState<number[]>([]);
  const [checkedPermissionGroups, setCheckedPermissionGroups] = React.useState<number[]>([]);

  const roleApi = new RoleApi();

  const NAME_MAX_LENGTH: number = 200;

  const customStyles = stylesRoleEditor();

  const shareModeTooltipText = (
    <span>
      <b>Multiple</b> - account can be shared accross multiple customers
      <br />
      <b>Single</b> - account can be assigned to only one customer
    </span>
  )

  const handleOpen = async (
    event: React.MouseEvent<HTMLElement>
  ): Promise<void> => {
    if(props.onOpen){
      props.onOpen(event)
    }
    setIsOpen(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (isOpen) {
      if (props.role) {
        let newCheckedApps: number[] = props.role.applications.map(
          (role) => role.appID
        );
        setCheckedApps(newCheckedApps);
        let newCheckedPermissionGroups: number[] = props.role.permissionGroups
        .filter((role) => role.isPermissionGroupEditable)
        .map((role) => role.permissionGroupID);
        setCheckedPermissionGroups(newCheckedPermissionGroups);
      } else {
        setCheckedApps([]);
        setCheckedPermissionGroups([]);
      }
    }
  }, [props.role, isOpen]);

  const handleSubmit = (role: SaveRoleFormType) => {
    let roleModel: SaveRoleModelType = {
      ...role,
      apps: checkedApps,
      permissionGroups: checkedPermissionGroups
    };

    saveRole(roleModel)
      .then(() => {})
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const handleToggleApp = (value: number) => () => {
    const currentIndex: number = checkedApps.indexOf(value);
    const newChecked: number[] = [...checkedApps];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedApps(newChecked);
  };

  const handleTogglePermissionGroup = (value: number) => () => {
    const currentIndex: number = checkedPermissionGroups.indexOf(value);
    const newChecked: number[] = [...checkedPermissionGroups];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedPermissionGroups(newChecked);
  };

  const saveRole = (
    role: SaveRoleModelType
  ): Promise<SaveRoleApiResultType | void> => {
    return roleApi
      .saveRole(role)
      .then((result) => {
        if (result && result.status === ApiResultStatusEnum.Ok) {
          if (props.getRoles) {
            props.getRoles();
          }
          setIsOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  let headerText: string = "New role";
  let buttonText: string =
    props.editorType === FormEditorEnum.Create ? "Add role" : "Edit";
  let button: JSX.Element = (
    <ButtonIcon
          iconType={IconType.SvgImageIcon}
          icon={<SvgPlusCircle width={"14px"}/>}
          handleClick={handleOpen}
        />
  );

  if (props.editorType === FormEditorEnum.Edit) {
    headerText = props.role?.name ?? "Edit role definition";
    button = <MenuItem onClick={handleOpen}>Edit role definition</MenuItem>
  }

  return (
    <React.Fragment>
      {button}
      <Dialog
        open={isOpen}
        maxWidth={"sm"}
        fullWidth
        onEscapeKeyDown={handleClose}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            roleID: props.role ? props.role.roleID : null,
            name: props.role ? props.role.name : "",
            clientID: props.client.id,
            isEnabled: props.role?.isEnabled ?? true,
            isDefault: props.role?.isDefault ?? false,
            shareMode: props.role?.shareMode ?? RoleShareMode.Multiple,
          }}
          onSubmit={async (values) => {
            await handleSubmit(values);
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .label("Role name")
              .required("Required")
              .max(NAME_MAX_LENGTH)
              .trim(),
          })}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = formProps;
            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogTitle>
                  <Grid container justify="space-between">
                    <TypographySubtitle text={headerText} margin="0px" />
                  </Grid>
                </DialogTitle>

                <DialogContent
                  dividers
                  classes={{ root: customStyles.dialogContent }}
                >
                  <Grid container alignItems="stretch" justify="space-between">
                    <TextFieldEntered
                      id="name"
                      name="name"
                      label="Role name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.name && touched.name)}
                      helperText={
                        (errors.name && touched.name && errors.name) ||
                        `${values.name.length}/${NAME_MAX_LENGTH}`
                      }
                      inputProps={{
                        maxLength: NAME_MAX_LENGTH,
                      }}
                      className={customStyles.textField}
                      required
                    />
                    <FormControlLabel

                      control={
                        <Checkbox
                          id="isDefault"
                          name="isDefault"
                          checked={values.isDefault}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{
                            maxLength: NAME_MAX_LENGTH,
                          }}
                          required
                        />
                      }
                      label="Default"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          id="isEnabled"
                          name="isEnabled"
                          color="primary"
                          checked={values.isEnabled}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      }
                      label="Active"
                    />
                    <Tooltip title={shareModeTooltipText} placement="top">
                      <FormControl className={customStyles.selectFormControl}>
                        <InputLabel>Share mode</InputLabel>
                        <Select
                          id="shareMode"
                          name="shareMode"
                          onChange={handleChange}
                          value={values.shareMode}
                        >
                          <MenuItem value={RoleShareMode.Multiple}>Multiple</MenuItem>
                          <MenuItem value={RoleShareMode.Single}>Single</MenuItem>
                        </Select>
                      </FormControl>
                    </Tooltip>
                  </Grid>
                  
                  <Box margin="24px 0px 0px 0px">
                    <TypographyInfo text={"Applications"} />

                    <Grid container className={customStyles.roleCheckboxes}>
                      {props.appList && props.appList.length > 0
                        ? props.appList.map((app: AppType) => {
                            return (
                              <Grid item xs={6}>
                                <ListItem
                                  key={app.guid}
                                  role={undefined}
                                  dense
                                  disableGutters={true}
                                  button
                                  onClick={handleToggleApp(app.id!)}
                                >
                                  <Checkbox
                                    edge="start"
                                    checked={checkedApps.indexOf(app.id!) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": app.name }}
                                    color="primary"
                                  />
                                  <ListItemText
                                    id={app.guid}
                                    primary={app.name}
                                  />
                                </ListItem>
                              </Grid>
                            );
                          })
                        : "No items"}
                    </Grid>
                  </Box>

                  <Box margin="24px 0px 0px 0px">
                    <TypographyInfo text={"Inactive Permission Groups"} />

                    <Grid container className={customStyles.roleCheckboxes}>
                      {props.role?.permissionGroups && props.role?.permissionGroups.length > 0
                        ? props.role?.permissionGroups.map((permissionGroup: GetRolesPermissionGroupsResult) => {
                            return (
                              <Grid item xs={6}>
                                <ListItem
                                  key={permissionGroup.permissionGroupID.toString()}
                                  role={undefined}
                                  dense
                                  disableGutters={true}
                                  button
                                  onClick={handleTogglePermissionGroup(permissionGroup.permissionGroupID!)}
                                >
                                  <Checkbox
                                    edge="start"
                                    checked={checkedPermissionGroups.indexOf(permissionGroup.permissionGroupID!) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": permissionGroup.name }}
                                    color="primary"
                                  />
                                  <ListItemText
                                    id={permissionGroup.permissionGroupID.toString()}
                                    primary={permissionGroup.name}
                                  />
                                </ListItem>
                              </Grid>
                            );
                          })
                        : "No items"}
                    </Grid>
                  </Box>
                  
                </DialogContent>
                <DialogActions>
                  <ButtonSubaction text="Cancel" handleClick={handleClose} />
                  <ButtonAction isSubmit text="Save" />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </React.Fragment>
  );
}
