import * as React from "react";
import * as Yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { IAppEditor, IAppEditorProps } from "./interfaces";
import { AppType } from "Types/app-types";
import {
  ApiResultStatusEnum,
  AppPlatformEnum,
  FileTypeEnum,
  IconType,
  RequiredLoginEnum,
} from "Common/enum";
import { AppLoginTypeEnum, AppUserTypeEnum } from "Common/enum-app";
import {
  FileUploadApiResultType,
  MobileAppGetApiResultType,
} from "Types/api-types";
import { FileUploadType } from "Types/file-types";
import { validationShape } from "./formik-validation-values";
import { SweetAlertResult } from "sweetalert2";
import { RouteApplications } from "Configuration/Routes";
import { useHistory } from "react-router-dom";
import AppEditorComponentName from "./AppEditorComponents/app-editor-component-name";
import AppEditorComponentGuid from "./AppEditorComponents/app-editor-component-guid";
import AppEditorComponentUser from "./AppEditorComponents/app-editor-component-user";
import AppEditorComponentLogin from "./AppEditorComponents/app-editor-component-login";
import AppEditorComponentRole from "./AppEditorComponents/app-editor-component-role";
import ButtonIcon from "Components/Buttons/button-icon";
import ClientAppApi from "Services/Api/client-app-api";
import Colors from "Common/colors";
import FileApi from "Services/Api/file-api";
import ImageOSIcon from "Components/Image/ImageOSIcon/image-os-icon";
import ImageUpload from "Components/Image/ImageUpload/image-upload";
import InputCheckbox from "Components/Inputs/input-checkbox";
import SvgPlusCircle from "Components/Images/image-plus-circle";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import useModalError from "Hooks/use-modal-error";
import SvgPencil from "Components/Images/image-edit-pencil";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";

const AppEditorMobile: (props: IAppEditorProps) => JSX.Element = (
  props: IAppEditorProps
): JSX.Element => {
  const IMAGE_MAX_SIZE: number = 4194304; //4 MB
  const history = useHistory();
  const clientAppApi: ClientAppApi = new ClientAppApi();
  const fileApi: FileApi = new FileApi();
  const showModalError: (
    errorResult: string,
    isHtml?: boolean
  ) => Promise<SweetAlertResult> = useModalError();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [image, setImage] = React.useState<Blob>();
  const [values, setValues] = React.useState<IAppEditor>({
    guid: "",
    id: props.id,
    clientId: props.clientId,
    name: "",
    appPlatformType: AppPlatformEnum.Android,
    isActivationRequired: false,
    appLoginType: RequiredLoginEnum.Email,
    appUserType: AppUserTypeEnum.InternalUser,
  });

  const handleOpen: () => void = (): void => {
    handleLoad();
    setIsOpen(true);
  };

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleLoad: () => void = (): void => {
    if (props.id) {
      clientAppApi
        .getMobileApp(props.id)
        .then((result: MobileAppGetApiResultType) => {
          if (result?.data) {
            if (result.data.iaClientID === props.clientId) {
              setValues({
                guid: result.data.guid,
                id: result.data.id,
                clientId: result.data.iaClientID,
                name: result.data.name,
                appPlatformType: result.data.platformType,
                isActivationRequired: result.data.isActivationRequired,
                appLoginType: result.data.requiredLoginType,
                appUserType: result.data.isRequireUsers
                  ? AppUserTypeEnum.InternalUser
                  : result.data.isExternalApplication
                  ? AppUserTypeEnum.ExternalUser
                  : AppUserTypeEnum.NoUser,
              });
              if (result.data.logoFile) {
                fileApi.download(result.data.logoFile.guid).then((data) => {
                  setImage(data);
                });
              }
            }
          } else {
            history.push(RouteApplications);
          }
        });
    }
  };

  const handleSave: (data: AppType) => void = (data: AppType): void => {
    clientAppApi
      .checkAppGuidAlreadyExists(data.guid)
      .then((response) => {
        if (response?.status === ApiResultStatusEnum.Ok && response.data) {
          if (
            response.data.isAlreadyExists &&
            response.data.appID !== props.id
          ) {
            showModalError("App with this guid already exists");
          } else {
            if (image) {
              uploadFile({
                type: FileTypeEnum.AppLogo,
                file: image,
              }).then((uploadResult) => {
                if (uploadResult.status === ApiResultStatusEnum.Ok) {
                  data.logoFile = uploadResult.data;
                  clientAppApi
                    .saveApp(data)
                    .then(() => {
                      if (props.handleAfterSave) {
                        props.handleAfterSave();
                      }
                      setIsOpen(false);
                    })
                    .catch((error) => {
                      showModalError(error.response.data.statusMessage);
                    });
                }
              });
            } else {
              clientAppApi
                .saveApp(data)
                .then(() => {
                  if (props.handleAfterSave) {
                    props.handleAfterSave();
                  }
                  setIsOpen(false);
                })
                .catch((error) => {
                  showModalError(error.response.data.statusMessage);
                });
            }
          }
        } else {
          showModalError("Something went wrong, try again later");
        }
      })
      .catch((error) => {
        showModalError(error.response.data.statusMessage);
      });
  };

  const prepareDataToSave: (values: IAppEditor) => AppType = (
    values: IAppEditor
  ): AppType => {
    let dataToSave: AppType = {
      name: values.name,
      guid: values.guid,
      iaClientID: values.clientId,
      isActivationRequired: values.isActivationRequired,
      isExternalApplication:
        values.appUserType === AppUserTypeEnum.ExternalUser,
      isReportsAvailable: false,
      isRequireUsers: values.appUserType === AppUserTypeEnum.InternalUser,
      platformType: values.appPlatformType,
      requiredLoginType: values.appLoginType,
      iaRoleID: values.roleId,
      id: values.id,
    };
    return dataToSave;
  };

  const uploadFile = async (
    fileToUpload: FileUploadType
  ): Promise<FileUploadApiResultType> => {
    return await fileApi.upload(fileToUpload);
  };

  return (
    <React.Fragment>
      <ButtonIcon
        iconType={IconType.SvgImageIcon}
        icon={props.id ? <SvgPencil /> : <SvgPlusCircle />}
        handleClick={handleOpen}
        height="22px"
        width="22px"
      />
      <Dialog open={isOpen}>
        <DialogTitle>
          <TypographySubtitle text={props.titleText} margin="0px" />
        </DialogTitle>
        <Formik
          initialValues={values}
          enableReinitialize={true}
          validationSchema={Yup.object().shape(validationShape)}
          onSubmit={(values: IAppEditor) => {
            let dataToSave: AppType = prepareDataToSave(values);
            handleSave(dataToSave);
          }}
        >
          {(formik) => (
            <Form>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid container item xs={4}>
                    <ImageUpload
                      file={image}
                      setFile={setImage}
                      acceptedFormats={[".jpg", ".jpeg", ".png"]}
                      maxSize={IMAGE_MAX_SIZE}
                      margin="0"
                      width="155px"
                      height="140px"
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    spacing={0}
                    justify={"flex-start"}
                    alignItems={"flex-start"}
                  >
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={1}
                        style={{ paddingTop: "12px" }}
                        onClick={() => {
                          formik.setFieldValue(
                            "appPlatformType",
                            AppPlatformEnum.Android
                          );
                        }}
                      >
                        <ImageOSIcon
                          platformType={AppPlatformEnum.Android}
                          width="28px"
                          color={
                            formik.values.appPlatformType ===
                            AppPlatformEnum.Android
                              ? Colors.grayDark
                              : Colors.graySeparate
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{ paddingTop: "12px" }}
                        onClick={() => {
                          formik.setFieldValue(
                            "appPlatformType",
                            AppPlatformEnum.IOS
                          );
                        }}
                      >
                        <ImageOSIcon
                          platformType={AppPlatformEnum.IOS}
                          width="28px"
                          color={
                            formik.values.appPlatformType ===
                            AppPlatformEnum.IOS
                              ? Colors.grayDark
                              : Colors.graySeparate
                          }
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <AppEditorComponentName
                          value={formik.values.name ?? ""}
                          handleBlur={formik.handleBlur}
                          handleChange={formik.handleChange}
                          error={!!(formik.errors.name && formik.touched.name)}
                          platformType={formik.values.appPlatformType}
                          showAdornment={!!props.id}
                        />
                      </Grid>
                    </Grid>
                    {props.id && (
                      <Grid item xs={12}>
                        <AppEditorComponentGuid
                          value={formik.values.guid ?? ""}
                          handleBlur={formik.handleBlur}
                          handleChange={formik.handleChange}
                          error={!!formik.errors.guid}
                          errorText={formik.errors.guid}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={4}>
                      <AppEditorComponentUser
                        value={formik.values.appUserType}
                        handleBlur={formik.handleBlur}
                        handleChange={formik.handleChange}
                      />
                    </Grid>
                    {formik.values.appUserType ===
                      AppUserTypeEnum.InternalUser && (
                      <>
                        <Grid item xs={4}>
                          <AppEditorComponentLogin
                            value={
                              formik.values.appLoginType ??
                              AppLoginTypeEnum.Email
                            }
                            handleBlur={formik.handleBlur}
                            handleChange={formik.handleChange}
                            error={
                              !!(
                                formik.errors.appLoginType &&
                                formik.touched.appLoginType
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputCheckbox
                            name="isActivationRequired"
                            checked={formik.values.isActivationRequired}
                            text="User activation required"
                            onChange={formik.handleChange}
                            labelFontSize="14px"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={4}>
                      {formik.values.appUserType ===
                        AppUserTypeEnum.InternalUser &&
                        props.roleList?.length > 0 && (
                          <AppEditorComponentRole
                            value={formik.values.roleId}
                            roleList={props.roleList}
                            handleChange={formik.handleChange}
                          />
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <ButtonSubaction text="Cancel" handleClick={handleClose} />
                <ButtonAction text="Save" isSubmit />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
};

export default AppEditorMobile;
