import * as React from "react";
import { ButtonSubactionProps } from "../interfaces";
import { stylesButtonSubaction } from "../styles";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";

const ButtonSubaction: (props: ButtonSubactionProps) => JSX.Element = (
  props: ButtonSubactionProps
): JSX.Element => {
  const customStyles = stylesButtonSubaction();
  const { text, margin, handleClick, disabled, size } = props;

  return (
    <ButtonAction
      text={text}
      color="secondary"
      margin={margin}
      className={`${customStyles.button}`}
      handleClick={handleClick}
      disabled={disabled}
      size={size}
    />
  );
};

export default ButtonSubaction;
