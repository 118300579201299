import * as React from "react";
import { GetReportParameterListType } from "Types/report-types";
import { Select, MenuItem } from "@material-ui/core";
import {
  ReportParameterEnterType,
  ReportParameterSourceType,
  ReportParameterFormatType,
  ReportParameterDefaultValueType,
} from "Common/enum";
import { stylesFont } from "./styles";

export interface SelectEnterTypeProps {
  data: GetReportParameterListType;
  list: GetReportParameterListType[];
  handleChangeParameters: (parameters: GetReportParameterListType[]) => void;
}

const SelectEnterType: React.FC<SelectEnterTypeProps> = ({
  data,
  list,
  handleChangeParameters,
}) => {
  const customStyles = stylesFont();
  const [value, setValue] = React.useState<ReportParameterEnterType>(
    data.enterType
  );

  const handleChangeEnterType = (
    event: React.ChangeEvent<{ value: unknown }>,
    parameterID: number
  ): void => {
    const enterType: ReportParameterEnterType = event.target.value as number;

    let parameter = list.find((p) => p.parameterID === parameterID)!;
    let connectedParam = list.find(
      (p) =>
        (p.orderNo === parameter.orderNo - 1 ||
          p.orderNo === parameter.orderNo + 1) &&
        p.enterType === ReportParameterEnterType.Range &&
        p.dataType === parameter.dataType
    );

    if (connectedParam) {
      if (enterType === ReportParameterEnterType.Range) {
        parameter = {
          ...parameter,
          enterType,
          sourceType: connectedParam.sourceType,
          sourceProcedure: connectedParam.sourceProcedure,
          reportDictionarySourceName: connectedParam.reportDictionarySourceName,
          isSourceMultiselect: connectedParam.isSourceMultiselect,
          iaReportDictionaryIDSource: connectedParam.iaReportDictionaryIDSource,
          dataTypeFormatText: connectedParam.dataTypeFormatText,
          dataTypeFormatID: connectedParam.dataTypeFormatID,
          formatType: connectedParam.formatType,
          formatValueText: connectedParam.formatValueText,
          reportDictionaryItemDefaultValue:
            connectedParam.reportDictionaryItemDefaultValue,
          iaReportDictionaryItemIDDefaultValue:
            connectedParam.iaReportDictionaryItemIDDefaultValue,
          defaultValueText: connectedParam.defaultValueText,
          defaultValueType: connectedParam.defaultValueType,
        };

        handleChangeParameters(
          list.map((p) =>
            p.parameterID === parameter.parameterID ? { ...parameter } : p
          )
        );
      } else {
        parameter = {
          ...parameter,
          enterType,
          sourceType: ReportParameterSourceType.None,
          sourceProcedure: "",
          reportDictionarySourceName: "",
          isSourceMultiselect: false,
          iaReportDictionaryIDSource: undefined,
          dataTypeFormatText: "",
          dataTypeFormatID: undefined,
          formatType: ReportParameterFormatType.None,
          formatValueText: "",
          reportDictionaryItemDefaultValue: "",
          iaReportDictionaryItemIDDefaultValue: undefined,
          defaultValueText: "",
          defaultValueType: ReportParameterDefaultValueType.Text,
        };
        connectedParam = {
          ...connectedParam,
          enterType,
          sourceType: ReportParameterSourceType.None,
          sourceProcedure: "",
          reportDictionarySourceName: "",
          isSourceMultiselect: false,
          iaReportDictionaryIDSource: undefined,
          dataTypeFormatText: "",
          dataTypeFormatID: undefined,
          formatType: ReportParameterFormatType.None,
          formatValueText: "",
          reportDictionaryItemDefaultValue: "",
          iaReportDictionaryItemIDDefaultValue: undefined,
          defaultValueText: "",
          defaultValueType: ReportParameterDefaultValueType.Text,
        };
        handleChangeParameters(
          list.map((p) =>
            p.parameterID === parameter.parameterID
              ? { ...parameter }
              : p.parameterID === connectedParam?.parameterID
              ? connectedParam
              : p
          )
        );
      }
    } else {
      handleChangeParameters(
        list.map((p) =>
          p.parameterID === parameterID
            ? {
                ...p,
                enterType,
                sourceType: ReportParameterSourceType.None,
                sourceProcedure: "",
                reportDictionarySourceName: "",
                isSourceMultiselect: false,
                iaReportDictionaryIDSource: undefined,
                dataTypeFormatText: "",
                dataTypeFormatID: undefined,
                formatType: ReportParameterFormatType.None,
                formatValueText: "",
                reportDictionaryItemDefaultValue: "",
                iaReportDictionaryItemIDDefaultValue: undefined,
                defaultValueText: "",
                defaultValueType: ReportParameterDefaultValueType.Text,
              }
            : p
        )
      );
    }
  };

  React.useEffect(() => {
    setValue(data.enterType);
  }, [data.enterType]);

  return (
    <Select
      fullWidth
      value={value}
      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as number);
        setTimeout(() => {
          handleChangeEnterType(event, data.parameterID);
        }, 50);
      }}
      disableUnderline={true}
      classes={{ root: customStyles.input }}
    >
      <MenuItem
        classes={{ root: customStyles.input }}
        value={ReportParameterEnterType.Combo}
      >
        {ReportParameterEnterType[ReportParameterEnterType.Combo]}
      </MenuItem>
      <MenuItem
        classes={{ root: customStyles.input }}
        value={ReportParameterEnterType.Range}
      >
        {ReportParameterEnterType[ReportParameterEnterType.Range]}
      </MenuItem>
      <MenuItem
        classes={{ root: customStyles.input }}
        value={ReportParameterEnterType.Text}
      >
        {ReportParameterEnterType[ReportParameterEnterType.Text]}
      </MenuItem>
    </Select>
  );
};

export default SelectEnterType;
