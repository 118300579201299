import * as React from 'react';

const SvgGear = ({
  style = {},
  fill = 'white',
  width = '14',
  height = '14',
  color = '#959595',
  viewBox = '0 0 14 14',
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="8.5"
      y="13.5"
      width="3"
      height="13"
      transform="rotate(180 8.5 13.5)"
      stroke={color}
    />
    <rect
      x="0.5"
      y="8.5"
      width="3"
      height="13"
      transform="rotate(-90 0.5 8.5)"
      stroke={color}
    />
    <rect
      x="3.46436"
      y="12.6562"
      width="3"
      height="13"
      transform="rotate(-135 3.46436 12.6562)"
      stroke={color}
    />
    <rect
      x="1.34309"
      y="3.46484"
      width="3"
      height="13"
      transform="rotate(-45 1.34309 3.46484)"
      stroke={color}
    />
    <circle
      cx="7"
      cy="7"
      r="5.5"
      transform="rotate(180 7 7)"
      fill={fill}
      stroke={color}
    />
    <rect
      x="8"
      y="13"
      width="2"
      height="12"
      transform="rotate(180 8 13)"
      fill={fill}
    />
    <rect
      x="1"
      y="8"
      width="2"
      height="12"
      transform="rotate(-90 1 8)"
      fill={fill}
    />
    <rect
      x="3.46436"
      y="11.9492"
      width="2"
      height="12"
      transform="rotate(-135 3.46436 11.9492)"
      fill={fill}
    />
    <rect
      x="2.05029"
      y="3.46484"
      width="2"
      height="12"
      transform="rotate(-45 2.05029 3.46484)"
      fill={fill}
    />
    <path
      d="M4.5 7C4.5 5.61929 5.61929 4.5 7 4.5C8.38071 4.5 9.5 5.61929 9.5 7C9.5 8.38071 8.38071 9.5 7 9.5C5.61929 9.5 4.5 8.38071 4.5 7Z"
      fill={fill}
      stroke={color}
    />
  </svg>
);

export default SvgGear;
