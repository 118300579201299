import * as React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { GetReportColumnListType } from '../../../../Types/report-types';
import { stylesFont } from './styles';
import {
  ReportParameterAlignment,
  ReportColumnAlignment,
} from '../../../../Common/enum';

export interface SelectAlignmentProps {
  data: GetReportColumnListType;
  list: GetReportColumnListType[];
  handleChangeColumns: (columns: GetReportColumnListType[]) => void;
}

const SelectAlignment: React.FC<SelectAlignmentProps> = ({
  data,
  list,
  handleChangeColumns,
}) => {
  const customStyles = stylesFont();
  const [value, setValue] = React.useState<ReportColumnAlignment>(
    data.alignment
  );

  const handleChangeAlignment = (
    event: React.ChangeEvent<{ value: unknown }>,
    columnID: number
  ): void => {
    const alignment: ReportColumnAlignment = event.target.value as number;

    handleChangeColumns(
      list.map((p) => (p.columnID === columnID ? { ...p, alignment } : p))
    );
  };

  React.useEffect(() => {
    setValue(data.alignment);
  }, [data.alignment]);

  return (
    <Select
      fullWidth
      value={value}
      disableUnderline={true}
      classes={{
        root: customStyles.input,
      }}
      onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
        setValue(event.target.value as number)
      }
      onBlur={(event: React.ChangeEvent<{ value: unknown }>) =>
        handleChangeAlignment(event, data.columnID)
      }
    >
      <MenuItem
        classes={{ root: customStyles.input }}
        value={ReportParameterAlignment.Center}
      >
        {ReportParameterAlignment[ReportParameterAlignment.Center]}
      </MenuItem>
      <MenuItem
        classes={{ root: customStyles.input }}
        value={ReportParameterAlignment.Right}
      >
        {ReportParameterAlignment[ReportParameterAlignment.Right]}
      </MenuItem>
      <MenuItem
        classes={{ root: customStyles.input }}
        value={ReportParameterAlignment.Left}
      >
        {ReportParameterAlignment[ReportParameterAlignment.Left]}
      </MenuItem>
    </Select>
  );
};

export default SelectAlignment;
