import * as React from "react";

const SvgCalendar = ({ fill = "none", color = "#444444", style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g fill={fill}>
      <rect x="2.5" y="5" width="13" height="10" rx="1.5" stroke={color} />
      <path d="M3 7.5H15" stroke={color} />
      <path d="M5 3.5V4.5" stroke={color} stroke-linecap="round" />
      <path d="M13 3.5V4.5" stroke={color} stroke-linecap="round" />
    </g>
  </svg>
);
export default SvgCalendar;
