import * as React from "react";
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

export interface SelectIsMultipleProps {
  multiselect: boolean;
  handleChangeSingleMultiply: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
}

const SelectIsMultiple: React.FC<SelectIsMultipleProps> = ({
  multiselect,
  handleChangeSingleMultiply,
}) => {
  return (
    <Grid item>
      <FormControl component="fieldset">
        <RadioGroup
          row
          name="type"
          value={multiselect}
          onChange={handleChangeSingleMultiply}
        >
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label="Single"
          />
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label="Multiple"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export default SelectIsMultiple;
