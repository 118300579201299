import { makeStyles } from "@material-ui/core";

export const stylesDeviceHeader = makeStyles({
  gridItem: {
    marginRight: "10px",
  },
  osIcon: {
    width: "30px",
    height: "30px",
    marginLeft: "25px",
    borderRadius: "8px",
  },
});
