import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Autocomplete } from '@material-ui/lab';
import {
  GetReportParameterListType,
  GetReportDictionariesApiResponseType,
} from '../../../../Types/report-types';
import { stylesFont } from './styles';
import {
  ReportParameterEnterType,
  ReportParameterDefaultValueType,
  ReportParameterSourceType,
} from 'Common/enum';

export interface SelectDefaultProps {
  data: GetReportParameterListType;
  list: GetReportParameterListType[];
  handleChangeParameters: (parameters: GetReportParameterListType[]) => void;
  dictionary?: GetReportDictionariesApiResponseType;
}

const SelectDefault: React.FC<SelectDefaultProps> = ({
  data,
  list,
  handleChangeParameters,
  dictionary,
}) => {
  const customStyles = stylesFont();

  const [openSelectFormat, setOpenSelectFormat] = React.useState(false);
  const [defaultValueText, setDefaultValueText] = React.useState<string>(
    data.defaultValueText
  );
  const [
    reportDictionaryItemDefaultValue,
    setReportDictionaryItemDefaultValue,
  ] = React.useState<string>(data.reportDictionaryItemDefaultValue);

  const handleChangeDefault = (name: string, parameterID: number): void => {
    const dictionaryItem = dictionary?.items.find(
      (item) => item.itemName === name
    );
    handleChangeParameters(
      list.map((p) =>
        p.parameterID === parameterID
          ? {
              ...p,
              reportDictionaryItemDefaultValue: dictionaryItem ? name : '',
              iaReportDictionaryItemIDDefaultValue: dictionaryItem
                ? dictionaryItem.itemID
                : undefined,
              defaultValueText: !dictionaryItem ? name : '',
              defaultValueType: dictionaryItem
                ? ReportParameterDefaultValueType.DictionaryItem
                : ReportParameterDefaultValueType.Text,
            }
          : p
      )
    );
  };
  const handleChangeDefaultText = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    handleChangeParameters(
      list.map((p) =>
        p.parameterID === data.parameterID
          ? {
              ...p,
              defaultValueText,
              defaultValueType: ReportParameterDefaultValueType.Text,
              reportDictionaryItemDefaultValue: '',
              iaReportDictionaryItemIDDefaultValue: undefined,
            }
          : p
      )
    );
  };

  React.useEffect(() => {
    setDefaultValueText(data.defaultValueText);
  }, [data.defaultValueText]);

  React.useEffect(() => {
    setReportDictionaryItemDefaultValue(data.reportDictionaryItemDefaultValue);
  }, [data.reportDictionaryItemDefaultValue]);

  return data.enterType === ReportParameterEnterType.Text ||
    data.sourceType === ReportParameterSourceType.Procedure ? (
    <TextField
      name='default'
      InputProps={{
        disableUnderline: true,
        style: { fontSize: '14px' },
      }}
      value={defaultValueText}
      onBlur={handleChangeDefaultText}
      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        setDefaultValueText(event.target.value)
      }
      fullWidth
      required
    />
  ) : (
    <Autocomplete
      freeSolo
      fullWidth
      disableClearable
      classes={{ option: customStyles.input }}
      open={openSelectFormat}
      onOpen={() => setOpenSelectFormat(true)}
      onClose={() => setOpenSelectFormat(false)}
      value={data.reportDictionaryItemDefaultValue ?? ''}
      onChange={(event: any, newValue: string | null) => {
        if (newValue != null) {
          handleChangeDefault(newValue, data.parameterID);
        }
      }}
      onBlur={() => {
        handleChangeDefault(reportDictionaryItemDefaultValue, data.parameterID);
      }}
      inputValue={reportDictionaryItemDefaultValue ?? ''}
      onInputChange={(event: any, newValue: string | null) => {
        if (newValue != null) {
          setReportDictionaryItemDefaultValue(newValue);
        }
      }}
      options={dictionary?.items.map((item) => item.itemName) ?? []}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          InputProps={{
            ...params.InputProps,
            classes: { input: customStyles.input },
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                  style={{ backgroundColor: 'transparent' }}
                  onClick={() => setOpenSelectFormat(!openSelectFormat)}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default SelectDefault;
