
import jwt from "jsonwebtoken";
import { UserData } from "./user-data";

export default class AuthorizationHelper {
    static keyLogin: string = "iaLogin";
    static keyUser: string = "iaUser";
    static keyTokens: string = "iaTokens";
    static defaultPage: string = "/applications";

  static setLogin = (login: string) => {
    localStorage.setItem(AuthorizationHelper.keyLogin, JSON.stringify(login));
  };

  static getLogin = (): string => {
    let loginSession = localStorage.getItem(AuthorizationHelper.keyLogin);
    let login: string = loginSession !== null ? JSON.parse(loginSession) : "";
    return login;
  };

  static logOut = () => {
    localStorage.removeItem(AuthorizationHelper.keyUser);
    localStorage.removeItem(AuthorizationHelper.keyTokens);
  };

  static setUser = (user: UserData) => {
    let userSession = {
      userID: user.userID,
      login: user.login,
      firstName:
        user.firstName !== undefined && user.firstName !== null
          ? user.firstName
          : "",
      lastName:
        user.lastName !== undefined && user.lastName !== null
          ? user.lastName
          : "",
      permissions: user.permissions
    };
    localStorage.setItem(AuthorizationHelper.keyUser, JSON.stringify(userSession));
  };

  static getUser = (): UserData => {
    let userSession = localStorage.getItem(AuthorizationHelper.keyUser);
    let user = userSession != null ? JSON.parse(userSession) : null;
    return user;
  };

  static setToken = (tokens: Tokens): void => {
    localStorage.setItem(AuthorizationHelper.keyTokens, JSON.stringify(tokens));
  };

  static getToken = (): Tokens | null => {
    let tokenSession = localStorage.getItem(AuthorizationHelper.keyTokens);
    let tokens: Tokens | null =
      tokenSession !== null ? JSON.parse(tokenSession) : null;
    if (tokens != null) {
      let decodedToken: any | null = jwt.decode(tokens.refresh, {
        complete: true,
        json: true
      });

      if (
        decodedToken === null ||
        decodedToken.payload.exp < Math.floor(Date.now() / 1000)
      ) {
        return null;
      }
    }

    return tokens;
  };

  static authHeader() {
    // return authorization header with jwt token
    let tokens = this.getToken();
    if (tokens !== null && tokens && tokens.auth !== null && tokens.auth) {
      return {
        Authorization: "Bearer " + tokens.auth,
        SecretKey: process.env.REACT_APP_API_SECRET_KEY,
        Language: "en-us"
      };
    }
    else {
      return {
        SecretKey: process.env.REACT_APP_API_SECRET_KEY,
        Language: "en-us"
      };
    }
  }
}

export type Tokens = {
  auth: string;
  refresh: string;
};
