import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import { ClientType } from 'Types/client-types';
import { EventOutlined } from '@material-ui/icons';
import { RouteMaintenance } from 'Configuration/Routes';
import { GetRolesForAppApiResponseType } from 'Types/role-types';
import { WebAppType, MobileAppType } from 'Types/app-types';
import { Container, Grid, Divider } from '@material-ui/core';
import { AppPlatformEnum } from 'Common/enum';
import ClientAppApi from 'Services/Api/client-app-api';
import Colors from 'Common/colors';
import BoundEditor from 'Components/Bound/BoundEditor/bound-editor';
import MobileAppCard from 'Components/MobileApp/MobileAppCard/mobile-app-card';
import TypographyTitle from 'Components/Typography/typography-title';
import WebAppCard from 'Components/WebApp/WebAppCard/web-app-card';
import ButtonTransparent from 'Components/Buttons/button-transparent';
import ExportStructure from 'Components/Export/ExportStructure/export-structure';
import ImportStructure from 'Components/Import/ImportStructure/import-structure';
import AppEditorMobile from 'Components/App/AppEditor/app-editor-mobile';
import AppEditorWeb from 'Components/App/AppEditor/app-editor-web';
import AppEditorWebService from 'Components/App/AppEditor/AppEditorWebService/app-editor-web-service';
import Swal from 'sweetalert2';
import ButtonSubaction from 'Components/Buttons/ButtonSubaction/button-subaction';

let clientAppApi: ClientAppApi = new ClientAppApi();

interface ApplicationsProps {
  client: ClientType;
}

interface IApplicationsState {
  webAppList: WebAppType[];
  webServicesList: WebAppType[];
  mobileAppList: MobileAppType[];
  roleList: GetRolesForAppApiResponseType[];
  webServiceNotRespondingIdList: number[];
}

export default class Applications extends React.Component<
  ApplicationsProps,
  IApplicationsState
> {
  state: IApplicationsState = {
    mobileAppList: [],
    webAppList: [],
    webServicesList: [],
    roleList: [],
    webServiceNotRespondingIdList: [],
  };

  componentDidMount = async () => {
    await this.getAppLists();
  };

  getAppLists = async () => {
    if (this.props.client.id) {
      let webAppList: WebAppType[] = [];
      let webServicesList: WebAppType[] = [];
      let mobileAppList: MobileAppType[] = [];
      await clientAppApi
        .getWebAppList(this.props.client.id, 1)
        .then((result) => {
          if (result && result.data) {
            webAppList = result.data.filter(
              (w) => w.platformType === AppPlatformEnum.Web
            );
            webServicesList = result.data.filter(
              (w) => w.platformType === AppPlatformEnum.WebService
            );
          }
        });
      await clientAppApi
        .getMobileAppList(this.props.client.id, 3)
        .then((result) => {
          if (result && result.data) {
            mobileAppList = result.data;
          }
        })
        .then((result) => {
          this.setState({
            mobileAppList,
            webAppList,
            webServicesList,
          });
        });
    }
  };

  handleCheckWS = async (): Promise<void> => {
    if (this.props.client.id) {
      await clientAppApi
        .checkAppIsRespondingForEveryApp(this.props.client.id)
        .then((r) => {
          Swal.fire({
            icon: 'success',
            text: 'Services working properly',
            width: '25rem',
            customClass: {
              title: 'swal-title',
              container: 'swal-container',
              content: 'swal-content',
              confirmButton: 'swal-confirm-button',
            },
          });
        })
        .catch((error) => {
          if (error.response.data?.data?.notRespondingAppIdList) {
            this.setState({
              ...this.state,
              webServiceNotRespondingIdList:
                error.response.data.data.notRespondingAppIdList,
            });
          }
          if (error.response.data?.data?.errorList) {
            Swal.fire({
              icon: 'error',
              html: _.join(error.response.data.data.errorList, '</br>'),
              width: '50rem',
              customClass: {
                title: 'swal-title',
                container: 'swal-container',
                content: 'swal-content',
                confirmButton: 'swal-confirm-button',
              },
            });
          } else {
            Swal.fire({
              icon: 'error',
              text: 'An error occured. Please, try again later',
              width: '25rem',
              customClass: {
                title: 'swal-title',
                container: 'swal-container',
                content: 'swal-content',
                confirmButton: 'swal-confirm-button',
              },
            });
          }
        });
    }
  };

  render() {
    return (
      <Container>
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid container item xs={12}>
            <BoundEditor clientID={this.props.client.id} />
            <ButtonTransparent>
              <EventOutlined />
              <Link to={RouteMaintenance} style={{ textDecoration: 'none' }}>
                Maintenance calendar
              </Link>
            </ButtonTransparent>
            <ExportStructure clientID={this.props.client.id} />
            <ImportStructure />
          </Grid>
          <Grid container item xs={4} justify="flex-start" alignItems="center">
            <Grid item xs={1}>
              <AppEditorWeb
                clientId={this.props.client.id ?? 0}
                roleList={this.state.roleList}
                titleText={'Add web app'}
                handleAfterSave={this.getAppLists}
              />
            </Grid>
            <Grid item xs={11}>
              <TypographyTitle text="Web apps" color={Colors.gray} />
            </Grid>
            <Grid item xs={12}>
              <Divider variant="fullWidth" />
            </Grid>
          </Grid>
          <Grid container item xs={4} justify="flex-start" alignItems="center">
            <Grid item xs={1}>
              <AppEditorWebService
                clientId={this.props.client.id ?? 0}
                roleList={this.state.roleList}
                titleText={'Add web service'}
                handleAfterSave={this.getAppLists}
              ></AppEditorWebService>
            </Grid>
            <Grid item xs={8}>
              <TypographyTitle text="Web services" color={Colors.gray} />
            </Grid>
            <Grid item xs={3}>
              <ButtonSubaction text="Check" handleClick={this.handleCheckWS} />
            </Grid>
            <Grid item xs={12}>
              <Divider variant="fullWidth" />
            </Grid>
          </Grid>
          <Grid container item xs={4} justify="flex-start" alignItems="center">
            <Grid item xs={1}>
              <AppEditorMobile
                clientId={this.props.client.id ?? 0}
                roleList={this.state.roleList}
                titleText={'Add mobile app'}
                handleAfterSave={this.getAppLists}
              ></AppEditorMobile>
            </Grid>
            <Grid item xs={11}>
              <TypographyTitle text="Mobile apps" color={Colors.gray} />
            </Grid>
            <Grid item xs={12}>
              <Divider variant="fullWidth" />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} direction={'row'}>
          <Grid
            container
            item
            xs={4}
            justify="flex-start"
            direction="column"
            alignItems="flex-start"
          >
            {this.state.webAppList.map((webApp) => {
              return (
                <Grid item>
                  <WebAppCard webApp={webApp} key={'webApp_' + webApp.id} />
                </Grid>
              );
            })}
          </Grid>
          <Grid
            container
            item
            xs={4}
            justify="flex-start"
            direction="column"
            alignItems="flex-start"
          >
            {this.state.webServicesList.map((webApp) => {
              return (
                <Grid item>
                  <WebAppCard
                    webApp={webApp}
                    key={'webApp_' + webApp.id}
                    isNotResponding={
                      !!this.state.webServiceNotRespondingIdList.find(
                        (ws) => ws === webApp.id
                      )
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid
            container
            item
            xs={4}
            justify="flex-start"
            direction="column"
            alignItems="flex-start"
          >
            {this.state.mobileAppList.map((mobileApp) => {
              return (
                <Grid item>
                  <MobileAppCard
                    mobileApp={mobileApp}
                    key={'mobileApp_' + mobileApp.id}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    );
  }
}
