import * as React from "react";
import { IDictionaryHeaderProps } from "Components/Reports/interfaces-reports";
import { Grid } from "@material-ui/core";
import { stylesDictionaryHeader } from "Components/Reports/Dictionary/DictionaryHeader/styles";
import { IconType } from "Common/enum";
import ButtonOutlined from "Components/Buttons/button-outlined";
import SvgPlusCircle from "Components/Images/image-plus-circle";
import HeaderCard from "Components/HeaderCard/header-card";

const DictionaryHeader: (props: IDictionaryHeaderProps) => JSX.Element = (
  props: IDictionaryHeaderProps
): JSX.Element => {
  let customStyles = stylesDictionaryHeader();

  const createNewDictionary = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    props.handleCreateNewDictionary();
  };

  return (
    <HeaderCard>
      <Grid container alignItems="center">
        <Grid item sm={9} alignItems="center" className={customStyles.title}>
          User lists
        </Grid>
        <Grid container item sm={3} alignItems="center" justify="flex-end">
          <Grid item className={customStyles.gridItem}>
            <ButtonOutlined
              text={"Add list"}
              iconStartType={IconType.SvgImageIcon}
              iconStart={<SvgPlusCircle width={"14px"} />}
              size={"md"}
              margin="0 10px 0 0"
              handleClick={createNewDictionary}
            />
          </Grid>
        </Grid>
      </Grid>
    </HeaderCard>
  );
};

export default DictionaryHeader;
