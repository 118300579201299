import { makeStyles } from '@material-ui/core';
import Colors from 'Common/colors';

export const stylesRoleEditor = makeStyles({
  gridItem: {
    borderRight: `1px solid ${Colors.graySeparate}`,
    marginRight: '40px',
    height: '40px',
  },
  paper: {
    padding: '11px 30px',
    marginBottom: '15px',
  },
  connectedIcon: {
    marginRight: '6px',
  },

  dialogContent: {
    '&:first-child': {
      paddingTop: '5px',
    },
  },
  textField: {
    width: '245px',
    margin: '10px 15px',
  },
  filterInput: {
    marginRight: '0',
    marginTop: '5px',
  },

  list: {
    width: '94.5%',
    height: 400,
    overflowX: 'auto',
    marginTop: '20px',
  },

  listItem: {
    borderBottom: `1px solid ${Colors.graySeparate}`,
  },
});
