import * as React from "react";
import { IAccessRightListContainerProps } from "Components/AccessRight/interfaces-access-right";
import AccessRightList from "Components/AccessRight/AccessRightList/access-right-list";
import AccessRightListFilters from "Components/AccessRight/AccessRightList/access-right-filters";
import { cloneDeep } from 'lodash';
import { GetPermissionGroupsForRoleApiResponseType } from "Types/permission-types";
import { stylesAccessRightListContainer } from "Components/AccessRight/AccessRightList/styles";
import TypographyInfo from "Components/Typography/typography-info";

const AccessRightListContainer: (props: IAccessRightListContainerProps) => JSX.Element
    = (props: IAccessRightListContainerProps): JSX.Element => {
        const [searchValue, setSearchValue] = React.useState('');
        const [selectAllPermissionGroups, setSelectAllPermissionGroups] = React.useState(false);
        const [selectedPermissionGroups, setSelectedPermissionGroups] = React.useState<number[]>([]);
        const [permissionGroups, setPermissionGroups] = React.useState<GetPermissionGroupsForRoleApiResponseType[]>([]);

        const customStyles = stylesAccessRightListContainer();
        
        const handleSearchValueChange = (value: React.SetStateAction<string>): void => {
            let searchText: string = value.toString().toUpperCase();
            searchText = searchText ? searchText : '';
            setSearchValue(searchText);
        }

        const handlePermissionGroupsChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
            let selectedValues = event.target.value as number[];
            if (selectedValues.indexOf(0) > -1) {
                if (selectAllPermissionGroups) {
                    setSelectedPermissionGroups([]);
                }
                else {
                    setSelectedPermissionGroups(props.permissionGroups.map(x => x.permissionGroupID));
                }
                setSelectAllPermissionGroups(!selectAllPermissionGroups);
            }
            else {
                setSelectAllPermissionGroups(false);
                setSelectedPermissionGroups(selectedValues);
            }
        }

        const searchByFilters = React.useCallback((permissions: GetPermissionGroupsForRoleApiResponseType[]): GetPermissionGroupsForRoleApiResponseType[] => {
            return searchByTextAndGroup(permissions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [searchValue, selectedPermissionGroups]);

        const searchByTextAndGroup = (permissions: GetPermissionGroupsForRoleApiResponseType[]): GetPermissionGroupsForRoleApiResponseType[] => {
            let results: GetPermissionGroupsForRoleApiResponseType[] = permissions;
            // searchText
            const text = searchValue;
            if (text && text !== '') {                
                results = results.map(permissionGroup => {
                    permissionGroup.permissions =  permissionGroup.permissions.filter(x => x.permissionName.toUpperCase().includes(text!.toUpperCase()));
                    return permissionGroup;
                });
            }
            // permissionGroups
            if (selectedPermissionGroups.length > 0) {
                results = results.filter(x => selectedPermissionGroups?.includes(x.permissionGroupID));
            }

            return results;
        };

        React.useEffect(() => {
            setPermissionGroups(searchByFilters(cloneDeep(props.permissionGroups)));
        }, [props.permissionGroups, searchByFilters, searchValue, selectedPermissionGroups]);

        return (
            <React.Fragment>
                <AccessRightListFilters
                    selectAllPermissionGroups={selectAllPermissionGroups}
                    selectedPermissionGroups={selectedPermissionGroups}
                    permissionGroups={props.permissionGroups}
                    onSearchChange={handleSearchValueChange}
                    onSelectedPermissionGroupsChange={handlePermissionGroupsChange}
                />
                {permissionGroups.length > 0 ?
                permissionGroups.map((rolePermission: GetPermissionGroupsForRoleApiResponseType) => (
                    <AccessRightList
                        key={rolePermission.permissionGroupID}
                        permissionGroups={rolePermission}
                        handleAllValuesChange={props.handleAllValuesChange}
                        handlePermissionValueChange={props.handlePermissionValueChange}
                        defaultTab={props.defaultTab}
                    />
                )) :
                (
                    <div className={customStyles.divNoPermissionGroups}>
                        <TypographyInfo text={"No records to display"} />
                    </div>
                )
                }
            </React.Fragment>
        )
    };

export default AccessRightListContainer;