import * as React from "react";
import { Container, Grid } from "@material-ui/core";
import { IWebApplicationInstanceDetailsProps } from "Components/WebApp/interfaces-web-app";
import {
  WebAppInstanceDeploymentType,
  WebAppInstanceType,
} from "Types/app-types";
import WebAppInstanceDeploymentList from "Components/WebApp/WebAppInstance/WebAppInstanceDeploymentList/web-app-instance-deployment-list";
import WebAppInstanceHeader from "Components/WebApp/WebAppInstance/web-app-instance-header";
import ClientAppApi from "Services/Api/client-app-api";
import { useHistory } from "react-router-dom";
import { RouteApplications } from "Configuration/Routes";

let clientAppApi: ClientAppApi = new ClientAppApi();

const WebApplicationInstanceDetails: (
  props: IWebApplicationInstanceDetailsProps
) => JSX.Element = (
  props: IWebApplicationInstanceDetailsProps
): JSX.Element => {
  const [instance, setInstance] = React.useState<WebAppInstanceType>();
  const [instanceDeploymentList, setInstanceDeploymentList] = React.useState<
    WebAppInstanceDeploymentType[]
  >([]);

  const history = useHistory();

  const getInstanceDetails = React.useCallback(async (): Promise<void> => {
    if (props.match.params.id && props.client.id && props.client.id > 0) {
      await clientAppApi
        .getWebAppInstance(props.match.params.id)
        .then((result) => {
          if (result && result.data) {
            clientAppApi.getWebApp(result.data.appID).then((appResult) => {
              if (appResult && appResult.data) {
                if (appResult.data.iaClientID === props.client.id) {
                  setInstance(result.data);
                } else {
                  history.push(RouteApplications);
                }
              } else {
                history.push(RouteApplications);
              }
            });
          } else {
            history.push(RouteApplications);
          }
        });
    }
  }, [props.match.params.id, props.client.id, history]);

  const getInstanceDeploymentListById: (
    deploymentItemCount?: number | undefined
  ) => Promise<void> = async (
    deploymentItemCount?: number | undefined
  ): Promise<void> => {
    return await clientAppApi
      .getWebAppInstanceDeploymentList(
        props.match.params.id,
        deploymentItemCount
      )
      .then((result) => {
        if (result && result.data) {
          setInstanceDeploymentList(result.data);
        }
      });
  };

  const getInstanceDeploymentList =
    React.useCallback(async (): Promise<void> => {
      await clientAppApi
        .getWebAppInstanceDeploymentList(props.match.params.id, 50)
        .then((result) => {
          if (result && result.data) {
            setInstanceDeploymentList(result.data);
          }
        });
    }, [props.match.params.id]);

  React.useEffect(() => {
    getInstanceDetails();
    getInstanceDeploymentList();
  }, [getInstanceDetails, getInstanceDeploymentList]);

  return (
    <Container>
      <Grid container={true}>
        <Grid item xs={12}>
          <WebAppInstanceHeader
            instanceName={instance?.name ?? ""}
            instanceId={instance?.id}
            getInstanceDeploymentList={getInstanceDeploymentListById}
          />
        </Grid>
        <Grid item xs={12}>
          <WebAppInstanceDeploymentList
            deploymentList={instanceDeploymentList}
            getInstanceDeploymentList={getInstanceDeploymentListById}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default WebApplicationInstanceDetails;
