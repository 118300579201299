import * as React from "react";
import { Formik } from "formik";
import { IconType } from "Common/enum";
import { IDictionaryDeleteProps } from "Components/Reports/interfaces-reports";
import ButtonIcon from "Components/Buttons/button-icon";
import SvgDelete from "Components/Images/image-delete";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";

export default function DictionaryDelete(props: IDictionaryDeleteProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setIsOpen(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    props.handleDeleteDictionary(
      props.dictionaryEditor.dictionary.dictionaryID
    );
  };

  return (
    <React.Fragment>
      <ButtonIcon
        iconType={IconType.SvgImageIcon}
        icon={<SvgDelete />}
        handleClick={handleOpen}
      />
      <Dialog open={isOpen} onEscapeKeyDown={handleClose}>
        <DialogTitle>
          <TypographySubtitle text={"Delete user list"} />
        </DialogTitle>

        <Formik
          initialValues={{
            dictionaryID: props.dictionaryEditor.dictionary.dictionaryID,
          }}
          onSubmit={async (values) => {
            await handleSubmit();
          }}
        >
          {(formProps) => {
            const { handleSubmit } = formProps;
            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <Typography>
                    Are you sure you want to delete{" "}
                    {props.dictionaryEditor.dictionary.dictionaryName}?
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <ButtonSubaction text="Cancel" handleClick={handleClose} />
                  <ButtonAction isSubmit text="Delete" />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
