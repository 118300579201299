import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Autocomplete } from '@material-ui/lab';
import {
  GetReportParameterListType,
  GetReportDataTypeFormatListApiResponseType,
} from '../../../../Types/report-types';
import { stylesFont } from './styles';
import { ReportParameterFormatType } from 'Common/enum';

export interface SelectDataTypeFormatProps {
  data: GetReportParameterListType;
  list: GetReportParameterListType[];
  handleChangeParameters: (parameters: GetReportParameterListType[]) => void;

  dataTypeFormatList: GetReportDataTypeFormatListApiResponseType[] | undefined;
}

const SelectDataTypeFormat: React.FC<SelectDataTypeFormatProps> = ({
  data,
  list,
  dataTypeFormatList,
  handleChangeParameters,
}) => {
  const [openSelectFormat, setOpenSelectFormat] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<string>(
    data.formatType === ReportParameterFormatType.DataTypeFormat
      ? data.dataTypeFormatText
      : data.formatType === ReportParameterFormatType.Custom
      ? data.formatValueText
      : ''
  );

  const customStyles = stylesFont();

  const handleChangeFormat = (value: string, parameterID: number): void => {
    const dataTypeFormat = dataTypeFormatList?.find(
      (format) => format.format === value
    );

    handleChangeParameters(
      list.map((p) =>
        p.parameterID === parameterID
          ? value == null || value.length === 0
            ? {
                ...p,
                dataTypeFormatText: '',
                dataTypeFormatID: undefined,
                formatType: ReportParameterFormatType.None,
                formatValueText: '',
              }
            : dataTypeFormat
            ? {
                ...p,
                dataTypeFormatText: value,
                dataTypeFormatID: dataTypeFormat.reportDataTypeFormatID,
                formatType: ReportParameterFormatType.DataTypeFormat,
                formatValueText: '',
              }
            : {
                ...p,
                dataTypeFormatText: '',
                dataTypeFormatID: undefined,
                formatType: ReportParameterFormatType.Custom,
                formatValueText: value,
              }
          : p
      )
    );
  };

  React.useEffect(() => {
    setInputValue(
      data.formatType === ReportParameterFormatType.DataTypeFormat
        ? data.dataTypeFormatText
        : data.formatType === ReportParameterFormatType.Custom
        ? data.formatValueText
        : ''
    );
  }, [data.dataTypeFormatText, data.formatType, data.formatValueText]);

  return (
    <Autocomplete
      freeSolo
      fullWidth
      disableClearable
      classes={{
        option: customStyles.input,
        inputRoot: customStyles.inputRoot,
      }}
      open={openSelectFormat}
      onOpen={() => setOpenSelectFormat(true)}
      onClose={() => setOpenSelectFormat(false)}
      value={data.dataTypeFormatText ?? ''}
      onChange={(event: any, newValue: string | null) => {
        if (newValue != null) {
          handleChangeFormat(newValue, data.parameterID);
        }
      }}
      onBlur={() => {
        handleChangeFormat(inputValue, data.parameterID);
      }}
      inputValue={inputValue ?? ''}
      onInputChange={(event: any, newValue: string | null) => {
        if (newValue != null) {
          setInputValue(newValue);
        }
      }}
      options={
        dataTypeFormatList
          ? dataTypeFormatList.map((format) => format.format)
          : []
      }
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            classes: { input: customStyles.input },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                  style={{ backgroundColor: 'transparent', padding: '12px 0' }}
                  onClick={() => setOpenSelectFormat(!openSelectFormat)}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default SelectDataTypeFormat;
