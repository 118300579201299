import { AppCheckRespondingTypeEnum } from 'Common/enum-app';
import * as React from 'react';
import EndpointAvailable from './endpoint-available';

interface ReportsEndpointProps {
  handleCheckWS: (
    checkRespondingType: AppCheckRespondingTypeEnum
  ) => Promise<void>;
}

const ReportsEndpoint: React.FC<ReportsEndpointProps> = ({ handleCheckWS }) => (
  <EndpointAvailable
    handleCheckWS={() => handleCheckWS(AppCheckRespondingTypeEnum.Report)}
    isAvailableKey="isReportsEndpointsAvailable"
    apiMethodPath="apiReportMethodPath"
    checkboxLabel="Reports endpoints available"
    apiMethodPathLabel="Api report method path"
  />
);

export default ReportsEndpoint;
