import { makeStyles } from '@material-ui/core';
import Colors from 'Common/colors';

export const stylesPermissionsGroupListItem = makeStyles({
  expansionPanel: {
    width: '100%',
    marginBottom: '15px',
    '&.Mui-expanded:last-child': {
      marginBottom: '15px',
    },
  },

  expansionPanelSummary: {
    '&.Mui-expanded': {
      borderBottom: `1px solid ${Colors.graySeparate}`,
    },
  },

  expansionPanelDetails: {
    paddingBottom: 0,
  },

  permissionGroupNameContainer: {
    borderRight: `1px solid ${Colors.graySeparate}`,
  },

  editButtonContainer: {
    paddingRight: '10px',
  },

  permissionContainer: {
    height: '54px',
    borderBottom: `1px solid ${Colors.graySeparate}`,
  },

  dialogContent: {
    '&:first-child': {
      paddingTop: '5px',
    },
  },

  filterInput: {
    marginRight: '0',
    marginTop: '5px',
  },

  list: {
    width: '94.5%',
    height: 400,
    overflowX: 'auto',
    marginTop: '20px',
  },

  listItem: {
    borderBottom: `1px solid ${Colors.graySeparate}`,
  },
});
