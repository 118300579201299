import * as React from "react";
import SearchField from "Components/Inputs/SearchField/search-field";
import { Grid } from "@material-ui/core";
import { IPermissionListFilters } from "./interfaces-permission-list";
import { MultiSelectItems } from "../MultiSelectItems/multi-select-items";

const PermissionListFilters: (props: IPermissionListFilters) => JSX.Element = (
  props: IPermissionListFilters
): JSX.Element => {
  return (
    <Grid item container justify="flex-start" spacing={4}>
      <Grid item sm={3}>
        <SearchField onChangeEvent={props.onSearchChange} />
      </Grid>
      <Grid item sm={2}>
        <MultiSelectItems
          {...props}
          labelName="Applications"
          selectedItems={props.selectedApps}
          itemList={props.appList.map((a) => ({ id: a.id!, name: a.name }))}
          onSelectedChange={props.onSelectedAppsChange}
        />
      </Grid>
    </Grid>
  );
};

export default PermissionListFilters;
