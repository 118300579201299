import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthorizationHelper from "./authorization-helper";
import Login from "Views/Login/login";
import ForgotPassword from "Views/Login/forgot-password";
import ResetPassword from "Views/Login/reset-password";
import Main from "Views/Main/main";

export const RouterApp = () => {
  const isAuthorized = AuthorizationHelper.getToken() !== null;

  return (
    <Router>
        {!isAuthorized ? (
          <Switch>
              <Route path="/forgot-password" render={() => <ForgotPassword />} />
              <Route path="/reset-password/:code" render={(props) => <ResetPassword {...props} />} />
              <Route path="/" render={() => <Login />} />
          </Switch>
        ) : (
            <div className="App">
                <Route
                    path="/"
                    render={() => (
                    <Fragment>
                        <Main />
                    </Fragment>
                    )}
                />
            </div>
        )}
    </Router>
  );
};