import * as React from "react";
import { IAccessRightsListReportsProps } from "Components/AccessRight/interfaces-access-right";
import MaterialTable from "material-table";
import { Grid } from "@material-ui/core";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { stylesAccessRightsListReports } from "Components/AccessRight/AccessRightsListReports/styles";
import AccessRightsListItemReports from "Components/AccessRight/AccessRightsListReports/access-rights-list-item-reports";
import ButtonOutlined from "Components/Buttons/button-outlined";

const AccessRightsListReports: (props: IAccessRightsListReportsProps) => JSX.Element
    = (props: IAccessRightsListReportsProps): JSX.Element => {
        const customStyles = stylesAccessRightsListReports();
        
        return (
            <React.Fragment>
                <Grid container alignItems="center">
                    <Grid container item alignItems="center">
                        <Grid container item xs={9}>
                            <Grid item className={customStyles.gridItemGroupName}>
                                <TypographySubtitle text={props.reportContainer.reportContainerName} />
                            </Grid>
                        </Grid>
                        <Grid container item xs={3} alignItems="center" justify="flex-end">
                            <Grid item className={customStyles.gridItem}>
                                <ButtonOutlined margin="10" text="Off" handleClick={() => props.handleAllValuesChange(props.reportContainer.reportContainerID, false)} />
                            </Grid>
                            <Grid item className={customStyles.gridItem}>
                                <ButtonOutlined margin="10" text="On" backgroundColor={"#DCDCDC"} handleClick={() => props.handleAllValuesChange(props.reportContainer.reportContainerID, true)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item alignItems="center">
                        <Grid item xs={12}>
                            <MaterialTable
                                columns={[]}
                                data={props.reportContainer.reports}
                                options={{
                                    showTitle: false,
                                    header: false,
                                    paging: false,
                                    search: false,
                                    toolbar: false
                                }}
                                components={{
                                    Row: tableProps => (
                                    <AccessRightsListItemReports 
                                        report={tableProps.data}
                                        handleReportSelectedChange={props.handleReportSelectedChange}
                                        defaultTab={props.defaultTab}
                                    />
                                )}}
                                style={{
                                    background: 'transparent',
                                    boxShadow: 'none'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    };

export default AccessRightsListReports;