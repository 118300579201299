import * as React from "react";
import { IAppEditorComponentProps } from "./interfaces";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";

const AppEditorComponentGuid: (props: IAppEditorComponentProps) => JSX.Element =
  (props: IAppEditorComponentProps): JSX.Element => {
    return (
      <TextFieldEntered
        name="guid"
        label="GUID"
        size="small"
        value={props.value}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        error={!!props.error}
        helperText={props.errorText}
        inputProps={{
          maxLength: 36,
        }}
        fullWidth
        required
      />
    );
  };

export default AppEditorComponentGuid;
