import * as React from "react";
import {
  WebAppInstanceType,
  WebAppChangeInstanceOrderType,
} from "Types/app-types";
import ClientAppApi from "Services/Api/client-app-api";
import { ApiResultStatusEnum } from "Common/enum";
import { AxiosError } from "axios";
import { IWebServiceListContainerProps } from "./interfaces-web-service";
import { WebServiceList } from "./web-service-list";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";

const clientAppApi = new ClientAppApi();

export default function WebServiceListContainer(
  props: IWebServiceListContainerProps
) {
  const [instanceList, setInstanceList] = React.useState<
    WebAppInstanceType[]
  >();
  const [isErrorDlgOpen, setIsErrorDlgOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [canDragInstance, setCanDragInstance] = React.useState(true);

  React.useEffect(() => {
    setInstanceList(props.instanceList);
  }, [props.instanceList]);

  const changeInstanceOrder = async (
    appInstance: number,
    oldIndex: number,
    newIndex: number
  ) => {
    let newOrder: WebAppChangeInstanceOrderType = {
      iaAppID: props.webAppId,
      iaWebAppInstanceID: appInstance,
      oldIndex: oldIndex,
      newIndex: newIndex,
    };

    await clientAppApi
      .changeWebAppInstanceOrder(newOrder)
      .then((result) => {
        if (result.status === ApiResultStatusEnum.Ok) {
          setCanDragInstance(true);
        }
      })
      .catch((error) => {
        setErrorResponse(error);
        setIsErrorDlgOpen(true);
        setInstanceList([]);
      });
  };

  const handleErrorDlgOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDlgOpen(false);
  };

  const sortList = (list: WebAppInstanceType[]): WebAppInstanceType[] => {
    list.sort((a, b) => (a.orderNo > b.orderNo ? 1 : -1));
    return list;
  };

  const onSortEnd = async ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (canDragInstance) {
      let newInstanceList = [...instanceList!];
      let draggedItemIndex = newInstanceList.findIndex(
        (x) => x.orderNo === oldIndex
      );
      newInstanceList[draggedItemIndex].orderNo = -1;

      if (newIndex > oldIndex) {
        newInstanceList.forEach((instance: WebAppInstanceType) => {
          if (instance.orderNo > oldIndex && instance.orderNo <= newIndex) {
            instance.orderNo--;
          }
        });
      } else {
        newInstanceList.forEach((instance: WebAppInstanceType) => {
          if (instance.orderNo >= newIndex && instance.orderNo < oldIndex) {
            instance.orderNo++;
          }
        });
      }
      draggedItemIndex = newInstanceList.findIndex((x) => x.orderNo === -1);
      newInstanceList[draggedItemIndex].orderNo = newIndex;
      const instance = newInstanceList[draggedItemIndex];
      setInstanceList(sortList(newInstanceList));
      setCanDragInstance(false);
      if (instance.id) {
        await changeInstanceOrder(instance.id, oldIndex, newIndex);
      }
    }
  };

  return (
    <>
      <WebServiceList
        instanceList={{
          getWebApp: props.getWebApp,
          instanceList: instanceList || [],
          platformType: props.platformType,
        }}
        onSortEnd={onSortEnd}
        useDragHandle
      />
      <ErrorDialog
        isOpen={isErrorDlgOpen}
        error={errorResponse}
        handleOkClick={handleErrorDlgOkClick}
      />
    </>
  );
}
