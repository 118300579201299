import * as React from "react";
import * as Yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@material-ui/core";
import ButtonOutlined from "Components/Buttons/button-outlined";
import {
  IconType,
  FileTypeEnum,
  ApiResultStatusEnum,
  FormEditorEnum,
  AppPlatformEnum,
} from "Common/enum";
import SvgUpload from "Components/Images/image-upload";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { Formik } from "formik";
import moment from "moment";
import ClientAppApi from "Services/Api/client-app-api";
import InputCheckbox from "Components/Inputs/input-checkbox";
import {
  MobileAppVersionType,
  WebAppWithVersions,
  MobileWebApp,
} from "Types/app-types";
import { AxiosError } from "axios";
import FileDownload from "Components/File/FileDownload/file-download";
import FileUpload from "Components/File/FileUpload/file-upload";
import FileApi from "Services/Api/file-api";
import { FileUploadType } from "Types/file-types";
import {
  FileUploadApiResultType,
  MobileAppVersionSaveApiResultType,
} from "Types/api-types";
import ProgressSpinner from "Components/ProgressSpinner/progress-spinner";
import AppVersionWebAppItem from "./app-version-web-app-item";
import TypographyInfo from "Components/Typography/typography-info";
import ButtonEdit from "Components/Buttons/ButtonEdit/button-edit";
import { IAppVersionEditorProps } from "./interfaces-app-version-editor";
import { initValues, validationShape } from "./formikValues";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import Swal from "sweetalert2";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import DateField from "Components/Inputs/DateField/date-field";

const VERSION_NO_MAX_LENGTH: number = 20;
const RELEASE_NOTES_MAX_LENGTH: number = 4000;
const FILE_MAX_SIZE: number = 104857600; //100 MB
const fileUploadAcceptedFormats = [".apk", ".xapk", ".ipa", ".jar"];

const clientAppApi: ClientAppApi = new ClientAppApi();
let fileApi: FileApi = new FileApi();

export default function AppVersionEditor(props: IAppVersionEditorProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [versionList, setVersionList] = React.useState<WebAppWithVersions[]>();
  const [mobileWebAppVersionList, setMobileWebAppVersionList] = React.useState<
    MobileWebApp[]
  >([]);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [isBlockedUi, setIsBlockedUi] = React.useState(false);
  const [isExecuting, setIsExecuting] = React.useState(false);

  const handleMobileWebAppVersionChange = (
    mobileWebAppVersion: MobileWebApp
  ): void => {
    if (mobileWebAppVersionList.length > 0) {
      setMobileWebAppVersionList(
        mobileWebAppVersionList.map((item, index) => {
          if (item.mobileWebAppID === mobileWebAppVersion.mobileWebAppID) {
            return {
              ...item,
              webAppVersionRequired: mobileWebAppVersion.webAppVersionRequired,
            };
          }
          return item;
        })
      );
    } else {
      setMobileWebAppVersionList([mobileWebAppVersion]);
    }
  };

  const handleOpen = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (versionList) {
      setMobileWebAppVersions(versionList);
    } else {
      setMobileWebAppVersionList([]);
    }
    setIsOpen(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setIsOpen(false);
  };

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const handleSave = async (
    mobileAppVersion: MobileAppVersionType,
    newAppFile?: Blob
  ): Promise<void> => {
    let fileToUpload: FileUploadType | undefined;
    if (props.editorType === FormEditorEnum.Create && newAppFile) {
      fileToUpload = {
        type: FileTypeEnum.AppInstall,
        file: newAppFile,
      };
    } else if (props.editorType === FormEditorEnum.Edit) {
      mobileAppVersion.appFile = props.mobileAppVersion?.appFile;
      mobileAppVersion.id = props.mobileAppVersion?.id;
    }
    mobileAppVersion.mobileWebApps = mobileWebAppVersionList;

    setIsExecuting(true);
    setIsBlockedUi(true);
    await save(mobileAppVersion, fileToUpload)
      .then(() => {
        setIsOpen(false);
        setIsExecuting(false);
      })
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
        setIsExecuting(false);
      })
      .finally(() => {
        setIsBlockedUi(false);
        setIsExecuting(false);
      });
  };

  const save = async (
    mobileAppVersion: MobileAppVersionType,
    fileToUpload: FileUploadType | undefined
  ): Promise<MobileAppVersionSaveApiResultType | void> => {
    if (fileToUpload) {
      return await uploadFile(fileToUpload).then(
        async (uploadResult: FileUploadApiResultType) => {
          if (uploadResult.status === ApiResultStatusEnum.Ok) {
            mobileAppVersion.appFile = uploadResult.data;
            return await saveMobileAppVersion(mobileAppVersion);
          }
        }
      );
    } else {
      return await saveMobileAppVersion(mobileAppVersion);
    }
  };

  const saveMobileAppVersion = async (
    mobileAppVersion: MobileAppVersionType
  ): Promise<MobileAppVersionSaveApiResultType | void> => {
    return await clientAppApi
      .saveMobileAppVersion(mobileAppVersion)
      .then(async () => {
        Swal.fire({
          icon: "success",
          text: "Successfully saved!",
          width: "25rem",
          customClass: {
            title: "swal-title",
            container: "swal-container",
            content: "swal-content",
            confirmButton: "swal-confirm-button",
          },
        });
        await props.getMobileAppVersionList();
      });
  };

  const uploadFile = async (
    fileToUpload: FileUploadType
  ): Promise<FileUploadApiResultType> => {
    return await fileApi.upload(fileToUpload);
  };

  const loadWebAppWithVersions = React.useCallback(async (): Promise<void> => {
    await clientAppApi.getWebAppWithVersionsList(props.appId).then((result) => {
      if (result.status === ApiResultStatusEnum.Ok) {
        setVersionList(result.data);
        setMobileWebAppVersions(result.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appId]);

  const setMobileWebAppVersions = (
    webAppWithVersionList: WebAppWithVersions[]
  ): void => {
    let versionList: MobileWebApp[] = webAppWithVersionList.map((d) => {
      return {
        webAppVersionRequired:
          props.mobileAppVersion && props.mobileAppVersion.mobileWebApps
            ? props.mobileAppVersion.mobileWebApps.find(
                (a) => a.mobileWebAppID === d.mobileWebAppID
              )?.webAppVersionRequired
            : d.versionList[0].apiVersionNo,
        mobileWebAppID: d.mobileWebAppID,
      };
    });
    setMobileWebAppVersionList(versionList);
  };

  React.useEffect(() => {
    if (isOpen) {
      if (versionList) {
        setMobileWebAppVersions(versionList);
      } else {
        loadWebAppWithVersions();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, versionList, loadWebAppWithVersions]);

  const headerText =
    props.editorType === FormEditorEnum.Edit
      ? `Edit ${props.appName} version`
      : `New ${props.appName} version`;

  const button =
    props.editorType === FormEditorEnum.Edit ? (
      <ButtonEdit iconWidth="25px" handleClick={handleOpen} />
    ) : (
      <ButtonOutlined
        text="New"
        iconStartType={IconType.SvgImageIcon}
        iconStart={<SvgUpload width="15px" />}
        size={props.buttonSize}
        margin="0 10px 0 0"
        handleClick={handleOpen}
      />
    );
  const isFileUpload = props.platformType === AppPlatformEnum.Android;
  const isMobileApplication =
    props.platformType === AppPlatformEnum.Android ||
    props.platformType === AppPlatformEnum.IOS;

  return (
    <>
      {button}
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"md"}
        onClose={handleClose}
        style={{ zIndex: 500 }}
      >
        <ProgressSpinner isDisplayed={isBlockedUi}>
          <DialogTitle>
            <TypographySubtitle text={headerText} />
          </DialogTitle>
          <Formik
            enableReinitialize={!isBlockedUi && true}
            initialValues={initValues(props, versionList)}
            onSubmit={async (values) => {
              if (values.isForcedUpdate && props.isAnyVersionWithForceUpdate) {
                Swal.fire({
                  icon: "question",
                  text: "In this app you have already set the force update flag. Are you sure you want to change it?",
                  width: "25rem",
                  customClass: {
                    title: "swal-title",
                    container: "swal-container",
                    content: "swal-content",
                    confirmButton: "swal-confirm-button",
                  },
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                  reverseButtons: true,
                }).then((result) => {
                  if (result.value) {
                    if (props.editorType === FormEditorEnum.Create) {
                      values.releaseDate = moment(values.releaseDate).format();
                      handleSave(values, values.file);
                    } else {
                      handleSave(values);
                    }
                  }
                });
              } else {
                if (props.editorType === FormEditorEnum.Create) {
                  values.releaseDate = moment(values.releaseDate).format();
                  await handleSave(values, values.file);
                } else {
                  await handleSave(values);
                }
              }
            }}
            validationSchema={Yup.object().shape(validationShape)}
          >
            {(formProps) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              } = formProps;
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <Grid container justify="center" alignItems="flex-start">
                      <Grid container item sm={12}>
                        {isFileUpload ? (
                          <Grid container item sm={4}>
                            {props.editorType === FormEditorEnum.Edit ? (
                              <FileDownload
                                file={props.mobileAppVersion?.appFile}
                              />
                            ) : (
                              <FileUpload
                                file={values.file}
                                setFile={(newFile) =>
                                  setFieldValue("file", newFile)
                                }
                                acceptedFormats={fileUploadAcceptedFormats}
                                maxSize={FILE_MAX_SIZE}
                                width="250px"
                                height="200px"
                              />
                            )}
                          </Grid>
                        ) : null}
                        <Grid
                          container
                          item
                          sm={isFileUpload ? 8 : 12}
                          spacing={2}
                        >
                          <Grid item sm={6}>
                            <TextFieldEntered
                              id="versionNo"
                              name="versionNo"
                              label="Release number"
                              value={values.versionNo}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={!!(errors.versionNo && touched.versionNo)}
                              helperText={
                                errors.versionNo &&
                                touched.versionNo &&
                                errors.versionNo
                              }
                              inputProps={{
                                maxLength: VERSION_NO_MAX_LENGTH,
                              }}
                              disabled={
                                props.editorType === FormEditorEnum.Edit
                              }
                              required
                            />
                          </Grid>

                          <Grid item sm={6}>
                            <DateField
                              id="releaseDate"
                              name="releaseDate"
                              label="Release date"
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              value={values.releaseDate}
                              onChange={(date) =>
                                setFieldValue("releaseDate", date)
                              }
                              onBlur={handleBlur}
                              error={
                                !!(errors.releaseDate && touched.releaseDate)
                              }
                              helperText={
                                errors.releaseDate &&
                                touched.releaseDate &&
                                errors.releaseDate
                              }
                              autoOk={true}
                              required
                            />
                          </Grid>

                          <Grid item sm={12}>
                            <TextFieldEntered
                              id="releaseNotes"
                              name="releaseNotes"
                              label="Release notes"
                              value={values.releaseNotes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                !!(errors.releaseNotes && touched.releaseNotes)
                              }
                              helperText={
                                (errors.releaseNotes &&
                                  touched.releaseNotes &&
                                  errors.releaseNotes) ||
                                `${values.releaseNotes.length}/${RELEASE_NOTES_MAX_LENGTH}`
                              }
                              inputProps={{
                                maxLength: RELEASE_NOTES_MAX_LENGTH,
                              }}
                              multiline
                              rowsMax={8}
                            />
                          </Grid>

                          <Grid container item sm={12}>
                            {isMobileApplication ? (
                              <>
                                <Grid item sm={6}>
                                  <InputCheckbox
                                    checked={values.availableToInstall}
                                    onChange={() =>
                                      setFieldValue(
                                        "availableToInstall",
                                        !values.availableToInstall
                                      )
                                    }
                                    text="Ready to distribute"
                                    margin="10px 5px 0px"
                                  />
                                </Grid>
                                <Grid item sm={6}>
                                  <InputCheckbox
                                    checked={values.isForcedUpdate}
                                    onChange={() =>
                                      setFieldValue(
                                        "isForcedUpdate",
                                        !values.isForcedUpdate
                                      )
                                    }
                                    text="Force update"
                                    margin="10px 5px 0px"
                                  />
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                      {values.versionList?.length &&
                      values.versionList.length > 0 ? (
                        <>
                          <Grid item sm={12}>
                            <TypographyInfo
                              text={"Required API versions"}
                              margin={"15px 0"}
                            />
                          </Grid>
                          <Grid container item sm={12} spacing={2}>
                            {values.versionList?.map((webApp) => (
                              <AppVersionWebAppItem
                                key={`${webApp.id}_${webApp.mobileWebAppID}`}
                                webAppWithVersions={webApp}
                                mobileWebApp={mobileWebAppVersionList?.find(
                                  (x) =>
                                    x.mobileWebAppID === webApp.mobileWebAppID
                                )}
                                handleVersionChange={
                                  handleMobileWebAppVersionChange
                                }
                              />
                            ))}
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <ButtonSubaction text="Cancel" handleClick={handleClose} />
                    <ButtonAction
                      text="Save"
                      isSubmit
                      isExecuting={isExecuting}
                    />
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </ProgressSpinner>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </>
  );
}
