import { makeStyles } from "@material-ui/core";

export const stylesMobileAppHeader = makeStyles({
  link: {
    textDecoration: "none",
  },
  paper: {
    padding: "20px 40px",
    borderRadius: "60px",
    marginTop: "30px",
  },
});
