import * as React from 'react';
import AuthorizationHelper from 'Configuration/authorization-helper';
import ButtonEdit from 'Components/Buttons/ButtonEdit/button-edit';
import Colors from 'Common/colors';
import ConfirmDialog from 'Components/Dialogs/ConfirmDialog/confirm-dialog';
import ErrorDialog from 'Components/Dialogs/ErrorDialog/error-dialog';
import SvgCustomerRole from 'Components/Images/image-customer-role';
import SvgEmail from 'Components/Images/image-email';
import TypographyInfo from 'Components/Typography/typography-info';
import TypographySubinfo from 'Components/Typography/typography-subinfo';
import UserApi from 'Services/Api/user-api';
import UserDelete from 'Components/User/UserDelete/user-delete';
import UserEditor from 'Components/User/UserEditor/user-editor';
import { AxiosError } from 'axios';
import { FormEditorEnum, UserActiveType } from 'Common/enum';
import { Grid, Paper } from '@material-ui/core';
import { IUserItemProps } from 'Components/User/interfaces-user';
import { RouteUser } from 'Configuration/Routes';
import { stylesUserListItem } from 'Components/User/UserList/styles';
import { useHistory } from 'react-router-dom';

export default function UserListItem(props: IUserItemProps) {
  const history = useHistory();
  const userApi = new UserApi();
  const maxCustomersToDisplay = 4;

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] =
    React.useState<boolean>(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );

  const customStyles = stylesUserListItem();
  const { item } = props;

  const editUserUrl = `${RouteUser}/${item.userID}/${props.appGuid}`;

  const handleClickButtonEdit = () => {
    if (item.activeType === UserActiveType.Active) {
      history.push(editUserUrl);
    } else {
      setIsOpenConfirmDialog(true);
    }
  };

  const handleOkClickConfirmDialog = () => {
    const currentUserID = AuthorizationHelper.getUser().userID;
    if (!currentUserID) {
      history.push("/");
    } else {
      userApi
        .setUserActive({
          userID: props.item.userID,
          currentUserID,
          isActive: true,
        })
        .then((response) => {
          setIsOpenConfirmDialog(false);
          if (response.data.isSaved) {
            history.push(editUserUrl);
          } else {
            setIsErrorDialogOpen(true);
          }
        })
        .catch((error) => {
          setErrorResponse(error);
          setIsErrorDialogOpen(true);
        });
    }
  };

  return (
    <>
      <tr>
        <td>
          <Paper className={customStyles.paper}>
            <Grid container alignItems="center">
              <Grid container item xs={!props.systemAppSelected ? 2 : 3}>
                <Grid
                  container
                  item
                  xs={12}
                  className={customStyles.gridItem}
                  alignItems="center"
                >
                  <TypographyInfo
                    text={`${item.firstName} ${item.lastName}`}
                    color={Colors.black}
                  />
                </Grid>
              </Grid>
              {!props.systemAppSelected && (
                <Grid
                  container
                  item
                  xs={5}
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                >
                  {item.customers && item.customers.length > 0 ? (
                    <SvgCustomerRole className={customStyles.emailIcon} />
                  ) : (
                    ""
                  )}
                  <Grid
                    container
                    item
                    xs={11}
                    direction="row"
                    wrap="nowrap"
                    alignItems="center"
                  >
                    {item.customers.slice(0, maxCustomersToDisplay).map((c) => (
                      <Grid
                        container
                        item
                        key={c.customerID}
                        direction="column"
                      >
                        {c.customerName ? (
                          <Grid item container>
                            <TypographyInfo
                              style={{
                                fontSize: "12px",
                                lineHeight: "12px",
                                margin: "2px",
                              }}
                              text={c.customerName}
                              color={Colors.grayDark}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Grid item container>
                          <TypographyInfo
                            style={{
                              fontSize: "12px",
                              lineHeight: "12px",
                              margin: "2px",
                            }}
                            text={c.roleName ?? ""}
                            color={Colors.grayDark}
                          />
                        </Grid>
                      </Grid>
                    ))}
                    {item.customers &&
                    item.customers.length > maxCustomersToDisplay ? (
                      <Grid item direction="column" alignContent="flex-end">
                        ...
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              )}
              <Grid container item xs={!props.systemAppSelected ? 2 : 4}>
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  alignItems="center"
                >
                  <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    alignItems="center"
                  >
                    {item.email ? (
                      <SvgEmail
                        className={customStyles.emailIcon}
                        width={"10px"}
                      />
                    ) : item.hasLogins ? (
                      ""
                    ) : (
                      <TypographySubinfo
                        text="No logins"
                        color={Colors.grayDark}
                      />
                    )}
                    <TypographySubinfo
                      text={item.email ? item.email : ""}
                      color={Colors.grayDark}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    alignItems="center"
                  >
                    {item.otherEmails && item.otherEmails.length > 0 && (
                      <TypographySubinfo
                        text={`and ${item.otherEmails.length} other`}
                        color="#959595"
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={!props.systemAppSelected ? 2 : 4}>
                {item.phoneNumber &&
                  item.phonePrefix &&
                  !props.systemAppSelected && (
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      alignItems="center"
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        direction="row"
                        alignItems="center"
                      >
                        <SvgEmail
                          className={customStyles.emailIcon}
                          width={"10px"}
                        />
                        <TypographySubinfo
                          text={`(${item.phonePrefix}) ${item.phoneNumber}`}
                          color={Colors.grayDark}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        direction="row"
                        alignItems="center"
                      >
                        {item.otherPhoneNumbers &&
                          item.otherPhoneNumbers.length > 0 && (
                            <TypographySubinfo
                              text={`and ${item.otherPhoneNumbers.length} other`}
                              color="#959595"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}
              </Grid>

              <Grid container item xs={1}>
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justify="flex-end"
                >
                  {props.systemAppSelected ? (
                    <UserEditor
                      editorType={FormEditorEnum.Edit}
                      appGuid={props.appGuid}
                      user={item}
                      getUsers={props.getUsers}
                      setUserData={null}
                      systemAppSelected={props.systemAppSelected}
                    />
                  ) : (
                    <ButtonEdit
                      iconWidth="25px"
                      handleClick={handleClickButtonEdit}
                    />
                  )}

                  {item.canDelete &&
                    item.activeType === UserActiveType.Active && (
                      <UserDelete
                        userID={item.userID}
                        getUsers={props.getUsers}
                        appGuid={props.appGuid}
                        systemAppSelected={props.systemAppSelected}
                      />
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </td>
      </tr>
      <ConfirmDialog
        text={"Would you like to activate this User?"}
        isOpen={isOpenConfirmDialog}
        handleOkClick={handleOkClickConfirmDialog}
        handleCancelClick={() => setIsOpenConfirmDialog(false)}
      />
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={() => setIsErrorDialogOpen(false)}
      />
    </>
  );
}
