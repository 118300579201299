import * as React from 'react';
import Devices from 'Views/Applications/devices';
import WebApplicationDetails from 'Views/Applications/web-application-details';
import MobileApplicationDetails from 'Views/Applications/mobile-application-details';
import WebApplicationInstanceDetails from 'Views/Applications/web-application-instance-details';
import { RouteType } from 'Types/common-types';
import { Route } from 'react-router-dom';
import { ClientType } from 'Types/client-types';
import { RouteReportEdit } from 'Configuration/Routes';
import EditReportView from 'Views/Reports/EditReport/edit-report';

interface ISimpleRouteConfigProps {
  client: ClientType;
}

const routes: RouteType[] = [
  {
    path: '/web-application/:id',
    component: WebApplicationDetails,
  },
  {
    path: '/web-application-instance/:id',
    component: WebApplicationInstanceDetails,
  },
  {
    path: '/mobile-application/:id',
    component: MobileApplicationDetails,
  },
  {
    path: '/devices/:mobileAppId',
    component: Devices,
  },
  {
    path: `${RouteReportEdit}/:id`,
    component: EditReportView,
  },
];

let selectedClient: ClientType;

function GenerateRoute(route: RouteType) {
  return (
    <Route
      path={route.path}
      render={(props) => <route.component client={selectedClient} {...props} />}
    />
  );
}

export default function SimpleRouteConfig(props: ISimpleRouteConfigProps) {
  selectedClient = props.client;

  return (
    <React.Fragment>
      {routes.map((route, i) => (
        <GenerateRoute key={i} {...route} />
      ))}
    </React.Fragment>
  );
}
