import * as React from "react";

const SvgUser = ({
    style = {},
    fill = "none",
    width = "50",
    className = "",
    viewBox = "0 0 50 50"
}) => (
        <svg width={width}
            style={style}
            height={width}
            viewBox={viewBox}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <circle cx="25" cy="25" r="25" fill="#DCDCDC" />
            <circle cx="25" cy="15" r="6" stroke="#AEAEAE" strokeWidth="2" />
            <mask id="path-3-inside-1" fill="white">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.92261 33.1029C12.5664 28.1866 18.4115 25 25.0008 25C31.5896 25 37.4342 28.1861 41.0781 33.1016C38.1135 38.9734 32.027 43 25 43C17.9736 43 11.8874 38.974 8.92261 33.1029Z" />
            </mask>
            <path d="M8.92261 33.1029L7.31583 31.912L6.5812 32.9032L7.13733 34.0044L8.92261 33.1029ZM41.0781 33.1016L42.8635 34.003L43.4195 32.9016L42.6848 31.9105L41.0781 33.1016ZM10.5294 34.2938C13.8122 29.8646 19.0718 27 25.0008 27V23C17.7512 23 11.3206 26.5087 7.31583 31.912L10.5294 34.2938ZM25.0008 27C30.9294 27 36.1885 29.8641 39.4714 34.2926L42.6848 31.9105C38.6799 26.5081 32.2499 23 25.0008 23V27ZM39.2927 32.2002C36.6551 37.4245 31.2433 41 25 41V45C32.8107 45 39.572 40.5223 42.8635 34.003L39.2927 32.2002ZM25 41C18.7572 41 13.3458 37.425 10.7079 32.2014L7.13733 34.0044C10.4291 40.5229 17.1899 45 25 45V41Z" fill="#AEAEAE" mask="url(#path-3-inside-1)" />
        </svg>

    );

export default SvgUser;