import * as React from 'react';
import ClientAppApi from 'Services/Api/client-app-api';
import CustomerApi from 'Services/Api/customer-api';
import UserApi from 'Services/Api/user-api';
import UsersHeader from 'Components/User/UserHeader/user-header';
import UsersList from './users-list';
import { ClientAppForCustomerApiResponseType } from 'Types/app-types';
import { Container } from '@material-ui/core';
import { GetCustomerListResponseType } from 'Types/customer-types';
import { GetRolesForAppApiResponseType } from 'Types/role-types';
import { GetUserListResponseType } from 'Types/user-types';
import { IUsersProps } from 'Components/User/interfaces-user';
import { LandingPageEnum, ParamType } from 'Common/enum';
import {
  getSavedPageParamValue,
  savePageParam,
} from "Configuration/settings-helper";

const clientAppApi = new ClientAppApi();
const userApi = new UserApi();
const customerApi = new CustomerApi();

export default function Users(props: IUsersProps) {
  const [customerGuid, setCustomerGuid] = React.useState("");
  const [customerID, setCustomerID] = React.useState<number | undefined>(
    undefined
  );
  const [appGuid, setAppGuid] = React.useState<string | undefined>(undefined);
  const [appID, setAppID] = React.useState(0);
  const [selectedAppFileUrl, setSelectedAppFileUrl] = React.useState("");
  const [applications, setApplications] = React.useState<
    ClientAppForCustomerApiResponseType[]
  >([]);
  const [users, setUsers] = React.useState<GetUserListResponseType[]>([]);
  const [customerList, setCustomerList] = React.useState<
    GetCustomerListResponseType[]
  >([]);
  const [roleList, setRoleList] = React.useState<
    GetRolesForAppApiResponseType[]
  >([]);

  const thisAppGuid: string = process.env.REACT_APP_IA_GUID!;

  React.useEffect(() => {
    if (
      props.client &&
      props.client.id &&
      props.client.id > 0 &&
      !props.systemAppSelected
    ) {
      const getCustomerList = async (): Promise<void> => {
        await customerApi.getList(props.client.id).then((result) => {
          if (result && result.data) {
            setCustomerList([
              {
                customerID: -1,
                name: "-- no customer --",
                description: "-- no customer --",
                guid: "empty",
                roles: [],
                pinLength: 0,
                isDealer: false,
                locations: [],
              },
              ...result.data,
            ]);
            if (result.data.length > 0) {
              const savedCustomerID = getSavedPageParamValue(
                LandingPageEnum.Users,
                ParamType.SelectedCustomerID
              );
              let customer: GetCustomerListResponseType | undefined =
                !Number.isNaN(Number(savedCustomerID))
                  ? result.data.find(
                      (c) => c.customerID === Number(savedCustomerID)
                    )
                  : undefined;

              if (!customer) {
                customer = result.data[0];
              }

              setCustomerGuid(customer.guid);
              setCustomerID(customer.customerID);
              setRoleList(customer.roles);
            }
          }
        });
      };
      getCustomerList();
    } else if (props.systemAppSelected) {
      setCustomerList([
        {
          customerID: -1,
          name: "-- no customer --",
          description: "-- no customer --",
          guid: "empty",
          roles: [],
          pinLength: 0,
          isDealer: false,
          locations: [],
        },
      ]);
      setCustomerGuid("empty");
      setCustomerID(-1);
      setRoleList([]);
    }
  }, [props.client, props.client.id, props.systemAppSelected]);

  React.useEffect(() => {
    const getApplications = async (): Promise<void> => {
      if (
        props.client &&
        props.client !== null &&
        props.client.id !== null &&
        props.client.id > 0 &&
        customerID !== undefined
      ) {
        await clientAppApi
          .getAppListForCustomer(
            props.client.id!,
            customerID > 0 ? customerID : 0
          )
          .then((result) => {
            if (result && result.data) {
              if (props.systemAppSelected) {
                let thisApp = result.data.find((app) => {
                  return app.appGuid === thisAppGuid;
                });
                setApplications(thisApp ? [thisApp] : []);
                setAppGuid(thisAppGuid);
                savePageParam({
                  page: LandingPageEnum.Users,
                  paramType: ParamType.SelectedAppGuid,
                  value: thisAppGuid,
                });
                if (thisApp && thisApp.logoFile) {
                  setSelectedAppFileUrl(thisApp.logoFile);
                } else {
                  setSelectedAppFileUrl("");
                }
              } else {
                setApplications(
                  result.data.filter((app) => {
                    return app.appGuid !== thisAppGuid;
                  })
                );
                if (result.data && result.data.length > 0) {
                  const savedAppGuid = getSavedPageParamValue(
                    LandingPageEnum.Users,
                    ParamType.SelectedAppGuid
                  );
                  const savedApp = savedAppGuid
                    ? result.data.find((a) => a.appGuid === savedAppGuid)
                    : undefined;

                  let resultApp = result.data[0];
                  if (savedApp) {
                    resultApp = savedApp;
                  }

                  setAppGuid(resultApp.appGuid);
                  setAppID(resultApp.appID);
                  savePageParam({
                    page: LandingPageEnum.Users,
                    paramType: ParamType.SelectedAppGuid,
                    value: resultApp.appGuid,
                  });
                  if (resultApp.logoFile) {
                    setSelectedAppFileUrl(resultApp.logoFile);
                  } else {
                    setSelectedAppFileUrl("");
                  }
                } else {
                  setAppGuid("");
                  setAppID(0);
                  setSelectedAppFileUrl("");
                }
              }
            }
          });
      }
    };
    getApplications();
  }, [customerID, props.client, thisAppGuid, props.systemAppSelected]);

  const getUsers = React.useCallback(async (): Promise<void> => {
    if (
      props.client &&
      props.client !== null &&
      appGuid &&
      appGuid !== "" &&
      customerID !== undefined
    ) {
      await userApi
        .getUserList(
          props.client.id!,
          appGuid,
          undefined,
          customerID > 0 ? customerID : undefined
        )
        .then((result) => {
          if (result && result.data) {
            setUsers(result.data);
          }
        });
    } else {
      setUsers([]);
    }
  }, [customerID, props.client, appGuid]);

  React.useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleAppChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let appGuid: string = event.target.value as string;
    setAppGuid(appGuid);
    if (applications) {
      let thisApp = applications.find((app) => {
        return app.appGuid === appGuid;
      });
      setAppID(thisApp?.appID ?? 0);
      if (thisApp) {
        savePageParam({
          page: LandingPageEnum.Users,
          paramType: ParamType.SelectedAppGuid,
          value: thisApp.appGuid,
        });
      }
      if (thisApp && thisApp.logoFile) {
        setSelectedAppFileUrl(thisApp.logoFile);
      } else {
        setSelectedAppFileUrl("");
      }
    }
  };

  const handleCustomerChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    let customerGuid: string = event.target.value as string;
    setAppGuid("");
    setAppID(0);
    setCustomerGuid(customerGuid);
    if (customerList) {
      const customer = customerList.find((c) => c.guid === customerGuid);
      if (customer) {
        setCustomerID(customer.customerID ?? 0);
        setRoleList(customer.roles);

        savePageParam({
          page: LandingPageEnum.Users,
          paramType: ParamType.SelectedCustomerID,
          value: customer.customerID.toString(),
        });

        savePageParam({
          page: LandingPageEnum.Users,
          paramType: ParamType.SelectedAppGuid,
          value: "",
        });
      }
    }
  };

  return (
    <Container>
      <UsersHeader
        logoFile={null}
        appTypeList={applications ? applications : []}
        defaultAppGuid={thisAppGuid}
        selectedAppGuid={appGuid ?? ""}
        selectedAppFileUrl={selectedAppFileUrl}
        onAppChange={handleAppChange}
        getUsers={getUsers}
        systemAppSelected={props.systemAppSelected}
        selectedCustomerGuid={customerGuid}
        onCustomerChange={handleCustomerChange}
        customerList={customerList}
      />
      <UsersList
        client={props.client}
        appGuid={appGuid ?? ""}
        searchText={undefined}
        systemAppSelected={props.systemAppSelected}
        users={users ? users : []}
        getUsers={getUsers}
        appID={appID}
        roleList={roleList}
      />
    </Container>
  );
}
