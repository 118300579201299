import * as React from "react";
import { Grid, Paper } from "@material-ui/core";
import TypographyInfo from "Components/Typography/typography-info";
import { IDictionaryListItemProps } from "Components/Reports/interfaces-reports";
import { stylesDictionaryList } from "Components/Reports/Dictionary/DictionaryList/styles";
import { IconType } from "Common/enum";
import SvgHeap from "Components/Images/image-heap";
import ButtonEdit from "Components/Buttons/ButtonEdit/button-edit";
import SvgDelete from "Components/Images/image-delete";
import { Formik } from "formik";
import ButtonOutlined from "Components/Buttons/button-outlined";
import SvgPlusCircle from "Components/Images/image-plus-circle";
import ButtonIcon from "Components/Buttons/button-icon";
import {
  SaveReportDictionaryModelType,
  DictionaryItemType,
} from "Types/report-types";
import DictionaryDelete from "Components/Reports/Dictionary/DictionaryList/dictionary-delete";
import * as Yup from "yup";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import { stylesButtonActionAndSubaction } from "Components/User/UserLogin/UserLoginListItem/styles";

export default function DictionaryListItem(props: IDictionaryListItemProps) {
  const [dictionaryName, setDictionaryName] = React.useState<string>("");
  const [itemList, setItemList] = React.useState<DictionaryItemType[]>([]);
  const [disabledSave, setDisabledSave] = React.useState(true);

  const NAME_MAX_LENGTH: number = 100;

  const customStyles = stylesDictionaryList();
  const buttonsCustomStyles = stylesButtonActionAndSubaction();

  const { dictionaryEditor } = props;

  React.useEffect(() => {
    setDictionaryName(
      props.dictionaryEditor.dictionary.dictionaryName
        ? props.dictionaryEditor.dictionary.dictionaryName
        : ""
    );
  }, [props.dictionaryEditor.dictionary.dictionaryName]);

  React.useEffect(
    () => {
      setItemList(props.dictionaryEditor.dictionary.items);

      validateItems(props.dictionaryEditor.dictionary.items);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.dictionaryEditor.dictionary.items]
  );

  const handleEditToggle = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    props.handleOpenDictionary(dictionaryEditor.dictionary);
  };

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDictionaryName(event.target.value);

    validateItems(itemList);
  };

  const handleItemNameChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    let newItemList: DictionaryItemType[] = [...itemList];
    newItemList[index] = {
      itemID: newItemList[index].itemID,
      itemName: event.target.value as string,
      itemValue: newItemList[index].itemValue,
      orderNo: newItemList[index].orderNo,
    };
    setItemList(newItemList);

    validateItems(newItemList);
  };

  const handleItemValueChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    let newItemList: DictionaryItemType[] = [...itemList];
    newItemList[index] = {
      itemID: newItemList[index].itemID,
      itemName: newItemList[index].itemName,
      itemValue: event.target.value as string,
      orderNo: newItemList[index].orderNo,
    };
    setItemList(newItemList);

    validateItems(newItemList);
  };

  const validateRequest = (): boolean => {
    if (itemList.length < 1) {
      props.showInfoMessage("Cannot add list without items.");
      return false;
    }

    let itemRequired: boolean = false;
    for (let i = 0; i < itemList.length; i++) {
      if (
        (itemList[i].itemName === "" && itemList[i].itemValue !== "") ||
        (itemList[i].itemName !== "" && itemList[i].itemValue === "")
      ) {
        itemRequired = true;
        break;
      }
    }
    if (itemRequired) {
      props.showInfoMessage("Value and Description are required.");
      return false;
    }

    return true;
  };

  const validateItems = (newItemList: DictionaryItemType[]): boolean => {
    if (newItemList.length < 1) {
      setDisabledSave(true);
      return false;
    }

    for (let i = 0; i < newItemList.length; i++) {
      if (newItemList[i].itemValue === "" || newItemList[i].itemName === "") {
        setDisabledSave(true);
        return false;
      }
    }

    setDisabledSave(false);
    return true;
  };

  const handleSubmit = async (): Promise<void> => {
    let dictionary: SaveReportDictionaryModelType = {
      clientID: props.client.id,
      dictionaryID: dictionaryEditor.dictionary.dictionaryID,
      dictionaryName: dictionaryName,
      items: itemList
        .map((item) => {
          return {
            itemID: item.itemID,
            itemName: item.itemName,
            itemValue: item.itemValue,
            orderNo: item.orderNo,
          };
        })
        .filter((item) => item.itemName !== "" && item.itemValue !== ""),
    };
    props.handleSaveDictionary(dictionary);
  };

  const addItem = () => {
    let newItemList: DictionaryItemType[] = [...itemList];
    newItemList.push({
      itemID: 0,
      itemName: "",
      itemValue: "",
      orderNo: itemList.length + 1,
    });
    setItemList(newItemList);

    validateItems(newItemList);
  };

  const deleteItem = (index: number) => {
    let newItemList: DictionaryItemType[] = [...itemList];
    newItemList.splice(index, 1);
    setItemList(newItemList);

    validateItems(newItemList);
  };

  const handleAddItem = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    addItem();
  };

  const handleDeleteItem = async (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ): Promise<void> => {
    deleteItem(index);
  };

  return (
    <tr>
      <td>
        <Paper className={customStyles.paper}>
          <Grid container alignItems="center">
            <Grid container item sm={3}>
              <Grid
                container
                item
                sm={12}
                direction="column"
                className={customStyles.gridItem}
                justify="center"
              >
                <TypographyInfo
                  style={{ margin: "0px" }}
                  text={dictionaryEditor.dictionary.dictionaryName}
                  color="#000000"
                />
              </Grid>
            </Grid>
            <Grid container item sm={7}>
              <SvgHeap style={{ marginTop: "10px" }} />
              {dictionaryEditor.dictionary.items
                .sort((a, b) => a.itemName.localeCompare(b.itemName))
                .map((item) => {
                  return (
                    <TypographyInfo
                      key={item.itemID}
                      text={item.itemName}
                      color="#444444"
                      margin={"5px 10px"}
                      style={{ fontSize: "14px", lineHeight: "21px" }}
                    />
                  );
                })}
            </Grid>
            <Grid container item sm={2}>
              <Grid
                container
                item
                sm={12}
                alignItems="center"
                justify="flex-end"
              >
                <ButtonEdit iconWidth="25px" handleClick={handleEditToggle} />
                <DictionaryDelete
                  dictionaryEditor={dictionaryEditor}
                  handleDeleteDictionary={props.handleDeleteDictionary}
                />
              </Grid>
            </Grid>
          </Grid>
          {props.isOpen && (
            <Grid className={customStyles.editForm}>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  dictionaryID: dictionaryEditor
                    ? dictionaryEditor.dictionary.dictionaryID
                    : 0,
                  dictionaryName: dictionaryName,
                  items: itemList.map((item) => {
                    return {
                      itemID: item.itemID,
                      itemName: item.itemName,
                      itemValue: item.itemValue,
                      orderNo: item.orderNo,
                    };
                  }),
                }}
                onSubmit={async (values) => {
                  if (validateRequest()) {
                    await handleSubmit();
                  }
                }}
                validationSchema={Yup.object().shape({
                  dictionaryName: Yup.string()
                    .label("Name")
                    .max(NAME_MAX_LENGTH)
                    .trim()
                    .required("Required name."),
                })}
              >
                {(formProps) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = formProps;
                  return (
                    <form noValidate onSubmit={handleSubmit}>
                      <Grid
                        container
                        alignItems="center"
                        spacing={3}
                        className={customStyles.row}
                      >
                        <Grid container item sm={3} alignItems="center">
                          <TextFieldEntered
                            id="dictionaryName"
                            name="dictionaryName"
                            label="Name"
                            value={dictionaryName}
                            onChange={(event) => {
                              handleChange(event);
                              handleNameChange(event);
                            }}
                            onBlur={handleBlur}
                            error={
                              !!(
                                errors.dictionaryName && touched.dictionaryName
                              )
                            }
                            helperText={
                              (errors.dictionaryName &&
                                touched.dictionaryName &&
                                errors.dictionaryName) ||
                              `${
                                values.dictionaryName
                                  ? values.dictionaryName.length
                                  : 0
                              }/${100}`
                            }
                            inputProps={{
                              maxLength: NAME_MAX_LENGTH,
                            }}
                            required
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          sm={9}
                          alignItems="center"
                          justify="flex-end"
                        >
                          <ButtonOutlined
                            text={"Add item"}
                            iconStartType={IconType.SvgImageIcon}
                            iconStart={<SvgPlusCircle width={"14px"} />}
                            size={"md"}
                            margin="0 10px 0 0"
                            handleClick={handleAddItem}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        direction="row"
                        spacing={3}
                      >
                        {itemList.map((item, index) => {
                          return (
                            <Grid key={index} container item sm={4} spacing={3}>
                              <Grid item sm={3}>
                                <TextFieldEntered
                                  id={`dictionary-item-value-${index}`}
                                  name="itemValue"
                                  label="Value"
                                  value={item.itemValue}
                                  onChange={(event) => {
                                    handleChange(event);
                                    handleItemValueChange(event, index);
                                  }}
                                  onBlur={handleBlur}
                                  inputProps={{
                                    maxLength: NAME_MAX_LENGTH,
                                  }}
                                  required
                                />
                              </Grid>
                              <Grid item sm={5}>
                                <TextFieldEntered
                                  id={`dictionary-item-name-${index}`}
                                  name="itemName"
                                  label="Description"
                                  value={item.itemName}
                                  onChange={(event) => {
                                    handleChange(event);
                                    handleItemNameChange(event, index);
                                  }}
                                  onBlur={handleBlur}
                                  inputProps={{
                                    maxLength: NAME_MAX_LENGTH,
                                  }}
                                  required
                                />
                              </Grid>
                              <Grid item sm={3} justify="center">
                                <ButtonIcon
                                  iconType={IconType.SvgImageIcon}
                                  icon={<SvgDelete />}
                                  handleClick={(event) => {
                                    handleDeleteItem(event, index);
                                  }}
                                  title="Remove item"
                                />
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid
                        container
                        item
                        sm={12}
                        alignItems="center"
                        justify="flex-end"
                        className={buttonsCustomStyles.root}
                        spacing={0}
                      >
                        <Grid item>
                          <ButtonSubaction
                            text="Cancel"
                            handleClick={handleEditToggle}
                          />
                        </Grid>
                        <Grid item>
                          <ButtonAction
                            text="Save"
                            isSubmit
                            disabled={disabledSave}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
          )}
        </Paper>
      </td>
    </tr>
  );
}
