import { makeStyles } from "@material-ui/core";
import Colors from "Common/colors";
import { IImageUpload } from "Components/Image/interfaces-image";

export const stylesImageUpload = makeStyles({
  centerItem: {
    textAlign: "center",
  },
  container: (props: IImageUpload) => ({
    border: `1px dashed ${Colors.graySeparate}`,
    margin: props.margin ? props.margin : "20px",
    width: props.width ? props.width : "200px",
    height: props.height ? props.height : "200px",
    outline: "none",
  }),
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
});
