import { Grid } from "@material-ui/core";
import Colors from "Common/colors";
import TypographyInfo from "Components/Typography/typography-info";
import * as React from "react";
import ActionButtonMove from "./ActionButton/move";
import CustomList from "./custom-list";
import MoveListHeader from "./move-list-header";
import { MoveListEditorItemType } from "./types";

interface MoveListEditorLeftSideProps {
  countAll: number;
  onSearchChangeAll: (value: React.SetStateAction<string>) => void;
  checkedAll: number[];
  moveToSelected: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  filteredAll: MoveListEditorItemType[] | undefined;
  handleToggleAll: (value: number) => void;
  selectAll: () => void;
  isAllSelected: boolean;
}
const MoveListEditorLeftSide: React.FC<MoveListEditorLeftSideProps> = ({
  countAll,
  onSearchChangeAll,
  checkedAll,
  moveToSelected,
  filteredAll,
  handleToggleAll,
  selectAll,
  isAllSelected,
}) => {
  return (
    <Grid container item sm={6} direction="column">
      <Grid container item sm={12} alignItems="center">
        <TypographyInfo text={`All (${countAll})`} color={Colors.black} />
      </Grid>
      <MoveListHeader
        onSearchChange={onSearchChangeAll}
        actionButton={
          <ActionButtonMove
            checkedAllLength={checkedAll.length}
            moveToSelected={moveToSelected}
          />
        }
      />
      <CustomList
        isAllSelected={isAllSelected}
        selectAll={selectAll}
        items={filteredAll}
        checked={checkedAll}
        handleToggleItem={handleToggleAll}
      />
    </Grid>
  );
};
export default MoveListEditorLeftSide;
