import { makeStyles, Theme } from "@material-ui/core";
import Colors from "Common/colors";

export const stylesReportContainers = makeStyles((theme: Theme) => ({
  editButton: {
    marginLeft: "25px",
  },
  gridItem: {
    marginRight: "15px",
    marginBottom: "0px",
  },
  title: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "48px",
    color: theme.palette.grayDarker.main,
    borderBottom: "none",
  },

  expansionPanel: {
    width: "100%",
    marginBottom: "15px",
    "&.Mui-expanded:last-child": {
      marginBottom: "15px",
    },
  },
  expansionPanelSummary: {
    "&.Mui-expanded": {
      borderBottom: `1px solid ${Colors.graySeparate}`,
    },
  },
  expansionPanelDetails: {
    paddingBottom: 0,
  },
  reportContainerNameContainer: {
    borderRight: `1px solid ${Colors.graySeparate}`,
  },
  editButtonContainer: {
    paddingRight: "10px",
  },
  reportContainer: {
    height: "54px",
    borderBottom: `1px solid ${Colors.graySeparate}`,
  },
  list: {
    width: "94.5%",
    height: 400,
    overflowX: "auto",
    marginTop: "20px",
  },
  listItem: {
    borderBottom: `1px solid ${Colors.graySeparate}`,
  },
}));
