import * as React from "react";

const SvgSearch = ({
  fill = "none",
  color = "#444444",
  style = {},
  className = "",
}) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    className={className}
  >
    <circle cx="8.5" cy="9" r="5" stroke={color} />
    <path d="M12 12.5L15 15.5" stroke={color} />
  </svg>
);

export default SvgSearch;
