import { FileUploadType } from 'Types/file-types';
import { FileUploadApiResultType } from 'Types/api-types';
import { axiosInstance } from 'Services/Api/base-api';

export default class FileApi {
    download = async(guid: string): Promise<Blob | undefined> => {
        return await axiosInstance
            .get('/File/Download?guid=' + guid, { responseType: 'arraybuffer' })
            .then(response => new Blob([response.data]))
            .catch(error => {
                console.error(error);
                return undefined;
            })
    }
    upload = async (file: FileUploadType): Promise<FileUploadApiResultType> => {
        let formData = new FormData();
        formData.append('Type', file.type.toString());
        formData.append('File', file.file!);
        return await axiosInstance
            .post('/File/Upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => response.data)
    };
}