import TextField, { StandardTextFieldProps } from "@material-ui/core/TextField";
import React from "react";
import { stylesTextFieldEntered } from "./styles";

const TextFieldEntered: (props: StandardTextFieldProps) => JSX.Element = (
  props: StandardTextFieldProps
): JSX.Element => {
  const classes = stylesTextFieldEntered();
  return (
    <TextField
      {...props}
      classes={{
        root: classes.root,
      }}
    />
  );
};

export default TextFieldEntered;
