import * as React from "react";
import InputCheckbox from "../../../Inputs/input-checkbox";
import { GetReportColumnListType } from "../../../../Types/report-types";

export interface CheckboxHidableProps {
  isEditable: boolean;

  data: GetReportColumnListType;
  list: GetReportColumnListType[];
  handleChangeColumns: (columns: GetReportColumnListType[]) => void;
}

const CheckboxHidable: React.FC<CheckboxHidableProps> = ({
  data,
  isEditable,
  list,
  handleChangeColumns,
}) => {
  const handleChangeHidable = (
    event: React.ChangeEvent<HTMLInputElement>,
    columnID: number
  ): void => {
    const hidable: boolean = event.target.checked as boolean;

    handleChangeColumns(
      list.map((p) =>
        p.columnID === columnID
          ? {
              ...p,
              isHideable: hidable || false,
            }
          : p
      )
    );
  };

  return (
    <InputCheckbox
      disabled={!isEditable}
      checked={!!data.isHideable}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleChangeHidable(event, data.columnID)
      }
    />
  );
};

export default CheckboxHidable;
