import * as React from "react";
import { Grid } from "@material-ui/core";
import TypographyTitle from "Components/Typography/typography-title";
import { FormEditorEnum } from "Common/enum";
import { IMobileAppHeaderProps } from "Components/MobileApp/interfaces-mobile-app";
import ButtonTransparent from "Components/Buttons/button-transparent";
import { Link } from "react-router-dom";
import { stylesMobileAppHeader } from "Components/MobileApp/MobileAppHeader/styles";
import ImageOSIcon from "Components/Image/ImageOSIcon/image-os-icon";
import ImageAppLogo from "Components/Image/ImageAppLogo/image-app-logo";
import AppVersionEditor from "Components/AppVersionEditor/app-version-editor";
import TypographySubinfo from "Components/Typography/typography-subinfo";
import AppEditorMobile from "Components/App/AppEditor/app-editor-mobile";
import HeaderCard from "Components/HeaderCard/header-card";

export default function MobileAppHeader(props: IMobileAppHeaderProps) {
  let customStyles = stylesMobileAppHeader();

  return (
    <HeaderCard>
      <Grid container>
        <Grid
          container
          item
          xs={9}
          alignItems="center"
          justify="flex-start"
          spacing={2}
        >
          <Grid item>
            <ImageAppLogo
              guid={props.logoFile?.guid}
              width="60px"
              height="60px"
            />
          </Grid>
          <Grid item>
            <TypographyTitle text={props.appName} margin="10px 0 0 0" />
            <TypographySubinfo text={`GUID: ${props.appGuid}`} />
          </Grid>
          <Grid item>
            <ImageOSIcon platformType={props.platformType} width="35px" />
          </Grid>
          <Grid item>
            <AppEditorMobile
              clientId={props.clientId}
              roleList={props.roleList}
              titleText={"Edit mobile app"}
              id={props.appId}
            ></AppEditorMobile>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={3}
          alignItems="center"
          justify="flex-end"
          spacing={2}
        >
          <Grid item>
            <Link to={`/devices/${props.appId}`} className={customStyles.link}>
              <ButtonTransparent text="Device list" />
            </Link>
          </Grid>
          <Grid item>
            <AppVersionEditor
              appId={props.appId}
              appName={props.appName}
              editorType={FormEditorEnum.Create}
              platformType={props.platformType}
              isAnyVersionWithForceUpdate={props.isAnyVersionWithForceUpdate}
              getMobileAppVersionList={props.handleRefreshApp}
            />
          </Grid>
        </Grid>
      </Grid>
    </HeaderCard>
  );
}
