import * as React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import Colors from "Common/colors";

interface IButtonBlockProps {
  text: string;
  onClickEvent: (event: React.MouseEvent<HTMLButtonElement>) => void;
  iconStart?: IconDefinition;
  iconEnd?: IconDefinition;
  marginLeft?: string;
  color?: string;
  backgroundColor?: string;
  padding?: string;
  minWidth?: string;
}

export default function ButtonBlock(props: IButtonBlockProps) {
  const styles = makeStyles({
    button: {
      color: props.color ? props.color : Colors.grayLight,
      backgroundColor: props.backgroundColor
        ? props.backgroundColor
        : Colors.grayDark,
      borderRadius: 0,
      marginLeft: props.marginLeft ? props.marginLeft : "auto",
      padding: props.padding ? props.padding : "10px 20px",
      minWidth: props.minWidth ? props.minWidth : "64px",
    },
  });

  const customStyles = styles({});

  let startIcon, endIcon;
  if (props.iconStart) {
    startIcon = <FontAwesomeIcon icon={props.iconStart} />;
  }
  if (props.iconEnd) {
    endIcon = <FontAwesomeIcon icon={props.iconEnd} />;
  }

  return (
    <Button
      className={customStyles.button}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={props.onClickEvent}
    >
      {props.text}
    </Button>
  );
}
