import * as React from 'react';
import MaterialTable from 'material-table';
import RoleEditor from '../RoleEditor/role-editor';
import RoleListItem from 'Components/Role/RoleList/role-list-item';
import TypographyTitle from 'Components/Typography/typography-title';
import { FormEditorEnum } from 'Common/enum';
import { IRoleListProps } from 'Components/Role/interfaces-role';
import { useTheme } from '@material-ui/core';

export default function RoleList(props: IRoleListProps) {
  const theme = useTheme();

  const headerStyle: React.CSSProperties = {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.graySeparate.main}`,
    borderRadius: "5px",
  };
  return (
    <MaterialTable
      columns={[
        {
          field: "name",
          title: "Role",
          width: "auto",
          headerStyle: headerStyle,
        },
        {
          field: "Applications",
          title: "Applications",
          width: "auto",
          headerStyle: headerStyle,
          sorting: false,
        },
        {
          field: "Type",
          title: "Type",
          width: "auto",
          headerStyle: headerStyle,
          sorting: false,
        },
        {
          field: "Actions",
          title: (
            <RoleEditor
              editorType={FormEditorEnum.Create}
              appList={props.appList}
              getRoles={props.getRoles}
              role={null}
              client={props.client}
            />
          ),
          width: "auto",
          headerStyle: headerStyle,
          sorting: false,
        },
      ]}
      data={props.roleList}
      options={{
        showTitle: false,
        header: true,
        paging: false,
        sorting: true,
        search: false,
        toolbar: true,
        tableLayout: "auto",
      }}
      components={{
        Row: (propsRow) => (
          <RoleListItem
            role={propsRow.data}
            appList={props.appList}
            getRoles={props.getRoles}
            client={props.client}
          />
        ),
        Toolbar: (e) => (
          <TypographyTitle text="Roles" color={theme.palette.grayDarker.main} />
        ),
      }}
      style={{
        boxShadow: "none",
        margin: "20px",
        background: "none",
      }}
    />
  );
}
