import { axiosInstance } from 'Services/Api/base-api';
import { GetCountryListApiResultType } from 'Types/api-types';

export default class CountryApi {
    getList = async (): Promise<GetCountryListApiResultType> => {
        return await axiosInstance
            .get('/Country/GetList')
            .then(response => response.data)
            .catch(error => console.log(error));
    };
}