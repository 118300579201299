import * as React from "react";

const SvgConnectedContainers = ({
    style = {},
    fill = "none",
    color = "black",
    width = "100%",
    height = "100%",
    className = "",
    viewBox = "0 0 16 15",
    title = ""
  }) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect x="0.5" y="0.5" width="15" height="11" rx="1.5" stroke={color} />
      <path d="M4 7L6.66667 5L9.33333 7L12 5" stroke={color} />
      <path d="M5 14H11" stroke={color} />
      <title>{title}</title>
    </svg>
);

export default SvgConnectedContainers;