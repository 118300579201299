import * as Yup from 'yup';
import { areValuesInArrayUnique } from 'Common/utils';
import { EMAIL_MAX_LENGTH, PHONE_NUMBER_MAX_LENGTH } from 'Common/consts';
import { GetUserLoginAppResult } from 'Types/app-types';
import { UserLoginIsDefaultForUser } from 'Common/enum';
import {
  GetUserLoginListResponseType,
  SaveUserLoginCustomersModelType,
} from "Types/user-types";

export type UserLoginListItemFormikValuesType = {
  userLoginID: number;
  email: string;
  phoneNumber: string;
  countryID: number;
  isDefaultForUser: UserLoginIsDefaultForUser;
  apps: GetUserLoginAppResult[];
  appsDupclicate?: boolean; //only for validation error
  customers: SaveUserLoginCustomersModelType[];
  customersDupclicate?: boolean; //only for validation error
};

export const validationSchema = () =>
  Yup.object<UserLoginListItemFormikValuesType>().shape(
    {
      phoneNumber: Yup.string()
        .label("Phone number")
        .max(PHONE_NUMBER_MAX_LENGTH)
        .nullable()
        .trim()
        .matches(/^\d+$/, { message: "Only digits are allowed" })
        .when("email", {
          is: (email) => !email || email.length === 0,
          then: Yup.string().required("Required email or phone").min(9),
        }),
      email: Yup.string()
        .label("Email")
        .email("Enter a valid email")
        .max(EMAIL_MAX_LENGTH)
        .nullable()
        .trim()
        .when("phoneNumber", {
          is: (phoneNumber) => !phoneNumber || phoneNumber.length <= 3,
          then: Yup.string().required("Required email or phone"),
        }),
      countryID: Yup.number()
        .label("Country")
        .when("phoneNumber", {
          is: (phoneNuber) => phoneNuber && phoneNuber.length > 0,
          then: Yup.number().required("Required"),
        }),
      customers: Yup.array()
        .test(
          "areCustomersUniqe",
          "Customers cannot be duplicated",
          (context: SaveUserLoginCustomersModelType[], value?: any) =>
            areValuesInArrayUnique(context.map((c) => c.customerID))
              ? true
              : new Yup.ValidationError(
                  "Customers cannot be duplicated",
                  "areCustomersUniqe",
                  "customersDupclicate"
                )
        )
        .of(
          Yup.object<SaveUserLoginCustomersModelType>().shape({
            roleID: Yup.number()
              .label("Role")
              .required()
              .moreThan(0, "Role is required"),
            customerID: Yup.number()
              .label("Customer")
              .required()
              .moreThan(0, "Customer is required"),
            pinCode: Yup.string()
              .label("PIN code")
              .nullable()
              .when("pinLength", {
                is: (pinLength) => pinLength && pinLength > 0,
                then: Yup.string().length(
                  Yup.ref("pinLength"),
                  ({ length }) => `PIN code must contain ${length} digits`
                ),
              }),
          })
        ),
      apps: Yup.array()
        .of(
          Yup.object<SaveUserLoginCustomersModelType>().shape({
            appGuid: Yup.string()
              .label("App")
              .required()
              .notOneOf(["0"], "Application is required"),
          })
        )
        .test(
          "areAppsUniqe",
          "Applications are duplicate",
          (context: GetUserLoginAppResult[], value?: any) =>
            areValuesInArrayUnique(context.map((c) => c.appGuid))
              ? true
              : new Yup.ValidationError(
                  "Applications are duplicate",
                  "areAppsUniqe",
                  "appsDupclicate"
                )
        ),
    },
    [["phoneNumber", "email"]]
  );

export const initialValues = (params: {
  userLogin: GetUserLoginListResponseType;
  email?: string;
  phoneNumber?: string;
  countryID?: number;
  appList: GetUserLoginAppResult[];
}): UserLoginListItemFormikValuesType => {
  const { userLogin, email, phoneNumber, countryID, appList } = params;
  return {
    userLoginID: userLogin.userLoginID ?? 0,
    email: email ?? "",
    phoneNumber: phoneNumber ?? "",
    countryID: countryID ?? 1,
    isDefaultForUser: userLogin.isDefaultForUser,
    apps: appList,
    customers:
      userLogin.customers?.map((c) => ({
        customerID: c.customerID,
        customerGuid: c.customerGuid,
        roleID: c.roleID,
        isExternalUserSync: c.isExternalUserSync,
        pinCode: c.pinCode,
        pinLength: c.pinLength,
        locationIDs: c.locations.map((l) => l.locationID),
      })) ?? [],
  };
};
