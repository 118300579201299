import * as React from 'react';
import StyledTable from 'Components/Table/styled-table';
import { DealerCustomersType } from 'Types/customer-types';
import { useTheme } from '@material-ui/core';

export interface ICustomerDealerAccountListProps {
  dealerAccountList: DealerCustomersType[];
}

const CustomerDealerAccountList: (
  props: ICustomerDealerAccountListProps
) => JSX.Element = (props: ICustomerDealerAccountListProps): JSX.Element => {
  const theme = useTheme();

  return (
    <StyledTable
      columns={[
        {
          field: "name",
          title: "Company name",
          headerStyle: {
            color: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.graySeparate.main}`,
            borderRadius: "5px",
          },
        },
        {
          field: "guid",
          title: "Guid",
          headerStyle: {
            color: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.graySeparate.main}`,
            borderRadius: "5px",
          },
        },
      ]}
      data={props.dealerAccountList}
      options={{
        showTitle: false,
        header: true,
        paging: false,
        search: false,
        toolbar: false,
        tableLayout: "auto",
      }}
      style={{
        boxShadow: "none",
        margin: "20px 20px 20px 0",
        background: "none",
        fontSize: "14px",
      }}
    />
  );
};

export default CustomerDealerAccountList;
