import * as React from "react";
import { IFileUploadProps } from "Components/File/interfaces-file";
import { stylesFileUpload } from "Components/File/FileUpload/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import { Snackbar } from "@material-ui/core";
import TypographyInfo from "Components/Typography/typography-info";
import TypographySubinfo from "Components/Typography/typography-subinfo";

const FileUpload: (props: IFileUploadProps) => JSX.Element = (
  props: IFileUploadProps
): JSX.Element => {
  const [isOpenErrorSnackbar, setIsOpenSnackbar] = React.useState(false);
  const customStyles = stylesFileUpload(props);
  let maxSize = props.maxSize ? props.maxSize : 4194304; //4MB

  const handleCloseErrorSnackbar = (): void => {
    setIsOpenSnackbar(false);
  };

  const onDrop = (files: Blob[]): void => {
    if (files.length > 0) {
      props.setFile(files[0]);
    }
  };

  const onDropRejected = (): void => {
    setIsOpenSnackbar(true);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: props.acceptedFormats,
    maxSize: maxSize,
    multiple: false,
    onDrop: onDrop,
    onDropRejected: onDropRejected,
  });

  const files = acceptedFiles.map((file) => (
    <TypographyInfo key={file.name} text={file.name} />
  ));

  return (
    <React.Fragment>
      <div className={customStyles.container} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={customStyles.centerItem}>
          {props.file ? (
            <div className={customStyles.fileInfo}>{files}</div>
          ) : (
            <div className={customStyles.fileInfo}>
              <FontAwesomeIcon icon={faFileUpload} size={"3x"} />
              <TypographyInfo text={props.text ? props.text : "Upload file"} />
              <TypographySubinfo
                text={`Max ${
                  maxSize > 0 ? Math.round(maxSize / 1048576) : 0
                } MB <br/> ${
                  !props.hideAcceptedFormats
                    ? props.acceptedFormats.join(", ") + " formats"
                    : ""
                }`}
              />
            </div>
          )}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={isOpenErrorSnackbar}
        onClose={handleCloseErrorSnackbar}
        autoHideDuration={6000}
        message="Unable to upload selected file!"
      />
    </React.Fragment>
  );
};

export default FileUpload;
