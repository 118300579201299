import {
  RouteApplications,
  RouteUsers,
  RouteSystemUsers,
  RouteSystemPermissions,
  RouteSystemAppsPermissions,
  RouteRoles,
  RouteSystemAccessRights,
  RouteSystemCustomers,
  RouteReportsDictionaries,
  RouteReportsReports,
  RouteReportsReportsContainers,
  RouteReportsAccessRights,
  RouteReportsAppsReports,
  RouteCustomerPermissions,
  RouteSystemPermissionList,
} from "../../Configuration/Routes";

export const menuContent = [
  {
    name: "Applications",
    path: RouteApplications,
    disabled: false,
    paperProps: {
      style: {
        width: "150px",
      },
    },
  },
  {
    name: "Users",
    path: RouteUsers,
    disabled: false,
    paperProps: {
      style: {
        width: "150px",
      },
    },
  },
  {
    name: "Reports",
    path: undefined,
    disabled: false,
    paperProps: {
      style: {
        width: "200px",
      },
    },
    subMenu: [
      {
        name: "User lists",
        path: RouteReportsDictionaries,
        disabled: false,
      },
      {
        name: "Reports",
        path: RouteReportsReports,
        disabled: false,
      },
      {
        name: "Reports containers",
        path: RouteReportsReportsContainers,
        disabled: false,
      },
      {
        name: "Apps reports",
        path: RouteReportsAppsReports,
        disabled: false,
      },
      {
        name: "Access Rights",
        path: RouteReportsAccessRights,
        disabled: false,
      },
    ],
  },
  {
    name: "System",
    path: undefined,
    disabled: false,
    paperProps: {
      style: {
        width: "190px",
      },
    },
    subMenu: [
      {
        name: "Users",
        path: RouteSystemUsers,
        disabled: false,
      },
      {
        name: "Permission groups",
        path: RouteSystemPermissions,
        disabled: false,
      },
      {
        name: "Apps Permissions",
        path: RouteSystemAppsPermissions,
        disabled: false,
      },
      {
        name: "Roles definition",
        path: RouteRoles,
        disabled: false,
      },
      {
        name: "Access Rights",
        path: RouteSystemAccessRights,
        disabled: false,
      },
      {
        name: "Customers",
        path: RouteSystemCustomers,
        disabled: false,
      },
      {
        name: "Customer roles",
        path: RouteCustomerPermissions,
        disabled: false,
      },
      {
        name: "Permission List",
        path: RouteSystemPermissionList,
        disabled: false,
      },
    ],
  },
];
