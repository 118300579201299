import * as React from 'react';

const radius = 1.8

const SvgThreeDots = ({
  style = {},
  fill = 'none',
  width = '100%',
  className = '',
  viewBox = '0 0 20 20',
  color = 'black',
}) => (
    <svg 
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="10" r={radius} fill={color}></circle>
        <circle cx="10" cy="10" r={radius} fill={color}></circle>
        <circle cx="16" cy="10" r={radius} fill={color}></circle>
    </svg>
);

export default SvgThreeDots;
