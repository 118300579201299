import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { AxiosError } from "axios";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { Formik } from "formik";
import * as React from "react";
import PermissionApi from "Services/Api/permission-api";
import * as Yup from "yup";
import { IPermissionNameEditorProps } from "../interfaces-permission-list";
import { stylesPermissionNameEditor } from "./styles";

const permissionApi = new PermissionApi();

type FormikDataType = {
  name: string;
};

export default function PermissionNameEditor(
  props: IPermissionNameEditorProps
) {
  const { setIsOpen, isOpen } = props;
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<
    AxiosError | null | string
  >(null);

  const NAME_MAX_LENGTH: number = 200;

  const customStyles = stylesPermissionNameEditor();

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setIsOpen(false);
  };

  const handleSave = (values: FormikDataType) => {
    props.setIsBlockedUi(true);
    permissionApi
      .savePermission({
        permissionName: values.name,
        permissionID: props.id,
      })
      .then((response) => {
        if (response?.data?.isSaved) {
          setIsOpen(false);
          props.getPermissionList();
        } else {
          setIsErrorDialogOpen(true);
          setErrorResponse("Error");
        }
      })
      .catch((error) => {
        setIsErrorDialogOpen(true);
        setErrorResponse(error);
      })
      .finally(() => {
        props.setIsBlockedUi(false);
      });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="xs"
        fullWidth
        onEscapeKeyDown={handleClose}
      >
        <Formik
          enableReinitialize={true}
          initialValues={
            {
              name: props.name,
            } as FormikDataType
          }
          onSubmit={async (values) => {
            await handleSave(values);
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .label("Name")
              .required("Required")
              .max(NAME_MAX_LENGTH)
              .trim(),
          })}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = formProps;

            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogTitle>
                  <Grid container justify="space-between">
                    <TypographySubtitle
                      text="Edit permission name"
                      margin="0px"
                    />
                  </Grid>
                </DialogTitle>

                <DialogContent
                  dividers
                  classes={{ root: customStyles.dialogContent }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldEntered
                        fullWidth
                        id="name"
                        name="name"
                        label="Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.name && touched.name)}
                        helperText={errors.name && touched.name && errors.name}
                        inputProps={{
                          maxLength: NAME_MAX_LENGTH,
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={customStyles.dialogActions}>
                  <ButtonSubaction text="Cancel" handleClick={handleClose} />
                  <ButtonAction isSubmit text="Save" />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={() => {
          setIsErrorDialogOpen(false);
          setErrorResponse(null);
        }}
      />
    </>
  );
}
