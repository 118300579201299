import * as React from "react";

const SvgImport = ({
  style = {},
  fill = "none",
  width = "17",
  height = "14",
  className = "",
  color = "#00000",
  viewBox = "0 0 17 14",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M13 10V11C13 12.1046 12.1046 13 11 13H3C1.89543 13 1 12.1046 1 11V3C1 1.89543 1.89543 1 3 1H11C12.1046 1 13 1.89543 13 3V4"
      stroke={color}
    />
    <path d="M10 4L7 7L10 10" stroke={color} />
    <path d="M7 7H17" stroke={color} />
  </svg>
);

export default SvgImport;
