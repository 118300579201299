import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { AxiosError } from "axios";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { Formik } from "formik";
import React from "react";
import AllRoles from "./all-roles";
import CustomerRoles from "./customer-roles";
import { unselectAllListElements } from "./utils";
import { RoleModel } from "./models";
import RoleApi from "Services/Api/role-api";
import { GetCustomerRolesResponseType } from "Types/customer-types";
import CustomerApi from "Services/Api/customer-api";
import AuthorizationHelper from "Configuration/authorization-helper";
import { ApiResultStatusEnum } from "Common/enum";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";

export interface RoleEditorProps {
  isOpen: boolean;
  close: () => void;

  clientID?: number;

  customerID: number;
  customerRoles: GetCustomerRolesResponseType[];
  refreshPage: () => void;
}

const roleApi = new RoleApi();
const customerApi = new CustomerApi();

const RoleEditor: React.FC<RoleEditorProps> = ({
  isOpen,
  close,
  customerRoles,
  clientID,
  customerID,
  refreshPage,
}) => {
  const handleSubmit = () => {
    const userID = AuthorizationHelper.getUser().userID;
    if (userID) {
      customerApi
        .saveCustomerRoles({
          customerID,
          currentUserID: userID,
          rolesIDs: customerRolesList.map((r) => r.data.roleID),
        })
        .then((response) => {
          if (response.status === ApiResultStatusEnum.Ok) {
            close();
            refreshPage();
          } else {
            setIsErrorDialogOpen(true);
          }
        })
        .catch((error) => {
          setIsErrorDialogOpen(true);
          setErrorResponse(error);
        });
    } else {
      setIsErrorDialogOpen(true);
    }
  };

  const [isErrorDialogOpen, setIsErrorDialogOpen] =
    React.useState<boolean>(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );

  const [allRoles, setAllRoles] = React.useState<RoleModel[]>([]);
  const [allRolesFiltered, setAllRolesFiltered] = React.useState<RoleModel[]>(
    []
  );

  const [customerRolesList, setCustomerRolesList] = React.useState<RoleModel[]>(
    []
  );
  const [customerRolesListFiltered, setCustomerRolesListFiltered] =
    React.useState<RoleModel[]>([]);

  React.useEffect(() => {
    setCustomerRolesList(
      customerRoles.map((c) => ({
        data: c,
        isSelected: false,
      }))
    );
    setCustomerRolesListFiltered(
      customerRoles.map((c) => ({
        data: c,
        isSelected: false,
      }))
    );
  }, [customerRoles, isOpen]);

  const getRoles = React.useCallback(async (): Promise<void> => {
    if (clientID) {
      await roleApi
        .getCustomerAvailableRoles(clientID, customerID)
        .then((result) => {
          if (result && result.data) {
            const roles = result.data
              .filter((r) => !customerRoles.find((c) => c.roleID === r.roleID))
              .map((r) => ({
                data: r,
                isSelected: false,
              }));
            setAllRoles(roles);
            setAllRolesFiltered(roles);
          }
        })
        .catch((error) => {
          setErrorResponse(error);
          setIsErrorDialogOpen(true);
        });
    }
  }, [clientID, customerRoles]);

  React.useEffect(() => {
    getRoles();
  }, [getRoles, isOpen]);

  const removeFromAllRoles = (rolesIDs: number[]) => {
    setAllRoles(
      unselectAllListElements(
        allRoles.filter((r) => !rolesIDs.find((id) => id === r.data.roleID))
      )
    );
    setAllRolesFiltered(
      unselectAllListElements(
        allRolesFiltered.filter(
          (r) => !rolesIDs.find((id) => id === r.data.roleID)
        )
      )
    );
  };

  const removeFromCustomerRoles = (rolesIDs: number[]) => {
    setCustomerRolesList(
      unselectAllListElements(
        customerRolesList.filter(
          (r) => !rolesIDs.find((id) => id === r.data.roleID)
        )
      )
    );
    setCustomerRolesListFiltered(
      unselectAllListElements(
        customerRolesListFiltered.filter(
          (r) => !rolesIDs.find((id) => id === r.data.roleID)
        )
      )
    );
  };

  const addToCustomerRoles = (roles: RoleModel[]) => {
    setCustomerRolesList(
      unselectAllListElements(customerRolesList.concat(roles))
    );
    setCustomerRolesListFiltered(
      unselectAllListElements(customerRolesListFiltered.concat(roles))
    );
  };

  const toggleSelectAllRoles = (roleID: number) => {
    setAllRoles(
      allRoles.map((r) =>
        r.data.roleID === roleID ? { ...r, isSelected: !r.isSelected } : r
      )
    );
    setAllRolesFiltered(
      allRolesFiltered.map((r) =>
        r.data.roleID === roleID ? { ...r, isSelected: !r.isSelected } : r
      )
    );
  };
  const toggleSelectCustomerRole = (roleID: number) => {
    setCustomerRolesList(
      customerRolesList.map((r) =>
        r.data.roleID === roleID ? { ...r, isSelected: !r.isSelected } : r
      )
    );
    setCustomerRolesListFiltered(
      customerRolesListFiltered.map((r) =>
        r.data.roleID === roleID ? { ...r, isSelected: !r.isSelected } : r
      )
    );
  };

  const addToAllRoles = (roles: RoleModel[]) => {
    setAllRoles(unselectAllListElements(allRoles.concat(roles)));
    setAllRolesFiltered(
      unselectAllListElements(allRolesFiltered.concat(roles))
    );
  };

  return (
    <>
      <Dialog open={isOpen} onEscapeKeyDown={close} fullWidth maxWidth={"md"}>
        <DialogTitle>
          <TypographySubtitle text={"Edit roles"} />
        </DialogTitle>

        <Formik
          initialValues={{}}
          onSubmit={async () => {
            await handleSubmit();
          }}
        >
          {(formProps) => {
            const { handleSubmit } = formProps;

            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <Grid container item sm={12} alignItems="center">
                    <AllRoles
                      list={allRoles}
                      filteredList={allRolesFiltered}
                      setFilterd={(roles: RoleModel[]) =>
                        setAllRolesFiltered(roles)
                      }
                      remove={removeFromAllRoles}
                      addToCustomerRoles={addToCustomerRoles}
                      toggleSelectRole={toggleSelectAllRoles}
                    />

                    <CustomerRoles
                      list={customerRolesList}
                      filteredList={customerRolesListFiltered}
                      setFilterd={(roles: RoleModel[]) =>
                        setCustomerRolesListFiltered(roles)
                      }
                      toggleSelectRole={toggleSelectCustomerRole}
                      addToAllRoles={addToAllRoles}
                      remove={removeFromCustomerRoles}
                    />
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ButtonSubaction text="Cancel" handleClick={close} />
                  <ButtonAction isSubmit text="Save" />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={() => setIsErrorDialogOpen(false)}
      />
    </>
  );
};

export default RoleEditor;
