import { makeStyles, Theme } from "@material-ui/core";

export const stylesDictionaryHeader = makeStyles((theme: Theme) => ({
  gridItem: {
    marginRight: "15px",
    marginBottom: "0px",
    color: theme.palette.grayDarker.main,
  },
  title: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "48px",
    color: theme.palette.grayDarker.main,
    borderBottom: "none",
  },
}));
