import * as React from 'react';
import { Container } from '@material-ui/core';
import { IPermissionsProps as ICustomerPermissionsProps } from 'Components/Permission/interfaces-permission';
import Header from '../../Components/CustomerPermissions/Header/header';
import TabsContainer from '../../Components/CustomerPermissions/tabs-container';
import {
  GetCustomerListResponseType,
  GetCustomerPermissionsDisabledResponseType,
  GetCustomerRolesResponseType,
} from 'Types/customer-types';
import CustomerApi from 'Services/Api/customer-api';
import {
  getSavedPageParamValue,
  savePageParam,
} from 'Configuration/settings-helper';
import { LandingPageEnum, ParamType } from 'Common/enum';

const customerApi = new CustomerApi();

export default function CustomerPermissions(props: ICustomerPermissionsProps) {
  const [customerList, setCustomerList] = React.useState<
    GetCustomerListResponseType[]
  >([]);
  const [
    selectedCustomer,
    setSelectedCustomer,
  ] = React.useState<GetCustomerListResponseType>();
  const [roleList, setRoleList] = React.useState<
    GetCustomerRolesResponseType[]
  >([]);
  const [disabledPermissions, setDisabledPermissions] = React.useState<
    GetCustomerPermissionsDisabledResponseType[]
  >([]);

  const getCustomerList = React.useCallback(async (): Promise<void> => {
    if (props.client.id) {
      await customerApi.getList(props.client.id).then((result) => {
        if (result?.data) {
          setCustomerList(result.data);
          if (result.data.length > 0) {
            let customer: GetCustomerListResponseType | undefined = undefined;
            const savedCustomer = getSavedPageParamValue(
              LandingPageEnum.CustomerPermissions,
              ParamType.SelectedCustomerID
            );
            if (savedCustomer && !Number.isNaN(Number(savedCustomer))) {
              customer = result.data.find(
                (c) => (c.customerID == Number(savedCustomer))
              );
            }
            if (!customer) {
              customer = result.data[0];
            }

            setSelectedCustomer(customer);
            getRolesForCustomer(customer);
            getDiabledPermissionsForCustomer(customer);
          }
        }
      });
    }
  }, [props.client.id]);

  const getRolesForCustomer = (customer: GetCustomerListResponseType) => {
    customerApi.getCustomerRoles(customer.customerID).then((result) => {
      if (result?.data) {
        setRoleList(result.data);
      }
    });
  };

  const getDiabledPermissionsForCustomer = (
    customer: GetCustomerListResponseType
  ) => {
    customerApi
      .getCustomerPermissionsDisabled(customer.customerID)
      .then((result) => {
        if (result?.data) {
          setDisabledPermissions(result.data);
        }
      });
  };

  React.useEffect(() => {
    getCustomerList();
  }, [getCustomerList]);

  const handleChangeCustomer = (customer: GetCustomerListResponseType) => {
    setSelectedCustomer(customer);
    getRolesForCustomer(customer);
    getDiabledPermissionsForCustomer(customer);

    savePageParam({
      page: LandingPageEnum.CustomerPermissions,
      paramType: ParamType.SelectedCustomerID,
      value: customer.customerID.toString(),
    });
  };

  const refreshPage = () => {
    if (selectedCustomer) {
      getRolesForCustomer(selectedCustomer);
      getDiabledPermissionsForCustomer(selectedCustomer);
    }
  };

  return (
    <Container>
      <Header
        customerList={customerList}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={handleChangeCustomer}
      />
      {selectedCustomer && (
        <TabsContainer
          selectedCustomer={selectedCustomer}
          roleList={roleList}
          disabledPermissions={disabledPermissions}
          clientID={props.client.id ?? undefined}
          refreshPage={refreshPage}
        />
      )}
    </Container>
  );
}
