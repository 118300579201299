import * as React from 'react';
import {
  GetReportDataTypeFormatListApiResponseType,
  GetReportColumnListType,
} from '../../../../Types/report-types';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';
import Colors from '../../../../Common/colors';
import {
  ReportColumnSortType,
  ReportColumnDataType,
  ReportColumnFormatType,
  ReportColumnAlignment,
  ReportColumnAggregateType,
  ReportParameterSourceType,
} from '../../../../Common/enum';
import { stylesEditReportColumnsListItem } from './styles';
import CheckboxVisible from './checkbox-visible';
import CheckboxWrap from './checkbox-wrap';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import CheckboxEditable from './checkbox-editable';
import CheckboxMaster from './checkbox-master';
import CheckboxHidable from './checkbox-hidable';

export interface EditReportColumnsListItemProps {
  data: GetReportColumnListType;
  list: GetReportColumnListType[];

  dataTypeFormatList: GetReportDataTypeFormatListApiResponseType[];
  isMasterDetailsParameters?: boolean;
}

const EditReportColumnsListItem: React.FC<EditReportColumnsListItemProps> = ({
  data,
  list,
  dataTypeFormatList,
  isMasterDetailsParameters
}) => {
  const customStyles = stylesEditReportColumnsListItem();
  const formatListForDataType =
    dataTypeFormatList?.filter(
      (format) => Number(format.dataType) === Number(data.dataType)
    ) ?? [];
  const styleRow: string = customStyles.row;
  return (
    <tr className={styleRow}>
      <td style={{ padding: 0 }} align="center">
        <IconButton disabled={true} style={{ padding: '12px 0' }}>
          <MenuIcon htmlColor={Colors.graySeparate} />
        </IconButton>
      </td>
      <td>
        {data.sortType === ReportColumnSortType.SortDefaultByAsc ? (
          <span style={{ fontWeight: 'bold' }}>
            Default
            <ArrowDownward
              style={{ width: '15px', marginLeft: '5px', marginBottom: '-7px' }}
            />
          </span>
        ) : data.sortType === ReportColumnSortType.SortDefaultByDesc ? (
          <span style={{ fontWeight: 'bold' }}>
            Default
            <ArrowUpward
              style={{ width: '15px', marginLeft: '5px', marginBottom: '-7px' }}
            />
          </span>
        ) : (
          <React.Fragment>{ReportColumnSortType[data.sortType]}</React.Fragment>
        )}
      </td>
      <td className={customStyles.disable}>{data.databaseName}</td>
      <td>{data.displayName}</td>
      <td className={`${customStyles.disable}`}>
        <div>{ReportColumnDataType[data.dataType]}</div>
      </td>
      <td>
        <div>{sourceName(data)}</div>
      </td>
      <td
        className={
          formatListForDataType.length === 0 ? customStyles.disable : ''
        }
      >
        {formatListForDataType.length > 0 ? (
          <div>
            {data.formatType === ReportColumnFormatType.DataTypeFormat
              ? data.dataTypeFormatText
              : data.formatType === ReportColumnFormatType.Custom
              ? data.formatValueText
              : ''}
          </div>
        ) : (
          ''
        )}
      </td>
      <td>{ReportColumnAlignment[data.alignment]}</td>
      <td style={{ textAlign: 'center' }}>
        <CheckboxVisible
          isEditable={false}
          data={data}
          list={list}
          handleChangeColumns={() => {}}
        />
      </td>
      <td style={{ textAlign: 'center' }}>
        <CheckboxEditable data={data} list={list} />
      </td>
      <td style={{ textAlign: 'center' }}>
        <CheckboxHidable
          isEditable={false}
          data={data}
          list={list}
          handleChangeColumns={() => {}}
        />
      </td>
      <td>{data.size === 0 ? 'auto' : data.size}</td>
      <td>{ReportColumnAggregateType[data.aggreagateType]}</td>
      <td style={{ textAlign: 'center' }}>
        <CheckboxWrap
          isEditable={false}
          data={data}
          list={list}
          handleChangeColumns={() => {}}
        />
      </td>
      {isMasterDetailsParameters === true &&
        <td style={{ textAlign: 'center' }}>
          <CheckboxMaster
            isEditable={false}
            data={data}
            list={list}
            handleChangeColumns={() => {}}
          />
        </td>
      }
    </tr>
  );
};

export default EditReportColumnsListItem;

export const sourceName = (data: GetReportColumnListType) => (
  <React.Fragment>
    {data.sourceType === ReportParameterSourceType.Procedure &&
    data.sourceProcedure != null &&
    data.sourceProcedure.length > 0
      ? `!${data.sourceProcedure}`
      : data.sourceType === ReportParameterSourceType.Dictionary &&
        data.reportDictionarySourceName != null &&
        data.reportDictionarySourceName.length > 0
      ? `@${data.reportDictionarySourceName}`
      : ''}

    {data.sourceType === ReportParameterSourceType.Dictionary &&
    data.reportDictionarySourceName
      ? data.isSourceMultiselect
        ? ':M'
        : ':S'
      : ''}
  </React.Fragment>
);
