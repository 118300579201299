const Colors = {
  black: '#000000',
  gray: '#999999',
  grayLight: '#F8F8F8',
  graySeparate: '#DCDCDC',
  grayDark: '#444444',
  grayDarker: '#6F6F6F',
  grayInactive: '#AEAEAE',
  green: '#7FBA00',
  greenLight: '#E3ECE3',
  red: '#F44336',
  white: '#FFFFFF',
};

export default Colors;
