import * as React from "react";

const SvgUpload = ({
    style = {},
    fill = "none",
    width = "100%",
    className = "",
    viewBox = "0 0 14 14"
  }) => (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M1 9V11C1 12.1046 1.89543 13 3 13H11C12.1046 13 13 12.1046 13 11V9" stroke="#444444"/>
        <path d="M7 10V1" stroke="#444444"/>
        <path d="M4 4L7 1L10 4" stroke="#444444"/>
    </svg>
  );

  export default SvgUpload;