import * as React from "react";

const SvgBounds = ({
    style = {},
    fill = "none",
    width = "100%",
    className = "",
    viewBox = "0 0 16 14"
  }) => (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M3.5 12H6.5" stroke="black"/>
        <path d="M9.5 12H12.5" stroke="black"/>
        <path d="M8 10.5V8" stroke="black"/>
        <circle cx="2" cy="12" r="1.5" stroke="black"/>
        <circle cx="8" cy="12" r="1.5" stroke="black"/>
        <circle cx="14" cy="12" r="1.5" stroke="black"/>
        <rect x="2.5" y="0.5" width="11" height="7" rx="1.5" stroke="black"/>
    </svg>
  );

  export default SvgBounds;