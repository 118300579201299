import * as React from 'react';
import AccessRightsView from 'Views/AccessRights/access-rights';
import Applications from 'Views/Applications/applications';
import ApplicationUserDetails from 'Views/Users/application-user-details';
import AppsPermissions from 'Views/Permissions/apps-permissions';
import ClientApi from 'Services/Api/client-api';
import ClientPopover from 'Components/Client/ClientPopover/client-popover';
import Colors from 'Common/colors';
import CustomerLocations from 'Views/CustomerLocations/customerLocations';
import CustomerPermissions from 'Views/CustomerPermissions/customer-permissions';
import Customers from 'Views/Customers/customers';
import ErrorBoundary from 'Components/ErrorBoundary/error-boundary';
import MainMenu from 'Components/Menu/main-menu';
import Maintenance from 'Views/Maintenance/maintence';
import PermissionListView from 'Views/PermissionList/permission-list-view';
import Permissions from 'Views/Permissions/permissions';
import ReportsAccessRights from 'Views/Reports/AccessRights/access-rights';
import ReportsAppsReports from 'Views/Reports/AppsReports/apps-reports';
import ReportsDictionaries from 'Views/Reports/Dictionaries/dictionaries';
import ReportsReports from 'Views/Reports/Reports/reports';
import ReportsReportsContainers from 'Views/Reports/ReportsContainers/reports-containers';
import Roles from 'Views/Role/roles';
import SimpleRouteConfig from 'Common/routes';
import SvgLogo from 'Components/Images/image-logo';
import Users from 'Views/Users/users';
import { ApiResultStatusEnum, LandingPageEnum } from 'Common/enum';
import { AppBar, Toolbar } from '@material-ui/core';
import { AppRoute } from 'Configuration/app-route';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ClientSaveApiResultType } from 'Types/api-types';
import { ClientType } from 'Types/client-types';
import { head } from 'lodash';
import './main.scss';
import {
  RouteApplications,
  RouteUsers,
  RouteSystemUsers,
  RouteSystemPermissions,
  RouteSystemAppsPermissions,
  RouteRoles,
  RouteSystemAccessRights,
  RouteSystemCustomers,
  RouteReportsDictionaries,
  RouteReportsReports,
  RouteReportsReportsContainers,
  RouteReportsAppsReports,
  RouteReportsAccessRights,
  RouteCustomerPermissions,
  RouteUserEdit,
  RouteMaintenance,
  RouteSystemPermissionList,
  RouteSystemCustomerLocations,
  RouteSystemCustomerLocationsParam,
} from "Configuration/Routes";

let clientApi: ClientApi = new ClientApi();

interface IMainProps {}

interface IMainState {
  clientList: ClientType[];
  clientSelected: ClientType;
}

class Main extends React.Component<IMainProps, IMainState> {
  state: IMainState = {
    clientList: [],
    clientSelected: {
      id: 0,
      name: "-",
      guid: "-",
      wsCustomerUrl: "",
      wsCustomerSecurityKeyName: "",
      wsCustomerSecurityKeyValue: "",
    },
  };

  componentDidMount = async () => {
    await clientApi.getList().then((result) => {
      let firstClient: ClientType = {
        id: 0,
        name: "No customer",
        guid: "-",
        wsCustomerUrl: "",
        wsCustomerSecurityKeyName: "",
        wsCustomerSecurityKeyValue: "",
      };
      let clientList: ClientType[] = [];
      if (result && result.data) {
        clientList = result.data;
        if (result.data.length > 0) {
          firstClient = head(result.data) as ClientType;
          let clientIdLocalStorage: string | null =
            localStorage.getItem("clientId");
          if (clientIdLocalStorage) {
            let client: ClientType | undefined = result.data.find(
              (x) => x.id?.toString() === clientIdLocalStorage
            );
            if (client) {
              firstClient = client;
            }
          }
          if (firstClient.id) {
            localStorage.setItem("clientId", firstClient.id.toString());
          }
        }
      }
      this.setState({
        clientList: clientList,
        clientSelected: firstClient,
      });
    });
  };

  handleClientChange = async (clientGuid: string) => {
    let clientList = [...this.state.clientList];
    let foundedClient: ClientType | undefined = clientList.find(
      (c) => c.guid === clientGuid
    );
    if (foundedClient && foundedClient.id) {
      localStorage.setItem("clientId", foundedClient.id?.toString());
    }
    this.setState({
      clientSelected: foundedClient
        ? foundedClient
        : {
            id: 0,
            name: "No customer",
            guid: "-",
            wsCustomerUrl: "",
            wsCustomerSecurityKeyName: "",
            wsCustomerSecurityKeyValue: "",
          },
    });
  };

  handleSaveClient = async (
    client: ClientType
  ): Promise<ClientSaveApiResultType | void> => {
    let clientList = [...this.state.clientList];
    client.id = client.id === 0 ? null : client.id;
    return await clientApi.save(client).then((result) => {
      if (result.status === ApiResultStatusEnum.Ok) {
        if (client.id) {
          let editedClientIndex = clientList.findIndex(
            (x) => x.id === client.id
          );
          if (editedClientIndex >= 0) {
            clientList[editedClientIndex] = client;
            this.setState({
              clientList: clientList,
              clientSelected: client,
            });
          }
        } else {
          client.id = result.data.id;
          clientList.push(client);
          this.setState({
            clientList: clientList,
          });
        }
      }
    });
  };

  getAppBarBackgroundColor = (): string => {
    if (process.env.REACT_APP_SERVER === "test") {
      return Colors.greenLight;
    } else {
      return Colors.white;
    }
  };

  render() {
    return (
      <div className="main-div">
        <div className="main-header">
          <Router>
            <AppBar
              position="relative"
              style={{
                backgroundColor: this.getAppBarBackgroundColor(),
                zIndex: 900,
              }}
            >
              <Toolbar className="main-toolbar">
                <SvgLogo height="24px" width="150px" />
                <MainMenu client={this.state.clientSelected} />

                <ClientPopover
                  client={this.state.clientSelected}
                  clientList={this.state.clientList}
                  handleClientChange={this.handleClientChange}
                  handleSaveClient={this.handleSaveClient}
                />
              </Toolbar>
            </AppBar>
            <ErrorBoundary>
              <Switch>
                <AppRoute
                  path={RouteApplications}
                  landingPage={LandingPageEnum.Applications}
                  Component={() => (
                    <Applications client={this.state.clientSelected} />
                  )}
                />
                <AppRoute
                  path={RouteUsers}
                  landingPage={LandingPageEnum.Users}
                  Component={() => (
                    <Users
                      client={this.state.clientSelected}
                      systemAppSelected={false}
                    />
                  )}
                />
                <AppRoute
                  path={RouteSystemUsers}
                  landingPage={LandingPageEnum.SystemUsers}
                  Component={() => (
                    <Users
                      client={this.state.clientSelected}
                      systemAppSelected={true}
                    />
                  )}
                />
                <AppRoute
                  path={RouteSystemPermissions}
                  landingPage={LandingPageEnum.SystemPermissions}
                  Component={() => (
                    <Permissions client={this.state.clientSelected} />
                  )}
                />
                <AppRoute
                  path={RouteSystemAppsPermissions}
                  landingPage={LandingPageEnum.SystemAppsPermissions}
                  Component={() => (
                    <AppsPermissions client={this.state.clientSelected} />
                  )}
                />
                <AppRoute
                  path={RouteUserEdit}
                  landingPage={LandingPageEnum.Users}
                  Component={(props) => (
                    <ApplicationUserDetails
                      client={this.state.clientSelected}
                      {...props}
                    />
                  )}
                />
                <AppRoute
                  path={RouteRoles}
                  landingPage={LandingPageEnum.Roles}
                  Component={() => <Roles client={this.state.clientSelected} />}
                />
                <AppRoute
                  path={RouteSystemAccessRights}
                  landingPage={LandingPageEnum.AccessRights}
                  Component={() => (
                    <AccessRightsView client={this.state.clientSelected} />
                  )}
                />
                <AppRoute
                  path={RouteSystemCustomers}
                  landingPage={LandingPageEnum.SystemCustomers}
                  Component={() => (
                    <Customers client={this.state.clientSelected} />
                  )}
                />
                <AppRoute
                  path={RouteSystemCustomerLocationsParam}
                  landingPage={LandingPageEnum.CustomerLocationList}
                  Component={() => (
                    <CustomerLocations client={this.state.clientSelected} />
                  )}
                />
                <AppRoute
                  path={RouteReportsDictionaries}
                  landingPage={LandingPageEnum.ReportsDictionaries}
                  Component={() => (
                    <ReportsDictionaries client={this.state.clientSelected} />
                  )}
                />
                <AppRoute
                  path={RouteReportsReports}
                  landingPage={LandingPageEnum.ReportsReports}
                  Component={() => (
                    <ReportsReports client={this.state.clientSelected} />
                  )}
                />
                <AppRoute
                  path={RouteReportsReportsContainers}
                  landingPage={LandingPageEnum.ReportsReportsContainers}
                  Component={() => (
                    <ReportsReportsContainers
                      client={this.state.clientSelected}
                    />
                  )}
                />
                <AppRoute
                  path={RouteReportsAppsReports}
                  landingPage={LandingPageEnum.ReportsAppsReports}
                  Component={() => (
                    <ReportsAppsReports client={this.state.clientSelected} />
                  )}
                />
                <AppRoute
                  path={RouteReportsAccessRights}
                  landingPage={LandingPageEnum.AccessRights}
                  Component={() => (
                    <ReportsAccessRights client={this.state.clientSelected} />
                  )}
                />
                <AppRoute
                  path={RouteCustomerPermissions}
                  landingPage={LandingPageEnum.CustomerPermissions}
                  Component={() => (
                    <CustomerPermissions client={this.state.clientSelected} />
                  )}
                />
                <AppRoute
                  path={RouteMaintenance}
                  landingPage={LandingPageEnum.Maintenance}
                  Component={() => (
                    <Maintenance clientID={this.state.clientSelected.id} />
                  )}
                />
                <AppRoute
                  path={RouteSystemPermissionList}
                  landingPage={LandingPageEnum.PermissionList}
                  Component={() => (
                    <PermissionListView client={this.state.clientSelected} />
                  )}
                />
                <SimpleRouteConfig client={this.state.clientSelected} />
              </Switch>
            </ErrorBoundary>
          </Router>
        </div>
      </div>
    );
  }
}

export default Main;
