import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@material-ui/core";
import ButtonSolid from "../../../../Buttons/button-solid";
import { Fragment } from "react";
import { GetReportColumnListType } from "Types/report-types";
import { stylesSelectColumnNameDialog } from "./styles";
import { ReportParameterDisplayNameType } from "Common/enum";
import SelectSourceType from "./select-column-name-type";

export interface SelectColumnNameDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  data: GetReportColumnListType;
  clickOk: (data: GetReportColumnListType) => void;
}

const SelectColumnNameDialog: React.FC<SelectColumnNameDialogProps> = ({
  isOpen,
  handleClose,
  data,
  clickOk,
}) => {
  const customStyles = stylesSelectColumnNameDialog();
  const [displayNameType, setDisplayNameType] = React.useState(
    data.displayNameType
  );
  const [displayName, setDisplayName] = React.useState(data.displayName);
  const [
    displayNameStoredProcedureRowIndex,
    setDisplayNameStoredProcedureRowIndex,
  ] = React.useState(data.displayNameStoredProcedureRowIndex);

  const handleChangeSelectedType = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setDisplayNameType(Number(value));
  };

  const handleClickOk = () => {
    clickOk({
      ...data,
      displayNameType: displayNameType,
      displayName: displayName,
      displayNameStoredProcedureRowIndex: displayNameStoredProcedureRowIndex,
    });
  };

  return (
    <Fragment>
      <Dialog
        open={isOpen}
        maxWidth="sm"
        fullWidth
        onClose={() => handleClose()}
        onEscapeKeyDown={() => handleClose()}
      >
        <DialogTitle></DialogTitle>
        <DialogContent
          classes={{ root: customStyles.content }}
          style={{ overflow: "hidden" }}
        >
          <Grid
            container
            direction="column"
            style={{ height: "100%" }}
            wrap="nowrap"
          >
            <SelectSourceType
              displayNameType={displayNameType}
              handleChangeSelectedType={handleChangeSelectedType}
            />

            <Grid
              item
              container
              style={{ flex: 1, paddingTop: "8px", overflow: "auto" }}
            >
              {displayNameType === ReportParameterDisplayNameType.SimpleText ? (
                <TextField
                  name="displayName"
                  variant="standard"
                  label="Display name"
                  InputProps={{
                    style: { fontSize: "14px" },
                  }}
                  value={displayName}
                  onChange={(e) => {
                    setDisplayName(e.target?.value);
                  }}
                  fullWidth
                />
              ) : displayNameType ===
                ReportParameterDisplayNameType.Procedure ? (
                <TextField
                  name="displayNameStoredProcedureRowIndex"
                  variant="standard"
                  label="Row index of procedure response"
                  InputProps={{
                    style: { fontSize: "14px" },
                  }}
                  type="number"
                  value={displayNameStoredProcedureRowIndex}
                  onChange={(e) => {
                    setDisplayNameStoredProcedureRowIndex(
                      e.target?.value as unknown as number
                    );
                  }}
                  fullWidth
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonSolid
            text="Select"
            onClickEvent={handleClickOk}
            margin="0 0 0 10px"
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default SelectColumnNameDialog;
