import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import {
  DeleteUserModelType,
  DeleteUserApplicationConnModelType,
} from "Types/user-types";
import { ApiResultStatusEnum, IconType } from "Common/enum";
import { SaveUserApiResultType } from "Types/api-types";
import { IUserDeleteProps } from "Components/User/interfaces-user";
import { AxiosError } from "axios";
import { Formik } from "formik";
import ButtonIcon from "Components/Buttons/button-icon";
import SvgDelete from "Components/Images/image-delete";
import UserApi from "Services/Api/user-api";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";

export default function UserDelete(props: IUserDeleteProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );

  const userApi = new UserApi();

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const handleOpen = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setIsOpen(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setIsOpen(false);
  };

  const handleSubmit = (userID: DeleteUserModelType) => {
    if (props.systemAppSelected) {
      deleteUser(userID)
        .then(() => {})
        .catch((error) => {
          console.error(error);
          setErrorResponse(error);
          setIsErrorDialogOpen(true);
        });
    } else {
      deleteUserApplication({ userID: userID.userID, appGuid: props.appGuid })
        .then(() => {})
        .catch((error) => {
          console.error(error);
          setErrorResponse(error);
          setIsErrorDialogOpen(true);
        });
    }
  };

  const deleteUser = (
    userID: DeleteUserModelType
  ): Promise<SaveUserApiResultType | void> => {
    return userApi.deleteUser(userID).then((result) => {
      if (result && result.status === ApiResultStatusEnum.Ok) {
        if (props.getUsers) {
          props.getUsers();
        }
        setIsOpen(false);
      }
    });
  };

  const deleteUserApplication = (
    userID: DeleteUserApplicationConnModelType
  ): Promise<SaveUserApiResultType | void> => {
    return userApi.deleteUserApplicationConn(userID).then((result) => {
      if (result && result.status === ApiResultStatusEnum.Ok) {
        if (props.getUsers) {
          props.getUsers();
        }
        setIsOpen(false);
      }
    });
  };

  return (
    <React.Fragment>
      <ButtonIcon
        iconType={IconType.SvgImageIcon}
        icon={<SvgDelete />}
        handleClick={handleOpen}
      />
      <Dialog open={isOpen}>
        <DialogTitle>
          <TypographySubtitle
            text={
              props.systemAppSelected ? "Delete user" : "Delete user connection"
            }
            margin="0px"
          />
        </DialogTitle>

        <Formik
          initialValues={{
            userID: props.userID,
          }}
          onSubmit={async (values) => {
            await handleSubmit(values);
          }}
        >
          {(formProps) => {
            const { handleSubmit } = formProps;
            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent dividers>
                  {props.systemAppSelected ? (
                    <Typography>
                      Are you sure you want to delete the user?
                    </Typography>
                  ) : (
                    <Typography>
                      Are you sure you want to delete the user connections to
                      this application?
                    </Typography>
                  )}
                </DialogContent>
                <DialogActions>
                  <ButtonSubaction text="Cancel" handleClick={handleClose} />
                  <ButtonAction isSubmit text="Delete" />
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </React.Fragment>
  );
}
