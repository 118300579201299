import * as React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@material-ui/core";
import { ReportParameterDisplayNameType } from "../../../../../Common/enum";

export interface DisplayNameTypeProps {
  displayNameType: ReportParameterDisplayNameType;
  handleChangeSelectedType: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
}
const DisplayNameType: React.FC<DisplayNameTypeProps> = ({
  displayNameType,
  handleChangeSelectedType,
}) => {
  return (
    <Grid item>
      <FormControl component="fieldset">
        <RadioGroup
          row
          name="type"
          value={displayNameType}
          onChange={handleChangeSelectedType}
        >
          <FormControlLabel
            value={ReportParameterDisplayNameType.SimpleText}
            control={<Radio color="primary" />}
            label="Static name"
          />
          <FormControlLabel
            value={ReportParameterDisplayNameType.Procedure}
            control={<Radio color="primary" />}
            label="Dynamic name"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};
export default DisplayNameType;
