import * as React from "react";
import SvgTrash from "Components/Images/image-trash";
import { FunctionComponent } from "react";
import ActionButton from "./action-button";

interface ActionButtonDeleteProps {
  checkedSelectedLength: number;
  deleteFromSelected: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
}
const ActionButtonDelete: FunctionComponent<ActionButtonDeleteProps> = ({
  checkedSelectedLength,
  deleteFromSelected,
}) => {
  return (
    <ActionButton
      count={checkedSelectedLength}
      handleClick={deleteFromSelected}
      alignItems="center"
      text="Delete"
      padding="3px 13px"
      iconStart={SvgTrash}
    />
  );
};
export default ActionButtonDelete;
