import { Grid } from "@material-ui/core";
import SearchField from "Components/Inputs/SearchField/search-field";
import * as React from "react";

interface MoveListHeaderProps {
  onSearchChange: (value: React.SetStateAction<string>) => void;
  actionButton: JSX.Element;
}
export const MoveListHeader: React.FunctionComponent<MoveListHeaderProps> = ({
  onSearchChange,
  actionButton,
}) => {
  return (
    <Grid container item sm={12} alignItems="center" spacing={2}>
      <Grid container item sm={8} alignItems="center">
        <SearchField
          onChangeEvent={onSearchChange}
          preventEnterKey
          marginTop="0px"
        />
      </Grid>
      {actionButton}
    </Grid>
  );
};
export default MoveListHeader;
