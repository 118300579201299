import * as React from 'react';
import MaterialTable from 'material-table';
import UserListItem from 'Components/User/UserList/user-list-item';
import { IUserListProps } from 'Components/User/interfaces-user';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { GetUserListResponseType } from 'Types/user-types';

export default function UserList(props: IUserListProps) {
  const {
    filteredUserList,
    appGuid,
    //appID,
    systemAppSelected,
    getUsers,
  } = props;
  const RENDERED_ITEMS_COUNT = 5;
  const [
    renderedActualItemCount,
    setRenderedActualItemCount,
  ] = React.useState<number>(0);
  const [renderedUserList, setRenderedUserList] = React.useState<
    GetUserListResponseType[]
  >([]);

  const loadMore = () => {
    const list: GetUserListResponseType[] = filteredUserList.slice(
      0,
      renderedActualItemCount + RENDERED_ITEMS_COUNT
    );

    setRenderedActualItemCount(renderedActualItemCount + RENDERED_ITEMS_COUNT);
    setRenderedUserList(list);
  };

  React.useEffect(() => {
    setRenderedActualItemCount(0);
    loadMore();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredUserList]);

  return (
    <InfiniteScroll
      dataLength={renderedUserList.length}
      next={loadMore}
      hasMore={renderedActualItemCount < filteredUserList.length}
      loader={
        <Grid container justify="center">
          <Box marginTop={6}>
            <CircularProgress color="primary" />
          </Box>
        </Grid>
      }
      style={{ overflow: 'hidden' }}
    >
      <MaterialTable
        columns={[]}
        data={renderedUserList}
        options={{
          showTitle: false,
          header: false,
          paging: false,
          search: false,
          toolbar: false,
        }}
        components={{
          Row: (propsRow) => (
            <UserListItem
              item={propsRow.data}
              systemAppSelected={systemAppSelected}
              appGuid={appGuid}
              getUsers={getUsers}
            />
          ),
        }}
        style={{
          background: 'transparent',
          boxShadow: 'none',
          minHeight: '30vh',
        }}
      />
    </InfiniteScroll>
  );
}
