import * as React from 'react';
import { Container } from '@material-ui/core';
import DeviceHeader from 'Components/Device/DeviceHeader/device-header';
import { AppPlatformEnum } from 'Common/enum';
import { MobileAppWithDevicesType } from 'Types/app-types';
import DeviceListContainer from 'Components/Device/DeviceList/device-list-container';
import { IDevicesProps } from 'Components/Device/interfaces-device';
import ClientAppApi from 'Services/Api/client-app-api';
import { useHistory } from 'react-router-dom';
import { RouteApplications } from 'Configuration/Routes';

const clientAppApi = new ClientAppApi();

export default function Devices(props: IDevicesProps) {
  const [
    appDevices,
    setAppDevices,
  ] = React.useState<MobileAppWithDevicesType>();

  const history = useHistory();

  React.useEffect(() => {
    const getAppDevices = async (): Promise<void> => {
      if (
        props.match.params.mobileAppId &&
        props.client.id &&
        props.client.id > 0
      ) {
        await clientAppApi
          .getMobileAppDevices(props.match.params.mobileAppId)
          .then((result) => {
            if (result && result.data) {
              if (result.data.iaClientID === props.client.id) {
                setAppDevices(result.data);
              } else {
                history.push(RouteApplications);
              }
            } else {
              history.push(RouteApplications);
            }
          });
      }
    };
    getAppDevices();
  }, [props.match.params.mobileAppId, props.client.id, history]);

  return (
    <Container>
      <DeviceHeader
        text='Devices'
        mobileAppName={appDevices ? appDevices.name : '-'}
        platformType={
          appDevices ? appDevices.platformType : AppPlatformEnum.Android
        }
        logoFile={appDevices ? appDevices.logoFile : undefined}
      />
      <DeviceListContainer
        deviceList={appDevices ? appDevices.deviceList : []}
      />
    </Container>
  );
}
