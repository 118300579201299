import { Grid, Paper } from '@material-ui/core';
import TypographyInfo from 'Components/Typography/typography-info';
import React from 'react';
import Colors from 'Common/colors';
import { IconType } from 'Common/enum';
import ButtonOutlined from 'Components/Buttons/button-outlined';
import SvgPencil from 'Components/Images/image-edit-pencil';
import { nameStyle, stylesDisabledPermissions } from './styles';
import DisabledPermissionsEditor from './Editor/disabled-permissions-editor';
import { GetCustomerPermissionsDisabledResponseType } from 'Types/customer-types';

export interface TabDisabledPermissionsProps {
  disabledPermissions: GetCustomerPermissionsDisabledResponseType[];
  customerID: number;
  refreshPage: () => void;
}

const TabDisabledPermissions: React.FC<TabDisabledPermissionsProps> = ({
  disabledPermissions,
  customerID,
  refreshPage,
}) => {
  const customStyles = stylesDisabledPermissions();

  const [isOpenEditor, setIsOpenEditor] = React.useState<boolean>(false);
  const handleClickEdit = () => {
    setIsOpenEditor(true);
  };
  const handleCloseEditor = () => {
    setIsOpenEditor(false);
  };
  const emptyList = (
    <Grid
      container
      item
      alignContent="center"
      alignItems="center"
      justify="center"
    >
      <TypographyInfo
        text={'Company has no assigned disabled permissions'}
        color={Colors.black}
        style={{
          fontSize: '14px',
        }}
      />
    </Grid>
  );
  return (
    <>
      <Grid container direction="column">
        <Grid container item justify="flex-end">
          <ButtonOutlined
            text={'Edit'}
            iconStartType={IconType.SvgImageIcon}
            iconStart={<SvgPencil width={'14px'} />}
            size="md"
            margin="0 0 16px 0"
            handleClick={() => handleClickEdit()}
            backgroundColor={Colors.white}
          />
        </Grid>
        <Paper className={customStyles.paper}>
          {disabledPermissions.length > 0 ? (
            <Grid container spacing={1}>
              {disabledPermissions.map((p) => (
                <Grid item xs={4}>
                  <TypographyInfo text={p.name} style={nameStyle} />
                </Grid>
              ))}
            </Grid>
          ) : (
            emptyList
          )}
        </Paper>
      </Grid>
      <DisabledPermissionsEditor
        isOpen={isOpenEditor}
        close={handleCloseEditor}
        disabledPermissions={disabledPermissions}
        customerID={customerID}
        refreshPage={refreshPage}
      />
    </>
  );
};

export default TabDisabledPermissions;
