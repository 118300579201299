import * as React from "react";

const SvgPencil = ({
    style = {},
    fill = "none",
    width = "100%",
    className = "",
    viewBox = "0 0 16 17"
  }) => (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M2.5625 11.4604L12.3799 1.58702L13.7941 3.00123L3.97589 12.8754L2.5625 12.8754L2.5625 11.4604Z" stroke="black"/>
        <rect x="1.00195" y="15.0026" width="14" height="1" fill="black"/>
    </svg>
  );

  export default SvgPencil;