import { RoleShareMode } from "Common/enum";
import _ from "lodash";

export const areValuesInArrayUnique = (
  array: (number | string)[] | null | undefined
): boolean => {
  return (
    !array || !array.map || array.length === new Set(array.map((a) => a)).size
  );
};

export const getRandomDigitsString = (length: number): string | undefined => {
  return [...Array(length)]
    .map(() => Math.floor(Math.random() * 9))
    .map((x) => x.toString())
    .join("");
};

export const convertShareModeToSymbol = (mode: RoleShareMode) => {
  switch (mode) {
    case RoleShareMode.Single:
      return "S";
    case RoleShareMode.Multiple:
      return "M";
  }
};

export const customSortList = (list1: string[], list2: string[]): number =>
  _.orderBy(
    list1.map((item1) => item1.toLocaleLowerCase()),
    (item) => item,
    ["asc"]
  )
    .join(", ")
    .localeCompare(
      _.orderBy(
        list2.map((item2) => item2.toLocaleLowerCase()),
        (item) => item,
        ["asc"]
      ).join(", ")
    );
