import { makeStyles } from "@material-ui/core";

export const stylesAddReport = makeStyles({
  selectedList: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#444444",
    borderBottom: "none",
  },
});
