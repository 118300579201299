import { makeStyles } from '@material-ui/core';
import Colors from 'Common/colors';

export const stylesCustomerPermissions = makeStyles({
  gridItem: {
    padding: '0 15px',
  },
  paper: {
    padding: '5px 30px',
    borderRadius: '70px',
    marginTop: '30px',
  },
  subinfo: {
    fontSize: '14px',
    lineHeight: '21px',
    color: '#959595',
  },
  tabs: {
    marginTop: '20px',
  },
  tab: {
    paddingTop: '15px',
    borderBottom: `2px solid ${Colors.graySeparate}`,
    textTransform: 'none',
    marginRight: '12px',
  },
});
