import { axiosInstance } from "Services/Api/base-api";
import {
  GetRolesApiResultType,
  SaveRoleApiResultType,
  DeleteRoleApiResultType,
  GetRolesForAppApiResultType,
  GetPermissionGroupsForRoleApiResultType,
  SaveRolePermissionsApiResultType,
  CopyRoleApiResultType,
  GetCustomerAvailableRolesApiResultType,
  GetPermissionListApiResultType,
} from "Types/api-types";
import {
  SaveRoleModelType,
  DeleteRoleModelType,
  SaveRolePermissionsType,
  CopyRoleModelType,
} from "Types/role-types";

export default class RoleApi {
  getRoles = async (
    clientID?: number,
    showDisabled?: boolean
  ): Promise<GetRolesApiResultType> => {
    return await axiosInstance
      .get(`/Role/GetRoles`, {
        params: {
          clientID,
          showDisabled,
        },
      })
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getRolesForApp = async (
    appID: number
  ): Promise<GetRolesForAppApiResultType> => {
    return await axiosInstance
      .get("/Role/GetRolesForApp?appID=" + appID)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  getCustomerAvailableRoles = async (
    clientID: number,
    customerID: number
  ): Promise<GetCustomerAvailableRolesApiResultType> => {
    return await axiosInstance
      .get(`/Role/GetCustomerAvailableRoles`, {
        params: {
          clientID,
          customerID,
        },
      })
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  saveRole = async (
    role: SaveRoleModelType
  ): Promise<SaveRoleApiResultType> => {
    return await axiosInstance
      .post("/Role/SaveRole", JSON.stringify(role))
      .then((response) => response.data);
  };

  deleteRole = async (
    role: DeleteRoleModelType
  ): Promise<DeleteRoleApiResultType> => {
    return await axiosInstance
      .post("/Role/DeleteRole", JSON.stringify(role))
      .then((response) => response.data);
  };

  copyRole = async (
    role: CopyRoleModelType
  ): Promise<CopyRoleApiResultType> => {
    return await axiosInstance
      .post("/Role/CopyRole", JSON.stringify(role))
      .then((response) => response.data);
  };

  getPermissionGroupsForRole = async (
    roleId: string
  ): Promise<GetPermissionGroupsForRoleApiResultType> => {
    return await axiosInstance
      .get("/Role/GetPermissionGroupsForRole?roleID=" + roleId)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  saveRolePermissions = async (
    rolePermissions: SaveRolePermissionsType
  ): Promise<SaveRolePermissionsApiResultType> => {
    return await axiosInstance
      .post("/Role/SaveRolePermissions", JSON.stringify(rolePermissions))
      .then((response) => response.data);
  };

  getPermissionList = async (
    clientID?: number
  ): Promise<GetPermissionListApiResultType> => {
    return await axiosInstance
      .get(`/Role/GetPermisions`, {
        params: {
          clientID,
        },
      })
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };
}
