import * as React from "react";

const SvgIos = ({
    style = {},
    fill = "none",
    width = "100%",
    className = "",
    color = "#DCDCDC",
    colorFill = "white",
    viewBox = "0 0 34 34"
  }) => (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <rect width="34" height="34" rx="5" fill={color} />
        <path d="M9.84346 13.3523C8.15265 16.2864 9.22753 20.739 11.0973 23.4388C12.0306 24.7886 12.9755 26.0002 14.2697 26.0002C14.2941 26.0002 14.3186 25.9997 14.3436 25.9987C14.9517 25.9745 15.3911 25.7873 15.816 25.6063C16.2929 25.4031 16.7863 25.1929 17.56 25.1929C18.2961 25.1929 18.7659 25.3963 19.2202 25.5928C19.6679 25.7865 20.1291 25.9869 20.8106 25.9742C22.2684 25.947 23.1635 24.6396 23.9532 23.4862C24.7772 22.282 25.1906 21.1127 25.3301 20.6659L25.3357 20.6481C25.3623 20.5687 25.3248 20.482 25.2486 20.4471C25.2465 20.4461 25.2388 20.4429 25.2366 20.442C24.9799 20.337 22.728 19.338 22.7043 16.6151C22.6824 14.4029 24.3937 13.2315 24.7369 13.0191L24.7529 13.0092C24.7901 12.9852 24.816 12.9473 24.8248 12.9039C24.8336 12.8605 24.8244 12.8154 24.7995 12.7789C23.6182 11.0503 21.8077 10.7898 21.0788 10.7584C20.9731 10.7479 20.8639 10.7426 20.7542 10.7426C19.8981 10.7426 19.078 11.0659 18.4191 11.3257C17.9643 11.505 17.5714 11.6599 17.3005 11.6599C16.996 11.6599 16.6009 11.5031 16.1434 11.3216C15.5316 11.0788 14.8382 10.8037 14.1041 10.8037C14.0866 10.8037 14.0693 10.8039 14.0524 10.8042C12.3455 10.8293 10.7326 11.8058 9.84346 13.3523Z" fill={colorFill}/>
        <path d="M21.0803 6.00013C20.0466 6.04222 18.8067 6.67848 18.0656 7.54654C17.4358 8.27618 16.8205 9.4927 16.9826 10.7189C16.9928 10.7956 17.0552 10.8549 17.1324 10.8608C17.2021 10.8663 17.2733 10.869 17.3443 10.8691C18.355 10.8691 19.4453 10.3099 20.1899 9.40962C20.9735 8.45893 21.3698 7.23887 21.2499 6.14603C21.2405 6.06039 21.1651 5.99678 21.0803 6.00013Z" fill={colorFill}/>
    </svg>
  );

  export default SvgIos;