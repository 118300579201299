import * as React from "react";
import ButtonAction from "Components/Buttons/ButtonAction/button-action";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import MoveListEditorLeftSide from "./move-list-editor-left-side";
import MoveListEditorRightSide from "./move-list-editor-right-side";
import TypographySubtitle from "Components/Typography/typography-subtitle";
import { FC } from "react";
import { Formik } from "formik";
import { MoveListEditorItemType } from "./types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";

interface IMoveListEditorProps {
  dialogTitle: string;
  allOptions: MoveListEditorItemType[];
  selectedOptions: MoveListEditorItemType[];

  isOpen: boolean;
  close: () => void;

  save: (ids: number[]) => void;
}
const MoveListEditor: FC<IMoveListEditorProps> = ({
  allOptions,
  selectedOptions,
  isOpen,
  close,
  dialogTitle,
  save,
}) => {
  const [allItems, setAllItems] = React.useState<MoveListEditorItemType[]>();
  const [filteredAll, setFilteredAll] =
    React.useState<MoveListEditorItemType[]>();
  const [selected, setSelected] = React.useState<MoveListEditorItemType[]>();
  const [filteredSelected, setFilteredSelected] =
    React.useState<MoveListEditorItemType[]>();
  const [countAll, setCountAll] = React.useState(0);
  const [countSelected, setCountSelected] = React.useState(0);
  const [checkedAll, setCheckedAll] = React.useState<number[]>([]);
  const [checkedSelected, setCheckedSelected] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (isOpen) {
      const all = allOptions.filter(
        (a) => !selectedOptions.some((s) => s.id === a.id)
      );
      refresh(all, selectedOptions, all, selectedOptions);
    }
  }, [isOpen, allOptions, selectedOptions]);

  const searchByText = (
    list: MoveListEditorItemType[],
    text?: string
  ): MoveListEditorItemType[] => {
    if (text) {
      list = list.filter((x) =>
        x.label.toLowerCase().includes(text.toLowerCase())
      );
    }
    return list;
  };

  const onSearchChangeAll = (value: React.SetStateAction<string>): void => {
    let searchedText: string = value.toString().toLowerCase();
    searchedText = searchedText ? searchedText : "";
    setFilteredAll(searchByText(allItems ?? [], searchedText));
  };

  const handleToggleAll = (value: number) => {
    const currentIndex: number = checkedAll.indexOf(value);
    const newChecked: number[] = [...checkedAll];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedAll(newChecked);
  };

  const onSearchChangeSelected = (
    value: React.SetStateAction<string>
  ): void => {
    let searchedText: string = value.toString().toLowerCase();
    searchedText = searchedText ? searchedText : "";
    setFilteredSelected(searchByText(selected ?? [], searchedText));
  };

  const handleToggleSelected = (value: number) => {
    const currentIndex = checkedSelected.indexOf(value);
    const newChecked = [...checkedSelected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedSelected(newChecked);
  };

  const moveToSelected = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (filteredAll && filteredSelected && allItems && selected) {
      // add to selected list and remove from all list
      const all: MoveListEditorItemType[] = allItems.filter(
        (item) => checkedAll.indexOf(item.id) === -1
      );
      const move: MoveListEditorItemType[] = allItems.filter(
        (item) => checkedAll.indexOf(item.id) > -1
      );
      const selectedItem: MoveListEditorItemType[] = selected.concat(move);
      const allItemsFiltered: MoveListEditorItemType[] = filteredAll.filter(
        (item) => checkedAll.indexOf(item.id) === -1
      );
      const selectedItemsFiltered: MoveListEditorItemType[] =
        filteredSelected.concat(move);

      refresh(all, selectedItem, allItemsFiltered, selectedItemsFiltered);
    }
  };

  const deleteFromSelected = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (filteredAll && filteredSelected && allItems && selected) {
      // add to all list and remove from selected list
      const selectedItem: MoveListEditorItemType[] = selected.filter(
        (item) => checkedSelected.indexOf(item.id) === -1
      );
      const deleteItems: MoveListEditorItemType[] = selected.filter(
        (item) => checkedSelected.indexOf(item.id) > -1
      );
      const allItem: MoveListEditorItemType[] = allItems.concat(deleteItems);
      const selectedItemFiltered: MoveListEditorItemType[] =
        filteredSelected.filter(
          (item) => checkedSelected.indexOf(item.id) === -1
        );
      const allItemFiltered: MoveListEditorItemType[] =
        filteredAll.concat(deleteItems);

      refresh(allItem, selectedItem, allItemFiltered, selectedItemFiltered);
    }
  };

  const refresh = (
    allItem: MoveListEditorItemType[],
    selectedItem: MoveListEditorItemType[],
    allItemFiltered: MoveListEditorItemType[],
    selectedItemFiltered: MoveListEditorItemType[]
  ): void => {
    setAllItems(allItem);
    setFilteredAll(allItemFiltered);
    setCountAll(allItemFiltered.length);
    setSelected(selectedItem);
    setFilteredSelected(selectedItemFiltered);
    setCountSelected(selectedItemFiltered.length);
    setCheckedAll([]);
    setCheckedSelected([]);
  };

  const isAllSelectedOnLeftSide =
    !!filteredAll?.length &&
    filteredAll.every((f) => checkedAll.some((c) => c === f.id));

  const isAllSelectedOnRightSide =
    !!filteredSelected?.length &&
    filteredSelected.every((f) => checkedSelected.some((c) => c === f.id));

  const selectAllOnLeftSide = () => {
    if (isAllSelectedOnLeftSide) {
      setCheckedAll((prev) =>
        prev.filter((p) => !filteredAll?.some((f) => f.id === p))
      );
    } else {
      setCheckedAll((prev) =>
        Array.from(new Set([...prev, ...(filteredAll?.map((f) => f.id) ?? [])]))
      );
    }
  };

  const selectAllOnRightSide = () => {
    if (isAllSelectedOnRightSide) {
      setCheckedSelected((prev) =>
        prev.filter((p) => !filteredSelected?.some((f) => f.id === p))
      );
    } else {
      setCheckedSelected((prev) =>
        Array.from(
          new Set([...prev, ...(filteredSelected?.map((f) => f.id) ?? [])])
        )
      );
    }
  };

  return (
    <Dialog
      open={isOpen}
      onEscapeKeyDown={close}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <TypographySubtitle text={dialogTitle} margin="0px" />
      </DialogTitle>

      <Formik
        initialValues={{}}
        onSubmit={async (values) => {
          save(selected?.map((s) => s.id) ?? []);
        }}
      >
        {(formProps) => {
          const { handleSubmit } = formProps;

          return (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Grid container item sm={12} alignItems="flex-start">
                  <MoveListEditorLeftSide
                    countAll={countAll}
                    onSearchChangeAll={onSearchChangeAll}
                    checkedAll={checkedAll}
                    moveToSelected={moveToSelected}
                    filteredAll={filteredAll}
                    handleToggleAll={handleToggleAll}
                    selectAll={selectAllOnLeftSide}
                    isAllSelected={isAllSelectedOnLeftSide}
                  />
                  <MoveListEditorRightSide
                    countSelected={countSelected}
                    onSearchChangeSelected={onSearchChangeSelected}
                    checkedSelected={checkedSelected}
                    deleteFromSelected={deleteFromSelected}
                    filteredSelected={filteredSelected}
                    handleToggleSelected={handleToggleSelected}
                    selectAll={selectAllOnRightSide}
                    isAllSelected={isAllSelectedOnRightSide}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <ButtonSubaction text="Cancel" handleClick={close} />
                <ButtonAction isSubmit text="Save" />
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default MoveListEditor;
