import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@material-ui/core';
import { IconType } from 'Common/enum';
import ButtonIcon from 'Components/Buttons/button-icon';
import ButtonAction from 'Components/Buttons/ButtonAction/button-action';
import ButtonSubaction from 'Components/Buttons/ButtonSubaction/button-subaction';
import SvgPencil from 'Components/Images/image-edit-pencil';
import SvgPlusCircle from 'Components/Images/image-plus-circle';
import TextFieldEntered from 'Components/Inputs/TextFieldEntered/text-field-entered';
import TypographySubtitle from 'Components/Typography/typography-subtitle';
import { Form, Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import AppEditorComponentGuid from '../AppEditorComponents/app-editor-component-guid';
import AppEditorComponentName from '../AppEditorComponents/app-editor-component-name';
import { validationShape } from '../formik-validation-values';
import { IAppEditor, IAppEditorProps } from '../interfaces';
import { prepareDataToSave } from './mappers';
import PowerBiEndpoint from './powerbi-endpoint';
import ReportsEndpoint from './reports-endpoint';
import { stylesAppEditorWebService } from './styles';
import { useClientAppApi } from './useClientAppApi';
import UsersEndpoint from './users-endpoint';

const AppEditorWebService: (props: IAppEditorProps) => JSX.Element = (
  props: IAppEditorProps
): JSX.Element => {
  const customStyles = stylesAppEditorWebService();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const { handleLoad, handleSave, handleCheckWS, initValues } =
    useClientAppApi(props);

  const handleOpen: () => void = (): void => {
    handleLoad();
    setIsOpen(true);
  };

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  return (
    <>
      <ButtonIcon
        iconType={IconType.SvgImageIcon}
        icon={props.id ? <SvgPencil /> : <SvgPlusCircle />}
        handleClick={handleOpen}
        height="22px"
        width="22px"
      />
      <Dialog open={isOpen} maxWidth="md">
        <DialogTitle>
          <TypographySubtitle text={props.titleText} />
        </DialogTitle>
        <Formik
          initialValues={initValues}
          enableReinitialize={true}
          validationSchema={Yup.object().shape(validationShape)}
          onSubmit={(values: IAppEditor) =>
            handleSave(prepareDataToSave(values), handleClose)
          }
        >
          {({ values, handleBlur, handleChange, errors, touched }) => (
            <Form>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    alignItems="flex-start"
                  >
                    <Grid item xs={4}>
                      <AppEditorComponentName
                        value={values.name ?? ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        error={!!(errors.name && touched.name)}
                        platformType={values.appPlatformType}
                        showAdornment={true}
                      />
                    </Grid>
                    {props.id && (
                      <Grid item xs={12}>
                        <AppEditorComponentGuid
                          value={values.guid ?? ''}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          error={!!errors.guid}
                          errorText={errors.guid}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <TextFieldEntered
                        name="documentationURL"
                        label="Swagger link"
                        size="small"
                        value={values.documentationURL}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.documentationURL}
                        helperText={errors.documentationURL}
                        inputProps={{
                          maxLength: 200,
                        }}
                        fullWidth
                      />
                    </Grid>
                    <UsersEndpoint handleCheckWS={handleCheckWS} />

                    <Divider className={customStyles.divider} />
                    <ReportsEndpoint handleCheckWS={handleCheckWS} />

                    <Divider className={customStyles.divider} />
                    <PowerBiEndpoint handleCheckWS={handleCheckWS} />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <ButtonSubaction text="Cancel" handleClick={handleClose} />
                <ButtonAction isSubmit text="Save" />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AppEditorWebService;
