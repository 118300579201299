import * as React from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from '@material-ui/core';
import { ReportParameterSourceType } from '../../../../../Common/enum';

export interface SelectSourceTypeProps {
  sourceType: ReportParameterSourceType;
  handleChangeSelectedType: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
}
const SelectSourceType: React.FC<SelectSourceTypeProps> = ({
  sourceType,
  handleChangeSelectedType,
}) => {
  return (
    <Grid item>
      <FormControl component='fieldset'>
        <RadioGroup
          row
          name='type'
          value={sourceType}
          onChange={handleChangeSelectedType}
        >
          <FormControlLabel
            value={ReportParameterSourceType.Dictionary}
            control={<Radio color='primary' />}
            label='User list'
          />
          <FormControlLabel
            value={ReportParameterSourceType.Procedure}
            control={<Radio color='primary' />}
            label='Stored procedures'
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};
export default SelectSourceType;
