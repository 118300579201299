import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { IAppEditorComponentProps } from "./interfaces";
import { AppLoginTypeEnum } from "Common/enum-app";

const AppEditorComponentLogin: (
  props: IAppEditorComponentProps
) => JSX.Element = (props: IAppEditorComponentProps): JSX.Element => {
  return (
    <FormControl fullWidth size="small" onChange={props.handleChange}>
      <InputLabel id="app-login-type-select-id">Required login type</InputLabel>
      <Select
        name="appLoginType"
        fullWidth
        labelId="app-login-type-select-id"
        value={props.value}
        onChange={props.handleChange}
      >
        <MenuItem value={AppLoginTypeEnum.Email}>Email</MenuItem>
        <MenuItem value={AppLoginTypeEnum.Phone}>Phone</MenuItem>
        <MenuItem value={AppLoginTypeEnum.EmailAndPhone}>All</MenuItem>
      </Select>
    </FormControl>
  );
};

export default AppEditorComponentLogin;
