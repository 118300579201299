import axios, { AxiosRequestConfig, Method } from 'axios';
import { saveAs } from 'file-saver';
import { saveJsErrorToLog } from "../../Configuration/save-error-to-log";
import AuthorizationHelper from "../../Configuration/authorization-helper";
import UserApi from "./user-api";

//console.log(process.env)
let envBaseURL: string = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : "https://intelliadmin-webapi-core-test.azurewebsites.net/api/";
let envApiVersion: string = process.env.REACT_APP_API_VERSION ? process.env.REACT_APP_API_VERSION : "v1";

export const axiosInstance = axios.create({
    baseURL:  envBaseURL + envApiVersion,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "securityKey": process.env.REACT_APP_API_SECRET_KEY
    }
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (
      error.response.status !== 401 ||
      error.config.url === envBaseURL + envApiVersion + "/User/RefreshToken"
    ) {
      saveJsErrorToLog(
        "ADMIN " + error.message + " " + error.config.url,
        "",
        "",
        "",
        error
      );
      return Promise.reject(error);
    }

    let token = AuthorizationHelper.getToken();
    if (token !== null && token) {
      axiosInstance.defaults.headers["Authorization"] = "Bearer " + token.refresh;

      const userApi: UserApi = new UserApi();

      return userApi.refreshToken(token.refresh)
        .then(response => {
          if (response !== null && response.data !== null) {
            AuthorizationHelper.setToken({
              auth: response.data.tokenAuth,
              refresh: response.data.tokenRefresh
            });
            error.response.config.headers["Authorization"] = "Bearer " + response.data.tokenAuth;

            var url = error.response.config.url.replace(
              envBaseURL + envApiVersion,
              ""
            );
            error.response.config.url = url;

            return axios(error.response.config);
          }
          else {
            userApi.logout({ tokenAuth: token!.auth, tokenRefresh: token!.refresh });
            removeFromStorage();
            redirectToLoginPage();
            return Promise.reject(error);
          }
        })
        .catch(error => {
          userApi.logout({ tokenAuth: token!.auth, tokenRefresh: token!.refresh });
          removeFromStorage();
          redirectToLoginPage();
          return Promise.reject(error);
        });
    }
    else {
      removeFromStorage();
      redirectToLoginPage();
      return Promise.reject(error);
    }
  }
);

axiosInstance.interceptors.request.use(
  config => {
    const tokens = AuthorizationHelper.getToken();

    if (
      tokens &&
      tokens.auth &&
      axiosInstance.defaults.headers["Authorization"] !== `Bearer ${tokens.refresh}`
    ) {
      config.headers["Authorization"] = `Bearer ${tokens.auth}`;
    }

    return config;
  },
  error => {
    saveJsErrorToLog(
      "ADMIN " + error.message + " " + error.config.url,
      "",
      "",
      "",
      error
    );
    return Promise.reject(error);
  }
);


export function apiDownloadRequest<T>(url: string, method: Method, data: any) {
  const tokens = AuthorizationHelper.getToken();

  let instance = axios.create({
    baseURL:  envBaseURL + envApiVersion,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "securityKey": process.env.REACT_APP_API_SECRET_KEY,
      "Authorization": tokens ? `Bearer ${tokens.auth}` : ""
    }
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if (
        error.response.status !== 401 ||
        error.config.url === envBaseURL + envApiVersion + "/User/RefreshToken"
      ) {
        saveJsErrorToLog(
          "ADMIN " + error.message + " " + error.config.url,
          "",
          "",
          "",
          error
        );
        return Promise.reject(error);
      }
  
      let token = AuthorizationHelper.getToken();
      if (token !== null && token) {
        axiosInstance.defaults.headers["Authorization"] = "Bearer " + token.refresh;
  
        const userApi: UserApi = new UserApi();
  
        return userApi.refreshToken(token.refresh)
          .then(response => {
            if (response !== null && response.data !== null) {
              AuthorizationHelper.setToken({
                auth: response.data.tokenAuth,
                refresh: response.data.tokenRefresh
              });
              error.response.config.headers["Authorization"] = "Bearer " + response.data.tokenAuth;
  
              var url = error.response.config.url.replace(
                envBaseURL + envApiVersion,
                ""
              );
              error.response.config.url = url;
  
              return axios(error.response.config);
            }
            else {
              userApi.logout({ tokenAuth: token!.auth, tokenRefresh: token!.refresh });
              removeFromStorage();
              redirectToLoginPage();
              return Promise.reject(error);
            }
          })
          .catch(error => {
            userApi.logout({ tokenAuth: token!.auth, tokenRefresh: token!.refresh });
            removeFromStorage();
            redirectToLoginPage();
            return Promise.reject(error);
          });
      }
      else {
        removeFromStorage();
        redirectToLoginPage();
        return Promise.reject(error);
      }
    }
  );

  instance.interceptors.request.use(
    config => {
      const tokens = AuthorizationHelper.getToken();
  
      if (
        tokens &&
        tokens.auth &&
        axiosInstance.defaults.headers["Authorization"] !== `Bearer ${tokens.refresh}`
      ) {
        config.headers["Authorization"] = `Bearer ${tokens.auth}`;
      }
  
      return config;
    },
    error => {
      saveJsErrorToLog(
        "ADMIN " + error.message + " " + error.config.url,
        "",
        "",
        "",
        error
      );
      return Promise.reject(error);
    }
  );

  let options: AxiosRequestConfig = { 
    url,
    data,
    method,
    responseType: 'blob' // don't forget this
  };
  
  return instance.request<any>(options)
    .then(response => {
      let filename = response.headers['content-disposition']
        .split(';')
        .find((n: any) => n.includes('filename='))
        .replace('filename=', '')
        .trim();
      let url = window.URL.createObjectURL(new Blob([response.data]));
      saveAs(url, filename);    
    });
}

function redirectToLoginPage() {
  // eslint-disable-next-line no-restricted-globals
  history.pushState(history.state, "logout", "/");
  // eslint-disable-next-line no-restricted-globals
  history.go();
}

function removeFromStorage() {
  localStorage.removeItem(AuthorizationHelper.keyUser);
  localStorage.removeItem(AuthorizationHelper.keyTokens);
  //ConfigurationHelper.removeAll();
}