import * as React from "react";
import EditReportGeneralParameters from "./edit-report-general-parameters";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import TypographyInfo from "Components/Typography/typography-info";
import { ChangeEvent } from "react";
import { EditReportGeneralOther } from "./edit-report-general-other";
import { GetPermissionsApiResponseType } from "Types/permission-types";
import { Grid, useTheme } from "@material-ui/core";
import { ReportTypeEnum } from "Common/enum";
import { stylesEditReportGeneral } from "./styles";
import {
  GetReportContainerListApiResponseType,
  GetReportDetailsApiResponseType,
} from "Types/report-types";

export interface EditReportGeneralProps {
  isEditable: boolean;
  data: GetReportDetailsApiResponseType;
  changeReportData: (data: GetReportDetailsApiResponseType) => void;
  permissionList: GetPermissionsApiResponseType[];
  reportContainerList: GetReportContainerListApiResponseType[];
}

const EditReportGeneral: React.FC<EditReportGeneralProps> = ({
  data,
  isEditable,
  permissionList,
  reportContainerList,
  changeReportData,
}) => {
  const theme = useTheme();

  const { reportName, reportGuid, reportDescription, reportType } = data;
  const customStyles = stylesEditReportGeneral();

  const handleChangeReportName = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    changeReportData({ ...data, reportName: value });
  };

  const handleChangeReportGuid = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    changeReportData({ ...data, reportGuid: value });
  };

  const handleChangeReportDescription = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    changeReportData({ ...data, reportDescription: value });
  };

  return (
    <form noValidate autoComplete="off">
      <Grid
        container
        direction="column"
        className={customStyles.container}
        spacing={3}
      >
        <Grid
          item
          className={customStyles.reportType}
          container
          wrap="nowrap"
          alignContent="center"
          alignItems="baseline"
        >
          <TypographyInfo
            text="Type:"
            fontSize="12px"
            margin="0 5px 0 0"
            color={theme.palette.grayDarker.main}
          />
          <TypographyInfo
            text={ReportTypeEnum[reportType]}
            fontSize="16px"
            color={theme.palette.grayDarker.main}
            margin="0"
          />
        </Grid>

        <Grid container justify="space-between" item sm={6}>
          <Grid item style={{ width: "49%" }}>
            <TextFieldEntered
              disabled={!isEditable}
              id="report-name"
              label="Report name"
              value={reportName}
              onChange={handleChangeReportName}
              error={reportName == null || reportName.length === 0}
              helperText={
                reportName == null || reportName.length === 0
                  ? "Report name is required"
                  : ""
              }
              multiline
              inputProps={{ maxLength: 200 }}
            />
          </Grid>
          <Grid item style={{ width: "49%" }}>
            <TextFieldEntered
              disabled={!isEditable}
              id="report-guid"
              label="Guid"
              value={reportGuid}
              onChange={handleChangeReportGuid}
            />
          </Grid>
        </Grid>

        <Grid item sm={6}>
          <TextFieldEntered
            disabled={!isEditable}
            id="report-description"
            label="Description (optional)"
            value={reportDescription}
            onChange={handleChangeReportDescription}
            multiline
          />
        </Grid>

        {reportType !== ReportTypeEnum.PowerBI && (
          <EditReportGeneralParameters
            isEditable={isEditable}
            changeReportData={changeReportData}
            data={data}
          />
        )}
        <EditReportGeneralOther
          isEditable={isEditable}
          reportContainerList={reportContainerList}
          permissionList={permissionList}
          changeReportData={changeReportData}
          data={data}
        />
      </Grid>
    </form>
  );
};

export default EditReportGeneral;
