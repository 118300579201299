import * as React from "react";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import TypographyInfo from "Components/Typography/typography-info";
import { ChangeEvent, FunctionComponent } from "react";
import { Checkbox, FormControlLabel, Grid, useTheme } from "@material-ui/core";
import { GetReportDetailsApiResponseType } from "Types/report-types";
import { stylesEditReportGeneral } from "./styles";
import {
  IsEnabled,
  ReportIsColorizeRows,
  ReportIsSetBeforeExecute,
  ReportSubtypeEnum,
} from "Common/enum";

interface EditReportGeneralParametersProps {
  isEditable: boolean;
  data: GetReportDetailsApiResponseType;
  changeReportData: (data: GetReportDetailsApiResponseType) => void;
}

const EditReportGeneralParameters: FunctionComponent<
  EditReportGeneralParametersProps
> = ({ isEditable, data, changeReportData }) => {
  const {
    maxRowsToReturn,
    isEnabled,
    isColorizeRows,
    isSetBeforeExecute,
    noOfParameterColumns,
    isRefreshable,
    searchForSummary
  } = data;
  const theme = useTheme();
  const customStyles = stylesEditReportGeneral();
  const maxNoOfParametersColumns: number = 10;

  const handleChangeNoOfColumns = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    if (value != null) {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        let valueInt = Number(value);
        if (valueInt > maxNoOfParametersColumns) {
          valueInt = maxNoOfParametersColumns;
        } else if (valueInt < 1) {
          valueInt = 1;
        }
        changeReportData({ ...data, noOfParameterColumns: valueInt });
      }
    }
  };

  const handleChangeMaxRowsToReturn = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = parseInt(event.target.value);
    changeReportData({ ...data, maxRowsToReturn: value });
  };

  const handleChangeIsActive = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    changeReportData({
      ...data,
      isEnabled: checked ? IsEnabled.Enabled : IsEnabled.NotEnabled,
    });
  };

  const handleChangeSetBeforeExecute = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    changeReportData({
      ...data,
      isSetBeforeExecute: checked
        ? ReportIsSetBeforeExecute.SetBeforeExecute
        : ReportIsSetBeforeExecute.NoSetBeforeExecute,
    });
  };

  const handleChangeColorizeRows = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    changeReportData({
      ...data,
      isColorizeRows: checked
        ? ReportIsColorizeRows.ColorizeRows
        : ReportIsColorizeRows.NoColorizeRows,
    });
  };

  const handleChangeIsRefreshable = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    changeReportData({
      ...data,
      isRefreshable: checked,
    });
  };

  const handleChangeSearchForSummary = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    changeReportData({
      ...data,
      searchForSummary: checked,
    });
  };

  return (
    <Grid item container direction="column">
      <TypographyInfo
        text="Parameters"
        fontSize="16px"
        color={theme.palette.grayDarker.main}
        margin="0 0 12px 0"
      />
      <Grid container justify="space-between" item xs={3}>
        <Grid item style={{ width: "48%" }}>
          <TextFieldEntered
            disabled={!isEditable}
            id="no-of-columns"
            label="Columns"
            value={noOfParameterColumns}
            type="number"
            inputProps={{ min: 1, max: maxNoOfParametersColumns }}
            InputLabelProps={{
              classes: { root: customStyles.labelNoOfColumns },
            }}
            onChange={handleChangeNoOfColumns}
            style={{ textAlign: "left" }}
          />
        </Grid>
        <Grid item style={{ width: "48%" }}>
          <TextFieldEntered
            disabled={!isEditable}
            id="max-rows-to-return"
            label="Rows"
            value={maxRowsToReturn}
            type="number"
            inputProps={{ min: 0, max: 100000 }}
            onChange={handleChangeMaxRowsToReturn}
            style={{ textAlign: "left" }}
          />
        </Grid>
      </Grid>
      <Grid item container style={{ marginTop: "12px", marginLeft: "-10px" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isEnabled === IsEnabled.Enabled}
              onChange={handleChangeIsActive}
              color="primary"
              name="isActive"
            />
          }
          style={{ marginLeft: "0", width: "100px" }}
          label="Active"
          disabled={!isEditable}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={
                isSetBeforeExecute === ReportIsSetBeforeExecute.SetBeforeExecute
              }
              onChange={handleChangeSetBeforeExecute}
              color="primary"
              name="setBeforeExecute"
            />
          }
          style={{ marginLeft: "0", width: "200px" }}
          label="Set before execute"
          disabled={!isEditable}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={isColorizeRows === ReportIsColorizeRows.ColorizeRows}
              onChange={handleChangeColorizeRows}
              color="primary"
              name="colorizeRows"
            />
          }
          style={{ marginLeft: "0", width: "160px" }}
          label="Colorize rows"
          disabled={!isEditable}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={isRefreshable}
              onChange={handleChangeIsRefreshable}
              color="primary"
              name="isRefreshable"
            />
          }
          style={{ marginLeft: "0", width: "160px" }}
          label="Refreshable"
          disabled={!isEditable}
        />

        {data.reportSubtype == ReportSubtypeEnum.Summary &&
          <FormControlLabel
            control={
              <Checkbox
                checked={searchForSummary}
                onChange={handleChangeSearchForSummary}
                color="primary"
                name="searchForSummary"
              />
            }
            style={{ marginLeft: "0", width: "250px" }}
            label="Search for summary"
            disabled={!isEditable}
          />
        }
      </Grid>
    </Grid>
  );
};

export default EditReportGeneralParameters;
