import { makeStyles, Theme } from "@material-ui/core";
import Colors from "Common/colors";

export const stylesMoveListEditor = makeStyles((theme: Theme) => ({
  permissionGroupNameContainer: {
    borderRight: `1px solid ${Colors.graySeparate}`,
  },

  editButtonContainer: {
    paddingRight: "10px",
  },

  permissionContainer: {
    height: "54px",
    borderBottom: `1px solid ${Colors.graySeparate}`,
  },

  dialogContent: {
    "&:first-child": {
      paddingTop: "5px",
    },
  },

  filterInput: {
    marginRight: "0",
    marginTop: "5px",
  },

  list: {
    width: "94.5%",
    height: 400,
    overflowX: "auto",
    marginTop: "20px",
  },

  listItem: {
    borderBottom: `1px solid ${Colors.graySeparate}`,
  },

  subHeader: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${Colors.grayInactive}`,
    padding: 0,
  },

  listItemSubHeader: {
    padding: "0 16px",
  },
}));
