import * as React from "react";
import { IReportsReportsContainersProps } from "Components/Reports/interfaces-reports";
import { Container, Grid } from "@material-ui/core";
import { stylesReportContainers } from "Components/Reports/ReportsContainers/styles";
import ReportApi from "Services/Api/report-api";
import { GetReportContainerListApiResponseType } from "Types/report-types";
import ReportsContainersList from "Components/Reports/ReportsContainers/reports-containers-list";
import ButtonOutlined from "Components/Buttons/button-outlined";
import SvgPlusCircle from "Components/Images/image-plus-circle";
import { IconType, FormEditorEnum } from "Common/enum";
import Colors from "Common/colors";
import { Fragment } from "react";
import ReportContainerEditor from "Components/Reports/ReportsContainers/reports-containers-editor";
import HeaderCard from "Components/HeaderCard/header-card";

const reportApi = new ReportApi();

export default function ReportsReportsContainers(
  props: IReportsReportsContainersProps
) {
  const [appsWithReportsContainers, setAppsWithReportsContainers] =
    React.useState<GetReportContainerListApiResponseType[]>([]);
  const [isOpenEditorAddContainer, setIsOpenEditorAddContainer] =
    React.useState(false);

  let customStyles = stylesReportContainers();

  const getReportsContainers = React.useCallback(async (): Promise<void> => {
    if (props.client && props.client.id) {
      await reportApi.getReportContainerList(props.client.id).then((result) => {
        if (result && result.data) {
          setAppsWithReportsContainers(result.data);
        }
      });
    }
  }, [props.client]);

  React.useEffect(() => {
    getReportsContainers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddContainer = () => {
    setIsOpenEditorAddContainer(true);
  };

  const closeEditorAddContainer = () => {
    setIsOpenEditorAddContainer(false);
  };

  return (
    <Fragment>
      <Container>
        <HeaderCard>
          <Grid container alignItems="center" justify="space-between">
            <Grid item className={customStyles.title}>
              Reports containers
            </Grid>
            <ButtonOutlined
              text={"Add container"}
              iconStartType={IconType.SvgImageIcon}
              iconStart={
                <SvgPlusCircle width={"14px"} color={Colors.grayDark} />
              }
              size="md"
              margin="0 10px 0 0"
              handleClick={handleAddContainer}
            />
          </Grid>
        </HeaderCard>
        <div style={{ marginTop: "30px" }}>
          <ReportsContainersList
            client={props.client}
            data={appsWithReportsContainers}
            getReportsContainers={getReportsContainers}
          />
        </div>
      </Container>
      <ReportContainerEditor
        client={props.client}
        isOpen={isOpenEditorAddContainer}
        reportContainer={{
          reportContainerID: 0,
          reportContainerName: "",
          reports: [],
          applications: [],
        }}
        closeEditor={closeEditorAddContainer}
        getReportsContainers={getReportsContainers}
        forEditor={FormEditorEnum.Create}
      />
    </Fragment>
  );
}
