import { createMuiTheme, MuiThemeProvider, useTheme } from "@material-ui/core";
import { customSortList } from "Common/utils";
import _ from "lodash";
import MaterialTable from "material-table";
import * as React from "react";
import { GetPermissionListItemType } from "Types/role-types";
import { IPermissionListProps } from "./interfaces-permission-list";
import PermissionListItem from "./PermissionListItem/permission-list-item";

export default function PermissionList(props: IPermissionListProps) {
  const theme = useTheme();
  const headerStyle: React.CSSProperties = {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.graySeparate.main}`,
    borderBottomWidth: `2px`,
    fontSize: "14px",
    padding: "8px",
  };
  const themeTable = createMuiTheme(theme, {
    overrides: {
      MuiTableSortLabel: {
        root: {
          width: "max-content",
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={themeTable}>
      <MaterialTable
        title="Permissions"
        columns={[
          {
            field: "permissionNumber",
            title: "No",
            headerStyle: headerStyle,
            sorting: true,
            width: "auto",
            customSort: (
              data1: GetPermissionListItemType,
              data2: GetPermissionListItemType
            ) => (data1.permissionNumber < data2.permissionNumber ? -1 : 1),
          },
          {
            field: "permissionName",
            title: "Name",
            headerStyle: headerStyle,
            sorting: true,
            customSort: (
              data1: GetPermissionListItemType,
              data2: GetPermissionListItemType
            ) => data1.permissionName.localeCompare(data2.permissionName),
            defaultSort: "asc",
            width: "auto",
          },
          {
            field: "permissionsGroups",
            title: "Permissions Groups",
            headerStyle: headerStyle,
            sorting: true,
            customSort: (
              data1: GetPermissionListItemType,
              data2: GetPermissionListItemType
            ) =>
              customSortList(
                data1["permissionsGroups"].map((p) => p["permissionGroupName"]),
                data2["permissionsGroups"].map((p) => p["permissionGroupName"])
              ),
            width: "auto",
          },
          {
            field: "appList",
            title: "Applications",
            headerStyle: headerStyle,
            sorting: true,
            customSort: (
              data1: GetPermissionListItemType,
              data2: GetPermissionListItemType
            ) =>
              customSortList(
                data1["appList"].map((p) => p["appName"]),
                data2["appList"].map((p) => p["appName"])
              ),
            width: "auto",
          },
          {
            field: "reportContainers",
            title: "Report Containers",
            headerStyle: headerStyle,
            sorting: true,
            customSort: (
              data1: GetPermissionListItemType,
              data2: GetPermissionListItemType
            ) =>
              customSortList(
                data1["reportContainers"].map((p) => p["reportContainerName"]),
                data2["reportContainers"].map((p) => p["reportContainerName"])
              ),
            width: "auto",
          },
          {
            field: "reports",
            title: "Reports",
            headerStyle: headerStyle,
            sorting: true,
            customSort: (
              data1: GetPermissionListItemType,
              data2: GetPermissionListItemType
            ) =>
              customSortList(
                data1["reports"].map((p) => p["reportName"]),
                data2["reports"].map((p) => p["reportName"])
              ),
            width: "auto",
          },
          {
            field: "Actions",
            headerStyle: {
              ...headerStyle,
              textAlign: "center",
            },
            sorting: false,
            width: "auto",
          },
        ]}
        data={props.permissionList}
        options={{
          showTitle: false,
          header: true,
          paging: false,
          sorting: true,
          search: false,
          toolbar: false,
          tableLayout: "auto",
        }}
        components={{
          Row: (propsRow) => (
            <PermissionListItem
              permission={propsRow.data}
              appList={props.appList}
              getPermissionList={props.getPermissionList}
              client={props.client}
              setIsBlockedUi={props.setIsBlockedUi}
              permissionGroupsList={props.permissionGroupsList}
              reportsContainerList={props.reportsContainerList}
              setErrorResponse={props.setErrorResponse}
              setIsErrorDialogOpen={props.setIsErrorDialogOpen}
              openInfoDialog={props.openInfoDialog}
            />
          ),
        }}
        style={{
          boxShadow: "none",
          margin: "28px 0",
          background: "none",
        }}
      />
    </MuiThemeProvider>
  );
}
