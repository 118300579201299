import { PermissionModel } from './models';

export const onSearchChange = (
  value: React.SetStateAction<string>,
  list: PermissionModel[],
  setFilterd: (list: PermissionModel[]) => void
): void => {
  const searchedText: string = value.toString().toLowerCase() ?? '';
  search(searchedText, list, setFilterd);
};

const search = (
  searchedText: string,
  list: PermissionModel[],
  setFilterd: (list: PermissionModel[]) => void
): void => {
  const filteredRoles: PermissionModel[] = searchByText(list, searchedText);
  setFilterd(unselectAllListElements(filteredRoles));
};

export const unselectAllListElements = (
  list: PermissionModel[]
): PermissionModel[] => list.map((r) => ({ ...r, isSelected: false }));

export const searchByText = (
  list: PermissionModel[],
  text?: string
): PermissionModel[] => {
  if (text) {
    list = list.filter((x) =>
      x.data.name.toLowerCase().includes(text.toLowerCase())
    );
  }
  return list;
};
