import * as React from 'react';
import CustomerListItem from './customer-list-item';
import MaterialTable from 'material-table';
import { createMuiTheme, MuiThemeProvider, useTheme } from '@material-ui/core';
import { ICustomerListProps } from 'Components/Customers/interfaces-customers';

export default function CustomerList(props: ICustomerListProps) {
  const theme = useTheme();
  const headerStyle: React.CSSProperties = {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.graySeparate.main}`,
    borderBottomWidth: `2px`,
    fontSize: "14px",
    padding: "8px",
  };
  const themeTable = createMuiTheme(theme, {
    overrides: {
      MuiTableSortLabel: {
        root: {
          width: "max-content",
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={themeTable}>
      <MaterialTable
        title="Customers"
        columns={[
          {
            field: "name",
            title: "Customer",
            headerStyle: headerStyle,
            sorting: true,
            width: "auto",
          },
          {
            field: "guid",
            title: "Guid",
            headerStyle: headerStyle,
            sorting: true,
            width: "auto",
          },
          {
            field: "pinLength",
            title: "Pin Length",
            headerStyle: headerStyle,
            sorting: true,
            width: "auto",
          },
          {
            field: "locations",
            title: "Location count",
            headerStyle: headerStyle,
            sorting: true,
          },
          {
            field: "Actions",
            headerStyle: {
              ...headerStyle,
              textAlign: "center",
            },
            sorting: false,
            width: "auto",
          },
        ]}
        data={props.customers}
        options={{
          showTitle: false,
          header: true,
          paging: false,
          sorting: true,
          search: false,
          toolbar: false,
          tableLayout: "auto",
        }}
        components={{
          Row: (propsRow) => <CustomerListItem item={propsRow.data} />,
        }}
        style={{
          boxShadow: "none",
          margin: "28px 0",
          background: "none",
        }}
      />
    </MuiThemeProvider>
  );
}
