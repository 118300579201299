import * as React from 'react';
import { Avatar } from '@material-ui/core';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileApi from 'Services/Api/file-api';
import { IImageApplogo } from 'Components/Image/interfaces-image';
import { stylesImageAppLogo } from 'Components/Image/ImageAppLogo/styles';

let fileApi: FileApi = new FileApi();

export default function ImageAppLogo(props: IImageApplogo) {
  const [imageData, setImageData] = React.useState<string | ArrayBuffer | null>(
    null
  );
  const customStyles = stylesImageAppLogo(props);

  React.useEffect(() => {
    const getImage = async (): Promise<void> => {
      if (props.guid) {
        await fileApi
          .download(props.guid)
          .then((data) => {
            if (data) {
              let reader = new FileReader();
              reader.readAsDataURL(data);
              reader.onloadend = () => {
                setImageData(reader.result);
              };
            } else if (props.setAppLogoNotLoaded) {
              props.setAppLogoNotLoaded();
            }
          })
          .catch(() => {
            if (props.setAppLogoNotLoaded) {
              props.setAppLogoNotLoaded();
            }
          });
      } else if (imageData) {
        setImageData(null);
        if (props.setAppLogoNotLoaded) {
          props.setAppLogoNotLoaded();
        }
      }
    };
    getImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.guid]);

  let logo: React.ReactNode;
  if (imageData) {
    logo = <Avatar className={customStyles.avatar} src={`${imageData}`} />;
  } else {
    logo = (
      <Avatar className={customStyles.avatar}>
        <FontAwesomeIcon icon={faImage} />
      </Avatar>
    );
  }

  return <React.Fragment>{logo}</React.Fragment>;
}
