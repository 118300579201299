import Colors from 'Common/colors';
import { makeStyles } from '@material-ui/core';

export const stylesUserLoginListItem = makeStyles({
  gridItem: {
    borderRight: `1px solid ${Colors.graySeparate}`,
    marginRight: "40px",
    height: "50px",
  },
  paper: {
    padding: "11px 20px",
    marginBottom: "15px",
  },
  emailIcon: {
    marginRight: "6px",
  },
  userName: {
    fontSize: "18px",
  },
  userEmail: {
    fontSize: "14px",
  },
  loginEmailNumber: {
    padding: "0px",
  },
  header: {
    marginBottom: "20px",
  },
  button: {
    marginLeft: "15px",
  },
  appRow: {
    borderBottom: `1px solid ${Colors.graySeparate}`,
    paddingBottom: "33px",
    marginTop: "15px",
    paddingRight: "45px",
  },
  country: {
    // height: "30px",
    padding: "2px 0 3px 0",
    // color: "red",
  },
  editForm: {
    marginTop: "15px",
    borderTop: `1px solid ${Colors.graySeparate}`,
  },
});

export const stylesUserLoginListItemApp = makeStyles({
  app: {
    width: "100%",
  },
});

export const stylesButtonActionAndSubaction = makeStyles({
  root: {
    "&>:first-child": {
      marginRight: "12px",
    },
    marginTop: "34px",
    marginBottom: "25px",
    marginRight: "10px",
    padding: "0px !important",
  },
});

export const stylesUserCompanySection = makeStyles({
  input: {
    padding: "6px 0 4px",
  },
});
