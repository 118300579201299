import { makeStyles } from "@material-ui/core";
import Colors from "Common/colors";

export const stylesDeviceListFilters = makeStyles({
  filters: {
    padding: "40px 0 20px 15px",
  },
  sortBy: {
    float: "right",
  },
});

export const stylesDeviceListItem = makeStyles({
  gridItem: {
    borderRight: `2px solid ${Colors.gray}`,
    marginRight: "40px",
  },
  paper: {
    padding: "20px 30px",
    marginBottom: "15px",
  },
});
