import * as React from "react";
import { Grid, Paper } from "@material-ui/core";
import { ListItemBaseProps } from "./interfaces";
import { stylesListItemBase } from "./styles";

const ListItemBase: <TListItem>(
  props: ListItemBaseProps<TListItem>
) => JSX.Element = (props): JSX.Element => {
  const customStyles = stylesListItemBase();

  return (
    <tr>
      <td>
        <Paper className={customStyles.paper}>
          <Grid container spacing={2}>
            <Grid container item className={customStyles.leftItem} xs={2}>
              {props.renderTitle(props.item)}
            </Grid>
            <Grid container item xs={10}>
              {props.renderItem(props.item)}
            </Grid>
          </Grid>
        </Paper>
      </td>
    </tr>
  );
};

export default ListItemBase;
