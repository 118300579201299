import { CustomerType } from "Common/enum";
import { GetCustomerListResponseType } from "Types/customer-types";

export const filter = (
  list: GetCustomerListResponseType[],
  textSearch: string,
  customerType: CustomerType
): GetCustomerListResponseType[] => {
  let customerList = searchByText(list, textSearch);
  customerList = searchByType(customerList, customerType);
  return customerList;
};

const searchByText = (
  list: GetCustomerListResponseType[],
  text: string
): GetCustomerListResponseType[] => {
  if (text) {
    list = list.filter(
      (x) =>
        x.name.toLowerCase().includes(text!) ||
        x.guid.toLowerCase().includes(text!)
    );
  }
  return list;
};

const searchByType = (
  list: GetCustomerListResponseType[],
  customerType: CustomerType
) => list.filter((customer) => {
  switch (customerType) {
    case CustomerType.All:
      return true;
    case CustomerType.Dealer:
      return customer.isDealer;
    case CustomerType.NonDelaer:
      return !customer.isDealer;
  }
});
