import * as React from "react";
import { IAccessRightFiltersProps } from "Components/AccessRight/interfaces-access-right";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import InputCheckbox from "Components/Inputs/input-checkbox";
import TypographyInfo from "Components/Typography/typography-info";
import SearchField from "Components/Inputs/SearchField/search-field";
import FilterCard from "Components/FilterCard/filter-card";

const AccessRightListFilters: (props: IAccessRightFiltersProps) => JSX.Element =
  (props: IAccessRightFiltersProps): JSX.Element => {
    const getSelectedGroupNames = (selectedGroupsIds: number[]): string => {
      let selectedGroupNames: string[] = [];
      props.permissionGroups.forEach((group) => {
        if (selectedGroupsIds.includes(group.permissionGroupID)) {
          selectedGroupNames.push(group.permissionGroupName);
        }
      });

      return selectedGroupNames.join(", ");
    };

    return (
      <FilterCard>
        <Grid item container justify="flex-start" spacing={4}>
          <Grid item sm={3}>
            <SearchField onChangeEvent={props.onSearchChange} />
          </Grid>
          <Grid item sm={2}>
            <FormControl>
              <InputLabel>Permission group</InputLabel>
              <Select
                multiple
                value={props.selectedPermissionGroups}
                onChange={props.onSelectedPermissionGroupsChange}
                renderValue={(selected) =>
                  props.selectAllPermissionGroups
                    ? "All"
                    : getSelectedGroupNames(selected as number[])
                }
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              >
                <MenuItem value={0}>
                  <InputCheckbox checked={props.selectAllPermissionGroups} />
                  <ListItemText
                    primary={<TypographyInfo text="Select all" />}
                  />
                </MenuItem>
                <Divider variant="middle" />
                {props.permissionGroups.map((permissionGroup) => (
                  <MenuItem
                    key={permissionGroup.permissionGroupID}
                    value={permissionGroup.permissionGroupID}
                  >
                    <InputCheckbox
                      checked={
                        props.selectedPermissionGroups.indexOf(
                          permissionGroup.permissionGroupID
                        ) > -1
                      }
                    />
                    <ListItemText
                      primary={
                        <TypographyInfo
                          text={permissionGroup.permissionGroupName}
                        />
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </FilterCard>
    );
  };

export default AccessRightListFilters;
