import * as React from "react";
import MaterialTable from "material-table";
import DeviceListItem from "Components/Device/DeviceList/device-list-item";
import { IDeviceListProps } from "Components/Device/interfaces-device";

export default function DeviceList(props: IDeviceListProps) {
    return (
        <MaterialTable
            columns={[]}
            data={props.deviceList}
            options={{
                showTitle: false,
                header: false,
                paging: false,
                search: false,
                toolbar: false
            }}
            components={{
                Row: props => <DeviceListItem device={props.data}/>
            }}
            style={{
                background: 'transparent',
                boxShadow: 'none'
            }}
        />
    )
}