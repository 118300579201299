import * as React from 'react';
import CustomerLocationListItem from './customer-location-list-item';
import StyledTable from 'Components/Table/styled-table';
import { CustomerLocationsListType } from 'Types/customer-types';
import { useTheme } from '@material-ui/core';

export interface ICustomerLocationListProps {
  locationList: CustomerLocationsListType[];
}

const CustomerLocationList: (props: ICustomerLocationListProps) => JSX.Element =
  (props: ICustomerLocationListProps): JSX.Element => {
    const theme = useTheme();

    return (
      <StyledTable
        columns={[
          {
            field: "name",
            title: "Location",
            headerStyle: {
              color: theme.palette.secondary.main,
              border: `1px solid ${theme.palette.graySeparate.main}`,
              borderRadius: "5px",
            },
          },
          {
            field: "address",
            title: "Address",
            headerStyle: {
              color: theme.palette.secondary.main,
              border: `1px solid ${theme.palette.graySeparate.main}`,
              borderRadius: "5px",
            },
          },
          {
            field: "guid",
            title: "Guid",
            headerStyle: {
              color: theme.palette.secondary.main,
              border: `1px solid ${theme.palette.graySeparate.main}`,
              borderRadius: "5px",
            },
          },
        ]}
        data={props.locationList}
        components={{
          Row: (propsRow) => (
            <CustomerLocationListItem location={propsRow.data} />
          ),
        }}
        options={{
          showTitle: false,
          header: true,
          paging: false,
          search: false,
          toolbar: false,
          tableLayout: "auto",
        }}
        style={{
          boxShadow: "none",
          margin: "20px 20px 20px 0",
          background: "none",
          fontSize: "14px",
        }}
      />
    );
  };

export default CustomerLocationList;
