import * as React from "react";
import { Container } from "@material-ui/core";
import MobileAppHeader from "Components/MobileApp/MobileAppHeader/mobile-app-header";
import AppListContainer from "Components/AppList/app-list-container";
import { MobileAppType } from "Types/app-types";
import { IMobileAppDetailsProps } from "Components/MobileApp/interfaces-mobile-app";
import { AppPlatformEnum, RequiredLoginEnum } from "Common/enum";
import ClientAppApi from "Services/Api/client-app-api";
import { useHistory } from "react-router-dom";
import { RouteApplications } from "Configuration/Routes";
import { GetRolesForAppApiResponseType } from "Types/role-types";
import RoleApi from "Services/Api/role-api";

const clientAppApi = new ClientAppApi();
const roleApi = new RoleApi();

export default function MobileAppDetails(props: IMobileAppDetailsProps) {
  const [mobileApp, setMobileApp] = React.useState<MobileAppType>();
  const [roleList, setRoleList] = React.useState<
    GetRolesForAppApiResponseType[]
  >([]);

  const history = useHistory();

  const getMobileApp = React.useCallback(async () => {
    if (props.match.params.id && props.client.id && props.client.id > 0) {
      await clientAppApi.getMobileApp(props.match.params.id).then((result) => {
        if (result && result.data) {
          if (result.data.iaClientID === props.client.id) {
            setMobileApp(result.data);
          } else {
            history.push(RouteApplications);
          }
        } else {
          history.push(RouteApplications);
        }
      });
    }
  }, [props.match.params.id, props.client.id, history]);

  React.useEffect(() => {
    getMobileApp();
  }, [getMobileApp]);

  React.useEffect(() => {
    if (mobileApp) {
      const getRoleList = async (): Promise<void> => {
        await roleApi.getRolesForApp(mobileApp?.id!).then((result) => {
          if (result && result.data) {
            setRoleList(result.data);
          }
        });
      };
      getRoleList();
    }
  }, [mobileApp]);

  return (
    <Container>
      <MobileAppHeader
        appName={mobileApp ? mobileApp.name : "-"}
        appId={mobileApp ? mobileApp.id : 0}
        appGuid={mobileApp ? mobileApp.guid : ""}
        clientId={mobileApp ? mobileApp.iaClientID : 0}
        platformType={
          mobileApp ? mobileApp.platformType : AppPlatformEnum.Android
        }
        logoFile={mobileApp ? mobileApp.logoFile : undefined}
        isRequireUsers={mobileApp ? mobileApp.isRequireUsers : true}
        requiredLoginType={
          mobileApp ? mobileApp.requiredLoginType : RequiredLoginEnum.Email
        }
        handleRefreshApp={getMobileApp}
        roleList={roleList}
        roleID={mobileApp?.iaRoleID}
        isReportsAvailable={mobileApp ? mobileApp.isReportsAvailable : true}
        isActivationRequired={mobileApp ? mobileApp.isActivationRequired : true}
        isAnyVersionWithForceUpdate={
          mobileApp
            ? mobileApp.versionList.filter((v) => v.isForcedUpdate).length > 0
            : false
        }
      />
      <AppListContainer
        appName={mobileApp ? mobileApp.name : ""}
        versionList={mobileApp ? mobileApp.versionList : []}
        isAnyVersionWithForceUpdate={
          mobileApp?.isAnyVersionWithForceUpdate ?? false
        }
        handleRefreshApp={getMobileApp}
        platformType={
          mobileApp ? mobileApp.platformType : AppPlatformEnum.Android
        }
      />
    </Container>
  );
}
