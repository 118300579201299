import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Autocomplete } from '@material-ui/lab';
import {
  GetReportColumnListType,
  GetReportDataTypeFormatListApiResponseType,
} from '../../../../Types/report-types';
import { stylesFont } from './styles';
import { ReportColumnFormatType } from 'Common/enum';

export interface SelectDataTypeFormatProps {
  data: GetReportColumnListType;
  list: GetReportColumnListType[];
  handleChangeColumns: (columns: GetReportColumnListType[]) => void;

  dataTypeFormatList: GetReportDataTypeFormatListApiResponseType[] | undefined;
}

const SelectDataTypeFormat: React.FC<SelectDataTypeFormatProps> = ({
  data,
  list,
  dataTypeFormatList,
  handleChangeColumns,
}) => {
  const [openSelectFormat, setOpenSelectFormat] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<string>(
    data.formatType === ReportColumnFormatType.DataTypeFormat
      ? data.dataTypeFormatText
      : data.formatType === ReportColumnFormatType.Custom
      ? data.formatValueText
      : ''
  );

  const customStyles = stylesFont();

  const handleChangeFormat = (value: string, columnID: number): void => {
    const dataTypeFormat = dataTypeFormatList?.find(
      (format) => format.format === value
    );

    handleChangeColumns(
      list.map((p) =>
        p.columnID === columnID
          ? value == null || value.length === 0
            ? {
                ...p,
                dataTypeFormatText: '',
                dataTypeFormatID: undefined,
                formatType: ReportColumnFormatType.None,
                formatValueText: '',
              }
            : dataTypeFormat
            ? {
                ...p,
                dataTypeFormatText: value,
                dataTypeFormatID: dataTypeFormat.reportDataTypeFormatID,
                formatType: ReportColumnFormatType.DataTypeFormat,
                formatValueText: '',
              }
            : {
                ...p,
                dataTypeFormatText: '',
                dataTypeFormatID: undefined,
                formatType: ReportColumnFormatType.Custom,
                formatValueText: value,
              }
          : p
      )
    );
  };

  React.useEffect(() => {
    setInputValue(
      data.formatType === ReportColumnFormatType.DataTypeFormat
        ? data.dataTypeFormatText
        : data.formatType === ReportColumnFormatType.Custom
        ? data.formatValueText
        : ''
    );
  }, [data.dataTypeFormatText, data.formatType, data.formatValueText]);

  return (
    <Autocomplete
      freeSolo
      disableClearable
      classes={{
        option: customStyles.input,
        inputRoot: customStyles.inputRoot,
      }}
      open={openSelectFormat}
      onOpen={() => setOpenSelectFormat(true)}
      onClose={() => setOpenSelectFormat(false)}
      value={data.dataTypeFormatText ?? ''}
      onChange={(event: any, newValue: string | null) => {
        if (newValue != null) {
          handleChangeFormat(newValue, data.columnID);
        }
      }}
      onBlur={() => {
        handleChangeFormat(inputValue, data.columnID);
      }}
      inputValue={inputValue ?? ''}
      onInputChange={(event: any, newValue: string | null) => {
        if (newValue != null) {
          setInputValue(newValue);
        }
      }}
      options={
        dataTypeFormatList
          ? dataTypeFormatList.map((format) => format.format)
          : []
      }
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            classes: { input: customStyles.input },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                  style={{
                    backgroundColor: 'transparent',
                    padding: '0 0 5px 0',
                  }}
                  onClick={() => setOpenSelectFormat(!openSelectFormat)}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default SelectDataTypeFormat;
