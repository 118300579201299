import * as React from "react";
import { IBoundAppListProps } from "Components/Bound/interfaces-bound";
import { Scrollbars } from 'react-custom-scrollbars';
import { List, ListItem, Divider, ListItemIcon, ListItemText } from "@material-ui/core";
import { stylesBoundAppList } from "Components/Bound/BoundAppList/styles";
import InputCheckbox from "Components/Inputs/input-checkbox";
import TypographyInfo from "Components/Typography/typography-info";

export default function BoundAppList(props: IBoundAppListProps) {
    const customStyles = stylesBoundAppList({});

    return (
        <Scrollbars>
            <List>
                {props.appList.map(app => {
                    return (
                        <React.Fragment key={app.iaAppID}>
                            <ListItem button onClick={props.handleAppToggle(app.iaAppID)}>
                                <ListItemIcon className={customStyles.listItemIcon}>
                                    <InputCheckbox checked={app.isBound} />
                                </ListItemIcon>
                                <ListItemText primary={<TypographyInfo text={app.appName}/>}/>
                            </ListItem>
                            <Divider variant="middle" />
                        </React.Fragment>
                    )
                })}
            </List>
        </Scrollbars>
    )
}