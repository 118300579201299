import * as React from 'react';
import { Paper } from '@material-ui/core';
import { IUserLoginListItemProps } from 'Components/User/interfaces-user';
import { stylesUserLoginListItem } from 'Components/User/UserLogin/UserLoginListItem/styles';
import { UserLoginListItemHeader } from './user-login-list-item-header';
import UserLoginListItemEditForm from './UserLoginListItemEditForm/user-login-list-item-edit-form';

export default function UserLoginListItem(props: IUserLoginListItemProps) {
  const {
    userLogin,
    countryList,
    customerList,
    userID,
    appList,
    isOpen,
    handleSaveUserLogin,
    showInfoMessage,
    handleDeleteUserLogin,
    handleOpenUserLogin,
    handleSendUserLoginResetCode,
  } = props;

  const customStyles = stylesUserLoginListItem();

  return (
    <tr>
      <td>
        <Paper className={customStyles.paper}>
          <UserLoginListItemHeader
            userLogin={userLogin}
            handleOpenUserLogin={handleOpenUserLogin}
            handleDeleteUserLogin={handleDeleteUserLogin}
          />
          {isOpen && (
            <UserLoginListItemEditForm
              userID={userID}
              userLogin={userLogin}
              propsAppList={appList}
              countryList={countryList}
              customerList={customerList}
              handleOpenUserLogin={handleOpenUserLogin}
              handleSaveUserLogin={handleSaveUserLogin}
              propsHandleSendUserLoginResetCode={handleSendUserLoginResetCode}
              showInfoMessage={showInfoMessage}
            />
          )}
        </Paper>
      </td>
    </tr>
  );
}
