import * as React from "react";

const SvgDelete = ({
    style = {},
    fill = "none",
    width = "22",
    className = "",
    viewBox = "0 0 22 22"
}) => (
        <svg width={width}
            style={style}
            height={width}
            viewBox={viewBox}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <path d="M1 1L21 21" stroke="black" strokeWidth="1.5" />
            <path d="M21 1L1 21" stroke="black" strokeWidth="1.5" />
        </svg>


    );

export default SvgDelete;