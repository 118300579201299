import * as React from "react";
import * as Yup from "yup";
import { ILoginFormProps } from "../../../Components/Login/interfaces-login";
import SvgLogo from "../../Images/image-logo";
import { Fragment } from "react";
import { Formik } from "formik";
import { UserLoginModelType } from "Types/user-types";
import ButtonSolid from "Components/Buttons/button-solid";
import { TextField } from "@material-ui/core";
import { AxiosError } from "axios";
import { ApiResultStatusEnum, LandingPageEnum } from "Common/enum";
import UserApi from "Services/Api/user-api";
import { ClientSaveApiResultType } from "Types/api-types";
import AuthorizationHelper from "Configuration/authorization-helper";
import { UserData } from "Configuration/user-data";
import { LandingPagesRoutes } from "Configuration/landing-pages-routes";
import { getLastVisitedPage } from "Configuration/settings-helper";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";

const userApi = new UserApi();

const EMAIL_MAX_LENGTH: number = 100;
const PASSWORD_MAX_LENGTH: number = 100;

export default function LoginForm(props: ILoginFormProps) {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [showError, setShowError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const isTestEnv: boolean = process.env.REACT_APP_IS_TEST_ENV === "true";
  const backdoorEmail: string = "" + process.env.REACT_APP_BACKDOOR_LOGIN;
  const backdoorPassword: string = "" + process.env.REACT_APP_BACKDOOR_PASS;

  const handleSubmit = (userLogin: UserLoginModelType) => {
    if (!isLoading) {
      setIsLoading(true);
      login(userLogin)
        .then(() => {})
        .catch((error) => {
          console.error(error);
          setErrorResponse(error);
          setIsErrorDialogOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const creatUserLoginDto = (
    userEmail: string,
    userPassword: string
  ): UserLoginModelType => {
    return {
      email: userEmail,
      password: userPassword,
      appGuid: process.env.REACT_APP_IA_GUID!,
    };
  };

  const loginBackdoor = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    await login(creatUserLoginDto(backdoorEmail, backdoorPassword))
      .then(() => {})
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const login = (
    userLogin: UserLoginModelType
  ): Promise<ClientSaveApiResultType | void> => {
    setShowError(false);
    return userApi.login(userLogin).then((result) => {
      if (result.status === ApiResultStatusEnum.Ok && result.data !== null) {
        AuthorizationHelper.setUser(
          new UserData(
            result.data.userID,
            result.data.firstName,
            result.data.lastName,
            result.data.login,
            result.data.permissions
          )
        );
        AuthorizationHelper.setToken({
          auth: result.data.tokenAuth,
          refresh: result.data.tokenRefresh,
        });

        const lastVisitedPage: LandingPageEnum | undefined =
          getLastVisitedPage();

        const goToUrl: string = lastVisitedPage
          ? LandingPagesRoutes.find((p) => p.page === lastVisitedPage)?.route ??
            AuthorizationHelper.defaultPage
          : AuthorizationHelper.defaultPage;

        // eslint-disable-next-line no-restricted-globals
        history.pushState(history.state, "login", goToUrl);
        // eslint-disable-next-line no-restricted-globals
        history.go();
      } else {
        setErrorMessage("Invalid email or password");
        setShowError(true);
      }
    });
  };

  return (
    <Fragment>
      <SvgLogo width="auto" height="50px" />
      <br />
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={async (values) => {
          await handleSubmit(creatUserLoginDto(values.email, values.password));
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .label("Email")
            .email("Enter a valid email")
            .required("Required")
            .max(EMAIL_MAX_LENGTH)
            .trim(),
          password: Yup.string()
            .label("Password")
            .required("Required")
            .min(5, "Password must contain at least 5 characters")
            .max(PASSWORD_MAX_LENGTH)
            .trim(),
        })}
      >
        {(formProps) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = formProps;
          return (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                id="email"
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.email && touched.email)}
                helperText={
                  (errors.email && touched.email && errors.email) ||
                  `${values.email.length}/${EMAIL_MAX_LENGTH}`
                }
                inputProps={{
                  maxLength: EMAIL_MAX_LENGTH,
                }}
                fullWidth
                required
                margin="normal"
              />
              <TextField
                id="password"
                name="password"
                label="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.password && touched.password)}
                helperText={
                  (errors.password && touched.password && errors.password) ||
                  `${values.password.length}/${PASSWORD_MAX_LENGTH}`
                }
                inputProps={{
                  maxLength: PASSWORD_MAX_LENGTH,
                }}
                type="password"
                fullWidth
                required
                margin="normal"
              />
              <div className="link">
                <a href="/forgot-password">Forgot password?</a>
              </div>
              <div className="errorInfo">{showError ? errorMessage : ""}</div>
              <div className="buttons">
                <ButtonSolid margin="10px" text="Login" disabled={isLoading} />
                {isTestEnv ? (
                  <Fragment>
                    <br />
                    <ButtonSolid
                      margin="20px"
                      text="Backdoor"
                      onClickEvent={loginBackdoor}
                    />
                  </Fragment>
                ) : (
                  ""
                )}
              </div>
            </form>
          );
        }}
      </Formik>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </Fragment>
  );
}
