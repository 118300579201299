import * as React from 'react';
import { WebAppInstanceDeploymentType } from 'Types/app-types';
import { Paper, Grid } from '@material-ui/core';
import {
  faCheck,
  faCog,
  faCalendar,
  faTimes,
  faEllipsisH,
} from '@fortawesome/free-solid-svg-icons';
import { SortableElement } from 'react-sortable-hoc';
import { head } from 'lodash';
import { stylesWebServiceListItem } from './styles';
import { AppPlatformEnum, FormEditorEnum, IconType } from 'Common/enum';
import Colors from 'Common/colors';
import moment from 'moment';
import TypographyInfo from 'Components/Typography/typography-info';
import TypographySubinfo from 'Components/Typography/typography-subinfo';
import TypographySubtitle from 'Components/Typography/typography-subtitle';
import WebServiceInstanceEditor from './web-service-instance-editor';
import ButtonIcon from 'Components/Buttons/button-icon';
import { Link } from 'react-router-dom';
import { WebServiceListDragHandle } from './web-service-list-drag-handle';
import { IWebServiceListItemProps } from './interfaces-web-service';

export const WebServiceListItem = SortableElement(
  ({ instance }: { instance: IWebServiceListItemProps }) => {
    const customStyles = stylesWebServiceListItem({});

    let lastDeployment: WebAppInstanceDeploymentType = {
      id: 0,
      iaWebAppInstanceID: instance.instance.id,
      releaseDate: '',
      releaseNo: '-',
    };

    if ((instance.instance.deploymentList || []).length > 0) {
      lastDeployment = head(
        instance.instance.deploymentList
      ) as WebAppInstanceDeploymentType;
    }

    let address: React.ReactText;
    if (instance.instance.url) {
      address = `<a target="_blank" style="text-decoration: none; color: inherit" href=" ${instance.instance.url}">${instance.instance.url}</a>`;
    } else {
      address = `<a style="text-decoration: none; color: inherit">No address</a>`;
    }

    return (
      <Paper className={customStyles.paper}>
        <Grid container alignItems="center">
          <Grid container item xs={3} alignItems="center">
            <Grid item xs={1}>
              <WebServiceListDragHandle />
            </Grid>
            <Grid container item xs={11}>
              <Grid item xs={12}>
                <TypographySubtitle
                  text={instance.instance.name}
                  margin="0px 20px"
                />
              </Grid>
              <Grid item xs={12}>
                <TypographyInfo
                  text={`Release ${lastDeployment.releaseNo}`}
                  margin="0px 20px"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={9} className={customStyles.releaseInfo}>
            <Grid container item xs={10}>
              <Grid container item xs={12}>
                <Grid item xs={2}>
                  <TypographySubinfo
                    text={
                      lastDeployment.releaseDate
                        ? moment(lastDeployment.releaseDate).format(
                            'DD/MM/YYYY'
                          )
                        : '-'
                    }
                    iconStart={faCalendar}
                    margin="0px 5px"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TypographySubinfo
                    text={lastDeployment.apiVersionNo || '-'}
                    iconStart={faCog}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TypographySubinfo
                    text="Security key value"
                    iconStart={
                      instance.instance.securityKeyValue ? faCheck : faTimes
                    }
                    iconStartColor={
                      instance.instance.securityKeyValue
                        ? Colors.green
                        : Colors.red
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TypographySubinfo
                    text="Security key name"
                    iconStart={
                      instance.instance.securityKeyName ? faCheck : faTimes
                    }
                    iconStartColor={
                      instance.instance.securityKeyName
                        ? Colors.green
                        : Colors.red
                    }
                  />
                </Grid>
                {instance.platformType === AppPlatformEnum.WebService ?
                <Grid item xs={2}>
                  <TypographySubinfo
                    text={'Token'}
                    iconStart={
                      instance.instance.isTokenRequired ? faCheck : faTimes
                    }
                    iconStartColor={
                      instance.instance.isTokenRequired
                        ? Colors.green
                        : Colors.red
                    }
                  />
                </Grid>
                :
                ""
                }
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={1}>
                  <TypographySubinfo text="URL: " />
                </Grid>
                <Grid item xs={9}>
                  <Paper className={customStyles.paperLink}>
                    <TypographySubinfo text={address} />
                  </Paper>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={1}>
                  <TypographySubinfo text="File URL: " />
                </Grid>
                <Grid item xs={9}>
                  <Paper className={customStyles.paperLink}>
                    <TypographySubinfo
                      text={
                        instance.instance.fileURL
                          ? instance.instance.fileURL
                          : '-'
                      }
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <div className={customStyles.buttonEdit}>
                <WebServiceInstanceEditor
                  editorType={FormEditorEnum.Edit}
                  webAppId={instance.instance.appID}
                  webAppInstanceId={instance.instance.id}
                  webAppInstanceObject={instance.instance}
                  getWebApp={instance.getWebApp}
                  platformType={instance.platformType}
                />
                <Link to={`/web-application-instance/${instance.instance.id}`}>
                  <ButtonIcon
                    iconType={IconType.FontAwesomeIcon}
                    icon={faEllipsisH}
                    borderColor={Colors.black}
                    width="26px"
                    height="26px"
                  />
                </Link>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
);
