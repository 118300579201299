import { Grid, TextField } from "@material-ui/core";
import TextFieldEntered from "Components/Inputs/TextFieldEntered/text-field-entered";
import { Autocomplete } from "@material-ui/lab";
import { useFormikContext } from "formik";
import * as React from "react";
import {
  AddReportFormikValuesType,
  NAME_MAX_LENGTH,
  ReportSubtypeEnumLabels,
} from "./types";
import { ReportSubtypeEnum } from "Common/enum";

interface AddNewReportNotPowerBiProps {
  selectedReportSubtype: ReportSubtypeEnum;
  setSelectedReportSubtype: React.Dispatch<
    React.SetStateAction<ReportSubtypeEnum>
  >;
}

const AddNewReportNotPowerBi: React.FC<AddNewReportNotPowerBiProps> = ({
  selectedReportSubtype,
  setSelectedReportSubtype,
}) => {
  const { values, errors, touched, handleBlur, handleChange } =
    useFormikContext<AddReportFormikValuesType>();
  const handleReportSubtypeChange = (
    _event: React.ChangeEvent<{}>,
    value: ReportSubtypeEnum | null
  ) => {
    setSelectedReportSubtype(value ?? ReportSubtypeEnum.Regular);
  };

  return (
    <>
      <Grid item sm={12}>
        <Autocomplete
          options={Object.keys(ReportSubtypeEnum)
            .filter((k) => !isNaN(Number(k)))
            .map((k) => Number(k))}
          value={selectedReportSubtype}
          getOptionLabel={(option) => ReportSubtypeEnumLabels[option]}
          renderInput={(params) => (
            <TextField {...params} label="Type" variant="standard" />
          )}
          onChange={handleReportSubtypeChange}
        />
      </Grid>
      <Grid item sm={12}>
        <TextFieldEntered
          id="stored-procedure-name"
          name="storedProcedureName"
          label={
            selectedReportSubtype !== ReportSubtypeEnum.Regular
              ? "Master stored procedure name"
              : "Stored procedure name"
          }
          value={values.storedProcedureName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!(errors.storedProcedureName && touched.storedProcedureName)}
          helperText={
            (errors.storedProcedureName &&
              touched.storedProcedureName &&
              errors.storedProcedureName) ||
            `${values.storedProcedureName.length}/${NAME_MAX_LENGTH}`
          }
          inputProps={{
            maxLength: NAME_MAX_LENGTH,
          }}
          required
        />
      </Grid>
      {selectedReportSubtype === ReportSubtypeEnum.Summary && (
        <Grid item sm={12}>
          <TextFieldEntered
            id="summary-stored-procedure-name"
            name="summaryStoredProcedureName"
            label="Summary stored procedure name"
            value={values.summaryStoredProcedureName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              !!(
                errors.summaryStoredProcedureName &&
                touched.summaryStoredProcedureName
              )
            }
            helperText={
              (errors.summaryStoredProcedureName &&
                touched.summaryStoredProcedureName &&
                errors.summaryStoredProcedureName) ||
              `${
                values.summaryStoredProcedureName
                  ? values.summaryStoredProcedureName.length
                  : 0
              }/${NAME_MAX_LENGTH}`
            }
            inputProps={{
              maxLength: NAME_MAX_LENGTH,
            }}
            required
          />
        </Grid>
      )}
      {selectedReportSubtype === ReportSubtypeEnum.MasterDetails && (
        <Grid item sm={12}>
          <TextFieldEntered
            id="details-stored-procedure-name"
            name="detailsStoredProcedureName"
            label="Details stored procedure name"
            value={values.detailsStoredProcedureName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              !!(
                errors.detailsStoredProcedureName &&
                touched.detailsStoredProcedureName
              )
            }
            helperText={
              (errors.detailsStoredProcedureName &&
                touched.detailsStoredProcedureName &&
                errors.detailsStoredProcedureName) ||
              `${
                values.detailsStoredProcedureName
                  ? values.detailsStoredProcedureName.length
                  : 0
              }/${NAME_MAX_LENGTH}`
            }
            inputProps={{
              maxLength: NAME_MAX_LENGTH,
            }}
            required
          />
        </Grid>
      )}
    </>
  );
};

export default AddNewReportNotPowerBi;
