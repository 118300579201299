import * as React from "react";
import MaterialTable, { MaterialTableProps } from "material-table";
import { stylesTable } from "./styles";

export default function StyledTable<T extends object>(
  props: MaterialTableProps<T>
) {
  const classes = stylesTable();

  return (
    <div className={classes.root}>
      <MaterialTable<T> {...props} />
    </div>
  );
}
