import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ITypographyInfoProps } from "./interfaces-typography";

const styles = makeStyles((theme: Theme) => ({
  text: (props: ITypographyInfoProps) => ({
    color: props.color ? props.color : theme.palette.primary.main,
    fontSize: props.fontSize ?? "16px",
    margin: props.margin ? props.margin : "5px 0",
    verticalAlign: "bottom",
    textTransform: props.isUppercase ? "uppercase" : "none",
  }),
  startIcon: {
    marginRight: "10px",
  },
  endIcon: {
    marginLeft: "10px",
  },
}));

export default function TypographyInfo(props: ITypographyInfoProps) {
  const { iconStart, iconEnd, iconStartColor, iconEndColor, text, ...other } =
    props;
  let customStyles = styles(props);

  let prependIcon, appendIcon;
  if (iconStart) {
    prependIcon = (
      <FontAwesomeIcon icon={iconStart} className={customStyles.startIcon} />
    );
  }
  if (iconEnd) {
    appendIcon = (
      <FontAwesomeIcon icon={iconEnd} className={customStyles.endIcon} />
    );
  }
  return (
    <p className={customStyles.text} {...other}>
      {prependIcon}
      {text}
      {appendIcon}
    </p>
  );
}
