import { Grid, Tab, Tabs } from '@material-ui/core';
import TabPanel from 'Components/TabPanel/tab-panel';
import React from 'react';
import {
  GetCustomerListResponseType,
  GetCustomerPermissionsDisabledResponseType,
  GetCustomerRolesResponseType,
} from 'Types/customer-types';
import TabDisabledPermissions from './DisabledPermissions/tab-disabled-permissions';
import TabRoles from './Roles/tab-roles';
import { stylesCustomerPermissions } from './styles';

enum CustomerPermissionsTabsEnum {
  Roles = 1,
  DisabledPermissions = 2,
}

export interface TabsContainerProps {
  selectedCustomer: GetCustomerListResponseType;
  roleList: GetCustomerRolesResponseType[];
  disabledPermissions: GetCustomerPermissionsDisabledResponseType[];
  clientID?: number;
  refreshPage: () => void;
}

const TabsContainer: React.FC<TabsContainerProps> = ({
  selectedCustomer,
  roleList,
  disabledPermissions,
  clientID,
  refreshPage,
}) => {
  const customStyles = stylesCustomerPermissions();

  const [selectedTab, setSelectedTab] = React.useState<
    CustomerPermissionsTabsEnum
  >(CustomerPermissionsTabsEnum.Roles);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };
  return (
    <Grid container>
      <Tabs
        value={selectedTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        className={customStyles.tabs}
      >
        <Tab
          label="Roles"
          classes={{ root: customStyles.tab }}
          value={CustomerPermissionsTabsEnum.Roles}
        />
        <Tab
          label="Disabled permission"
          classes={{ root: customStyles.tab }}
          value={CustomerPermissionsTabsEnum.DisabledPermissions}
        />
      </Tabs>
      <TabPanel
        padding="22px 0"
        style={{ width: '100%' }}
        value={selectedTab}
        index={CustomerPermissionsTabsEnum.Roles}
      >
        <TabRoles
          roleList={roleList}
          clientID={clientID}
          customerID={selectedCustomer.customerID}
          refreshPage={refreshPage}
        />
      </TabPanel>
      <TabPanel
        padding="22px 0"
        style={{ width: '100%' }}
        value={selectedTab}
        index={CustomerPermissionsTabsEnum.DisabledPermissions}
      >
        <TabDisabledPermissions
          disabledPermissions={disabledPermissions}
          customerID={selectedCustomer.customerID}
          refreshPage={refreshPage}
        />
      </TabPanel>
    </Grid>
  );
};

export default TabsContainer;
