import * as React from "react";
import ButtonImage from "Components/Buttons/ButtonImage/button-image";
import SvgCopyReport from "Components/Images/Reports/image-copy-report";
import SvgImportReport from "Components/Images/Reports/image-import-report";
import SvgNewReport from "Components/Images/Reports/image-new-report";
import { AddReportType } from "./types";
import { Autocomplete } from "@material-ui/lab";
import { Grid, TextField } from "@material-ui/core";
import { ReportTypeEnum } from "Common/enum";

interface AddReportTypeNoneProps {
  selectedReportType: ReportTypeEnum;
  setSelectedReportType: React.Dispatch<React.SetStateAction<ReportTypeEnum>>;
  handleNewReport: () => void;
  setAddReportType: React.Dispatch<React.SetStateAction<AddReportType>>;
  setIsBtnSaveVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddReportTypeNone: React.FC<AddReportTypeNoneProps> = ({
  selectedReportType,
  setSelectedReportType,
  handleNewReport,
  setAddReportType,
  setIsBtnSaveVisible,
}) => {
  const handleCopyReport = () => {
    setAddReportType(AddReportType.CopyReport);
    setIsBtnSaveVisible(true);
  };

  const handleImportReport = () => {
    setAddReportType(AddReportType.ImportReport);
    setIsBtnSaveVisible(true);
  };

  const handleReportTypeChange = (
    _event: React.ChangeEvent<{}>,
    value: ReportTypeEnum | null
  ) => {
    setSelectedReportType(value ?? ReportTypeEnum.Report);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} container>
        <Grid item sm={6}>
          <Autocomplete
            options={Object.keys(ReportTypeEnum)
              .filter((k) => !isNaN(Number(k)))
              .map((k) => Number(k))}
            value={selectedReportType}
            getOptionLabel={(option) => ReportTypeEnum[option]}
            renderInput={(params) => (
              <TextField {...params} label="Report Type" variant="standard" />
            )}
            onChange={handleReportTypeChange}
          ></Autocomplete>
        </Grid>
      </Grid>
      <Grid
        item
        sm={12}
        container
        spacing={1}
        justify="space-between"
        alignItems="center"
      >
        <Grid container item sm={4} justify="center">
          <ButtonImage
            icon={<SvgNewReport />}
            handleClick={handleNewReport}
            text="New Report"
            isEnabled={true}
          />
        </Grid>
        <Grid container item sm={4} justify="center">
          {selectedReportType !== ReportTypeEnum.PowerBI && (
            <ButtonImage
              icon={<SvgCopyReport />}
              handleClick={handleCopyReport}
              text="Copy from Report"
              isEnabled={true}
            />
          )}
        </Grid>
        <Grid container item sm={4} justify="center">
          {selectedReportType !== ReportTypeEnum.PowerBI && (
            <ButtonImage
              icon={<SvgImportReport />}
              handleClick={handleImportReport}
              text="Import from File"
              isEnabled={true}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddReportTypeNone;
