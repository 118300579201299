import { IEditReportColumnsProps } from "../interfaces-edit-report";
import * as React from "react";
import MaterialTable, { Icons } from "material-table";
import {
  ArrowDownward,
  Check,
  Clear,
  DeleteOutline,
  Edit,
} from "@material-ui/icons";
import { forwardRef } from "react";
import Colors from "../../../../Common/colors";
import EditReportColumnsListItemEditable from "./edit-report-columns-list-item-editable";
import EditReportColumnsListItem from "./edit-report-columns-list-item";
import { Box, IconButton } from "@material-ui/core";
import SvgMenuButton from "../../../../Components/Images/image-menu-button";
import DefineProcedureForColumnName from "../EditReportParameters/DefineProcedureForColumnName/DefineProcedureForColumnName";

const EditReportColumns: (props: IEditReportColumnsProps) => JSX.Element = (
  props
): JSX.Element => {
  const tableIcons: Icons = {
    //Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    // DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    // Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    // FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    // LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    // NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    // PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    // ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    // Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    //ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    //ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const [selectedRowOrderNo, setSelectedRowOrderNo] = React.useState<
    number | undefined
  >(undefined);
  const [
    openDefineProcedureForColumnName,
    setOpenDefineProcedureForColumnName,
  ] = React.useState(false);

  const handleMouseDown = (orderNo: number) => {
    setSelectedRowOrderNo(orderNo);
  };

  const handleMouseUp = () => {
    if (selectedRowOrderNo !== undefined) {
      setSelectedRowOrderNo(undefined);
    }
  };

  const handleMouseLeave = () => {
    if (selectedRowOrderNo !== undefined) {
      setSelectedRowOrderNo(undefined);
    }
  };
  const handleMouseEnter = (orderNo: number) => {
    if (selectedRowOrderNo) {
      moveOneParam(selectedRowOrderNo, orderNo);
    }
  };
  const moveOneParam = (selected: number, orderNo: number) => {
    const directionDown = selected < orderNo;
    const directionUp = selected > orderNo;

    props.handleChangeColumns(
      props.data
        .map((p) =>
          p.orderNo === selected
            ? {
                ...p,
                orderNo,
              }
            : directionUp && p.orderNo >= orderNo && p.orderNo < selected
            ? { ...p, orderNo: p.orderNo + 1 }
            : directionDown && p.orderNo <= orderNo && p.orderNo > selected
            ? { ...p, orderNo: p.orderNo - 1 }
            : p
        )
        .sort((a, b) => a.orderNo - b.orderNo)
    );
    setSelectedRowOrderNo(orderNo);
  };

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={[
          {
            title: "",
            field: "orderNo",
            sorting: true,
            defaultSort: "asc",
            headerStyle: {
              borderLeft: "none",
            },
            width: "auto",
          },
          {
            title: "Sort",
            field: "sortType",
            width: "auto",
          },
          {
            title: "DB Name",
            field: "databaseName",
            disableClick: true,
            width: "auto",
          },
          {
            title: (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingRight="5px"
              >
                <span>Name</span>
                {props.isEditable && (
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={() => {
                      setOpenDefineProcedureForColumnName(true);
                    }}
                    size="small"
                  >
                    <SvgMenuButton />
                  </IconButton>
                )}
              </Box>
            ),
            width: "auto",
          },
          {
            title: "Data Type",
            field: "dataType",
            width: "auto",
          },
          {
            title: "Source",
            field: "sourceProcedure",
            width: "auto",
          },
          {
            title: "Format",
            field: "dataTypeFormatID",
            width: "auto",
          },
          {
            title: "Alignment",
            field: "alignment",
            width: "auto",
          },
          {
            title: "Visible",
            field: "visible",
            type: "boolean",
            headerStyle: {
              textAlign: "center",
            },
            width: "auto",
          },
          {
            title: "Editable",
            field: "editable",
            type: "boolean",
            headerStyle: {
              textAlign: "center",
            },
            width: "auto",
          },
          {
            title: "Hidable",
            field: "hidable",
            type: "boolean",
            headerStyle: {
              textAlign: "center",
            },
            width: "auto",
          },
          {
            title: "Size",
            field: "size",
            width: "auto",
          },
          {
            title: "Aggregate",
            field: "aggregate",
            width: "auto",
          },
          {
            title: "Wrap",
            field: "wrap",
            type: "boolean",
            headerStyle: {
              textAlign: "center",
            },
            width: "auto",
          },
          {
            title: "Is Master Column",
            field: "isMaster",
            type: "boolean",
            hidden: !props.isMasterDetailsParameters,
            headerStyle: {
              textAlign: "center",
            },
            width: "auto",
          },
        ]}
        data={props.data}
        options={{
          showTitle: false,
          header: true,
          paging: false,
          search: false,
          toolbar: false,
          sorting: false,
          draggable: false,
          headerStyle: {
            color: "#AEAEAE",
            fontSize: "14px",
            borderBottom: `2px solid ${Colors.graySeparate}`,
            borderLeft: `1px solid ${Colors.graySeparate}`,
            padding: "10px 5px 9px 12px",
            whiteSpace: "nowrap",
          },
          tableLayout: "auto",
        }}
        components={{
          Row: (propsRow) => {
            return props.isEditable ? (
              <EditReportColumnsListItemEditable
                key={propsRow.data.parameterID}
                data={propsRow.data}
                list={props.data}
                reportData={props.reportData}
                dictionaries={props.dictionaries}
                handleChangeNewStoredProcedureParam={
                  props.handleChangeNewStoredProcedureParam
                }
                newAddedStoredProcedureParams={
                  props.newAddedStoredProcedureParams
                }
                dataTypeFormatList={props.dataTypeFormatList}
                handleChangeColumns={props.handleChangeColumns}
                handleMouseDown={handleMouseDown}
                handleMouseUp={handleMouseUp}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                selectedRowOrderNo={selectedRowOrderNo}
                isMasterDetailsParameters={props.isMasterDetailsParameters}
              />
            ) : (
              <EditReportColumnsListItem
                key={propsRow.data.parameterID}
                data={propsRow.data}
                list={props.data}
                dataTypeFormatList={props.dataTypeFormatList}
                isMasterDetailsParameters={props.isMasterDetailsParameters}
              />
            );
          },
        }}
        style={{
          background: "white",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
        }}
      />
      {openDefineProcedureForColumnName && (
        <DefineProcedureForColumnName
          isOpen={openDefineProcedureForColumnName}
          close={() => {
            setOpenDefineProcedureForColumnName(false);
          }}
          reportData={props.reportData}
          clickOk={(data) => {
            props.handleChangeReportData(data);
          }}
        />
      )}
    </>
  );
};
export default EditReportColumns;
