import * as React from "react";
import UserFilters from "Components/User/UserFilters/user-filters";
import UserList from "Components/User/UserList/user-list";
import { IUserListContainerProps } from "Components/User/interfaces-user";
import { GetUserListResponseType } from "Types/user-types";
import { filter } from "../UserFilters/utils";
import { UserActiveType } from "Common/enum";

export default function UserListContainer(props: IUserListContainerProps) {
  const [searchValue, setSearchValue] = React.useState("");
  const [companyID, setCompanyID] = React.useState<number>(0);
  const [roleID, setRoleID] = React.useState<number>(0);
  const [activityUser, setActivityUser] = React.useState<UserActiveType>(
    UserActiveType.Active
  );

  const [filteredUserList, setFilteredUserList] =
    React.useState<GetUserListResponseType[]>();

  React.useEffect(() => {
    const userList = filter(
      props.userList,
      searchValue,
      companyID,
      roleID,
      activityUser
    );

    setFilteredUserList(userList);
  }, [
    companyID,
    props.userList,
    roleID,
    searchValue,
    activityUser,
    props.appID,
    props.systemAppSelected,
  ]);

  const handleSearchValueChange = (
    value: React.SetStateAction<string>
  ): void => {
    let searchedText: string = value.toString().toLowerCase();
    searchedText = searchedText ? searchedText : "";

    setSearchValue(searchedText);
  };

  const handleCompanyChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    let selectedCompanyID: number = event.target.value as number;

    setCompanyID(selectedCompanyID);
  };

  const handleRoleChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    let selectedRoleID: number = event.target.value as number;

    setRoleID(selectedRoleID);
  };

  const handleActivityChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const selectedActivity: number = event.target.value as number;
    setActivityUser(selectedActivity);
  };

  return (
    <React.Fragment>
      <UserFilters
        onSearchChange={handleSearchValueChange}
        onCompanyChange={handleCompanyChange}
        systemAppSelected={props.systemAppSelected}
        selectedCompanyID={companyID}
        selectedRoleID={roleID}
        onRoleChange={handleRoleChange}
        roleList={props.roleList}
        selectedActivity={activityUser}
        onActivityChange={handleActivityChange}
      />
      <UserList
        filteredUserList={filteredUserList || []}
        systemAppSelected={props.systemAppSelected}
        appGuid={props.appGuid}
        appID={props.appID}
        getUsers={props.getUsers}
        roleList={props.roleList}
      />
    </React.Fragment>
  );
}
