import { Grid, Paper } from '@material-ui/core';
import Colors from 'Common/colors';
import { IconType } from 'Common/enum';
import ButtonOutlined from 'Components/Buttons/button-outlined';
import SvgPencil from 'Components/Images/image-edit-pencil';
import TypographyInfo from 'Components/Typography/typography-info';
import React from 'react';
import { GetCustomerRolesResponseType } from 'Types/customer-types';
import RoleEditor from './Editor/role-editor';
import RoleList from './List/role-list';
import { stylesTabRoles } from './styles';

export interface TabRolesProps {
  roleList: GetCustomerRolesResponseType[];
  clientID?: number;
  customerID: number;
  refreshPage: () => void;
}

const TabRoles: React.FC<TabRolesProps> = ({
  roleList,
  clientID,
  customerID,
  refreshPage,
}) => {
  const customStyles = stylesTabRoles();

  const [isOpenEditor, setIsOpenEditor] = React.useState<boolean>(false);
  const handleClickEdit = () => {
    setIsOpenEditor(true);
  };
  const handleCloseEditor = () => {
    setIsOpenEditor(false);
  };

  const emptyRoleList = (
    <Paper className={customStyles.paper}>
      <Grid
        container
        item
        alignContent="center"
        alignItems="center"
        justify="center"
      >
        <TypographyInfo
          text={'Company has no assigned roles'}
          color={Colors.black}
          style={{
            fontSize: '14px',
          }}
        />
      </Grid>
    </Paper>
  );
  return (
    <>
      <Grid container direction="column">
        <Grid container item justify="flex-end">
          <ButtonOutlined
            text={'Edit'}
            iconStartType={IconType.SvgImageIcon}
            iconStart={<SvgPencil width={'14px'} />}
            size="md"
            margin="0 0 16px 0"
            handleClick={handleClickEdit}
            backgroundColor={Colors.white}
          />
        </Grid>
        {roleList.length > 0 ? <RoleList roleList={roleList} /> : emptyRoleList}
      </Grid>
      <RoleEditor
        isOpen={isOpenEditor}
        close={handleCloseEditor}
        customerRoles={roleList}
        clientID={clientID}
        customerID={customerID}
        refreshPage={refreshPage}
      />
    </>
  );
};

export default TabRoles;
