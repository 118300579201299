import { makeStyles, Theme } from "@material-ui/core";

export const stylesAccessRightHeader = makeStyles((theme: Theme) => ({
  gridItem: {
    padding: "0 15px",
  },
  selectRole: {
    fontSize: "32px",
    lineHeight: "48px",
    color: theme.palette.grayDarker.main,
  },
}));
