import * as React from 'react';
import { ReportColumnIsWrap } from '../../../../Common/enum';
import InputCheckbox from '../../../Inputs/input-checkbox';
import { GetReportColumnListType } from '../../../../Types/report-types';

export interface CheckboxWrapProps {
  isEditable: boolean;

  data: GetReportColumnListType;
  list: GetReportColumnListType[];
  handleChangeColumns: (columns: GetReportColumnListType[]) => void;
}

const CheckboxWrap: React.FC<CheckboxWrapProps> = ({
  isEditable,
  data,
  list,
  handleChangeColumns,
}) => {
  const handleChangeWrap = (
    event: React.ChangeEvent<HTMLInputElement>,
    columnID: number
  ): void => {
    const wrap: boolean = event.target.checked as boolean;

    handleChangeColumns(
      list.map((p) =>
        p.columnID === columnID
          ? {
              ...p,
              isWrap: wrap
                ? ReportColumnIsWrap.Wrap
                : ReportColumnIsWrap.NotWrap,
            }
          : p
      )
    );
  };

  return (
    <InputCheckbox
      disabled={!isEditable}
      checked={data.isWrap === ReportColumnIsWrap.Wrap}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleChangeWrap(event, data.columnID)
      }
    />
  );
};

export default CheckboxWrap;
