import * as React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { ReportParameterDataType } from './../../../../../../Common/enum';
import { stylesFont } from './../../styles';

export interface SelectParamDataTypeProps {
  dataType: ReportParameterDataType;
  handleChange: (dataType: ReportParameterDataType) => void;
}

const SelectParamDataType: React.FC<SelectParamDataTypeProps> = ({
  dataType,
  handleChange,
}) => {
  const [value, setValue] = React.useState(dataType);
  const customStyles = stylesFont();

  React.useEffect(() => {
    setValue(dataType);
  }, [dataType]);

  const types = Object.keys(ReportParameterDataType).filter(
    (key: any) => !isNaN(Number(key))
  );
  return (
    <FormControl variant='standard' fullWidth>
      <InputLabel>Data type</InputLabel>
      <Select
        value={value}
        classes={{
          root: customStyles.input,
        }}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          setValue(event.target.value as number)
        }
        onBlur={(event: React.ChangeEvent<{ value: unknown }>) =>
          handleChange(value)
        }
      >
        {types.map((key: string) => (
          <MenuItem
            classes={{ root: customStyles.input }}
            value={Number(key)}
            key={key}
            id={key}
          >
            {ReportParameterDataType[Number(key)]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectParamDataType;
