import * as Yup from "yup";

export const validationShape = {
  notificationText: Yup.string().max(1000).required("Required"),
  notificationSmsDistributionListText: Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(";") : [];
    })
    .of(Yup.number().typeError(`There is not a valid number.`))
    .max(500),
  notificationMailDistributionList: Yup.array(Yup.string().email()),
  notificationMailDistributionListText: Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(";") : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email. `))
    .max(500),
};
