import * as React from 'react';
import Colors from 'Common/colors';
import FileUpload from 'Components/File/FileUpload/file-upload';
import { AddReportFormikValuesType, FILE_MAX_SIZE } from './types';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';

interface AddReportImportReportProps {}

const AddReportImportReport: React.FC<AddReportImportReportProps> = () => {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<AddReportFormikValuesType>();
  return (
    <Grid container direction="column">
      <Grid item sm={5}>
        <FileUpload
          file={values.file}
          setFile={(newFile) => setFieldValue('file', newFile)}
          acceptedFormats={['.xml']}
          maxSize={FILE_MAX_SIZE}
          width="427px"
          height="130px"
          text="Import from XML file"
          hideAcceptedFormats={true}
          color={
            errors.file && touched.file && !values.file
              ? Colors.red
              : Colors.gray
          }
        />
      </Grid>
    </Grid>
  );
};

export default AddReportImportReport;
