import * as React from 'react';
import { Grid } from '@material-ui/core';
import SelectParamDBName from './select-param-db-name';
import SelectParamDataType from './select-param-data-type';
import {
  ReportParameterIsRequired,
  ReportParameterDataType,
  IconType,
} from '../../../../../../Common/enum';
import SelectParamDefault from './select-param-default';
import {
  GetReportStoredProcedureParameterType,
  SaveReportStoredProcedureParameterType,
} from '../../../../../../Types/report-types';
import InputCheckbox from '../../../../../../Components/Inputs/input-checkbox';
import ButtonIcon from 'Components/Buttons/button-icon';
import SvgDelete from 'Components/Images/image-delete';

export interface EditParamProps {
  data:
    | GetReportStoredProcedureParameterType
    | SaveReportStoredProcedureParameterType;

  edit: (
    data:
      | GetReportStoredProcedureParameterType
      | SaveReportStoredProcedureParameterType
  ) => void;

  deleteParam: (
    data:
      | GetReportStoredProcedureParameterType
      | SaveReportStoredProcedureParameterType
  ) => void;
}

const EditParam: React.FC<EditParamProps> = ({ data, edit, deleteParam }) => {
  const { isRequired, defaultValueText, dataType, databaseName } = data;

  const handleChangeDatabaseName = (name: string) => {
    edit({ ...data, databaseName: name });
  };

  const handleChangeDefault = (text: string) => {
    edit({ ...data, defaultValueText: text });
  };

  const handleChangeDataType = (dataType: ReportParameterDataType) => {
    edit({ ...data, dataType });
  };

  const handleChangeRequired = (isChecked: boolean) => {
    edit({
      ...data,
      isRequired: isChecked
        ? ReportParameterIsRequired.Required
        : ReportParameterIsRequired.NotRequired,
    });
  };

  return (
    <Grid container direction='column' wrap='nowrap' item spacing={1}>
      <Grid container item>
        <SelectParamDBName
          name={databaseName}
          handleChange={(name: string) => handleChangeDatabaseName(name)}
        />
      </Grid>
      <Grid container item>
        <SelectParamDataType
          dataType={dataType}
          handleChange={(type: ReportParameterDataType) =>
            handleChangeDataType(type)
          }
        />
      </Grid>
      <Grid container item>
        <SelectParamDefault
          text={defaultValueText}
          handleChange={(text: string) => handleChangeDefault(text)}
        />
      </Grid>
      <Grid
        container
        item
        justify='space-between'
        alignContent='center'
        alignItems='center'
      >
        <InputCheckbox
          margin='0 0 0 -10px'
          checked={isRequired === ReportParameterIsRequired.Required}
          text='Required'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeRequired(event.currentTarget.checked)
          }
        />
        <ButtonIcon
          iconType={IconType.SvgImageIcon}
          icon={<SvgDelete />}
          handleClick={() => deleteParam(data)}
          width='20px'
          height='20px'
        />
      </Grid>
    </Grid>
  );
};

export default EditParam;
