import * as React from "react";
import * as Yup from "yup";
import { IResetPasswordFormProps } from "../../../Components/Login/interfaces-login";
import SvgLogo from "../../Images/image-logo";
import { Fragment } from "react";
import { Formik } from "formik";
import { ResetUserPasswordModelType } from "Types/user-types";
import ButtonSolid from "Components/Buttons/button-solid";
import { TextField } from "@material-ui/core";
import { AxiosError } from "axios";
import { ApiResultStatusEnum } from "Common/enum";
import UserApi from "Services/Api/user-api";
import { ResetUserPasswordApiResultType } from "Types/api-types";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";

const userApi = new UserApi();

const PASSWORD_MAX_LENGTH: number = 100;

export default function ResetPasswordForm(props: IResetPasswordFormProps) {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [showError, setShowError] = React.useState<boolean>(false);
  const [showContent, setShowContent] = React.useState<boolean>(false);
  const [showSuccess, setShowSuccess] = React.useState<boolean>(false);

  const verifyResetPasswordCode = React.useCallback(async () => {
    if (props.code) {
      // check if code exists and is not expired
      await userApi
        .verifyUserResetPasswordCode({
          code: props.code,
          appGuid: process.env.REACT_APP_IA_GUID!,
        })
        .then((result) => {
          if (result && result.data) {
            if (
              result.status === ApiResultStatusEnum.Ok &&
              !result.data.isValid
            ) {
              setErrorMessage("This code is invalid or expired.");
              setShowError(true);
            } else {
              setShowContent(true);
            }
          }
        });
    }
  }, [props.code]);

  React.useEffect(() => {
    verifyResetPasswordCode();
  }, [verifyResetPasswordCode, props.code]);

  const handleSubmit = (password: string) => {
    resetUserPassword({
      code: props.code,
      password: password,
      appGuid: process.env.REACT_APP_IA_GUID!,
    })
      .then(() => {})
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      });
  };

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const resetUserPassword = (
    userPassword: ResetUserPasswordModelType
  ): Promise<ResetUserPasswordApiResultType | void> => {
    setShowError(false);
    return userApi.resetUserPassword(userPassword).then((result) => {
      if (
        result &&
        result.status === ApiResultStatusEnum.Ok &&
        result.data.isSaved
      ) {
        setShowSuccess(true);
        setShowContent(false);
      } else {
        setErrorMessage("Error occurred while resetting password");
        setShowError(true);
      }
    });
  };

  return (
    <Fragment>
      <SvgLogo width="auto" height="50px" />

      <br />
      <br />
      {showContent ? (
        <div>
          <div className="title">Reset Password</div>
          <div className="info">
            Please enter new password for your account.
          </div>
          <Formik
            initialValues={{
              password: "",
              repeatPassword: "",
            }}
            onSubmit={async (values) => {
              await handleSubmit(values.password);
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .label("Password")
                .required("Required")
                .min(5, "Password must contain at least 5 characters")
                .max(PASSWORD_MAX_LENGTH)
                .trim(),
              repeatPassword: Yup.string()
                .label("Repeat password")
                .required("Required")
                .oneOf([Yup.ref("password")], "Password does not match")
                .min(5, "Password must contain at least 5 characters")
                .max(PASSWORD_MAX_LENGTH)
                .trim(),
            })}
          >
            {(formProps) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
              } = formProps;
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(errors.password && touched.password)}
                    helperText={
                      (errors.password &&
                        touched.password &&
                        errors.password) ||
                      `${values.password.length}/${PASSWORD_MAX_LENGTH}`
                    }
                    inputProps={{
                      maxLength: PASSWORD_MAX_LENGTH,
                    }}
                    type="password"
                    fullWidth
                    required
                    margin="normal"
                  />
                  <TextField
                    id="repeatPassword"
                    name="repeatPassword"
                    label="Repeat password"
                    value={values.repeatPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(errors.repeatPassword && touched.repeatPassword)}
                    helperText={
                      (errors.repeatPassword &&
                        touched.repeatPassword &&
                        errors.repeatPassword) ||
                      `${values.repeatPassword.length}/${PASSWORD_MAX_LENGTH}`
                    }
                    inputProps={{
                      maxLength: PASSWORD_MAX_LENGTH,
                    }}
                    type="password"
                    fullWidth
                    required
                    margin="normal"
                  />
                  <div className="errorInfo">
                    {showError ? errorMessage : ""}
                  </div>
                  <div className="buttons">
                    <ButtonSolid margin="10px" text="Save password" />
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      ) : !showSuccess ? (
        <div className="invalidCode">
          <div className="title">Reset Password</div>
          <div className="errorInfo">{showError ? errorMessage : ""}</div>
          <div className="link">
            <a href="/forgot-password">Go to Forgot password</a>
          </div>
        </div>
      ) : (
        <div className="invalidCode">
          <div className="link">
            Password was reset successfully.
            <br />
            <br />
            <a href="/">Back to login page</a>
          </div>
        </div>
      )}
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </Fragment>
  );
}
