import * as React from 'react';
import ButtonOutlined from 'Components/Buttons/button-outlined';
import SvgPlusCircle from 'Components/Images/image-plus-circle';
import TypographyTitle from 'Components/Typography/typography-title';
import { GetUserListCustomerResponseType } from 'Types/user-types';
import { Grid } from '@material-ui/core';
import { IApplicationUserHeaderProps } from 'Components/User/interfaces-user';
import { IconType } from 'Common/enum';
import { stylesUsersHeader } from 'Components/User/ApplicationUserHeader/styles';

export default function ApplicationUsersHeader(
  props: IApplicationUserHeaderProps
) {
  const [firstName, setFirstName] = React.useState<string>(
    props.user ? props.user.firstName : ""
  );
  const [lastName, setLastName] = React.useState<string>("");
  const [userCustomers, setUserCustomers] = React.useState<
    GetUserListCustomerResponseType[]
  >([]);

  let customStyles = stylesUsersHeader();

  // const setUserData = async (
  //   user: SaveUserModelType,
  //   customersRoles: GetUserListCustomerResponseType[]
  // ) => {
  //   setFirstName(user.firstName);
  //   setLastName(user.lastName);
  //   setUserCustomers(
  //     customersRoles.map((c) => ({
  //       ...c,
  //       customerName:
  //         props.customerList.find((cust) => cust.customerID === c.customerID)
  //           ?.name ?? c.customerName,
  //       roleName:
  //         props.roleList?.find((cust) => cust.roleID === c.roleID)?.name ??
  //         c.roleName,
  //     }))
  //   );
  // };

  React.useEffect(() => {
    if (props.user) {
      setFirstName(props.user.firstName);
      setLastName(props.user.lastName);
      setUserCustomers(
        props.user.customers.map((c) => ({
          ...c,
          customerName:
            props.customerList.find((cust) => cust.customerID === c.customerID)
              ?.name ?? c.customerName,
          roleName:
            props.roleList?.find((cust) => cust.roleID === c.roleID)?.name ??
            c.roleName,
        }))
      );
    }
  }, [props.user, props.client.id, props.roleList, props.customerList]);

  const createNewUserLogin = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    props.handleCreateNewUserLogin();
  };

  return (
    <Grid container alignItems="center">
      <Grid container item xs={10} alignItems="center">
        <Grid item className={customStyles.gridItem}>
          {props.user && (
            <TypographyTitle
              text={`${firstName} ${lastName}`}
              fontSize="20px"
              margin="10px 0 10px 10px"
            />
          )}
        </Grid>
      </Grid>
      <Grid container item xs={2} alignItems="center" justify="flex-end">
        <Grid item className={customStyles.gridItem}>
          <ButtonOutlined
            text={"Add login"}
            iconStartType={IconType.SvgImageIcon}
            iconStart={<SvgPlusCircle width={"14px"} />}
            size={"md"}
            handleClick={createNewUserLogin}
          />
        </Grid>
      </Grid>
      {/* <Grid container item xs={1} alignItems="center" justify="flex-end">
        <Grid item className={customStyles.gridItem}>
          <UserEditor
            appGuid={props.appGuid}
            editorType={FormEditorEnum.Edit}
            user={{
              userID: props.user ? props.user.userID : 0,
              firstName: firstName,
              lastName: lastName,
              email: props.user ? props.user.email : "",
              canDelete: props.user ? props.user.canDelete : false,
              customers: userCustomers,
              hasLogins: props.user ? props.user?.hasLogins : false,
            }}
            getUsers={null}
            setUserData={setUserData}
            systemAppSelected={false}
            showInfoMessage={props.showInfoMessage}
          />
        </Grid>
      </Grid> */}
    </Grid>
  );
}
