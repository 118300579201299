import * as React from 'react';
import CustomerDealerAccountList from './customer-dealer-account-list';
import { DealerCustomersType } from 'Types/customer-types';

export interface ICustomerDealerAccountsProps {
  dealerAccountList: DealerCustomersType[];
}

const CustomerDealerAccountListContainer: (
  props: ICustomerDealerAccountsProps
) => JSX.Element = (props: ICustomerDealerAccountsProps): JSX.Element => (
  <CustomerDealerAccountList
    dealerAccountList={props.dealerAccountList ?? []}
  />
);

export default CustomerDealerAccountListContainer;
