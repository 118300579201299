import * as React from "react";
import { Paper, Grid } from "@material-ui/core";
import TypographyInfo from "Components/Typography/typography-info";
import TypographySubinfo from "Components/Typography/typography-subinfo";
import moment from "moment";
import {
  faCalendar,
  faDiceD6,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { IDeviceListItemProps } from "Components/Device/interfaces-device";
import { stylesDeviceListItem } from "Components/Device/DeviceList/styles";

export default function DeviceListItem(props: IDeviceListItemProps) {
  const customStyles = stylesDeviceListItem({});
  const { device } = props;

  return (
    <tr>
      <td>
        <Paper className={customStyles.paper}>
          <Grid container alignItems="center">
            <Grid container item xs={3}>
              <Grid container item xs={12} className={customStyles.gridItem}>
                <Grid item sm={12}>
                  <TypographyInfo text={device.name} />
                </Grid>
                <Grid item sm={12}>
                  <TypographySubinfo text={device.serialNo} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item sm={9}>
              <Grid item sm={2}>
                <TypographySubinfo
                  text={moment(device.lastContactDateUTC).format("DD/MM/YYYY")}
                  iconStart={faCalendar}
                />
              </Grid>
              <Grid item sm={2}>
                <TypographySubinfo text={device.osInfo} iconStart={faDiceD6} />
              </Grid>
              <Grid item sm={6}>
                <TypographySubinfo
                  text={`Release ${device.mobileAppVersionNo}`}
                  iconStart={faMobileAlt}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </td>
    </tr>
  );
}
