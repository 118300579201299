import { makeStyles } from "@material-ui/core";
import Colors from "Common/colors";

export const stylesListItemBase = makeStyles({
  paper: {
    padding: "10px 30px",
    marginBottom: "15px",
  },
  leftItem: {
    borderRight: `1px solid ${Colors.graySeparate}`,
    fontSize: "18px",
  },
});
