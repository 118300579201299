import * as React from "react";
import { stylesFiltersCard } from "./styles";
import { FilterCardProps } from "./interface";

const FilterCard: (props: FilterCardProps) => JSX.Element = (
  props: FilterCardProps
): JSX.Element => {
  const customStyles = stylesFiltersCard();
  return <div className={customStyles.filters}>{props.children}</div>;
};

export default FilterCard;
