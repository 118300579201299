import * as React from 'react';
import WebAppMainListItem from './web-app-main-list-item';
import { IWebAppMainListProps } from '../interfaces-web-app';
import { List, ListItem, Divider } from '@material-ui/core';
import { stylesWebAppCard } from '../WebAppCard/styles';

export default function WebAppMainList(props: IWebAppMainListProps) {
  let customStyles = stylesWebAppCard({});

  return (
    <List disablePadding>
      {props.instanceList.map((instance) => {
        return (
          <React.Fragment key={instance.id}>
            <ListItem classes={{ gutters: customStyles.listItem }}>
              <WebAppMainListItem
                instance={instance}
                platformType={props.platformType}
              />
            </ListItem>
            <Divider variant="fullWidth" />
          </React.Fragment>
        );
      })}
    </List>
  );
}
