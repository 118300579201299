import * as React from "react";
import {
  GetReportParameterListType,
  GetReportDataTypeFormatListApiResponseType,
} from "../../../../Types/report-types";
import MenuIcon from "@material-ui/icons/Menu";
import CheckboxRequired from "./checkbox-required";
import CheckboxVisible from "./checkbox-visible";
import { IconButton } from "@material-ui/core";
import Colors from "../../../../Common/colors";
import {
  ReportParameterDataType,
  ReportParameterEnterType,
  ReportParameterDefaultValueType,
  ReportParameterFormatType,
  ReportParameterDatabaseDirection,
} from "Common/enum";
import { stylesEditReportParametersListItem } from "./styles";
import {
  isSecondParamDataRange,
  isFirstParamDataRange,
} from "./edit-report-parameters";
import { sourceName } from "./select-source-procedure";

export interface EditReportParametersListItemProps {
  data: GetReportParameterListType;
  list: GetReportParameterListType[];

  dataTypeFormatList: GetReportDataTypeFormatListApiResponseType[];
}

const EditReportParametersListItem: React.FC<EditReportParametersListItemProps> = ({
  data,
  list,
  dataTypeFormatList,
}) => {
  const customStyles = stylesEditReportParametersListItem();
  const formatListForDataType =
    dataTypeFormatList?.filter((format) => format.dataType === data.dataType) ??
    [];

  const firstParamStyle: React.CSSProperties = {
    position: "relative",
    top: "30px",
  };
  const tdStyle: React.CSSProperties = isSecondParamDataRange(data, list)
    ? {
        borderTop: "none",
      }
    : {};
  const style: React.CSSProperties = isFirstParamDataRange(data, list)
    ? firstParamStyle
    : {};
  const styleRow: string = `${customStyles.row}`;
  return (
    <tr className={styleRow}>
      <td style={{ padding: 0 }} align="center">
        <IconButton disabled={true} style={{ padding: "12px 0" }}>
          <MenuIcon htmlColor={Colors.graySeparate} />
        </IconButton>
      </td>
      <td className={customStyles.disable}>
        {data.databaseName}
        <div style={{ ...style }}>
          {!isSecondParamDataRange(data, list)
            ? ReportParameterDataType[data.dataType]
            : ""}
        </div>
      </td>
      <td>{data.displayName}</td>
      <td
        style={{
          ...tdStyle,
        }}
      >
        {!isSecondParamDataRange(data, list) ? (
          <div style={{ ...style }}>
            {data.defaultValueType ===
            ReportParameterDefaultValueType.DictionaryItem
              ? data.reportDictionaryItemDefaultValue ?? ""
              : data.defaultValueText}
          </div>
        ) : (
          ""
        )}
      </td>
      <td style={{ textAlign: "center" }}>
        <CheckboxVisible
          data={data}
          list={[]}
          isEditable={false}
          handleChangeParameters={() => {}}
        />
      </td>
      <td
        style={{
          ...tdStyle,
        }}
      >
        {!isSecondParamDataRange(data, list) ? (
          <div style={{ ...style }}>
            {ReportParameterEnterType[data.enterType]}
          </div>
        ) : (
          ""
        )}
      </td>
      <td
        style={{
          ...tdStyle,
        }}
        className={
          data.enterType === ReportParameterEnterType.Text
            ? customStyles.disable
            : ""
        }
      >
        {data.enterType !== ReportParameterEnterType.Text &&
        !isSecondParamDataRange(data, list) ? (
          <div style={{ ...style }}>{sourceName(data)}</div>
        ) : (
          ""
        )}
      </td>
      <td
        style={{
          ...tdStyle,
        }}
        className={
          formatListForDataType.length === 0 ? customStyles.disable : ""
        }
      >
        {formatListForDataType.length > 0 &&
        !isSecondParamDataRange(data, list) ? (
          <div style={{ ...style }}>
            {data.formatType === ReportParameterFormatType.DataTypeFormat
              ? data.dataTypeFormatText
              : data.formatType === ReportParameterFormatType.Custom
              ? data.formatValueText
              : ""}
          </div>
        ) : (
          ""
        )}
      </td>
      {/* <td>
        {ReportParameterAlignment[data.alignment]}
      </td> */}
      <td>{data.columnNo}</td>
      <td
        style={{
          ...tdStyle,
        }}
      >
        <div style={{ ...style }}>
          {ReportParameterDatabaseDirection[data.databaseDirection]}
        </div>
      </td>
      <td style={{ textAlign: "center" }}>
        <CheckboxRequired
          data={data}
          list={[]}
          isEditable={false}
          handleChangeParameters={() => {}}
        />
      </td>
    </tr>
  );
};

export default EditReportParametersListItem;
