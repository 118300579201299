import * as React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IButtonTransparentProps } from "./interfaces";
import { IconType } from "Common/enum";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

export default function ButtonTransparent(props: IButtonTransparentProps) {
  let styles = makeStyles({
    button: {
      background: "transparent",
      border: "none",
      borderRadius: "7px",
      padding: props.padding ?? "3px 20px",
      textTransform: "none",
      margin: props.margin ? props.margin : "0",
    },
    buttonSmall: {
      background: "transparent",
      border: "none",
      borderRadius: "7px",
      padding: props.padding ?? "2px 15px",
      fontSize: "10px",
      textTransform: "none",
      margin: props.margin ? props.margin : "0",
    },
    buttonLarge: {
      background: "transparent",
      border: "none",
      borderRadius: "7px",
      padding: props.padding ?? "5px 25px",
      fontSize: "20px",
      textTransform: "none",
      margin: props.margin ? props.margin : "0",
    },
  });

  let customStyles = styles({});
  let startIcon, endIcon;
  let styleName: string;
  switch (props.iconStartType) {
    case IconType.FontAwesomeIcon:
      startIcon = <FontAwesomeIcon icon={props.iconStart as IconDefinition} />;
      break;
    case IconType.SvgImageIcon:
      startIcon = props.iconStart;
      break;
    default:
      startIcon = undefined;
      break;
  }

  switch (props.iconEndType) {
    case IconType.FontAwesomeIcon:
      endIcon = <FontAwesomeIcon icon={props.iconEnd as IconDefinition} />;
      break;
    case IconType.SvgImageIcon:
      endIcon = props.iconEnd;
      break;
    default:
      endIcon = undefined;
      break;
  }

  switch (props.size) {
    case "sm":
      styleName = customStyles.buttonSmall;
      break;
    case "lg":
      styleName = customStyles.buttonLarge;
      break;
    default:
      styleName = customStyles.button;
      break;
  }

  return (
    <Button
      className={styleName}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={props.handleClick}
    >
      {props.text}
      {props.children}
    </Button>
  );
}
