import * as React from 'react';

const SvgPlusCircle = ({
  style = {},
  fill = 'none',
  width = '100%',
  className = '',
  viewBox = '0 0 14 14',
  color = 'black',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <circle cx="7" cy="7" r="6.5" stroke={color} />
    <path d="M7 3.5V10.5" stroke={color} />
    <path d="M10.5 7L3.5 7" stroke={color} />
  </svg>
);

export default SvgPlusCircle;
