import * as React from "react";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { stylesDateField } from "../styles";
import DateFnsUtils from "@date-io/date-fns";
import SvgCalendar from "Components/Images/image-calendar";

const DateField: (props: KeyboardDatePickerProps) => JSX.Element = (
  props: KeyboardDatePickerProps
): JSX.Element => {
  const customStyles: Record<string, string> = stylesDateField(props);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        className={customStyles.root}
        keyboardIcon={<SvgCalendar />}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateField;
