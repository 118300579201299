import * as React from "react";
import { IAccessRightHeaderProps } from "Components/AccessRight/interfaces-access-right";
import { stylesAccessRightHeader } from "Components/AccessRight/AccessRightHeader/styles";
import { Grid, Select, MenuItem } from "@material-ui/core";
import ButtonSubaction from "Components/Buttons/ButtonSubaction/button-subaction";
import HeaderCard from "Components/HeaderCard/header-card";
import { convertShareModeToSymbol } from "Common/utils";

const AccessRightHeader: (props: IAccessRightHeaderProps) => JSX.Element = (
  props: IAccessRightHeaderProps
): JSX.Element => {
  const customStyles = stylesAccessRightHeader();

  return (
    <HeaderCard>
      <Grid container alignItems="center">
        <Grid container item xs={9} alignItems="center">
          <Grid item className={customStyles.gridItem}>
            <Select
              value={props.selectedRoleId}
              onChange={props.handleRoleChange}
              className={customStyles.selectRole}
              disableUnderline
            >
              {props.roleList.map((role) => (
                <MenuItem key={role.roleID} value={role.roleID.toString()}>
                  {`${role.name} (${convertShareModeToSymbol(role.shareMode)})`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container item xs={3} alignItems="center" justify="flex-end">
          <Grid item className={customStyles.gridItem}>
            <ButtonSubaction
              text="Save"
              disabled={!props.isChanged}
              handleClick={props.handleSaveClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </HeaderCard>
  );
};

export default AccessRightHeader;
