import { Select, SelectProps } from "@material-ui/core";
import React from "react";
import { stylesSelect } from "./styles";

const StyledSelect: (props: SelectProps) => JSX.Element = (
  props: SelectProps
): JSX.Element => {
  const classes = stylesSelect();
  return (
    <Select
      {...props}
      classes={{
        root: classes.root,
      }}
    />
  );
};

export default StyledSelect;
