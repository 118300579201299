import { makeStyles } from "@material-ui/core";

export const stylesFileDownload = makeStyles({
    button: {
        bottom: '5px',
        width: '100%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        width: '100%',
        position: 'relative'
    },
    fileInfo: {
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute'
    }
})