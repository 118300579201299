import * as React from 'react';
import { Fab, makeStyles } from '@material-ui/core';
import { IButtonDeleteProps } from '../interfaces';
import SvgDelete from 'Components/Images/image-delete';

const styles = makeStyles({
  buttonImg: (props: IButtonDeleteProps) => ({
    background: 'transparent',
    height: props.buttonHeight ? props.buttonHeight : '30px',
    minHeight: props.buttonHeight ? props.buttonHeight : '30px',
    width: props.buttonWidth ? props.buttonWidth : '30px',
    boxShadow: 'none',
  }),
});

export default function ButtonDelete(props: IButtonDeleteProps) {
  let customStyles = styles(props);

  return (
    <Fab className={customStyles.buttonImg} onClick={props.handleClick}>
      <SvgDelete width={props.iconWidth} />
    </Fab>
  );
}
