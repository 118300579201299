import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { IAppEditorComponentRoleProps } from "./interfaces";

const AppEditorComponentRole: (
  props: IAppEditorComponentRoleProps
) => JSX.Element = (props: IAppEditorComponentRoleProps): JSX.Element => {
  return (
    <FormControl fullWidth size="small" onChange={props.handleChange}>
      <InputLabel id="app-role-select-id">Default role</InputLabel>
      <Select
        name="roleId"
        fullWidth
        labelId="app-role-select-id"
        value={props.value}
        onChange={props.handleChange}
      >
        {props.roleList &&
          props.roleList.map((role) => (
            <MenuItem key={role.roleID} value={role.roleID}>
              {role.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default AppEditorComponentRole;
