import * as React from "react";

const SvgExportXml = ({
    style = {},
    fill = "none",
    width = "10",
    height = "14",
    className = "",
    color = "#444444",
    viewBox = "0 0 10 14"
  }) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H6C7.933 0.5 9.5 2.067 9.5 4V12C9.5 12.8284 8.82843 13.5 8 13.5H2C1.17157 13.5 0.5 12.8284 0.5 12V2Z" stroke={color} />
        <path d="M6 0V3C6 3.55228 6.44772 4 7 4H10" stroke={color} />
    </svg>
);

export default SvgExportXml;