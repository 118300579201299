import * as React from "react";
import TypographyTitle from "Components/Typography/typography-title";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, useTheme } from "@material-ui/core";
import { IEditReportHeaderProps } from "../interfaces-edit-report";
import { stylesEditReportHeader } from "./styles";

const EditReportHeader: (props: IEditReportHeaderProps) => JSX.Element = ({
  reportName,
  procedureName,
  apiName,
}): JSX.Element => {
  const customStyles = stylesEditReportHeader();
  const theme = useTheme();

  return (
    <Grid container alignItems="center" className={customStyles.container}>
      <Grid container item xs={8} direction="column" spacing={1}>
        <Grid item>
          <TypographyTitle
            text={reportName}
            color={theme.palette.grayDarker.main}
            margin="0"
            useTruncate={true}
            fontSize="20px"
          />
        </Grid>
        {(procedureName.trim().length > 0 || apiName.trim().length > 0) && (
          <Grid item>
            <span
              className={customStyles.subinfo}
              style={{ marginRight: "10px" }}
            >
              {apiName}
            </span>
            <FontAwesomeIcon
              icon={faCircle}
              style={{ width: "5px" }}
              color={theme.palette.graySeparate.main}
            />
            <span
              style={{ marginLeft: "10px" }}
              className={customStyles.subinfo}
            >
              {procedureName}
            </span>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default EditReportHeader;
