import * as Yup from 'yup';
import { FormEditorEnum, UserType } from 'Common/enum';
import { IUserEditorProps } from 'Components/User/interfaces-user';
import {
  EMAIL_MAX_LENGTH,
  NAME_MAX_LENGTH,
  PASSWORD_MAX_LENGTH,
} from 'Common/consts';

export const initialValues = (props: IUserEditorProps) => {
  return {
    userID: props.user?.userID,
    firstName: props.user?.firstName ?? '',
    lastName: props.user?.lastName ?? '',
    email: props.user?.email ?? '',
    password: '',
    userType: props.systemAppSelected ? UserType.Admin : UserType.Client,
    appGuid: props.appGuid!,
  };
};

export const validationSchema = (props: IUserEditorProps) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .label('First name')
      .required('Required')
      .max(NAME_MAX_LENGTH)
      .trim(),
    lastName: Yup.string()
      .label('Last name')
      .required('Required')
      .max(NAME_MAX_LENGTH)
      .trim(),
    email: props.systemAppSelected
      ? Yup.string()
          .label('Email')
          .required('Required')
          .max(EMAIL_MAX_LENGTH)
          .email()
          .trim()
      : Yup.string(),
    password:
      props.editorType === FormEditorEnum.Create && props.systemAppSelected
        ? Yup.string()
            .label('Password')
            .required('Required')
            .min(5, 'Password must contain at least 5 characters')
            .max(PASSWORD_MAX_LENGTH)
            .trim()
        : Yup.string(),
  });
};
