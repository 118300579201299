import * as React from "react";
import { Link } from "react-router-dom";
import { Paper, Grid, Divider, Link as MaterialLink } from "@material-ui/core";
import { IWebAppCardProps } from "../interfaces-web-app";
import { AppPlatformEnum, IconType } from "Common/enum";
import { stylesWebAppCard } from "./styles";
import ButtonIcon from "Components/Buttons/button-icon";
import TypographyTitle from "Components/Typography/typography-title";
import WebAppMainList from "Components/WebApp/WebAppMainList/web-app-main-list";
import ImageAppLogo from "Components/Image/ImageAppLogo/image-app-logo";
import SvgDetails from "Components/Images/image-details";
import TypographySubinfo from "Components/Typography/typography-subinfo";

export default function WebAppCard(props: IWebAppCardProps) {
  let customStyles = stylesWebAppCard({});
  const [isAppLogoLoaded, setIsAppLogoLoaded] = React.useState<boolean>(true);

  return (
    <Paper
      className={customStyles.paper}
      style={props.isNotResponding ? { border: "1px solid red" } : {}}
    >
      <Grid container spacing={1}>
        <Grid container item>
          {props.webApp.logoFile?.guid && isAppLogoLoaded ? (
            <Grid
              item
              xs={2}
              container
              alignContent="center"
              alignItems="center"
            >
              <ImageAppLogo
                guid={props.webApp.logoFile?.guid}
                width="45px"
                height="45px"
                setAppLogoNotLoaded={() => setIsAppLogoLoaded(false)}
              />
            </Grid>
          ) : (
            ""
          )}
          <Grid
            item
            xs={props.webApp.logoFile?.guid && isAppLogoLoaded ? 8 : 10}
          >
            <TypographyTitle
              text={props.webApp.name}
              margin="0"
              style={{ fontSize: "28px" }}
            />
          </Grid>
          <Grid item xs={2} className={customStyles.itemIcon}>
            <Link to={`/web-application/${props.webApp.id}`}>
              <ButtonIcon
                iconType={IconType.SvgImageIcon}
                icon={<SvgDetails />}
                width="30px"
                height="30px"
              />
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" className={customStyles.divider} />
          <TypographySubinfo text={`GUID: ${props.webApp.guid}`} />
          <Divider variant="fullWidth" className={customStyles.divider} />
        </Grid>
        {props.webApp.documentationURL && (
          <Grid item xs={12}>
            <MaterialLink
              href={props.webApp.documentationURL}
              target="blank"
              style={{ fontSize: "12px" }}
            >
              Swagger
            </MaterialLink>
            <Divider variant="fullWidth" className={customStyles.divider} />
          </Grid>
        )}
        <Grid item xs={12}>
          {(props.webApp.platformType !== AppPlatformEnum.WebService ||
            props.webApp.instanceList.length > 1) && (
            <WebAppMainList
              instanceList={props.webApp.instanceList ?? []}
              platformType={props.webApp.platformType}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
