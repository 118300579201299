import { makeStyles, Theme } from "@material-ui/core";

export const stylesEditReportGeneral = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "white",
    width: "100%",
    padding: "8px",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 4px",
    borderRadius: "5px",
    margin: 0,
  },
  labelNoOfColumns: {
    width: "230px",
  },
  reportType: {
    background: theme.palette.grayLight.main,
    border: `1px solid ${theme.palette.graySeparate.main}`,
    borderRadius: "4px",
    width: "max-content",
    padding: "4px 10px !important",
    margin: "12px 12px 2px 12px",
  },
}));
