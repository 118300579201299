import * as React from "react";
import { stylesHeaderCard } from "./style";
import { Paper } from "@material-ui/core";
import { HeaderCardProps } from "./interface";

const HeaderCard: (props: HeaderCardProps) => JSX.Element = (
  props: HeaderCardProps
): JSX.Element => {
  const customStyles = stylesHeaderCard();
  return <Paper className={customStyles.paper}>{props.children}</Paper>;
};

export default HeaderCard;
