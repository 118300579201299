import * as React from "react";
import { IWebAppInstanceDeploymentListProps } from "../interfaces";
import MaterialTable from "material-table";
import WebAppInstanceDeploymentListItem from "./web-app-instance-deployment-list-item";

const WebAppInstanceDeploymentList: (
  props: IWebAppInstanceDeploymentListProps
) => JSX.Element = (props: IWebAppInstanceDeploymentListProps): JSX.Element => {
  return (
    <MaterialTable
      columns={[]}
      data={props.deploymentList}
      options={{
        showTitle: false,
        header: false,
        paging: false,
        search: false,
        toolbar: false,
      }}
      components={{
        Row: (prop) => (
          <WebAppInstanceDeploymentListItem
            item={prop.data}
            getInstanceDeploymentList={props.getInstanceDeploymentList}
          />
        ),
      }}
      style={{
        background: "transparent",
        boxShadow: "none",
      }}
    />
  );
};

export default WebAppInstanceDeploymentList;
