import * as React from 'react';
import InputCheckbox from 'Components/Inputs/input-checkbox';
import TypographyInfo from 'Components/Typography/typography-info';
import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { ItemType } from './types';

interface MultiSelectItemsProps {
  selectedItems: number[];
  itemList: ItemType[];
  onSelectedChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  isSelectedAll: boolean;
  labelName: string;
  helperText?: string | false;
  error?: boolean;
  fullWidth?: boolean;
  className?: string;
  renderValue?: string;
}
export const MultiSelectItems: React.FC<MultiSelectItemsProps> = ({
  selectedItems,
  onSelectedChange,
  helperText,
  isSelectedAll,
  itemList,
  error,
  fullWidth,
  className,
  labelName,
  renderValue,
}) => {
  return (
    <FormControl className={className} error={error} fullWidth={fullWidth}>
      <InputLabel>{labelName}</InputLabel>
      <Select
        multiple
        value={selectedItems}
        onChange={onSelectedChange}
        renderValue={(selected) =>
          isSelectedAll
            ? 'All'
            : renderValue ??
              selectedItems
                .map((a) => itemList.find((aa) => aa.id === a)?.name ?? '')
                .join(', ')
        }
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        fullWidth={fullWidth}
      >
        <MenuItem value={0}>
          <span style={{ width: '40px' }}>
            <InputCheckbox checked={isSelectedAll} />
          </span>
          <ListItemText primary={<TypographyInfo text="Select all" />} />
        </MenuItem>
        <Divider variant="middle" />
        {itemList.map((item) => (
          <MenuItem key={item.id!} value={item.id!}>
            <span style={{ width: '40px' }}>
              <InputCheckbox
                checked={selectedItems.some((id) => id === item.id)}
              />
            </span>
            <ListItemText primary={<TypographyInfo text={item.name} />} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
export default MultiSelectItems;
