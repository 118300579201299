/* eslint-disable array-callback-return */
import * as React from "react";
import { IAccessRightsProps } from "Components/AccessRight/interfaces-access-right";
import { Container, Tabs, Tab } from "@material-ui/core";
import AccessRightHeader from "Components/AccessRight/AccessRightHeader/access-right-header";
import AccessRightListContainer from "Components/AccessRight/AccessRightList/access-right-list-container";
import AccessRightsListReportsContainer from "Components/AccessRight/AccessRightsListReports/access-rights-list-reports-container";
import RoleApi from "Services/Api/role-api";
import ReportApi from "Services/Api/report-api";
import { head } from "lodash";
import {
  GetRolesApiResultType,
  GetPermissionGroupsForRoleApiResultType,
  GetReportsForRoleApiResultType,
} from "Types/api-types";
import {
  GetRolesApiResponseType,
  SaveRolePermissionsType,
} from "Types/role-types";
import { GetPermissionGroupsForRoleApiResponseType } from "Types/permission-types";
import {
  GetReportsForRoleApiResponseType,
  SaveReportsForRoleModelType,
} from "Types/report-types";
import {
  SetAllValuesEnum,
  AccessRightsDefaultTab,
  LandingPageEnum,
  ParamType,
} from "Common/enum";
import ProgressSpinner from "Components/ProgressSpinner/progress-spinner";
import { AxiosError } from "axios";
import TabPanel from "Components/TabPanel/tab-panel";
import { stylesAccessRights } from "Components/AccessRight/styles";
import {
  getSavedPageParamValue,
  savePageParam,
} from "Configuration/settings-helper";
import ErrorDialog from "Components/Dialogs/ErrorDialog/error-dialog";

const roleApi = new RoleApi();
const reportApi = new ReportApi();

const AccessRights: (props: IAccessRightsProps) => JSX.Element = (
  props: IAccessRightsProps
): JSX.Element => {
  const [isBlockedUi, setIsBlockedUi] = React.useState(false);
  const [
    isChangedPermissions,
    setIsChangedPermissions,
  ] = React.useState<boolean>(false);
  const [isChangedReports, setIsChangedReports] = React.useState<boolean>(
    false
  );
  const [roles, setRoles] = React.useState<GetRolesApiResponseType[]>([]);
  const [selectedRoleId, setSelectedRoleId] = React.useState<string>("");
  const [permissionGroups, setPermissionGroups] = React.useState<
    GetPermissionGroupsForRoleApiResponseType[]
  >([]);
  const [reportContainers, setReportContainers] = React.useState<
    GetReportsForRoleApiResponseType[]
  >([]);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState<AxiosError | null>(
    null
  );
  const [selectedTab, setSelectedTab] = React.useState(0);

  const permissionsTab: number = 0;
  const reportsTab: number = 1;

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const customStyles = stylesAccessRights();

  const getRoles = React.useCallback(async (): Promise<void> => {
    setIsBlockedUi(true);
    await roleApi
      .getRoles(props.client.id ?? undefined)
      .then(async (result: GetRolesApiResultType) => {
        if (result && result.data) {
          setRoles(result.data);
          let roleID: string | undefined = head(result.data)?.roleID.toString();

          const savedRoleID = getSavedPageParamValue(
            LandingPageEnum.AccessRights,
            ParamType.SelectedRole
          );
          if (savedRoleID) {
            roleID = savedRoleID;
          }

          if (roleID) {
            setSelectedRoleId(roleID);
            await getPermissionGroups(roleID);
            await getReportContainers(roleID);
          }
        }
      })
      .finally(() => {
        setIsBlockedUi(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.client.id]);

  React.useEffect(() => {
    getRoles();
  }, [getRoles]);

  const handleRoleChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ): Promise<void> => {
    let roleId: string = event.target.value as string;
    await getPermissionGroups(roleId);
    await getReportContainers(roleId);
    setSelectedRoleId(roleId);

    savePageParam({
      page: LandingPageEnum.AccessRights,
      paramType: ParamType.SelectedRole,
      value: roleId,
    });
  };

  let permissions: GetPermissionGroupsForRoleApiResponseType[] = [
    ...permissionGroups,
  ];

  const getPermissionGroups = async (roleId: string): Promise<void> => {
    setIsBlockedUi(true);
    await roleApi
      .getPermissionGroupsForRole(roleId)
      .then((result: GetPermissionGroupsForRoleApiResultType) => {
        if (result && result.data) {
          setPermissionGroups(result.data);
        } else {
          setPermissionGroups([]);
        }
      })
      .finally(() => {
        setIsBlockedUi(false);
        setIsChangedPermissions(false);
      });
  };

  const handlePermissionValueChange = (
    permissionId: number,
    value: string
  ): void => {
    if (permissions.length === 0) {
      permissions = [...permissionGroups];
    }
    permissions.map((permissionGroup) =>
      permissionGroup.permissions.map((permission) => {
        if (permission.permissionID === permissionId) {
          permission.permissionValues.map((permissionValue) => {
            if (permissionValue.permissionValue.toString() === value) {
              permissionValue.isSelected = true;
            } else {
              permissionValue.isSelected = false;
            }
          });
        }
      })
    );
    setPermissionGroups(permissions);
    setIsChangedPermissions(true);
  };

  const handlePermissionAllValuesChange = (
    permissionGroupId: number,
    settingType: SetAllValuesEnum
  ): void => {
    if (permissions.length === 0) {
      permissions = [...permissionGroups];
    }
    let permissionIDs: number[] = [];
    permissions.map((permissionGroup) => {
      if (permissionGroup.permissionGroupID === permissionGroupId) {
        permissionGroup.permissions.map((permission) => {
          permissionIDs.push(permission.permissionID);
          let value: number | undefined;
          if (settingType === SetAllValuesEnum.MinValues) {
            value = permission.permissionValues.reduce(
              (min, p) => (p.permissionValue < min ? p.permissionValue : min),
              permission.permissionValues[0].permissionValue
            );
          } else {
            value = permission.permissionValues.reduce(
              (max, p) => (p.permissionValue > max ? p.permissionValue : max),
              permission.permissionValues[0].permissionValue
            );
          }
          if (value !== undefined) {
            permission.permissionValues.map((permissionValue) => {
              if (permissionValue.permissionValue === value) {
                permissionValue.isSelected = true;
              } else {
                permissionValue.isSelected = false;
              }
            });
          }
        });
      }
    });
    permissions.map((permissionGroup) => {
      if (permissionGroup.permissionGroupID !== permissionGroupId) {
        permissionGroup.permissions.map((permission) => {
          if (permissionIDs.indexOf(permission.permissionID) >= 0) {
            let value: number | undefined;
            if (settingType === SetAllValuesEnum.MinValues) {
              value = permission.permissionValues.reduce(
                (min, p) => (p.permissionValue < min ? p.permissionValue : min),
                permission.permissionValues[0].permissionValue
              );
            } else {
              value = permission.permissionValues.reduce(
                (max, p) => (p.permissionValue > max ? p.permissionValue : max),
                permission.permissionValues[0].permissionValue
              );
            }
            if (value !== undefined) {
              permission.permissionValues.map((permissionValue) => {
                if (permissionValue.permissionValue === value) {
                  permissionValue.isSelected = true;
                } else {
                  permissionValue.isSelected = false;
                }
              });
            }
          }
        });
      }
    });
    setPermissionGroups(permissions);
    setIsChangedPermissions(true);
  };

  let reports: GetReportsForRoleApiResponseType[] = [...reportContainers];

  const getReportContainers = async (roleID: string): Promise<void> => {
    setIsBlockedUi(true);
    await reportApi
      .getReportsForRole(roleID)
      .then((result: GetReportsForRoleApiResultType) => {
        if (result && result.data) {
          setSelectedTab(
            props.defaultTab === AccessRightsDefaultTab.Permissions
              ? permissionsTab
              : reportsTab
          );
          setReportContainers(result.data);
        } else {
          setSelectedTab(permissionsTab);
          setReportContainers([]);
        }
      })
      .finally(() => {
        setIsBlockedUi(false);
        setIsChangedReports(false);
      });
  };

  const handleReportSelectedChange = (
    reportID: number,
    isSelected: boolean
  ): void => {
    if (reports.length === 0) {
      reports = [...reportContainers];
    }
    reports.map((reportContainer) =>
      reportContainer.reports.map((report) => {
        if (report.reportID === reportID) {
          report.isSelected = isSelected;
        }
      })
    );
    setReportContainers(reports);
    setIsChangedReports(true);
  };

  const handleReportAllValuesChange = (
    reportContainerID: number,
    changeTo: boolean
  ): void => {
    if (reports.length === 0) {
      reports = [...reportContainers];
    }
    let reportIDs: number[] = [];
    reports.map((reportContainer) => {
      if (reportContainer.reportContainerID === reportContainerID) {
        reportContainer.reports.map((report) => {
          report.isSelected = changeTo;
          reportIDs.push(report.reportID);
        });
      }
    });
    reports.map((reportContainer) => {
      if (reportContainer.reportContainerID !== reportContainerID) {
        reportContainer.reports.map((report) => {
          if (reportIDs.indexOf(report.reportID) >= 0) {
            report.isSelected = changeTo;
          }
        });
      }
    });
    setReportContainers(reports);
    setIsChangedReports(true);
  };

  const handleSaveClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setIsBlockedUi(true);

    // permissions
    //if (selectedTab === permissionsTab) {
    let permissionValueIDs: number[] = [];
    permissions.map((permissionGroup) => {
      permissionGroup.permissions.map((permission) => {
        permission.permissionValues.map((permissionValue) => {
          if (permissionValue.isSelected) {
            permissionValueIDs.push(permissionValue.permissionValueID);
          }
        });
      });
    });

    const rolePermissions: SaveRolePermissionsType = {
      roleID: Number(selectedRoleId),
      permissionValueIDs: permissionValueIDs,
    };

    await roleApi
      .saveRolePermissions(rolePermissions)
      .then(async () => {
        await getPermissionGroups(selectedRoleId);
      })
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      })
      .finally(() => {
        setIsBlockedUi(false);
      });
    //}
    //// reports
    //else {
    let reportIDsSelected: number[] = [];
    let reportIDsUnselected: number[] = [];
    reports.map((reportContainer) => {
      reportContainer.reports.map((report) => {
        if (report.isSelected) {
          reportIDsSelected.push(report.reportID);
        } else {
          reportIDsUnselected.push(report.reportID);
        }
      });
    });

    const roleReports: SaveReportsForRoleModelType = {
      roleID: Number(selectedRoleId),
      reportIDsSelected: reportIDsSelected,
      reportIDsUnselected: reportIDsUnselected,
    };

    await reportApi
      .saveReportsForRole(roleReports)
      .then(async () => {
        await getReportContainers(selectedRoleId);
      })
      .catch((error) => {
        console.error(error);
        setErrorResponse(error);
        setIsErrorDialogOpen(true);
      })
      .finally(() => {
        setIsBlockedUi(false);
      });
    //}
  };

  const handleErrorDialogOkClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setIsErrorDialogOpen(false);
  };

  const isChanged = isChangedPermissions || isChangedReports;

  return (
    <Container>
      <AccessRightHeader
        roleList={roles}
        selectedRoleId={selectedRoleId}
        isChanged={isChanged}
        handleRoleChange={handleRoleChange}
        handleSaveClick={handleSaveClick}
      />
      <ProgressSpinner isDisplayed={isBlockedUi}>
        {reports.length > 0 ? (
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            className={customStyles.tabs}
          >
            <Tab label="Permissions" />
            <Tab label="Reports" />
          </Tabs>
        ) : (
          ""
        )}
        <TabPanel value={selectedTab} index={0}>
          <AccessRightListContainer
            permissionGroups={permissionGroups}
            handleAllValuesChange={handlePermissionAllValuesChange}
            handlePermissionValueChange={handlePermissionValueChange}
            defaultTab={AccessRightsDefaultTab.Permissions}
          />
        </TabPanel>
        {reports.length > 0 ? (
          <TabPanel value={selectedTab} index={1}>
            <AccessRightsListReportsContainer
              reportContainers={reports}
              handleAllValuesChange={handleReportAllValuesChange}
              handleReportSelectedChange={handleReportSelectedChange}
              defaultTab={AccessRightsDefaultTab.Reports}
            />
          </TabPanel>
        ) : (
          ""
        )}
      </ProgressSpinner>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        error={errorResponse}
        handleOkClick={handleErrorDialogOkClick}
      />
    </Container>
  );
};

export default AccessRights;
